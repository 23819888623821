import styled, { css } from 'styled-components';

import { crossBrowserTransition, ptr } from '../../../styles/utils';
import { colors, transition } from '../../../styles/variables';

const Container = styled.div``;

const Wrapper = styled.div`
  margin-top: ${ptr(12)};
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
  }

  & + button {
    width: 100%;
  }
`;

const Passwords = styled.div`
  margin-top: ${ptr(16)};

  > :first-child {
    margin-bottom: ${ptr(12)};
  }
`;

const CheckBoxContainer = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(32)};
`;

const EmailContainer = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(32)};

  > :first-child {
    margin-bottom: ${ptr(12)};
  }
`;

const FormInput = {
  Wrapper: styled.div`
    margin-bottom: ${ptr(12)};
  `,
};

const PasswordDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${ptr(4)} 0 ${ptr(24)} 0;
  
  > * {
    margin-bottom: ${ptr(8)};
  }
`;

const PasswordDescriptionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > * {
    margin-right: ${ptr(8)};
  }
`;

const InvalidTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  ${crossBrowserTransition(transition.slow)};
`;
const InvalidIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ptr(24)};
  height: ${ptr(24)};
  border-radius: 50%;
  background-color: ${colors.red._10};
  margin-right: ${ptr(12)};
`;



export {
  Container,
  Wrapper,
  Passwords,
  CheckBoxContainer,
  EmailContainer,
  FormInput,
  PasswordDescriptionWrapper,
  PasswordDescriptionItemWrapper,
  InvalidTextWrapper,
  InvalidIconWrapper,
};
