import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
import { formatBytes } from '../../utils/strings';
import { failed, success } from '../Toasts';
import { Text } from '../Text';
import { DeletePopup } from '../../pages/Onboarding/components/DeletePopup';
import { Modal } from '../LoadingModal';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions } from '../../pages/Onboard/config';
import { OnboardingActions } from '../../redux/onboarding/actions';
import onboardingService from '../../services/internal/onboarding/onboarding.service';

const FORMATS_TO_PREVIEW = ['image/png', 'image/jpg', 'image/jpeg'];
const EXCEL_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
const EXCEL_EXTENSIONS = ['csv', 'xls', 'xlsx'];
const MAX_SIZE = 2000000;

interface FileIconProps {
  title?: string;
}

const FileIcon: React.FC<FileIconProps> = ({ title = '' }) => (
  <S.FileIconContainer>
    <S.FileIconTitleWrapper>
      <S.FileIconTitle>{title?.toUpperCase()}</S.FileIconTitle>
    </S.FileIconTitleWrapper>
    <S.FileIconBackground />
  </S.FileIconContainer>
);

interface DeleteCrossProps {
  onClick: () => void;
}

const DeleteCross: React.FC<DeleteCrossProps> = ({ onClick }) => (
  <S.DeleteCross onClick={onClick}>
    <Icon name='trash-can' />
  </S.DeleteCross>
);

export interface FileUploadProps {
  /**
   * Accepted formats of files (all accepted by default)
   */
  acceptedFormats?: Array<string>;
  /**
   * Formats to preview (images 'image/png', 'image/jpg', 'image/jpeg' by default)
   */
  formatsToPreview?: Array<string>;
  file?: File;
  showModal?: boolean;
  /**
   * On change file event
   */
  onChange?: (file: File | undefined) => void;
  /**
   * Custom props
   */
  email?: string;
  type: string;
  reference: string;
  isSignature?: boolean;
  isReferenceForm?: boolean;
}

export interface PreviewProps {
  withPreviewImage: boolean;
}

interface FileNameAndFormat {
  name: string;
  format: string;
}

export const DocumentUpload: React.FC<FileUploadProps> = ({
  formatsToPreview = FORMATS_TO_PREVIEW,
  email = '',
  isSignature = false,
  showModal = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const route = useHistory();

  const [openn, setOpen] = useState(false);
  const [file, setFile] = useState<Blob | string>('');
  const [fileName, setFileName] = useState<FileNameAndFormat | null>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const getFileNameAndFormat = (name: string): FileNameAndFormat => {
    const split = name.split('.');
    return {
      name: split.slice(0, split.length - 1).join(''),
      format: split[split.length - 1],
    };
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  const isNeedPreview = (fileType: string) =>
    !!formatsToPreview?.includes(fileType);

  const getAndSetPreview = (newFile: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(newFile);

    setLoading(true);

    reader.onloadend = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPreview(reader.result);
      setLoading(false);
    };
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (
      EXCEL_EXTENSIONS?.includes(acceptedFiles[0].name.split('.').pop()) ||
      EXCEL_FORMATS?.includes(acceptedFiles[0].type)
    ) {
      failed('Failed', 'Wrong file format');
      return;
    }
    if (acceptedFiles[0].size > MAX_SIZE) {
      failed('Failed', 'File should not be more then 2mb');
      return;
    }
    if (isSignature && !formatsToPreview?.includes(acceptedFiles[0].type)) {
      failed('Failed', 'Signature must be in picture format');
      return;
    }
    if (acceptedFiles[0]) {
      if (props.onChange) {
        props.onChange(acceptedFiles[0]);
      }

      setFile(acceptedFiles[0]);
      setFileName(getFileNameAndFormat(acceptedFiles[0].name));

      if (isNeedPreview(acceptedFiles[0].type)) {
        getAndSetPreview(acceptedFiles[0]);
      }
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: props?.acceptedFormats?.join(',') || undefined,
  });

  const deleteFile = () => {
    setFile('');
    setFileName(null);
    setPreview(undefined);

    if (props.onChange) {
      props.onChange(undefined);
    }

    setOpen(false);
  };

  const deleteFilePopup = () => {
    setOpen(!openn);
  };

  const deleteFilePopupCan = () => {
    setOpen(true);
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const data = new FormData();
      data.append('file', file);
      data.append('type', props.type);
      data.append('reference', props.reference);
      data.append('email', email);
      const response = await onboardingService.uploadDocuments(data);
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            reference: response.data.reference,
          })
        );
      });
      success('File upload', 'Successful');
    } catch (err) {
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setUploading(false);
  };

  useEffect(() => {
    setFile(props.file as File);
  }, [props.file]);

  useEffect(() => {
    if (file && !props.isReferenceForm) {
      handleUpload();
    }
  }, [file]);

  return (
    <>
      {/* <Modal visible={uploading} title='Uploading, please wait...' /> */}
      <Modal visible={uploading} title='Uploading, please wait...' />
      {/* <S.Container showModal={showModal}> */}
      <S.Wrapper {...getRootProps()}>
        <input {...getInputProps()} />
        {file && !loading ? (
          <>
            <S.PreviewWrapper withPreviewImage={!!preview}>
              <S.FileNameWrapper>
                <S.FileFormat>
                  <Icon name='file-display' color={colors.pink._100} />
                  <S.FileName>{fileName?.name}</S.FileName> .
                  {fileName?.format.toUpperCase()}
                </S.FileFormat>

                <DeleteCross onClick={deleteFilePopupCan} />
              </S.FileNameWrapper>
            </S.PreviewWrapper>
            {openn && (
              <div style={{ position: 'absolute', zIndex: 90 }}>
                <DeletePopup onClick={deleteFile} onCancel={deleteFilePopup} />
              </div>
            )}
          </>
        ) : (
          <S.WrapperEmpty onClick={open}>
            <S.FileIconWrapper>
              <Icon name='files' color={colors.pink._100} />
            </S.FileIconWrapper>
            <S.TextDrag>
              <Text size={5} color={colors.black._80}>
                Browse file
              </Text>
              <span>
                <Text size={8} color={colors.black._80}>
                  {isSignature
                    ? 'File format: JPG, PNG'
                    : 'File format: JPG, PNG, GIF, PDF'}
                </Text>
              </span>
            </S.TextDrag>
          </S.WrapperEmpty>
        )}
      </S.Wrapper>
      {/* </S.Container> */}
    </>
  );
};
