import React from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OnboardingActions } from '../../../redux/onboarding/actions';

import { success } from '../../../components/Toasts';
import { SuccessSection } from '../../Onboarding/components/SuccessSection';

const CommonSoleAccCompleted: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Custom handlers initiation region
   */
  const gotItHandler = () => {
    batch(() => {
      dispatch(OnboardingActions.handleResetStacks());
      dispatch(OnboardingActions.handleUnSetEnv());
    });
    success('Success!', 'You\'ve created an account successfully.');
    history.push('/login');
  };

  return (
    <SuccessSection
      title="Account opening completed"
      description="We have sent you a confirmation email, but you will not be able to make any transactions until board members have confirmed  account opening with VFD MFB by following the link we sent them in a personal letter."
      onClick={gotItHandler}
    />
  );
};
export default CommonSoleAccCompleted;
