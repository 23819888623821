import styled, { css } from 'styled-components';

import { crossBrowserTransition, ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${ptr(20)} ${ptr(30)};
  width: ${ptr(454)};
  gap: ${ptr(40)};
  align-items: center;
  text-align: center;
  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const TextContainer = styled.div``;

const DescriptionContainer = styled.div`
  margin-top: 15px;
`;

const ButtonContainer = styled.div`
  width: ${ptr(420)};
  @media (max-width: ${breakpoints.sm}px) {
    width: ${ptr(250)};
  }
`;

const ButtonContainer2 = styled.div``;

export {
  Container,
  TextContainer,
  DescriptionContainer,
  ButtonContainer,
  ButtonContainer2,
};
