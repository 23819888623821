import styled, { keyframes } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

function blinkEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

const Wrapper = styled.div`
  padding: ${ptr(44)} ${ptr(44)};
  box-sizing: border-box;
  width: 34.445vw;
 margin-left: auto;
    margin-right: auto;
  @media (max-width: ${breakpoints.md}px) {
    width: 90vw;
  }
`;

const TextContainer = styled.div`
text-align: center;
 animation: ${blinkEffect} 2s linear infinite;
`;

export { Wrapper, TextContainer };
