import styled, { css } from 'styled-components';

import { ButtonProps } from '.';
import { fonts, colors, transition, breakpoints } from '../../styles/variables';
import {
  crossBrowserBoxShadow,
  crossBrowserTransition,
  hexToRgbA,
  ptr,
} from '../../styles/utils';

const borderWidth = ptr(2);

const StyledButton = styled.button<ButtonProps>`
  font-family: ${fonts.inter.regular};
  border: 0;
  border-radius: ${ptr(6)};
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 140%;
  justify-content: center;
  gap:5px;
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    min-width:fit-content;
  }
  ${crossBrowserTransition(`background ${transition.fast} linear`)}
  ${crossBrowserTransition(`box-shadow ${transition.fast} linear`)}

  ${(props) =>
    props.label &&
    css`
      :last-child svg {
        margin-left: ${ptr(12)};
      }
    `}

  ${(props) =>
    props.theme === 'primary' &&
    css`
    color: ${colors.white._100};
    background-color: ${colors.pink._5};

    ${
      !props.disabled &&
      `
      :hover {
        background-color: ${colors.pink._80};
        
        ${crossBrowserBoxShadow(
          `0 ${ptr(8)} ${ptr(10)} ${hexToRgbA(colors.pink._80, 20)}`
        )}
      }
      
      :active {
        ${crossBrowserBoxShadow(
          `inset 0 0 ${ptr(20)} ${hexToRgbA(colors.black._100, 20)}`
        )}
      }

      :focus {
        border: ${borderWidth} solid ${colors.pink._20};
      }
    `
    }
    
    ${
      props.disabled &&
      `
      background-color: ${colors.pink._40};
      cursor: default;
    `
    }
    ${
      props.loading &&
      `
     background-color: ${colors.black._40};
      cursor: default;
    `
    }
  `}

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      color: ${colors.white._100};
      background-color: ${colors.black._100};

      ${!props.disabled &&
      `
      :hover {
        ${crossBrowserBoxShadow(
          `0 ${ptr(8)} ${ptr(10)} ${hexToRgbA(colors.black._100, 25)}`
        )}
      }
      
      :active {
        ${crossBrowserBoxShadow(
          `inset 0 0 ${ptr(20)} ${hexToRgbA(colors.black._100, 60)}`
        )}
      }
      
      :focus {
        border: ${borderWidth} solid ${colors.black._40};
      }
    `}

      ${props.disabled &&
      `
      background-color: ${colors.black._40};
      cursor: default;
    `}
    `}
  
  ${(props) =>
    props.theme === 'light' &&
    css`
      color: ${colors.pink._100};
      background-color: ${colors.white._100};

      ${!props.disabled &&
      `
      :hover {
        ${crossBrowserBoxShadow(
          `0 ${ptr(8)} ${ptr(10)} ${hexToRgbA(colors.black._100, 6)}`
        )}
      }

      :active {
        ${crossBrowserBoxShadow(
          `inset 0 0 ${ptr(6)} ${hexToRgbA(colors.black._100, 8)}`
        )}
      }
      
      :focus {
        border: ${borderWidth} solid ${colors.black._10};
      }
    `}

      ${props.disabled &&
      `
      color: ${colors.black._40};
      background-color: ${colors.black._5};
      cursor: default;
    `}
    `}

  ${(props) =>
    props.theme === 'info' &&
    css`
      color: ${colors.black._100};
      background-color: ${colors.black._5};

      ${!props.disabled &&
      `
      :hover {
        background-color: ${colors.pink._10};
        color: ${colors.pink._80};
      }
      
      :active {
        ${crossBrowserBoxShadow(
          `inset 0 0 ${ptr(10)} ${hexToRgbA(colors.pink._100, 10)}`
        )}
        color: ${colors.pink._100};
      }
      
      :focus {
        border: ${borderWidth} solid ${colors.pink._20};
        color: ${colors.pink._80};
      }
    `}

      ${props.disabled &&
      `
      color: ${colors.black._40};
      cursor: default;
    `}
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${ptr(14)};
      padding: ${ptr(6)} ${ptr(16)};

      :focus {
        padding: calc(${ptr(6)} - ${borderWidth})
          calc(${ptr(16)} - ${borderWidth});
      }
    `}

  ${(props) =>
    props.size === 'tiny' &&
    css`
      font-size: ${ptr(14)};
      padding: ${ptr(8)} ${ptr(16)};

      :focus {
        padding: calc(${ptr(8)} - ${borderWidth})
          calc(${ptr(16)} - ${borderWidth});
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: ${ptr(16)};
      padding: ${ptr(16)} ${ptr(40)};

      :focus {
        padding: calc(${ptr(16)} - ${borderWidth})
          calc(${ptr(40)} - ${borderWidth});
      }
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: ${ptr(24)};
      padding: ${ptr(16)} ${ptr(40)};

      :focus {
        padding: calc(${ptr(16)} - ${borderWidth})
          calc(${ptr(40)} - ${borderWidth});
      }
    `}
  ${(props) =>
    props.fontSize &&
    `
      font-size: ${props.fontSize}px;
    `}
`;

export { StyledButton };
