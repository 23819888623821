import React from 'react';

import { Button } from '../../../../components/Button';
import { Text } from '../../../../components/Text';
import { Icon } from '../../../../components/Icon';
import * as S from './styles';
import { colors } from '../../../../styles/variables';

export interface SuccessSectionProps {
  title: string;
  description?: string;
  onClick?: () => void;
  buttonLabel?: string;
}

export const SuccessSection: React.FC<SuccessSectionProps> = ({
  title,
  description,
  buttonLabel,
  onClick = () => {},
}) => (
  <S.Container>
    <S.Circle>
      {/* <Icon color='pink' name='check-mark' width={56} height={42} /> */}
      <S.Img src='/images/onboarding/success.png' alt='..' />
    </S.Circle>
    <S.Wrapper>
      <Text color={colors.black._20} bold size={3}>
        {title}
      </Text>
      {description && (
        <S.SuccessDescription>
          <div>
            <Text size={7} color='#946200'>
              {description}
            </Text>
          </div>
        </S.SuccessDescription>
      )}
      <Button
        style={{
          background: '#B10180',
          width: '80%',
          padding: '12px',
        }}
        label={buttonLabel || 'Log In'}
        onClick={onClick}
      />
    </S.Wrapper>
  </S.Container>
);
