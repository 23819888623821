import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';

export interface LogoProps {
  marginLeft?: number,
  marginTop?: number,
}

export const Logo: React.FC<LogoProps> = React.memo(
  ({ marginLeft, marginTop }) => (
    <S.Wrapper marginLeft={marginLeft} marginTop={marginTop}>
      <Link to="/">
        <S.Img alt="logo" src="/images/logo/logo.svg" />
      </Link>
    </S.Wrapper>
  ),
);
