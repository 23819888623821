import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Text } from '../Text';
import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
import { formatBytes } from '../../utils/strings';
import { failed } from '../Toasts';

const FORMATS_TO_PREVIEW = ['image/png', 'image/jpg', 'image/jpeg'];
const MAX_SIZE = 2000000;

interface FileIconProps {
  title?: string;
}

const FileIcon: React.FC<FileIconProps> = ({ title = '' }) => (
  <S.FileIconContainer>
    <S.FileIconTitleWrapper>
      <S.FileIconTitle>{title?.toUpperCase()}</S.FileIconTitle>
    </S.FileIconTitleWrapper>
    <S.FileIconBackground />
  </S.FileIconContainer>
);

interface DeleteCrossProps {
  onClick: () => void;
}

const DeleteCross: React.FC<DeleteCrossProps> = ({ onClick }) => (
  <S.DeleteCross onClick={onClick}>
    <Icon name='cross' color={colors.white._100} />
  </S.DeleteCross>
);

export interface FileUploadProps {
  /**
   * Accepted formats of files (all accepted by default)
   */
  acceptedFormats?: Array<string>;
  /**
   * Formats to preview (images 'image/png', 'image/jpg', 'image/jpeg' by default)
   */
  formatsToPreview?: Array<string>;
  file?: File;
  /**
   * On change file event
   */
  onChange?: (file: File | undefined) => void;
}

export interface PreviewProps {
  withPreviewImage: boolean;
}

interface FileNameAndFormat {
  name: string;
  format: string;
}

export const FileUploadUpdated2: React.FC<FileUploadProps> = ({
  formatsToPreview = FORMATS_TO_PREVIEW,
  ...props
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<FileNameAndFormat | null>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const getFileNameAndFormat = (name: string): FileNameAndFormat => {
    const split = name.split('.');
    return {
      name: split.slice(0, split.length - 1).join(''),
      format: split[split.length - 1],
    };
  };

  const isNeedPreview = (fileType: string) =>
    !!formatsToPreview?.includes(fileType);

  const getAndSetPreview = (newFile: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(newFile);

    setLoading(true);

    reader.onloadend = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPreview(reader.result);
      setLoading(false);
    };
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size > MAX_SIZE) {
      failed('Failed', 'File should not be more then 2mb');
      return;
    }
    if (acceptedFiles[0]) {
      if (props.onChange) {
        props.onChange(acceptedFiles[0]);
      }

      setFile(acceptedFiles[0]);
      setFileName(getFileNameAndFormat(acceptedFiles[0].name));

      if (isNeedPreview(acceptedFiles[0].type)) {
        getAndSetPreview(acceptedFiles[0]);
      }
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: props?.acceptedFormats?.join(',') || undefined,
  });

  const deleteFile = () => {
    setFile(null);
    setFileName(null);
    setPreview(undefined);

    if (props.onChange) {
      props.onChange(undefined);
    }
  };

  useEffect(() => {
    setFile(props.file as File);
  }, [props.file]);

  return (
    <S.Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      {file && !loading ? (
        <>
          <S.PreviewWrapper withPreviewImage={!!preview}>
            {preview ? (
              <S.ImagePreview src={preview || undefined} />
            ) : (
              <FileIcon title={fileName?.format} />
            )}
            <S.FileNameWrapper>
              <Text onClick={deleteFile} bold size={7} color='#C30052'>
                Delete file
              </Text>
              {/* <S.FileName>{fileName?.name}</S.FileName> */}
              {/* <S.FileFormat>.{fileName?.format.toUpperCase()}</S.FileFormat> */}
            </S.FileNameWrapper>
            <S.FileSizeWrapper>
              <S.FileSize>{formatBytes(file?.size)}</S.FileSize>
            </S.FileSizeWrapper>
          </S.PreviewWrapper>
        </>
      ) : (
        <S.WrapperEmpty onClick={open}>
          <S.FileIconWrapper>
            <Icon name='cloud-upload-big' />
          </S.FileIconWrapper>

          <S.TextDrag>
            <div style={{ textAlign: 'center' }}>
              <Text bold size={8} color='#000000'>
                Drag and drop or{'  '}
                <Text bold size={8} color='#B10180'>
                  Browse file{' '}
                </Text>
              </Text>
            </div>

            <div>
              <Text
                style={{
                  whiteSpace: 'pre-wrap',
                }}
                size={8}
                color={colors.black._80}
              >
                Accepted file format: *.jpeg, *.png or *.pdf only
              </Text>
            </div>
          </S.TextDrag>
        </S.WrapperEmpty>
      )}
    </S.Wrapper>
  );
};
