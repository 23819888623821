import React, { FC, useState } from 'react';
import * as S from './styles';

import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TextInput } from '../../../components/TextInput';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface IProps {
  handleCancel: () => void;
  onSubmit: (token: string) => void;
  handlePrev: () => void;
  loading: boolean;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
};
const StepTwo: FC<IProps> = ({
  handleCancel,
  onSubmit,
  handlePrev,
  loading,
}) => {
  const [token, setToken] = useState('');
  const handleNext = () => {
    if (!token) return;
    if (onSubmit) onSubmit(token);
  };
  return (
    <S.ManualContainer>
      <S.TitleContainer>
        <Icon name="arrow_back" onClick={handlePrev} color="#000000" />
        <Text bold size={4}>
          Set up authenticator app
        </Text>
      </S.TitleContainer>

      <S.InputContainer>
        <TextInput
          label="Enter the 6-digit code you see in the app"
          value={token}
          onChange={setToken}
          type="password"
          mask={createNumberMask(numberMaskOptions)}
        />
      </S.InputContainer>

      <S.ButtonContainerTwo style={{ marginTop: '50px' }}>
        <Button label="Cancel" theme="light" onClick={handleCancel} />
        <div style={{ width: '200px' }}>
          <Button
            style={{ width: '100%' }}
            label="Verify"
            onClick={handleNext}
            loading={loading}
            disabled={!token || token?.length !== 6 || loading}
          />
        </div>
      </S.ButtonContainerTwo>
    </S.ManualContainer>
  );
};

export default StepTwo;
