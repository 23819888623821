import styled from 'styled-components';

import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${ptr(-16)};

  > div {
    margin-right: ${ptr(16)};
    margin-top: ${ptr(16)};
  }
`;

export {
  Wrapper,
};
