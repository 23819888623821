import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { validateObject, ValidateResult } from '../../../../utils/validation';
import { success } from '../../../../components/Toasts';
import { FormTitle } from '../../../../components/FormTitle';
import { FormButton, FormInput } from '../../../../styles/wrappers';
import { TextInput } from '../../../../components/TextInput';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Button } from '../../../../components/Button';
import ConfigPassword from '../../../../configs/password';
import { Icon } from '../../../../components/Icon';

const SCHEME_VALIDATION_PASSWORD = {
  minimumCharacters: (value: string) => value.length >= ConfigPassword.minLength,
  uppercase: (value: string) => ConfigPassword.uppercase.test(value),
  lowercase: (value: string) => ConfigPassword.lowercase.test(value),
  special: (value: string) => ConfigPassword.special.test(value),
};

export const CreatePasswordForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [validation, setValidation] = useState<ValidateResult | undefined>(undefined);

  const history = useHistory();

  const onCreateNewPassword = () => {
    success('Success!', 'New password has been successfully created.');
    history.push('/login');
  };

  useEffect(() => {
    const validationResult = validateObject({
      minimumCharacters: password,
      uppercase: password,
      lowercase: password,
      special: password,
    }, SCHEME_VALIDATION_PASSWORD);

    setValidation({
      ...validationResult,
      isValid: validationResult.isValid,
    });
  }, [password, repeatPassword]);

  return (
    <>
      <FormTitle
        title="Create password"
        description="Create password of your account."
      />
      <FormInput.Wrapper>
        <TextInput
          label="New password"
          type="password"
          value={password}
          maxLength={ConfigPassword.maxLength}
          onChange={setPassword}
          valid={validation?.isValid}
        />
      </FormInput.Wrapper>
      <S.PasswordDescriptionWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.minimumCharacters?.isValid && (
              <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least 8 characters.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.uppercase?.isValid && (
            <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least one uppercase is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.lowercase?.isValid && (
            <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least one lowercase is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.special?.isValid && (
            <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least one special character is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
      </S.PasswordDescriptionWrapper>
      <FormInput.Wrapper>
        <TextInput
          label="Repeat new password"
          type="password"
          value={repeatPassword}
          maxLength={ConfigPassword.maxLength}
          onChange={setRepeatPassword}
          valid={validation?.isValid && password === repeatPassword}
        />
      </FormInput.Wrapper>
      <FormButton.WrapperEnd>
        <Button
          label="Create password"
          disabled={!validation?.isValid || password !== repeatPassword}
          onClick={onCreateNewPassword}
        />
      </FormButton.WrapperEnd>
    </>
  );
};
