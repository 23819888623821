import styled, { css } from "styled-components";
import { colors, fonts } from "../../styles/variables";
import { ptr } from "../../styles/utils";

const InfoWrapper = styled.div`
  display: flex;
  gap: ${ptr(8)};
  // flex-wrap:wrap;
  align-items:center;
  border-radius: 4px;
  background: #FFF9EF;
  padding: ${ptr(10)};
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(14)};
 
`;

export {
    InfoWrapper,
};
