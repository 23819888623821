/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Slider from "react-slick";
import { IconName } from "../../../../components/Icon";
import * as S from "./styles";
import "./styles.css";

export interface ItemProps {
  label: string;
  link: string;
  icon?: IconName["name"];
  disabled?: boolean;
}

export interface WelcomeSliderProps {
  image?: string;
}

export const Bar: React.FC<WelcomeSliderProps> = ({
  image = "/images/onboarding/1.jpeg",
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    color: "#394455",
    arrows: false,
  };

  function slideOne() {
    return <S.Container></S.Container>;
  }

  function slideTwo() {
    return <S.Container1></S.Container1>;
  }
  // const sliderData = [slideOne(), slideOne(), slideOne(), slideOne()];

  return (
    <div className="ad">
      <S.WelcomeWrapper>
        {/* <ContentContainer> */}
        <S.ImgContainer style={{ position: "relative" }}>
          {/* <Slider style={{ position: 'absolute', width: '100%' }} {...settings}> */}
          <div
            style={{ position: "absolute", width: "100%", borderRadius: 90 }}
          >
            <Slider {...settings}>
              {slideOne()}
              {slideTwo()}
            </Slider>
          </div>
        </S.ImgContainer>

        {/* </ContentContainer> */}
      </S.WelcomeWrapper>
    </div>
  );
};
