import styled from "styled-components";

import { ptr } from "../../../styles/utils";
import { colors, fonts, breakpoints } from "../../../styles/variables";

const Container = styled.div``;

const Wrapper = styled.div`
  margin-top: ${ptr(32)};

  > :not(:first-child) {
    margin-top: ${ptr(16)};
  }
`;
const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const WrapperItems= styled.div`
display:flex;
flex-direction:column;
justify-content: space-between;
 
`;

const WrapperItemsOne= styled.div`
display:flex;
flex-direction:column;
padding-bottom:12px;
border-bottom: 1px solid var(--stroke, #EAECF0);
`;


const Lhs= styled.div`
flex-wrap:wrap;
display:flex;
gap:8px;

>div{
  gap:2px;
  display:flex;
  flex-direction:column;
}
`;


const Rhs= styled.div`

`;

const PreferredOperator= styled.div` 
width:100%
`;

const PreferredOperatorItemDiv= styled.div`
display:flex;
gap:14px;
`;


const PreferredOperatorItem= styled.div`
margin-top: 14px;
display: flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;

`;

const WrapperItemsInner= styled.div`
display:flex;
align-items: center;
justify-content: space-between;
`;

const Circle = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(15)}; 
  height: ${ptr(15)};
  border-radius: ${ptr(12)};
 border: ${ptr(1)} #B10180 solid;
  cursor:pointer;
  
 
  
`;
const InsideCircle = styled.div`
  width: ${ptr(7)};
  height: ${ptr(7)};
  
  border-radius: ${ptr(9)};
background-color: #B10180
 
`;

const ModalChild = styled.div`
  padding: ${ptr(16)} ${ptr(6)} ${ptr(32)} ${ptr(6)};

  div {
    width: ${ptr(420)};
    border: 1px solid ${colors.black._1};
    border-radius: ${ptr(6)};
    padding: ${ptr(20)} ${ptr(16)};
    margin-bottom: ${ptr(10)};
    font-family: ${fonts.inter.regular};
    background-color: ${colors.white._50};
    cursor: pointer;
    @media (max-width: ${breakpoints.md}px) {
      width: auto;
    }
  }
`;

export { Container, Wrapper,Circle,PreferredOperator,WrapperItemsOne,PreferredOperatorItemDiv, PreferredOperatorItem, WrapperItemsInner, InsideCircle,ButtonGroup,WrapperItems, Lhs,Rhs, PreloadContainer, ModalChild };
