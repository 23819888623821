import React, { useEffect, useState } from 'react';

import * as S from './styles';
import { OtpInput } from '../../../../components/OtpInput';
import { FlexColumn } from '../../../../styles/wrappers';
import { FormTitle } from '../../../../components/FormTitle';
import { ResendCode } from '../ResendCode';
import otpService from '../../../../services/internal/otp/otp.service';
import { failed } from '../../../../components/Toasts';
// import { failed } from '../../../../components/Toasts';

const OTP_CODE_LENGTH = 6;
export interface OtpCodeProps {
  /**
   * Create request click action
   */
  onComplite: (code: string) => void,
  accountInfo: any,
}

export const OtpCode: React.FC<OtpCodeProps> = ({ onComplite, accountInfo }) => {
  const [showResendCode, setShowResendCode] = useState(0);

  const sentOTP = async () => {
    try {
      await otpService.sendOTP(accountInfo.accountNo);
    } catch (err) {
      failed('Fail', 'Failed send otp code');
    }
  };

  useEffect(() => {
    if (accountInfo) {
      sentOTP();
    }
  }, []);

  const onResendCode = () => {
    setShowResendCode(showResendCode + 1);
  };

  const onOtpCodeChanged = (value: string) => {
    if (value.length === OTP_CODE_LENGTH) {
      onComplite(value);
    }
  };

  return (
    <FlexColumn>
      <FormTitle
        title="OTP Code"
        description="We have sent OTP Code to phone number + 28 (23) *** *** 10. Enter this code to verify your account."
      />
      <OtpInput
        codeLength={OTP_CODE_LENGTH}
        onCodeChange={onOtpCodeChanged}
        secure
      />
      <S.ResendCodeWrapper>
        <ResendCode
          duration={10}
          resetTimer={showResendCode}
          onClick={onResendCode}
        />
      </S.ResendCodeWrapper>
    </FlexColumn>
  );
};
