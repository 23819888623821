import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { colors } from '../../styles/variables';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${ptr(-16)};

  > div {
    margin-right: ${ptr(16)};
    margin-top: ${ptr(16)};
  }
`;

const CheckboxContainer = styled.div`
display: flex;
flex-wrap:wrap;
margin-bottom: 10px;
`;

const SpaceWrapper = styled.div`
margin: 0 5px;
`;

const CheckboxWrapper = styled.div`
    padding: 10px;
    border: 1px solid  ${colors.black._40};
    border-radius: 50px;
    margin-bottom: 5px;
`;

export {
  Wrapper,
  CheckboxContainer,
  SpaceWrapper,
  CheckboxWrapper,
};
