import React, { useState, useEffect, CSSProperties } from 'react';
import { Preload } from '../Preload';

import * as S from './styles';

// const BACKSPACE_KEY_CODE = 8;

export interface OtpInputProps {
  /**
   * Length of the code
   */
  codeLength?: number;
  /**
   * Initial value
   */
  value?: string;
  /**
   * Show or hide display
   */
  secure?: boolean;
  /**
   * On change event
   */
  /**
   * Display error message
   */
  errorMessage?: string;

  /**
   * Indicates if input is loading
   */
  loading?: boolean;

  style?: CSSProperties;

  onCodeChange?: (value: string) => void;
}

export const OtpInput: React.FC<OtpInputProps> = ({
  codeLength = 6,
  secure = false,
  loading = false,
  ...props
}) => {
  const [value, setValue] = useState(props.value?.split('') || []);
  const inputRefs: (HTMLInputElement | null)[] = [];

  useEffect(() => {
    setValue(props.value?.split('') || []);
  }, [props.value]);

  useEffect(() => {
    if (props.onCodeChange) {
      props.onCodeChange(value.join(''));
    }
  }, [value]);

  const focus = (index: number) => {
    inputRefs[index]?.focus();
  };

  const handleChanged = (index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    if (value.length <= codeLength) {
      setValue((old) => {
        const newVal = [...old];
        newVal[index] = inputValue;
        return newVal;
      });
    }
    if (inputValue && index <= codeLength - 1) {
      focus(index + 1);
    } else if (index !== 0) {
      focus(index - 1);
    }
  };

  return (
    <>
      {/* <S.Wrapper onClick={() => focus(value.length)}> */}
      <S.Wrapper>
        {loading ? (
          <S.PreloadContainer>
            <Preload />
          </S.PreloadContainer>
        ) : (
          <form autoComplete='off'>
            {new Array(codeLength).fill(0).map((item, index) => (
              <S.Input
                style={props.style ? { ...props.style } : {}}
                secure={secure}
                type={secure ? 'text' : 'number'}
                key={`otp-input-${+index}`}
                placeholder='0'
                autoFocus={index === 0}
                maxLength={1}
                codeLength={codeLength}
                value={value[index] || ''}
                ref={(ref) => {
                  inputRefs[index] = ref;
                }}
                autoComplete='one-time-code'
                onChange={handleChanged(index)}
                // onKeyDown={(e) => {
                //   if (e.keyCode === BACKSPACE_KEY_CODE) {
                //     // setValue(value.slice(0, value.length - 1));
                //     focus(value.length - 1);
                //   }
                // }}
              />
            ))}
          </form>
        )}
      </S.Wrapper>
      {props.errorMessage && <S.errorText>{props.errorMessage}</S.errorText>}
    </>
  );
};
