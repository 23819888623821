import React from 'react';
import { Button } from '../../../../components/Button';
import { FileUpload } from '../../../../components/FileUpload';
import { FormTitle } from '../../../../components/FormTitle';
import { Link } from '../../../../components/Link';
import { ContentContainer } from '../../../../styles/wrappers';

import * as S from './styles';

export interface StepFifthProps {
  /**
   * handler on click continue
   */
  onContinue?: () => void,
}

export const StepFifth: React.FC<StepFifthProps> = ({ onContinue = () => {} }) => (
  <>
    <FormTitle
      title="Upload Picture"
      description="Please upload picture of yourself holding your valid ID Card."
    />
    <S.FileWrapper>
      <FileUpload />
    </S.FileWrapper>
    <ContentContainer flex={1} justifyContent="space-between" alignItems="center">
      <Link text="Save and continue later" />
      <Button onClick={onContinue} label="Upload" />
    </ContentContainer>
  </>
);
