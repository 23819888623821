import styled, { css } from "styled-components";
import { breakpoints, fonts } from "../../styles/variables";


const ClickPanel = styled.div<{ backGroundColor: string }>`
  display: flex;
padding: 12px;
 
justify-content: space-between;
align-items: center;
height:60px;
border-radius: 5px;
border: 1px solid  #DBDFE4;
background:  #EEF7FF;
${(props) => props.backGroundColor && css`
background: ${props.backGroundColor};
cursor: pointer;
`};
font-family: ${fonts.inter.regular};
margin-bottom:15px;
@media (max-width: ${breakpoints.sm}px) {
  display:block;
  height:100%;
};
`

const RightAction = styled.div`
display: flex;
align-items: center;
gap: 8px;
white-space:nowrap;

};
`


const PanelTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  /* width: 70%; */
  svg {
    height: 24px;
    width: 100%;
    max-height: 24px;
    max-width: 24px;
    /* flex:1 */
  }
  @media (max-width: ${breakpoints.sm}px) {
  display:block;
  height:100%;
};
`

export { ClickPanel, PanelTitle, RightAction }