import React from 'react';
import { PageTitle } from '../../styles/wrappers';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';

// import * as S from './styles';

export interface SavedBeneficiariesTransferProps {
  /**
   * Example page property
   */
  example?: string,
}

export const SavedBeneficiariesTransfer: React.FC<SavedBeneficiariesTransferProps> = () => (
  <div>
    <PageTitle.Wrapper>
      <Text color={colors.black._100} size={4} bold>Saved Beneficiaries</Text>
    </PageTitle.Wrapper>
  </div>
);
