/* eslint-disable max-len */

/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { Text } from '../../components/Text';

import * as S from './styles';

import { colors } from '../../styles/variables';

import { PaymentHistory, TransactionReport } from './TransactionReport';

import AccessControl, { EPermissions } from '../../components/AccessControl';

import { BulkPayment } from './BulkPayment';

export interface ViewComponentProps {
  Individualpayment: any;

  Bulkpayment: any;
}

export const TransactionNReport: React.FC = () => {
  const { state } = useLocation<{ tab: string }>();

  const [CurrentUserView, ChangeuserView] = useState<string>(
    'Individualpayment'
  );

  const ChangeUserDisplay = (userView: string) => {
    ChangeuserView(userView);
  };

  const UserViews: ViewComponentProps = {
    Individualpayment: <TransactionReport />,

    Bulkpayment: <BulkPayment />,
  };

  const CurrentRenderView = CurrentUserView as keyof typeof UserViews;

  useEffect(() => {
    const view =
      state?.tab && state?.tab === 'UserReport'
        ? 'Individualpayment'
        : 'Individualpayment';
    ChangeuserView(view);
  }, []);

  return (
    <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]} isPage>
      <S.TopWrapper>
        <S.TypeContainer>
          <Text color='#333754' size={5} bold>
            {' '}
            PAYMENT TYPE
          </Text>

          <S.TypeTabs>
            <S.TableAlign2>
              <div>
                <S.Tab1
                  onClick={() => {
                    ChangeUserDisplay('Individualpayment');
                  }}
                >
                  <Text
                    tag='span'
                    size={4}
                    background={
                      CurrentUserView === 'Individualpayment'
                        ? '#333754'
                        : 'unset'
                    }
                    color={
                      CurrentUserView === 'Individualpayment'
                        ? colors.white._100
                        : '#59595E'
                    }
                  >
                    Individual payment
                  </Text>
                </S.Tab1>
              </div>
              <div>
                <S.MarginLeftComp1
                  onClick={() => {
                    ChangeUserDisplay('Bulkpayment');
                  }}
                >
                  <Text
                    tag='span'
                    size={4}
                    background={
                      CurrentUserView === 'Bulkpayment' ? '#333754' : 'unset'
                    }
                    color={
                      CurrentUserView === 'Bulkpayment'
                        ? colors.white._100
                        : '#59595E'
                    }
                  >
                    Bulk payment
                  </Text>
                </S.MarginLeftComp1>
              </div>
            </S.TableAlign2>
          </S.TypeTabs>
        </S.TypeContainer>
      </S.TopWrapper>

      {UserViews[CurrentRenderView]}
    </AccessControl>
  );
};
