import styled from "styled-components";
import { fonts, transition } from '../../../styles/variables';
import { crossBrowserTransition, ptr } from "../../../styles/utils";
import { colors } from "../../../styles/variables";

const Container = styled.div`
  margin-bottom: ${ptr(100)};
  > button {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin-top: ${ptr(32)};
  margin-bottom: ${ptr(70.5)};

  > span {
    display: block;
    margin-bottom: ${ptr(32)};
  }
`;

const HelpText = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(12)};
`;

const File = styled.div`
  background-color: ${colors.black._40};
  border: ${ptr(1)} solid ${colors.black._5};
  box-sizing: border-box;
  border-radius: ${ptr(4)};
  width: ${ptr(192)};
  height: ${ptr(235)};
  // margin-bottom: ${ptr(82)};
  background-size: cover;
 position: relative;

  // > span {
  //   position: absolute;
  //   margin-top: ${ptr(140)};
  // }
`;



const FullScreen = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  width: ${ptr(192)};

  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  justify-content: space-between;
`;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};
  flex-wrap: wrap;
  
`;


const InnerItem = styled.div`
width:100%;
display:flex; 
margin-bottom: ${ptr(-12)};
 
gap:10px;
`;

const FileOuter = styled.div`
  border: 1px solid none;
  padding: 12px;
  width: 216px;
  display: flex;
 
  justify-content: space-around;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const ContainerB = styled.div`
margin-top:-20px;
  padding: 10px 10px 10px 20px; 
  /* width:50vw; */
`;

const Image = styled.img`
  border-radius: 2px;
  background: var(
    --vfd-gradient,
    linear-gradient(
      90deg,
      #a54189 0%,
      #ee3a3e 45.31%,
      #f79431 99.99%,
      rgba(196, 196, 196, 0) 100%
    )
  );
  width: 100%;
  height: 14px;
  flex-shrink: 0;
  margin-top: -6px;
`;

const TopText = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 17px 29px 16px 29px;
`;



const Upload = styled.div`
  padding: 0px;

  > :nth-child(3) {
    margin-top: 16px;
  }
`;

const UploadText = styled.div`
  margin-bottom: 14px;
`;

const FileTexts = styled.div`
margin-top:12px;
`;




const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(18)}; 
  height: ${ptr(18)};
  border-radius: ${ptr(12)};
 border: ${ptr(1)} #B10180 solid;
 

  
 ${crossBrowserTransition(`${transition.slow}`)}
  
`;
const InsideCircle = styled.div`
display: flex;
justify-content: center;
align-items: center;
  width: ${ptr(10)};
  height: ${ptr(10)};
  border-radius: ${ptr(9)};
background-color: #B10180
 
`;


export { Container, Circle, InsideCircle, Wrapper, Image, FileTexts, ContainerB, FileOuter, HelpText, File, FullScreen, ItemRow, InnerItem, ButtonGroup };