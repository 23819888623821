import React, { useState } from 'react';
import { Button } from '../../../../components/Button';
import { FormTitle } from '../../../../components/FormTitle';
import { Link } from '../../../../components/Link';
import { TableGenerator } from '../../../../components/TableGenerator';
import { TextInput } from '../../../../components/TextInput';
import { ContentContainer } from '../../../../styles/wrappers';

import * as S from './styles';

export interface StepThirdProps {
  /**
   * handler on click continue
   */
  onContinue?: () => void,
  /**
   * type of step
   */
  type?: string,
}

export const StepThird: React.FC<StepThirdProps> = ({ type, onContinue = () => {} }) => {
  const [data, setData] = useState<boolean | null>(null);
  const [bvn, setBvn] = useState<string>('');
  if (data) {
    return (
      <>
        <FormTitle
          title="Check the Data"
          description="Please check your data. If everything is correct, then click Continue."
        />
        <S.InputWrapper>
          <TableGenerator data={[
            {
              title: 'Phone Number',
              description: '+42 (42) *** *** 592',
            },
            {
              title: 'Email',
              description: 'h***o@company.com',
            },
          ]}
          />
        </S.InputWrapper>
        <ContentContainer flex={1} justifyContent="space-between" alignItems="center">
          <Link text="Save and continue later" />
          <Button onClick={onContinue} label="Continue" />
        </ContentContainer>
      </>
    );
  }
  if (type === 'bvn') {
    return (
      <>
        <FormTitle
          title="BVN"
          description="Enter your BVN to create account for your business."
        />
        <S.InputWrapper>
          <TextInput
            label="BVN"
            value={bvn}
            onChange={setBvn}
          />
        </S.InputWrapper>
        <ContentContainer flex={1} justifyContent="space-between" alignItems="center">
          <Link text="Save and continue later" />
          <Button onClick={() => setData(true)} label="Add" />
        </ContentContainer>
      </>
    );
  } if (type === 'other') {
    return (
      <>
        <FormTitle
          title="Other Account Number"
          description="Enter other account number to create account for your business."
        />
        <S.InputWrapper>
          <TextInput
            label="Other Account Number"
            value={bvn}
            onChange={setBvn}
          />
        </S.InputWrapper>
        <ContentContainer flex={1} justifyContent="space-between" alignItems="center">
          <Link text="Save and continue later" />
          <Button onClick={() => setData(true)} label="Add" />
        </ContentContainer>
      </>
    );
  }
  return null;
};
