import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationActions } from '../../redux/paginationSettings/actions';
import { IPaginationSettings } from '../../redux/paginationSettings/Ipagination';
import * as PS from './styles';

export interface IPagination {
  totalPages: number;
  currentPage: number;
  limit: number;
  pageItems: number;
  action?: (data: { page: number }) => void;
}
export const PaginationUpdated: React.FC<IPagination> = ({
  pageItems,
  limit,
  currentPage,
  totalPages,
  action,
}) => {
  const [pageList, setPageList] = useState<Array<number>>([]);
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { paginationSettings: IPaginationSettings }) =>
      state.paginationSettings
  );
  useEffect(() => {
    if (totalPages) {
      const pages = [];
      for (let i = 1; i <= totalPages; i += 1) {
        pages.push(i);
      }
      setPageList(pages);
    }
  }, [totalPages]);

  const nextPage = () => {
    if (action && currentPage !== totalPages && totalPages !== 0) {
      const next = currentPage + 1;
      action({ page: next });
      const count = settings.count + 1;
      if (count === 6) {
        dispatch(
          PaginationActions.setPaginationLimit({
            paginationLimit: [
              settings.paginationLimit[0] + 5,
              settings.paginationLimit[1] + 5,
            ],
            count: 1,
          })
        );
      } else {
        // setSettings({ ...settings, count });
        dispatch(PaginationActions.setPaginationLimit({ ...settings, count }));
      }
    }
  };
  const prevPage = () => {
    if (action && currentPage === 2) {
      action({ page: 1 });
      dispatch(PaginationActions.reset());
    } else if (action && currentPage !== 1) {
      const prev = currentPage - 1;
      action({ page: prev });
      const count = settings.count - 1;
      if (count === 0) {
        dispatch(
          PaginationActions.setPaginationLimit({
            paginationLimit: [
              settings.paginationLimit[0] - 5,
              settings.paginationLimit[1] - 5,
            ],
            count: 5,
          })
        );
      } else {
        dispatch(PaginationActions.setPaginationLimit({ ...settings, count }));
      }
    }
  };

  const goto = async (page: number) => {
    if (action) {
      action({ page });
      const diff = page - currentPage;
      let count = 0;
      count = settings.count + diff;
      dispatch(PaginationActions.setPaginationLimit({ ...settings, count }));
    }
  };
  return (
    <PS.TableFooter>
      <div style={{ whiteSpace: 'nowrap' }}>
        <p style={{ fontSize: '14px' }}>
          Page{' '}
          {/* {pageItems < limit
            ? (currentPage - 1) * limit + pageItems
            : limit * currentPage} */}
          {currentPage} of{' '}
          {currentPage === totalPages && pageItems < limit
            ? (currentPage - 1) * limit + pageItems
            : totalPages * limit}{' '}
        </p>
      </div>

      <PS.Pagination>
        <PS.PaginationButton disabled={currentPage === 1} onClick={prevPage}>
          Previous
        </PS.PaginationButton>

        <PS.PaginationButton
          disabled={currentPage === totalPages}
          onClick={nextPage}
        >
          Next
        </PS.PaginationButton>
      </PS.Pagination>
    </PS.TableFooter>
  );
};
