import styled, { css } from 'styled-components';
import { colors, fonts, transition } from '../../styles/variables';

type PaginationProps = {
  active?: boolean
  disabled?: boolean
};

const TableFooter = styled.div` 
display: flex;
flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.125rem;
  padding-right: 1.25rem;
  height: 5.75rem;
  font-family: ${fonts.inter.regular};
`;

const Pagination = styled.ul`
 list-style: none;
  display: flex;
  gap:12px;
  font-family: ${fonts.inter.regular};
`;

const PaginationButton = styled.li<PaginationProps>`
  /* height: 1.875rem; */
  text-align: center;
  background-color: ${colors.white._100};
  border: 1px solid ${colors.black._40};

  
  padding: 0.6125rem 0.75rem;
  color: ${colors.black._100};
  cursor: pointer;
  color: var(--gray-700, #344054);
font-size: 14px; 
font-weight: 500; 
  transition: ${transition.slow};



  border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--white, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);


  ${(props) => props.disabled && css`
  background-color: ${colors.black._5};
  cursor:default;
  `}
  :hover {
  ${(props) => !props.disabled && css`
  transform: scale(0.95);
  `}
}
// :first-child {
//   border-radius: 4px 0px 0px 4px;
//   color: #4b4b4b;
// }
// :last-child {
//   border-radius: 0px 4px 4px 0px;
//   color: #4b4b4b;
// }
  ${(props) => props.active && css`
   background-color: ${colors.pink._100};
   border: 1px solid ${colors.pink._20};
   color: ${colors.white._100};
  `}
`;

export {
  TableFooter,
  Pagination,
  PaginationButton,
};
