import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import {
  validateObject,
  ValidateResult,
} from '../../../../../utils/validation';
import { success } from '../../../../../components/Toasts';
import { FormTitle } from '../../../../../components/FormTitle';
import { FormButton, FormInput } from '../../../../../styles/wrappers';
import { TextInput } from '../../../../../components/TextInput';
import { Text } from '../../../../../components/Text';
import { colors } from '../../../../../styles/variables';
import { Button } from '../../../../../components/Button';
import ConfigPassword from '../../../../../configs/password';
import { Icon } from '../../../../../components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import onboardingService from '../../../../../services/internal/onboarding/onboarding.service';
import { actions } from '../../../config';
import { OnboardingActions } from '../../../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../../../redux/onboarding/IOnboarding';
import { InfoConponent } from '../../../../../components/Info';
import { OnboardingCard } from '../../../../../components/OnboardingCard';
import { Title } from '../../../../Onboarding/components/Title';

const SCHEME_VALIDATION_PASSWORD = {
  minimumCharacters: (value: string) =>
    value.length >= ConfigPassword.minLength,
  uppercase: (value: string) => ConfigPassword.uppercase.test(value),
  lowercase: (value: string) => ConfigPassword.lowercase.test(value),
  special: (value: string) => ConfigPassword.special.test(value),
};

export const CreatePasswordForm: React.FC = () => {
  const dispatch = useDispatch();

  const email = useSelector(
    (state: { onboardingStack: any }) =>
      state.onboardingStack.data.credentials.email as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState<ValidateResult | undefined>(
    undefined
  );

  const route = useHistory();

  const onCreateNewPassword = async () => {
    try {
      setLoading(true);
      const response = await onboardingService.submitAuth({
        auth: {
          password,
          email,
        },
        reference,
      });

      route.push(actions[response.actionRequired]);
    } catch (err) {
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')],
          {
            reference,
          }
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const validationResult = validateObject(
      {
        minimumCharacters: password,
        uppercase: password,
        lowercase: password,
        special: password,
      },
      SCHEME_VALIDATION_PASSWORD
    );

    setValidation({
      ...validationResult,
      isValid: validationResult.isValid,
    });
  }, [password, repeatPassword]);

  return (
    <S.Container>
      <Title
        title='Set up Pasword'
        subTitle='kindly create your login password'
        color='#394455'
      />

      <S.InfoContainer>
        <InfoConponent text='The information provided here will become the super admin who will be able to create, disable and edit user roles on account.' />
      </S.InfoContainer>

      <OnboardingCard>
        <FormInput.Wrapper>
          <TextInput
            label='Create password'
            type='password'
            value={password}
            maxLength={ConfigPassword.maxLength}
            onChange={setPassword}
            placeholder='Create password'
            // valid={validation?.isValid}
          />
        </FormInput.Wrapper>
        <S.PasswordDescriptionWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {validation?.data.minimumCharacters?.isValid ? (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='purple-check-mark' />
                  At least 8 characters.
                </Text>
              ) : (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='empty-check-mark' />
                  At least 8 characters.
                </Text>
              )}
            </>
          </S.PasswordDescriptionItemWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {validation?.data.uppercase?.isValid ? (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='purple-check-mark' />
                  At least one uppercase is entered.
                </Text>
              ) : (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='empty-check-mark' />
                  At least one uppercase is entered.
                </Text>
              )}
            </>
          </S.PasswordDescriptionItemWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {validation?.data.lowercase?.isValid ? (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='purple-check-mark' />
                  At least one lowercase is entered.
                </Text>
              ) : (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='empty-check-mark' />
                  At least one lowercase is entered.
                </Text>
              )}
            </>
          </S.PasswordDescriptionItemWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {validation?.data.special?.isValid ? (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='purple-check-mark' />
                  At least one special character is entered.
                </Text>
              ) : (
                <Text
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                  color='#12000D'
                  size={7}
                >
                  <Icon name='empty-check-mark' />
                  At least one special character is entered.
                </Text>
              )}
            </>
          </S.PasswordDescriptionItemWrapper>
        </S.PasswordDescriptionWrapper>
        <FormInput.Wrapper>
          <TextInput
            label='Confirm password'
            type='password'
            value={repeatPassword}
            maxLength={ConfigPassword.maxLength}
            onChange={setRepeatPassword}
            placeholder='Confirm password'
            // valid={validation?.isValid && password === repeatPassword}
          />
        </FormInput.Wrapper>

        <S.ButtonGroup>
          <div>
            <Button
              label='Continue'
              loading={loading}
              style={{ width: 304 }}
              disabled={
                !validation?.isValid || password !== repeatPassword || loading
              }
              onClick={onCreateNewPassword}
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
