import styled, { css } from 'styled-components';

import { CheckAreaProps } from '.';
import { colors, fonts, transition } from '../../styles/variables';
import {
  crossBrowserInlineFlex, crossBrowserTransition, ptr, crossBrowserDisableSelection,
} from '../../styles/utils';

const Wrapper = styled.div<CheckAreaProps>`
  ${crossBrowserInlineFlex()}
  ${crossBrowserDisableSelection()};

  align-items: center;
  border-radius: ${ptr(30)};
  padding: ${ptr(2)};
  box-sizing: border-box;
  cursor: pointer;
  padding: ${ptr(12)};
  float: left;

  ${(props) => props.theme === 'dark' && css`
    border: ${ptr(1)} ${colors.black._40} solid;
  `}

  ${(props) => props.theme === 'no' && css`
  border: none;
  padding: ${ptr(0)};
`}
  
  ${(props) => props.theme === 'light' && css`
    border: ${ptr(1)} ${colors.white._100} solid;
  `}
  
  ${crossBrowserTransition(`${transition.slow}`)}

  ${(props) => props.checked && css`
    ${props.theme === 'dark' && `border: ${ptr(1)} ${colors.pink._100} solid;`}
    ${props.theme === 'light' && `border: ${ptr(1)} ${colors.white._100} solid;`}
    ${props.theme === 'no' && `border: ${ptr(1)} ${colors.white._100} solid;`}
  `}
  
  ${(props) => props.disabled && css`
    cursor: default;
    ${props.theme === 'dark' && css`
      ${!props.checked && `background-color: ${colors.pink._20}`}
      ${props.checked && `background-color: ${colors.black._10}`}
    `}

    ${props.theme === 'no' && css`
    ${!props.checked && `background-color: ${colors.pink._20}`}
    ${props.checked && `background-color: ${colors.black._10}`}
  `}
    
    ${props.theme === 'light' && css`
      ${!props.checked && 'opacity: 0.6'}
      ${props.checked && 'opacity: 0.6'}
    `}
  `}
`;

const Circle = styled.div<CheckAreaProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(22)}; 
  height: ${ptr(22)};
  border-radius: ${ptr(12)};

 

 

  ${crossBrowserTransition(`${transition.slow}`)}
  
   ${(props) => css`
    ${props.theme === 'dark' && `border: ${ptr(1)} ${colors.black._40} solid;`}
    ${props.theme === 'no' && `border: ${ptr(1)} ${colors.black._40} solid;`}
    ${props.theme === 'light' && `border: ${ptr(1)} ${colors.white._100} solid;`}
  `}
  
  ${(props) => props.checked && css`
    ${props.theme === 'dark' && `border: ${ptr(1)} ${colors.pink._100} solid;`}
    ${props.theme === 'no' && `border: ${ptr(1)} ${colors.pink._100} solid;`}
    ${props.theme === 'light' && `border: ${ptr(1)} ${colors.white._100} solid;`}
  `}
`;

const InsideCircle = styled.div<CheckAreaProps>`
  width: ${ptr(18)};
  height: ${ptr(18)};
  border-radius: ${ptr(9)};

  ${(props) => css`
    ${props.theme === 'dark' && `background-color: ${colors.pink._100};`}
    ${props.theme === 'no' && `background-color: ${colors.pink._100};`}
    ${props.theme === 'light' && `background-color: ${colors.white._100};`}
  `}
`;

const ValueText = styled.span<CheckAreaProps>`
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(16)};
  line-height: 140%;
  margin-left: ${ptr(12)};
  color: ${colors.black._100};

  ${(props) => css`
    ${props.theme === 'dark' && `color: ${colors.black._100};`}
    ${props.theme === 'no' && `color: ${colors.black._100};`}
    ${props.theme === 'light' && `color: ${colors.white._100};`}
    
    ${props.theme === 'dark' && props.checked && `color: ${colors.pink._100};`}
  `}
`;

export {
  Wrapper,
  Circle,
  InsideCircle,
  ValueText,
};
