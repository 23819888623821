import React, { CSSProperties } from 'react';

import { Text } from '../Text';
import * as S from './styles';

export interface InfoTabProps {
  text: string;
  backgroundColor?: string;
  color?: string;
  style?: CSSProperties;
}

export const InfoTab: React.FC<InfoTabProps> = ({
  text,
  backgroundColor,
  color,
  style,
  ...props
}) => (
  <S.InfoTab
    style={style ? { ...style } : {}}
    backgroundColor={backgroundColor}
  >
    <p
      style={{
        fontWeight: 'bold',
        color: color || '#946200',
        fontSize: 20,
        marginBottom: 0,
      }}
    >
      &#9432;
    </p>
    <Text size={6} style={{ fontWeight: 600 }} color={color || '#946200'}>
      {text}
    </Text>
  </S.InfoTab>
);
