import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackBar } from '../../../components/BackBar';
import { EventPanel } from '../../../components/EventPanel';
import LimitInitiationModal from '../../../components/LimitModals';
import { Modal as LoadingModal } from '../../../components/LoadingModal';
import { Text } from '../../../components/Text';
import { failed } from '../../../components/Toasts';
import { PaymentActions } from '../../../redux/payment/actions';
import { PaymentInterface } from '../../../redux/payment/IPayment';
import paymentsService from '../../../services/internal/payments/payments.service';
import * as S from './styles';
import AccessControl, { EPermissions } from '../../../components/AccessControl';

const Requests: React.FC = () => {
  const dispatch = useDispatch();

  const paymentsAccount = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.paymentAccounts
  );
  const limitInfo = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.limitInfo
  );

  const [loading, setLoading] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);

  const toggleLimitModal = () => setShowLimitModal((prev) => !prev);

  const limitRequest = async () => {
    try {
      setLoading(true);
      if (!paymentsAccount?.length) {
        const result = await paymentsService.getPaymentAccounts();
        dispatch(PaymentActions.savePaymentAccounts(result));
        dispatch(
          PaymentActions.getLimitInfo(
            result[0].nuban,
            failed,
            setLoading,
            toggleLimitModal
          )
        );
      } else {
        dispatch(
          PaymentActions.getLimitInfo(
            paymentsAccount[0].nuban,
            failed,
            setLoading,
            toggleLimitModal
          )
        );
      }
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error retrieving limit information');
      setShowLimitModal(false);
      setLoading(false);
    }
  };

  return (
    <AccessControl requiredPermission={[EPermissions.CREATE_OPERATOR]} isPage>
      <S.BackBarContainer>
        <BackBar name="Requests" />
      </S.BackBarContainer>
      <S.Container>
        <S.TextContainer>
          <Text bold size={4} color="#2C2A3B">
            Select request type
          </Text>
        </S.TextContainer>
        <S.Body>
          <EventPanel
            showIcon={true}
            icon={{ name: 'trending-up', color: '#394455' }}
            onClick={() => limitRequest()}
            title="Transaction Limit increase"
            description="Request for an increase in your daily transaction amount."
            iconBg="#EFF0F6"
            arrowColor="#394455"
          />
        </S.Body>
        {!loading && (
          <LimitInitiationModal
            show={showLimitModal}
            toggle={toggleLimitModal}
            fromRoute={location.pathname}
            usedLimit={
              limitInfo?.dailyWithdrawLimitCap &&
              limitInfo.dailyWithdrawLimitBalance
                ? limitInfo?.dailyWithdrawLimitCap -
                  limitInfo?.dailyWithdrawLimitBalance
                : 0
            }
            availableLimit={limitInfo?.dailyWithdrawLimitBalance ?? 0}
            totalLimit={limitInfo?.dailyWithdrawLimitCap ?? 0}
            showContinueTransferButton={false}
          />
        )}

        <LoadingModal visible={loading} title="Please wait" />
      </S.Container>
    </AccessControl>
  );
};

export default Requests;
