import React from 'react';

import * as S from './styles';
import { TextInput } from '../TextInput';
import { Link } from '../Link';
import { Text } from '../Text';
import { colors } from '../../styles/variables';

interface Beneficiary {
  /**
   * Beneficiary name
   */
  name?: string;
  /**
   * Beneficiary account number
   */
  accountNumber?: string;
  /**
   * Beneficiary bank name
   */
  bankName?: string;
}

export interface SavedBeneficiariesProps {
  /**
   * Beneficiaries
   */
  data: Array<Beneficiary>;
}

export const SavedBeneficiaries: React.FC<SavedBeneficiariesProps> = ({ ...props }) => (
  <S.Wrapper>
    <S.HeaderWrapper>
      <Text color={colors.black._100} bold>Saved Beneficiaries</Text>
      <S.HeaderAddWrapper>
        <Link icon="plus-in-circle" text="Add Another Saved Beneficiary" />
      </S.HeaderAddWrapper>
    </S.HeaderWrapper>

    {props.data?.map((beneficiaryItem) => (
      <S.BeneficiaryWrapper>
        <S.DescriptionWrapper>
          <S.NameWrapper>
            <Text color={colors.black._100}>{beneficiaryItem.name}</Text>
          </S.NameWrapper>
          <S.AccountWrapper>
            <S.AccountText>
              <Text color={colors.black._60} size={6}>{`Acc. ${beneficiaryItem.accountNumber}`}</Text>
            </S.AccountText>
            <S.AccountSeparator />
            <S.AccountText>
              <Text color={colors.black._60} size={6}>{beneficiaryItem.bankName}</Text>
            </S.AccountText>
          </S.AccountWrapper>
        </S.DescriptionWrapper>
        <S.InputIconWrapper>
          <S.InputWrapper>
            <TextInput label="Amount, ₦" symbol="₦" />
          </S.InputWrapper>
          <S.RemoveButtonWrapper>
            <Link icon="trash-basket" />
          </S.RemoveButtonWrapper>
        </S.InputIconWrapper>
      </S.BeneficiaryWrapper>
    ))}
  </S.Wrapper>
);
