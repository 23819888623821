import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';

import * as S from './styles';
import { TextInput } from '../../../../components/TextInput';

export interface SimpleFormProps {
  nin?: string,
  bvn: string,
  email: string,
  index?: number,
  removeFromIndex?: number,
  emailIsValid?: boolean,
  bvnIsValid?: boolean,
  ninIsValid?: boolean,
  remove?: ((index: number) => void),
  onChange?: ((index: number, property: string, value: string) => void),
  title?: string,
}

const numberMaskOptions = { 
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const SimpleForm: React.FC<SimpleFormProps> = ({
  nin,
  bvn,
  email,
  index,
  removeFromIndex = 0,
  emailIsValid,
  title,
  remove = () => {},
  onChange = () => {},
  bvnIsValid,
  ninIsValid,
}) => (
  <S.ItemContainer>
    <S.HeaderRow>
      <Text bold color={colors.black._100}>{title}</Text>
      {index && index > removeFromIndex ? (
        <S.Remove onClick={() => remove(index)}>
          <Icon name="trash" color={colors.pink._100} />
        </S.Remove>
      ) : null}
    </S.HeaderRow>
    <S.ItemRow>
      <TextInput label="NIN" type="currency" valid={ninIsValid} mask={createNumberMask(numberMaskOptions)} value={nin} onChange={(value) => onChange(index as number, 'nin', value)} />
      <TextInput label="BVN" type="currency" valid={bvnIsValid} mask={createNumberMask(numberMaskOptions)} value={bvn} onChange={(value) => onChange(index as number, 'bvn', value)} />
    </S.ItemRow>
    <TextInput label="Email" value={email} onChange={(value) => onChange(index as number, 'email', value)} valid={emailIsValid} />
  </S.ItemContainer>
);
