import styled from 'styled-components';

import { ptr } from '../../../../styles/utils';

const FileWrapper = styled.div`
  margin-bottom: ${ptr(40)};
`;

export {
  FileWrapper,
};
