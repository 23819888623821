/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef, FC } from 'react';
/* eslint-disable max-len */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { handleDownloadFile, onFileUpload } from './action';
import { BankNames } from '../OtherBankTransfer';
import beneficiaryService from '../../services/internal/beneficiary/beneficiary.service';
import debug from '../../services/internal/debbug.service';
import { colors } from '../../styles/variables';
import { AddNewBeneficiaryModal } from '../../components/AddNewBeneficiaryModal';
import { Button } from '../../components/Button';
import { CheckBox } from '../../components/CheckBox';
import { EditBeneficiaryModal } from '../../components/EditBeneficiaryModal';
import { Icon } from '../../components/Icon';
import { Preload } from '../../components/Preload';
import { IBeneficiary } from '../../components/SelectBeneficiaryType';
import { Table, TableFooterProps } from '../../components/Table';
import { Text } from '../../components/Text';
import { UploadFileModal } from '../../components/UploadFileModal';
import * as S from './styles';
import { failed, success } from '../../components/Toasts';
import { Splash } from '../Splash/index';
import paymentsService from '../../services/internal/payments/payments.service';
import BeneficaryActions from '../../redux/beneficiary.management/action';
import { IPagination, Pagination } from '../../components/Pagination';
import { IGetQueries, IMeta } from '../../interface';
import getQueryString from '../../utils/getQueryString';

export interface IBeneficiaries {
  label?: string;
  name: string;
  tags: {
    id: string;
    name: string;
    slug: string;
  };
  bank: {
    nipCode: string;
    name: string;
  };
  company?: {
    id: string;
    name: string;
  };
  id: number;
  nuban: string;
  lastAmountSent?: number;
  check?: boolean;
  loading?: boolean;
  defaultAmount?: string;
  comment?: string;
}

interface ISort {
  type: string;
  order: 'ASC' | 'DESC' | 'default';
}

const BENEFICIARY_NAME = beneficiaryService.settings.sorting.beneficiaryName;
const COMPANY = beneficiaryService.settings.sorting.company;
const BANK_NAME = beneficiaryService.settings.sorting.bankName;
const ACCOUNT_NUMBER = beneficiaryService.settings.sorting.accountNumber;
const LAST_AMOUNT_SENT = beneficiaryService.settings.sorting.lastAmountSent;

export const BeneficiariesManagementView: FC<{
  isModal?: boolean;
  selectBeneficiaries?: (item: IBeneficiaries[]) => void;
}> = ({ isModal, selectBeneficiaries }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File>();
  const [beneficiaries, setBeneficiaries] = useState<Array<IBeneficiaries>>([]);
  // const [beneficiaryViews, setBeneficiaryViews] = useState<Array<IBeneficiaries>>([]);
  const [search, setSearch] = useState<string>('');
  const [pageLoading, setPageLoading] = useState(true);
  const [bottomLoading] = useState<boolean>(false);
  const [preload, setPreload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // const [page, setPage] = useState<number>(1);
  // const [lastPage, setLastPage] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false);

  const [bankNames, setBankNames] = useState<Array<BankNames> | undefined>([]);
  const [beneficiariesTypes, setBeneficiariesTypes] = useState<
    Array<IBeneficiary> | undefined
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectBeneficiariesTypes, setSelectBeneficiariesTypes] = useState<
    Array<IBeneficiary>
  >([]);

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [addBenefModalVisible, setAddBenefModalVisible] = useState<boolean>(
    false
  );
  const [editBenefModal, setEditBenefModal] = useState<
    IBeneficiaries | undefined
  >();
  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>({
    limit: 50,
    currentPage: 1,
    pageItems: 1,
    totalPages: 1,
  });
  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({
    limit: 50,
    page: 1,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [sort, setSort] = useState<Array<ISort>>([
    {
      type: BENEFICIARY_NAME,
      order: 'default',
    },
    {
      type: COMPANY,
      order: 'default',
    },
    {
      type: BANK_NAME,
      order: 'default',
    },
    {
      type: ACCOUNT_NUMBER,
      order: 'default',
    },
    {
      type: LAST_AMOUNT_SENT,
      order: 'default',
    },
  ]);

  const ref = useRef<any>();

  const setExistingTags = (tag: any) => {
    beneficiariesTypes?.forEach((type) => {
      if (tag.id === type.id) {
        setSelectBeneficiariesTypes((prev) => [...prev, type]);
      }
    });
  };
  useEffect(() => {
    if (editBenefModal) {
      const { tags } = editBenefModal;
      if (Array.isArray(tags)) {
        tags.forEach((tag) => {
          setExistingTags(tag);
        });
      }
    }
  }, [editBenefModal]);

  const getBeneficiaries = async (
    queriesData?: IGetQueries,
    $preload?: boolean
  ): Promise<void> => {
    setEmpty(false);
    if ($preload) {
      setPreload(true);
    } else {
      setLoading(true);
    }
    let queries =
      queriesData && queriesData.search
        ? { ...queriesData }
        : {
            limit: 50,
            page: 1,
            ...additionalQueries,
            ...queriesData,
          };
    if (!queriesData?.search) {
      setSearch('');
      delete queries.search;
    }
    if (!queriesData) queries = { limit: 50, page: 1 };
    try {
      const result = await beneficiaryService.getBeneficiary(
        getQueryString(queries)
      );
      setPagination({
        limit: result.meta.limit,
        currentPage: result.meta.page,
        pageItems: result.meta.pageCount,
        totalPages: result.meta.totalPages,
      });
      setAddtionalQueries(queries);
      // setLastPage(result.last);
      setBeneficiaries(
        result.data.map((el: IBeneficiaries) => ({ ...el, check: false }))
      );
      setLoading(false);
      setPreload(false);
      if (result.data.length === 0) {
        setEmpty(true);
      }
    } catch (err) {
      setLoading(false);
      setPreload(false);
      failed('Failed', err.message || 'Error retrieving beneficiaries');
    }
  };

  const onChangeValue = (
    arr: Array<any>,
    index: number,
    key: string,
    value: any,
    set: (value: any) => void
  ) => {
    const newArr = [];
    for (let i = 0; i < arr.length; i += 1) {
      if (index === i) {
        newArr.push({ ...arr[i], [key]: value });
      } else {
        newArr.push(arr[i]);
      }
    }
    set(newArr);
  };

  const loadingProps = () => {
    if (bottomLoading || preload || loading) {
      return { disabled: true };
    }
    return null;
  };
  /**
   * Open window choose dialog.
   */
  const onAddFile = async () => {
    setUploadFileModal(false);
    if (file) {
      const ibeneficiaries = await onFileUpload(file);
      if (ibeneficiaries && ibeneficiaries.length) {
        setBeneficiaries((mBeneficiaries) => [
          ...ibeneficiaries.map((el: IBeneficiaries) => ({
            ...el,
            check: false,
          })),
          ...mBeneficiaries,
        ]);
        getBeneficiaries();
        success('Sucess', 'Beneficiaries Uploaded.');
      }
    }
  };

  const checkLoadings = () => {
    if (bottomLoading || loading || preload) {
      return true;
    }
    return false;
  };

  /*   const handleContainerOnBottom = () => {
      if (!checkLoadings()) {
        setPage((old) => old + 1);
      }
    }; */

  const changeOrder = (key: string) => {
    const arr: Array<ISort> = [];
    for (let i = 0; i < sort.length; i += 1) {
      if (sort[i].type === key) {
        let newOrder: 'ASC' | 'DESC' | 'default';
        if (sort[i].order === 'ASC') {
          newOrder = 'DESC';
        } else {
          newOrder = 'ASC';
        }
        arr.push({ ...sort[i], order: newOrder });
      } else if (sort[i].order) {
        arr.push({ ...sort[i], order: 'default' });
      } else {
        arr.push(sort[i]);
      }
    }
    setSort(arr);
  };

  const findCurrentSorting = (key: string) => {
    const element = sort.find((item) => item.type === key);
    return element;
  };

  const onAdd = () => {
    if (selectBeneficiaries) {
      selectBeneficiaries(beneficiaries.filter((el) => el.check));
    }
  };

  const onMakeTransfer = () => {
    dispatch(
      BeneficaryActions.makeATransfer(beneficiaries.filter((el) => el.check))
    );
    history.push('/transfers/other-bank');
  };

  const checkFilterValid = () => {
    const $bankNames = bankNames?.filter((el) => el.check);
    const $beneficiariesTypes = beneficiariesTypes?.filter((el) => el.check);
    return !$bankNames?.length && !$beneficiariesTypes?.length;
  };

  const onReset = () => {
    setBeneficiariesTypes(
      beneficiariesTypes?.map((el: IBeneficiary) => ({ ...el, check: false }))
    );
    setBankNames(bankNames?.map((el: BankNames) => ({ ...el, check: false })));
    getBeneficiaries(undefined, true);
  };

  const deleteBulkBeneficiaries = async () => {
    setPreload(true);
    const listOfIds = beneficiaries.filter((el) => el.check).map((el) => el.id);
    try {
      await beneficiaryService.deleteBeneficiaries(listOfIds);
      setBeneficiaries((old) => {
        const res = [];
        for (let i = 0; i < old.length; i += 1) {
          let count = 0;
          for (let ii = 0; ii < listOfIds.length; ii += 1) {
            if (old[i].id === listOfIds[ii]) {
              count += 1;
            }
          }
          if (!count) {
            res.push(old[i]);
          }
        }
        return res;
      });
      setPreload(false);
    } catch (err) {
      setPreload(false);
      debug.error('Error deleting beneficiaries', err);
    }
  };

  const handleApllyFilter = async () => {
    const $bankNames = bankNames?.filter((el) => el.check);
    const $beneficiariesTypes = beneficiariesTypes?.filter((el) => el.check);
    // const filter = applyFilter(
    //   { _bankNames: $bankNames, _beneficiariesTypes: $beneficiariesTypes },
    // );
    let filter = '';
    if ($bankNames?.length && $beneficiariesTypes?.length) {
      filter = `bankCode:${$bankNames
        ?.map((name) => name.nipCode)
        .toString()};beneficiaryTypeId:${$beneficiariesTypes
        ?.map((type) => type.id)
        .toString()}`;
    } else if ($bankNames?.length && !$beneficiariesTypes?.length) {
      filter = `bankCode:${$bankNames?.map((name) => name.nipCode).toString()}`;
    } else if (!$bankNames?.length && $beneficiariesTypes?.length) {
      filter = `tag:${$beneficiariesTypes
        ?.map((type) => type.name)
        .toString()}`;
    }
    await getBeneficiaries({ page: 1, filter }, true);
  };

  useEffect(() => {
    const isSort = sort.filter((el) => el.order !== 'default')[0];
    if (isSort) {
      getBeneficiaries(
        { page: 1, orderBy: `${isSort.type}:${isSort.order}` },
        true
      );
    }
  }, [sort]);

  const taggedBeneficiaries = (mBeneficiaries: Array<IBeneficiaries>) => {
    const beneficiaryTags = mBeneficiaries.reduce(
      (
        taggedBeneficiary: Record<string, Array<IBeneficiaries>>,
        data: IBeneficiaries
      ): Record<string, Array<IBeneficiaries>> => {
        const compileTag = (tagName: string) => {
          data.check = false;
          if (taggedBeneficiary[tagName] !== undefined) {
            taggedBeneficiary[tagName] = [
              ...taggedBeneficiary[tagName],
              ...[data],
            ];
          } else {
            taggedBeneficiary[tagName] = [data];
          }
        };
        if (Array.isArray(data.tags)) {
          data.tags.forEach((value: { name: string }) =>
            compileTag(value.name)
          );
        } else {
          compileTag(data.tags.name);
        }
        return taggedBeneficiary;
      },
      {}
    );

    setBeneficiaries(
      Object.keys(beneficiaryTags)
        .sort()
        .reduce((stack: Array<IBeneficiaries>, key: string) => {
          stack = stack.concat(
            beneficiaryTags[key].map((nData) => ({ ...nData, label: key }))
          );
          return stack;
        }, [])
    );
  };

  const getUniqueTag = (data: any[]) => [
    ...Array.from(new Map(data.map((d) => [d.name, d])).values()),
  ];

  useEffect(() => {
    // get beneficiary
    const loadBeneficiariesAndBanks = async () => {
      try {
        const bankResults = await paymentsService.getBankNames();
        const beneficiaryTypeResults = await beneficiaryService.getBeneficiaryTypes();

        const mBeneficiaries: {
          data: Array<IBeneficiaries>;
          meta: IMeta;
        } = await beneficiaryService.getBeneficiary();
        setPagination({
          limit: mBeneficiaries.meta.limit,
          currentPage: mBeneficiaries.meta.page,
          pageItems: mBeneficiaries.meta.pageCount,
          totalPages: mBeneficiaries.meta.totalPages,
        });
        taggedBeneficiaries(mBeneficiaries.data);

        setBankNames(
          bankResults?.map((el: BankNames) => ({ ...el, check: false }))
        );
        setBeneficiariesTypes(beneficiaryTypeResults);
      } catch (e) {
        failed('Failed', e.message || 'Initiation Error');
      } finally {
        setPageLoading(false);
      }
    };
    loadBeneficiariesAndBanks();
  }, []);

  useEffect(() => {
    let delayDebounceFn: any = null;
    if (search.length) {
      delayDebounceFn = setTimeout(() => {
        getBeneficiaries({ page: 1, search }, true);
      }, 1000);
    } else if (!search.length) {
      delayDebounceFn = setTimeout(() => {
        getBeneficiaries({ page: 1 }, true);
      }, 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (filterOpen) {
      document.addEventListener('mousedown', (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setFilterOpen(false);
        }
      });
    }
    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, [filterOpen]);

  // useEffect(() => {
  //   if (page > 1 && !checkLoadings() && !lastPage) {
  //     getNextPageOfBeneficiaries();
  //   }
  // }, [page]);

  return (
    <>
      {pageLoading ? <Splash fade /> : ''}
      <>
        <S.Wrapper>
          {isModal ? (
            ''
          ) : (
            <S.TemplateWrapper>
              <S.TemplateWrapperButtonContainer>
                <Text color={colors.black._100} bold size={7}>
                  Download Template
                </Text>
                <br />
                <Text color={colors.black._100} size={7}>
                  Download import template and use it populating the list.
                </Text>
              </S.TemplateWrapperButtonContainer>
              <Button
                onClick={handleDownloadFile}
                label="Download"
                size="tiny"
              />
            </S.TemplateWrapper>
          )}
          <S.TopBarWrapper>
            <S.InputBox>
              <Icon name="search" color={colors.black._40} />
              <S.TextField
                value={search}
                onChange={(el) => setSearch(el.target.value)}
                placeholder="Search"
                {...loadingProps()}
              />
            </S.InputBox>
            <S.ButtonsGroup>
              <S.ButtonItem
                onClick={() => setUploadFileModal(true)}
                {...loadingProps()}
              >
                <Icon name="download" color={colors.black._100} />
              </S.ButtonItem>
              <S.Block ref={ref}>
                <S.ButtonItem
                  onClick={() => setFilterOpen(!filterOpen)}
                  {...loadingProps()}
                >
                  <Icon name="filter" color={colors.black._100} />
                </S.ButtonItem>
                <S.Dropdown hidden={!filterOpen}>
                  <S.CloseDropdown onClick={() => setFilterOpen(false)}>
                    <Icon name="cross" color={colors.black._100} />
                  </S.CloseDropdown>
                  <S.DropdownContent>
                    {/* <S.FilterBox>
                      <Text bold color={colors.black._100}>
                        Bank
                      </Text>
                      <S.Content>
                        {bankNames?.map((item, index) => (
                          <CheckBox text={item.name} checked={item.check} onChange={(isChecked) => onChangeValue(bankNames, index, 'check', isChecked, setBankNames)} />
                        ))}
                      </S.Content>
                    </S.FilterBox> */}
                    <S.FilterBox>
                      <Text bold color={colors.black._100}>
                        Beneficiary type
                      </Text>
                      {beneficiariesTypes && beneficiariesTypes?.length > 0 && (
                        <S.Content>
                          {getUniqueTag(beneficiariesTypes).map(
                            (item, index) => (
                              <CheckBox
                                key={index.toString()}
                                text={item.name}
                                checked={item.check}
                                onChange={(isChecked) =>
                                  onChangeValue(
                                    beneficiariesTypes,
                                    index,
                                    'check',
                                    isChecked,
                                    setBeneficiariesTypes
                                  )
                                }
                              />
                            )
                          )}
                        </S.Content>
                      )}
                    </S.FilterBox>
                  </S.DropdownContent>
                  <S.DropdownBottom>
                    <Button
                      theme="light"
                      label="Reset"
                      size="small"
                      onClick={onReset}
                      disabled={checkFilterValid()}
                    />
                    <Button
                      theme="primary"
                      label="Apply filters"
                      size="small"
                      onClick={handleApllyFilter}
                      disabled={checkFilterValid()}
                    />
                  </S.DropdownBottom>
                </S.Dropdown>
              </S.Block>
              <S.ButtonItem
                {...loadingProps()}
                disabled={
                  beneficiaries.filter((el) => el.check).length === 0 ||
                  checkLoadings()
                }
                onClick={deleteBulkBeneficiaries}
              >
                <Icon name="trash" color={colors.black._100} />
              </S.ButtonItem>
              <S.ButtonItem
                onClick={() => setAddBenefModalVisible(true)}
                {...loadingProps()}
              >
                <Icon name="plus" color={colors.black._100} />
              </S.ButtonItem>
            </S.ButtonsGroup>
            {isModal ? (
              ''
            ) : (
              <Button
                label="Make a transfer"
                fontSize={10}
                disabled={
                  beneficiaries.filter((el) => el.check).length === 0 ||
                  checkLoadings()
                }
                onClick={onMakeTransfer}
                size="small"
              />
            )}
          </S.TopBarWrapper>
          {loading ? (
            <S.PreloadContainer>
              <Preload />
            </S.PreloadContainer>
          ) : (
            <S.TableContainer loading={preload}>
              <Table
                header={{
                  cells: {
                    0: {
                      align: 'left',
                      children: (
                        <CheckBox
                          onChange={(isChecked) => {
                            if (isChecked) {
                              setBeneficiaries((old) =>
                                old.map((el) => ({ ...el, check: true }))
                              );
                            } else {
                              setBeneficiaries((old) =>
                                old.map((el) => ({ ...el, check: false }))
                              );
                            }
                          }}
                        />
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                    1: {
                      align: 'left',
                      children: (
                        <Text size={7} bold>
                          BENEFICIARY NAME
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                      order: findCurrentSorting(BENEFICIARY_NAME)?.order,
                      onClick: () => {
                        changeOrder(BENEFICIARY_NAME);
                      },
                    },
                    2: {
                      align: 'left',
                      children: (
                        <Text size={7} bold>
                          COMPANY
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                      // order: findCurrentSorting(COMPANY)?.order,
                      // onClick: () => {
                      //   changeOrder(COMPANY);
                      // },
                    },
                    3: {
                      align: 'left',
                      children: (
                        <Text size={7} bold>
                          BANK NAME
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                      // order: findCurrentSorting(BANK_NAME)?.order,
                      // onClick: () => {
                      //   changeOrder(BANK_NAME);
                      // },
                    },
                    4: {
                      align: 'left',
                      children: (
                        <Text size={7} bold>
                          ACCOUNT NUMBER
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                      order: findCurrentSorting(ACCOUNT_NUMBER)?.order,
                      onClick: () => {
                        changeOrder(ACCOUNT_NUMBER);
                      },
                    },
                    5: {
                      align: 'right',
                      children: (
                        <Text size={7} bold>
                          LAST AMOUNT SENT
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                      // order: findCurrentSorting(LAST_AMOUNT_SENT)?.order,
                      // onClick: () => {
                      //   changeOrder(LAST_AMOUNT_SENT);
                      // },
                    },
                  },
                  padding: '1.25rem 0',
                }}
                footer={((): TableFooterProps | undefined => {
                  if (bottomLoading) {
                    return {
                      backgroundColor: colors.white._100,
                      cells: {
                        0: {
                          align: 'center',
                          children: (
                            <Icon
                              name="small-loading"
                              color={colors.black._100}
                            />
                          ),
                          padding: '1.25rem 1.5rem',
                          colspan: 6,
                        },
                      },
                    };
                  }
                  if (empty) {
                    return {
                      backgroundColor: colors.white._100,
                      cells: {
                        0: {
                          align: 'center',
                          children: <Text>Result not found</Text>,
                          padding: '1.25rem 1.5rem',
                          colspan: 6,
                        },
                      },
                    };
                  }
                  return undefined;
                })()}
                rows={beneficiaries.map((el, index) => ({
                  label: el.label,
                  padding: '1.25rem 0',
                  border: {
                    color: colors.black._10,
                    width: 1,
                    style: 'solid',
                    sides: ['top'],
                  },
                  cells: {
                    0: {
                      align: 'left',
                      children: (
                        <CheckBox
                          checked={el.check}
                          onChange={(isChecked) =>
                            onChangeValue(
                              beneficiaries,
                              index,
                              'check',
                              isChecked,
                              setBeneficiaries
                            )
                          }
                        />
                      ),
                      padding: '1.25rem 1.5rem',
                      display: isModal ? 'inline-block' : 'none',
                    },
                    1: {
                      align: 'left',
                      onClick: () => setEditBenefModal(el),
                      children: (
                        <S.Column>
                          <Text size={6}>{el?.name}</Text>
                          <Text size={7} color={colors.black._60}>
                            {el?.tags?.name}
                          </Text>
                        </S.Column>
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                    2: {
                      align: 'left',
                      onClick: () => setEditBenefModal(el),
                      children: <Text size={6}>{el.company?.name}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    3: {
                      align: 'left',
                      onClick: () => setEditBenefModal(el),
                      children: <Text size={6}>{el.bank?.name}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    4: {
                      align: 'left',
                      onClick: () => setEditBenefModal(el),
                      children: <Text size={6}>{el?.nuban}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    5: {
                      align: 'right',
                      onClick: () => setEditBenefModal(el),
                      children: (
                        <Text size={6}>{`₦ ${el?.lastAmountSent}`}</Text>
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                  },
                }))}
              />
              {beneficiaries.length > 0 && (
                <Pagination {...pagination} action={getBeneficiaries} />
              )}
            </S.TableContainer>
          )}
        </S.Wrapper>
        <S.ButtonWrapper>
          {isModal ? (
            <Button
              label="Add"
              onClick={onAdd}
              disabled={
                beneficiaries.filter((el) => el.check).length === 0 ||
                checkLoadings()
              }
            />
          ) : (
            ''
          )}
        </S.ButtonWrapper>
      </>
      <EditBeneficiaryModal
        data={editBenefModal}
        onCloseModal={() => setEditBenefModal(undefined)}
        beneficiariesTypes={beneficiariesTypes}
        setBeneficiariesTypes={setBeneficiariesTypes}
        setSelectBeneficiariesTypes={(name: string, value: any) => {
          setSelectBeneficiariesTypes(value);
        }}
        addedElements={(el) => {
          setBeneficiariesTypes([el]);
        }}
        bankNames={bankNames}
        selectBeneficiariesTypes={selectBeneficiariesTypes}
        getBeneficiaries={() => {
          // const $bankNames = bankNames?.filter((el) => el.check);
          // const $beneficiariesTypes = beneficiariesTypes?.filter((el) => el.check);
          // const filter = applyFilter(
          //   { _bankNames: $bankNames, _beneficiariesTypes: $beneficiariesTypes },
          // );
          // setPage(1);
          // setLastPage(false);
          getBeneficiaries(undefined, true);
        }}
      />
      <AddNewBeneficiaryModal
        visible={addBenefModalVisible}
        onCloseModal={() => setAddBenefModalVisible(false)}
        beneficiariesTypes={beneficiariesTypes}
        setBeneficiariesTypes={setBeneficiariesTypes}
        setSelectBeneficiariesTypes={(name: string, value: any) => {
          setSelectBeneficiariesTypes(value);
        }}
        addedElements={(el) => {
          setBeneficiariesTypes([el]);
        }}
        bankNames={bankNames}
        selectBeneficiariesTypes={selectBeneficiariesTypes}
        getBeneficiaries={() => {
          // const $bankNames = bankNames?.filter((el) => el.check);
          // const $beneficiariesTypes = beneficiariesTypes?.filter((el) => el.check);
          // const filter = applyFilter(
          //   { _bankNames: $bankNames, _beneficiariesTypes: $beneficiariesTypes },
          // );
          // setPage(1);
          // setLastPage(false);
          getBeneficiaries(undefined, true);
        }}
      />
      <UploadFileModal
        visible={uploadFileModal}
        onCloseModal={() => setUploadFileModal(false)}
        onAdd={onAddFile}
        setFile={setFile}
      />
    </>
  );
};
