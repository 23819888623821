import React, { FC } from 'react';
import * as S from './styles';
import { Icon } from '../Icon';

export const Loader: FC = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <S.LoaderContainer>
        <Icon name='loader' />
      </S.LoaderContainer>
    </div>
  );
};
