import styled, { css } from 'styled-components';
import { breakpoints, colors, transition } from '../../styles/variables';
import { crossBrowserTransition, hexToRgbA, ptr } from '../../styles/utils';






const Container = styled.div`
  padding: 0px 29px 0px 29px;
`;

const MainWrapper= styled.div`
 
`;

const LogoWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
height: 90px; 
padding: ${ptr(10.5)} ${ptr(54)} ${ptr(10.5)} ${ptr(62)};
position:sticky;
box-sizing: border-box;
background-color: ${colors.white._100};
 
box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.09);
z-index:999999; 
`;

const MainWrapperInner= styled.div`
 width:45%;
 margin:0 auto;
 border-radius: 8px;
border: 2px solid var(--stroke, #EAECF0);
background: #FFF;
padding:32px;
margin-top:35px;

@media screen and (max-width: ${breakpoints.lg}px) {  
  width:70%;
  padding:0;
}

@media screen and (max-width: ${breakpoints.sm}px) {  
  width:95%;
  padding:0;
}
`;

const Image = styled.img`
  border-radius: 2px;
  background: var(
    --vfd-gradient,
    linear-gradient(
      90deg,
      #a54189 0%,
      #ee3a3e 45.31%,
      #f79431 99.99%,
      rgba(196, 196, 196, 0) 100%
    )
  );
  width: 100%;
  height: 14px;
  flex-shrink: 0;
  margin-top: -6px;
`;

const TopText = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 17px 29px 16px 29px;
`;

const TopTextLhs = styled.div``;

const OperatorDetails = styled.div`
  padding: 0px 0px 32px 0px;

  > :nth-child(2) {
    margin-top: 8px;
  }
`;

const ButtonGroup = styled.div`
  padding: 0px 0px 64px 0px; 
  border-top: 4px solid #dbdfe4;
  padding-top: 14px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:10px;
  justify-content:space-between;
`;

const ButtonGroupA = styled.div`
 

  border-top: 1px dotted #dbdfe4;
  padding-top: 14px;
  margin-top: 20px;
  display: flex;
  justify-content:right;
  flex-wrap: wrap;
  > div {
    height: 50px;
    display: flex;

    justify-content: right;
  }

  > button {
    display: flex;
  }
`;

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 17.5px;
  gap: 8px;
`;

const Upload = styled.div`
  padding: 0px;

  > :nth-child(3) {
    margin-top: 16px;
  }
`;

const UploadText = styled.div`
  margin-bottom: 14px;
`;













const TableAlign2 = styled.div`
display:flex;
align-items:center;
margin-top:15px;
border-radius:8px;
 height:43px;
 gap:14px;
width:fit-content; 
@media screen and (max-width: ${breakpoints.sm}px) {  
  padding:10px 0px;
  display: flex;
  flex-direction:column;
  height:fit-content;
  gap:20px
}
`;

const Tab1 = styled.div`
padding:8px;
max-width:fit-content;
border-radius: 8px;
display:flex;
gap:8px;
align-items:center; 
 
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
 > span{
 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media screen and (max-width: ${breakpoints.sm}px) { 
   width:100% 
  }
`;

const TypeTabs = styled.div`
margin-bottom:14px;
`;
const MarginLeftComp1 = styled.div`
padding:8px;
max-width:fit-content;
border-radius: 8px;
display:flex;
align-items:center; 
margin-left:  ${ptr(4)};
gap:8px;
 > span{
border-radius: 6px;
 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media screen and (max-width: ${breakpoints.sm}px) { 
    width:100% 
    margin-left:0px;
    > span{ 
      padding:7px 68px;
      width:100%; 
        }
   }
`;
const Circle = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(18)}; 
  height: ${ptr(18)};
  border-radius: ${ptr(12)};
 border: ${ptr(1)} #B10180 solid;
 
  ${crossBrowserTransition(`${transition.slow}`)}
  
`;

const CheckCircle= styled.div `
display: flex; 
width:100%;
justify-content: center;
align-items: center; 
  
margin-bottom: 30px; 

`; 



const InsideCircle = styled.div`
  width: ${ptr(10)};
  height: ${ptr(10)};
  border-radius: ${ptr(6)};
background-color: #B10180
 
`;


















const TextDrag = styled.div` 
white-space:nowrap; 
display: flex;
align-items: center;
flex-direction: column;  
justify-content:center;
padding:4px;
`;

const SigningOptionsDiv = styled.div<{ hideBorder?: boolean }>` 
border-radius: 4px;
border:${(props) => props.hideBorder ? css`none!important ` : css`1px dashed #7F91A8;` };
 margin-bottom:32px;
background: #FFF;
padding: ${(props) => props.hideBorder ? css`padding:0!important ` : css`padding:16px` };
`;

const ConsentTexts = styled.div`
  padding: 0px 0px 16px 0px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  > :nth-child(2) {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }
`;

const TopTextRhs = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    width: 4px;
    height: 22px;
    border-radius: 2px;
    background: var(
      --vfd-gradient,
      linear-gradient(
        90deg,
        #a54189 0%,
        #ee3a3e 45.31%,
        #f79431 99.99%,
        rgba(196, 196, 196, 0) 100%
      )
    );
  }
`;

const TermsText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Img = styled.img`
  width: 76px;
  height: 52px;
`;

const Img1 = styled.img`
  width: 83.5px;
  height: 83.5px;
`;

const TermsLink = styled.a`
  color: ${colors.pink._100};
`;

const DrawDiv= styled.div`
color: ${colors.pink._100};
`;

const ModalInner= styled.div`
 padding:40px; 
`;

// const SectionsWrapper = styled.div`
// margin-top: ${ptr(32)};
// `
// const OperatorsConatiner = styled.div`
// display: grid;
// grid-template-columns: 1fr 1fr;
// grid-gap: ${ptr(32)};
// line-height: ${ptr(31)};

// @media (max-width: 992px) {
//   grid-template-columns: auto;
// }   
// `
// const OperatorWrapper = styled.div`
// text-transform: capitalize;
// `

// const TermsText = styled.div`
//   display: flex;
//   align-items: flex-start;
//   gap: 16px;
// `;

// const TermsLink = styled.a`
// color: ${colors.pink._100};
// `

// const WrapperP1 = styled.div`
// `;

// const AlignP1 = styled.div`
// text-align:right;
// `;

// const HeaderWrapperP1 = styled.div`
// padding:${ptr(50)} ${ptr(40)};
// background-color:  ${colors.pink._100};

// @media (max-width:576px){
//     padding:${ptr(30)} ${ptr(30)};
// }
// `;
// const HeaderWrapperBottomP1 = styled.div`
// padding:${ptr(50)} ${ptr(50)};

// @media (max-width:576px){
//     padding:${ptr(50)} ${ptr(10)};
// }
// `;

// const MarginP1 = styled.div`
// text-align: center;
// margin:${ptr(10)} ${ptr(0)};
// `;

// const DisplayP1 = styled.div`
// margin:${ptr(30)} ${ptr(0)};
// display:flex;
// justify-content: space-between;

// button{
//     margin: 0 5px;
// }
 

// `;

// const Wrapper = styled.div`
//   padding: ${ptr(0)};
//   /* width: 60vw; */
//   width: 100%;
//   max-width: 621px;
//   min-height: calc(100vh - ${ptr(270)});
//   width:100%;
//   padding-right:15px;
//   padding-left:15px;
//   margin-right:auto;margin-left:auto;
//   @media (min-width:320px){max-width:300px;}
//   @media (min-width:360px){max-width:340px;}
//   @media (min-width:576px){max-width:540px;}
//   @media (min-width:768px){max-width:720px;}
//   @media (min-width:992px){max-width: 621px;}
//   @media (min-width:1200px){max-width:1140px;}
//   /* @media (max-width: 992px)
//  {
//     max-width: 960px;
//  }

//  @media (max-width: 768px)
// {
//     max-width: 720px;
// }
// @media (max-width: 576px)
//  {
//     max-width: 540px;
// } */

// `;
// const TemplateWrapper = styled.div`
//   width: 100%;
//   position: relative;
//   overflow: hidden;
//   padding: 20px;
//   background-color: ${colors.black._10};
//   box-sizing: border-box;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: space-between;
//   text-align: left;
//   border: ${ptr(1)} solid ${colors.black._10};

//   ${crossBrowserTransition(transition.slow)}

//   border-radius: ${ptr(8)};
//   color: ${colors.white._100};
// `;

// const TemplateWrapperButtonContainer = styled.div`
// @media only screen and (max-width: 800px) {
//   margin-bottom:${ptr(10)};
// }
// `;
// const TopBarWrapper = styled.div`
//   display: flex;
//   flex-wrap:wrap;
//   padding: ${ptr(24)} 0;
// `;

// const ButtonWrapper = styled.div`
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: ${colors.white._100};
//   padding: ${ptr(24)} ${ptr(64)};
//   display: flex;
//   border-top-width: 1px;
//   border-color: ${colors.black._10};
//   border-style: solid;
//   justify-content: flex-end;
//   position: sticky;
// `;

// const ButtonsGroup = styled.div`
//   display: flex;
//   min-width: ${ptr(240)};
//   margin-right: ${ptr(16)};
//   & > :not(:last-child) {
//     margin-right: ${ptr(16)};
//   }
//   @media only screen and (max-width: 1200px) {
//   margin-bottom:${ptr(20)};
// }
// `;

// const InputBox = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0 ${ptr(16)};
//   border-radius: ${ptr(8)};
//   flex: 1.5;
//   border: 1px solid ${colors.black._10};
//   margin-right: ${ptr(32)};
//   @media only screen and (max-width: 1200px) {
//   margin-bottom:${ptr(10)};
// }
// `;

// const Block = styled.div`
//   position: relative;
// `;

// const ButtonItem = styled.button`
//   width: ${ptr(48)};
//   height: ${ptr(48)};
//   background: ${colors.black._5};
//   border-radius: ${ptr(6)};
//   border: none;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   transition: ${transition.slow};

//   &[disabled] {
//     opacity: 0.6;
//   }
// `;

// const TextField = styled.input`
//   width: 100%;
//   outline: none;
//   border: none;
//   color: ${colors.black._100};
//   transition: .3s;
//   margin-left: ${ptr(-40)};
//   margin-right: ${ptr(-16)};
//   padding: ${ptr(16)} ${ptr(16)} ${ptr(12)} ${ptr(50)};
//   background: transparent;

//   &::placeholder {
//     color: ${colors.black._40}
//   }
// `;

// const Dropdown = styled.div`
//   background: ${colors.white._100};
//   position: absolute;
//   top: ${ptr(60)};
//   box-sizing: border-box;
//   box-shadow: 0px ${ptr(4)} ${ptr(26)} ${hexToRgbA(colors.black._100, 20)};
//   width: ${ptr(258)};
//   border-radius: ${ptr(8)};
//   right: ${ptr(-138)};
//   z-index: 1;
// `;

// const CloseDropdown = styled.button`
//   background: transparent;
//   border: 0;
//   padding: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top : ${ptr(24)};
//   right: ${ptr(24)};
//   position: absolute;
// `;

// const DropdownContent = styled.div`
//   padding: ${ptr(24)};
//   display: flex;
//   align-items: flex-start;
//   flex-direction: column;
//   max-height: ${ptr(476)};
//   overflow: scroll;
//   z-index: 1,
// `;

// const DropdownBottom = styled.div`
//   padding: ${ptr(16)};
//   border-top: ${ptr(1)} solid ${colors.black._5};
//   display: flex;
//   justify-content: space-between;

//   > button {
//     padding: ${ptr(12)};
//     flex: 1;

//     &:last-child {
//       flex: 1.4;
//       margin-left: ${ptr(12)};
//     }
//   }
// `;

// const FilterBox = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   align-items: flex-start;

//   &:not(:last-child) {
//     margin-bottom: ${ptr(24)};
//   }

//   > span {
//     margin-bottom: ${ptr(12)};
//   }
// `;

// const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   align-items: flex-start;

//   > div {
//     margin: ${ptr(12)} 0;
//   }
// `;

// const PreloadContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: ${ptr(94)} 0;
// `;

// const TableContainer = styled.div<{ loading: boolean }>`
//   ${(props) => props.loading && css`
//     opacity: 0.8;
//   `}
// `;

// const Column = styled.div`
//   > * {
//     display: block;
//     text-transform: capitalize; 
//   }
// `;

// const Blank = styled.div`
//   height: ${ptr(24)}
// `;

export {
  Container,
  Image,
  TopText,
  TopTextLhs,
  TopTextRhs,
  Img,  Img1,
  Tab1,
  DrawDiv,
  TermsText,
  TermsLink,
  UploadText,
  ConsentTexts,
  OperatorDetails,
  ButtonGroup,
  ButtonGroupA,
  Upload,
  ModalInner,
  Info,
  TextDrag,
  MainWrapper,
  LogoWrapper,
  MainWrapperInner,
  SigningOptionsDiv,
  TableAlign2, TypeTabs, MarginLeftComp1, Circle, InsideCircle, CheckCircle,
  // TemplateWrapper,
  // TemplateWrapperButtonContainer,
  // Blank,
  // TableContainer,
  // PreloadContainer,
  // Wrapper,
  // TopBarWrapper,
  // ButtonWrapper,
  // ButtonsGroup,
  // InputBox,
  // ButtonItem,
  // TextField,
  // Dropdown,
  // CloseDropdown,
  // DropdownBottom,
  // DropdownContent,
  // FilterBox,
  // Content,
  // Block,
  // Column,
  // /*  */
  // WrapperP1,
  // AlignP1,
  // MarginP1,
  // HeaderWrapperP1,
  // HeaderWrapperBottomP1,
  // DisplayP1,
  // SectionsWrapper,
  // OperatorsConatiner,
  // OperatorWrapper,
  // TermsText,
  // TermsLink
};
