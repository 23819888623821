import styled from 'styled-components';

import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const adaptiveBreakPoint = 800;

const Wrapper = styled.div`
  display: flex;
  background-color: ${colors.black._5};
  padding: ${ptr(28)} ${ptr(32)};
  border-radius: ${ptr(8)};

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
`;

const DescriptionWrapper = styled.div`
  margin-top: ${ptr(4)};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${ptr(24)};

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    margin-left: 0;
    margin-top: ${ptr(24)};
  }
`;

export {
  Wrapper,
  TextWrapper,
  DescriptionWrapper,
  ButtonWrapper,
};
