import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import validator from 'validator';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../../components/DropdownInput';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';

import { validateObject, ValidateResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from '../common.personal-info/styles';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { actions } from '../config';
import { useHistory } from 'react-router-dom';
import { idTypes, SIGN_UP, validIdTypes } from '../constants';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { InfoConponent } from '../../../components/Info';
import { RootState } from '../../../redux';

const SCHEME = {
  referralCode: (value: string) => value.length > 2,
  phone: (value: string) => value.length === 11,
  bvn: (value: string) => value.length === 11,
  idType: (value: { value: string }) => value?.value?.length > 0,
  personalEmail: (value: string) => validator.isEmail(value),
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CommonSignupPersonalInformation: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean
  );

  const savedBvn = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.bvn as string
  );

  const savedReference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const credentials = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.credentials as Record<string, string>
  );

  const preference = useSelector((store: RootState) => store.preference);


  /**
   * Local state initiation region
   */
  const [reference, setReference] = useState<string>(savedReference);
  const [loading, setLoading] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>('');
  const [bvn, setBvn] = useState<string>(
    () => (savedBvn || credentials?.bvn) ?? ''
  );
  const [phone, setPhone] = useState<string>(() => credentials?.phone ?? '');
  const [idType, setIdType] = useState<DropdownOptionProps | null>(() =>
    credentials?.idType
      ? validIdTypes.find((id) => id.value === credentials?.idType) ?? null
      : null
  );
  const [idNumber, setIdNumber] = useState<string>(
    () => credentials?.idNumber ?? ''
  );
  const [validation, setValidation] = useState<ValidateResult>();
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();

  const [validInProgress, setValidInProgress] = useState(false);
  const [bvnValid, setBvnValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [idNumberValid, setNumberValid] = useState(false);

  const [personalEmail, setPersonalEmail] = useState<string>(
    () => credentials.email ?? ''
  );

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  /**
   * useEffect region
   */

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            bvn,
            phone,
            idType,
            personalEmail,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [bvn, phone, idType, personalEmail]);

  const continueHandleForNoBvnConsent = async () => {
    const details = {
      referralCode,
      email: personalEmail,
      phone,
      idNumber,
      idType: idType?.value ?? '',
      bvn,
    };

    try {
      setLoading(true);
      const response = inApp
        ? await onboardingService.sendOtpForBusiness({
            ...details,
          })
        : await onboardingService.sendGuestOtpForBusiness({
            ...details,
          });

      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            credentials: {
              ...details,
            },
            type: SIGN_UP,
            maskedPhone: response.data.maskedPhone,
            reference: response.data.reference,
          })
        );
      });
      if (response.data.reference) setReference(response.data.reference);
      route.push(actions[response.actionRequired]);
    } catch (err) {
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  const continueHandle = async () => {
    const details = {
      referralCode,
      email: personalEmail,
      phone,
      idNumber,
      idType: idType?.value ?? '',
      bvn,
      reference,
    };

    try {
      setLoading(true);
      // const response = inApp
      //   ? await onboardingService.sendOtpForBusiness({
      //       ...details,
      //     })
      //   : await onboardingService.sendGuestOtpForBusiness({
      //       ...details,
      //     });

      const response = await onboardingService.initSignup({
        ...details,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            credentials: {
              ...details,
            },
            type: SIGN_UP,
            maskedPhone: response.data.maskedPhone,
            reference: response.data.reference,
          })
        );
      });
      if (response.data.reference) setReference(response.data.reference);
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setIdNumberValidation(
        validateObject(
          {
            idNumber,
          },
          idNumberScheme
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [idNumber, idType]);

  return (
    <S.Container>
      <Title
        title='Personal Information'
        subTitle='Tell us about yourself by providing your personal details.'
        color='#394455'
      />
      <S.InfoContainer>
        <InfoConponent text='The information provided here will become the super admin who will be able to create, disable and edit user roles on account.' />
      </S.InfoContainer>
      <S.Wrapper>
        <OnboardingCard>
          <Text bold color={colors.black._100}>
            Tell us about yourself
          </Text>
          <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
            {!preference.bvnConsentEnabled && (
              <div style={{ flex: 1 }}>
                <TextInput
                  label='BVN'
                  type='currency'
                  placeholder='Enter BVN'
                  mask={createNumberMask(numberMaskOptions)}
                  value={bvn}
                  valid={!bvnValid && validation?.data.bvn.isValid}
                  onChange={setBvn}
                />
              </div>
            )}
            <div style={{ flex: 1 }}>
              <TextInput
                label='Phone number'
                type='tel'
                placeholder='Enter Phone Number'
                mask={createNumberMask(numberMaskOptions)}
                value={phone}
                valid={!phoneValid && validation?.data.phone.isValid}
                onChange={setPhone}
              />
            </div>
          </S.ItemRow>
          <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
            <div style={{ flex: 1 }}>
              <DropdownInput
                label='Valid ID Type'
                placeholder='Select valid ID'
                options={[...validIdTypes]}
                inputValue={idType?.label}
                onSelect={setIdType}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TextInput
                label='ID Number'
                type='text'
                name='idNumber'
                value={idNumber}
                placeholder='Enter ID Number'
                valid={
                  !idNumberValid && idNumberValidation?.data.idNumber.isValid
                }
                onChange={setIdNumber}
              />
            </div>
          </S.ItemRow>

          <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
            <div style={{ flex: 1 }}>
              <TextInput
                placeholder='Enter email address'
                label='Email address'
                value={personalEmail}
                valid={
                  !validInProgress && validation?.data.personalEmail.isValid
                }
                onChange={(value) => setPersonalEmail(value?.trim())}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TextInput
                label='Referal code'
                labelExtra=' (Optional)'
                type='text'
                value={referralCode}
                valid={referralCode.length > 3}
                onChange={setReferralCode}
              />
            </div>
          </S.ItemRow>

          <S.ButtonGroup>
            <div>
              <Button
                style={{ width: 304 }}
                label='Continue'
                loading={loading}
                disabled={
                  !validation?.isValid ||
                  !idNumberValidation?.isValid ||
                  loading
                }
                onClick={
                  preference.bvnConsentEnabled
                    ? continueHandle
                    : continueHandleForNoBvnConsent
                }
              />
            </div>
          </S.ButtonGroup>
        </OnboardingCard>
      </S.Wrapper>
    </S.Container>
  );
};
export default CommonSignupPersonalInformation;
