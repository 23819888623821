import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { breakpoints } from '../../../styles/variables';

const Container = styled.div`
  > button {
    width: 100%
  }
`;
const ListWrapper = styled.div`
  margin-bottom: 10px;
`;
const Wrapper = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-bottom: ${ptr(32)};
  }
`;

const Content = styled.div`
  > :not(:first-child) {
    margin-top: ${ptr(16)};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content:space-between;
  margin-top:30px;
  button {
    width: 45%;
  }
`;

const ModalContainer = styled.div`
padding: ${ptr(24)} ${ptr(64)};
  box-sizing: border-box;
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  button{
    margin-left: auto;
    margin-right: auto;
    /* display: block; */
    margin-top: ${ptr(40)};
    margin-bottom: ${ptr(40)};
    width: 100%;
 }
   @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export {
  Container, Wrapper, Content, ListWrapper, ButtonContainer, ModalContainer,
};
