import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackBar } from '../../../components/BackBar';
import { Button } from '../../../components/Button';
import RadioCheckWithLabelAndDesc from '../../../components/RadioCheckWithLabelAndDesc';
import { Text } from '../../../components/Text';
import { TextInput } from '../../../components/TextInput';
import { limit } from '../../../navigation';
import { colors } from '../../../styles/variables';
import { PageTitle } from '../../../styles/wrappers';
import { data } from '../../Onboarding/steps/common.type.of.business';
import * as S from '../styles';
import { useSelector } from 'react-redux';
import { PaymentInterface } from '../../../redux/payment/IPayment';
import { formatCash } from '../../../utils/money';
import { failed, warning } from '../../../components/Toasts';
import paymentsService from '../../../services/internal/payments/payments.service';
import { useDispatch } from 'react-redux';
import { PaymentActions } from '../../../redux/payment/actions';
import AccessControl, { EPermissions } from '../../../components/AccessControl';

const LimitRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentsAccount = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.paymentAccounts
  );
  const limitInfo = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.limitInfo
  );
  const [requestData, setRequestData] = useState({
    amount: '',
    businessType: '',
  });
  const [loading, setLoading] = useState(false);
  const [limitInfoLoading, setLimitInfoLoading] = useState(false);

  useEffect(() => {
    if (!paymentsAccount.length) {
      getPaymentAccounts();
    } else if (
      paymentsAccount &&
      paymentsAccount.some((account) => account.type)
    ) {
      const type = paymentsAccount.find((account) => account.type)?.type;
      if (type) {
        setRequestData({ ...requestData, businessType: type });
      }
    }
  }, [paymentsAccount]);

  useEffect(() => {
    if (!limitInfo) {
      getLimitInfo();
    }
  }, [limitInfo, paymentsAccount]);

  const getPaymentAccounts = async (): Promise<void> => {
    try {
      const result = await paymentsService.getPaymentAccounts();
      // setPaymentsAccount(result);
      dispatch(PaymentActions.savePaymentAccounts(result));
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error retrieving payments accounts');
    }
  };

  const getLimitInfo = async (): Promise<void> => {
    setLimitInfoLoading(true);
    try {
      if (!paymentsAccount?.length) return;
      dispatch(PaymentActions.getLimitInfo(paymentsAccount[0].nuban, failed));
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error retrieving bank names');
    }
    setLimitInfoLoading(false);
  };

  const getButtonState = () => {
    !requestData.amount ||
      !requestData.businessType ||
      loading ||
      limitInfoLoading;

    if (!requestData.amount || !requestData.businessType) return true;
    if (loading) return true;
    if (limitInfoLoading) return true;
    if (!limitInfo) return true;
    return false;
  };

  const handleChange = (name: string, value: string) => {
    setRequestData({ ...requestData, [name]: value });
  };
  const handleBusinessTypeChange = (value: string) => {
    setRequestData({ ...requestData, businessType: value });
  };
  const proceed = async () => {
    setLoading;

    try {
      if (!requestData.amount || !requestData.businessType)
        return failed('Error', 'Amount and business type is required');

      if (
        requestData?.amount &&
        limitInfo &&
        Number(requestData.amount.replace(/[\s,₦%]/g, '')) >
          Number(process.env.REACT_APP_TRANSACTION_LIMIT_CAP ?? 250000000)
      ) {
        warning('Info', 'Amount can not be more than available limit');
        return;
      }
      setLoading(true);
      const response = await paymentsService.initializeLimitRequest({
        nuban: paymentsAccount[0].nuban,
        dailyWithdrawLimitBalance: limitInfo?.dailyWithdrawLimitBalance ?? 0,
        dailyWithdrawLimitCap: limitInfo?.dailyWithdrawLimitCap ?? 0,
        singleTransactionLimit: limitInfo?.singleTransactionLimit ?? 0,
        newDailyTransactionLimit: Number(
          requestData.amount.replace(/[\s,₦%]/g, '')
        ),
        businessType: requestData.businessType,
      });
      if (response) dispatch(PaymentActions.saveLimitRequestResponse(response));
      history.push(`${limit}/upload`);
    } catch (error) {
      const err = error as Error;
      failed(
        'Error',
        err.message ?? 'Unable to initialize request at the moment'
      );
    }
    setLoading(false);
  };
  return (
    <AccessControl requiredPermission={[EPermissions.CREATE_OPERATOR]} isPage>
      <S.Container>
        <S.TitleWrapper>
          <PageTitle.Wrapper>
            <BackBar name="New transaction limit" />
            <S.TitleDescription>
              <S.CircleDiv>
                <Text size={8} color={colors.white._100}>
                  1
                </Text>
              </S.CircleDiv>
              <Text size={5}>Enter new limit/select your business type</Text>
              <S.Line />
              <S.CircleDiv
                backgroundColor={colors.white._100}
                borderColor={colors.gray._100}
              >
                <Text size={8} color={colors.black._25}>
                  2
                </Text>
              </S.CircleDiv>
              <Text size={5}>Upload indemnity and KYCB form </Text>
            </S.TitleDescription>
          </PageTitle.Wrapper>
        </S.TitleWrapper>

        <S.ContentContainer>
          <S.InputContainer>
            <TextInput
              label="New daily transaction limit"
              value={requestData?.amount?.toString() ?? ''}
              onChange={(value) => handleChange('amount', value)}
              symbol={
                requestData?.amount &&
                limitInfo &&
                Number(requestData.amount.replace(/[\s,₦%]/g, '')) >
                  Number(
                    process.env.REACT_APP_TRANSACTION_LIMIT_CAP ?? 250000000
                  )
                  ? ''
                  : '₦'
              }
              type="currency"
              invalid={
                requestData?.amount &&
                limitInfo &&
                Number(requestData.amount.replace(/[\s,₦%]/g, '')) >
                  Number(
                    process.env.REACT_APP_TRANSACTION_LIMIT_CAP ?? 250000000
                  )
                  ? 'Amount can not be more than the available limit'
                  : ''
              }
            />

            <Text size={7} color={colors.black._25}>
              Not more than{' '}
              {formatCash(
                process.env.REACT_APP_TRANSACTION_LIMIT_CAP ?? 250000000
              )}
            </Text>
          </S.InputContainer>
          <S.BusinessTypeSection>
            <Text size={5} color={colors.black._25}>
              Select your business type
            </Text>

            <RadioCheckWithLabelAndDesc
              items={data.map((dat) => ({
                title: dat.title,
                description: dat.description,
                value: dat.businessType,
              }))}
              onChange={handleBusinessTypeChange}
              selectedValue={
                paymentsAccount.length ? paymentsAccount[0].type : undefined
              }
            />
          </S.BusinessTypeSection>

          <S.ButtonContainer>
            <Button
              label="Proceed"
              disabled={getButtonState()}
              onClick={proceed}
              loading={loading}
            />
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Container>
    </AccessControl>
  );
};

export default LimitRequest;
