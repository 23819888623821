import React from 'react';
import AccessControl, { EPermissions } from '../../components/AccessControl';

import { BackBar } from '../../components/BackBar';
import * as S from './styles';
import { BeneficiariesManagementView } from './view';

export const BeneficiariesManagement: React.FC = () => (
  <>
    <AccessControl isPage requiredPermission={[EPermissions.CREATE_PAYMENT]}>
      <BackBar name="Beneficiaries Management" />
      <S.Blank />
      <BeneficiariesManagementView />
    </AccessControl>
  </>
);
