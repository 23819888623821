import React, { useState } from 'react';

import { ModalWrapper } from '../../../../components/ModalWrapper';

import * as S from './styles';
import { Text } from '../../../../components/Text';
import { Table } from '../../../../components/Table';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';
import { CheckBox } from '../../../../components/CheckBox';
import { CheckAreaGroup } from '../../../../components/CheckAreaGroup';
import { Button } from '../../../../components/Button';

export interface ConsentModalProps {
  image?: string;
  onClose: () => void;
  visible: boolean;
}

const TEST_DATA = [
  {
    name: 'Operator 1',
    role: 'Authorizer',
    limit: 'N1,000,000',
  },
  {
    name: 'Operator 2',
    role: 'Initaitor',
    limit: '-',
  },
  {
    name: 'Operator 3',
    role: 'Initaitor',
    limit: '-',
  },
];

export const ConsentSample: React.FC<ConsentModalProps> = ({
  image,
  visible,
  onClose,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  return (
    <ModalWrapper maxWidth='50%' showModal={visible}>
      <S.Image />
      <S.Container>
        <S.TopText>
          <S.TopTextLhs>
            <S.Img alt='logo' src='/images/logo/logo.svg' />
          </S.TopTextLhs>

          <S.TopTextRhs>
            <Text bold size={7} color='#000000'>
              Date :
            </Text>

            <Text size={7} color='#000000'>
              23, April 2023
            </Text>
            <div />
          </S.TopTextRhs>

          <S.Close>
            <Icon name='cancel' color='#000000' onClick={onClose} />
          </S.Close>
        </S.TopText>

        <S.ConsentTexts>
          <Text bold size={8} color='#000000'>
            Hello Board Member,
          </Text>

          <Text bold size={6} color='#000000'>
            Consent to onboard account on VBiz.
          </Text>

          <Text size={7} color='#000000'>
            This is to formally request for your consent to proceed with
            onboarding your company on VBiz
          </Text>

          <Text
            style={{ marginTop: '10px', lineHeight: '20px' }}
            size={7}
            color='#000000'
          >
            The purpose of onboarding [company name] [account number] on VBiz is
            to provide you with convenient and secure access to a wide range of
            banking services and transactions. VBiz offers smart features and
            functionalities that will streamline your financial management
            processes and enhance the overall efficiency of your company's
            banking operations.
          </Text>

          <Text style={{ marginTop: '10px' }} size={7} color='#000000'>
            By providing your consent, you agree to the following:
          </Text>
          <Text style={{ marginTop: '-4px' }} bold size={7} color='#000000'>
            Workflow on account: Multiple Operators (initiator - verifier -
            authorizer)
          </Text>
        </S.ConsentTexts>

        <S.OperatorDetails>
          <Text bold size={7} color='#000000'>
            Operator details
          </Text>

          <Table
            headerStyle={{
              paddingTop: '0px',
              padding: '0px',
              color: '#667085',
              fontWeight: 500,
              fontSize: 12,
            }}
            header={{
              cells: {
                0: {
                  align: 'left',
                  children: <Text size={7}>Operator name</Text>,
                  padding: '0rem 4rem 0rem 1.5rem',
                },
                1: {
                  align: 'left',
                  children: <Text size={7}>Role</Text>,
                  padding: '0rem 4rem 0rem 0rem',
                },
                2: {
                  align: 'left',
                  children: <Text size={7}>Approval limit</Text>,
                  padding: '0.3rem 1rem 0.3rem 0rem',
                },
              },
            }}
            rows={TEST_DATA?.map((el, index) => ({
              padding: '1.25rem 0',
              border: {
                color: colors.black._10,
                width: 1,
                style: 'solid',
                sides: ['top'],
              },
              cells: {
                0: {
                  align: 'left',

                  children: (
                    <Text bold style={{ width: '100%' }} size={7}>
                      {el.name}
                    </Text>
                  ),
                  padding: '0.3rem 2rem 0.3rem 1.5rem',
                },
                1: {
                  align: 'left',

                  children: (
                    <Text bold size={7}>
                      {el.role}
                    </Text>
                  ),
                  padding: '0rem 4rem 0rem 0rem',
                },
                2: {
                  align: 'left',
                  children: (
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        cursor: 'pointer',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Text bold size={7}>
                        {el.limit}
                      </Text>
                    </div>
                  ),
                  padding: '0.3rem 1rem 0.3rem 0rem',
                },
              },
            }))}
          />

          <S.Info>
            <Icon color='#ED405C' name='info' />
            <Text size={7} color='#ED405C'>
              Please review this consent letter carefully and indicate your
              agreement by signing below.  
            </Text>
          </S.Info>
        </S.OperatorDetails>

        <S.Upload>
          <S.UploadText>
            <Text
              style={{ marginBottom: '14px' }}
              bold
              size={7}
              color='#000000'
            >
              Choose your preferred signing options
            </Text>
          </S.UploadText>

          <CheckAreaGroup
            //   onChange={setRole}
            dimension='12px'
            textStyle={{ fontSize: 14 }}
            items={[
              { value: 'Upload signature or drag & drop' },
              { value: 'Sign on the screen' },
            ]}
          />

          {/* <S.TermsText>
            <CheckBox
              //   height={1}

              onChange={(isCheckedd) => {
                setChecked(isCheckedd);
                if (!isCheckedd) {
                  setChecked(false);
                }
              }}
            >
              aa
            </CheckBox>

            <Text size={7} color='#394455'>
              By checking this box, you attest to have read our indemnity,
              Privacy Policy and Terms of Use, understood its contents, and
              consent to the information provided.
            </Text>
          </S.TermsText> */}
        </S.Upload>

        {/* <S.ButtonGroup>
          <div>
            <Button
              label='Decline'
              size='tiny'
              theme='light'
              style={{ width: 300 }}
              onClick={onClose}
            />
          </div>
          <div>
            <Button
              label='Accept'
              style={{ width: 300 }}
              //   onClick={continueHandle}
            />
          </div>
        </S.ButtonGroup> */}
      </S.Container>
    </ModalWrapper>
  );
};
