 

import styled, { css } from 'styled-components';

import { FileUploadProps, PreviewProps } from '.';
import {
  fonts, colors, transition, breakpoints,
} from '../../styles/variables';
import { crossBrowserTransition, ptr } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: ${ptr(64)}; */
  font-family: ${fonts.inter.regular};
  line-height: 140%;
  width:100%;
  box-sizing: border-box;
  cursor:pointer;
`;
 

const Container = styled.div`
padding:5px 25px 0px 25px;
height:400px; 
max-height:fit-content;


`;

const LoadContainer = styled.div`
position: fixed;
z-index: 10000;
max-width:675px;
max-height:596px;
margin: auto auto;
border-radius: 8px;
top: 0;
bottom: 0;
left: 0;
right: 0;
background-color: rgba(0, 0, 0, 0.3);
align-items: center;
justify-content: center;
${crossBrowserTransition(transition.slow)}
 
  display: flex; 
`;
 
const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: ${ptr(94)} 0;
  display: inline-flex;
padding: 19px 21px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: #F1F2F3;

/* Cards Shadow */
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 8px 24px -6px rgba(0, 0, 0, 0.16);
`;

const WrapperEmpty = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
/* margin-top:180px; */
  border-radius: ${ptr(4)};
  box-sizing: border-box;
  padding: ${ptr(47)} ${ptr(236)};  
  border: ${ptr(1)} dashed ${colors.black._80};
@media (max-width: ${breakpoints.md}px) {
    padding:0;
 }
 @media (max-width: 1396px) {
  padding: ${ptr(47)} ${ptr(236)};  
 }

 @media (max-width: ${breakpoints.sm}px) {
  padding: ${ptr(47)} ${ptr(136)};  
 }
`;

const IdWrapper = styled.div`

  width:100%;
  padding: ${ptr(0)} ${ptr(236)}; 
@media (max-width: ${breakpoints.md}px) {
    padding:0;
 }
 @media (max-width: 1396px) {
  padding: ${ptr(0)} ${ptr(236)};  
 }

 @media (max-width: ${breakpoints.sm}px) {
  padding: ${ptr(0)} ${ptr(136)};  
 }
`;


const TextDrag = styled.span` 
white-space:nowrap; 
display: flex;
align-items: center;
flex-direction: column;  
justify-content:center;
`;

const TextDrag1 = styled.span` 
white-space:nowrap;
 height:106px;
display: flex;
align-items: center;
flex-direction: column;  
justify-content:center;
`;

const TextSelect = styled.span`
  color: ${colors.pink._100};
  cursor: pointer;

  ${crossBrowserTransition(transition.slow)};
  
  :hover {
    color: ${colors.pink._60};
  }
`;

const FileIconWrapper = styled.div`
 
`;


const DeleteCrossWrapper = styled.div`
  position: absolute;
  right: ${ptr(-8)};
  top: ${ptr(-8)};
  z-index: 1;
`;

const DeleteCross = styled.button`
background:none;
  border: 0;  
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewWrapper = styled.div<PreviewProps>`
  display: flex;
  flex-direction: column;
  position: relative; 
background:${colors.black._5};
  display: flex; 
  justify-content: center;
  align-items: center;
  width:100%;
  border-radius: ${ptr(4)};
  box-sizing: border-box;
  padding: ${ptr(16)} ${ptr(22.88)};  
  border: ${ptr(1)} solid ${colors.black._80};
  
  ${(props) => props.withPreviewImage && css`
    width: 100%;
  `}
`;

const ImagePreview = styled.img`
  background-color: ${colors.pink._20};
  border-radius: ${ptr(6)};
  width: ${ptr(140)};
  height: ${ptr(124)};
  object-fit: cover;
`;

const FileNameWrapper = styled.div` 
display:flex;
color:#7F8EA4;
  overflow: hidden;
  white-space: nowrap; 
  width:100%;
  justify-content: space-between;
  line-height: 140%;
  font-size: ${ptr(14)};
  font-family: ${fonts.inter.bold};
  text-overflow: ellipsis;
`;

const FileName = styled.span` 
margin-left:12px;
`;

const FileFormat = styled.span` 
display:flex;
align-items:center; 
`;

const FileSizeWrapper = styled.div``;

const FileSize = styled.span`
  line-height: 140%;
  font-size: ${ptr(14)};
  font-family: ${fonts.inter.regular};
  text-overflow: ellipsis;
`;

const FileIconContainer = styled.div`
  width: ${ptr(104)};
  height: ${ptr(120)};
  position: relative;
`;

const FileIconBackground = styled.div`
  width: ${ptr(94)};
  height: ${ptr(120)};
  border-radius: ${ptr(6)}; 
  float: right;
`;

const TopText = styled.div`
border-radius: 8px;
border: 1px solid #D9DBE9;
display: flex;
padding: 12px 16px;
align-items: center;
gap: 10px;
align-self: stretch;
background: var(--grayscale-input-background, #EFF0F6);
margin-bottom:20px;
`;

const FileIconTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(94)};
  height: ${ptr(45)};
  padding: ${ptr(8)};
  background-color: #F15642;
  border-radius: ${ptr(6)};
  position: absolute;
  bottom: ${ptr(18)};
  box-sizing: border-box;
`;

const FileIconTitle = styled.span`
  color: ${colors.white._100};
  font-family: ${fonts.inter.bold};
  font-size: ${ptr(24)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

export {
  Wrapper,
  Container,
  WrapperEmpty,
  TextDrag1,
  TextDrag,
  TextSelect,
  FileIconWrapper,
  DeleteCrossWrapper,
  DeleteCross,
  PreviewWrapper,
  ImagePreview,
  FileNameWrapper,
  FileName,
  TopText,
  FileFormat,
  PreloadContainer,
  FileSizeWrapper,
  FileSize,
  ButtonGroup,
  LoadContainer,
  FileIconContainer,
  FileIconBackground,
  FileIconTitleWrapper,
  FileIconTitle,
  IdWrapper
};




 