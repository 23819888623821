import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './styles';
import { Text } from '../../../../components/Text';
import { Icon } from '../../../../components/Icon';
import { colors } from '../../../../styles/variables';
import { IPayments, RecentTransactionsProps } from '../..';
import { formatCash } from '../../../../utils/money';
import {
  getBeautyDateTimeString,
  getTimeString,
  timeDateMonthString,
} from '../../../../utils/datetime';
import { ModalWrapper } from '../../../../components/ModalWrapper';
import { CompanyInterface } from '../../../../redux/company/ICompany';
import { Table, TableFooterProps } from '../../../../components/Table';
import { failed } from '../../../../components/Toasts';
import debug from '../../../../services/internal/debbug.service';
import paymentsService from '../../../../services/internal/payments/payments.service';
import getQueryString from '../../../../utils/getQueryString';
import { IPagination, Pagination } from '../../../../components/Pagination';
import { IGetQueries } from '../../../../interface';
import { PaymentHistory } from '../../../AnalyticsNReport/TransactionReport';
import { FilterResult } from '../../../AnalyticsNReport/Component/FilterResult';
import { PaymentDetailsViews } from '../../../AnalyticsNReport/Component/PaymentDetailsViews';
import { Preload } from '../../../../components/Preload';
import { PaginationActions } from '../../../../redux/paginationSettings/actions';
import { PaginationUpdated } from '../../../../components/PaginationUpdated';
import { TableUpdated } from '../../../../components/TableUpdated';
import EmptyState from '../../../../components/EmptyState';

interface IStatusResponse {
  code: number | null;
  status: string | null;
  _status: string | null;
}

export interface ModalViewComponent {
  FilterResult: any;
  PaymentDetails: any;
}

interface IStatusColor {
  text: string;
  icon: string;
  backgroud: string;
}

interface IRecentTransactions {
  payments?: RecentTransactionsProps;
}

const checkPaymentStatus = (status: string): IStatusResponse => {
  switch (status.toLocaleLowerCase()) {
    case 'success':
      return { code: 1, status: 'Done', _status: status };
    case 'failed':
      return { code: 0, status: 'Failed', _status: status };
    case 'pending':
      return { code: 2, status: 'Pending', _status: status };
    case 'processing':
      return { code: 3, status: 'Processing', _status: status };
    default:
      return {
        code: null,
        status: status,
        _status: status,
      };
  }
};

const checkStatusColor = (code: number | null): IStatusColor => {
  switch (code) {
    case 0:
      return {
        text: colors.red._100,
        icon: colors.pink._100,
        backgroud: colors.pink._10,
      };
    case 1:
      return {
        text: colors.green._100,
        icon: colors.green._100,
        backgroud: colors.green._90,
      };
    case 2:
      return {
        text: colors.amber._100,
        icon: colors.black._100,
        backgroud: colors.black._10,
      };
    case 3:
      return {
        text: colors.orange._90,
        icon: colors.black._100,
        backgroud: colors.black._10,
      };
    default:
      return {
        text: colors.black._40,
        icon: colors.black._100,
        backgroud: colors.black._10,
      };
  }
};

const checkTransactionTypeColor = (
  fromAccountName: string,
  businessName?: string
) => {
  switch (fromAccountName) {
    case businessName:
      return {
        text: colors.red._100,
        icon: colors.pink._100,
        backgroud: colors.pink._10,
      };
    default:
      return {
        text: colors.green._100,
        icon: colors.green._100,
        backgroud: colors.green._90,
      };
  }
};

const Item: React.FC<IPayments> = (props) => {
  const business = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const [paymentStatus, setPaymentStatus] = useState<IStatusResponse>({
    code: null,
    status: null,
    _status: null,
  });

  useEffect(() => {
    setPaymentStatus(checkPaymentStatus(props.status));
  }, []);
  return (
    <S.Wrapper>
      <S.Aside>
        <S.Icon
          color={
            checkTransactionTypeColor(props.fromAccountName, business?.name)
              .backgroud
          }
        >
          <Icon
            name='two-opposite-arrows'
            color={
              checkTransactionTypeColor(props.fromAccountName, business?.name)
                .icon
            }
            width={24}
            height={24}
          />
        </S.Icon>
        <S.ShortData>
          <Text size={6}>Transfer to {props.receiverName}</Text>
          <Text color={colors.black._40} size={7}>
            {getBeautyDateTimeString(new Date(props.createdAt))}
          </Text>
        </S.ShortData>
      </S.Aside>
      <S.Data>
        <Text bold>₦{formatCash(String(props.amount))}</Text>
        <Text color={checkStatusColor(paymentStatus.code).text} size={7}>
          {props.status === 'pending' && props.actionRequired
            ? `Pending ${props.actionRequired}`
            : paymentStatus.status}
        </Text>
      </S.Data>
    </S.Wrapper>
  );
};

export const RecentTransactions: React.FC<IRecentTransactions> = ({
  payments,
}) => {
  const [text, setText] = useState<string>('');
  const [pay, setPay] = useState<Array<IPayments>>([]);
  const [empty, setEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bottomLoading, setBottomLoading] = useState<boolean>(false);
  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});
  const [pagination, setPagination] = useState<IPagination>({
    limit: 3,
    currentPage: 1,
    pageItems: 1,
    totalPages: 1,
  });

  useEffect(() => {
    PaginationActions.reset();
  }, []);

  const getPayments = async (queriesData?: IGetQueries): Promise<void> => {
    // try {
    //   const queries = queriesData
    //     ? { ...queriesData }
    //     : {
    //         ...additionalQueries,
    //         ...queriesData,
    //         page: 1,
    //         limit: 3,
    //       };

    //   const result = await paymentsService.getPayments(getQueryString(queries));
    //   setAddtionalQueries(queries);
    //   setLoading(false);
    //   const paymentdata = result.data.map((el: IPayments) => ({
    //     ...el,
    //     check: false,
    //   }));
    //   setPagination({
    //     limit: 10,
    //     currentPage: result.meta.page,
    //     pageItems: 100,
    //     totalPages: result.meta.totalPages,
    //   });
    //   setPay(paymentdata);
    // }

    try {
      if (!queriesData?.search) setLoading(true);
      const queries =
        queriesData && queriesData.search
          ? { ...queriesData }
          : {
              page: 1,
              limit: 3,
              ...additionalQueries,
              ...queriesData,
            };

      if (!queriesData?.search) {
        delete queries.search;
        setText('');
      }
      const result = await paymentsService.getPayments(getQueryString(queries));
      setAddtionalQueries(queries);
      setLoading(false);
      const paymentdata = result.data.map((el: IPayments) => ({
        ...el,
        check: false,
      }));
      setPagination({
        limit: result.meta.limit,
        currentPage: result.meta.page,
        pageItems: result.meta.pageCount,
        totalPages: result.meta.totalPages,
      });
      // setPayments(paymentdata);
      setPay(paymentdata);
    } catch (err) {
      debug.error('Error retrieving payments', err);
      failed('Failed', err.message || 'Error retrieving payments');
    }
  };

  const getStatusButton = (el: IPayments) => {
    if (el.status === 'success') {
      return (
        <S.BtnSuccess>
          <Icon name='dot' color={colors.green._100} />
          <Text size={7}>{el.status}</Text>
        </S.BtnSuccess>
      );
    }
    if (el.status === 'processing') {
      return (
        <S.BtnWarning>
          <Icon name='dot' color={colors.amber._100} />
          <Text size={7}>{el.status}</Text>
        </S.BtnWarning>
      );
    }

    if (el.status === 'pending') {
      return (
        <S.BtnPending>
          <Icon name='dot' color='#667085' />
          <Text size={7}>
            {el.status} {el.actionRequired ?? el.actionRequired}
          </Text>
        </S.BtnPending>
      );
    }

    if (el.status === 'failed') {
      return (
        <S.BtnDanger>
          <Icon name='dot' color='#F04438' />
          <Text size={7}>{el.status}</Text>
        </S.BtnDanger>
      );
    }
    if (el.status === 'unknown') {
      return (
        <S.BtnUnknwn>
          <Icon name='dot' color={colors.amber._100} />
          <Text size={6}>{el.status}</Text>
        </S.BtnUnknwn>
      );
    }
    return (
      <S.BtnWarning>
        <Icon name='dot' color='#fff4d8' />
        <Text size={6}>{el.status}</Text>
      </S.BtnWarning>
    );
  };

  useEffect(() => {
    getPayments();
  }, []);

  const [currentPaymentDetails, setcurrentPaymentDetails] = useState<
    PaymentHistory
  >();
  const [currentView, setcurrentView] = useState<string>('');
  const [showmodalView, setshowmodalView] = useState<boolean>(false);

  const ModalView: ModalViewComponent = {
    FilterResult: (
      <FilterResult action={getPayments} showModal={setshowmodalView} />
    ),
    PaymentDetails: <PaymentDetailsViews data={currentPaymentDetails} />,
  };
  const UserModalView = currentView as keyof typeof ModalView;
  const setcurrentPaymentdetails = (details: PaymentHistory) => {
    // const myArray = [];
    // myArray.push(details);

    setcurrentPaymentDetails(details);
    setcurrentView('PaymentDetails');
    setshowmodalView(true);
  };

  const renderModalView = () => (
    <ModalWrapper
      overflowY='auto'
      overflowX='inherit'
      maxHeight='96vh'
      showModal={showmodalView}
      onCloseModal={() => setshowmodalView(false)}
      closeBackground={
        UserModalView === 'PaymentDetails' ? colors.pink._20 : ''
      }
      crossColor={colors.black._100}
    >
      {ModalView[UserModalView]}
    </ModalWrapper>
  );

  // return (
  const renderTableItem = () => (
    <>
      <S.Header>
        <Text style={{ whiteSpace: 'nowrap' }} size={5}>
          Recent Transactions
        </Text>
        <S.SeeAll>
          <Link to={{ pathname: '/payments', state: { tab: 'UserReport' } }}>
            <Text size={5}>See All</Text>
          </Link>
          <Icon
            width={22}
            height={20}
            name='arrow-right-updated'
            color={colors.black._100}
          />
        </S.SeeAll>
      </S.Header>

      {/* {payments?.data.slice(0, 10).map((item, index) => (
        <Item key={index.toString()} {...item} />
      ))} */}

      <S.TableContainer loading={loading}>
        <TableUpdated
          bgColor='white'
          styleBg={{ background: 'white', border: 'none' }}
          // style={{ background: 'white' }}
          header={
            !pay.length
              ? undefined
              : {
                  cells: {
                    0: {
                      align: 'left',
                      children: (
                        <Text color='#667085' size={7}>
                          Trade
                        </Text>
                      ),

                      padding: '1.25rem 1.5rem',
                    },
                    1: {
                      align: 'left',
                      children: (
                        <Text color='#667085' size={7}>
                          Amount
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                    2: {
                      align: 'left',
                      children: (
                        <Text color='#667085' size={7}>
                          Initiated by
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                    3: {
                      align: 'left',
                      children: (
                        <Text color='#667085' size={7}>
                          Status
                        </Text>
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                    4: {
                      align: 'left',
                      children: <Text color='#667085' size={7}></Text>,
                      padding: '1.25rem 1.5rem',
                    },
                  },
                  padding: '1.25rem 0',
                }
          }
          footer={((): TableFooterProps | undefined => {
            if (bottomLoading) {
              return {
                backgroundColor: colors.white._100,
                cells: {
                  0: {
                    align: 'center',
                    children: (
                      <Icon name='small-loading' color={colors.black._100} />
                    ),

                    colspan: 6,
                  },
                },
              };
            }
            if (!pay.length) {
              return {
                backgroundColor: colors.white._100,
                cells: {
                  0: {
                    align: 'center',
                    children: <EmptyState />,
                    padding: '1.25rem 1.5rem',
                    colspan: 6,
                  },
                },
              };
            }
            return undefined;
          })()}
          rows={pay?.map((el: any) => ({
            padding: '1.25rem 0',
            border: {
              color: colors.black._10,
              width: 1,
              style: 'solid',
              sides: ['top'],
            },
            cells: {
              0: {
                align: 'left',
                children: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text color='#101828' size={7}>
                      Transfer
                    </Text>

                    <Text
                      style={{ whiteSpace: 'nowrap' }}
                      color='#A0A3BD'
                      size={8}
                    >
                      {timeDateMonthString(new Date(el.createdAt))} ||{' '}
                      {getTimeString(new Date(el.createdAt))}
                    </Text>
                  </div>
                ),
                padding: '1.25rem 1.5rem',
                display: 'inline-block',
              },
              1: {
                align: 'left',

                children: (
                  <Text
                    style={{ whiteSpace: 'nowrap' }}
                    color='#667085'
                    size={7}
                  >
                    <Text size={6}>{`₦ ${formatCash(el.amount)}`}</Text>
                  </Text>
                ),
                padding: '1.25rem 1.5rem',
              },

              2: {
                align: 'left',
                children: (
                  <Text color='#A0A3BD' size={8}>
                    {el.receiverName}
                  </Text>
                ),
                padding: '1.25rem 1.5rem',
                display: 'inline-block',
              },
              3: {
                align: 'left',

                children: getStatusButton(el),
                padding: '1.25rem 1.5rem',
              },

              4: {
                align: 'left',
                onClick: () => setcurrentPaymentdetails(el),
                children: (
                  <Text bold color='#B10180' size={8}>
                    View
                  </Text>
                ),
                padding: '1.25rem 1.5rem',
              },
            },
          }))}
        />

        {pay.length > 0 && (
          <PaginationUpdated {...pagination} action={getPayments} />
        )}

        {/* {pay.length > 0 && (
          <Pagination {...pagination} action={getBatchDetails} />
        )} */}
      </S.TableContainer>
    </>
  );

  return (
    <S.Container>
      {renderModalView()}

      {loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      ) : (
        renderTableItem()
      )}
    </S.Container>
  );
};
