import React from 'react';

import { colors } from '../../styles/variables';
import { FormTitle } from '../FormTitle';

export interface OnboardingFormHeaderProps {
  /**
   * Title (big text)
   */
  title?: string;
  /**
   * Description (small text)
   */
  description?: string;
  titleColor?: string;
  descriptionColor?: string;
}

export const OnboardingFormHeader: React.FC<OnboardingFormHeaderProps> = ({
  title = '',
  description = '',
  ...props
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    <div>
      <FormTitle
        description={description}
        descriptionColor={props.descriptionColor || '#6E7191'}
        title={title}
        titleColor={props.titleColor || colors.black._20}
      />
    </div>
    {/* circle tracker here */}
  </div>
);
