import styled from 'styled-components';

import { fonts, colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  width: ${ptr(88)};
  height: ${ptr(88)};
`;

const TextWrapper = styled.div`
  font-family: ${fonts.inter.bold};
  color: ${colors.pink._100};
`;

const BigText = styled.span`
  font-size: ${ptr(24)};
`;

const SmallText = styled.span``;

export {
  Wrapper,
  TextWrapper,
  BigText,
  SmallText,
};
