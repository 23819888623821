import { FC } from 'react';
import { fonts } from '../../../styles/variables';

import * as S from './styles';

import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';

interface IProps {
  handleCancel: () => void;
  handleNext: () => void;
  goBack: () => void;
  tokenKey: string;
}
const ManualStep: FC<IProps> = ({
  handleCancel,
  handleNext,
  goBack,
  tokenKey,
}) => {
  const onNext = () => {
    handleNext();
    goBack();
  };
  return (
    <S.ManualContainer>
      <S.TitleContainer>
        <Icon name="arrow_back" onClick={goBack} color="#000000" />
        <Text bold size={4}>
          Set up authenticator app
        </Text>
      </S.TitleContainer>

      <S.ManualStepContainer>
        <ol style={{ fontFamily: fonts.inter.regular, fontSize: 18 }}>
          <li>
            In the Google Authenticator app tap the + then tap{' '}
            <strong style={{ fontFamily: fonts.inter.bold }}>
              {' '}
              Enter a set up key{' '}
            </strong>
          </li>
          <li
            style={{
              maxWidth: '100%',
              wordWrap: 'break-word',
            }}
          >
            Enter your email address and this key (space dont matter){' '}
            <strong
              style={{
                fontFamily: fonts.inter.bold,
              }}
            >
              {' '}
              {tokenKey}
            </strong>
          </li>
          <li>
            Make sure{' '}
            <strong style={{ fontFamily: fonts.inter.bold }}>
              {' '}
              Time based{' '}
            </strong>{' '}
            is selected
          </li>
          <li>
            Tap <strong style={{ fontFamily: fonts.inter.bold }}> Add </strong>{' '}
            to finish
          </li>
        </ol>
      </S.ManualStepContainer>

      <S.ButtonContainer>
        <Button label="Cancel" theme="light" onClick={handleCancel} />
        <div style={{ width: '200px' }}>
          <Button style={{ width: '100%' }} label="Next" onClick={onNext} />
        </div>
      </S.ButtonContainer>
    </S.ManualContainer>
  );
};

export default ManualStep;
