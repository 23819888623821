import styled, { css } from "styled-components";
import { crossBrowserBoxShadow, crossBrowserDisableSelection, crossBrowserTransition, ptr } from "../../../../styles/utils";
import { colors, transition } from "../../../../styles/variables";

interface DropdownState {
    open?: boolean,
    value?: string,
}

interface SingleItemProps {
    first?: boolean;

}


const Input = styled.button`
width: 40%;
    position: relative;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
 
  justify-content: flex-end;
  padding: 0;

  > svg {
    margin-left: ${ptr(14)};
  }
`;

const DropdownContent = styled.div<DropdownState>`
  display: none;
  position: absolute;
  z-index: 1000;
  max-height: ${ptr(450)};
  overflow: auto;
  overflow-x: hidden;
  flex-direction: column;
  background-color: ${colors.white._100};
  top: ${ptr(50)};
  margin-top: ${ptr(8)};
  width: 100%; 
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(6)};
  max-height: ${ptr(450)};
  ${crossBrowserTransition(transition.slow)}
  ${crossBrowserBoxShadow(`0 0 15px ${ptr(0.2)} ${colors.black._10}`)}
  ${(props) => props.open && css`
    display: flex;
  `}
`;

const SingleItemWrapper = styled.div<SingleItemProps>`
  padding: ${ptr(18)} ${ptr(24)};
  border-top: 1px solid ${colors.black._10};
    cursor: pointer;
    :hover {
      background-color: ${colors.black._7};
      > span {
        ${crossBrowserDisableSelection()}
        color: ${colors.black._20};
        font-weight: 600;
      }
    }

  ${(props) => props.first && css`
    border-top: 0;
    
  `}
`;

export { Input, DropdownContent, SingleItemWrapper }