import ErrorApi, { ErrorApiInterface } from '../api/api.error';

export default class ErrorPayments extends ErrorApi {
  public static get NoTokens(): ErrorApiInterface {
    return {
      message: 'Server error, please try again',
      code: 'NO_TOKENS',
    };
  }
}
