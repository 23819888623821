import React, { useState, useEffect } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { OtpInput } from '../../../components/OtpInput';

import { validateObject, ValidateResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { actions, credentialsActions } from '../config';
import { InfoConponent } from '../../../components/Info';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { Icon } from '../../../components/Icon';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';

const SCHEME = {
  pin: (value: string) => value.length === 4,
  // confirmPin: (value: string) => value === pin,
};

const CommonOtherTransactionPinCreate: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */

  const storedPin = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.pin as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  /**
   * Local state initiation region
   */
  const [pin, setPin] = useState<string>(storedPin || '');
  const [ConfirmPin, setConfirmPin] = useState<string>(storedPin || '');
  const [validation, setValidation] = useState<ValidateResult>();
  const [loading, setLoading] = useState(false);

  const [pinVisibility, setPinVisibility] = useState(false);

  const togglePinVisibility = () => {
    setPinVisibility(!pinVisibility);
  };

  /**
   * Custom handlers initiation region
   */
  // const continueHandle = () => {
  //   batch(() => {
  //     dispatch(
  //       OnboardingActions.handleSetData({
  //         pin,
  //       })
  //     );
  //   });
  //   route.push(credentialsActions.CONFIRM_PIN);
  // };

  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.submitPin({
          pin,
          reference,
        });

        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              reference: response.data.reference,
            })
          );
          dispatch(
            OnboardingActions.handleSetOnBoardingSummaryResponse(
              response.data.data
            )
          );
        });
        route.push(actions[response.actionRequired]);
      } catch (err) {
        if (err.code === 'INVALID_ACTION_REQUIRED') {
          route.push(
            actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
          );
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || 'Can not complete request at the moment'
            )
          );
        }
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(validateObject({ pin }, SCHEME));
  }, [pin]);

  return (
    <S.Container>
      <Title color={colors.black._20} title='Set up 4-digit Transaction PIN' />

      <S.InfoContainer>
        <InfoConponent
          style={{ background: '#FFF9EF' }}
          text='Note that this PIN will be required to confirm your transactions'
        />
      </S.InfoContainer>
      <OnboardingCard>
        <S.Wrapper style={{ marginTop: '14px' }}>
          <Text bold color={colors.black._20}>
            Enter PIN
          </Text>
          <OtpInput
            secure={pinVisibility ? true : false}
            codeLength={4}
            value={pin}
            onCodeChange={setPin}
          />
        </S.Wrapper>

        <S.Wrapper style={{ marginTop: '14px' }}>
          <Text bold color={colors.black._20}>
            Confirm PIN
          </Text>
          <OtpInput
            secure={pinVisibility ? true : false}
            codeLength={4}
            value={ConfirmPin}
            onCodeChange={setConfirmPin}
          />
        </S.Wrapper>

        <S.IconCircleWrapper color='transparent' onClick={togglePinVisibility}>
          {pinVisibility ? (
            <Icon name='basic-eye' color='#B10180' />
          ) : (
            <Icon name='eye' color='#B10180' />
          )}

          {pinVisibility ? (
            <Text color='#B10180'>See PIN</Text>
          ) : (
            <Text color='#B10180'> Hide PIN</Text>
          )}
        </S.IconCircleWrapper>

        <S.ButtonGroup>
          <div>
            <Button
              label='Continue'
              loading={loading}
              onClick={continueHandle}
              style={{ width: '304px' }}
              disabled={!validation?.isValid || pin !== ConfirmPin}
            />
          </div>
        </S.ButtonGroup>

        {/* <Text color={colors.black._100} size={6}>
          By clicking Continue, you agree with{' '}
          <Link to='*'>
            <Text color={colors.pink._100}>Terms & Conditions</Text>
          </Link>
        </Text> */}
      </OnboardingCard>
    </S.Container>
  );
};

export default CommonOtherTransactionPinCreate;
