import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { OnboardingActions } from '../../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../../services/internal/onboarding/onboarding.service';
import { Button } from '../../../../components/Button';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import * as S from './styles';
import * as B from '../../components/RangeSlider/styles';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../../../components/DropdownInput';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from '../../../../components/LoadingModal';
import { actions } from '../../../Onboard/config';
import { setOperatorsLimitRoute } from '../../../../routing/setOperators';
import { OnboardingFormHeader } from '../../../../components/OnboardingFormHeader';
import { OnboardingCard } from '../../../../components/OnboardingCard';
import { formatCash } from '../../../../utils/money';

interface IProps {
  closeOperator?: (data?: { success: boolean }) => void;
  handleContinue?: (data: {
    workflowSelected?: string;
    actionRequired?: string;
  }) => void;
}

export const CommonApprovalLimit: React.FC<IProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const route = useHistory();
  const location = useLocation();

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const operators = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.operators as Array<Record<string, string>>
  );

const [authoriser, setAuthoriser] = useState<DropdownOptionProps | null>(null);
const [value, setValue] = useState(0.0);
const [loading, setLoading] = useState(false);
const [saving, setSaving] = useState(false);
const [authorizers, setAuthorizers] = useState<Array<Record<string, string>>>(
  []
);
const [selectedValues, setSelectedValues] = useState<string[]>([]);
useEffect(() => {
  if (operators) {
    const savedOperators = operators ?? [];
    const authorisers = savedOperators.filter(
      (operator: any) => operator.role?.toLowerCase() === 'authoriser'
    );
    setAuthorizers([...authorisers]);
  }
  //}, [leaders]);
}, []);

useEffect(() => {
  if (operators?.length) {
    const savedOperatorsWithApproverLimit = operators
      .filter((ops) => ops.approverLimit)
      .map((ops) => ops.email);
    setSelectedValues(savedOperatorsWithApproverLimit);
  }
}, [operators]);

  const handleOnChange = (e: any) => {
    setValue(e.target.value);
  };

  const MAX = 10000000;
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(value * 100) / MAX}% 100%`,
    };
  };

  const selectAuthoriser = (item: DropdownOptionProps | null) => {
    setAuthoriser(item);
    if (item?.value) {
      let newArr = [...selectedValues, item.value];
      newArr = [...Array.from(new Set(newArr))];
      setSelectedValues([...newArr]);
    }
  };

  async function save() {
    setSaving(true);
    const newAuthorizers = authorizers.map((autho) => {
      if (autho.email === authoriser?.value) {
        autho.approverLimit = value.toString();
      }
      return autho;
    });
    setAuthorizers(newAuthorizers);
    setAuthoriser(null);
    setValue(0);
    setSaving(false);
  }
  const continueHandle = async (skip: boolean) => {
    setLoading(true);
    try {
      let response: any;

      if (
        location.pathname === setOperatorsLimitRoute ||
        props.handleContinue
      ) {
        // if (!skip) {
        response = await onboardingService.setOperatorApproverLimit({
          skip,
          data: authorizers
            .filter((autho) => autho.approverLimit)
            .map((autho) => ({
              email: autho.email,
              approverLimit: Number(autho.approverLimit),
            })),
        });
        // }

        batch(() => {
          dispatch(OnboardingActions.handleResetStacks());
          dispatch(OnboardingActions.handleUnSetEnv());
        });
        //enable reload and trigger state change
        if (props.handleContinue && props.closeOperator) {
          props.closeOperator({
            success: true,
          });
        } else {
          window.location.href = '/dashboard';
        }
      } else {
        // if (skip) {
        response = await onboardingService.submitApprovalLimit({
          skip,
          data: authorizers
            .filter((autho) => autho.approverLimit)
            .map((autho) => ({
              email: autho.email,
              approverLimit: autho.approverLimit,
            })),
          reference,
        });
        route.push(actions[response.actionRequired]);
        // } else {
        //   route.push(nextStep);
        // }
      }
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  return (
    <S.Container>
      <Modal visible={loading} />
      <S.TopTitle>
        <OnboardingFormHeader
          description='This step is optional and skippable.'
          descriptionColor='#6E7191'
          title='Set approval limits'
          titleColor={colors.black._20}
        />
      </S.TopTitle>

      <OnboardingCard>
        <S.Wrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 15,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <S.DropDown>
                <DropdownInput
                  label='Select authoriser'
                  placeholder='Select authoriser'
                  options={authorizers.map((item: any) => ({
                    label: `${item?.firstName?.toUpperCase() ?? item.email} ${
                      item?.lastName?.toUpperCase() ?? ''
                    }`,
                    value: item.email,
                  }))}
                  inputValue={authoriser?.value}
                  onSelect={selectAuthoriser}
                />
              </S.DropDown>

              <S.RangeWrapper>
                <B.Wrapper>
                  <Text size={6} color={colors.black._100}>
                    Set approval amount limit{' '}
                    <span
                      style={{
                        color: '#DB9308',
                      }}
                    >
                      (Max 10 million)
                    </span>
                  </Text>
                  <B.Slider>
                    <input
                      step={50000}
                      style={getBackgroundSize()}
                      type='range'
                      min={0}
                      max={MAX}
                      value={value.toLocaleString()}
                      onChange={handleOnChange}
                    />
                  </B.Slider>
                  <B.Buble>₦{Number(value).toLocaleString()}</B.Buble>
                </B.Wrapper>
                {/* <Slider/> */}
              </S.RangeWrapper>
              <div
                style={{
                  marginTop: 10,
                }}
              ></div>
              <Button
                disabled={saving || !authoriser?.value || !Number(value || 0)}
                // backgroundColor={colors.pink._10}
                // color={colors.pink._100}
                label='Save limit'
                theme='primary'
                size='small'
                onClick={() => save()}
              />
            </div>

            <S.Table>
              <S.TableItem>
                <Text color='#667085'>Authorizer</Text>
                <Text color='#667085'>Approval limit</Text>
              </S.TableItem>
              {authorizers &&
                authorizers.length > 0 &&
                authorizers
                  .filter((auth) => selectedValues.includes(auth.email))
                  .map((operator, index) => (
                    <S.TableItem>
                      <Text color='#667085' size={5}>
                        {/* {operator.firstName}  {operator.lastName} */}
                        {operator.email}
                      </Text>
                      <Text color='#667085' size={5}>
                        {`₦${formatCash(operator.approverLimit)}`}
                      </Text>
                    </S.TableItem>
                  ))}

              <S.TableItem />
            </S.Table>
          </div>
          <S.ButtonGroup>
            <Button
              disabled={loading}
              backgroundColor={colors.pink._10}
              color={colors.pink._100}
              label='Skip'
              theme='info'
              onClick={() => continueHandle(true)}
            />
            <S.FullButton>
              <Button
                onClick={() => continueHandle(false)}
                disabled={
                  !authorizers.filter((autho) => autho.approverLimit).length ||
                  loading
                }
                label='Continue'
              />
            </S.FullButton>
          </S.ButtonGroup>
        </S.Wrapper>
      </OnboardingCard>
    </S.Container>
  );
};
