import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const TextInputWrapper = styled.div`
  margin-bottom: ${ptr(5)};
`;
const TwoFactorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${ptr(30)};
  width: ${ptr(634)};
  gap: ${ptr(20)};
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;
const Container = styled.div``;
const IconContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  span {
    line-height: 28px !important;
  }
  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  /* button {
    width: 70px;
  } */
`;

export {
  TextInputWrapper,
  TwoFactorContainer,
  IconContainer,
  TextContainer,
  ButtonContainer,
};
