import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Text';
import { CompanyInterface } from '../../../redux/company/ICompany';
import { colors } from '../../../styles/variables';
import { getBeautyDateTimeString } from '../../../utils/datetime';
import { formatCash } from '../../../utils/money';
import { PaymentHistory } from '../../PaymentList';
import { BatchHistory } from '../BulkPayment';
import { downloadPDF } from '../../../utils/download';

import * as S from '../styles';

interface IPaymentProps {
	data?: PaymentHistory;
}

export const PaymentDetailsViews: React.FC<IPaymentProps> = ({ data }) => {
	const activeCompany = useSelector(
		(state: { company: CompanyInterface }) => state.company.active
	);
	const getStatusColor = (status: string) => {
		if (status === 'pending') {
			return colors.orange._90;
		}
		if (status === 'failed') {
			return colors.pink._80;
		}
		if (status === 'success') {
			return colors.green._100;
		}
		if (status === 'unknown') {
			return colors.amber._100;
		}
		return colors.orange._90;
	};

	const getMaskedAccountNumber = (number: string) => {
		const first6 = number.substring(0, 6).replace(/\d/g, '*');
		const last4 = number.substring(6, 10);
		return `${first6}${last4}`;
	};
	return (
		<S.InnerContainer>
			<div id='pdf'>
				<S.TableHeaderStyle id='header'>
					<div>
						<Icon name='logo' color={colors.black._100} width={40} height={40} />
					</div>
					<Text color={colors.pink._100} size={3} bold>
						Transaction Details
					</Text>
				</S.TableHeaderStyle>
				{data && (
					<S.TableStyle>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Payment Description
								</Text>
							</td>
							<td>
								<Text color={colors.black._100} size={5}>
									{data.comment}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Amount
								</Text>
							</td>
							<td>
								<Text color={colors.black._100} size={5}>
									{`₦ ${formatCash(data.amount)}`}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Destination account
								</Text>
							</td>
							<td>
								{' '}
								<Text color={colors.black._100} size={5}>
									{data.receiverNuban}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Destination bank
								</Text>
							</td>
							<td>
								{' '}
								<Text color={colors.black._100} size={5}>
									{data.destinationBank}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Receiver name
								</Text>
							</td>
							<td>
								{' '}
								<Text color={colors.black._100} size={5}>
									{data.receiverName}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Sender Account
								</Text>
							</td>
							<td>
								{' '}
								<Text color={colors.black._100} size={5}>
									{data.fromAccountNo ? getMaskedAccountNumber(data.fromAccountNo) : '-'}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Sender Name
								</Text>
							</td>
							<td>
								{' '}
								<Text color={colors.black._100} size={5}>
									{activeCompany?.name || '-'}
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Date
								</Text>
							</td>
							<td>
								{' '}
								<Text color={colors.black._100} size={5}>
									{/* Sept 16, 2021 | 5: 40 PM */}
									{getBeautyDateTimeString(new Date(data.createdAt), false)}
								</Text>
							</td>
						</tr>
					</S.TableStyle>
				)}
				<hr />
				{data && (
					<S.TableStyle>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Payment Reference
								</Text>
							</td>
							<td>
								<Text color={colors.black._100} size={5}>
									{data.requestReference || data.reference}
								</Text>
							</td>
						</tr>
						{data.sessionId && (
							<tr>
								<td>
									<Text color={colors.black._100} size={5} bold>
										Session ID
									</Text>
								</td>
								<td>
									<Text color={colors.black._100} size={5}>
										{data.sessionId}
									</Text>
								</td>
							</tr>
						)}
						{data.transactionId && (
							<tr>
								<td>
									<Text color={colors.black._100} size={5} bold>
										Transaction ID
									</Text>
								</td>
								<td>
									<Text color={colors.black._100} size={5}>
										{data.transactionId}
									</Text>
								</td>
							</tr>
						)}
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Transaction Type
								</Text>
							</td>
							<td>
								<Text color={colors.black._100} size={5}>
									Transfers
								</Text>
							</td>
						</tr>
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
									Transaction Status
								</Text>
							</td>
							<td>
								{' '}
								<Text color={getStatusColor(data.status)} size={5}>
									{data.status}
								</Text>
							</td>
						</tr> 
					{ data.gatewayResponse !== null && data.status === 'failed' &&
						<tr>
							<td>
								<Text color={colors.black._100} size={5} bold>
								Failure response
								</Text>
							</td>
							<td>
								{' '}
								<Text color={getStatusColor(data.status)} size={5}>
									{data.gatewayResponse}
								</Text>
							</td>
						</tr>
						}
						{/* {data.status === "failed" && (
              <tr>
                <td>
                  <Text color={colors.black._100} size={5} bold>
                    Reason of failure
                  </Text>
                </td>
                <td>
                  <Text color={colors.black._100} size={5}>
                    {data.gatewayResponse}
                  </Text>
                </td>
              </tr>
            )} */}
					</S.TableStyle>
				)}

				<S.FullButtonWrapper id='action'>
					{(data?.status === 'success' || data?.status === 'failed') && (
						<Button
							label='Download Reciept'
							onClick={() => downloadPDF('pdf', 'action', 'Payment', 'header')}
						/>
					)}
				</S.FullButtonWrapper>
			</div>
		</S.InnerContainer>
	);
};
