import styled from "styled-components";

const LoaderContainer = styled.div`
border-radius: 53.571px;
background: #FFF;
box-shadow: 0px 0px 1.0714285373687744px 0px rgba(0, 0, 0, 0.20), 0px 0px 34.28571319580078px -8.571428298950195px rgba(0, 0, 0, 0.12), 0px 34.28571319580078px 34.28571319580078px -8.571428298950195px rgba(0, 0, 0, 0.08);
display:flex;
justify-content: center;
align-items: center;
width: 50px;
height: 50px;
padding: 17.143px;
`

export { LoaderContainer }