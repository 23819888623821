import React, { useEffect, useRef, useState } from 'react';

import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
import { Text } from '../Text';
import { AddNewBeneficiaryTypeModal } from '../AddNewBeneficiaryTypeModal';
import beneficiaryService from '../../services/internal/beneficiary/beneficiary.service';
import debug from '../../services/internal/debbug.service';
import { failed } from '../Toasts';

export interface SelectBeneficiaryProps {
  types?: any,
  setTypes?: (name: string, value: any) => void,
  label?: string,
  list: Array<IBeneficiary> | undefined,
  setList: (value: Array<IBeneficiary>) => void,
  addedElements?: (value: IBeneficiary) => void,
}

export interface IBeneficiary {
  businessId?: string,
  createdAt?: string,
  id: string,
  name: string,
  slug: string,
  updatedAt?: string,
  check?: boolean,
}

export interface SingleItemProps {
  setType?: (item: IBeneficiary) => void,
  itemData: IBeneficiary,
}

const SingleItem: React.FC<SingleItemProps> = ({
  itemData, setType = () => {},
}) => (
  <S.SingleItemWrapper onClick={() => setType(itemData)}>
    <Text size={5} color={colors.black._100}>
      {itemData.name}
    </Text>
  </S.SingleItemWrapper>
);

export const SelectBeneficiaryType: React.FC<SelectBeneficiaryProps> = ({
  setTypes = () => {},
  types,
  addedElements = () => {},
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef<any>();
  const inputRef = useRef<any>();

  const onAdd = (v: IBeneficiary) => {
    setTypes('types', [v, ...types]);
  };

  const renderItems = () => {
    const arr = props.list?.filter((el: IBeneficiary) => (
      el.id !== types?.filter((t: IBeneficiary) => t.id === el.id)[0]?.id));
    return arr?.map((item) => (
      <SingleItem
        setType={onAdd}
        itemData={item}
      />
    ));
  };

  const removeType = (id: string) => {
    setTypes('types', [...types.filter((el: IBeneficiary) => el.id !== id)]);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      });
    }
    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, [open]);

  const onClickBlock = (e: { target: any; }) => {
    if (inputRef.current !== e.target) {
      if (!open) {
        inputRef.current.focus();
      } else {
        setOpen(false);
      }
    }
  };

  const addNewType = async (item: {
    name: string,
  }): Promise<void> => {
    try {
      setLoading(true);
      const result = await beneficiaryService.createBeneficiaryType(
        { name: item.name },
      );
      addedElements(result);
      props.list?.push(result);
      props?.setList(props.list || []);
      onAdd(result);
      setModalVisible(false);
    } catch (err) {
      debug.error('Failed to create new beneficiary type', err);
      failed('Failed', 'Failed to create new beneficiary type');
    }
    setLoading(false);
  };

  return (
    <>
      <AddNewBeneficiaryTypeModal
        visible={modalVisible}
        onCloseModal={() => setModalVisible(false)}
        setType={(item) => addNewType(item)}
        loading={loading}
      />
      <S.Container ref={ref}>
        <S.Wrapper>
          {types?.length > 0 ? (
            <S.Row onClick={() => setOpen(true)}>
              {types.map((el: IBeneficiary) => (
                <S.Item>
                  <Text size={6}>
                    {el.name}
                  </Text>
                  <S.CrossBlock onClick={() => removeType(el.id)}>
                    <S.Cross />
                  </S.CrossBlock>
                </S.Item>
              ))}
            </S.Row>
          ) : (
            <S.StyledLabel>
              {props.label}
            </S.StyledLabel>
          )}
          <S.TouchableZone
            ref={inputRef}
            onClick={onClickBlock}
            onFocus={() => setOpen(true)}
          />
          <S.ArrowIconWrapper
            rotate={open ? 90 : -90}
            onClick={() => setOpen(!open)}
          >
            <Icon name="arrow-left" width={6} color={colors.black._100} />
          </S.ArrowIconWrapper>
        </S.Wrapper>

        <S.SelectBeneficiaryTypeContent open={open}>
          {renderItems()}
          <S.LastItem onClick={() => { setModalVisible(true); setOpen(!open); }}>
            <S.PlusBlock>
              <S.Plus />
            </S.PlusBlock>
            <Text size={5} color={colors.black._100}>Add new type</Text>
          </S.LastItem>
        </S.SelectBeneficiaryTypeContent>
      </S.Container>
    </>
  );
};
