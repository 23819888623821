/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';

import { validateObject, ValidateResult } from '../../../utils/validation';

import * as S from './styles';
import { colors } from '../../../styles/variables';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { actions } from '../config';
import { FormTitle } from '../../../components/FormTitle';
import { Text } from '../../../components/Text';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { Loader } from '../../../components/Loader';
import { OnboardingOtpModal } from '../../../components/OnboardingOtpModal';
import { OnboardingFormHeader } from '../../../components/OnboardingFormHeader';
import { success } from '../../../components/Toasts';

const SCHEME = {
  accountNo: (value: string) => value.length === 10,
  email: (value: string) => value.length > 0,
  phoneNumber: (value: string) => value.length > 0,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CorporateSwitcherHasNotEnterDetails: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean
  );

  const corporateAccountNumber = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporateAccountNumber as string
  );

  const corporateEmailAddress = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporateEmailAddress as string
  );

  const corporatePhoneNumber = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporatePhoneNumber as string
  );

  const corporateBusinessName = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporateBusinessName as string
  );

  /**
   * Local state initiation region
   */
  const [accountNo, setAccountNo] = useState<string>(
    corporateAccountNumber || ''
  );
  const [businessName, setBusinessName] = useState<string>(
    corporateBusinessName || ''
  );
  const [email, setEmail] = useState<string>(corporateEmailAddress || '');
  const [reference, setReference] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>(
    corporatePhoneNumber || ''
  );
  const [loading, setLoading] = useState(false);
  const [
    accountValidation,
    setAccountValidation,
  ] = useState<ValidateResult | null>(null);
  const [confirm, setConfirm] = useState(false);
  const [actionRequired, setActionRequired] = useState<string>();
  const [showOtp, setShowOtp] = useState(false);

  /**
   * Custom handlers initiation region
   */

  /**
   * useEffect region
   */
  useEffect(() => {
    setAccountValidation(
      validateObject(
        {
          accountNo,
        },
        SCHEME
      )
    );
  }, [accountNo]);

  const continueHandle = async () => {
    try {
      if (confirm) {
        if (actionRequired) {
          if (actionRequired === 'SUBMIT_OTP') {
            toggleOtp();
            return;
          }
          route.push(actions[actionRequired]);
        }
      }
      if (!confirm) {
        setLoading(true);
        const response = inApp
          ? await onboardingService.sendOtpForBusiness({
              nuban: accountNo,
            })
          : await onboardingService.sendGuestOtpForBusiness({
              nuban: accountNo,
            });
        setConfirm(true);

        setPhoneNumber(response.data.maskedPhone);
        setEmail(response.data.maskedEmail);
        setBusinessName(response.data.businessName);
        setReference(response.data.reference);
        setActionRequired(response.actionRequired);

        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              type: 'create profile',
              vbankAccount: accountNo,
              maskedEmail: response.data.maskedEmail,
              maskedPhone: response.data.maskedPhone,
              reference: response.data.reference,
              directors: [
                ...(response.data.proprietors
                  ? [...response.data.proprietors]
                  : []),
              ],
            })
          );
        });
      }
    } catch (err) {
      const error = err as Error;
      dispatch(
        OnboardingActions.handleSetError(
          error.message || 'Can not complete request at the moment'
        )
      );
    }
    setLoading(false);
  };

  const resendFn = async () => {
    try {
      setLoading(true);
      const response = inApp
        ? await onboardingService.sendOtpForBusiness({
            nuban: accountNo,
          })
        : await onboardingService.sendGuestOtpForBusiness({
            nuban: accountNo,
          });
      success('OTP sent', 'Another OTP has been sent to you');
    } catch (err) {
      const error = err as Error;
      dispatch(
        OnboardingActions.handleSetError(
          error.message || 'Can not complete request at the moment'
        )
      );
    }
    setLoading(false);
  };

  const toggleOtp = () => {
    setShowOtp((prev) => !prev);
  };

  const next = () => {
    if (actionRequired) route.push(actions[actionRequired]);
  };

  return (
    <>
      <S.Container>
        <OnboardingFormHeader
          description='Provide your company’s details'
          descriptionColor='#6E7191'
          title='Company information'
          titleColor={colors.black._20}
        />
        <OnboardingCard>
          <S.Wrapper>
            <TextInput
              type='currency'
              mask={createNumberMask(numberMaskOptions)}
              label='Corporate account number'
              value={accountNo}
              onChange={setAccountNo}
              disabled={loading}
            />
          </S.Wrapper>

          {loading && <Loader />}

          {confirm && (
            <S.Wrapper>
              <div style={{ marginTop: 20, marginBottom: 10 }}>
                <Text color={colors.black._20} size={5} bold>
                  Confirm your details
                </Text>
              </div>
              <TextInput
                type='text'
                mask={createNumberMask(numberMaskOptions)}
                label='Company name'
                value={businessName}
                disabled
                onChange={setAccountNo}
              />
              <div style={{ display: 'flex', gap: 15, width: '100%' }}>
                <div style={{ flex: 1 }}>
                  <TextInput
                    type='text'
                    mask={createNumberMask(numberMaskOptions)}
                    label='Email Address'
                    value={email}
                    disabled={true}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TextInput
                    type='text'
                    mask={createNumberMask(numberMaskOptions)}
                    label='Phone Number'
                    disabled={true}
                    value={phoneNumber}
                  />
                </div>
              </div>
            </S.Wrapper>
          )}
          <S.ButtonContainer>
            <Button
              label='Continue'
              // size='small'
              style={{ height: 40 }}
              loading={loading}
              onClick={() => continueHandle()}
              disabled={!accountValidation?.isValid || loading}
            />
          </S.ButtonContainer>
        </OnboardingCard>
      </S.Container>
      <OnboardingOtpModal
        showModal={showOtp}
        data={{ email, phoneNumber }}
        onCloseModal={toggleOtp}
        resendFn={resendFn}
      />
    </>
  );
};
export default CorporateSwitcherHasNotEnterDetails;
