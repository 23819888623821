import React, { useEffect } from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";

import { colors } from "../../styles/variables";
import { Icon } from "../Icon";
import * as S from "./styles";
import { Details } from "../../utils/onboardModalDetails";
import { useHistory } from "react-router";

export interface OperatorsModalProps {
  onCloseModal?: () => void;
  /**
   * Show modal
   */
  showModal?: boolean;
  crossColor?: string;
  overflowY?: string;
  overflowX?: string;
  maxHeight?: string | null;
  maxWidth?: string | null;
  onBottom?: () => void;
  closeBackground?: string;
  details?: Details | null;
}

export const OperatorsModal: React.FC<OperatorsModalProps> = ({
  showModal = false,
  crossColor = colors.black._100,
  overflowY = "auto",
  overflowX = "auto",
  maxHeight = null,
  maxWidth = "90%",
  onBottom = () => {},
  ...props
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  const route = useHistory();
  const proceedRoute = props.details ? props.details.route : "/";

  return (
    <BottomScrollListener onBottom={onBottom}>
      {(scrollRef: any) => (
        <S.Container showModal={showModal}>
          <S.Wrapper
            overflowY={overflowY}
            overflowX={overflowX}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            ref={scrollRef}
          >
            <S.Header>
              <S.HeaderTitle>
                Choose operator flow
              </S.HeaderTitle>
              {props.onCloseModal && (
                <S.CrossWrapper background={props.closeBackground}>
                  <Icon
                    width={14}
                    height={14}
                    name="cross"
                    color={crossColor}
                    onClick={props.onCloseModal}
                  />
                </S.CrossWrapper>
              )}
            </S.Header>
            {/* <S.InfoWrapper>
              <p style={{ fontWeight: "bold" }}>&#9432;</p>
              <div>
                {props.details &&
                  props.details.info.map((info, index) => (
                    <p key={index}>{info}</p>
                  ))}
              </div>
            </S.InfoWrapper>
            <S.ListWrapper>
              {props.details &&
                props.details.list.map((item, index) => (
                  <S.ListItem key={index}>{item}</S.ListItem>
                ))}
            </S.ListWrapper>
            <S.ButtonsWrapper>
              <S.Button
                background={colors.black._40}
                hover={colors.black._60}
                onClick={props.onCloseModal}
              >
                Back
              </S.Button>
              <S.Button
                background={colors.pink._100}
                hover={colors.pink._80}
                onClick={() => route.push(proceedRoute)}
              >
                Proceed
              </S.Button>
            </S.ButtonsWrapper> */}
            {props.children}
          </S.Wrapper>
        </S.Container>
      )}
    </BottomScrollListener>
  );
};
