import styled, { } from 'styled-components';
import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';
// import { hexToRgbA, ptr } from '../../../../styles/utils';

const Container = styled.div` 
`;

const InnerContainer = styled.div` 
 width: ${ptr(640)};
@media (max-width: ${ptr(740)}) {
  width: auto;
}
`;

const ModalWrapper = styled.div`
padding: ${ptr(50)};
 `;
const CheckboxWrapper = styled.div`
    padding: 10px;
    border: 1px solid  ${colors.black._40};
    border-radius: 50px;
    margin-bottom: 10px;
`;

const ButtonWrapper1 = styled.div`
margin-left: auto;
margin-right: auto;
width: 100%;
margin-top:30px;
align-items:center;

display: flex;
button{
  width:100%;
}

`;
const TextInputContainer = styled.div`
display:flex;
/* margin-bottom: 10px; */
`;
const TextInputWrapper = styled.div`
flex:1;
margin-bottom: 10px;
position: relative;
`;
const SpaceWrapper = styled.div`
margin: 0 5px;
`;
const CheckboxContainer = styled.div`
display: flex;
flex-wrap:wrap;
margin-bottom: 10px;
`;

const MarginBottom = styled.div`
margin-bottom: 20px;
`;

const Header = styled.div`
  position: sticky;
  top:30px;
  background-color:#ffffff;
  z-index:99999;
  /* padding-top:50px; */
`;

const TextMarginBottom = styled.div`
margin-bottom: 10px;
`;

const DropDownMarginBottom = styled.div`
margin-bottom: 40px;
`;

const CustomMarginBottom = styled.div`
margin-bottom: 30px;
`;

export {
  Container,
  MarginBottom,
  CheckboxWrapper,
  ButtonWrapper1,
  ModalWrapper,
  TextInputContainer,
  TextInputWrapper,
  SpaceWrapper,
  CheckboxContainer,
  InnerContainer,
  TextMarginBottom,
  DropDownMarginBottom,
  CustomMarginBottom,
  Header,
};
