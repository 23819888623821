import styled from 'styled-components';

import { colors, transition } from '../../styles/variables';
import { crossBrowserBoxShadow, crossBrowserTransition, ptr } from '../../styles/utils';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(48)};
  height: ${ptr(48)};
  border-radius: 50%;
  background-color: ${colors.pink._10};
  border: 0;
  
  ${crossBrowserTransition(transition.fast)}
  
  :hover {
    opacity: 0.7;
  }
  
  :active {
    background-color: ${colors.pink._20};
  }
  
  :focus {
    ${crossBrowserBoxShadow(`inset 0 0 0 ${ptr(2)} ${colors.pink._20}`)}
  }
`;

export {
  Button,
};
