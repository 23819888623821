import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ActionInterface } from '../IAction';
import { AppDispatch } from '../index';
import { BusinessInterface } from '../../services/internal/payments/IPayments';
import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';

export const SET_LIST = 'SET_LIST';

export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const companySetList = (
  payload: Array<BusinessInterface>,
): ActionInterface<Array<BusinessInterface>> => ({
  type: SET_LIST,
  payload,
});

const companySetActive = (
  payload: BusinessInterface,
): ActionInterface<BusinessInterface> => ({
  type: SET_ACTIVE,
  payload,
});

const getError = (payload: string): ActionInterface<string> => ({
  type: SET_ERROR,
  payload,
});

const removeError = () => ({
  type: REMOVE_ERROR,
});

export class CompanyActions {
  public static getCompaniesList(): ThunkAction<
  Promise<void>,
  unknown,
  unknown,
  AnyAction
  > {
    return async (dispatch: AppDispatch) => {
      try {
        const result = await paymentsService.getBusinesses();
        dispatch(companySetList(result));
      } catch (error: any) {
        debug.error(error);
        // this error will be displayed on the main menu
        dispatch(getError('Unable to get businesses at this time'));
        dispatch(removeError());
      }
    };
  }

  public static removeError(): ThunkAction<Promise<void>, unknown, unknown, AnyAction> {
    return async (dispatch: AppDispatch) => {
      dispatch(removeError());
    };
  }

  public static getActiveCompany(): ThunkAction<
  Promise<void>,
  unknown,
  unknown,
  AnyAction
  > {
    return async (dispatch: AppDispatch) => {
      try {
        const result = await paymentsService.getActiveBusiness();
        dispatch(companySetActive(result));
      } catch (error: any) {
        debug.error(error);
        // this error will be displayed on the main menu
        dispatch(getError('Unable to get active business at this time'));
        dispatch(removeError());
      }
    };
  }
}
