/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { TrusteeInfoInterface } from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../../utils/validation';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { actions } from '../config';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { SIGN_UP } from '../constants';
import SignUpManagementInfo from './components/SignUpManagementInfo/index';
import { InfoConponent } from '../../../components/Info';
import { OnboardingCard } from '../../../components/OnboardingCard';

import { InfoFormMD } from '../../Onboarding/components/InfoFormMD';
import { MinimizedInfo } from '../../../components/MinimizedInfo';
import { ptr } from '../../../styles/utils';

const SCHEME = {
  firstName: (value: string) => value.length > 1,
  lastName: (value: string) => value.length > 1,
  phone: (value: string) => value.length === 11,
  email: (value: string) => validator.isEmail(value),
};

const directorDefaultItem = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

const CommonManagementInfo: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const onboardingType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.type as string
  );

  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<TrusteeInfoInterface>
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      (state.onboardingStack.businessInfo.businessType as string) ||
      (state.onboardingStack.data.businessType as string)
  );
  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<TrusteeInfoInterface>>(
    leaders?.length
      ? leaders.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
          email: item.email,
        }))
      : [directorDefaultItem]
  );
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */

  const [addTrustee, setAddTrustee] = useState(false);

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };
  const push = () => {
    // setCollection([...collection, directorDefaultItem]);
    // setCollection([...collection]);
    if (validationCollection?.isValid) {
      const newCollection = collection.map((coll) => {
        coll.isAdded = true;
        coll.isEdit = false;
        return coll;
      });

      setCollection([...newCollection, directorDefaultItem]);
    }
  };

  const editInfo = (index: number) => {
    const newCollection = [...collection];
    newCollection[index].isEdit = true;
    setCollection([...newCollection]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: TrusteeInfoInterface, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }

          return el;
        }) as Array<TrusteeInfoInterface>
    );
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  const continueHandle = async () => {
    setLoading(true);
    const newCollection = collection.map((_collection) => {
      delete _collection.isAdded;
      delete _collection.isEdit;
      return _collection;
    });
    try {
      const response = await onboardingService.submitDirectorsDetails({
        directors: newCollection,
        reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            directors: [...collection],
            reference: response.data.reference,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  function getButtonLabel() {
    switch (businessType) {
      case 'partnership':
        return 'partner';
      case 'co_operative_society':
        return 'principal officer';
      case 'trustee_ngo':
        return 'trustee';
      default:
        return 'director';
    }
  }

  if (onboardingType === SIGN_UP) return <SignUpManagementInfo />;

  return (
    <S.Container>
      <Title
        color={colors.black._20}
        title={
          businessType === 'co_operative_society'
            ? 'Provide details of all Principal Officers '
            : 'Company management’s details'
        }
        subTitle='Minimum requirement is 2'
      />

      <S.InfoContainer>
        <InfoConponent
          style={{ background: '#FFF9EF' }}
          text='Note that the consent of person provided here will be required for the account to become operational on VBiz.'
        />
      </S.InfoContainer>

      <OnboardingCard>
        {collection.map((item, index) => (
          <div style={{ marginBottom: '30px' }}>
            {!item.isAdded || item.isEdit ? (
              <InfoFormMD
                firstName={item.firstName}
                lastName={item.lastName}
                phone={item.phone}
                email={item.email}
                index={index}
                removeFromIndex={0}
                remove={remove}
                onChange={onChangeValue}
                emailIsValid={
                  validationCollection?.data[index]?.data.email.isValid
                }
              />
            ) : (
              <MinimizedInfo
                title={`${item.firstName} ${item.lastName}`}
                description={`${item.phone} || ${item.email}`}
                handleEdit={() => editInfo(index)}
                handleDelete={() => remove(index)}
              />
            )}
          </div>
        ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <S.Add onClick={push}>
            <Icon name='plus' color='#000000' />
            <Text color='#7F91A8'>Add another {getButtonLabel()} </Text>
          </S.Add>

          {collection.length > 1 && (
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                marginBottom: ptr(40),
                cursor: 'pointer',
              }}
              onClick={() =>
                collection.length > 1 && remove(collection.length - 1)
              }
            >
              <Icon name='cancel' color={colors.red._100} />
              <Text size={6} color={colors.red._100}>
                Cancel
              </Text>
            </div>
          )}
        </div>
        <S.ButtonGroup>
          <div>
            <Button
              loading={loading}
              style={{ width: 304 }}
              onClick={continueHandle}
              label='Continue'
              disabled={
                !validationCollection?.isValid ||
                loading ||
                collection.length < 2
              }
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
export default CommonManagementInfo;
