import React from 'react';

import * as S from './styles';
import { BorderType } from '../../styles/utils';
import { TableCell, TableCellProps } from '../TableCell';

export interface TableRowProps {
  /**
   * Cell object
   */

  cells: {
    readonly [columnId: number]: TableCellProps;
  };
  /**
   * Is row disabled
   */
  disabled?: boolean;

  label?: string;
  /**
   * Row height
   */
  height?: number;
  /**
   * Cell's padding
   */
  padding?: number | string;
  /**
   * Cell's padding
   */
  backgroundColor?: string;
  /**
   * Row border
   */
  border?: BorderType;
  /**
   * onClick row handler
   */
  onClick?: () => void;
}

const TableRowComponent: React.FC<TableRowProps> = ({
  cells = {},
  disabled = false,
  ...row
}) => (
  <S.TableRowWrapper disabled={disabled} {...row}>
    {Object.entries(cells).map(([columnId, cell]) => (
      <TableCell
        key={columnId}
        height={row.height}
        padding={row.padding}
        {...cell}
      >
        {cell.children}
      </TableCell>
    ))}
  </S.TableRowWrapper>
);

export const TableRow = React.memo(TableRowComponent);
