export const insertParams = (path: string, params: { [key: string]: string }): string => {
  const paramKeys = Object.keys(params);
  const newPath = path.split('/:');
  paramKeys.forEach((key) => {
    if (newPath.includes(key)) {
      newPath.splice(newPath.indexOf(key), 1, `/${params[key]}`);
    }
  });
  return newPath.join('');
};
