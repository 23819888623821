import styled from 'styled-components';

import { ptr } from '../../../../styles/utils';

const ResendCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${ptr(64)};
  padding-bottom: ${ptr(64)};
`;

export {
  ResendCodeWrapper,
};
