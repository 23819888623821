/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { IBeneficiaries } from './view';
import { failed } from '../../components/Toasts';
import { errorToString, sheetToBulkJsonArray, sheetToJsonArray } from '../../services/internal/app';
import beneficiaryService from '../../services/internal/beneficiary/beneficiary.service';
import debug from '../../services/internal/debbug.service';
import { IBulkTransferFileContent } from '../../components/UploadFileModal';

const beneficiaryLimit = process.env.REACT_APP_BENEFICIARY_LIMIT;

export const endPoints = {
  upload: 'beneficiaries​/bulk',
};
export const beneficiaryFileRegex = /^[a-zA-Z 0-9-]+\.(xls|xlsx)$/;

export const onFileUpload = async (file: File): Promise<IBeneficiaries[] | void> => {
  // setUploadFileModal(false);

  if (file && beneficiaryFileRegex.test(file.name)) {
    try {
      const jsonArray = await sheetToJsonArray(file);
      if (jsonArray.length > 0) {
        const newArr = jsonArray.map((arr) => ({
          bankCode: arr.bankCode, nuban: arr.accountNumber, defaultAmount: arr.amount, tags: arr.groupIdentifier,
        }));
        const ibeneficiaries: IBeneficiaries[] | void = await beneficiaryService.saveFile(newArr);
        return ibeneficiaries;
      }
      failed('Failed', 'Please add inputs for beneficiaries.');
      return [];
    } catch (e) {
      failed('Uploading Error', errorToString(e));
      return [];
    }
  } else if (file) {
    failed('Unrecognized File', 'Download and use template.');
    return [];
  }
  return [];
};

export const onBulkTransactionUpload = async (file: File): Promise<IBulkTransferFileContent[]> => {
  if (file && beneficiaryFileRegex.test(file.name)) {
    try {
      const jsonArray:unknown = await sheetToBulkJsonArray(file);
      const resp = jsonArray as IBulkTransferFileContent[];
      if (Number(beneficiaryLimit) !== 0 && jsonArray && resp.length > Number(beneficiaryLimit)) {
        failed('Uploading Error', `Please limit beneficiary upload to ${beneficiaryLimit} beneficiaries`);
      } else {
        return resp;
      }
    } catch (e) {
      if (Array.isArray(e)) {
        e.forEach((err) => failed('Uploading Error', err));
      } else {
        failed('Uploading Error', errorToString(e));
      }
      return [];
    }
  } else if (file) {
    failed('Unrecognized File', 'Download and use template.');
    return [];
  }
  return [];
};

export const handleDownloadFile = async (bulkTransfer?:boolean): Promise<void> => {
  try {
    if (bulkTransfer) {
      const link = document.createElement('a');
      link.href = '../../images/documents/bulk-transactions.xlsx';
      link.setAttribute(
        'download',
        'bulk-transaction.xlsx',
      );
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      return;
    }
    const response = await beneficiaryService.downloadTemplate();
    window.location.href = response as string;
  } catch (err) {
    debug.error('Failed download beneficiaries template', err);
  }
};

export const taggedBeneficiaries = (mBeneficiaries:
Array<IBeneficiaries>): Record<string, Array<IBeneficiaries>> => {
  const beneficiaryTags = mBeneficiaries.reduce((taggedBeneficiary: Record<string, Array<IBeneficiaries>>,
    data: IBeneficiaries):Record<string, Array<IBeneficiaries>> => {
    const compileTag = (tagName: string) => {
      data.check = false;
      if (taggedBeneficiary[tagName] !== undefined) {
        taggedBeneficiary[tagName] = [...taggedBeneficiary[tagName], ...[data]];
      } else {
        taggedBeneficiary[tagName] = [data];
      }
    };
    if (Array.isArray(data.tags)) {
      data.tags.forEach((value: { name: string }) => compileTag(value.name));
    } else {
      compileTag(data.tags.name);
    }
    return taggedBeneficiary;
  }, {});
  return Object.keys(beneficiaryTags).sort().reduce((stack: Record<string, Array<IBeneficiaries>>, key: string) => {
    stack[key] = beneficiaryTags[key];
    return stack;
  }, {});
};
