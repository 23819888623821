import ErrorApi, { ErrorApiInterface } from '../api/api.error';

export default class ErrorOnboarding extends ErrorApi {
  public static get Fatal(): ErrorApiInterface {
    return {
      message: 'Subaccount server error, please try again',
      code: 'FATAL',
    };
  }
}
