import React, { ChangeEvent, FormEvent } from 'react';
import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';

export interface SearchInputProps {
  /**
   * Placeholder text
   */
  placeholder?: string;

  /**
   * onchange handler
   */
  onChange?: (value: string) => void;

  onSubmitSearch?: () => void;
  onFocus?: () => void;

  value?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = 'Search for',
  onChange,
  onSubmitSearch,
  value,
  onFocus,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    if (onChange && typeof e === 'object') {
      onChange(e.target.value);
    }
  };

  const submit = (e: FormEvent) => {
    e.preventDefault();
    if (onSubmitSearch) onSubmitSearch();
  };
  return (
    <S.Wrapper>
      <S.Form onSubmit={submit}>
        <S.SearchInput
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={onFocus ? onFocus : () => null}
          value={value}
        />
      </S.Form>
      <S.IconContainer>
        <Icon name='search' color={colors.black._40} />
      </S.IconContainer>
    </S.Wrapper>
  );
};

export default SearchInput;
