import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div``;
const TextContainer = styled.div`
margin-top: 30px;
`;

const Wrapper = styled.div`
  margin-top: ${ptr(12)};

  > :not(:first-child) {
    margin-top: ${ptr(16)};
  }
  button {
    width:100%;
    margin-top: 50px!important;
  }
`;
const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Container, TextContainer, Wrapper, PreloadContainer,
};
