import React, { useState, useEffect } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import beneficiaryService from '../../services/internal/beneficiary/beneficiary.service';
import ErrorBanks from '../../services/internal/banks/banks.error';
import banksService from '../../services/internal/banks/banks.service';
import debug from '../../services/internal/debbug.service';

import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';
import { DropdownInput, DropdownOptionProps } from '../DropdownInput';
import { TextInput } from '../TextInput';
import { IBeneficiary, SelectBeneficiaryType } from '../SelectBeneficiaryType';
import { Button } from '../Button';

import { BankNames } from '../../pages/OtherBankTransfer';

import { validateObject, ValidateResult } from '../../utils/validation';

import { colors } from '../../styles/variables';
import * as S from './styles';
import { success } from '../Toasts';

type DestinationAccountType = {
  response: {
    code?: string;
    message?: string;
    name?: string;
  } | null;
  value: string;
};

const SCHEME = {
  destinationAccount: (data: DestinationAccountType) => data.response?.code === 'success',
  destinationBank: (data: DropdownOptionProps) => !!data?.key,
  beneficiaryTypes: (data: Array<IBeneficiary>) => data.length > 0,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export interface AddNewBeneficiaryModalProps {
  onCloseModal?: () => void,
  visible?: boolean,
  beneficiariesTypes: Array<IBeneficiary> | undefined,
  setBeneficiariesTypes: (value: Array<IBeneficiary>) => void,
  selectBeneficiariesTypes: Array<IBeneficiary> | undefined,
  setSelectBeneficiariesTypes: (name: string, value: Array<IBeneficiary>) => void
  addedElements: (value: IBeneficiary) => void,
  bankNames?: Array<BankNames>,
  getBeneficiaries: () => void,
}

export const AddNewBeneficiaryModal: React.FC<AddNewBeneficiaryModalProps> = ({
  visible = false,
  onCloseModal = () => {},
  beneficiariesTypes,
  setBeneficiariesTypes,
  setSelectBeneficiariesTypes,
  selectBeneficiariesTypes,
  addedElements,
  bankNames,
  getBeneficiaries,
}) => {
  const [defaultAmount, setDefaultAmount] = useState<string>();
  const [destinationAccountLoading, setDestinationAccountLoading] = useState(false);
  const [destinationBank, setDestinationBank] = useState<DropdownOptionProps | null>(null);
  const [creationLoading, setCreationLoading] = useState(false);
  const [destinationAccount, setDestinationAccount] = useState<DestinationAccountType>({
    response: null,
    value: '',
  });
  const [validation, setValidation] = useState<ValidateResult>();
  const convertBankNamesArray = (d: Array<BankNames>): Array<Pick<DropdownOptionProps, 'label' | 'key'>> => {
    const arr = [];
    for (let i = 0; i < d?.length; i += 1) {
      arr.push({ label: d[i].name, key: `${d[i].nipCode}` });
    }
    return arr;
  };

  const checkResolve = async ():Promise<void> => {
    if (destinationAccount?.value?.length > 9 && destinationBank?.key) {
      setDestinationAccountLoading(true);
      try {
        const result = await banksService.checkResolve(
          destinationAccount.value, destinationBank?.key as string,
        );
        setDestinationAccountLoading(false);
        setDestinationAccount({
          ...destinationAccount,
          response: Object.assign(result, { code: 'success' }),
        });
      } catch (err) {
        setDestinationAccountLoading(false);
        if (err instanceof ErrorBanks) {
          setDestinationAccount({
            ...destinationAccount,
            response: { code: err.code, message: err.message },
          });
          debug.error('Failed get payment accounts', err);
        }
      }
    }
  };
  // useEffect(() => {
  //   if (destinationAccount.value.length > 10) {
  //     const newValue = destinationAccount.value.substring(0, 10);
  //     setDestinationAccount({ ...destinationAccount, value: newValue });
  //   }
  // }, [destinationAccount]);

  const handleDestinationAccount = (value: string) => {
    setDestinationAccount({ ...destinationAccount, value });
  };

  const handleCreate = async (cb: () => void) => {
    setCreationLoading(true);
    try {
      const response = await beneficiaryService.saveFile([{
        nuban: destinationAccount.value,
        bankCode: destinationBank?.key || '',
        tags: selectBeneficiariesTypes?.map((el) => String(el.name)).join(',') || '',
        defaultAmount: defaultAmount?.replaceAll(' ', '') || '',
      }]);
      if (response && response.length > 0) {
        success('Status', 'Beneficiary Added.');
      }
      setSelectBeneficiariesTypes('types', []);
      setDestinationBank(null);
      setDefaultAmount(undefined);
      setDestinationAccount({
        response: null,
        value: '',
      });
      setCreationLoading(false);
      onCloseModal();
      cb();
    } catch (err) {
      setCreationLoading(false);
      debug.error('Error creating beneficiary', err);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkResolve();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [destinationAccount?.value, destinationBank?.key]);

  useEffect(() => {
    setValidation(validateObject(
      { destinationAccount, destinationBank, beneficiaryTypes: selectBeneficiariesTypes }, SCHEME,
    ));
  }, [destinationBank, destinationAccount, selectBeneficiariesTypes]);

  return (
    <ModalWrapper
      showModal={visible}
      onCloseModal={!creationLoading ? onCloseModal : undefined}
      crossColor={colors.black._100}
      overflowY="auto"
      overflowX="hidden"
    >
      <S.Wrapper>
        <Text size={2} bold color={colors.pink._100}>
          Add New Beneficiary
        </Text>
        <S.InputRow>
          <Text bold size={4} color={colors.black._100}>Payment information</Text>
          <DropdownInput
            placeholder="Select or enter destination bank"
            label="Destination Bank"
            options={convertBankNamesArray(bankNames || [])}
            title={destinationBank?.label}
            onSelect={setDestinationBank}
          />
          <TextInput
            // disabled={destinationAccountLoading || creationLoading}
            value={destinationAccount?.value}
            onChange={(value) => handleDestinationAccount(value)}
            type="currency"
            mask={createNumberMask(numberMaskOptions)}
            invalid={destinationAccount?.response?.code && destinationAccount?.response?.code === 'FORBIDDEN' ? 'User not found' : ''}
            label="Destination account"
            validText={destinationAccount?.response?.name}
            valid={destinationAccount?.response?.code === 'success'}
            loading={destinationAccountLoading}
          />
          <TextInput
            disabled={destinationAccountLoading || creationLoading}
            value={defaultAmount}
            onChange={setDefaultAmount}
            type="currency"
            label="Default amount"
            rightText="Optional"
          />
        </S.InputRow>
        <Text bold size={4} color={colors.black._100}>Beneficiary type</Text>
        <SelectBeneficiaryType label="Select beneficiary type" list={beneficiariesTypes} setList={setBeneficiariesTypes} setTypes={setSelectBeneficiariesTypes} types={selectBeneficiariesTypes} addedElements={addedElements} />
        <Button
          theme="primary"
          label="Add new beneficiary"
          disabled={!validation?.isValid || destinationAccountLoading || creationLoading}
          onClick={() => handleCreate(getBeneficiaries)}
        />
      </S.Wrapper>
    </ModalWrapper>
  );
};
