/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import {
  OnboardingInterface,
  BusinessType,
} from '../../../redux/onboarding/IOnboarding';

import {
  BasicLeaderDirectorShareholderInterface,
  LeaderDirectorShareholderInterface,
  TrusteeInfoInterface,
} from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../../utils/validation';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { InfoForm } from '../../Onboarding/components/InfoForm';
import { InfoConponent } from '../../../components/Info';
import { OnboardingCard } from '../../../components/OnboardingCard';

const SCHEME = {
  firstName: (value: string) => value.length > 1,
  lastName: (value: string) => value.length > 1,
  phone: (value: string) => value.length === 11,
  email: (value: string) => validator.isEmail(value),
};

const directorDefaultItem = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

const CommonTrusteeInfo: React.FC = () => {
  /**
   * Redux store initiation region
   */
  const business = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.business as Record<string, string>
  );
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<TrusteeInfoInterface>
  );

  /**
   * Local state initiation region
   */

  const [collection, setCollection] = useState<Array<TrusteeInfoInterface>>(
    leaders?.length
      ? leaders.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
          email: item.email,
        }))
      : [directorDefaultItem]
  );

  // const [collectionName, setCollectionName] = useState<
  //   Array<TrusteeInfoInterface>
  // >();

  // {
  //   console.log(collection.map((i) => i.email));
  // }

  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);
  const [addTrustee, setAddTrustee] = useState(false);

  /**
   * Custom handlers initiation region
   */
  // const push = () => {
  //   setCollection([...collection, directorDefaultItem]);
  // };

  const push = () => {
                       setCollection([...collection]);
                       setAddTrustee(true);
                       // setValidationCollection(collection.map((i) => i.email));
                     };

  // {
  //   console.log(collection);
  // }

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: TrusteeInfoInterface, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }
          return el;
        }) as Array<TrusteeInfoInterface>
    );
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  return (
    <S.Container>
      <S.TopTitle>
        <Title
          color={colors.black._20}
          title='Provide details of all Trustees '
          subTitle='Minimum requirement is 2'
        />
      </S.TopTitle>

      <S.InfoContainer>
        <InfoConponent text='Note that the consent of the person(s) provided here will be required for the account to become operational on VBiz.' />
      </S.InfoContainer>

      <OnboardingCard>
        {addTrustee && (
          <div
            style={{ borderBottom: '4px solid #DBDFE4', marginBottom: '20px' }}
          >
            {/* {collection.map((item, index) => { */}

            <S.AddedTrusteeDiv>
              <S.Lhs>
                <Text bold size={7} color='#394455'>
                  {collection.map((i) => i.firstName)}-{' '}
                  <Text bold size={7} color='#394455'>
                    (National ID Card)
                  </Text>{' '}
                </Text>

                <Text size={7} color='#6E7191'>
                  {collection.map((i) => i.phone)} ||{' '}
                  <Text size={7} color='#6E7191'>
                    {collection.map((i) => i.email)}{' '}
                  </Text>{' '}
                </Text>
              </S.Lhs>

              <S.Rhs onClick={() => remove}>
                {' '}
                <Icon name='edit' color={colors.pink._100} />
                <Icon name='trash' color='#ED405C' />
              </S.Rhs>
            </S.AddedTrusteeDiv>
            {/* })} */}
          </div>
        )}
        {collection.map((item, index) => (
          <div style={{ marginBottom: '30px' }}>
            <InfoForm
              title='Trustee’s details'
              firstName={item.firstName}
              lastName={item.lastName}
              phone={item.phone}
              email={item.email}
              index={index}
              removeFromIndex={0}
              remove={remove}
              onChange={onChangeValue}
              emailIsValid={
                validationCollection?.data[index]?.data.email.isValid
              }
            />
          </div>
        ))}
        <S.Add
          disabled={!validationCollection?.isValid || loading}
          onClick={push}
        >
          <Icon name='plus' color='#000000' />
          <Text color='#7F91A8'>Add another trustee </Text>
        </S.Add>
        <S.ButtonGroup>
          <div>
            <Button
              label='Continue'
              disabled={!validationCollection?.isValid || loading}
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
export default CommonTrusteeInfo;
