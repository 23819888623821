import React from 'react';
import { useHistory } from 'react-router';
import { ClickablePanel } from '../../components/ClickablePanel';
import { RoundButton } from '../../components/RoundButton';
import { Text } from '../../components/Text';
import { WelcomeSlider } from '../../components/WelcomeSlider';
import { colors } from '../../styles/variables';
import { Columns, TwoSide } from '../../styles/wrappers';

import * as S from './styles';

export interface NewUserProps {
  /**
   * Example page property
   */
  example?: string,
}

// eslint-disable-next-line no-empty-pattern
export const NewUser: React.FC<NewUserProps> = ({}) => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };
  return (
    <TwoSide.Wrapper>
      <TwoSide.Left>
        <WelcomeSlider />
      </TwoSide.Left>
      <TwoSide.Right>
        <Columns.Wrapper>
          <Columns.ContentWrapper>
            <RoundButton
              icon="arrow-left"
              onClick={onBack}
            />
          </Columns.ContentWrapper>
          <Columns.ContentWrapper>
            <S.TitleWrapper>
              <Text color={colors.black._100} size={4} bold>
                What will you like to do:
              </Text>
            </S.TitleWrapper>
            <S.ItemWrapper>
              <ClickablePanel
                title="Registered Business"
                description="My business is registered with the Corporate Affairs Commission and I have all the corporate documents in place."
              />
            </S.ItemWrapper>
            <S.ItemWrapper>
              <ClickablePanel
                to="/new-user/unregistered"
                title="Unregistered Business"
                description="My business is not formally registered yet but I will like to at some point."
              />
            </S.ItemWrapper>
          </Columns.ContentWrapper>
          <Columns.ContentWrapper />
        </Columns.Wrapper>
      </TwoSide.Right>
    </TwoSide.Wrapper>
  );
};
