import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { Text } from '../Text';
import { colors } from '../../styles/variables';
import { TextInput } from '../TextInput';
import { Link } from '../Link';
import { DropdownInput, DropdownOptionProps } from '../DropdownInput';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../utils/validation';
import { Button } from '../Button';
// import debug from '../../services/internal/debbug.service';
import paymentsService from '../../services/internal/payments/payments.service';
import { PaymementAccountInterface } from '../../services/internal/payments/IPayments';
import { formatCash } from '../../utils/money';
import { failed } from '../Toasts';
import { ModalWrapper } from '../ModalWrapper';
import { PinAuth } from '../PaymentConfirms/SelfPinAuth';

interface IAmount {
  pa: string;
  value: string;
}
export interface IPaymentDetails {
  paymentAccount?: DropdownOptionProps;
  amount?: IAmount;
  destinationAccount?: DropdownOptionProps;
  comment?: string;
}

export interface PaymentDetailsProps {
  example?: boolean;
}

const defaultData: IPaymentDetails = {
  paymentAccount: {
    label: '',
    value: '',
    category: undefined,
    subLabel: '',
    key: '',
  },
  amount: {
    pa: '',
    value: '',
  },
  destinationAccount: {
    label: '',
    value: '',
    category: undefined,
    subLabel: '',
    key: '',
  },
  comment: '',
};

const SCHEME = {
  paymentAccount: (data: DropdownOptionProps) => {
    if (data?.key && data?.value) {
      return true;
    }
    return false;
  },
  destinationAccount: (data: DropdownOptionProps) => {
    if (data?.key && data?.value) {
      return true;
    }
    return false;
  },
  amount: (data: IAmount) => {
    const maxValue = Number(data.pa?.replace(/[\s,₦%]/g, ''));
    if (Number(data.value.replace(/\s/g, '')) > maxValue || !data.value) {
      return false;
    }
    return true;
  },
  comment: () => true,
};

export const PaymentDetails: React.FC<PaymentDetailsProps> = () => {
  const [data, setData] = useState<Array<IPaymentDetails>>([defaultData]);
  const [paymentAccountsLoading, setPaymentAccountsLoading] = useState<boolean>(
    false
  );
  const [paymentsAccount, setPaymentsAccount] = useState<
    Array<PaymementAccountInterface>
  >([]);

  const [validation, setValidation] = useState<ValidateArrayResult | null>(
    null
  );
  const [showAuthModal, setshowAuthModal] = useState<boolean>(false);
  // const [currentView, setcurrentView] = useState<string>('confirmPage');

  const history = useHistory();

  const convertPaymentAccountArray = (
    paymentAccounts: Array<PaymementAccountInterface>
  ): Array<DropdownOptionProps> =>
    paymentAccounts.map((account) =>
      account.allowOverdraft && account.overdraftLimit
        ? {
            label: account.nuban,
            key: account.id,
            value: `₦ ${formatCash(account.balance)}`,
            category: account.category,
            subLabel: account.type,
            od: `₦ ${formatCash(account.balance + account.overdraftLimit)}`,
          }
        : {
            label: account.nuban,
            key: account.id,
            value: `₦ ${formatCash(account.balance)}`,
            category: account.category,
            subLabel: account.type,
          }
    );

  const getPaymentAccounts = async (): Promise<void> => {
    setPaymentAccountsLoading(true);
    try {
      const result = await paymentsService.getPaymentAccounts();
      setPaymentsAccount(result);
      setPaymentAccountsLoading(false);
    } catch (err) {
      failed('Failed', err.message || 'Error retrieving payments accounts');
    }
  };

  const onChangeAmount = (index: number, value: string, fieldName: string) => {
    setData(
      (old) =>
        old.map((el: IPaymentDetails, i: number) => {
          if (i === index) {
            return {
              ...el,
              amount: { ...el.amount, [fieldName]: value },
            };
          }
          return el;
        }) as Array<IPaymentDetails>
    );
  };

  const onAdd = () => {
    data.push(defaultData);
    setData([...data]);
  };

  const onRemove = (i: number) => {
    setData([...data.filter((el, index) => i !== index)]);
  };

  // const makeTransfer = async () => {
  //   try {
  //     console.log(data);
  //     const res = data.map((item) => ({
  //       paymentAccount: item.paymentAccount?.key || '',
  //       receiverPaymentAccount: item.destinationAccount?.key || '',
  //       amount: Number(item.amount?.value.replace(/\s/g, '')),
  //       comment: item.comment || '',
  //     }));
  //     const result = await paymentsService.makeTransfer({
  //       payments: res,
  //     });
  //     // success('Successfull', 'Transfer completed successfully');
  //     // history.push('/dashboard');
  //     debug.info('Result', result);
  //   } catch (err) {
  //     debug.error('Error while payment creation', err);
  //   }
  //   debug.info('Result', data);
  // };

  const onChangeData = (index: number, fieldName: string, value: any) => {
    setData((old) =>
      old.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            [fieldName]: value,
          };
        }
        return el;
      })
    );
  };

  useEffect(() => {
    getPaymentAccounts();
  }, []);

  useEffect(() => {
    const validateResult = validateArrayOfObjects(data, SCHEME);
    setValidation(validateResult);
  }, [data]);

  const closeModalView = () => {
    setshowAuthModal(false);
    setTimeout(() => {
      history.push('/dashboard');
    }, 1000);
  };

  const renderModalRecord = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={showAuthModal}
      onCloseModal={() => closeModalView()}
      crossColor={colors.black._100}
    >
      <PinAuth paymentsDetails={data} />
    </ModalWrapper>
  );

  return (
    <S.Wrapper>
      {renderModalRecord()}
      {data.map((valueItem, index) =>
        valueItem !== null ? (
          <>
            {index > 0 && <S.Separator />}
            <S.BeneficiaryWrapper>
              <S.InputsHeaderWrapper>
                <Text color={colors.black._100} bold>
                  Payment details
                </Text>
                {index > 0 && (
                  <Link icon="trash-basket" onClick={() => onRemove(index)} />
                )}
              </S.InputsHeaderWrapper>
              <S.InputsWrapper>
                <S.InputWrapper>
                  <DropdownInput
                    options={convertPaymentAccountArray(
                      paymentsAccount.filter(
                        (acc) => acc.id !== valueItem.destinationAccount?.key
                      )
                    )}
                    onSelect={(value) => {
                      onChangeData(index, 'paymentAccount', value);
                      if (!value?.od)
                        onChangeAmount(index, String(value?.value), 'pa');
                      if (value?.od)
                        onChangeAmount(index, String(value?.od), 'pa');
                    }}
                    value={valueItem.paymentAccount?.value}
                    multiOptions
                    label="Sender's account"
                    disabled={paymentAccountsLoading}
                    loading={paymentAccountsLoading}
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <DropdownInput
                    options={convertPaymentAccountArray(
                      paymentsAccount.filter(
                        (acc) => acc.id !== valueItem.paymentAccount?.key
                      )
                    )}
                    value={valueItem.destinationAccount?.value}
                    onSelect={(value) =>
                      onChangeData(index, 'destinationAccount', value)
                    }
                    multiOptions
                    label="Destination account"
                    disabled={paymentAccountsLoading}
                    loading={paymentAccountsLoading}
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <TextInput
                    onChange={(value) => onChangeAmount(index, value, 'value')}
                    invalid={
                      !valueItem.amount?.value ||
                      validation?.data[index]?.data.amount.isValid
                        ? ''
                        : 'Amount is greater than account balance'
                    }
                    symbol={
                      !valueItem.amount?.value ||
                      validation?.data[index]?.data.amount.isValid
                        ? '₦'
                        : ''
                    }
                    type="currency"
                    label="Amount"
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <TextInput
                    label="Comment for payment"
                    maxLength={140}
                    onChange={(value) => onChangeData(index, 'comment', value)}
                  />
                </S.InputWrapper>
              </S.InputsWrapper>
            </S.BeneficiaryWrapper>
          </>
        ) : null
      )}

      <S.FooterWrapper>
        <Link
          icon="plus-in-circle"
          text="Add New Beneficiary"
          disabled={paymentAccountsLoading}
          onClick={onAdd}
        />
        <Button
          label="Make a transfer"
          disabled={!validation?.isValid}
          onClick={() => setshowAuthModal(true)}
        />
      </S.FooterWrapper>
    </S.Wrapper>
  );
};
