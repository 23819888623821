import styled from 'styled-components';
import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const PanelsWrapper = styled.div`
  display: flex;
  padding-bottom: ${ptr(40)};
  flex-wrap: wrap;
  margin: ${ptr(6)} ${ptr(-6)};
`;
const PanelWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: ${ptr(6)};
  margin-left: ${ptr(6)};
  margin-right: ${ptr(6)};
  width: auto;
`;
const FooterWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    margin-bottom: 17px;
  }
`;
const FooterInnerWrapper = styled.div`
  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 17px;
  }
`;

const ErrorWrapper = styled.div`
  padding: 20px;
  width: 500px;
`;

const ErrorTextContainer = styled.div`
  border-bottom: solid 1px ${colors.pink._60};
  :nth-child(even) {
    background-color: ${colors.black._5};
  }
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div<{ flex: number }>`
  color: ${colors.black._60};
  flex: ${(props) => props.flex};
`;

const Batch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  space-between: ${ptr(6)};
  width: 50%;
  margin-bottom: ${ptr(40)};
  @media (max-width: ${breakpoints.sm}px) {
    padding: ${ptr(10)};
    width: 100%;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
`;

const Input = styled.input`
  width: 50%;
  placeholder: ${(props) => props.placeholder};
  border: none;
  border: solid 1px ${colors.black._60};
  border-radius: ${ptr(4)};
  padding: ${ptr(10)};
  font-size: ${ptr(14)};
  margin: 10px ${ptr(10)};
  color: ${colors.black._60};
  &:focus {
    outline: none;
  }
  @media (max-width: ${breakpoints.sm}px) {
    padding: ${ptr(5)};
  }
`;

export {
  PanelsWrapper,
  PanelWrapper,
  FooterWrapper,
  FooterInnerWrapper,
  ErrorWrapper,
  ErrorTextContainer,
  Text,
  Batch,
  Input,
  SwitchContainer,
};
