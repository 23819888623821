import styled from 'styled-components';

// import { SavedBeneficiariesProps } from '.';
// import { fonts, colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';
import { colors } from '../../styles/variables';

const adaptiveBreakPoint = 1024;

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${ptr(24)};

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderAddWrapper = styled.div`
  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    margin-top: ${ptr(16)};
  }
`;

const BeneficiaryWrapper = styled.div`
  display: flex;
  border-bottom: ${ptr(1)} solid ${colors.black._5};
  padding-bottom: ${ptr(16)};
  
  :not(:last-child) {
    margin-bottom: ${ptr(16)};
  }

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    flex-direction: column;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: ${ptr(24)};
  justify-content: center;
  max-width: 40%;

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    max-width: 100%;
    margin-bottom: ${ptr(16)};
  }
`;

const NameWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.black._100};
`;

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${ptr(8)};
`;

const AccountText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.black._60};
`;

const AccountSeparator = styled.div`
  width: ${ptr(6)};
  max-width: ${ptr(6)};
  height: ${ptr(6)};
  max-height: ${ptr(6)};
  border-radius: 50%;
  background-color: ${colors.black._60};
  margin: 0 ${ptr(12)};
`;

const InputWrapper = styled.div`
  flex: 2;

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    max-width: 100%;
  }
`;
const InputIconWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${ptr(24)};
`;

export {
  Wrapper,
  HeaderWrapper,
  HeaderAddWrapper,
  BeneficiaryWrapper,
  DescriptionWrapper,
  NameWrapper,
  AccountWrapper,
  AccountText,
  AccountSeparator,
  InputWrapper,
  RemoveButtonWrapper,
  InputIconWrapper,
};
