import React, { useState, useEffect } from 'react';

import { batch, useDispatch } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import { useHistory } from 'react-router';
import * as S from './styles';
import { TextInput } from '../../../../components/TextInput';
import { Button } from '../../../../components/Button';
import { Link } from '../../../../components/Link';
import { Text } from '../../../../components/Text';

import { colors } from '../../../../styles/variables';
import { validateObject, ValidateResult } from '../../../../utils/validation';
import {
  FlexColumn,
  FormTitle,
  FormButton,
  FormInput,
} from '../../../../styles/wrappers';
import authService from '../../../../services/internal/auth/auth.service';
import { failed, success, warning } from '../../../../components/Toasts';
import { AuthorizationActions } from '../../../../redux/authorization/actions';
import { Icon } from '../../../../components/Icon';
import { EPermissions } from '../../../../components/AccessControl';
import userService from '../../../../services/internal/user/user.service';
import { userSuccess } from '../../../../redux/current-user/actions';
import { ApiService } from '../../../../services/internal/api/api.service';

const RULES = {
  password: {
    minLength: 3,
  },
};

const SCHEME = {
  email: (value = '') => Boolean(value.length && isEmail(value)),
  password: (value = '') => value.length >= RULES.password.minLength,
};

export const LogInForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [validation, setValidation] = useState<ValidateResult | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [validInProgress, setValidInProgress] = useState(false);

  const route = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            email,
            password,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [email, password]);

  const authentication = async () => {
    setLoading(true);
    try {
      const result = (await authService.authentication(email, password)) as any;

      batch(() => {
        dispatch(AuthorizationActions.authorization());
        dispatch(AuthorizationActions.setPermission(result?.permissions));
        dispatch(AuthorizationActions.setRole(result?.roles));
      });
      if (
        result &&
        Array.isArray(result.permissions) &&
        result.permissions.length > 0
      ) {
        if (
          result.permissions.includes(EPermissions.APPROVE_PAYMENT) ||
          result.permissions.includes(EPermissions.CREATE_PAYMENT) ||
          result.permissions.includes(EPermissions.APPROVE_PAYMENT)
        ) {
          const user = await userService.getCurrentUserData();
          if (user) dispatch(userSuccess(user));
          if (user && !user.hasPin) {
            setTimeout(() => {
              warning(
                'Transaction pin notification',
                'Please, reset your transaction pin to be able to perform transactions'
              );
              route.push('/settings/t-pin/reset', { password });
            }, 3000);
          }
        }
      }

      success('Success', 'You are logged in');
    } catch (error) {
      const err = error as Error;
      failed('Failed to log in', err.message);
    }
    setLoading(false);
  };

  return (
    <FlexColumn>
      <FormTitle.Wrapper>
        <FormTitle.TitleWrapper>
          <Text color={colors.black._20} size={2} bold>
            Log in
          </Text>
        </FormTitle.TitleWrapper>
        <FormTitle.DescriptionWrapper>
          <Text color={colors.black._15}>Enter your details below</Text>
        </FormTitle.DescriptionWrapper>
      </FormTitle.Wrapper>
      <FormInput.Wrapper>
        <TextInput
          label="Email"
          value={email}
          onChange={setEmail}
          valid={!validInProgress && validation?.data?.email?.isValid}
          disabled={loading}
        />
        {!validInProgress && !validation?.data?.email?.isValid && email ? (
          <S.InvalidTextWrapper>
            <S.InvalidIconWrapper>
              <Icon name="exclamation-mark" color={colors.red._100} />
            </S.InvalidIconWrapper>
            <Text color={colors.black._60} size={7}>
              Invalid email
            </Text>
          </S.InvalidTextWrapper>
        ) : null}
      </FormInput.Wrapper>
      <FormInput.Wrapper>
        <TextInput
          label="Password"
          value={password}
          onChange={setPassword}
          type="password"
          disabled={loading}
        />
      </FormInput.Wrapper>
      <S.ForgotPasswordWrapper>
        <Link
          to="/reset-password"
          text="Forgot your Password?"
          theme="dark"
          small
          disabled={loading}
        />
      </S.ForgotPasswordWrapper>
      <FormButton.WrapperEnd>
        <Button
          label="Log In"
          disabled={!validation?.isValid || loading}
          onClick={authentication}
          style={{ width: '100%' }}
        />
      </FormButton.WrapperEnd>
    </FlexColumn>
  );
};
