import React from 'react';
import { colors } from '../../styles/variables';
import { Icon } from '../Icon';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';

import * as S from './styles';

export interface WarningModalProps {
  /**
   * Modal title
   */
  title?: string,
  /**
   * Modal description
   */
  description?: string,
  /**
   * On close modal
   */
  onCloseModal?: () => void,
  /**
   * Show modal
   */
  showModal?: boolean,
}

export const WarningModal: React.FC<WarningModalProps> = ({
  showModal = false,
  title = '',
  description = '',
  ...props
}) => (
  <ModalWrapper
    onCloseModal={props.onCloseModal}
    showModal={showModal}
    crossColor={colors.white._100}
    closeBackground={colors.orange._100}
  >
    <S.Wrapper>
      <S.IconWrapper>
        <Icon width={12} height={44} name="exclamation-mark" color={colors.orange._100} />
      </S.IconWrapper>
      <S.TitleWrapper>
        <Text size={3} color={colors.white._100} bold>
          {title}
        </Text>
      </S.TitleWrapper>
      <Text color={colors.white._100} size={5}>
        {description}
      </Text>
    </S.Wrapper>
  </ModalWrapper>
);
