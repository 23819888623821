import styled, { css } from 'styled-components';

import { fonts, colors, transition } from '../../styles/variables';
import {
  crossBrowserBoxShadow, crossBrowserDisableSelection, crossBrowserTransition, ptr,
} from '../../styles/utils';

interface ArrowProps {
  rotate?: number,
}
interface SelectBeneficiaryTypeState {
  open?: boolean,
  value?: string,
}

const Container = styled.div`
  position: relative;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${ptr(6)};
  /* background-color: ${colors.black._5}; */
  background-color: ${colors.white._100};
  position: relative;
  cursor: pointer;
  padding: 0 ${ptr(20)};
  height: ${ptr(60)};
  overflow: hidden;
  width: 100%;
   
  box-sizing: border-box;
  :hover {
    opacity: 0.8;
  }

    @media screen and (min-device-width: 280px) and (max-device-width: 420px){
   

     
  }
`;

const StyledLabel = styled.label<SelectBeneficiaryTypeState>`
  position: absolute;
  pointer-events: none;
  line-height: ${ptr(24)};
  font-family: ${fonts.inter.regular};
  color: ${colors.black._60}; 
  ${crossBrowserTransition(transition.slow)}
  padding: ${ptr(18)} 20;
  
  ${(props) => (props.open || props.value?.length) && css`
    top: ${ptr(-8)};  
    line-height: 140%;
    font-size: ${ptr(12)};
  `}
`;

const TouchableZone = styled.button`
  width: 100%;
  height: 100%;
  font-family: ${fonts.inter.regular};
  line-height: ${ptr(24)};
  border: 0;
  padding: ${ptr(27)} ${ptr(20)} ${ptr(9)} ${ptr(0)};
  color: ${colors.black._100};
  display: block;
  box-sizing: border-box;
  background-color: transparent;
  overflow: hidden;

  ${crossBrowserTransition(transition.superSlow)}
  ::placeholder {
    color: ${colors.black._40};
  }
`;

const ArrowIconWrapper = styled.div<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  ${crossBrowserTransition(transition.slow)}
  ${(props) => props.rotate && css`
    transform: rotate(${props.rotate}deg);
  `}
`;

const SelectBeneficiaryTypeContent = styled.div<SelectBeneficiaryTypeState>`
  display: none;
  position: absolute;
  z-index: 1000;
  overflow-y: scroll;
  flex-direction: column;
  background-color: ${colors.white._100};
  top: ${ptr(60)};
  margin-top: ${ptr(8)};
  width: 100%;
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(6)};
  max-height: ${ptr(150)};
  ${crossBrowserTransition(transition.slow)}
  ${crossBrowserBoxShadow(`0 0 15px ${ptr(0.2)} ${colors.black._10}`)}
  ${(props) => props.open && css`
    display: flex;
  `}

  > div:first-child {
    border-top: none;
  }
`;

const SingleItemWrapper = styled.div`
  padding: ${ptr(18)} ${ptr(24)};
  border-top: 1px solid ${colors.black._10};
  cursor: pointer;

  > span {
    text-transform : capitalize;
  }

  :hover {
    background-color: ${colors.black._60};
    > span {
      ${crossBrowserDisableSelection()}
      color: ${colors.white._100};
    }
  } 
`;

const LastItem = styled.div`
  padding: ${ptr(18)} ${ptr(24)};
  border-top: 1px solid ${colors.black._10};
  display: flex;
  align-items: center;

  cursor: pointer;
  :hover {
    background-color: ${colors.black._60};
    > span {
      ${crossBrowserDisableSelection()}
      color: ${colors.white._100};
    }

    figure {
      background-color: ${colors.white._100};
      
      :after {
        background-color: ${colors.white._100};
      }
    }
  }
`;

const PlusBlock = styled.div`
  width: ${ptr(24)};
  height: ${ptr(24)};
  margin-right: ${ptr(12)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Plus = styled.figure`
  width: ${ptr(14)};
  height: ${ptr(2)};
  background: ${colors.black._100};
  border-radius: ${ptr(8)};
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    content: '';
    display: block;
    width: ${ptr(2)};
    height: ${ptr(14)};
    border-radius: ${ptr(8)};
    background: ${colors.black._100};
  }
`;

const Row = styled.div`
  overflow: auto;
  min-width: calc(100% - ${ptr(28)});
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  padding: ${ptr(8)} ${ptr(12)};
  background: ${colors.black._2};
  border: ${ptr(1)} solid ${colors.black._10};
  box-sizing: border-box;
  border-radius: ${ptr(6)};
  margin-right: ${ptr(4)};
  display: flex;
  align-items: center;
  height: fit-content;

  > span {
    text-transform : capitalize;
    white-space: nowrap;
  }
`;

const CrossBlock = styled.div`
  width: ${ptr(20)};
  height: ${ptr(20)};
  margin-left: ${ptr(4)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cross = styled.figure`
  width: ${ptr(10)};
  height: ${ptr(2)};
  border-radius: ${ptr(8)};
  background: ${colors.black._100};
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    content: '';
    display: block;
    width: ${ptr(2)};
    height: ${ptr(10)};
    border-radius: ${ptr(8)};
    background: ${colors.black._100};
  }
`;

export {
  TouchableZone,
  Container,
  Wrapper,
  StyledLabel,
  ArrowIconWrapper,
  SelectBeneficiaryTypeContent,
  SingleItemWrapper,
  LastItem,
  PlusBlock,
  Plus,
  Item,
  Row,
  CrossBlock,
  Cross,
};
