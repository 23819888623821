import React, { FC } from 'react';
import * as S from './styles';
import { Icon } from '../Icon';
import { Link } from '../Link';
import { Text } from '../Text';
import { useHistory } from 'react-router-dom';

export interface IProps {
  type?: any;
  title: string;
  buttonLabel?: string;
  description: string;
  backgroundColor: string;
  handleOnClick: () => void;
  actionText?: string;
  showActionButton?: boolean;
}

export const OnboardClickPanel: FC<IProps> = ({
  type,
  title,
  description,
  backgroundColor,
  handleOnClick,
  buttonLabel,
  actionText,
  showActionButton = false,
}) => {
  function handleClick() {
    handleOnClick();
  }
  const history = useHistory();


  return (
    <S.ClickPanel backGroundColor={backgroundColor} onClick={handleClick}>
      <div>
        <S.PanelTitle>
          <Icon name='newAccount' />
          <div>
            <p
              style={{
                marginBottom: 5,
                fontWeight: 600,
              }}
            >
              {title}
            </p>
            <p
              style={{
                marginBottom: 0,
                fontWeight: 400,
                lineHeight: '18px',

                fontSize: 14,
              }}
            >
              {description}
            </p>
          </div>
        </S.PanelTitle>{' '}
      </div>

      <div
        style={{
          alignSelf: 'flex-end',
          fontSize: 12,
          fontWeight: 600,
          flex: 1,
          flexWrap: 'nowrap',
          textAlign: 'right',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        {showActionButton && (
          <S.RightAction>
            <Text size={7} color='#B10180'>
              {actionText}
            </Text>
            <Link
              icon='arrow-right'
              text={buttonLabel}
              iconRight
              // onClick={handleOnClick}
              // onClick={() => signup(requirementData.route)}
            />
          </S.RightAction>
        )}
      </div>
    </S.ClickPanel>
  );
};
