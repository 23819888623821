import * as sentry from '@sentry/react';

class SentryService {
  private static instance: SentryService;

  private dsn = '';

  public static getInstance(): SentryService {
    if (!SentryService.instance) {
      SentryService.instance = new SentryService();
    }
    return SentryService.instance;
  }

  init(): void {
    sentry.init({ dsn: this.dsn });
  }
}

export default SentryService;
