import styled from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../../styles/utils';
import { colors, transition } from '../../../../styles/variables';

const Container = styled.div`
  width: 100%;
  
  position: relative;
  overflow: hidden;
  text-align: left;
  border: 0;

  ${crossBrowserTransition(transition.slow)}

  border-radius: ${ptr(8)};
  background-color: ${colors.pink._100};
  color: ${colors.white._100};

`;
const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  text-align: left;
  padding: ${ptr(36)} ${ptr(40)};

  ${crossBrowserTransition(transition.slow)}
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  
  :first-child {
    margin-bottom: ${ptr(24)};
  }
  `;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${ptr(8)};
`;

const AmountWrapper = styled.div`
  margin-top: ${ptr(8)};
`;

export {
  Container,
  Wrapper,
  Row,
  Column,
  AmountWrapper,
};
