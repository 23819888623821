import React, { FC, useEffect, useState } from 'react';
import * as S from './styles';
import { Text } from '../Text';

interface IProps {
  items: Array<{ title: string; description: string; value: string }>;
  onChange: (value: string) => void;
  selectedValue?: string;
}
const RadioCheckWithLabelAndDesc: FC<IProps> = ({
  items,
  onChange,
  selectedValue,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();

  useEffect(() => {
    if (selectedValue) {
      const index = items.findIndex((item) => item.value === selectedValue);
      setSelectedIndex(index);
    }
  }, [selectedValue]);

  const handleChange = (index: number, value: string) => {
    setSelectedIndex(index);
    if (onChange) onChange(value);
  };
  return (
    <S.Container>
      {items.map((item, i) => (
        <S.CheckContainer
          key={i + 1}
          onClick={() => handleChange(i, item.value)}
        >
          <S.CheckCircle checked={i === selectedIndex} />
          <S.CheckLabel>
            <Text size={5} bold color="#344054">
              {item.title}
            </Text>
            <Text size={5} color="#475467">
              {item.description}
            </Text>
          </S.CheckLabel>
        </S.CheckContainer>
      ))}
    </S.Container>
  );
};

export default RadioCheckWithLabelAndDesc;
