import React, { CSSProperties } from 'react';
import { Icon, IconName } from '../Icon';
import * as S from './styles';

export interface InfoPanelProps {
  /**
   * Info title text
   */
  style?: CSSProperties;
  color?: string;
  text: JSX.Element | string;
  showInfoIcon?: boolean;
  infoIcon?: IconName['name'];
}

const InfoComponent: React.FC<InfoPanelProps> = ({
  text,
  style,
  color = '#946200',
  showInfoIcon = true,
  infoIcon,
}) => (
  <S.InfoWrapper style={style}>
    {showInfoIcon && (
      <div style={{ alignSelf: 'flex-start' }}>
        <Icon color={color || '#946200'} name={infoIcon ?? 'info'} />
      </div>
    )}

    <div>
      {typeof text === 'string' && (
        <p
          style={{
            color: color || '#946200',
            fontWeight: 500,
            fontSize: 14,
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
          }}
        >
          {text}
        </p>
      )}
      {typeof text !== 'string' && text}
    </div>
  </S.InfoWrapper>
);

export const InfoConponent = React.memo(InfoComponent);
