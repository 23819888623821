import React from 'react';

import { AccountCardProps } from '../../components/AccountCard';
import { AccountsList } from '../../components/AccountsList';
import { Text } from '../../components/Text';
import { PageTitle, ComponentWrapper, ContentContainer } from '../../styles/wrappers';
import { colors } from '../../styles/variables';
import { PaymentDetails } from '../../components/PaymentDetails';
import { SavedBeneficiaries } from '../../components/SavedBeneficiaries';
import { InfoPanel } from '../../components/InfoPanel';
import { Button } from '../../components/Button';

const ACCOUNTS_MOCK_DATA: Array<AccountCardProps> = [
  {
    balance: 278951.51,
    number: 'AIUY7892OHYIWJ51',
    currency: 'usd',
  },
  // {
  //   balance: 87610.90,
  //   number: '90HDG008910971',
  //   currency: 'ngn',
  // },
  // {
  //   balance: 1508.12,
  //   number: 'KLLOIHFKKI19800',
  //   currency: 'usd',
  // },
];

// const SCHEME = {
//   account: (value: number) => value >= 0,
// };

export const IntraBankTransfer: React.FC = () => (
  <div>
    <PageTitle.Wrapper>
      <Text color={colors.black._100} size={4} bold>Intra Bank Transfer</Text>
    </PageTitle.Wrapper>

    <AccountsList
      accounts={ACCOUNTS_MOCK_DATA}
    />

    <ComponentWrapper>
      <InfoPanel
        title="Saved Beneficiaries"
        description="You can select the saved recipient from the list if want to transfer to a mix of new accounts and accounts from saved beneficiary list."
        buttonText="Select"
      />
    </ComponentWrapper>

    <ComponentWrapper>
      <SavedBeneficiaries
        data={[
          {
            name: 'Name Surname',
            accountNumber: 'HUIE889150OPKMHDK1',
            bankName: 'GT Bank',
          },
          {
            name: 'FirstName SecondName',
            accountNumber: '0982395395103495',
            bankName: 'Alfa Bank',
          },
        ]}
      />
    </ComponentWrapper>

    <ComponentWrapper>
      <PaymentDetails />
    </ComponentWrapper>

    <ComponentWrapper>
      <ContentContainer justifyContent="flex-end">
        <Button
          label="Make a transfer"
        />
      </ContentContainer>
    </ComponentWrapper>
  </div>
);
