/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CompanyActions } from '../../redux/company/actions';
import { CompanyInterface } from '../../redux/company/ICompany';

import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';
import { Icon } from '../../components/Icon';

import { Text } from '../../components/Text';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { Table, TableFooterProps } from '../../components/Table';
import { Preload } from '../../components/Preload';
import { CheckBox } from '../../components/CheckBox';
import { ModalWrapper } from '../../components/ModalWrapper';
import SearchInput from '../../components/SearchInput';
import { PaymementAccountInterface } from '../../services/internal/payments/IPayments';
import { FilterResult } from '../AnalyticsNReport/Component/FilterResult';
import { PaymentDetailsViews } from '../AnalyticsNReport/Component/PaymentDetailsViews';
import { formatCash } from '../../utils/money';
import {
  getBeautyDateTimeString,
  getDateWithYearFirst,
} from '../../utils/datetime';
import { IPagination, Pagination } from '../../components/Pagination';
import { IGetQueries } from '../../interface';
import getQueryString from '../../utils/getQueryString';
import { PaginationActions } from '../../redux/paginationSettings/actions';
import { Button } from '../../components/Button';
import { BackBar } from '../../components/BackBar';
import { failed } from '../../components/Toasts';
import { PinAuth } from '../../components/PaymentConfirms/PinAuth';
import { FormTitle } from '../../components/FormTitle';
import { TextInput } from '../../components/TextInput';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { IndividualPayment } from './individualPayment';
import { BulkPayment } from './bulkPayment';

const separator = '=';

interface ViewComponentProps {

  Individualpayment: any;

  Bulkpayment: any;

}
export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}
export interface PaymentHistory {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
  check: boolean;
  actionRequired: string;
  fromAccountNo: string;
  beneficiaryTypes: string;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
}
export interface ModalViewComponent {
  FilterResult: any;
  PaymentDetails: any;
}

export const SelfTransfer: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Redux store initiation region
   */
  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active,
  );
  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list,
  );

  /**
   * Local state initiation region
   */
  const [currentCompanyId, setCurrentCompanyId] = useState<string>('');
  const [CurrentUserView, ChangeuserView] = useState<string>('Individualpayment');

  useEffect(() => {
    PaginationActions.reset();
  }, []);

  /**
   * Custom handlers initiation region
   */

  const getCompanyFromUrl = (location: Location | any) => {
    const companyId = location.search.split(separator)[1];
    if (companyId) {
      const company = companies.find((item) => item.id === companyId);
      setCurrentCompanyId(company?.id || '');
    }
  };


  const UserViews: ViewComponentProps = {

    Individualpayment: <IndividualPayment />,

    Bulkpayment: <BulkPayment />,

  };

  const CurrentRenderView = CurrentUserView as keyof typeof UserViews;



  const ChangeUserDisplay = (userView: string) => {

    ChangeuserView(userView);

  };

  return (
    <AccessControl isPage requiredPermission={[EPermissions.APPROVE_PAYMENT]}>
      <S.Container>
        <BackBar name="Payment Approvals" />

        <div style={{ marginTop: 50 }}>

          <Text color='#333754' size={5} bold> PAYMENT TYPE</Text>

          <S.TypeTabs>

            <S.TableAlign2>

              <S.Tab1 onClick={() => { ChangeUserDisplay('Individualpayment'); }}><Text tag='span' size={4} background={CurrentUserView === 'Individualpayment' ? '#333754' : 'unset'} color={CurrentUserView === 'Individualpayment' ? colors.white._100 : '#59595E'}  >Individual payment</Text></S.Tab1>

              <S.MarginLeftComp1 onClick={() => { ChangeUserDisplay('Bulkpayment'); }}><Text tag='span' size={4} background={CurrentUserView === 'Bulkpayment' ? '#333754' : 'unset'} color={CurrentUserView === 'Bulkpayment' ? colors.white._100 : '#59595E'} >Bulk payment</Text></S.MarginLeftComp1>

            </S.TableAlign2>

          </S.TypeTabs>

        </div>



        {/* {loading ? (
          <S.PreloadContainer>
            <Preload />
          </S.PreloadContainer>
        ) : (
          renderTableItem()
        )} */}

        {UserViews[CurrentRenderView]}
      </S.Container>
    </AccessControl>
  );
};
