export const multiOptionData = [
    {
        label: 'Tech Enabled Business',
        value: 'Tech Enabled Business',
    },
    { label: 'Arts & Design', value: 'Arts & Design' },
    {
        label: 'Education & E-Learning',
        value: 'Education & E-Learning',
    },
    {
        label: 'Clean Energy & Solar',
        value: 'Clean Energy & Solar',
    },
    {
        label: 'Healthcare & Pharmaceuticals',
        value: 'Healthcare & Pharmaceuticals',
    },
    {
        label: 'Transportation & Logistics',
        value: 'Transportation & Logistics',
    },
    {
        label: 'Construction & Engineering',
        value: 'Construction & Engineering',
    },
    { label: 'Oil & Gas', value: 'Oil & Gas' },
    {
        label: 'Manufacturing & Production',
        value: 'Manufacturing & Production',
    },
    {
        label: 'Wholesale & Distribution',
        value: 'Wholesale & Distribution',
    },
    {
        label: 'Service providers',
        value: 'Service providers',
    },
    {
        label: 'Agribusiness & Services',
        value: 'Agribusiness & Services',
        subOptions: [
            { label: 'Crop production', value: 'Crop production' },
            {
                label: 'Mechanized Farming',
                value: 'Mechanized Farming',
            },
            {
                label: 'Livestock Farming',
                value: 'Livestock Farming',
            },
            {
                label: 'Machineries & Equipment',
                value: 'Machineries & Equipment',
            },
            {
                label: 'Farmer’s Market Vending',
                value: 'Farmer’s Market Vending',
            },
            {
                label: 'Honey Processing',
                value: 'Honey Processing',
            },
            { label: 'Agrochemicals', value: 'Agrochemicals' },
            { label: 'Dealers in Mechanized Farming', value: 'Dealers in Mechanized Farming' },
            { label: 'Dealers in Farming Equipment and Machineries', value: 'Dealers in Farming Equipment and Machineries' },
            {
                label: ' fisheries & aquaculture',
                value: ' fisheries & aquaculture',
            },
            {
                label: 'Food Processing and Value-Added Products',
                value: 'Food Processing and Value-Added Products',
            },
            {
                label: 'Agricultural Services',
                value: 'Agricultural Services',
            },
        ],
    },
    {
        label: 'Automotives',
        value: 'Automotives',
        subOptions: [
            {
                label: 'Auto parts stores',
                value: 'Auto parts stores',
            },
            {
                label: 'Automotive dealers (car dealership)',
                value: 'Automotive dealers (car dealership)',
            },
            {
                label: 'Specialty automotive retailers',
                value: 'Specialty automotive retailers',
            },
            {
                label: 'Repair stores',
                value: 'Repair stores',
            },
            {
                label: 'Automotive parts distributor',
                value: 'Automotive parts distributor',
            },
        ],
    },
    {
        label: 'IT & Digital services',
        value: 'IT & Digital services',
    },
    {
        label: 'Real Estate & Property',
        value: 'Real Estate & Property',
        subOptions: [
            {
                label: 'Dealers in Real Estate',
                value: 'Dealers in Real Estate',
            },
            {
                label: 'Estate Developers',
                value: 'Estate Developers',
            },
            {
                label: 'Estate Agents And Brokers',
                value: 'Estate Agents And Brokers',
            },
        ],
    },
    {
        label: 'Gaming & Sports', value: 'Gaming & Sports', subOptions: [
            {
                label: 'Pool betting, Casino',
                value: 'Pool betting, Casino',
            },
            {
                label: 'E-Sport',
                value: 'E-Sport',
            },
            {
                label: 'Gym & Fitness',
                value: 'Gym & Fitness',
            },
            {
                label: 'Sports Retailers',
                value: 'Sports Retailers',
            },
            {
                label: 'Outdoor Equipment Stores',
                value: 'Outdoor Equipment Stores',
            },
            {
                label: 'Fitness Equipment Suppliers',
                value: 'Fitness Equipment Suppliers',
            },

        ],
    },
    {
        label: 'Hospitality', value: 'Hospitality', subOptions: [
            {
                label: 'Hotel',
                value: 'Hotel',
            },
            {
                label: 'Restaurant & Bar',
                value: 'Restaurant & Bar',
            },
            {
                label: 'Resort & Recreation Centers',
                value: 'Resort & Recreation Centers',
            },
            {
                label: 'Event Planning & Management',
                value: 'Event Planning & Management',
            },
            {
                label: 'Spa & Wellness',
                value: 'Spa & Wellness',
            },
            {
                label: 'Travel & Tourism',
                value: 'Travel & Tourism',
            },
            {
                label: 'Leisure & Entertainment',
                value: 'Leisure & Entertainment',
            },

        ],
    },
    {
        label: 'High Value Dealers',
        value: 'High Value Dealers',
        subOptions: [
            {
                label: 'Dealers in Precious Stones & Metals',
                value: 'Dealers in Precious Stones & Metals',
            },
            {
                label: 'Dealers in Jewelries',
                value: 'Dealers in Jewelries',
            },
            {
                label: 'High Value dealers',
                value: 'High Value dealers',
            },
        ],
    },
    {
        label: 'Legal & Financial Services',
        value: 'Legal & Financial Services',
        subOptions: [
            {
                label: 'Consultants & Consulting Companies',
                value: 'Consultants & Consulting Companies',
            },
            {
                label: 'Licensed Professional Accountants',
                value: 'Licensed Professional Accountants',
            },
            {
                label: 'Mortgage Brokers',
                value: 'Mortgage Brokers',
            },
            {
                label: 'Tax Consultants',
                value: 'Tax Consultants',
            },
            {
                label: 'Trust & Company Service Providers',
                value: 'Trust & Company Service Providers',
            },
            {
                label: 'Legal Practitioners & Notaries',
                value: 'Legal Practitioners & Notaries',
            },
            {
                label: 'Clearing & Settlement Companies',
                value: 'Clearing & Settlement Companies',
            },
        ],
    },
    {
        label: 'Retail & E Commerce',
        value: 'Retail & E Commerce',
        subOptions: [
            { label: 'Supermarkets', value: 'Supermarkets' },
            {
                label: 'Food and Beverage',
                value: 'Food and Beverage',
            },
            {
                label: 'Personal care & Hygiene',
                value: 'Personal care & Hygiene',
            },
            {
                label: 'Beauty & Cosmetics',
                value: 'Beauty & Cosmetics',
            },
            {
                label: 'Household Products',
                value: 'Household Products',
            },
            {
                label: 'Fashion & Apparel',
                value: 'Fashion & Apparel',
            },
            { label: 'Goods', value: 'Goods' },
            {
                label: 'Consumer Electronics & Appliances',
                value: 'Consumer Electronics & Appliances',
            },
            { label: 'Grocery', value: 'Grocery' },
            {
                label: 'Online & Offline stores',
                value: 'Online & Offline stores',
            },
            {
                label: 'Online Marketplace',
                value: 'Online Marketplace',
            },
            {
                label: 'Pet stores & Veterinary',
                value: 'Pet stores & Veterinary',
            },
            {
                label: 'Specialty retail store',
                value: 'Specialty retail store',
            },
        ],
    },
]


export const industryTypeWithScumlRequired = ['Dealers in Mechanized Farming', 'Dealers in Farming Equipment and Machineries', 'Automotive dealers (car dealership)', 'Real Estate & Property', 'Dealers in Real Estate', 'Estate Developers', 'Estate Agents And Brokers', 'Pool betting, Casino', 'Hotel', 'Legal & Financial Services', 'Consultants & Consulting Companies', 'Licensed Professional Accountants', 'Mortgage Brokers', 'Tax Consultants', 'Trust & Company Service Providers', 'Legal Practitioners & Notaries', 'Clearing & Settlement Companies', 'Supermarkets', 'Hospitality', 'High Value Dealers', 'Dealers in Precious Stones & Metals', 'Dealers in Jewelries']


export const createProfileOnboardingFlow = {
    sole_proprietorship: (path: string) => [
        {
            done: false,
            text: 'Company’s information',
            isActive: path === '/onboarding/corporate/create',
            defaultOpaciy: 1,
            route: '/onboarding/corporate/create',
            key: 'companyInfo',
        },
        {
            done: false,
            text: 'Set up login information',
            isActive: path === '/onboarding/credentials',
            route: '/onboarding/credentials',
            defaultOpaciy: 0.8,
            key: 'loginInfo',
        },
        {
            done: false,
            text: 'Super admin’s details ',
            isActive: path === '/onboarding/personal-information',
            defaultOpaciy: 0.6,
            key: 'adminInfo',
            route: '/onboarding/personal-information',
        },
        {
            done: false,
            text: 'Select business type',
            isActive: path === '/onboarding/type-of-business',
            defaultOpaciy: 0.5,
            key: 'businessType',
            route: '/onboarding/type-of-business',
        },
        {
            done: false,
            text: 'Management’s details',
            isActive: path === '/onboarding/proprietors-details' || path === '/onboarding/management-details',
            defaultOpaciy: 0.4,
            key: 'managementInfo',
            route: ['/onboarding/proprietors-details', '/onboarding/management-details'],
        },
        {
            done: false,
            text: 'Select workflow',
            isActive:
                path === '/onboarding/operators' ||
                path === '/onboarding/non-approval-role' ||
                path === '/onboarding/role',
            defaultOpaciy: 0.4,
            key: 'workflow',
            route: [
                '/onboarding/operators',
                '/onboarding/non-approval-role',
                '/onboarding/role',
            ],
        },
        // {
        //   done: false,
        //   text: 'Set approval limit',
        //   isActive: path === '/onboarding/approval-limit',
        //   defaultOpaciy: 0.4,
        //   key: 'approvallLimit',
        //   route: '/onboarding/approval-limit',
        // },
        {
            done: false,
            text: 'Consent letter',
            isActive: path === '/onboarding/directors',
            defaultOpaciy: 0.4,
            key: 'consentLetter',
            route: '/onboarding/directors',
        },
        // {
        //   done: false,
        //   text: 'Set transaction PIN',
        //   isActive: path === '/onboarding/credentials/pin',
        //   defaultOpaciy: 0.4,
        //   key: 'setPin',
        //   route: '/onboarding/credentials/pin',
        // },
        {
            done: false,
            text: 'Preview',
            isActive: path === '/onboarding/preview-details',
            defaultOpaciy: 0.4,
            key: 'preview',
            route: '/onboarding/preview-details',
        },
    ]
}


export const signupOnboardingFlow = {
    sole_proprietorship: (path: string) => [
        {
            done: false,
            text: 'Personal information',
            isActive: path === 'onboarding/signup/verify-bvn' || path === '/onboarding/signup/personal-information' || path === '/onboarding/otp/phone' || path === '/onboarding/otp/email',
            defaultOpaciy: 1,
            route: ['/onboarding/signup/personal-information', '/onboarding/otp/phone'],
            key: 'personalInfo',
        },
        {
            done: false,
            text: 'Set up login information',
            isActive: path === '/onboarding/create-password',
            route: '/onboarding/create-password',
            defaultOpaciy: 0.8,
            key: 'loginInfo',
        },

        {
            done: false,
            text: 'Business type',
            isActive: path === '/onboarding/type-of-business',
            defaultOpaciy: 0.5,
            key: 'businessType',
            route: '/onboarding/type-of-business',
        },
        {
            done: false,
            text: 'Company information',
            isActive: path === '/onboarding/company-information',
            defaultOpaciy: 0.4,
            key: 'companyInfo',
            route: '/onboarding/company-information',
        },
        {
            done: false,
            text: 'Management’s details',
            isActive: path === '/onboarding/proprietors-details' || path === '/onboarding/management-details',
            defaultOpaciy: 0.4,
            key: 'managementInfo',
            route: ['/onboarding/proprietors-details', '/onboarding/management-details'],
        },
        {
            done: false,
            text: 'Upload document',
            isActive: path === '/onboarding/upload-documents',
            defaultOpaciy: 0.4,
            key: 'uploadDocument',
            route: '/onboarding/upload-documents',
        },
        {
            done: false,
            text: 'Reference information',
            isActive: path === '/onboarding/reference-information',
            defaultOpaciy: 0.4,
            key: 'referenceInfo',
            route: '/onboarding/reference-information',
        },
        // {
        //     done: false,
        //     text: 'Select workflow',
        //     isActive:
        //         path === '/onboarding/operators' ||
        //         path === '/onboarding/non-approval-role' ||
        //         path === '/onboarding/role',
        //     defaultOpaciy: 0.4,
        //     key: 'workflow',
        //     route: [
        //         '/onboarding/operators',
        //         '/onboarding/non-approval-role',
        //         '/onboarding/role',
        //     ],
        // },
        // {
        //   done: false,
        //   text: 'Set approval limit',
        //   isActive: path === '/onboarding/approval-limit',
        //   defaultOpaciy: 0.4,
        //   key: 'approvallLimit',
        //   route: '/onboarding/approval-limit',
        // },
        {
            done: false,
            text: 'Consent letter',
            isActive: path === '/onboarding/directors',
            defaultOpaciy: 0.4,
            key: 'consentLetter',
            route: '/onboarding/directors',
        },
        // {
        //   done: false,
        //   text: 'Set transaction PIN',
        //   isActive: path === '/onboarding/credentials/pin',
        //   defaultOpaciy: 0.4,
        //   key: 'setPin',
        //   route: '/onboarding/credentials/pin',
        // },
        {
            done: false,
            text: 'Preview',
            isActive: path === '/onboarding/preview-details',
            defaultOpaciy: 0.4,
            key: 'preview',
            route: '/onboarding/preview-details',
        },
    ],

    limited_liability: (path: string) => [
        {
            done: false,
            text: 'Personal information',
            isActive: path === 'onboarding/signup/verify-bvn' || path === '/onboarding/signup/personal-information' || path === '/onboarding/otp/phone' || path === '/onboarding/otp/email',
            defaultOpaciy: 1,
            route: ['/onboarding/signup/personal-information', '/onboarding/otp/phone'],
            key: 'personalInfo',
        },

        {
            done: false,
            text: 'Business type',
            isActive: path === '/onboarding/type-of-business',
            defaultOpaciy: 0.5,
            key: 'businessType',
            route: '/onboarding/type-of-business',
        },
        {
            done: false,
            text: 'Company information',
            isActive: path === '/onboarding/company-information',
            defaultOpaciy: 0.4,
            key: 'companyInfo',
            route: '/onboarding/company-information',
        },
        {
            done: false,
            text: 'Management’s details',
            isActive: path === '/onboarding/proprietors-details' || path === '/onboarding/management-details',
            defaultOpaciy: 0.4,
            key: 'managementInfo',
            route: ['/onboarding/proprietors-details', '/onboarding/management-details'],
        },
        {
            done: false,
            text: 'Ultimate beneficial details',
            isActive: path === '/onboarding/ubo',
            defaultOpaciy: 0.4,
            key: 'uboInfo',
            route: '/onboarding/ubo',
        },
        {
            done: false,
            text: 'Upload document',
            isActive: path === '/onboarding/upload-documents',
            defaultOpaciy: 0.4,
            key: 'uploadDocument',
            route: '/onboarding/upload-documents',
        },
        {
            done: false,
            text: 'Reference information',
            isActive: path === '/onboarding/reference-information',
            defaultOpaciy: 0.4,
            key: 'referenceInfo',
            route: '/onboarding/reference-information',
        },
        // {
        //     done: false,
        //     text: 'Select workflow',
        //     isActive:
        //         path === '/onboarding/operators' ||
        //         path === '/onboarding/non-approval-role' ||
        //         path === '/onboarding/role',
        //     defaultOpaciy: 0.4,
        //     key: 'workflow',
        //     route: [
        //         '/onboarding/operators',
        //         '/onboarding/non-approval-role',
        //         '/onboarding/role',
        //     ],
        // },
        // {
        //   done: false,
        //   text: 'Set approval limit',
        //   isActive: path === '/onboarding/approval-limit',
        //   defaultOpaciy: 0.4,
        //   key: 'approvallLimit',
        //   route: '/onboarding/approval-limit',
        // },
        {
            done: false,
            text: 'Consent letter',
            isActive: path === '/onboarding/directors',
            defaultOpaciy: 0.4,
            key: 'consentLetter',
            route: '/onboarding/directors',
        },
        // {
        //   done: false,
        //   text: 'Set transaction PIN',
        //   isActive: path === '/onboarding/credentials/pin',
        //   defaultOpaciy: 0.4,
        //   key: 'setPin',
        //   route: '/onboarding/credentials/pin',
        // },
        {
            done: false,
            text: 'Preview',
            isActive: path === '/onboarding/preview-details',
            defaultOpaciy: 0.4,
            key: 'preview',
            route: '/onboarding/preview-details',
        },
    ]


}