import styled from 'styled-components';

import { crossBrowserTransition, ptr } from '../../../../styles/utils';
import { colors, transition } from '../../../../styles/variables';

const ForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${ptr(12)};
  font-weight: 700;
`;
const InvalidTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  ${crossBrowserTransition(transition.slow)};
`;
const InvalidIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ptr(24)};
  height: ${ptr(24)};
  border-radius: 50%;
  background-color: ${colors.red._10};
  margin-right: ${ptr(12)};
`;

export {
  ForgotPasswordWrapper,
  InvalidTextWrapper,
  InvalidIconWrapper,
};
