/* eslint-disable */

export interface ValidateObject {
  [key: string]: any,
}

interface ValidateScheme {
  [key: string]: (value: any) => boolean,
}

interface ValidateResultItem {
  value: any,
  isValid: boolean,
}

interface ValidateResultData {
  [key: string]: ValidateResultItem,
}

export interface ValidateResult {
  isValid: boolean,
  data: ValidateResultData,
}

export interface ValidateArrayResult {
  isValid: boolean,
  data: Array<ValidateResult | null>,
}

export const validateObject = (
  validateObj: ValidateObject,
  validateScheme: ValidateScheme,
): ValidateResult => {
try {
  return Object.entries(validateObj).reduce(
    (result, [key, value]) => {
      const isValid = validateScheme[key] ?  validateScheme[key](value) : true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.isValid = Boolean(result.isValid * isValid); // Discrete multiplication
      result.data = {
        ...result.data,
        [key]: { value, isValid },
      };
      return result;
    },
    { isValid: true, data: {} },
  );
} catch (error) {
  return Object.entries(validateObj).reduce(
    (result, [key, value]) => {
      const isValid = false
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.isValid = false
      result.data = {
        ...result.data,
        [key]: { value, isValid },
      };
      return result;
    },
    { isValid: true, data: {} },
  );
}
  
};

export const validateArrayOfObjects = (
  validateArrayOfObjects: Array<ValidateObject>,
  validateScheme: ValidateScheme,
): ValidateArrayResult => {
  return <ValidateArrayResult>validateArrayOfObjects.reduce(
    (result, validateObj) => {
      if (!validateObj) {
        return {
          ...result,
          data: [
            ...result.data,
            null,
          ],
        };
      }

      const validateObjectResult = validateObject(validateObj, validateScheme);

      return {
        isValid: Boolean(result.isValid * +validateObjectResult.isValid), // Discrete multiplication,
        data: [
          ...result.data,
          validateObjectResult,
        ],
      };
    },
    { isValid: true, data: [] },
  );
};
