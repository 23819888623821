import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';

import * as S from '../InfoForm/styles';
import { TextInput } from '../../../../components/TextInput';

export interface SimpleFormProps {
  firstName: string;
  lastName: string;
  email?: string;
  index?: number;
  removeFromIndex?: number;
  emailIsValid?: boolean;
  firstNameIsValid?: boolean;
  lastNameIsValid?: boolean;
  remove?: (index: number) => void;
  onChange?: (index: number, property: string, value: string) => void;
  title?: string;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const InfoForm3: React.FC<SimpleFormProps> = ({
  firstName,
  lastName,
  email,
  index,
  removeFromIndex = 0,
  emailIsValid,
  firstNameIsValid,
  lastNameIsValid,
  title,
  remove = () => {},
  onChange = () => {},
}) => (
  <S.ItemContainer>
    <S.HeaderRow>
      <Text size={5} color='#A0A3BD'>
        {title}
      </Text>
      {index && index > removeFromIndex ? (
        <S.Remove onClick={() => remove(index)}>
          <Icon name='trash' color={colors.pink._100} />
        </S.Remove>
      ) : null}
    </S.HeaderRow>

    <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
      <div style={{ flex: 1 }}>
        <TextInput
          label='Name'
          type='text'
          value={firstName}
          placeholder='Enter referee name'
          onChange={(value) => onChange(index as number, 'firstName', value)}
          valid={firstNameIsValid}
        />
      </div>
      <div style={{ flex: 1 }}>
        <TextInput
          label='Email address'
          value={email}
          placeholder='Enter referee email'
          onChange={(value) => onChange(index as number, 'email', value)}
          valid={emailIsValid}
        />
      </div>
    </S.ItemRow>
  </S.ItemContainer>
);
