import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Container = styled.div`
  margin: ${ptr(0)} ${ptr(50)};

  @media (max-width: ${breakpoints.sm}px) {
    margin: 0 ${ptr(5)};
  }
`;

const TitleWrapper = styled.div`
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #d9dbe9;
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 30px;
  ul {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #2c2a3b;
  }
  li {
    margin-top: 10px;
  }
  a {
    color: ${colors.pink._5};
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 70px;
`;

const SectionTwo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 90px;
  @media (max-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

const FirstContent = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export {
  Container,
  TitleWrapper,
  SectionContent,
  ButtonContainer,
  SectionTwo,
  FirstContent,
};
