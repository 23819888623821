import styled from 'styled-components';
import { ptr } from '../../../styles/utils';

const Container = styled.div`
`;

const Wrapper = styled.div`
`;

const Lhs = styled.div`
display: flex; 
flex-direction:column;
`;

const Rhs = styled.div`
display: flex; 
gap:24px;
cursor:pointer;
`;

const AddedTrusteeDiv = styled.div`
padding:12px 16px;
border-radius: 8px;
border: 1px solid #D9DBE9;
display: flex; 
justify-content: space-between;
align-items: center;
margin-bottom:20px;
background: var(--grayscale-input-background, #EFF0F6); 
`;

const TopTitle = styled.div`
display:flex;
flex-direction:column;
gap:30px;
margin-bottom:${ptr(30)};
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const Add = styled.button`
  display: flex;
  align-items: center;
  max-width: fit-content;
  border: 0;
  padding: 5px 13px;
  border-radius: 20px;
  border: 1px solid #C8D2DF;
  background: #F1F1F5;
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-left: ${ptr(7)};
  }
`;

const InfoContainer
= styled.div`
width:85%;
  margin: 4px 0px 30px 0;
`;

export {
  Container, Wrapper, TopTitle, ButtonGroup, Add, InfoContainer, AddedTrusteeDiv, Lhs, Rhs
};
