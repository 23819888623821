import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const Container = styled.div`
  > button {
    width: 100%
  }
`;

const Head = styled.div`
  /* border-bottom: ${ptr(1)} solid ${colors.black._10}; */
  /* padding-bottom: ${ptr(32)}; */

  > span {
    margin-top: ${ptr(32)};
    display: block;
  }

  > :nth-child(2) {
    margin-top: ${ptr(4)};
    margin-bottom: ${ptr(16)};
  }
`;

const Wrapper = styled.div`
  margin: ${ptr(32)} 0;

  > :not(:first-child) {
    margin-top: ${ptr(20)};
  }
`;

const Add = styled.button`
  display: flex;
  align-items: center;
  max-width: fit-content;
  border: 0;
  background: none;
  margin-bottom: ${ptr(40)};

  height: 40px;

border-radius: 20px;
border: 1px solid #C8D2DF;
background: #F1F1F5;
padding: 0 10px;

  > span {
    display: block;
    margin-left: ${ptr(16)};
  }
`;

const OperatorRole = styled.div`
  > span {
    display: block;
    margin-bottom: ${ptr(16)};
    margin-top: ${ptr(32)};
  }
`;

const InfoContainer
  = styled.div`
width:85%;
  margin: 4px 0px 30px 0;
`;

const ButtonContainer = styled.div`
  /* margin-top: ${ptr(30)}; */
  display: flex;
  justify-content: flex-end;
  border-top:solid 4px #DBDFE4;
  /* height: 80px; */
  padding-top: 30px;
  align-items: center;
  button {
    width: 45%;
  }
`;

export {
  Container, Wrapper, Add, Head, OperatorRole, InfoContainer, ButtonContainer
};
