import React, { useState } from 'react';

// import { useHistory } from 'react-router-dom';

import { Title } from '../../Onboarding/components/Title';

import * as S from './styles';
import { Preload } from '../../../components/Preload';
import { Text } from '../../../components/Text';
import { CheckAreaGroup } from '../../../components/CheckAreaGroup';
import { Button } from '../../../components/Button';

const CorporateOperatorSwitcher: React.FC = () => {
  /**
   * Hooks initiation region
   */
  // const route = useHistory();
  const [loading] = useState(false);

  /**
   * Redux store initiation region
   */
  // const leaders = useSelector(
  //   (state: { onboardingStack: OnboardingInterface }) => state
  //     .onboardingStack.data.directors as Array<LeaderDirectorShareholderInterface>,
  // );
  // const reference = useSelector(
  //   (state: { onboardingStack: OnboardingInterface }) => state
  //     .onboardingStack.data.reference as string,
  // );

  /**
   * Custom handlers initiation region
   */

  const next = () => {
    // route.push('/');
  };

  return (
    <S.Container>
      <Title title="Operators & Authorization" />
      <S.TextContainer>
        <Text>Will you be operating the account alone?</Text>

      </S.TextContainer>
      {loading
        ? (
          <S.PreloadContainer>
            <Preload />
          </S.PreloadContainer>
        )

        : (
          <S.Wrapper>

            <CheckAreaGroup
              items={[
                {
                  theme: 'dark',
                  value: 'Yes',
                },
                {
                  theme: 'dark',
                  value: 'No',
                },

              ]}
              onChange={() => {}}
            />

            <Button label="Continue" onClick={next} />
          </S.Wrapper>
        )}
    </S.Container>
  );
};
export default CorporateOperatorSwitcher;
