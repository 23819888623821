import styled, { css } from 'styled-components';

import { colors, breakpoints, fonts } from '../../../../styles/variables';
import { ptr } from '../../../../styles/utils';

const Container = styled.div`
  padding: ${ptr(32)};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  // max-width: 532px;
  //  max-width: 442px;
  //  width:100%
  display: flex;
  flex-direction: column;
  // align-items: center;
  white-space: nowrap;
  background: ${colors.black._2};

  height: 112px;
  margin-bottom: -1px;
  @media (max-width: ${ptr(breakpoints.xl)}) {
    max-width: 100%;
    // height: 68%;
    margin-bottom: 40px;
  }
`;

const Links = styled.div`
  background: ${colors.black._2};
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  justify-content: space-between;
  overflow: auto;

  overflow-x: scroll;
  overflow: scroll;
  position: sticky;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LinksB = styled.div`
  position: absolute; 
width:100%
  display: flex;
  width: inherit;
  max-width: inherit;
 
  overflow: auto;
  overflow-x: auto; 
  flex-wrap: nowrap;
  // max-width: fit-content;

>div{
  ::-webkit-scrollbar {
    display: none;
  }
}

`;

const LinksInner = styled.div`
  border-radius: 70px;
  background: rgba(219, 223, 228, 0.39);
  margin-right: 8px;

  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  min-width: 173px;
  gap: 8px;
  overflow: auto;
  overflow-x: auto;

  :last-child {
    margin-right: 0px;
  }

  > svg {
    // background: #eff0f6;
    // width: 22px;
    // height: 20px;
    // display: flex;
    // padding: 12px;
    // justify-content: center;
    // align-items: center;
    // gap: 12px;
    // border-radius: 45px;
    // margin-bottom: 9px;
  }
`;

const LinksInnerText = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
`;

export { Container, LinksB, Links, LinksInner, LinksInnerText };
