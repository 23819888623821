/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import {
  LeaderDirectorShareholderInterface,
  DirectorPreviewDetails,
} from '../../../services/internal/onboarding/IOnboarding';
import debug from '../../../services/internal/debbug.service';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { ManDetails } from '../../Onboarding/components/ManDetails';
import { actions } from '../config';

import * as S from './styles';

// TEMPORARY

export const PurviewDetails: FC<{
  purviewData: Array<DirectorPreviewDetails>;
  hasRoles?: boolean;
  onlyText?: boolean;
}> = ({ purviewData, onlyText }) => (
  <>
    {purviewData &&
      purviewData.length > 0 &&
      purviewData.map((data, index) => (
        <ManDetails
          key={index}
          name={`${data.firstName} ${data.middleName} ${data.lastName}`}
          phone={data.phone || '+xxx xxx xxx xxxx'}
          dateOfBirth={data.dob}
          address={data.companyAddress || data.address || 'Not Set'}
          image={data.avatarUrl}
          onlyText={onlyText}
        />
      ))}
  </>
);

const CommonCompanyCheckManagement: React.FC = () => {
  /**
   * Hooks initiation region
   */

  const route = useHistory();
  const { actionRequired } = useLocation<{ actionRequired: string }>().state;

  /**
   * Redux store initiation region
   */
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<
        LeaderDirectorShareholderInterface
      >
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );
  const directorPreviewDetails = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.serverResponse as Array<DirectorPreviewDetails>
  );

  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    debug.info('', [leaders, reference]); // TEMPORARY

    route.push(actions[actionRequired]);
  };
  return (
    <S.Container>
      <Title title='Check management details' />
      <S.Wrapper>
        <PurviewDetails purviewData={directorPreviewDetails} />
      </S.Wrapper>
      <Button label='Continue' onClick={continueHandle} />
    </S.Container>
  );
};

export default CommonCompanyCheckManagement;
