import React, { useState, useEffect } from 'react';

import * as S from './styles';

export interface SwitchProps {
  /**
   * Is this the principal call to action on the page?
   */
  checked?: boolean;
  /**
   * Is disabled
   */
  disabled?: boolean;
  /**
   * Theme of the component
   */
  theme?: 'dark1' | 'dark2';
  size?: 'small' | 'regular';
  /**
   * Text value
   */
  text?: string;
  /**
   * Optional click handler
   */
  onClick?: (isChecked: boolean) => void;
}

const SwitchComponent: React.FC<SwitchProps> = ({
  theme = 'dark1',
  size = 'regular',
  checked = false,
  disabled = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <S.Wrapper
      size={size}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          setIsChecked(!isChecked);

          if (props.onClick) {
            props.onClick(!isChecked);
          }
        }
      }}
    >
      {props.text && <S.Text>{props.text}</S.Text>}
      <S.SwitchWrapper
        size={size}
        theme={theme}
        checked={isChecked}
        disabled={disabled}
      >
        <S.Circle size={size} checked={isChecked} />
      </S.SwitchWrapper>
    </S.Wrapper>
  );
};

export const Switch = React.memo(SwitchComponent);
