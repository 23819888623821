/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import validator from 'validator';
import { colors } from '../../../../styles/variables';
import { useHistory } from 'react-router-dom';
import {
  OnboardingInterface,
  BusinessType,
} from '../../../../redux/onboarding/IOnboarding';
import {
  ReferencesInterface,
  LeaderDirectorShareholderInterface,
} from '../../../../services/internal/onboarding/IOnboarding';
import { Button } from '../../../../components/Button';
import { Title } from '../../../Onboarding/components/Title';
import { InfoForm3 } from '../../../Onboarding/components/InfoForm3';
import { Modal } from '../../../../components/LoadingModal';

import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../../../utils/validation';
import * as S from './styles';
import { Text } from '../../../../components/Text';
import { OnboardingActions } from '../../../../redux/onboarding/actions';
import { actions } from '../../../Onboard/config';
import onboardingService from '../../../../services/internal/onboarding/onboarding.service';
import { OnboardingCard } from '../../../../components/OnboardingCard';
import { InfoConponent } from '../../../../components/Info';

const SCHEME = {
  firstName: (value: string) => value.length > 1,
  lastName: (value: string) => value.length > 1,
  email: (value: string) => validator.isEmail(value),
};

const directorDefaultItem = {
  firstName: '',
  lastName: '',
  email: '',
};

export const CommonReferenceInfo: React.FC = () => {
  const route = useHistory();
  const dispatch = useDispatch();

  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const referenceInfo = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.referenceInfo as Array<ReferencesInterface>
  );

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<ReferencesInterface>>(() =>
    referenceInfo?.length
      ? [...referenceInfo]
      : [directorDefaultItem, directorDefaultItem]
  );
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: ReferencesInterface, i: number) => {
          if (i === index) {
            if (property === 'firstName') {
              const val = value as string;
              return {
                ...el,
                [property]: val.split(' ')[0],
                lastName: val.split(' ')[1] || '',
              };
            }
            return {
              ...el,
              [property]: value,
            };
          }
          return el;
        }) as Array<ReferencesInterface>
    );
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  const continueHandle = async () => {
    setLoading(true);
    try {
      const response = await onboardingService.submitReferences({
        references: collection.map((col) => ({
          firstName: col.firstName,
          lastName: col.lastName,
          email: col.email,
        })),
        reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            referenceInfo: collection,
            reference: response.data.reference,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (err) {
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  return (
    <S.Container>
      <S.TopTitle>
        <Title
          color={colors.black._100}
          title='Reference Information'
          subTitle='Referees must either be a current account or corporate account from VBank or any other bank'
        />
      </S.TopTitle>

      <S.InfoContainer>
        <InfoConponent text='The reference form will be sent to the referees via email to fill.' />
      </S.InfoContainer>

      <S.Wrapper>
        <OnboardingCard>
          <Text
            bold
            size={4}
            color={colors.black._20}
            style={{ marginBottom: '14px' }}
          >
            Provide details of 2 referees 
          </Text>
          {collection.map((item, index) => (
            <S.Reference>
              <InfoForm3
                title={`Reference ${index + 1}`}
                firstName={item.firstName}
                lastName={item.lastName}
                email={item.email}
                index={index}
                removeFromIndex={1}
                onChange={onChangeValue}
                emailIsValid={
                  validationCollection?.data[index]?.data.email.isValid
                }
                firstNameIsValid={
                  validationCollection?.data[index]?.data.firstName.isValid
                }
                lastNameIsValid={
                  validationCollection?.data[index]?.data.lastName.isValid
                }
              />
            </S.Reference>
          ))}
          <S.ButtonGroup>
            <div>
              <Button
                label='Continue'
                loading={loading}
                style={{ width: 304 }}
                disabled={!validationCollection?.isValid || loading}
                onClick={continueHandle}
              />
            </div>
          </S.ButtonGroup>
        </OnboardingCard>
      </S.Wrapper>
      {/* <Modal visible={loading} /> */}
    </S.Container>
  );
};
