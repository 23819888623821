import { DirectorPreviewDetails, OnboardingSummary} from '../../services/internal/onboarding/IOnboarding';
import { ActionInterface } from '../IAction';
import { StackInterface, BusinessType, BusinessInfo } from './IOnboarding';

export const SET_IN_APP = 'SET_IN_APP';
export const UNSET_IN_APP = 'UNSET_IN_APP';

export const NEXT_STACK = 'NEXT_STACK';
export const NEXT_STEP = 'NEXT_STEP';

export const SET_REQUIRED_ACTION = 'SET_REQUIRED_ACTION';

export const EXTEND_STEPS_TO_TOP = 'EXTEND_STEPS';

export const SET_DATA = 'SET_DATA';
export const SET_BUSINESS_TYPE = 'SET_BUSINESS_TYPE';
export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';
export const SET_ERROR = 'SET_ERROR';

export const BACK = 'BACK';

export const RESET_STACKS = 'RESET_STACKS';

export const HAS_ACCOUNT = 'HAS_ACCOUNT';
export const HAS_NOT_ACCOUNT = 'HAS_NOT_ACCOUNT';
export const SET_SERVER_DIRECTOR_RESPONSE = 'SET_SERVER_DIRECTOR_RESPONSE';
export const SET_ONBOARDING_SUMMARY_RESPONSE = 'SET_SERVER_OPERATOR_RESPONSE';
export const RESET_STATE = 'RESET_STATE';

export const DONT_USE_DETAILS = "DON'T USE DETAILS";

const setEnv = (): ActionInterface<unknown> => ({
  type: SET_IN_APP,
});

const unsetEnv = (): ActionInterface<unknown> => ({
  type: UNSET_IN_APP,
});

const setRequiredAction = (payload: string): ActionInterface<unknown> => ({
  type: SET_REQUIRED_ACTION,
  payload,
});

const nextStack = (payload: StackInterface): ActionInterface<unknown> => ({
  type: NEXT_STACK,
  payload,
});

const extendStepsToTop = (payload: StackInterface): ActionInterface<unknown> => ({
  type: EXTEND_STEPS_TO_TOP,
  payload,
});

const setData = (payload: Record<string, unknown>): ActionInterface<unknown> => ({
  type: SET_DATA,
  payload,
});

const setServerDirectorResponse = (payload: Array<DirectorPreviewDetails>):
ActionInterface<unknown> => ({
  type: SET_SERVER_DIRECTOR_RESPONSE,
  payload,
});

const setServerSummaryResponse = (payload: OnboardingSummary):
ActionInterface<unknown> => ({
  type: SET_ONBOARDING_SUMMARY_RESPONSE,
  payload,
});

const setBusinessType = (payload: BusinessType): ActionInterface<unknown> => ({
  type: SET_BUSINESS_TYPE,
  payload,
});

const setBusinessInfo = (payload: BusinessInfo): ActionInterface<unknown> => ({
  type: SET_BUSINESS_INFO,
  payload,
});


const setError = (payload: string): ActionInterface<unknown> => ({
  type: SET_ERROR,
  payload,
});

const nextStep = (): ActionInterface<unknown> => ({
  type: NEXT_STEP,
});

const back = (): ActionInterface<unknown> => ({
  type: BACK,
});

const resetStacks = (): ActionInterface<unknown> => ({
  type: RESET_STACKS,
});

const has = (): ActionInterface<unknown> => ({
  type: HAS_ACCOUNT,
});

const hasNot = (): ActionInterface<unknown> => ({
  type: HAS_NOT_ACCOUNT,
});

const resetOnboardingState = (): ActionInterface<unknown> => ({
  type: RESET_STATE,
});

const dontUseDeails = (): ActionInterface<unknown> => ({
  type: DONT_USE_DETAILS,
});

export class OnboardingActions {
  public static handleSetEnv(): ActionInterface<unknown> {
    return setEnv();
  }

  public static handleUnSetEnv(): ActionInterface<unknown> {
    return unsetEnv();
  }

  public static handleRequiredAction(payload: string): ActionInterface<unknown> {
    return setRequiredAction(payload);
  }

  public static handleNextStack(payload: StackInterface): ActionInterface<unknown> {
    return nextStack(payload);
  }

  public static handleExendStackToTop(payload: StackInterface): ActionInterface<unknown> {
    return extendStepsToTop(payload);
  }

  public static handleSetData(payload: Record<string, unknown>): ActionInterface<unknown> {
    return setData(payload);
  }

  public static handleSetServerDirectorResponse(payload: Array<DirectorPreviewDetails>):
  ActionInterface<unknown> {
    return setServerDirectorResponse(payload);
  }

  public static handleSetOnBoardingSummaryResponse(payload: OnboardingSummary):
  ActionInterface<unknown> {
    return setServerSummaryResponse(payload);
  }

  public static handleSetBusinessType(payload: BusinessType): ActionInterface<unknown> {
    return setBusinessType(payload);
  }

  public static handleSetBusinessInfo(payload: BusinessInfo): ActionInterface<unknown> {
    return setBusinessInfo(payload);
  }

  public static handleSetError(payload: string): ActionInterface<unknown> {
    return setError(payload);
  }

  public static handleNextStep(): ActionInterface<unknown> {
    return nextStep();
  }

  public static handleBack(): ActionInterface<unknown> {
    return back();
  }

  public static handleResetStacks(): ActionInterface<unknown> {
    return resetStacks();
  }

  public static handleHasAccount(): ActionInterface<unknown> {
    return has();
  }

  public static handleHasNotAccount(): ActionInterface<unknown> {
    return hasNot();
  }

  public static handleDontUseDetails(): ActionInterface<unknown> {
    return dontUseDeails();
  }

  public static resetOnboardingState(): ActionInterface<unknown> {
    return resetOnboardingState();
  }
}
