import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import LoginMethod from "../pages/LoginMethod";
import LogIn from "../pages/LogIn";
import SignUp from "../pages/SignUp";
import ResetPassword from "../pages/ResetPassword";
import Info from "../pages/Info";
import NewPassword from "../pages/NewPassword";
import { NotFound } from "../pages/NotFound";
import CreatePassword from "../pages/CreatePassword";
import { NewUser } from "../pages/NewUser";
// import { Onboarding } from '../pages/Onboarding';
import { UnregisteredBusiness } from "../pages/UnregisteredBusiness";
import Onboard from "./onboardingContainer";
import { ReferenceForm } from "../pages/reference";
import { ConsentForm } from "../pages/Consentform";
import { SubaccountConsentForm } from "../pages/SubaccountConsentform";

const PublicRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/">
      {/* <LoginMethod /> */}
      <LogIn />
    </Route>
    <Route path="/login">
      <LogIn />
    </Route>
    <Route path="/onboarding">
      <Onboard />
    </Route>
    <Route path="/sign-up">
      <SignUp />
    </Route>
    <Route path="/reset-password">
      <ResetPassword />
    </Route>
    <Route path="/done">
      <Info
        title="Done!"
        description="We have sent an email to reset your password. Follow the link to create a new password."
      />
    </Route>
    <Route path="/request-sent">
      <Info
        title="Request sent successfully!"
        description="After the approval you will receive an email with a link to create password in order to complete activation.
After password is set up you will get an email with username and password with which you can log into your account."
      />
    </Route>
    <Route path="/new-password">
      <NewPassword />
    </Route>
    <Route path="/create-password">
      <CreatePassword />
    </Route>
    <Route exact path="/new-user">
      <NewUser />
    </Route>
    <Route path="/new-user/unregistered">
      <UnregisteredBusiness />
    </Route>
    <Route path="/new-user/unregistered">
      <UnregisteredBusiness />
    </Route>
    <Route path="/leader">
      <ConsentForm />
    </Route>
    <Route path="/subaccount/leader">
      <SubaccountConsentForm />
    </Route>
    <Route path="/reference-form">
      <ReferenceForm />
    </Route>
    <Redirect from="*" to="/" />
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
);

export default PublicRoutes;
