/* eslint-disable max-len */
import React from 'react';
import { BeneficiariesManagementView, IBeneficiaries } from '../../pages/BeneficiariesManagement/view';

import { BankNames } from '../../pages/OtherBankTransfer';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import { IBeneficiary } from '../SelectBeneficiaryType';
import * as S from './styles';

export interface SelectBeneficiariesModalProps {
  onCloseModal?: () => void,
  /**
   * Show modal
   */
  showModal?: boolean,
  bankNames?: Array<BankNames>,
  beneficiariesTypes?: Array<IBeneficiary>,
  setBeneficiariesTypes: (value: Array<IBeneficiary>) => void,
  selectBeneficiaries?: (value: Array<IBeneficiaries>) => void,
}

export const SelectBeneficiariesModal: React.FC<SelectBeneficiariesModalProps> = ({
  onCloseModal = () => {},
  selectBeneficiaries = () => {},
  showModal = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleContainerOnBottom = () => {
  };
  return (
    <ModalWrapper
      overflowY="scroll"
      maxHeight="96vh"
      showModal={showModal}
      onCloseModal={onCloseModal}
      crossColor={colors.black._100}
      onBottom={handleContainerOnBottom}
    >
      <S.Margin>
        <BeneficiariesManagementView isModal selectBeneficiaries={selectBeneficiaries} />
      </S.Margin>
    </ModalWrapper>
  );
};
