import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div`
 padding: 50px 100px 50px 100px;
`;

const Wrapper = styled.div`

  margin-bottom: ${ptr(8)};

  > :not(:last-child) {
    margin-bottom: ${ptr(12)};
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${ptr(30)};
  display: flex;
  justify-content: flex-end;
  border-top:solid 4px #DBDFE4;
  /* height: 80px; */
  padding-top: 30px;
  align-items: center;
  button {
    width: 45%;
  }
`;


export { Container, Wrapper, ButtonContainer };
