import React, { useState } from "react";
import { Button } from "../../../components/Button";
import Datepicker from "../../../components/DatePicker";
import {
  DropdownInput,
  DropdownOptionProps,
} from "../../../components/DropdownInput";

import { RoundCheckBoxGroup } from "../../../components/RoundCheckboxGroup";
import { Text } from "../../../components/Text";
import { TextInput } from "../../../components/TextInput";
import { colors } from "../../../styles/variables";
import {
  getDateWithYearFirst,
  timeDateMonthString,
} from "../../../utils/datetime";

import * as S from "../styles";

interface IData {
  from?: Date | string | null;
  to?: Date | string | null;
  filter?: string;
  page?: number;
}
interface IFilterResult {
  action?: (data: IData) => void;
  showModal?: (show: boolean) => void;
}

export const FilterResult: React.FC<IFilterResult> = ({
  action,
  showModal,
}) => {
  const [data, setData] = useState<IData>({});
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [custom, setCustom] = useState<boolean>(false);
  const [status] = useState([
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Failed",
      value: "failed",
    },
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Unknown",
      value: "unknown",
    },
    {
      label: "Processing",
      value: "processing ",
    },
  ]);

  const onChange = (value: boolean, name?: string) => {
    if (!value) {
      setData({ ...data, from: null, to: null });
      setCustom(false);
    } else if (name && name === "Custom") {
      setCustom(true);
    } else if (name && name !== "Custom") {
      setCustom(false);
      const dateValue = name.split(" ");
      const date = new Date();
      if (
        dateValue[1].toUpperCase() === "MONTHS" ||
        dateValue[1].toUpperCase() === "MONTH"
      ) {
        date.setMonth(date.getMonth() - Number(dateValue[0]));
        setData({
          ...data,
          from: getDateWithYearFirst(date),
          to: getDateWithYearFirst(new Date()),
        });
      } else if (
        dateValue[1].toUpperCase() === "YEARS" ||
        dateValue[1].toUpperCase() === "YEAR"
      ) {
        date.setFullYear(date.getFullYear() - Number(dateValue[0]));
        setData({
          ...data,
          from: getDateWithYearFirst(date),
          to: getDateWithYearFirst(new Date()),
        });
      }
    }
  };

  const toggleStart = () => {
    setOpenStart(!openStart);
  };
  const toggleEnd = () => {
    setOpenEnd(!openEnd);
  };
  const onChangeStart = async (date: Date) => {
    setData({ ...data, from: getDateWithYearFirst(new Date(date)), to: null });

    toggleStart();
  };

  const onChangeEnd = async (date: Date) => {
    setData({ ...data, to: getDateWithYearFirst(new Date(date)) });
    toggleEnd();
  };

  const selectStatus = (value: DropdownOptionProps | null) => {
    setData({ ...data, filter: `status:${value?.value}` });
  };

  const submit = () => {
    if (action) action({ ...data, page: 1 });
    if (showModal) showModal(false);
  };

  return (
    <S.InnerContainer>
      <S.Container>
        <S.ModalWrapper>
          <S.Header>
            <S.MarginBottom>
              <Text color={colors.pink._100} size={3} bold>
                Filter
              </Text>
              <S.MarginBottom>
                <Text color={colors.black._60} size={5}>
                  Filter your transactions
                </Text>
              </S.MarginBottom>
            </S.MarginBottom>
          </S.Header>

          <div>
            <S.MarginBottom>
              <Text color={colors.black._100} size={4} bold>
                Account statement period
              </Text>
            </S.MarginBottom>

            <S.MarginBottom>
              <RoundCheckBoxGroup
                onChange={onChange}
                items={[
                  { text: "1 Month" },
                  { text: "3 Months" },
                  { text: "6 Months" },
                  { text: "1 Year" },
                  { text: "2 Years" },
                  { text: "3 Years" },
                  { text: "Custom" },
                ]}
              />
            </S.MarginBottom>
            {/* <S.MarginBottom>
              <Text color={colors.black._100} size={5} bold>
                Status
              </Text>
            </S.MarginBottom> */}

            <S.MarginBottom>
              <S.MarginBottom>
                <S.Container>
                  <Text color={colors.black._100} size={4} bold>
                    Custom period
                  </Text>
                </S.Container>
              </S.MarginBottom>

              <S.Container>
                {custom && (
                  <S.TextInputContainer>
                    <S.TextInputWrapper>
                      <TextInput
                        icon="calender"
                        iconColor="#7F8EA4"
                        label="Start period"
                        type="text"
                        value={
                          data.from
                            ? timeDateMonthString(new Date(data.from))
                            : ""
                        }
                        onIconClick={toggleStart}
                      />
                      <Datepicker
                        isOpen={openStart}
                        dateChange={onChangeStart}
                      />
                    </S.TextInputWrapper>
                    <S.SpaceWrapper>
                      <span> </span>
                    </S.SpaceWrapper>
                    <S.TextInputWrapper>
                      <TextInput
                        icon="calender"
                        iconColor="#7F8EA4"
                        label="End period"
                        type="text"
                        value={
                          data.to ? timeDateMonthString(new Date(data.to)) : ""
                        }
                        onIconClick={toggleEnd}
                      />
                      <Datepicker isOpen={openEnd} dateChange={onChangeEnd} />
                    </S.TextInputWrapper>
                  </S.TextInputContainer>
                )}
                <S.TextInputWrapper>
                  <DropdownInput
                    label="Status"
                    onSelect={selectStatus}
                    options={status}
                    placeholder=""
                    subLabel=""
                  />
                </S.TextInputWrapper>
              </S.Container>
            </S.MarginBottom>
          </div>

          <S.ButtonWrapper1>
            <Button onClick={submit} label="Apply" />
          </S.ButtonWrapper1>
        </S.ModalWrapper>
      </S.Container>
    </S.InnerContainer>
  );
};
