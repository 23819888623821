import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { Text } from '../../../components/Text';
import { FileUploadUpdated } from '../../../components/FileUploadUpdated';
import { FileUploadUpdated2 } from '../../../components/FileUploadUpdated2';
import { colors } from '../../../styles/variables';

export const NewMandate: React.FC<{
         onChangeFile?: (file: File) => void;
         file?: File;
       }> = ({ onChangeFile, ...props }) => {
         const [filetoUpload, setfiletoUpload] = useState('');
         const onChangeValue = (value: any) => {
           setfiletoUpload(value);
           if (onChangeFile) {
             onChangeFile(value);
           }
         };

         return (
           <S.Container>
             <Text bold size={6} color={colors.black._20}>
               Upload board resolution
             </Text>
             <S.Lists>
               <Text
                 size={7}
                 color='#6E7191'
               >{`\u2022 ${'Must clearly state the mandate - person(s) authorized to operate the account.'}`}</Text>
               <Text
                 size={7}
                 color='#6E7191'
               >{`\u2022 ${'Must be stated on your company letterhead with your logo and other contact information'}`}</Text>
               <Text
                 size={7}
                 color='#6E7191'
               >{`\u2022 ${'Must be signed by company management who consented to the main account opening.'}`}</Text>
             </S.Lists>
             <S.UploadDiv>
               <FileUploadUpdated2
                 acceptedFormats={[
                   'image/jpg',
                   'image/jpeg',
                   'application/pdf',
                 ]}
                 onChange={(file) => onChangeValue(file)}
                 file={props.file}
               />
             </S.UploadDiv>
           </S.Container>
         );
       };
