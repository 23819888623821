import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { InfoConponent } from '../../../components/Info';
import { FormTitle } from '../../../components/FormTitle';
import { NewMandate } from './newMandate';
import { MaintainMandate } from './maintainMandate';
import subaccountService from '../../../services/internal/subaccount/subaccount.service';
import { SubaccountInterface } from '../../../redux/subaccount/ISubaccount';
import { failed, success } from '../../../components/Toasts';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';

export interface ViewComponentProps {
  Maintain: any;
  Screen: any;
}

export const CommonBoardResolution: React.FC = () => {
  const route = useHistory();

  const [loading, setLoading] = useState(false);

  const { state } = useLocation<{ tab: string }>();
  const [CurrentUserView, ChangeuserView] = useState<string>('');

  const subaccountRequest = useSelector(
    (state: { subaccount: SubaccountInterface }) =>
      state.subaccount.subaccountRequest
  );
  // useEffect(() => {
  //   const view =
  //     state?.tab && state?.tab === 'UserReport' ? 'Maintain' : 'Maintain';
  //   ChangeuserView(view);
  // }, []);

  const [file, setFile] = useState<File | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();

  useEffect(() => {
    if (subaccountRequest?.boardResolutionLink) {
      getResolutionLink();
    }
  }, [subaccountRequest]);

  const getResolutionLink = async () => {
    try {
      if (subaccountRequest?.boardResolutionLink) {
        const response = await onboardingService.getDocLink({
          key: subaccountRequest.boardResolutionLink,
        });

        fetch(response.url, {
          method: 'GET',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const filename = `boardresolution.${blob.type.split('/')[1]}`;

            // Create a File object from the Blob
            const newFile = new File([blob], filename, { type: blob.type });
            setUploadedFile(newFile);
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      const err = error as any;
      failed('Failed', 'Unable to get document link');
    }
  };

  const handleFileChange = (value: File) => {
    if (value === undefined && uploadedFile) {
      setUploadedFile(value);
    }
    setFile(value);
  };

  const nextPage = () => {
    if (
      subaccountRequest &&
      subaccountRequest?.subAccountConsentApproval?.length >= 2
    ) {
      route.push('/subaccount/preview-details');
      return;
    }
    route.push('/subaccount/subconsent-form');
  };

  const continueHandle = async () => {
    try {
      if (uploadedFile) {
        nextPage();
        return;
      }
      setLoading(true);
      if (subaccountRequest && file) {
        const formData = new FormData();
        formData.append('requestId', subaccountRequest.id);
        formData.append('file', file);
        const response = await subaccountService.uploadBoardResolution(
          formData
        );
        success('Success', response.message);
        nextPage();
      }
    } catch (error) {
      const err = error as any;
      failed('Error', err.message);
    }
    setLoading(false);
  };

  return (
    <S.Container>
      {/* <Modal visible={loading} /> */}
      <S.TopTitle>
        <FormTitle
          margin={-5}
          title="Upload board resolution"
          titleColor={colors.black._20}
        />

        <S.InfoComponent>
          <InfoConponent
            style={{ background: '#FFF9EF' }}
            text="Mandate outlines person(s) authorized to operate the account. "
          />
        </S.InfoComponent>
      </S.TopTitle>

      <OnboardingCard>
        <S.SigningOptionsDiv>
          <NewMandate onChangeFile={handleFileChange} file={uploadedFile} />
        </S.SigningOptionsDiv>

        <S.ButtonGroup>
          <div>
            <Button
              loading={loading}
              style={{ width: 304 }}
              label="Continue"
              onClick={() => continueHandle()}
              // disabled={isCheckedb === false || isChecked === false || loading}
              disabled={!!file && !!uploadedFile}
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
