import React, { useState } from 'react';
import { colors } from '../../styles/variables';
import { Text } from '../Text';
import { useHistory } from 'react-router-dom';
import {
  Details,
  getOnboardModalDetail,
} from '../../utils/onboardModalDetails';
import { OnboardModal } from '../OnBoardModal';
import { SignupOptionModal } from '../SignupOptionModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

export const SignUpButton: React.FC = ({ ...props }) => {
  const history = useHistory();

  const [details, setDetails] = useState<Details | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const preference = useSelector((store: RootState) => store.preference);


  const handleOnboardingClick = (action: string) => {
    const actionDetails = getOnboardModalDetail(action, preference.bvnConsentEnabled);
    setDetails(actionDetails);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setDetails(null);
    setShowModal(false);
  };

  return (
    <>
      <SignupOptionModal
        showModal={showModal}
        onCloseModal={handleCloseModal}
        details={details}
      />
      <div style={{ marginTop: 10, textAlign: 'right' }}>
        <Text color={colors.black._20} size={6} bold>
          New to VBiz?{' '}
          <Text
            color={colors.pink._100}
            size={6}
            bold
            //   onClick={() => history.push('/onboarding')}
            onClick={() => handleOnboardingClick('signupOptions')}
            style={{ cursor: 'pointer' }}
          >
            Sign Up
          </Text>
        </Text>
      </div>
    </>
  );
};
