import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackBar } from '../../../components/BackBar';
import { Button } from '../../../components/Button';
import RadioCheckWithLabelAndDesc from '../../../components/RadioCheckWithLabelAndDesc';
import { Text } from '../../../components/Text';
import { TextInput } from '../../../components/TextInput';
import { limit } from '../../../navigation';
import { colors, fonts } from '../../../styles/variables';
import { PageTitle } from '../../../styles/wrappers';
import { data } from '../../Onboarding/steps/common.type.of.business';
import * as S from '../styles';
import { InfoConponent } from '../../../components/Info';
import { Table, TableFooterProps } from '../../../components/Table';
import TwoFactorAuthenticationModal from '../../../components/2faAuthenticationModal';
import { failed, warning } from '../../../components/Toasts';
import paymentsService from '../../../services/internal/payments/payments.service';
import { useSelector } from 'react-redux';
import { PaymentInterface } from '../../../redux/payment/IPayment';
import { SuccessModal } from '../../../components/SuccessModal';
import * as storage from '../../../utils/storage';
import { Icon } from '../../../components/Icon';
import { downloadFile } from '../../../utils/download';
import AccessControl, { EPermissions } from '../../../components/AccessControl';

const LimitUpload = () => {
  const history = useHistory();
  const fileRef = useRef<HTMLInputElement>(null);
  const savedRequest = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.limitRequest
  );
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploads, setUploads] = useState<
    Array<{ type: string; status: string; file: File | null }>
  >([
    {
      type: 'indemnity',
      status: 'pending',
      file: null,
    },
    { type: 'kycb', status: 'pending', file: null },
  ]);
  const MAX_SIZE = 1024 * 1024 * 2;

  useEffect(() => {
    if (!savedRequest) {
      warning('Info', 'Fill in required information');
      history.goBack();
    }
  }, [savedRequest]);

  const toggleOtpModal = () => setShowOtpModal((prev) => !prev);
  const toggleSuccess = () => setShowSuccess((prev) => !prev);

  const proceed = () => {
    const requireUpload = uploads.filter(
      (upload) => upload.status !== 'uploaded'
    );

    if (requireUpload.length) {
      failed(
        'Error',
        `Please upload ${requireUpload.map((upload) => upload.type).join()}`
      );
      return;
    }
    toggleOtpModal();
  };

  const confirmRequest = async (otp?: string) => {
    try {
      if (!savedRequest?.reference) {
        failed('Error', 'Reference not found');
        return;
      }
      if (!otp) {
        failed('Error', 'Otp is required');
        return;
      }
      setLoading(true);
      const response = await paymentsService.confirmLimitRequest(
        savedRequest?.reference,
        otp
      );

      storage.setItem(
        storage.storageKey.DAILY_LIMIT_REQUEST,
        JSON.stringify(savedRequest)
      );
      toggleOtpModal();
      toggleSuccess();
    } catch (error) {
      const err = error as Error;
      failed(
        'Error',
        err.message ?? 'Unable to finalize request at the moment'
      );
    }
    setLoading(false);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!savedRequest) return failed('Error', 'Request information missing');
      if (e.target.files) {
        setUploading(true);
        const file = e?.target?.files[0];
        if (file.size > MAX_SIZE) {
          failed('Error', 'File should not be more than 2MB');
        }
        const type = uploads[selectedFileIndex ?? 0].type;
        const formData = new FormData();
        formData.append('reference', savedRequest.reference);
        formData.append('type', type);
        formData.append('file', file);

        await paymentsService.uploadLimitRequestDocument(formData);

        const newUploads = [...uploads];
        setUploads(
          newUploads.map((upload) => ({
            ...upload,
            ...(type === upload.type ? { status: 'uploaded', file } : {}),
          }))
        );
      }
    } catch (error) {
      const err = error as Error;
      failed('Error', err.message ?? 'Error uploading document');
    }
    setUploading(false);
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  const handleFileClick = (i: number) => {
    if (fileRef.current) {
      fileRef.current.click();
      setSelectedFileIndex(i);
    }
  };

  const removeUpload = (index: number) => {
    let uploadedData = [...uploads];
    uploadedData = uploadedData.map((upload, i) => {
      if (i == index) {
        upload.file = null;
        upload.status = 'pending';
      }
      return upload;
    });
    setUploads(uploadedData);
  };

  const downloadTemplate = () => {
    downloadFile('/images/documents/Archive.zip', 'Idemnity-and-KYCB-Template');
  };

  return (
    <AccessControl requiredPermission={[EPermissions.CREATE_OPERATOR]} isPage>
      <S.Container>
        <S.TitleWrapper>
          <PageTitle.Wrapper>
            <BackBar name="New transaction limit" />
            <S.TitleDescription>
              <S.CircleDiv>
                <Text size={8} color={colors.white._100}>
                  1
                </Text>
              </S.CircleDiv>
              <Text size={5}>Enter new limit/select your business type</Text>
              <S.Line />
              <S.CircleDiv
              //   backgroundColor={colors.white._100}
              //   borderColor={colors.gray._100}
              >
                <Text size={8} color={colors.white._100}>
                  2
                </Text>
              </S.CircleDiv>
              <Text size={5}>Upload indemnity and KYCB form </Text>
            </S.TitleDescription>
          </PageTitle.Wrapper>
        </S.TitleWrapper>

        <S.ContentContainer>
          <Text bold color="#2C2A3B">
            Upload indemnity and KYCB document
          </Text>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <InfoConponent
              style={{ background: '#F7F7FC', borderRadius: 5 }}
              infoIcon="info_with_bg"
              text={
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 10,
                    }}
                  >
                    <Text size={5} color={colors.amber._90} bold>
                      Notice:{' '}
                    </Text>
                    <Text size={5} color={colors.black._20}>
                      Don't have a template?
                    </Text>
                  </div>
                  <div>
                    <Text size={5} color={colors.black._20}>
                      Download the Indemnity Agreement PDF, and KYCB form,
                      review, sign, and re-upload the completed document to
                      proceed.
                    </Text>
                  </div>
                  <div>
                    <Button
                      theme="info"
                      style={{
                        color: colors.pink._5,
                        padding: '10px 10px 10px 0',
                        backgroundColor: 'transparent',
                        border: 'none',
                        marginLeft: -11,
                        fontFamily: fonts.inter.bold,
                      }}
                      leftIcon="download"
                      iconColor={colors.pink._5}
                      label="Download template"
                      onClick={downloadTemplate}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <S.uploadSection>
            <Table
              header={{
                cells: {
                  0: {
                    align: 'left',
                    children: (
                      <Text size={7} bold>
                        Document type
                      </Text>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                  1: {
                    align: 'left',
                    children: (
                      <Text size={7} bold>
                        Upload status
                      </Text>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                  2: {
                    align: 'left',
                    children: (
                      <Text size={7} bold>
                        Action
                      </Text>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                  3: {
                    align: 'left',
                    children: <Text size={7} bold></Text>,
                    padding: '1.25rem 1.5rem',
                  },
                },
                padding: '1.25rem 0',
              }}
              rows={uploads?.map((el, i) => ({
                padding: '1.25rem 0',
                border: {
                  color: colors.black._10,
                  width: 1,
                  style: 'solid',
                  sides: ['top'],
                },
                cells: {
                  0: {
                    align: 'left',
                    children: (
                      <Text size={6} color={colors.black._20}>
                        {el.type}
                      </Text>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                  1: {
                    align: 'left',
                    children: (
                      <Text size={6} color={colors.black._20}>
                        {el.status}
                      </Text>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                  2: {
                    align: 'left',
                    children: (
                      <>
                        <input
                          type="file"
                          ref={fileRef}
                          onChange={(e) => handleFileChange(e)}
                          style={{ display: 'none' }}
                        />
                        {el.file ? (
                          <Text size={6} color={colors.black._20}>
                            {el.file.name}
                          </Text>
                        ) : (
                          <Button
                            theme="info"
                            style={{
                              color: colors.pink._5,
                              padding: '10px 0px 10px 0',
                              backgroundColor: 'transparent',
                              border: 'none',
                              //   marginLeft: -11,
                            }}
                            righIcon="upload"
                            iconColor={colors.pink._5}
                            label={
                              uploading ? 'Please wait' : 'Upload document'
                            }
                            disabled={uploading}
                            onClick={() => handleFileClick(i)}
                          />
                        )}
                      </>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                  3: {
                    align: 'left',
                    children: (
                      <>
                        {el.file && (
                          <div style={{ cursor: 'pointer' }}>
                            <Icon
                              name="trash-basket"
                              color="#DC2626"
                              onClick={() => removeUpload(i)}
                            />
                          </div>
                        )}
                      </>
                    ),
                    padding: '1.25rem 1.5rem',
                  },
                },
              }))}
            />
          </S.uploadSection>
          <S.ButtonContainer>
            <Button label="Proceed" onClick={proceed} />
          </S.ButtonContainer>
        </S.ContentContainer>
        <TwoFactorAuthenticationModal
          show={showOtpModal}
          toggle={toggleOtpModal}
          callback={confirmRequest}
          loading={loading}
        />

        <SuccessModal
          title="Request Recieved!"
          description="We're reviewing your request for an increase in daily transaction limit. You'll receive an update via your registered business mail."
          onClose={toggleSuccess}
          show={showSuccess}
          buttonLabel="Got it"
          buttonAction={() => history.push('/transfers')}
        />
      </S.Container>
    </AccessControl>
  );
};

export default LimitUpload;
