
import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors, fonts } from '../../styles/variables';
import { CSSProperties } from 'react';

const Wrapper = styled.div<{ style?: CSSProperties }>`
    position: absolute;
    /* top:-30px; */
    top:-50%;
    bottom:0;
    left:30px;
    width: 250px;
    height: fit-content;
    border: 2px solid #394455;
    background-color: #394455;
    border-radius: 3px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    padding-left:25px;
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.10);
    gap: 15px;
    z-index: 9000;
    ${(props) => props.style?.top && css`
      top: ${props.style.top};
    `}

    ::before {
      content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    width: 0;
    height: 0;
    border: 10px solid transparent; /* Adjust the size of the arrow */
    border-bottom-color: #394455; /* Arrow color */
    transform: translateY(-50%) rotate(-90deg);
    z-index: 9000000;
    display:none;
    }
`;
const Content = styled.div`
  /* padding: 20px; */
  /* text-align: center; */
  font-size: 12px;
  font-style: normal;
  font-family: ${fonts.inter.regular};
/* font-weight: 700; */
  color:#ffffff;
  z-index: 10000;
  li {
    margin-bottom:5px;
  }
`;
const container = styled.div`
  position: relative;

`;

export {
  Wrapper,
  Content,
};