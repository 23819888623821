/**
 * No more 10 elements
 */
export const DATA = [
  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 80,
      rotate: 320,
      position: {
        left: '-10%',
      },
    },
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],

  [
    {
      radius: 60,
      rotate: 0,
      position: {
        right: '-5%',
        bottom: '-20%',
      },
    },
  ],
];
