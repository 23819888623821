import React from 'react';
// import { useDispatch } from 'react-redux';

// import { OnboardingActions } from '../../../redux/onboarding/actions';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Title } from '../../Onboarding/components/Title';
import { InfoSection } from '../../Onboarding/components/InfoSection';

import * as S from './styles';
import { RootState } from '../../../redux';

const CommonOperatorSwitcherOtherSwitcher: React.FC = () => {
  /**
   * Hooks initiation region
   */
  // const dispatch = useDispatch();

  const route = useHistory();

  const preference = useSelector((store: RootState) => store.preference);

  /**
   * Custom handlers initiation region
   */
  const Approver = () => {
    route.push('/onboarding/role');
  };

  const nonApprover = () => {
    route.push('/onboarding/non-approval-role');
  };

  return (
    <S.Container>
      <Title title="Select preferred transaction flow" />
      <S.Wrapper>
        <InfoSection
          title={preference.workflow?.initVerAuth?.replace(/-/g, ' ').replace(/role:/g, ' ')}
          onClick={Approver}
        />
        <InfoSection
          title={preference.workflow?.initToAuth?.replace(/-/g, ' ').replace(/role:/g, ' ')}
          onClick={nonApprover}
        />
      </S.Wrapper>
    </S.Container>
  );
};

export default CommonOperatorSwitcherOtherSwitcher;
