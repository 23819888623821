import React, { useState, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { OtpInput } from '../../../components/OtpInput';

import { colors } from '../../../styles/variables';
import * as S from '../common.otp/styles';
import { actions } from '../config';
import { success } from '../../../components/Toasts';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { Button } from '../../../components/Button';

const CommonPhoneOtp: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  // const phone = useSelector(
  //   (state: { onboardingStack: any }) =>
  //     state.onboardingStack.data.credentials.phone as string
  // );
  const maskedPhone = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.maskedPhone as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const storedOtp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.otp as string
  );

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean
  );

  /**
   * Local state initiation region
   */
  const [code, setCode] = useState<string>(storedOtp || '');
  const [loading, setLoading] = useState(false);
  const [reSend, setReSend] = useState(false);
  const [countDown, setCountDown] = useState<string>('01:00');

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!reSend) {
      const currentData = new Date();
      const countDownDate = new Date(
        currentData.getTime() + 1 * 60000
      ).getTime();
      timer.current = setInterval(() => {
        setCountDown(() => {
          const now = new Date().getTime();
          const diff = countDownDate - now;
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          if (minutes <= 0 && seconds <= 0) {
            setReSend(true);
            // if (timer.current) clearInterval(timer.current);
            return '0:0';
          }
          return `${minutes}:${seconds}`;
        });
      }, 1000);
    }

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [reSend]);

  /**
   * Custom handlers initiation region
   */

  const resendOtp = async () => {
    try {
      if (countDown !== '0:0') return;
      setLoading(true);
      const response = await onboardingService.resendOtp({
        type: 'phone',
        reference,
      });
      setReSend(false);
      success('Done!', 'Another OTP has been sent');
    } catch (err) {
      dispatch(
        OnboardingActions.handleSetError(
          err.message || 'Can not complete request at the moment'
        )
      );
    }
    setLoading(false);
  };

  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.validatePhoneOtp({
          otp: code,
          reference,
        });
        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              reference: response.data.reference,
              maskedEmail: response.data.maskedEmail,
            })
          );
        });

        route.push(actions[response.actionRequired]);

        debug.info('', response); // TEMPORARY
      } catch (err) {
        if (err.code === 'INVALID_ACTION_REQUIRED') {
          route.push(
            actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
          );
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || 'Can not complete request at the moment'
            )
          );
        }
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    if (code.length > 5) {
      continueHandle();
    }
  }, [code]);

  return (
    <S.Container>
      <Title color={colors.black._20} title='Phone number OTP verification' />
      <S.Wrapper>
        <Text color='#000000'>
          An OTP has been sent to your phone number
          {` ${maskedPhone || ''}.`} Input token to verify your identity
        </Text>
        <OnboardingCard>
          <S.OtpWrapper>
            <Text bold color={colors.black._20} size={4}>
              Enter OTP sent to your phone number
            </Text>

            <OtpInput
              secure
              codeLength={6}
              value={code}
              loading={loading}
              onCodeChange={setCode}
            />
          </S.OtpWrapper>
          <S.TextContainer>
            <Text style={{ whiteSpace: 'nowrap' }} color='#000000' size={5}>
              Didn’t get the code?
            </Text>

            <Text
              style={{ whiteSpace: 'nowrap' }}
              bold
              color='#000000'
              size={5}
            >
              <strong
                onClick={resendOtp}
                style={{
                  fontWeight: 700,
                  color: colors.pink._100,
                  opacity: countDown !== '0:0' ? 0.3 : 1,
                  cursor: countDown === '0:0' ? 'pointer' : 'unset',
                }}
              >
                Resend{' '}
              </strong>
              in{' '}
            </Text>

            <Text
              style={{ whiteSpace: 'nowrap' }}
              bold
              color='#B10180'
              size={5}
            >
              {/* <span
                style={{
                  opacity: reSend ? 1 : 0.3,
                  cursor: !reSend ? 'default' : 'pointer',
                }}
                onClick={resendOtp}
                aria-hidden='true'
              >
                Resend code
              </span> */}
              {countDown}
            </Text>
          </S.TextContainer>

          <S.ButtonGroup>
            <div>
              <Button
                style={{ width: 304, padding: 20 }}
                label='Continue'
                loading={loading}
              />
            </div>
          </S.ButtonGroup>
        </OnboardingCard>
      </S.Wrapper>
    </S.Container>
  );
};

export default CommonPhoneOtp;
