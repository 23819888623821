import styled, { css } from 'styled-components';

import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';

const Container = styled.aside`
  display: flex;
`;

const Image = styled.div<{ image: string }>`
  min-width: ${ptr(120)};
  height: ${ptr(148)};
  background-color: ${colors.black._40};
  border-radius: ${ptr(6)};
  margin-right: ${ptr(24)};

  ${(props) => props.image && css`
    background-image: url(${props.image});
    background-size: cover;
    background-repeat: no-repeat;
  `}
`;

const Data = styled.aside`
  & > :not(:first-child) {
    margin-top: ${ptr(16)};
  }
`;

const Row = styled.aside`
  display: flex;
  justify-content: space-between;
`;

const DataBlock = styled.aside`
  max-width: 60%;
  text-align: right;
`;

export {
  Container,
  Image,
  Data,
  Row,
  DataBlock,
};
