import styled, { css } from 'styled-components';

import { TableHeaderProps } from '.';
import { TableCellProps } from '../TableCell';
import { colors, fonts, transition } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const TableRowWrapper = styled.tr<Pick<TableHeaderProps, 'height' | 'backgroundColor'>>`
  width: 100%;
  ${(props) => props.height && `height: ${ptr(props.height)};`}
  font-family: ${fonts.inter.bold};
  
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor}
 
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-right: ${ptr(4)} solid transparent;
  border-left: ${ptr(4)} solid transparent;
  border-bottom: ${ptr(5)} solid ${colors.black._40};
  margin: ${ptr(4)} 0;
  transition: ${transition.slow}
`;

const FigureGroup = styled.div<{ order?: 'ASC' | 'DESC' | 'default' }>`
  margin-left: ${ptr(8)};

  ${(props) => props.order === 'ASC' && css`
    > :first-child {
      border-bottom-color: ${colors.black._60};
    }
  `}

  ${(props) => props.order === 'DESC' && css`
    > :last-child {
      border-bottom-color: ${colors.black._60};
    }
  `}

  > :last-child {
    transform: rotate(180deg);
  }
`;

const TableCellWrapper = styled.div<Pick<TableCellProps, 'align' | 'onClick'>>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    switch (props.align) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  }};
`;

const SortArrowsIcon = styled.span`
  margin-left: ${ptr(8)}
`;

export {
  TableRowWrapper,
  TableCellWrapper,
  SortArrowsIcon,
  Triangle,
  FigureGroup,
};
