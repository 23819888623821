import React, { CSSProperties } from 'react';

import { ModalWrapper } from '../../../../components/ModalWrapper';

import * as S from './styles';

export interface ImagePreviewModalProps {
  image: string;
  style?: CSSProperties;
  onClose?: () => void;
  visible?: boolean;
}

export const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  style,
  image,
  visible,
  onClose,
}) => {
  const [open, setOpen] = React.useState(false);
  const toggle = () => {
    setOpen((prev) => !prev);
  };
  return (
    <ModalWrapper
      showModal={visible !== undefined ? visible : open}
      onCloseModal={onClose || toggle}
    >
      <S.Container>
        <S.Image style={style} src={image} />
      </S.Container>
    </ModalWrapper>
  );
}; 
