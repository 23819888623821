import styled, { css } from 'styled-components';
import { ptr, hexToRgbA } from '../../styles/utils';
import { breakpoints, colors, transition } from '../../styles/variables';
import { crossBrowserTransition } from '../../styles/utils';
// import { hexToRgbA, ptr } from '../../../../styles/utils';

const Container = styled.div`
 margin-right:auto;margin-left:auto;
  @media (min-width:320px){max-width:300px;}
  @media (min-width:360px){max-width:340px;}
  @media (min-width:576px){max-width:540px;}
  @media (min-width:768px){max-width:720px;}
  @media (min-width:992px){max-width: 621px;}
  @media (min-width:1200px){max-width:1140px;} 
`;
const Tab = styled.div`
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
const YearStyle = styled.div`
 position: absolute;
width: 150px;
background: white;
margin-top: 10px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const PYear = styled.p`
margin:12px 0;
padding:8px 20px;

.active{
  background:${colors.black._10}
}

`;

const InnerContainer = styled.div` 
 width: ${ptr(640)};
@media (max-width: ${ptr(740)}) {
  width: auto;
}
`;
// Style for tableHeader
const TableConatiner = styled.div`
display:flex;
flex-wrap: wrap;
justify-content :space-between;
align-items: center;
margin-bottom:20px;
`;
const TableIIConatiner = styled.div`
display:flex;
justify-content :space-between;
align-items: center;
width: 65%;

`;
const TableMarginBottom = styled.div`
margin-bottom:40px;
`;
const MarginLeftComp = styled.div`
:hover {
    cursor: pointer;
    opacity: 0.9;
  };
margin-left:  ${ptr(30)};`;

const TableAlign = styled.div`
display:flex;
align-items: center;

`;
// End of Style

// Transaction Details Style

// Transaction Modal
const TableStyle = styled.table`
width:100%;
border-collapse: separate;
  /* border-spacing: 15px 30px; */
  border-spacing: 20px;
 
  padding: 0 1rem;

td:nth-child(1){
text-align: left;
}
td:nth-child(2){
text-align: right;
}


`;
//
const TableHeaderStyle = styled.div`
text-align: center;
background:${colors.pink._20};
padding: ${ptr(50)}  ${ptr(0)};
  position: sticky;
  top:40px;
  background-attachment: fixed;
  z-index:99999;
`;
const ModalWrapper = styled.div`
padding: ${ptr(30)};
 `;
const CheckboxWrapper = styled.div`
    padding: 10px;
    border: 1px solid  ${colors.black._60};
    border-radius: 20px;
    margin-bottom: 10px;
`;
const YearBadge = styled.button`
  border: ${ptr(1)} solid ${colors.black._40};
  box-sizing: border-box;
  border-radius: ${ptr(50)};
  display: flex;
  align-items: center;
  padding: ${ptr(8)} ${ptr(16)};
  background: transparent;

  > span {
    line-height: ${ptr(14)};
  }

  > svg {
    margin-left: ${ptr(6)};
    transform: rotate(-90deg);
  }
`;
const ButtonWrapper1 = styled.div`
margin-left: auto;
margin-right: auto;
width: 100%;
margin-top:30px;
margin-bottom:30px;
align-items:center;

display: flex;
button{
  width:100%;
}

`;
const FullButtonWrapper = styled.div`
margin-left: ${ptr(30)};
margin-right: ${ptr(50)};
margin-bottom: ${ptr(50)};
width: 90%;
margin-top:30px;
align-items:center;

display: flex;
button{
  width:100%;
}
`;
const TextInputContainer = styled.div`
display:flex;
/* margin-bottom: 10px; */
`;
const TextInputWrapper = styled.div`
flex:1;
margin-bottom: 10px;
`;
const SpaceWrapper = styled.div`
margin: 0 5px;
`;
const CheckboxContainer = styled.div`
display: flex;
flex-wrap:wrap;
margin-bottom: 10px;
`;

const TableNedded = styled.div`
width:100%;
table, th,tr:nth-child(1)    {
  /* border-bottom: 1px solid black; */
  border-collapse: collapse;
  width:100%
}
tr:nth-child(2){
border-top:1px solid;
}
th, td {
  padding: 15px 5px;
  text-align: left;    
}
`;
// no nedded
const TopWrapper = styled.div`
padding: 0px;
`;
const TopContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
flex-wrap: wrap;
`;
const Flex = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
`;
const MarginBottom = styled.div`
margin-bottom: 20px;
`;

const Header = styled.div`
position: sticky;
top:40px;
z-index:999999;
background-color: #ffffff;
`;

const TextsContainer = styled.div`
display: flex;
flex-direction: column;

margin-right:25px;
`;

const BtnSuccess = styled.div`
background: #F3FDF3;
color: #36B83B;
padding: 10px 15px;
border-radius: 10px;
`;

const BtnWarning = styled.div`
background: #FFF4D8;
color: #F3A100;
padding: 10px 15px;
border-radius: 10px;
`;

const BtnDanger = styled.div`
background: ${colors.pink._40};
color: #FFFFFF;
text-align: center;
padding: 10px 15px;
border-radius: 10px;
`;

const BtnUnknwn = styled.div`
background: ${colors.amber._100};
color: #FFFFFF;
text-align: center;
padding: 10px 15px;
border-radius: 10px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  flex: 1;
  justify-content: flex-end;
  padding: 0;

  > svg {
    margin-left: ${ptr(14)};
  }
`;

const TabNav = styled.div`
  flex:2;
  display: flex;
  overflow: scroll;

  > a {
    text-decoration: none;
  }

  > :not(:last-child) {
    margin-right: ${ptr(48)};
  }
`;

// const Wrapper = styled.div`
//   display: flex;
// `;

const LeftContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: ${ptr(32)};
`;

const RigthContainer = styled.div`
  flex: 1;
  margin-left: ${ptr(32)};
  display: flex;
  flex-direction: column;
  gap: ${ptr(32)};
`;
const Wrapper = styled.div`
  padding: ${ptr(64)};
  width: 60vw;
  min-height: calc(100vh - ${ptr(270)});
`;
const TopBarWrapper = styled.div`
  display: flex;
  padding: ${ptr(24)} 0;
  width: 72%;

`;

const ButtonWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white._100};
  padding: ${ptr(24)} ${ptr(64)};
  display: flex;
  border-top-width: 1px;
  border-color: ${colors.black._10};
  border-style: solid;
  justify-content: flex-end;
  position: sticky;
`;

const ButtonsGroup = styled.div`
  display: flex;
  min-width: ${ptr(240)};

  & > :not(:last-child) {
    margin-right: ${ptr(16)};
  }
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${ptr(16)};
  border-radius: ${ptr(8)};
  width: 100%;
  border: 1px solid ${colors.black._10};
  margin-right: ${ptr(32)};
`;

const Block = styled.div`
  position: relative;
`;

const ButtonItem = styled.button`
  width: ${ptr(48)};
  height: ${ptr(48)};
  background: ${colors.black._5};
  border-radius: ${ptr(6)};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: ${transition.slow};

  &[disabled] {
    opacity: 0.6;
  }
`;

const TextField = styled.input`
  width: inherit;
  outline: none;
  border: none;
  color: ${colors.black._100};
  transition: .3s;
  margin-left: ${ptr(-40)};
  margin-right: ${ptr(-16)};
  padding: ${ptr(16)} ${ptr(16)} ${ptr(12)} ${ptr(50)};
  background: transparent;

  &::placeholder {
    color: ${colors.black._40}
  }
`;

const Dropdown = styled.div`
  background: ${colors.white._100};
  position: absolute;
  top: ${ptr(60)};
  box-sizing: border-box;
  box-shadow: 0px ${ptr(4)} ${ptr(26)} ${hexToRgbA(colors.black._100, 20)};
  width: ${ptr(258)};
  border-radius: ${ptr(8)};
  right: ${ptr(-138)};
  z-index: 1;
`;

const CloseDropdown = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top : ${ptr(24)};
  right: ${ptr(24)};
  position: absolute;
`;

const DropdownContent = styled.div`
  padding: ${ptr(24)};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-height: ${ptr(476)};
  overflow: scroll;
  z-index: 1,
`;

const DropdownBottom = styled.div`
  padding: ${ptr(16)};
  border-top: ${ptr(1)} solid ${colors.black._5};
  display: flex;
  justify-content: space-between;

  > button {
    padding: ${ptr(12)};
    flex: 1;

    &:last-child {
      flex: 1.4;
      margin-left: ${ptr(12)};
    }
  }
`;

const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: ${ptr(24)};
  }

  > span {
    margin-bottom: ${ptr(12)};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  > div {
    margin: ${ptr(12)} 0;
  }
`;

const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ptr(94)} 0;
`;

const PreloadContainer2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${ptr(94)} 0;
`;

const TableContainer = styled.div<{ loading: boolean }>`
  ${(props) => props.loading && css`
    opacity: 0.8;
  `}
`;

const Column = styled.div`
  > * {
    display: block;
    text-transform: capitalize; 
  }
`;

const WrapperContainer = styled.div`
padding: 20px 10px;
margin: 20px;
border:1px solid
`;

const WrapperContainer2 = styled.div`
padding: 40px 50px;
margin: 40px 0;
border:1px solid
`;

const CloseButton = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
margin-right: 10px;
`;

const GroupWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const ImageWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 20px;

img{
  width: 70%;
}
`;

const TextWrapper = styled.div`
margin-bottom: 5px;
`;
const TextWrapper1 = styled.div`
margin-bottom: 15px;
`;

const MarginButtom = styled.div`
margin-bottom: ${ptr(30)};
`;
const MarginRight = styled.div`
margin-right: ${ptr(40)};
`;

const FlexStyle = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

const IconMargin = styled.span`
margin-right: ${ptr(10)};
`;

const TypeContainer = styled.div`
`;

const Tab1 = styled.div`
padding:1px;
 > span{
border-radius: 6px;
padding:7px 11.5px; 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }

:hover {
    cursor: pointer;
    opacity: 0.9;
  }


 > span{
border-radius: 6px;
padding:7px 38px;
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }

:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  @media screen and (max-width: ${breakpoints.sm}px) { 
   width:100% 
  }
`;

const TableAlign2 = styled.div`
display:flex;
align-items:center;
margin-top:15px;
border-radius:8px;
 height:43px;
width:fit-content;
border: 1px solid #EBEBEB;

@media screen and (max-width: ${breakpoints.sm}px) {  
  padding:10px 0px;
  display: flex;
  flex-direction:column;
  height:fit-content;
  gap:20px
}
`;

const TypeTabs = styled.div`
margin-bottom:41.5px;
`;

const BackgroundWrapper = styled.div`
// border:1px solid ${colors.black._10};
border: 0.0625rem solid #E9EBF1;
border-radius: 0.5rem;
padding:${ptr(20)};
margin-bottom: ${ptr(30)};
/* background-color:  ${colors.black._10}; */
.recharts-default-legend,.recharts-cartesian-grid-vertical{
  display:none;
}
`;
// Charts
const ChartPosition = styled.div`
width:100%;
height:40vh;
margin-top: ${ptr(40)};

`;
const Chart2Position = styled.div`
width:100%;
height:30vh;
margin-top: ${ptr(40)};

`;

//
const Background2Wrapper = styled.div`
border:1px solid ${colors.black._10};
border-radius: 0.5rem;
padding:${ptr(20)};
margin-bottom: ${ptr(30)};
margin-right: ${ptr(20)};
width:100%;
flex:2;
@media (min-width: 768px)
 {
    flex: 0 0 auto;
    width: 40%;
}
/* background-color:  ${colors.black._10}; */
.recharts-default-legend,.recharts-cartesian-grid-vertical{
  display:none;
}
`;
const Flex2Style = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 20px;
flex-shrink: 0;
    width: 100%;
`;

//
const Background3Wrapper = styled.div`
border:1px solid ${colors.black._10};
padding:${ptr(20)};
border-radius: 0.5rem;
margin-bottom: ${ptr(30)};
margin-right: ${ptr(20)};
width:100%;
// flex:1;
/* max-width: 332px; */


@media (min-width: 768px)
 {
    flex: 0 0 auto ;
    width: 40% !important;
}

/* background-color:  ${colors.black._10}; */
.recharts-default-legend,.recharts-cartesian-grid-vertical{
  display:none;
}
`;

const MarginLeftComp1 = styled.div`
padding:1px;
margin-left:  ${ptr(4)};

 > span{
border-radius: 6px;
padding:7px 38px;
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }

:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media screen and (max-width: ${breakpoints.sm}px) { 
    width:100% 
    margin-left:0px;

    > span{ 
      padding:7px 68px;
      width:100%; 
        }
   }
`;

const TextContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom:  ${ptr(10)};
`;

const TopTitle = styled.div` 
display:flex;
justify-content: space-between;
align-items:center;
width:80%;
margin-bottom:  ${ptr(74)};
}
`;

export {
  Container, Tab, Tab1, TabNav, Head, Button,
  LeftContainer, RigthContainer,
  TableContainer,
  PreloadContainer,
  PreloadContainer2,
  Wrapper,
  TopBarWrapper,
  ButtonWrapper,
  ButtonsGroup,
  InputBox,
  ButtonItem,
  TextField,
  Dropdown,
  CloseDropdown,
  DropdownBottom,
  DropdownContent,
  FilterBox,
  Content,
  Block,
  Column,
  TopWrapper,
  MarginLeftComp1,
  TypeTabs,
  TableAlign2,
  TypeContainer,
  TopContainer,
  Flex,
  MarginBottom,
  TextsContainer,
  BtnSuccess,
  BtnWarning,
  BtnDanger,
  WrapperContainer,
  CloseButton,
  GroupWrapper,
  ImageWrapper,
  TextWrapper,
  WrapperContainer2,
  TextWrapper1,
  CheckboxWrapper,
  ButtonWrapper1,
  ModalWrapper,
  TextInputContainer,
  TextInputWrapper,
  SpaceWrapper,
  CheckboxContainer,
  TableNedded,
  YearBadge,
  TableConatiner,
  TableMarginBottom,
  TableAlign,
  TableIIConatiner,
  MarginLeftComp,
  TableStyle,
  TableHeaderStyle,
  InnerContainer,
  TopTitle,
  YearStyle,
  PYear,
  FullButtonWrapper,
  MarginButtom,
  MarginRight,
  FlexStyle,
  BackgroundWrapper,
  ChartPosition,
  Background2Wrapper,
  Flex2Style,
  Background3Wrapper,
  IconMargin,
  Chart2Position,
  TextContainer,
  Header,
  BtnUnknwn,
};
