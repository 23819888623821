import React from 'react';
import styled from 'styled-components';

import { TableCellProps } from '.';
import { ptr, borderParams } from '../../styles/utils';

const TableCell = styled(({ tag = 'td', children, ...props }: TableCellProps) => React.createElement(tag, props, children))`
  ${(props) => props.width && `width: ${ptr(props.width)};`}
  vertical-align: middle;
  text-align: ${(props) => props.align || 'center'};
  text-align: -webkit-${(props) => props.align || 'center'};

  ${(props) => props.padding && `padding: ${typeof props.padding === 'number' ? ptr(props.padding) : props.padding};`}

  ${(props) => props.border && borderParams(props.border)}

  :hover {
    ${(props) => props.onClick && 'cursor: pointer;'}
  }
`;

export {
  TableCell,
};
