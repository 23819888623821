/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { useHistory } from "react-router-dom";
import { OnboardingInterface } from '../../../../../redux/onboarding/IOnboarding';

import { SignUpTrusteeInfoInterface } from '../../../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../../../components/Button';
import { Title } from '../../../../Onboarding/components/Title';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
  ValidateResult,
  validateObject,
} from '../../../../../utils/validation';

import { colors } from '../../../../../styles/variables';
import * as S from '../../styles';
import { InfoForm2 } from '../../../../Onboarding/components/InfoForm2';
import { OnboardingActions } from '../../../../../redux/onboarding/actions';
import { actions } from '../../../config';
import onboardingService from '../../../../../services/internal/onboarding/onboarding.service';
import { idTypes } from '../../../constants';
import { InfoConponent } from '../../../../../components/Info';
import { OnboardingCard } from '../../../../../components/OnboardingCard';
import { RootState } from '../../../../../redux';

const SignUpProprietorsDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.businessType as string
  );

  const business = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.business as Record<string, string>
  );
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<SignUpTrusteeInfoInterface>
  );

  const preference = useSelector((store: RootState) => store.preference);

  const SCHEME = {
    phone: (value: string) => value.length === 11,
    ...(!preference.bvnConsentEnabled
      ? { bvn: (value: string) => value.length === 11 }
      : {}),
    idType: (value: string) => value.length > 1,
    email: (value: string) => validator.isEmail(value),
  };

  const directorDefaultItem = {
    phone: '',
    ...(preference.bvnConsentEnabled ? { bvn: '' } : {}),
    email: '',
    idNumber: '',
    idType: '',
  };

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<
    Array<SignUpTrusteeInfoInterface>
  >(() =>
    leaders?.length
      ? leaders.map((item) => ({
          phone: item.phone,
          ...(!preference.bvnConsentEnabled ? { bvn: item.bvn } : {}),
          email: item.email,
          idNumber: item.idNumber,
          idType: item.idType,
        }))
      : [directorDefaultItem]
  );
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);

  const [idNumber, setIdNumber] = useState<string>(
    () => leaders[0]?.idNumber ?? ''
  );
  const [idType, setIdType] = useState<any>(() => leaders[0]?.idType ?? '');
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();

  /**
   * Custom handlers initiation region
   */

  useEffect(() => {
    setCollection(() =>
      leaders?.length
        ? leaders.map((item) => ({
            phone: item.phone,
            ...(!preference.bvnConsentEnabled ? { bvn: item.bvn } : {}),
            email: item.email,
            idNumber: item.idNumber,
            idType: item.idType,
          }))
        : [directorDefaultItem]
    );
  }, [leaders]);
  const push = () => {
    setCollection([...collection, directorDefaultItem]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: SignUpTrusteeInfoInterface, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }
          return el;
        }) as Array<SignUpTrusteeInfoInterface>
    );
  };

  const getIdType = (value: any) => {
    setIdType(value);
  };

  const getIdNumber = (value: any) => {
    setIdNumber(value);
  };

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
    setIdNumberValidation(
      validateObject(
        {
          idType,
          idNumber,
        },
        idNumberScheme
      )
    );
  }, [collection, idType, idNumber]);

  const continueHandle = async () => {
    setLoading(true);
    try {
      const response = await onboardingService.submitSignUpProprietorsDetails({
        proprietors: collection,
        reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            directors: [...collection],
            reference: response.data.reference,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  return (
    <S.Container>
      <S.TopTitle>
        <Title
          color={colors.black._20}
          title='Management Details'
          subTitle='Kindly provide proprietor’s details'
        />
        {/* <Text color={colors.black._60}>
          Kindly provide proprietor’s details
        </Text> */}
      </S.TopTitle>

      <S.InfoContainer>
        <InfoConponent text='Note that the consent of person provided here will be required for the account to become operational on VBiz.' />
      </S.InfoContainer>

      <OnboardingCard>
        {collection.map((item, index) => (
          <div style={{ marginBottom: '30px' }}>
            <InfoForm2
              // title={`Proprietor's details`}
              phone={item.phone}
              bvn={!preference.bvnConsentEnabled ? item.bvn : ''}
              idNumber={item.idNumber}
              idType={item.idType}
              email={item.email}
              index={index}
              onSelectId={getIdType}
              onChangeId={getIdNumber}
              removeFromIndex={0}
              remove={remove}
              onChange={onChangeValue}
              emailIsValid={
                validationCollection?.data[index]?.data?.email?.isValid
              }
              bvnIsValid={
                preference.bvnConsentEnabled
                  ? true
                  : validationCollection?.data[index]?.data?.bvn?.isValid
              }
              phoneIsValid={
                validationCollection?.data[index]?.data?.phone?.isValid
              }
            />
          </div>
        ))}

        {/* <S.Add onClick={push}>
        <Icon name="plus-in-circle" color={colors.pink._100} />
        <Text color={colors.pink._100}>Add Proprietor</Text>
      </S.Add> */}

        <S.ButtonGroup>
          <div>
            <Button
              label='Continue'
              loading={loading}
              disabled={!validationCollection?.isValid || loading}
              onClick={continueHandle}
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
export default SignUpProprietorsDetails;
