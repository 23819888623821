import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormTitle } from '../../components/FormTitle';
import { OneCenter, FormButton, ContentContainer } from '../../styles/wrappers';
import { Button } from '../../components/Button';
import { Logo } from '../../components/Logo';

export interface InfoProps {
  /**
   * Info title
   */
  title?: string,
  /**
   * Info description
   */
  description?: string,
}

const Info: React.FC<InfoProps> = ({ title = '', description = '' }) => {
  const history = useHistory();

  return (
    <OneCenter.Wrapper>
      <ContentContainer justifyContent="flex-start">
        <Logo marginLeft={80} marginTop={32} />
      </ContentContainer>
      <ContentContainer flex={1} justifyContent="center" alignItems="center">
        <OneCenter.ContentWrapper>
          <FormTitle
            title={title}
            description={description}
          />
          <FormButton.WrapperEnd>
            <Button
              label="Got it"
              onClick={() => {
                history.push('/login');
              }}
            />
          </FormButton.WrapperEnd>
        </OneCenter.ContentWrapper>
      </ContentContainer>
    </OneCenter.Wrapper>
  );
};

export default Info;
