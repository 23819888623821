import { IBeneficiaries } from '../../pages/BeneficiariesManagement/view';
import { ActionInterface } from '../IAction';

export const MAKE_TRANSFER = 'MAKE_TRANSFER';
export const REMOVE_SAVED = 'REMOVE_SAVED';
export const CLEAR = 'CLEAR';
export default class BeneficaryActions {
  public static makeATransfer(payload: Array<IBeneficiaries>):
  ActionInterface<Array<IBeneficiaries>> {
    return { type: MAKE_TRANSFER, payload };
  }

  public static removeSavedBeneficiary(payload: string |undefined):
  ActionInterface<string> {
    return { type: REMOVE_SAVED, payload };
  }

  public static clearBeneficiary(): ActionInterface<unknown> {
    return { type: CLEAR };
  }
}
