import styled from 'styled-components';

import { ptr } from '../../../../../styles/utils';

const Container = styled.div`
  span {
    display: block;
  }
`;

const PasswordDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${ptr(4)} 0 ${ptr(24)} 0;

  > * {
    margin-bottom: ${ptr(8)};
  }
`;

const InfoContainer = styled.div`
  width: 85%;
  margin: 30px 0;
`;

const PasswordDescriptionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: ${ptr(8)};
  }
`;

const ButtonWrapper = styled.div`
  > * {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  border-top: 4px solid #dbdfe4;
  padding-top: 14px;
  margin-top: 20px;
  > div {
    height: 50px;
    display: flex;
    width: 100%;
    justify-content: right;
  }

  > button {
    display: flex;
    width: 80%;
  }
`;

export {
  Container,
  ButtonGroup,
  ButtonWrapper,
  InfoContainer,
  PasswordDescriptionWrapper,
  PasswordDescriptionItemWrapper,
};
