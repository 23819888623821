import { ActionInterface } from '../IAction';

export const OPEN = 'MAIN_MENU/OPEN';
export const CLOSE = 'MAIN_MENU/CLOSE';
export const TOGGLE = 'MAIN_MENU/TOGGLE';

const openMainMenu = (): ActionInterface<unknown> => ({
  type: OPEN,
});

const closeMainMenu = (): ActionInterface<unknown> => ({
  type: CLOSE,
});

const toggleMainMenu = (): ActionInterface<unknown> => ({
  type: TOGGLE,
});

export class MainMenuActions {
  public static open(): ActionInterface<unknown> {
    return openMainMenu();
  }

  public static close(): ActionInterface<unknown> {
    return closeMainMenu();
  }

  public static toggle(): ActionInterface<unknown> {
    return toggleMainMenu();
  }
}
