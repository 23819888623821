import React, { useState } from 'react';
import { BackBar } from '../../../components/BackBar';
import * as S from './styles';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import { OtpInput } from '../../../components/OtpInput';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/SimpleModal';

const ChangeTransactionPin:React.FC = () => {
  const [errors, setErrors] = React.useState({ currentPin: '', newPin: '', confirmPin: '' });
  const [data, setData] = React.useState<{ currentPin:string, newPin:string, confirmPin:string }>({ currentPin: '', newPin: '', confirmPin: '' });
  const [showModal, setShowModal] = useState(true);

  const onChange = (value:string, name?:string) => {
    if (name) setData({ ...data, [name]: value });
    // if (name && value.length < 4)setErrors({ ...errors, [name]: `This is a ${name} error` });
    if (name && value.length === 4)setErrors({ ...errors, [name]: '' });
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <S.OTPContainer>
      <BackBar name="Change Transaction PIN" />

      <S.Body>
        <S.InputContainer>
          <Text color={colors.black._100} bold>Enter current PIN</Text>
          <S.OtpWrapper>
            <OtpInput errorMessage={errors.currentPin} onCodeChange={(value) => onChange(value, 'currentPin')} secure codeLength={4} />

          </S.OtpWrapper>
        </S.InputContainer>

        <S.InputContainer>
          <Text color={colors.black._100} bold>Enter new PIN</Text>
          <S.OtpWrapper>
            <OtpInput errorMessage={errors.newPin} onCodeChange={(value) => onChange(value, 'newPin')} secure codeLength={4} />

          </S.OtpWrapper>
        </S.InputContainer>

        <S.InputContainer>
          <Text color={colors.black._100} bold>Repeat new PIN</Text>
          <S.OtpWrapper>
            <OtpInput errorMessage={errors.confirmPin} onCodeChange={(value) => onChange(value, 'confirmPin')} secure codeLength={4} />

          </S.OtpWrapper>
        </S.InputContainer>

        <Button label="Change PIN" disabled={!(data.confirmPin && data.currentPin && data.newPin)} />

      </S.Body>

      <Modal visible={showModal} onCloseModal={closeModal} />
    </S.OTPContainer>
  );
};

export default ChangeTransactionPin;
