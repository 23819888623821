import styled, { css } from "styled-components";

import { WelcomeSliderProps } from "./index";
import { ptr } from "../../styles/utils";
import { breakpoints, fonts } from '../../styles/variables';

const WelcomeWrapper = styled.div<WelcomeSliderProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${ptr(5)} ${ptr(140)};
  box-sizing: border-box;
  text-align: center;
  @media (max-width: ${breakpoints.sm}px) {
  padding: ${ptr(5)} ${ptr(10)};
  margin-bottom: ${ptr(50)}

  }
`;

const WelcomeTitle = styled.h2`
font-family: ${fonts.inter.bold};
  color: white;
  font-size: ${ptr(48)};

`;
const ImgContainer = styled.div`
margin-top:${ptr(50)};
margin-bottom:${ptr(50)};
width:100%;
`

const Img = styled.img`
  width: ${ptr(300)};
  height: ${ptr(300)};
`;

const BgContainer = styled.div`

height:100%;
width:100%;
position: relative;
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;

::before {
  background-image:url('../../images/onboarding/spiral.svg');
background-size:cover;
background-repeat:no-repeat;
content: "";
position: absolute;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
opacity: 0.1;
}
`
const SlideWrapper = styled.div`
display: flex!important;
justify-content: center!important;
flex-direction:column;
align-items: center;

`

const SpiralImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ImgSlideOneContainer = styled.div`
  position: relative;
`
const ImgSlideSixContainer = styled.div`
  position: relative;
`

const SlideOneItemOne = styled.div`
position:absolute;
height: 40px;
/* width:150px; */
display: flex;
align-items: center;
padding-left: 10px;
padding-right: 10px;
background: #DFFFF6;
border: 1px solid #DBDFE4;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
border-radius: 48px;
font-weight: 600;
font-size: 16px;
gap:10px;
font-family: ${fonts.inter.regular};
top: 55px;
right: 70%;
@media (max-width: ${breakpoints.sm}px) {
  top: 55px;
right: 65%;

  }
`

const SlideOneItemTwo = styled.div`
position:absolute;
height: 40px;
width:180px;
display: flex;
align-items: center;
padding-left: 10px;
padding-right: 10px;
background: #FFDFED;
border: 1px solid #DBDFE4;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
border-radius: 48px;
font-weight: 600;
font-size: 16px;
gap:10px;
flex-wrap: nowrap;
font-family: ${fonts.inter.regular};
top: 185px;
right: 57%;
@media (max-width: ${breakpoints.sm}px) {

 top: 185px;
right: 50%;
  }
`

const SlideOneItemThree = styled.div`
position:absolute;
height: 40px;
width:190px;
display: flex;
align-items: center;
padding-left: 10px;
padding-right: 10px;
background: #EEF7FF;
border: 1px solid #DBDFE4;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
border-radius: 48px;
font-weight: 600;
font-size: 16px;
gap:10px;
font-family: ${fonts.inter.regular};
top: 40px;
right: 0;
left: 77%;
@media (max-width: ${breakpoints.sm}px) {
  left: 57%;
  width:170px;
  }
`

const SlideOneItemFour = styled.div`
position:absolute;
height: 40px;
width:90px;
display: flex;
align-items: center;
padding-left: 10px;
padding-right: 10px;
background: #FFF4DF;
border: 1px solid #DBDFE4;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
border-radius: 48px;
font-weight: 600;
font-size: 16px;
gap:10px;
font-family: ${fonts.inter.regular};
top: 210px;
right: 0;
left: 75%;
@media (max-width: ${breakpoints.sm}px) {
  right: 0;
left: 65%;

  }
`

const slideFourItems = styled.div`
position: absolute;
display: flex;
flex-direction: column;
gap:10px;
top:120px;
left : 45%;
@media (max-width: ${breakpoints.sm}px) {

left: 25%;

  }
`

const slideFourItem = styled.div<{ type: 'one' | 'two' }>`
width: 240px;
height: 40px;
${(props) => props.type === 'one' && css`
background: #FFF4DF;
`}

${(props) => props.type === 'two' && css`
background: #D2EAFF;
`}

border: 1px solid #EAECF0;
box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
border-radius: 6px;
padding: 15px;
display: flex;
flex-direction: row;
justify-content: space-between;
`

const Div = styled.div`

`


export { WelcomeWrapper, WelcomeContainer, WelcomeTitle, ImgContainer, Img, BgContainer, SpiralImg, SlideWrapper, Div, ImgSlideOneContainer, SlideOneItemOne, SlideOneItemTwo, SlideOneItemThree, SlideOneItemFour, slideFourItems, slideFourItem, ImgSlideSixContainer };
