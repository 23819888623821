import { useHistory } from 'react-router-dom';
import { MouseEvent, useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Icon, IconName } from '../../components/Icon';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';
import * as S from './styles';
import { QuickLinks } from '../Dashboard/components/QuickLinks';
import SearchInput from '../../components/SearchInput';
import {
  ICategory,
  MenuItem,
  QuickLinkInterface,
} from '../../redux/quicklinks/IQuickLinks';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveLinks } from '../../redux/quicklinks/actions';
import { success, warning } from '../../components/Toasts';
import {
  DragDropContext,
  DropResult,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { DraggableQuickLinks } from '../Dashboard/components/DraggableQuicklinks';
import AccessControl from '../../components/AccessControl';

// const categories: ICategory[] = [
//   {
//     label: 'Payments',
//     id: 1,
//   },
//   {
//     label: 'Reports',
//     id: 2,
//   },
//   {
//     label: 'User management',
//     id: 3,
//   },
//   {
//     label: 'Settings',
//     id: 4,
//   },
// ];

const EditQuicklinks = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const quickLinksState = useSelector(
    (state: { quickLinks: QuickLinkInterface }) => state.quickLinks
  );

  const categories = quickLinksState.quickLinkCategories;
  const activeLinks = quickLinksState.activeQuickLinks;
  const maxLinks = quickLinksState.maximumQuickLinks;
  const allLinks = quickLinksState.allLinks;

  const [allQuickLinks, setAllQuickLinks] = useState<Array<MenuItem>>(allLinks);
  const [localActiveLinks, setLocalActiveLinks] = useState<Array<MenuItem>>([]);
  const [open, setOpen] = useState(false);
  const [searchResult, setSearchResult] = useState<Array<MenuItem>>([]);

  useEffect(() => {
    setLocalActiveLinks(activeLinks);
  }, []);

  useEffect(() => {
    if (allQuickLinks.length > 0 && localActiveLinks.length > 0) {
      const newLinks = allQuickLinks.map((link) => {
        if (
          localActiveLinks.find((activeLink) => activeLink.label === link.label)
        ) {
          link.isActive = true;
        } else {
          link.isActive = false;
        }
        return link;
      });
      setAllQuickLinks(newLinks);
      if (searchResult.length > 0) {
        const newSearchLinks = searchResult.map((link) => {
          if (
            localActiveLinks.find(
              (activeLink) => activeLink.label === link.label
            )
          ) {
            link.isActive = true;
          } else {
            link.isActive = false;
          }
          return link;
        });
        setSearchResult(newSearchLinks);
      }
    } else {
      setAllQuickLinks(
        allQuickLinks.map((link) => ({ ...link, isActive: false }))
      );
      setSearchResult([]);
    }
  }, [localActiveLinks]);

  const removeItem = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    label: string
  ) => {
    e.stopPropagation();
    const newData = localActiveLinks.filter(
      (theData) => theData.label !== label
    );

    setLocalActiveLinks(newData);
    // setData(newData);
  };

  const onSelect = (item: MenuItem) => {
    const newLinks = allQuickLinks.map((link) => {
      if (link.label === item.label) {
        if (!link.isActive && localActiveLinks.length === maxLinks) {
          link.isActive = false;
        } else {
          link.isActive = !link.isActive;
        }
      }
      return link;
    });
    setAllQuickLinks(newLinks);
    // set active links
    const activeItemIndex = localActiveLinks.findIndex(
      (link) => link.label === item.label
    );

    if (activeItemIndex >= 0) {
      const newActiveLinks = localActiveLinks;
      newActiveLinks.splice(activeItemIndex, 1);
      setLocalActiveLinks(newActiveLinks);
    } else {
      if (localActiveLinks.length === maxLinks) {
        // warning('Info', `You already have ${maxLinks}`);
        return;
      }
      setLocalActiveLinks([...localActiveLinks, { ...item }]);
    }
  };

  const onSelectFromSearch = (item: MenuItem) => {
    const newLinks = searchResult.map((link) => {
      if (link.label === item.label) {
        if (!link.isActive && localActiveLinks.length === maxLinks) {
          link.isActive = false;
        } else {
          link.isActive = !link.isActive;
        }
      }
      return link;
    });
    setSearchResult(newLinks);
    // set active links
    const activeItemIndex = localActiveLinks.findIndex(
      (link) => link.label === item.label
    );

    if (activeItemIndex >= 0) {
      const newActiveLinks = localActiveLinks;
      newActiveLinks.splice(activeItemIndex, 1);
      setLocalActiveLinks(newActiveLinks);
    } else {
      if (localActiveLinks.length === maxLinks) {
        // warning('Info', `You already have ${maxLinks}`);
        return;
      }
      setLocalActiveLinks([...localActiveLinks, { ...item }]);
    }
  };

  const onSave = () => {
    dispatch(setActiveLinks(localActiveLinks));
    success('Done!', 'Quick links saved successfully');
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newLinks = localActiveLinks;
    // move soucrce to destination and vice versa
    const itemToMove: MenuItem = newLinks[source.index];
    newLinks.splice(source.index, 1);
    newLinks.splice(destination.index, 0, { ...itemToMove });
    setLocalActiveLinks([...newLinks]);
  };

  const searchLinks = (searchTerm: string) => {
    // Normalize the search term to make the search case insensitive.
    const normalizedSearchTerm = searchTerm.toLowerCase();

    return allQuickLinks
      .map((link) => {
        // If there are no keywords, set matches to 0.
        if (!link.keywords) {
          return { ...link, matches: 0 };
        }

        // Count the number of matching keywords.
        const matches = link.keywords.reduce((count, tag) => {
          return tag.toLowerCase().includes(normalizedSearchTerm)
            ? count + 1
            : count;
        }, 0);

        return { ...link, matches };
      })
      .filter((link) => link.matches > 0) // Only consider items with one or more matches.
      .sort((a, b) => b.matches - a.matches); // Sort by descending order of matches.
  };

  function search(value: string) {
    if (!value) {
      setSearchResult([]);
      return;
    }
    const result = searchLinks(value);
    setSearchResult(result);
  }

  function closeSearch() {
    setSearchResult([]);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <S.HeaderContainer>
        <S.HeaderBackButton>
          <S.BackButton onClick={() => router.goBack()}>
            <Icon name='small-caret-left' color='#0B0B0B' />
          </S.BackButton>
          <Text color='#2C2A3B' size={5} bold style={{ fontWeight: 700 }}>
            Edit Quicklinks
          </Text>
        </S.HeaderBackButton>
        <S.Input>
          <SearchInput
            placeholder='What are you looking for? e.g statement...'
            onChange={search}
          />
          <S.DropdownContent open={searchResult.length > 0}>
            <div
              style={{
                position: 'absolute',
                right: 5,
                top: 5,
                cursor: 'pointer',
              }}
              onClick={closeSearch}
            >
              <Icon name='small_close' color={colors.black._20} />
            </div>
            <div>
              <S.SingleItemWrapper
                disabled={false}
                // onClick={() => onSelect(item)}
                first={true}
              >
                {/* <Text size={6} color={colors.black._100}>
                    Label
                  </Text> */}
                {searchResult?.length > 0 &&
                  searchResult?.map((result, index) => (
                    <AccessControl
                      requiredPermission={result.requiredPermission ?? []}
                    >
                      <S.LinksInner
                        key={index}
                        onClick={() => onSelectFromSearch(result)}
                      >
                        <Icon
                          width={22}
                          height={20}
                          name={result.icon as IconName['name']}
                          color={colors.black._100}
                        />
                        <Text
                          // style={{ whiteSpace: 'nowrap' }}
                          color={colors.black._100}
                          bold
                          size={6}
                        >
                          {result.label}
                        </Text>
                        {result.isActive && (
                          <S.CheckBtn>
                            <Icon name='small_check' />
                          </S.CheckBtn>
                        )}
                      </S.LinksInner>
                    </AccessControl>
                  ))}
              </S.SingleItemWrapper>
            </div>
          </S.DropdownContent>
        </S.Input>
      </S.HeaderContainer>
      <S.CardContainer>
        {/* <S.CardContentContainer>
        <Text color={colors.black._20}>Quick Links</Text>
      </S.CardContentContainer> */}

        <S.CardMainContentContainer>
          <S.ContentHeader>
            <Text color={colors.black._20} size={5} style={{ fontWeight: 600 }}>
              Select the quicklinks that you want to see on your dashboard
            </Text>

            <div
              style={{
                padding: '5px 10px 5px 10px',
                background: '#2C323A',
                height: 'fit-content',
                width: 'fit-content',
                borderRadius: 36,
              }}
            >
              <Text
                color={colors.white._100}
                size={5}
                style={{ fontWeight: 600 }}
              >
                {localActiveLinks.length}/{maxLinks}
              </Text>
            </div>
            <div>
              <Button label='Save' style={{ width: 184 }} onClick={onSave} />
            </div>
          </S.ContentHeader>
          <div>
            <DraggableQuickLinks
              id={'11'}
              isActive
              showDeleteButton
              data={localActiveLinks}
              onRemoveItem={removeItem}
            />
          </div>
          <>
            {categories.map((category) => (
              <div>
                <QuickLinks
                  onMenuClick={onSelect}
                  showSelectedIcon
                  key={category.id}
                  title={category.label}
                  data={allQuickLinks.filter(
                    (item) => item.categoryId === category.id
                  )}
                />
              </div>
            ))}
          </>
        </S.CardMainContentContainer>
      </S.CardContainer>
    </DragDropContext>
  );
};

export default EditQuicklinks;
