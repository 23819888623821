import { FC, useEffect, useState } from 'react';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import * as S from './styles';
import { Text } from '../Text';
import { OtpInput } from '../OtpInput';
import { Button } from '../Button';

interface IProps {
  show: boolean;
  toggle: () => void;
  onSubmit: (pin: string) => Promise<void>;
  loading?: boolean;
}
const PinComponent: FC<IProps> = ({
  show,
  toggle,
  onSubmit,
  loading = false,
}) => {
  const [pin, setPin] = useState<string>('');

  useEffect(() => {
    if (!show) {
      setPin('');
    }
  }, [show]);

  const handlePinChange = (pin: string) => {
    setPin(pin);
  };

  const handleSubmit = () => {
    onSubmit(pin);
  };
  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      onCloseModal={toggle}
      crossColor={colors.black._100}
    >
      <S.Container>
        <S.PaymentWrapper>
          <S.BodyWrapper>
            <Text color={colors.black._100} size={2} bold>
              PIN Authentication
            </Text>
            <Text color={colors.black._100} size={5}>
              Enter your 4-digit PIN.
            </Text>
            <S.OtpWapper>
              <OtpInput
                secure
                codeLength={4}
                value={pin}
                onCodeChange={handlePinChange}
              />
            </S.OtpWapper>

            <Button
              disabled={pin.length < 4}
              label={loading ? 'Loading...' : 'Verify Pin'}
              onClick={() => handleSubmit()}
            />
          </S.BodyWrapper>
        </S.PaymentWrapper>
      </S.Container>
    </ModalWrapper>
  );
};

export default PinComponent;
