import { useCallback, useEffect, useState } from 'react';
import { OnboardingInterface } from '../redux/onboarding/IOnboarding';
import { useSelector } from 'react-redux';
import {
  createProfileOnboardingFlow,
  signupOnboardingFlow,
} from '../utils/data';
import { useHistory } from 'react-router-dom';
import { Text } from '../components/Text';
import { colors } from '../styles/variables';

export const useOnboarding = () => {
                                     const history = useHistory();

                                     const onboarding = useSelector(
                                       (state: {
                                         onboardingStack: OnboardingInterface;
                                       }) => state.onboardingStack
                                     );

                                     const path = history.location.pathname;

                                     const savedTracker = sessionStorage.getItem(
                                       'onboardingTracker'
                                     );

                                     const [tracker, setTracker] = useState<
                                       Array<{
                                         done: boolean;
                                         text: string;
                                         isActive: boolean;
                                         defaultOpaciy: number;
                                         route: string | string[];
                                         key: string;
                                       }>
                                     >(() =>
                                       savedTracker
                                         ? JSON.parse(savedTracker)
                                         : []
                                     );

                                     const [data, setData] = useState<{
                                       start: number;
                                       end: number;
                                     }>({
                                       start: 0,
                                       end: 5,
                                     });

                                     const [label, setLabel] = useState<
                                       JSX.Element
                                     >();

                                     const updateTracker = (
                                       value: typeof tracker
                                     ) => {
                                       setTracker(value);
                                       sessionStorage.setItem(
                                         'onboardingTracker',
                                         JSON.stringify(value)
                                       );
                                     };

                                     useEffect(() => {
                                       if (
                                         onboarding.data.type ==
                                         'create profile'
                                       ) {
                                         if (onboarding.data.businessType) {
                                           // setTracker((prev) => [
                                           //   ...createProfileOnboardingFlow['sole_proprietorship'](path),
                                           // ]);
                                           updateTracker([
                                             ...createProfileOnboardingFlow[
                                               'sole_proprietorship'
                                             ](path),
                                           ]);
                                         }
                                       }
                                     }, [onboarding]);

                                     useEffect(() => {
                                       if (
                                         path ===
                                           '/onboarding/corporate/create' ||
                                         onboarding.data.type ===
                                           'create profile'
                                       ) {
                                         setLabel(
                                           <Text
                                             size={4}
                                             color={colors.black._20}
                                             bold
                                           >
                                             Sign up with an
                                             <br /> existing account
                                           </Text>
                                         );

                                         updateTracker([
                                           ...createProfileOnboardingFlow[
                                             'sole_proprietorship'
                                           ](path),
                                         ]);
                                       }

                                       if (
                                         path ===
                                           '/onboarding/signup/personal-information' ||
                                         path ===
                                           '/onboarding/signup/verify-bvn' ||
                                         path === '/onboarding/ubo' ||
                                         onboarding.data.type === 'signup'
                                       ) {
                                         setLabel(
                                           <Text
                                             size={4}
                                             color={colors.black._20}
                                             bold
                                           >
                                             Create new
                                             <br /> account
                                           </Text>
                                         );
                                         if (
                                           onboarding.data.businessType ===
                                             'limited_liability' ||
                                           onboarding.data.businessType ===
                                             'trustee_ngo'
                                         ) {
                                           // setTracker((prev) => {
                                           //   return signupOnboardingFlow['limited_liability'](path);
                                           // });
                                           updateTracker(
                                             signupOnboardingFlow[
                                               'limited_liability'
                                             ](path)
                                           );
                                         } else {
                                           // setTracker((prev) => {
                                           //   return [...signupOnboardingFlow['sole_proprietorship'](path)];
                                           // });
                                           updateTracker([
                                             ...signupOnboardingFlow[
                                               'sole_proprietorship'
                                             ](path),
                                           ]);
                                         }
                                       }
                                       const pathIndex = tracker.findIndex(
                                         (track) =>
                                           Array.isArray(track.route)
                                             ? track.route.includes(path)
                                             : track.route === path
                                       );
                                       if (pathIndex >= 0) {
                                         if (pathIndex === tracker.length - 1) {
                                           setData({
                                             start: pathIndex - 2,
                                             end: pathIndex + 1,
                                           });
                                         } else if (pathIndex % 2 === 0) {
                                           setData({
                                             start: pathIndex,
                                             end: pathIndex + 5,
                                           });
                                         }
                                       }
                                       if (tracker.length) {
                                         const newTracker = tracker.map(
                                           (track) => {
                                             if (Array.isArray(track.route)) {
                                               if (track.route.includes(path)) {
                                                 track.isActive = true;
                                               } else {
                                                 track.isActive = false;
                                               }
                                             } else if (track.route === path) {
                                               track.isActive = true;
                                             } else {
                                               track.isActive = false;
                                             }
                                             if (track.key === 'companyInfo') {
                                               const businessInfo =
                                                 (onboarding?.data
                                                   ?.businessInfo as any) ||
                                                 onboarding.businessInfo;
                                               if (
                                                 onboarding?.data
                                                   ?.vbankAccount ||
                                                 businessInfo?.nuban ||
                                                 businessInfo?.cac
                                               ) {
                                                 track.done = true;
                                               }
                                             }

                                             if (
                                               track.key === 'loginInfo' ||
                                               track.key === 'adminInfo' ||
                                               track.key === 'personalInfo'
                                             ) {
                                               let user = onboarding?.data
                                                 ?.user as any;
                                               let credentials = onboarding
                                                 ?.data?.credentials as any;
                                               if (
                                                 (user ||
                                                   credentials?.personalEmail) &&
                                                 track.key === 'personalInfo'
                                               ) {
                                                 track.done = true;
                                               } else if (
                                                 (credentials?.personalEmail ||
                                                   onboarding?.data?.userId) &&
                                                 track.key === 'loginInfo'
                                               ) {
                                                 track.done = true;
                                               } else if (
                                                 user?.profile ||
                                                 credentials?.email ||
                                                 onboarding?.data?.userId
                                               ) {
                                                 track.done = true;
                                               }
                                             }
                                             if (track.key === 'businessType') {
                                               const businessInfo = onboarding
                                                 ?.data?.businessInfo as any;
                                               if (
                                                 businessInfo?.type ||
                                                 onboarding?.data?.businessType
                                               ) {
                                                 track.done = true;
                                               }
                                             }

                                             if (
                                               track.key === 'managementInfo'
                                             ) {
                                               const info = onboarding?.data
                                                 ?.directors as any;
                                               if (info?.length) {
                                                 track.done = true;
                                               }
                                             }

                                             if (track.key === 'workflow') {
                                               if (
                                                 onboarding?.data?.operators
                                               ) {
                                                 const info = onboarding?.data
                                                   ?.operators as any;
                                                 if (
                                                   info?.length ||
                                                   onboarding?.data?.workflowSet
                                                 ) {
                                                   track.done = true;
                                                 }
                                               }
                                             }

                                             if (
                                               track.key === 'approvallLimit'
                                             ) {
                                               if (
                                                 onboarding?.data?.operators
                                               ) {
                                                 const info = onboarding?.data
                                                   ?.operators as any;
                                                 if (
                                                   info?.length &&
                                                   info.some(
                                                     (inf: any) =>
                                                       inf.approvalLimit
                                                   )
                                                 ) {
                                                   track.done = true;
                                                 }
                                               }
                                             }

                                             if (
                                               track.key === 'uploadDocument'
                                             ) {
                                               // add upload document to state and otp route
                                               if (
                                                 onboarding?.data?.fileUploads
                                               ) {
                                                 track.done = true;
                                               }
                                             }

                                             if (
                                               track.key === 'referenceInfo'
                                             ) {
                                               // add reference into to otp route on the BE
                                               if (
                                                 onboarding?.data?.referenceInfo
                                               ) {
                                                 const info = onboarding?.data
                                                   ?.referenceInfo as any;
                                                 if (info?.length) {
                                                   track.done = true;
                                                 }
                                               }
                                             }

                                             if (
                                               track.key === 'consentLetter'
                                             ) {
                                               const consentEmails = onboarding
                                                 ?.data?.consentEmails as any;
                                               if (
                                                 onboarding?.data?.directors
                                               ) {
                                                 const info = onboarding?.data
                                                   ?.directors as any;
                                                 if (
                                                   (info.length &&
                                                     info.some(
                                                       (inf: any) =>
                                                         inf.sendConsent ||
                                                         inf.consentStatus ===
                                                           'Approved'
                                                     )) ||
                                                   consentEmails?.length
                                                 ) {
                                                   track.done = true;
                                                 }
                                               }
                                             }

                                             if (track.key === 'setPin') {
                                               if (onboarding.data.user) {
                                                 const info = onboarding?.data
                                                   ?.user as any;
                                                 if (info?.pin) {
                                                   track.done = true;
                                                 }
                                               }
                                             }

                                             return track;
                                           }
                                         );

                                         // setTracker(newTracker);
                                         updateTracker(newTracker);
                                       }

                                       const activeSliceArrayIndex = tracker
                                         .slice(data.start, data.end)
                                         .findIndex((track) =>
                                           Array.isArray(track.route)
                                             ? track.route.includes(path)
                                             : track.route === path
                                         );
                                       if (
                                         activeSliceArrayIndex < 0 &&
                                         data.start !== 0
                                       ) {
                                         setData({
                                           start: data.start - 1,
                                           end: data.end - 1,
                                         });
                                       }
                                     }, [path]);

                                     const addTracker = useCallback(
                                       (data: any): void => {
                                         setTracker(data);
                                       },
                                       []
                                     );
                                     return {
                                       tracker,
                                       data,
                                       label,
                                     };
                                   };
