import styled, { css } from 'styled-components';

import { DropdownInputProps, SingleItemProps, MultiItemProps } from '.';
import { fonts, colors, transition } from '../../styles/variables';
import {
  crossBrowserBoxShadow, crossBrowserDisableSelection, crossBrowserTransition, ptr,
} from '../../styles/utils';

interface ArrowProps {
  rotate?: number,
}
interface DropdownState {
  open?: boolean,
  value?: string,
}

const Container = styled.div`
  position: relative;
  flex: 1;
`;

const Wrapper = styled.div<DropdownState & DropdownInputProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${ptr(6)};
  background-color: ${colors.white._100};
  position: relative;
  cursor: pointer;
  padding: 0 ${ptr(20)};
  height: ${ptr(42)};  
  border: 1px solid ${colors.black._1};
  :hover {
    opacity: 0.8;
  }
  @media screen and (min-device-width: 280px) and (max-device-width: 420px){    
    margin-right:40px;
    width: 91%;
 }

  ${(props) => props.disabled && css`
    background-color: ${colors.black._10};
  `}
`;

const StyledLabel = styled.label<DropdownState>`
  /* position: absolute; */
  pointer-events: none;
  line-height: ${ptr(24)};
  font-family: ${fonts.inter.regular};
  color: ${colors.black._100};
  ${crossBrowserTransition(transition.slow)}
  padding: ${ptr(18)} 0;
  
`;
const StyledInput = styled.input`
  width: 100%;
  font-family: ${fonts.inter.regular};
  line-height: ${ptr(24)};
  border: 0;
  padding: ${ptr(27)} ${ptr(20)} ${ptr(27)} ${ptr(0)};
  color: ${colors.black._100};
  display: block;
  box-sizing: border-box;
  background-color: transparent;

  ${crossBrowserTransition(transition.superSlow)}
  ::placeholder {
    /* color: ${colors.black._40}; */
    opacity: 0.4;
    font-size: 12px;
  }
`;

const SubLabelWrapper = styled.div`
  width: 40%;
  text-align: right;
  padding-right: ${ptr(20)};
  align-items: center;
  background-color: transparent;
  ${crossBrowserDisableSelection()}
`;

const ArrowIconWrapper = styled.div<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  ${crossBrowserTransition(transition.slow)}
  ${(props) => props.rotate && css`
    transform: rotate(${props.rotate}deg);
  `}
`;

const DropdownContent = styled.div<DropdownState>`
  display: none;
  position: absolute;
  z-index: 1000;
  max-height: ${ptr(450)};
  overflow: auto;
  overflow-x: hidden;
  flex-direction: column;
  background-color: ${colors.white._100};
  top: ${ptr(60)};
  margin-top: ${ptr(8)};
  width: 100%; 
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(6)};
  max-height: ${ptr(450)};
  ${crossBrowserTransition(transition.slow)}
  ${crossBrowserBoxShadow(`0 0 15px ${ptr(0.2)} ${colors.black._10}`)}
  ${(props) => props.open && css`
    display: flex;
  `}
`;

const SingleItemWrapper = styled.div<SingleItemProps>`
  padding: ${ptr(18)} ${ptr(24)};
  border-top: 1px solid ${colors.black._10};
  ${(props) => !props.disabled && css`
    cursor: pointer;
    :hover {
      background-color: ${colors.black._7};
      > span {
        ${crossBrowserDisableSelection()}
        color: ${colors.black._20};
        font-weight: 600;
      }
    }
  `}
  ${(props) => props.first && css`
    border-top: 0;
    
  `}
`;
const MultiItemWrapper = styled.div<MultiItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${ptr(8)} ${ptr(20)};
  border-top: 1px solid ${colors.black._10}; 
  ${(props) => !props.disabled && css`
    cursor: pointer;
    :hover {
      background-color: ${colors.black._60};
      span {
        ${crossBrowserDisableSelection()}
        color: ${colors.white._100};
      }
    }
    `}
    ${(props) => props.first && css`
    border-top: 0;
    `}
    `;
const MultiItemLabelsWrapper = styled.div<MultiItemProps>`
  display: flex;
  flex-direction: column;
`;
const MultiCategoryWrapper = styled.div<MultiItemProps>`
  padding: ${ptr(18)} ${ptr(20)};
  border-top: 1px solid ${colors.black._10};
`;

const Loading = styled.div`
  margin-right: ${ptr(16)};
`;

export {
  SubLabelWrapper,
  Container,
  Wrapper,
  StyledLabel,
  ArrowIconWrapper,
  DropdownContent,
  SingleItemWrapper,
  MultiCategoryWrapper,
  MultiItemWrapper,
  MultiItemLabelsWrapper,
  StyledInput,
  Loading,
};
