import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../redux";
import PublicRoutes from "./public";
import PrivateRoutes from "./private";
import { CurrentUserActions } from "../redux/current-user/actions";
import { Splash } from "../pages/Splash";
import { getPreference, PreferenceActions } from "../redux/preference/actions";
import { failed } from "../components/Toasts";
import { AuthorizationActions } from "../redux/authorization/actions";

const Routing: React.FC = () => {
  const authorization = useSelector((store: RootState) => store.authorization);
  const currentUser = useSelector((store: RootState) => store.currentUser);
  const preference = useSelector((store: RootState) => store.preference);

  const dispatch = useDispatch();

  /**
   * Authorization
   */
  const checkTokenAvailability = () => {
    dispatch(CurrentUserActions.getUserData());
  };

  useEffect(() => {
    checkTokenAvailability();
  }, []);

  useEffect(() => {
    if (!preference.withdrawalFee || !preference.withdrawalFee.length) {
      PreferenceActions.getPreference()
        .then((res) => dispatch(getPreference(res)))
        .catch(() => {
          failed("Failed", "Unable to load app configuration");
          dispatch(AuthorizationActions.logOut());
        });
    }
  }, [preference]);

  if (currentUser.loading) {
    return <Splash />;
  }

  return (
    <Router>
      {authorization.success ? <PrivateRoutes /> : <PublicRoutes />}
    </Router>
  );
};

export default Routing;
