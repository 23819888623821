import React, { useEffect, useState } from 'react';
import { colors } from '../../styles/variables';
import { Text } from '../../components/Text';
import { useHistory } from 'react-router-dom';
import {
  Details,
  getOnboardModalDetail,
} from '../../utils/onboardModalDetails';
import { SignupOptionModal } from '../../components/SignupOptionModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { OnboardingActions } from '../../redux/onboarding/actions';

export const AddBusiness: React.FC = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const preference = useSelector((store: RootState) => store.preference);

  const [details, setDetails] = useState<Details | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const actionDetails = getOnboardModalDetail(
      'signupOptions',
      preference.bvnConsentEnabled
    );
    setDetails(actionDetails);
    setShowModal(true);
    dispatch(OnboardingActions.resetOnboardingState());
    sessionStorage.removeItem('onboardingState');
    sessionStorage.removeItem('onboardingTracker');
  }, []);

  const handleCloseModal = () => {
    setDetails(null);
    setShowModal(false);
    history.push('/dashboard');
  };

  return (
    <>
      <SignupOptionModal
        showModal={showModal}
        onCloseModal={handleCloseModal}
        details={details}
      />
    </>
  );
};
