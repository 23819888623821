/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';
import successImg from './assets/Images/success.png';
import { Text } from '../Text';
import { OtpInput } from '../OtpInput';
import * as S from './styles';
import { colors } from '../../styles/variables';
import { Button } from '../Button';
import { success, failed, warning } from '../Toasts';
import { formatCash } from '../../utils/money';
import { downloadPDF } from '../../utils/download';
import { IPaymentDetails } from '../PaymentDetails';

export interface PinAuthProps {
  paymentsDetails?: Array<IPaymentDetails>;
}
export interface PaymentSuccessDetailsProps {
  payresponse: Array<PaymentSuccessDetails>;
}
export interface PaymentSuccessDetails {
  id: string;
  isFlagged: boolean;
  retryCount: number;
  userId: string;
  businessId: string;
  accountId: string;
  reference: string;
  amount: number;
  receiverName: string;
  receiverBvn: number;
  receiverNuban: number;
  receiverBankCode: number;
  receiverClientId: number;
  receiverSavingsId: number;
  comment: null;
  status: string;
  destinationBank: string;
  lastFailureResponse: string;
  actionRequired: string;
}
export interface TransaferResponse {
  data: Array<PaymentSuccessDetails>;
  meta: {
    failed: number;
    successful: number;
    total: number;
    pending: number;
  };
}
const defaultResponseData: TransaferResponse = {
  data: [],
  meta: {
    failed: 0,
    successful: 0,
    total: 0,
    pending: 0,
  },
};
export const PinAuth: React.FC<PinAuthProps> = ({ paymentsDetails }) => {
  // const [width] = useWindowSize();
  /**
   * Local state initiation region
   */
  const [currentView, setcurrentView] = useState<string>('Pin');
  const [pin, setPin] = useState<string>('');

  const [isproccessing, setIsproccessing] = useState<boolean>(false);
  const [paymentSuccessResponseData, setPaymentSuccessResponseData] = useState<
    TransaferResponse
  >(defaultResponseData);
  const [reSend, setReSend] = useState(false);
  const [countDown, setCountDown] = useState<string>('04:00');
  const [validatingOtp, setValidatingOtp] = useState(false);
  const [checkPin, setCheckPin] = useState(true);

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!reSend && currentView === 'otp') {
      const currentData = new Date();
      const countDownDate = new Date(
        currentData.getTime() + 4 * 60000
      ).getTime();
      timer.current = setInterval(() => {
        setCountDown(() => {
          const now = new Date().getTime();
          const diff = countDownDate - now;
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          if (minutes === 0 && seconds === 0) {
            setReSend(true);
            if (timer.current) clearInterval(timer.current);
          }
          return `${minutes}:${seconds}`;
        });
      }, 1000);
    }
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [reSend, currentView]);
  const determineTransferInfoDisplay = (
    successcount: number,
    failurecount: number,
    totalcount: number,
    pendingcount: number
  ) => {
    let responseObject = {
      bgcolor: '',
      bodyText: '',
      headers: '',
      type: '',
    };
    const fullSuccess = successcount + pendingcount;
    if (failurecount === totalcount) {
      responseObject = {
        bgcolor: 'red',
        bodyText: 'Failed',
        headers: 'Operation Failed',
        type: 'fail',
      };
    } else if (fullSuccess === totalcount) {
      responseObject = {
        bgcolor: '#36B83B',
        bodyText: 'Success!',
        headers: 'Your bank transfer was successful!',
        type: 'sucess',
      };
    } else if (fullSuccess < totalcount) {
      responseObject = {
        bgcolor: '#FFBF00',
        bodyText: '',
        headers: 'Operation successful!',
        type: 'warning',
      };
    }

    return responseObject;
  };

  const verifyUserPin = async (otp?: string): Promise<void> => {
    // setcurrentView('otp');
    setIsproccessing(true);
    try {
      if (paymentsDetails) {
        debug.info('PAyment Details', paymentsDetails);
        const otpValue = otp || '';
        const res = paymentsDetails.map((item) => ({
          paymentAccount: item.paymentAccount?.key || '',
          receiverPaymentAccount: item.destinationAccount?.key || '',
          amount: Number(item.amount?.value.replace(/[\s,₦%]/g, '')),
          comment: item.comment || '',
        }));
        const result = await paymentsService.makeTransfer(
          {
            payments: res,
          },
          checkPin,
          pin,
          otpValue
        );

        if (result.message && result.message === 'otp generated') {
          setCheckPin(false);
          setReSend(false);
          success(
            'Successful',
            'An OTP has been sent to your registered phone number'
          );
          setcurrentView('otp');
        } else {
          const ThisPaymentResponse = determineTransferInfoDisplay(
            result.meta.successful,
            result.meta.failed,
            result.meta.total,
            result.meta.pending
          ).type;
          if (ThisPaymentResponse === 'sucess') {
            success('Successful', 'Transfer completed successfully');
          } else if (ThisPaymentResponse === 'warning') {
            warning('Successful', 'Operation successful!');
          } else {
            failed('Failed', 'Operation Failed');
          }
          setPaymentSuccessResponseData(result);
          setcurrentView('PaymentSuccess');
          setIsproccessing(false);
        }
      }
    } catch (err) {
      failed(
        'Error',
        err.message || 'Unable to make payment at the moment, please try later'
      );
      setIsproccessing(false);

      debug.error('Error while payment creation', err);
      debug.error('Error while payment creation', err.message);
    }
    debug.info('Result', paymentsDetails);
  };

  const resendOtp = async () => {
    await verifyUserPin();
  };

  const getOtpValue = async (value: string) => {
    if (value.length === 6) {
      setValidatingOtp(true);
      await verifyUserPin(value);
      setValidatingOtp(false);
    }
  };
  return (
    <>
      <S.Container>
        {currentView === 'Pin' ? (
          <S.PaymentWrapper>
            <S.BodyWrapper>
              <Text color={colors.black._100} size={2} bold>
                PIN Authentication
              </Text>
              <Text color={colors.black._100} size={5}>
                Enter your 4-digit PIN.
              </Text>
              <S.OtpWapper>
                <OtpInput secure codeLength={4} onCodeChange={setPin} />
              </S.OtpWapper>

              <Button
                disabled={pin.length < 4 || isproccessing}
                label={
                  isproccessing === true ? 'Processing..' : 'Make a transfer'
                }
                onClick={() => verifyUserPin()}
              />
            </S.BodyWrapper>
          </S.PaymentWrapper>
        ) : currentView === 'otp' ? (
          <S.PaymentWrapper>
            <S.BodyWrapper>
              <Text color={colors.black._100} size={2} bold>
                OTP Code
              </Text>
              <Text color={colors.black._100} size={5}>
                Open your authenticator app
              </Text>
              <Text color={colors.black._100} size={5}>
                Enter the code below to proceed.
              </Text>
              <S.OtpWapper>
                <OtpInput
                  secure
                  codeLength={6}
                  loading={validatingOtp}
                  onCodeChange={(value) => getOtpValue(value)}
                />
              </S.OtpWapper>

              {/* <Text color={colors.black._100} size={5}>
                <span
                  style={{ opacity: reSend ? 1 : 0.3, cursor: 'pointer' }}
                  onClick={resendOtp}
                  aria-hidden="true"
                >
                  Resend code
                </span>
                ({countDown})
              </Text> */}
            </S.BodyWrapper>
          </S.PaymentWrapper>
        ) : currentView === 'PaymentSuccess' ? (
          <>
            <div id="pdf">
              <S.SuccessHeader
                backgroundColor={
                  determineTransferInfoDisplay(
                    paymentSuccessResponseData.meta.successful,
                    paymentSuccessResponseData.meta.failed,
                    paymentSuccessResponseData.meta.total,
                    paymentSuccessResponseData.meta.pending
                  ).bgcolor
                }
              >
                <S.BodyWrapper>
                  <S.ImageIcon>
                    <img src={successImg} alt="" />
                  </S.ImageIcon>
                  <S.TextWrapper>
                    <Text color={colors.white._100} size={4} bold>
                      {
                        determineTransferInfoDisplay(
                          paymentSuccessResponseData.meta.successful,
                          paymentSuccessResponseData.meta.failed,
                          paymentSuccessResponseData.meta.total,
                          paymentSuccessResponseData.meta.pending
                        ).headers
                      }
                    </Text>
                  </S.TextWrapper>
                  <Text color={colors.white._100} size={6}>
                    {
                      determineTransferInfoDisplay(
                        paymentSuccessResponseData.meta.successful,
                        paymentSuccessResponseData.meta.failed,
                        paymentSuccessResponseData.meta.total,
                        paymentSuccessResponseData.meta.pending
                      ).bodyText
                    }
                  </Text>
                </S.BodyWrapper>
              </S.SuccessHeader>

              <S.AccountDetails>
                {paymentSuccessResponseData.data.map((item, index) => {
                  const uniqueKey = index + 1;

                  return (
                    <>
                      <S.CustomTable key={uniqueKey}>
                        <tr>
                          <th>
                            <Text color={colors.black._100} size={5} bold>
                              Amount
                            </Text>
                          </th>
                          <th>
                            <Text color={colors.black._100} size={5}>
                              ₦{formatCash(String(item.amount))}
                            </Text>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <Text color={colors.black._100} size={5} bold>
                              Destination account
                            </Text>
                          </th>
                          <th>
                            <Text color={colors.black._100} size={5}>
                              {item.receiverNuban}
                            </Text>
                          </th>
                        </tr>

                        {item.destinationBank && (
                          <tr>
                            <th>
                              <Text color={colors.black._100} size={5} bold>
                                Destination bank
                              </Text>
                            </th>
                            <th>
                              <Text color={colors.black._100} size={5}>
                                {item.destinationBank}
                              </Text>
                            </th>
                          </tr>
                        )}
                        <tr>
                          <th>
                            <Text color={colors.black._100} size={5} bold>
                              Receiver name
                            </Text>
                          </th>
                          <th>
                            <Text color={colors.black._100} size={5}>
                              {item.receiverName}
                            </Text>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <Text color={colors.black._100} size={5} bold>
                              Payment Status
                            </Text>
                          </th>
                          <th>
                            <Text
                              color={
                                item.status === 'failed'
                                  ? colors.red._100
                                  : colors.black._100
                              }
                              size={5}
                            >
                              {item.actionRequired
                                ? `Pending ${item.actionRequired}`
                                : item.status}
                            </Text>
                          </th>
                        </tr>
                        {/* {item.status === 'failed' && (
                                <tr>
                                  <th>
                                    <Text color={colors.black._100} size={5} bold>Reason of failure</Text>

                                  </th>
                                  <th style={{ width: 400 }}>

                                    <Text color={item.status === 'failed' ? colors.red._100 : colors.black._100} size={5}>
                                      {item.lastFailureResponse}
                                    </Text>

                                  </th>

                                </tr>
                              )} */}
                        <tr>
                          <th>
                            <Text color={colors.black._100} size={5} bold>
                              Comment
                            </Text>
                          </th>
                          <th>
                            <Text color={colors.black._100} size={5}>
                              {' '}
                              {item.comment}
                            </Text>
                          </th>
                        </tr>
                      </S.CustomTable>

                      <S.HrStyled />
                    </>
                  );
                })}
                {paymentSuccessResponseData.meta.successful <
                paymentSuccessResponseData.meta.total ? (
                  <S.CustomTable>
                    <tr>
                      <th>
                        <Text color={colors.black._100} size={5} bold>
                          Success Count:
                        </Text>
                      </th>
                      <th>
                        <Text color={colors.black._100} size={5}>
                          {paymentSuccessResponseData.meta.successful}
                        </Text>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <Text color={colors.black._100} size={5} bold>
                          Fail Count:
                        </Text>
                      </th>
                      <th>
                        <Text color={colors.black._100} size={5}>
                          {paymentSuccessResponseData.meta.failed}
                        </Text>
                      </th>
                    </tr>
                  </S.CustomTable>
                ) : null}

                <S.GroupButton id="action">
                  {/* <Button label="Share" backgroundColor="transparent" theme="info" />
                    <S.VertialHrStyled /> */}
                  {paymentSuccessResponseData.data.length === 1 && (
                    <Button
                      label="Download"
                      onClick={() => downloadPDF('pdf', 'action', 'Payment')}
                    />
                  )}
                </S.GroupButton>
              </S.AccountDetails>
            </div>
          </>
        ) : null}
      </S.Container>
    </>
  );
};
