import React, { useState, useRef } from 'react';

import { Icon } from '../../../../components/Icon';
import { Text } from '../../../../components/Text';
import { Button } from '../../../../components/Button';
import { formatCash } from '../../../../utils/money';
import copy from 'copy-to-clipboard';
import { colors } from '../../../../styles/variables';
import * as S from './styles';
import {
  DropdownSelect,
  YearDropList,
} from '../../../../components/YearDropList';
import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../../redux/onboarding/actions';
import { useDispatch } from 'react-redux';
import { addBusinessRoute } from '../../../../navigation';
import Overlay from '../../../../components/Overlay';

export interface ItemProps {
  title: string;
  value: string;
  sub: string;
  income?: boolean;
  colour: string;
}

export interface AccountItemProps {
  id?: string;
  balance: string;
  nuban: string;
  type: string;
  category?: string;
  isChecked?: boolean;
  setIsChecked?: (value: boolean) => void;
  theme?: 'dark' | 'light';
}

export interface CompanyBalanceProps {
  balance: string;
  nuban: string;
  category?: string;
  accountId?: string;
  outflow: { balance: number; debitCount?: number; sub: string };
  inflow: { balance: number; creditCount?: number; sub: string };
  open?: boolean;
  onDropdown?: () => void;
  accounts: Array<AccountItemProps>;
  FilterByYear: any;
  year: number;
  month?: string;
  od?: boolean;
  odLimit?: number;
}

const Item: React.FC<ItemProps> = ({
  income = false,
  title = '',
  sub = '',
  value = '',
  colour = '',
}) => (
  <S.ItemBox>
    <S.Icon income={income}>
      <Icon name='arrow-down-right' color={colour} />
    </S.Icon>
    <S.Article>
      <Text color={colors.black._60} size={7}>
        {title}
      </Text>
      <S.Data>
        <Text size={4} bold color={colour}>
          {value}
        </Text>
        <Text size={7}>{sub}</Text>
      </S.Data>
    </S.Article>
  </S.ItemBox>
);

const AccountItem: React.FC<AccountItemProps> = ({
  balance = '0',
  nuban = '',
  type = '',
  category = '',
  isChecked = false,
  theme = 'dark',
  setIsChecked = () => {},
}) => {
  const [visibility, setVisibility] = useState(true);
  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  const route = useHistory();
  const dispatch = useDispatch();

  const addCompany = () => {
    dispatch(OnboardingActions.handleSetEnv());
    // route.push(addCompanyRoute);
    route.push('/onboarding');
  };
  return (
    <S.AccountItemBox
      onClick={() => {
        setIsChecked(true);
      }}
    >
      <S.AccountItemInfoBlock>
        <div>
          <Text size={6} style={{ fontWeight: 600 }} color={colors.black._20}>
            Account Name
          </Text>
        </div>

        <div style={{ display: 'flex', width: '100%' }}>
          <Text size={6} color='#6E7191'>
            {`  ${category} `}
            {` (${nuban}) `}
          </Text>
        </div>

        {/* {!!type && (
        <div>
          <Text color={colors.black._60}>{type}</Text>
        </div>
      )} */}

        <div>
          <S.Balance1>
            <Text style={{ fontWeight: 600 }} color={colors.black._20} size={5}>
              {visibility ? `₦ ${formatCash(balance)}` : '***********'}
            </Text>
            <S.IconWrapper onClick={toggleVisibility}>
              <Icon
                name='eye'
                color={colors.black._20}
                width={38}
                height={38}
              />
            </S.IconWrapper>
          </S.Balance1>
        </div>
      </S.AccountItemInfoBlock>

      <S.AccountItemBalanceBlock>
        {/* <S.CircleBlock>
        <S.Circle theme={theme} checked={isChecked}>
          {isChecked && <S.InsideCircle theme={theme} />}
        </S.Circle>
      </S.CircleBlock> */}
        {isChecked ? <Icon name='check' /> : ''}
      </S.AccountItemBalanceBlock>
    </S.AccountItemBox>
  );
};

export const CompanyBalance: React.FC<CompanyBalanceProps> = ({
  accounts = [],
  balance,
  nuban,
  open = false,
  onDropdown = () => {},
  od,
  odLimit,
}) => {
  const [filterType, setFilterType] = useState<string>('Year');
  const [visibility, setVisibility] = useState(true);

  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  const route = useHistory();
  const dispatch = useDispatch();

  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipBoard = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const copyToClipboard = (nuban: any) => {
    copy(nuban);
    alert(`Copied "${nuban}"`);
  };

  const addCompany = () => {
    dispatch(OnboardingActions.handleSetEnv());
    // route.push(addCompanyRoute);
    route.push(addBusinessRoute);
  };

  const subAccount = () => {
    dispatch(OnboardingActions.handleSetEnv());
    // route.push(addCompanyRoute);
    route.push('/subaccount/confirm-details');
  };

  return (
    <S.Container>
      <S.Bars>
        <img src='/images/onboarding/bubble1.svg' alt='logo' />
      </S.Bars>

      <S.BgImg>
        <img src='/images/onboarding/bubble2.svg' alt='logo' />
      </S.BgImg>
      <S.Wrapper>
        <S.Content>
          <S.Company>
            <S.Account>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Text
                  style={{ display: 'flex' }}
                  color={colors.white._20}
                  size={4}
                >
                  Business Account - {'  '}
                </Text>
                <Text
                  style={{ marginRight: '12px' }}
                  color={colors.white._20}
                  size={4}
                >
                  {nuban}
                </Text>{' '}
                <div onClick={() => copyToClipboard(nuban)}>
                  <Icon
                    name='copy-white'
                    color={colors.white._100}
                    width={38}
                  />
                </div>
              </div>
              <S.AccountIcon onClick={onDropdown} open={open}>
                <Icon
                  name='dropdown'
                  color={colors.black._100}
                  width={24}
                  height={24}
                />
              </S.AccountIcon>
            </S.Account>

            <S.BalanceText>
              <Text color={colors.white._20} size={5}>
                Available balance
              </Text>
            </S.BalanceText>

            <S.Balance>
              <Text bold color={colors.white._20} size={3}>
                {visibility ? `₦ ${formatCash(balance)}` : '***********'}
              </Text>

              <S.IconWrapper onClick={toggleVisibility}>
                <Icon
                  name='eye'
                  color={colors.white._20}
                  width={38}
                  height={38}
                />
              </S.IconWrapper>
            </S.Balance>

            <S.AddNew>
              {/* <Button
                onClick={subAccount}
                style={{
                  borderRadius: 30,
                  padding: 12,
                  background: '#F5F5F5',
                  display: 'flex',
                  gap: '12px',
                }}
                label='Open sub account'
                righIcon='thick-plus'
                color='#21202E'
                size='tiny'
              /> */}
              <Button
                onClick={addCompany}
                style={{ borderRadius: 30, padding: 12, background: '#FFDFED' }}
                label='Add Business'
                righIcon='copy'
                color='#21202E'
                size='tiny'
              />
            </S.AddNew>

            {od && odLimit && (
              <div style={{ paddingTop: 10 }}>
                <Text bold color={colors.black._100} size={5}>
                  Overdraft Limit:{' '}
                </Text>
                <Text bold color={colors.black._100} size={6}>{`₦ ${formatCash(
                  odLimit
                )}`}</Text>
              </div>
            )}

            <S.DropownContent open={open}>
              <div>
                <S.DropownContentText>
                  <Text
                    style={{ fontWeight: 600 }}
                    color={colors.black._20}
                    size={5}
                  >
                    Sub accounts
                  </Text>
                  <Text color={colors.black._20} size={7}>
                    Select an account
                  </Text>
                </S.DropownContentText>
                {accounts.map((item, index) => (
                  <AccountItem
                    key={`${index.toString()}`}
                    balance={item.balance}
                    nuban={item.nuban}
                    type={item.type}
                    category={item.category}
                    isChecked={item.isChecked}
                    setIsChecked={item.setIsChecked}
                  />
                ))}{' '}
              </div>
            </S.DropownContent>
            {open && <Overlay handleClick={onDropdown} />}

            {/* <S.Calendar open={open}>
              <DropdownSelect
                items={['Year', 'Month']}
                text={filterType}
                onChange={(value) => setFilterType(value)}
              />

              {filterType.toLowerCase() === 'year' ? (
                <YearDropList
                  type='year'
                  text={!year ? 'Year' : year.toString()}
                  onChange={FilterByYear}
                />
              ) : (
                <YearDropList
                  type='month'
                  text={!month ? 'Month' : month}
                  onChange={FilterByYear}
                />
              )}
            </S.Calendar> */}
          </S.Company>
        </S.Content>

        {/* <S.BottomContainer open={open}>
          <Item
            income
            title='Outflow'
            value={`- ${+outflow.balance < 0 ? '-' : ''}₦ ${
              formatCash(outflow.balance) || '0'
            }`}
            sub=''
            colour={colors.pink._100}
          />
          <Item
            title='Inflow'
            value={`+ ${+inflow.balance < 0 ? '-' : ''}₦ ${
              formatCash(inflow.balance) || '0'
            }`}
            sub=''
            colour={colors.green._100}
          />
        </S.BottomContainer> */}
      </S.Wrapper>
    </S.Container>
  );
};
