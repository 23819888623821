import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { defaultOperator } from '../../../redux/onboarding/reducer';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { OperatorInterface } from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import { SimpleForm, SimpleFormProps } from '../../Onboarding/components/SimpleForm';

import { validateArrayOfObjects, ValidateArrayResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { errorToString } from '../../../services/internal/app/index';
import { actions } from '../config';
import { Modal } from '../../../components/LoadingModal';

const SCHEME = {
  nin: (value: string) => value.length === 11,
  bvn: (value: string) => value.length === 11,
  email: (value: string) => validator.isEmail(value),
};

const directorDefaultItem = {
  nin: '',
  bvn: '',
  email: '',
};

const CommonSoleAddViewers: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.reference as string,
  );
  const storedViewers = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.viewers as Array<OperatorInterface>,
  );

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<SimpleFormProps>>(
    storedViewers?.length ? storedViewers : [directorDefaultItem],
  );
  const [validation, setValidation] = useState<ValidateArrayResult>();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const push = () => {
    setCollection([...collection, directorDefaultItem]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: string) => {
    setCollection((old) => old.map((el: SimpleFormProps, i: number) => {
      if (i === index) {
        return {
          ...el,
          [property]: value,
        };
      }
      return el;
    }) as Array<SimpleFormProps>);
  };

  const continueHandle = () => {
    setLoading(true);
    const viewers = collection.map((item) => {
      const viewer = { ...defaultOperator };

      viewer.role = 'viewer'; // by default
      viewer.email = item.email;
      //viewer.nin = item.nin;
      viewer.bvn = item.bvn;

      return viewer;
    });
    (async () => {
      try {
        const response = await onboardingService.submitBusinessOperatorsInfo({
          operators: viewers,
          reference,
          workflow: '',
        });
        batch(() => {
          dispatch(OnboardingActions.handleSetData({
            reference: response.data.reference,
            operators: response.data.operators,
          }));
          dispatch(OnboardingActions.handleSetOnBoardingSummaryResponse(response.data));
        });
        route.push(actions[response.actionRequired]);
      } catch (err) {
        dispatch(OnboardingActions.handleSetError(errorToString(err)));
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  return (
    <S.Container>
      <Title title="Add viewers to account " />
      <S.Wrapper>
        {collection.map((item, index) => (
          <SimpleForm
            nin={item.nin}
            bvn={item.bvn}
            email={item.email}
            index={index}
            remove={remove}
            onChange={onChangeValue}
            emailIsValid={validation?.data[index]?.data.email.isValid}
            title="Viewer’s details"
          />
        ))}
      </S.Wrapper>
      <S.Add onClick={push}>
        <Icon name="plus-in-circle" color={colors.pink._100} />
        <Text color={colors.pink._100}>
          Add viewer
        </Text>
      </S.Add>
      <S.ButtonGroup>
        <Button label="Continue" loading={loading} onClick={continueHandle} disabled={!validation?.isValid || loading} />
        {/* <Button label={loading ? 'Please wait..' : 'Skip'}
        onClick={continueHandle} theme="light" /> */}
      </S.ButtonGroup>

      <Modal visible={loading} />
    </S.Container>
  );
};
export default CommonSoleAddViewers;
