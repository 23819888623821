import styled, { css } from 'styled-components';

const InfoTab = styled.div<{ backgroundColor?: string }>`
  display: flex;
  gap: 10px;
  height: 45px;
  width: 100%;
  background-color: #fff9ef;
  padding: 15px;
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export { InfoTab };
