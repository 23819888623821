import React from 'react';
import { batch, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import { OnboardingActions } from '../../../redux/onboarding/actions';

import { success } from '../../../components/Toasts';
import { SuccessSection } from '../../Onboarding/components/SuccessSection';

const CommonOtherAccCompleted: React.FC = () => {
  /**
   * Hooks initiation region
   */
  // const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Custom handlers initiation region
   */
  const gotItHandler = () => {
    batch(() => {
      dispatch(OnboardingActions.handleResetStacks());
      dispatch(OnboardingActions.handleUnSetEnv());
    });
    success('Success!', 'You\'ve created an account successfully.');
    // history.push('/login');
  };

  return (
    <SuccessSection
      title="Account opening completed"
      description="You can now proceed to login using your details"
      onClick={gotItHandler}
    />
  );
};
export default CommonOtherAccCompleted;
