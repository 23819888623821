import React from 'react';
import { useDispatch } from 'react-redux';

import * as S from './styles';
import { Logo } from '../Logo';
import { Link } from '../Link';
import { MainMenuActions } from '../../redux/main-menu/actions';
import { MiniProfile } from '../MiniProfile';

export const Header: React.FC = () => {
  const dispatch = useDispatch();

  const onMenuButtonClick = () => {
    dispatch(MainMenuActions.toggle());
  };

  return (
    <S.Wrapper>
      <S.LeftSideWrapper>
        <S.LogoWrapper>
          <Logo />
        </S.LogoWrapper>
      </S.LeftSideWrapper>
      <S.RightSideWrapper>
        {/* <p>PLEASE SIGN CONSENT FORM</p> */}
        <S.MenuButtonWrapper>
          <Link icon='burger' onClick={onMenuButtonClick} />
        </S.MenuButtonWrapper>
        <S.MiniProfileWrapper>
          <MiniProfile />
        </S.MiniProfileWrapper>
      </S.RightSideWrapper>
    </S.Wrapper>
  );
};
