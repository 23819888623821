/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CompanyActions } from '../../redux/company/actions';
import { CompanyInterface } from '../../redux/company/ICompany';
import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';
import { Icon } from '../../components/Icon';
import { Text } from '../../components/Text';
import * as S from './styles';
import { colors } from '../../styles/variables';
import { Table, TableFooterProps } from '../../components/Table';
import { YearDropList } from '../../components/YearDropList';
import { Preload } from '../../components/Preload';
import { CheckBox } from '../../components/CheckBox';
import { ModalWrapper } from '../../components/ModalWrapper';
import SearchInput from '../../components/SearchInput';
import { PaymementAccountInterface } from '../../services/internal/payments/IPayments';
import { FilterResult } from '../AnalyticsNReport/Component/FilterResult';
import { PaymentDetailsViews } from '../AnalyticsNReport/Component/PaymentDetailsViews';
import { formatCash } from '../../utils/money';
import { getDateWithYearFirst, timeDateMonthString } from '../../utils/datetime';
import { IPagination, Pagination } from '../../components/Pagination';
import { IGetQueries } from '../../interface';
import getQueryString from '../../utils/getQueryString';
import { PaginationActions } from '../../redux/paginationSettings/actions';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { failed } from '../../components/Toasts';
import { IndividualPayment } from './individualPayment';
const separator = '=';

export interface RecentTransactionsProps {
	data: Array<IPayments>,
	last: boolean,
}

export interface BatchHistory {
	businessId: string,
	reference: string,
	name: string,
	status: string,
	id: string,
	frequency: number,
	startDate: string,
	createdAt: string
	updatedAt: string
	next_payment_date: number,
	totalPayment: number,
	senderAccount: number,
	transactionCount: number,
	check: boolean
}

export interface PaymentHistory {
	accountId: number,
	amount: number,
	businessId: number,
	comment: string,
	createdAt: string,
	flagReason: any // TODO:
	gatewayResponse: string,
	requestReference: string,
	gatewayResponseCode: string,
	id: number,
	isFlagged: boolean
	lastFailureResponse: any // TODO:
	receiverBankCode: string,
	receiverBvn: string,
	receiverClientId: string,
	receiverName: string,
	receiverNuban: string,
	receiverSavingsId: string,
	reference: string,
	retryCount: number,
	status: string,
	updatedAt: string,
	userId: number,
	check: boolean,
	destinationBank: string,
	fromAccountNo: string,
}

export interface IPayments {
	accountId: number,
	amount: number,
	businessId: number,
	comment: string,
	createdAt: string,
	flagReason: any // TODO:
	gatewayResponse: string,
	requestReference: string,
	gatewayResponseCode: string,
	id: number,
	isFlagged: boolean
	lastFailureResponse: any // TODO:
	receiverBankCode: string,
	receiverBvn: string,
	receiverClientId: string,
	receiverName: string,
	receiverNuban: string,
	receiverSavingsId: string,
	reference: string,
	retryCount: number,
	status: string,
	updatedAt: string,
	userId: number,
}

export interface ModalViewComponent {
	FilterResult: any,
	PaymentDetails: any,
}

export interface ViewComponentProps {
	Bulkpayment: any;
	BatchPayment: any;
}

export const BulkPayment: React.FC = () => {
	/**
	 * Hooks initiation region
	 */
	const history = useHistory();
	const dispatch = useDispatch();

	/**
	 * Redux store initiation region
	 */
	const activeCompany = useSelector(
		(state: { company: CompanyInterface }) => state.company.active,
	);
	const companies = useSelector(
		(state: { company: CompanyInterface }) => state.company.list,
	);

	/**
	 * Local state initiation region
	 */
	const [currentCompanyId, setCurrentCompanyId] = useState<string>('');
	const [paymentAccounts, setPaymentAccounts] = useState<
		Array<PaymementAccountInterface & { isChecked: boolean }>>();
	const [payments, setPayments] = useState<Array<BatchHistory>>([]);
	const route = useHistory();
	const [empty, setEmpty] = useState<boolean>(false);
	const [bottomLoading, setBottomLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [showmodalView, setshowmodalView] = useState<boolean>(false);
	const [currentView, setcurrentView] = useState<string>('');
	const [CurrentUserView, ChangeuserView] = useState<string>('');
	const [currentPaymentDetails, setcurrentPaymentDetails] = useState<BatchHistory>();
	const [selectedPayment, setselectedPayment] = useState<Array<BatchHistory>>([]);
	const [year, setYear] = useState<number>();
	const [text, setText] = useState<string>('');
	const [pagination, setPagination] = useState<IPagination>({
		limit: 50, currentPage: 1, pageItems: 1, totalPages: 1,
	});
	const [batchPagination, setBatchPagination] = useState<IPagination>({
		limit: 50, currentPage: 1, pageItems: 1, totalPages: 1,
	});
	const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});
	const [batchAdditionalQueries, setBatchAddtionalQueries] = useState<IGetQueries>({});
	const [batchPayment, setBatchPayment] = useState()
	const [batchId, setbatchId] = useState<string>()
	const [checkAll, setCheckAll] = useState<boolean>(false)

	useEffect(() => {
		PaginationActions.reset();
	}, []);


	const [view, setView] = useState(false)

	/**
	 * Custom handlers initiation region
	 */


	const getPayments = async (queriesData?: IGetQueries): Promise<void> => {
		try {
			if (!queriesData?.search) setLoading(true);
			const queries = queriesData && queriesData.search ? { ...queriesData } : {
				page: 1,
				limit: 50,
				...additionalQueries,
				...queriesData,
			};

			if (!queriesData?.search) {
				delete queries.search;
				setText('');
			}

			const result = await paymentsService.getBatchPayment(getQueryString(queries));
			setAddtionalQueries(queries);
			setLoading(false);
			const paymentdata = result.data.map((el: IPayments) => ({ ...el, check: false }));
			setPagination({
				limit: result.meta.limit, currentPage: result.meta.page, pageItems: result.meta.pageCount, totalPages: result.meta.totalPages,
			});
			setPayments(paymentdata);
		}

		catch (err: any) {
			debug.error('Error retrieving payments', err);
			failed('Failed', err.message || 'Error retrieving payments');
		}

	};

	const getPaymentAccounts = async (id: string): Promise<void> => {
		try {
			const result = await paymentsService.getPaymentAccounts(id);
			setPaymentAccounts(result.map((item) => ({ ...item, isChecked: false })));
		} catch (err) {
			debug.error('Error while getting payment accounts on dashboard', err);
		}
	};

	const getCompanyFromUrl = (location: Location | any) => {
		const companyId = location.search.split(separator)[1];
		if (companyId) {
			const company = companies.find((item) => item.id === companyId);
			setCurrentCompanyId(company?.id || '');
		}
	};

	const UserViews: ViewComponentProps = {
		BatchPayment: <IndividualPayment />,
		Bulkpayment: <BulkPayment />,
	};

	const UserModalView = currentView as keyof typeof ModalView;
	useEffect(() => {
		if (activeCompany) {
			getPaymentAccounts(currentCompanyId || activeCompany.id);
		}
	}, [currentCompanyId]);
	const ModalView: ModalViewComponent = {
		FilterResult: <FilterResult action={getPayments} showModal={setshowmodalView} />,
		PaymentDetails: <IndividualPayment />,
	};

	const handleSearchText = (value: string) => {
		setText(value);
		if (!value.length) {
			getPayments({ page: 1 });
		}
	};



	const searchPayments = async () => {
		if (text) {
			setAddtionalQueries({ search: text, page: 1 });
			await getPayments({ search: text, page: 1 });
		}
	};
	useEffect(() => {
		let delayDebounceFn: any = null;
		if (text.length) {
			delayDebounceFn = setTimeout(() => {
				getPayments({ search: text, page: 1 });
			}, 500);
		}
		return () => clearTimeout(delayDebounceFn);
	}, [text]);

	const changeView = (id?: string, checkAlll = false) => {
		setCheckAll(checkAlll)
		// setBatchPagination({
		// 	limit: 50, currentPage: 1, pageItems: 1, totalPages: 1,
		// });
		if (!view) {
			setbatchId(id)
			setView(true)
		} else {
			setView(false)
		}

	}

	useEffect(() => {
		if (view) getBatchDetails({ page: 1 })

	}, [batchId, view])

	// pass this function to inidividual payment 
	const getBatchDetails = useCallback(async (queriesData?: IGetQueries) => {
		try {
			if (batchId) {
				if (!queriesData?.search) setLoading(true);

				const queries = queriesData && queriesData.search ? { ...queriesData } : {
					page: 1,
					limit: 50,
					...batchAdditionalQueries,
					...queriesData,
				};

				if (!queriesData?.search) {
					delete queries.search;
					setText('');
				}

				const result = await paymentsService.getBatchId(batchId, getQueryString(queries));
				setBatchAddtionalQueries(queries);
				setLoading(false);
				const paymentdata = result.data.map((el: IPayments) => ({ ...el, check: false }));

				setBatchPagination({

					limit: result.meta.limit, currentPage: result.meta.page, pageItems: result.meta.pageCount, totalPages: result.meta.totalPages,

				});

				setBatchPayment(paymentdata);
			}


		} catch (err: any) {
			debug.error('Error retrieving payments', err);
			failed('Failed', err.message || 'Error retrieving payments');
		}
	}, [batchPayment, batchId, batchPagination])


	const handleBatchSearchText = (value: string) => {
		setText(value);
		if (!value.length) {
			getBatchDetails({ page: 1 });
		}
	};

	const searchBatchPayments = async () => {
		if (text) {
			setBatchAddtionalQueries({ search: text, page: 1 });
			await getBatchDetails({ search: text, page: 1 });
		}
	};

	useEffect(() => {
		let delayDebounceFn: any = null;

		if (text.length) {
			delayDebounceFn = setTimeout(() => {
				getBatchDetails({ search: text, page: 1 });
			}, 500);
		}
		return () => clearTimeout(delayDebounceFn);
	}, [text]);

	const renderTableItem = () => (

		payments && !view
			? (
				<>
					<AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]} isPage>
						<S.TopWrapper>

							<S.TableConatiner>
								{/* <YearDropList text={!year ? 'Year' : year.toString()} onChange={getYearFilter} /> */}

								<S.TableIIConatiner>
									<SearchInput onChange={handleSearchText} value={text} onSubmitSearch={searchPayments} placeholder="Search for using account or reference." />

									<S.MarginLeftComp onClick={() => FilterResultHandler()}>
										<Icon name="settings" color={colors.pink._100} width={12} height={12} />
									</S.MarginLeftComp>

								</S.TableIIConatiner>

							</S.TableConatiner>

						</S.TopWrapper>

						<S.TableContainer loading={loading}>
							<Table
								header={{
									cells: {
										0: {
											align: 'left',
											children: (
												<AccessControl
													requiredPermission={[EPermissions.APPROVE_PAYMENT]}
												>
													<CheckBox
														disabled
													/>
												</AccessControl>
											),
											padding: '1.25rem 1.5rem',
										},
										1: {
											align: 'left',
											children: <Text size={7} bold>BATCH NAME</Text>,
											padding: '1.25rem 1.5rem',
										},
										2: {
											align: 'left',
											children: <Text size={7} bold>BENEFICIARY ACCOUNT</Text>,
											padding: '1.25rem 1.5rem',
										},
										3: {
											align: 'left',
											children: <Text size={7} bold>DATE</Text>,
											padding: '1.25rem 1.5rem',
										},
										4: {
											align: 'left',
											children: <Text size={7} bold>TOTAL AMOUNT</Text>,
											padding: '1.25rem 1.5rem',
										},
										5: {
											align: 'right',
											children: <Text size={7} bold>BATCH ID</Text>,
											padding: '1.25rem 1.5rem',
										},
										6: {
											align: 'right',
											children: (
												<Text size={7} bold>
													DATE UPDATED
												</Text>
											),
											padding: '1.25rem 1.5rem',
										},
										7: {
											align: 'right',
											children: <Text size={7} bold> </Text>,
											padding: '1.25rem 1.5rem',
										},
									},
									padding: '1.25rem 0',
								}}
								footer={((): TableFooterProps | undefined => {
									if (bottomLoading) {
										return {
											backgroundColor: colors.white._100,
											cells: {
												0: {
													align: 'center',
													children: <Icon name="small-loading" color={colors.black._100} />,
													padding: '1.25rem 1.5rem',
													colspan: 6,
												},
											},
										};
									}
									if (empty) {
										return {
											backgroundColor: colors.white._100,
											cells: {
												0: {
													align: 'center',
													children: <Text>Result not found</Text>,
													padding: '1.25rem 1.5rem',
													colspan: 6,
												},
											},
										};
									}
									return undefined;
								})()}
								rows={payments?.map((el) => ({
									padding: '1.25rem 0',
									border: {
										color: colors.black._10,
										width: 1,
										style: 'solid',
										sides: ['top'],
									},
									cells: {
										0: {
											align: 'left',
											children: (
												<AccessControl
													requiredPermission={[EPermissions.APPROVE_PAYMENT]}
												>
													<CheckBox
														checked={el.check || false}
														onChange={(isChecked) => {

															if (isChecked) {
																changeView(el.id, true)
															} else {
																setCheckAll(false)
															}
															setPayments((old) => old.map((pay) => {
																if (pay.id === el.id) {
																	pay.check = isChecked;
																}
																if (isChecked && pay.id !== el.id) {
																	pay.check = false
																}

																return pay;
															}));
														}}
													/>
												</AccessControl>
											),
											padding: '1.25rem 1.5rem',
											display: 'inline-block',
										},
										1: {
											align: 'left',
											children: (
												<S.Column>
													<Text size={7}>
														{el.name}
													</Text>
												</S.Column>
											),
											padding: '1.25rem 1.5rem',
										},
										2: {
											align: 'left',
											children: <Text size={6}>{el.senderAccount}</Text>,
											padding: '1.25rem 1.5rem',
										},
										3: {
											align: 'left',
											children: <Text size={6}>{el.createdAt && timeDateMonthString(new Date(el.createdAt))}</Text>,
											padding: '1.25rem 1.5rem',
										},
										4: {
											align: 'left',
											children: <Text size={6}>{`₦ ${formatCash(el.totalPayment)}`}</Text>,
											padding: '1.25rem 1.5rem',
										},
										5: {
											align: 'right',
											children: <Text size={6} color="#333754">{el.id}</Text>,
											padding: '1.25rem 1.5rem',
										},
										6: {
											align: 'left',
											children: <Text size={6}>{el.updatedAt && timeDateMonthString(new Date(el.updatedAt))}</Text>,
											padding: '1.25rem 1.5rem',
										},
										7: {
											align: 'right',
											//onClick: () => setcurrentPaymentdetails(el),
											// onClick: () => setcurrentPaymentdetails(el) ,
											onClick: () => changeView(el.id, false),
											children: <Text size={6} color="#333754">View batch</Text>,
											padding: '1.25rem 1.5rem',
										},
									},
								}))}

							/>
							{payments.length > 0 && <Pagination {...pagination} action={getPayments} />}
						</S.TableContainer>
					</AccessControl>
				</>
			)
			: <IndividualPayment getBatchDetails={getBatchDetails} batchPayments={batchPayment} showBackButton={true} backAction={changeView} checkAll={checkAll} batchPagination={batchPagination} />
	);

	const renderModalView = () => (
		<ModalWrapper
			overflowY="auto"
			overflowX="inherit"
			maxHeight="96vh"
			showModal={showmodalView}
			onCloseModal={() => setshowmodalView(false)}
			closeBackground={UserModalView === 'PaymentDetails' ? colors.pink._20 : ''}
			crossColor={colors.black._100}
		>
			{
				ModalView[UserModalView]
			}
		</ModalWrapper>
	);

	const setcurrentPaymentdetails = (details: BatchHistory) => {
		// const myArray = [];
		// myArray.push(details);
		setcurrentPaymentDetails(details);
		setcurrentView('PaymentDetails');
		setshowmodalView(true);
	};
	const toggleItemInArray = (
		identifier: any,
		bucketArray: any[] = [],
	) => {
		const uniqueKey = identifier.id;
		if (identifier === 'empty') {
			bucketArray = [];
			return;
		}
		const check = bucketArray.findIndex((wizard) => wizard.id === uniqueKey);


		if (check !== -1) {

			bucketArray.splice(check, 1);

		} else {

			bucketArray[bucketArray.length] = identifier;

		}


		setselectedPayment(bucketArray);

	};

	const FilterResultHandler = () => {

		setcurrentView('FilterResult');

		setshowmodalView(true);

		const queries = additionalQueries;

		delete queries.to;

		delete queries.from;

		setAddtionalQueries(queries);

	};


	/**
  
	 * Listeners region
  
	 */

	history.listen((location) => {
		getCompanyFromUrl(location);
	});

	/**
	 * useEffect region 
	 */

	// useEffect(() => {
	// 	if (!companies?.length) {
	// 		dispatch(CompanyActions.getCompaniesList());
	// 	}


	// 	if (!activeCompany) {
	// 		dispatch(CompanyActions.getActiveCompany());
	// 	} else {
	// 		setCurrentCompanyId(activeCompany.id);
	// 	}

	// 	if (companies?.length && activeCompany) {
	// 		getPayments();
	// 		getCompanyFromUrl(history.location);
	// 	}
	// }, [companies, activeCompany]);

	useEffect(() => {
		getPayments();
	}, [])


	return (
		<S.Container>
			{renderModalView()}

			{loading ? (
				<S.PreloadContainer>
					<Preload />
				</S.PreloadContainer>
			)
				: (
					renderTableItem()
				)}
		</S.Container>
	);
};