export interface ErrorApiInterface {
  message: string;
  code: string;
}

export default class ErrorApi extends Error {
  public code: string;

  public static get ServerError(): ErrorApiInterface {
    return {
      message: 'Server error, please try again',
      code: 'SERVER_ERROR',
    };
  }

  constructor(error: ErrorApiInterface) {
    super(error.message);
    this.code = error.code;
  }

  public static JwtError(): ErrorApiInterface {
    return {
      message: 'Your session has expired, please sign in again',
      code: 'TIMEOUT',
    };
  }

  public static TestError(): ErrorApiInterface {
    return {
      message: 'Your session has expired !@@$%^&*())(*(&(dsdksjdsjdsjd))), please sign in again',
      code: 'TIMEOUT',
    };
  }

  public static throwError(error: ErrorApiInterface): void {
    // TODO sentry
    throw new ErrorApi(error);
  }
}
