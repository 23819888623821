import styled from 'styled-components';

import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const PADDING_LARGE = ptr(64);
const PADDING_SMALL = ptr(40);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${PADDING_LARGE};
  background-color: ${colors.orange._100};
  width: ${ptr(640)};
  box-sizing: border-box;
  @media (max-width: ${ptr(740)}) {
    width: auto;
    padding: ${PADDING_SMALL};
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${ptr(80)};
  width: ${ptr(80)};
  border-radius: 100%;
  background-color: ${colors.white._100};
  margin-bottom: ${ptr(16)};
`;
const TitleWrapper = styled.div`
  margin-bottom: ${ptr(4)};
`;

export {
  Wrapper,
  IconWrapper,
  TitleWrapper,
};
