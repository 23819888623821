import { IPreference } from "../redux/preference/IPreference";

export type Money = string | number | undefined;

/**
 * NOTE: Should be reviewed!
 *
 * Format numeric string from '123456' to '123,456'
 * @param _value - money value <string | number | undefined>
 * @returns
 */
export const formatCash = (_value: Money): string => {
  const value: string = `${_value}`.replace(/[\s,₦%]/g, "");
  if (!value || Number.isNaN(+value)) {
    return "0";
  }
  return `${Number(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getWithdrawalFee = ({
  amount = 0,
  feeArray,
}: {
  amount: number;
  feeArray: IPreference["withdrawalFee"];
}): number | undefined => {
  if (feeArray && feeArray.length > 0) {
    const newFeeArray = [...feeArray];

    for (let fee of newFeeArray) {
      let result = 0;
      if (amount >= fee.lowerBoundary && amount <= fee.upperBoundary) {
        result = getFee({ vat: fee.vat, fee: fee.fee });
        return result;
      }
    }
  }
};

export const getFee = (data: { vat: number; fee: number }): number => {
  if (!data) return 0;
  const vat = data.vat / 100;
  return vat * data.fee + data.fee;
};
