import React, { useEffect } from "react";
import { Route, useLocation, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { OnboardingActions } from "../../redux/onboarding/actions";
import { OnboardingInterface } from "../../redux/onboarding/IOnboarding";

import { WelcomeSlider } from "../../components/WelcomeSlider";
import { Icon } from "../../components/Icon";
import { WarningModal } from "../../components/WarningModal";

import { TwoSide, Columns } from "../../styles/wrappers";
import { colors } from "../../styles/variables";
import * as S from "../onboardingContainer/styles";
import SelectOperationType from "../../pages/Onboard/common.operator.switcher";
import ApproverRoles from "../../pages/Onboard/common.other.approver-operators";
import NonApproverRoles from "../../pages/Onboard/common.other.non-approver-operators";
import { CommonApprovalLimit } from "../../pages/Onboarding/steps/common.approval.limit";

export const setOperatorsRoute = "/set-operators",
  setOperatorsApproverRoute = "/set-operators/role",
  setOperatorsNonApproverRoute = "/set-operators/non-approval-role",
  setOperatorsLimitRoute = "/set-operators/approval-limit";

const SetOperators: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [image, setImage] = React.useState("");
  const [routeImage] = React.useState<{ [key: string]: string }>({
    "/set-operators": "/images/onboarding/3.jpg",
    "/set-operators/role": "/images/onboarding/3.jpg",
    "/set-operators/non-approval-role": "/images/onboarding/3.jpg",
    "/set-operators/approval-limit": "/images/onboarding/2.jpg",
  });

  useEffect(() => {
    setImage(routeImage[location.pathname]);
  }, [location.pathname]);

  const onboarding = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack
  );

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <TwoSide.Wrapper inApp={onboarding.inApp}>
        {!onboarding.inApp && (
          <TwoSide.Left>
            <WelcomeSlider image={image} />
          </TwoSide.Left>
        )}
        <TwoSide.Right inApp={onboarding.inApp}>
          <S.BackContainer inApp={onboarding.inApp}>
            <S.Back onClick={handleBack}>
              <Icon name="arrow-left" color={colors.pink._100} />
            </S.Back>
          </S.BackContainer>
          <Columns.Wrapper>
            <Columns.ContentWrapper />
            <Columns.ContentWrapper>
              {/*
               * Rendering the warning modal if a step got an API error
               */}
              {!!onboarding.error && (
                <WarningModal
                  title="Something went wrong!"
                  description={onboarding.error}
                  showModal={!!onboarding.error}
                  onCloseModal={() => {
                    dispatch(OnboardingActions.handleSetError(""));
                  }}
                />
              )}
              {/*
               * Rendering a step component
               */}

              <Route path={setOperatorsRoute} exact>
                <SelectOperationType />
              </Route>
              <Route path={setOperatorsApproverRoute} exact>
                <ApproverRoles />
              </Route>

              <Route path={setOperatorsNonApproverRoute} exact>
                <NonApproverRoles />
              </Route>

              <Route path={setOperatorsLimitRoute} exact>
                <CommonApprovalLimit />
              </Route>

              <Redirect
                from="**"
                to="/set-operators"
                exact
              />
            </Columns.ContentWrapper>
            <Columns.ContentWrapper />
          </Columns.Wrapper>
        </TwoSide.Right>
      </TwoSide.Wrapper>
    </>
  );
};

export default SetOperators;
