import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Text } from '../../../../../../components/Text';
import { colors } from '../../../../../../styles/variables';
import { DropdownOptionProps } from '../../../../../../components/DropdownInput';
import { useSelector } from 'react-redux';
import { OnboardingInterface } from '../../../../../../redux/onboarding/IOnboarding';
import { LeaderDirectorShareholderInterface } from '../../../../../../services/internal/onboarding/IOnboarding';
import { Table } from '../../../../../../components/Table';
import { Icon } from '../../../../../../components/Icon';
import { UploadModal } from '../../../../../../components/UploadModal';
import { Details } from '../../../../../../utils/onboardModalDetails';
import { IUploadData } from '../..';
import Tooltip from '../../../../../../components/Tooltip';
import { Table2 } from '../../../../../../components/Table2';

type DirectorsList = Array<DropdownOptionProps>;

export interface CheckModalPaymentData {
  name?: string;
  status?: string;
  action?: string;
}

export const ProprietorUploads: React.FC<{
  uploadData: IUploadData[];
  setUploadData: (data: IUploadData[]) => void;
}> = ({ uploadData, setUploadData }) => {
  /**
   * Redux store initiation region
   */
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<
        LeaderDirectorShareholderInterface
      >
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );
  /**
   * local state
   */
  const [directors, setDirectors] = useState<DirectorsList>([]);

  const covertDataForTable = (data: CheckModalPaymentData) => {
    const keys = Object.entries(data);
    const result = keys.map((keyValue) => {
      const key = keyValue[0];
      const value = keyValue[1];
      switch (key) {
        case 'amount':
          return {
            title: 'Amount',
            description: `₦ ${value}`,
          };
        case 'paymentAccount':
          return {
            title: 'Payment Account',
            description: value,
          };
        case 'destinationAccNo':
          return {
            title: 'Destination Acc. No',
            description: value,
          };
        case 'receiverName':
          return {
            title: 'Receiver Name',
            description: value,
          };
        default:
          return {
            title: key,
            description: value,
          };
      }
    });
    return result;
  };

  /**
   * useEffect Region
   */
  useEffect(() => {
    if (leaders && leaders.length) {
      setDirectors(
        leaders.map((item, index) => ({
          label: item.email,
          value: item.email,
          category: 'Directors',
          subLabel: item.email,
          key: index.toString(),
        }))
      );
    }
  }, [leaders]);

  const [details, setDetails] = useState<Details | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [docType, setDocType] = useState<IUploadData>();

  const handleOnboardingClick = (type: IUploadData) => {
    // const actionDetails = getOnboardModalDetail(action);
    // console.log(actionDetails);
    // setDetails(actionDetails);
    setDocType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setDetails(null);
    setShowModal(false);
  };

  const [modalVisible, seModalVisible] = useState<boolean>(false);

  const tableRef = useRef<any>();

  const onCloseModal = () => {
    setShowModal(false);
  };

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.businessType as string
  );

  const removeUpload = (item: IUploadData) => {
    const newData = uploadData.map((data) => {
      if (data.label === item.label) {
        data.file = '';
        delete data.email;
      }
      return data;
    });
    setUploadData(newData);
  };

  return (
    <>
      {docType && (
        <UploadModal
          reference={reference}
          type={docType}
          visible={showModal}
          onClose={onCloseModal}
          setUploadData={setUploadData}
          uploadData={uploadData}
        />
      )}
      <Table2
        ref={tableRef}
        headerStyle={{
          padding: '0px',
          color: '#667085',
          fontWeight: 500,
          fontSize: 12,
        }}
        style='white'
        header={{
          cells: {
            0: {
              align: 'left',
              children: (
                <Text size={7} bold>
                  Document type
                </Text>
              ),
              padding: '1rem 1rem 1rem 1rem',
            },
            1: {
              align: 'left',
              children: (
                <Text size={7} bold>
                  Upload status
                </Text>
              ),
              padding: '1rem 4rem 1rem 0rem',
            },
            2: {
              align: 'left',
              children: (
                <Text size={7} bold>
                  Action
                </Text>
              ),
              padding: '1rem 1rem 1rem 0rem',
            },
          },
          // padding: '1.25rem 0',
        }}
        rows={uploadData?.map((el, index) => ({
          padding: '1.25rem 0',
          border: {
            color: colors.black._10,
            width: 1,
            style: 'solid',
            sides: ['top'],
          },
          cells: {
            0: {
              align: 'left',

              children: (
                <div>
                  <Text
                    style={{
                      width: '100%',
                    }}
                    size={7}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', position:'relative' }}>
                      {el.label}
                      {el.info && <Tooltip
                        style={{ top: '-40px' }}
                        list={el.info}
                        infoType={el.infoType}
                        reff={tableRef}
                      />}
                    </div>
                  </Text>
                  {el.otherInfo && el.otherInfo.length && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 15,
                      }}
                    >
                      <Text size={6} color='#6E7191'>
                        The document should contain
                      </Text>
                      <ol
                        style={{
                          listStyle: 'initial',
                          listStyleType: 'number',
                          marginTop: 15,
                          marginLeft: 15,
                        }}
                      >
                        {el.otherInfo.map((otherIn, i) => (
                          <li
                            style={{
                              color: '#6E7191',
                            }}
                            key={i + otherIn}
                          >
                            <Text size={6} color='#6E7191'>
                              {otherIn}
                            </Text>
                          </li>
                        ))}
                      </ol>
                    </div>
                  )}
                </div>
              ),
              padding: '1rem 2rem 1rem 1rem',
            },
            1: {
              align: 'left',

              children: (
                <Text
                  style={{
                    width: '100%',
                    ...(el.file
                      ? {
                          color: colors.green._100,
                        }
                      : {}),
                  }}
                  size={6}
                >
                  {el.file ? 'Uploaded' : 'Pending'}
                </Text>
              ),
              padding: '1rem 6rem 1rem 0rem',
            },
            2: {
              align: 'left',
              children: (
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    cursor: 'pointer',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Text
                    style={{
                      width: '100%',
                    }}
                    size={6}
                  >
                    {el.file?.name || el.file?.path
                      ? `${(el.file?.name || el.file.path)
                          ?.split('.')[0]
                          .substring(0, 14)}${
                          (el.file?.name || el.file.path)?.length > 14
                            ? '...'
                            : ''
                        }.${(el.file?.name || el.file?.path)?.split('.')[1]}`
                      : `Upload document`}
                  </Text>
                  {el.file ? (
                    <Icon
                      name='trash'
                      color={colors.red._100}
                      onClick={() => removeUpload(el)}
                    />
                  ) : (
                    <Icon
                      name='upload'
                      onClick={() => handleOnboardingClick(el)}
                    />
                  )}
                </div>
              ),
              padding: '1rem 2rem 1rem 0rem',
            },
          },
        }))}
      />
    </>
  );
};
