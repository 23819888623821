import styled, { css } from 'styled-components';
import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.black._10};
  padding: ${ptr(40)} 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${ptr(20)};
`;
const InputsRow = styled.div`
  margin-bottom: ${ptr(12)};
  display: flex;
 
  @media screen and (min-device-width: 280px) and (max-device-width: 420px){
  
     flex-direction: column;
      width: 100%;
     row-gap:10px; 
  }
  
`;
const InputWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1;  
  ${(props) => props.first && css`
    margin-right: ${ptr(12)};
    
  `}
`;
const InputDescription = styled.div`
  margin-top: ${ptr(8)};
`;
const SwitchWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: ${ptr(6)};
`;

const BeneficiaryContainer = styled.div`
  margin-top: ${ptr(32)};  

  > span {
    display: block;
    margin-bottom: ${ptr(12)}; 
  }

  > div {   
  }
`;

export {
  Wrapper,
  TitleWrapper,
  InputsRow,
  InputWrapper,
  InputDescription,
  SwitchWrapper,
  BeneficiaryContainer,
};
