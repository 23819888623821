import React from 'react';

import * as S from './styles';
import { getStringHashCode } from '../../utils/strings';
import { DATA } from './data';

interface PositionOptions {
  left?: string,
  right?: string,
  top?: string,
  bottom?: string,
}

export interface CircleOptions {
  radius: number,
  rotate: number,
  position: PositionOptions,
}

export interface CircleDecoratorsProps {
  /**
   * If string - pseudo-random circles and positions.
   * Every time one result with the same string.
   */
  circles: Array<CircleOptions> | string,
  /**
   * Children
   */
  children?: JSX.Element,
}

export const CircleDecorators: React.FC<CircleDecoratorsProps> = ({
  circles,
  children,
}) => {
  if (typeof circles === 'string') {
    // get hash of the string (number like 987690174)
    const hash = getStringHashCode(circles);
    // get numbers from string
    const hashNumbers = Math.abs(hash).toString().split('').map((item) => parseInt(item, 10));
    // first Number of the hash MINUS last number of the hash
    const circlesVariant = Math.abs(hashNumbers[0] - hashNumbers[hashNumbers.length - 1]);

    return (
      <S.Wrapper>
        {DATA[circlesVariant - 1].map((circleItem, index) => (
          <S.Circle {...circleItem} key={`${+index}-circle`} />
        ))}
        {children || null}
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      {circles.map((circleItem, index) => (
        <S.Circle {...circleItem} key={`${+index}-circle`} />
      ))}
      {children || null}
    </S.Wrapper>
  );
};
