import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Wrapper = styled.div`
  padding: ${ptr(24)} ${ptr(64)};
  box-sizing: border-box;
  width: 34.445vw;
 margin-left: auto;
    margin-right: auto;
 button{
    margin-left: auto;
    margin-right: auto;
    /* display: block; */
    margin-top: ${ptr(40)};
    margin-bottom: ${ptr(40)};
 }
  @media (max-width: ${breakpoints.md}px) {
    width: 90vw;
  }
`;

const TextContainer = styled.div`
text-align: center;
margin-top: ${ptr(20)};
`;

const ModalButton = styled.div`
    button{
        @media (max-width: ${breakpoints.xl}px) {
    width: 100%;
  }
    }
`;

const ModalPromptButtons = styled.div`
display: flex;
justify-content: space-between;
button {
  width: 45%
}
`;

export {
  Wrapper, TextContainer, ModalButton, ModalPromptButtons,
};
