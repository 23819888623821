import React from 'react';
import { colors } from '../../styles/variables';

import { Icon, IconName } from '../Icon';
import * as S from './styles';

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  theme?: 'primary' | 'secondary' | 'light' | 'info';

  color?: string;
  /**
   * What background color to use (ignore theme backgroundColor)
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'tiny' | 'medium' | 'large';
  /**
   * How large should the button be?
   */
  fontSize?: number;
  /**
   * Button contents
   */
  label?: string | JSX.Element;
  /**
   * Button is disabled
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Set button loading state
   */
  loading?: boolean;
  righIcon?: IconName['name'];
  leftIcon?: IconName['name'];
  iconColor?: string;
  style?: React.CSSProperties;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  backgroundColor,
  theme = 'primary',
  size = 'medium',
  ...props
}) => (
  <S.StyledButton
    type="button"
    theme={theme}
    style={{ backgroundColor, ...props.style }}
    size={size}
    {...props}
  >
    {props.leftIcon && <Icon name={props.leftIcon} color={props.iconColor} />}
    {props.loading ? 'Please wait..' : props.label}
    {props.righIcon && (
      <Icon
        name={props.righIcon}
        color={
          theme === 'info'
            ? props.iconColor ?? colors.black._100
            : props.iconColor ?? undefined
        }
      />
    )}
  </S.StyledButton>
);

export const Button = React.memo(ButtonComponent);
