import styled, { css } from 'styled-components';
import { ptr } from '../../../../styles/utils';
import { colors, fonts } from '../../../../styles/variables';


const Wrapper = styled.div<any>`
`;

const Slider = styled.div`
margin-top:15px;

input[type='range'] {
	-webkit-appearance: none;
    width:100%;
	height: 7px;
	background: #EBEBEB;
	border-radius: 5px;
	background-image: linear-gradient(${colors.pink._100}, ${colors.pink._100});
	background-repeat: no-repeat;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
    }

    &::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
    }
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 32px;
width: 32px; 
border-radius: 8px;
padding: 6px;
background-image:url(/images/onboarding/slider.jpg); 
	cursor: pointer; 
}

input[type='range']::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

`;

const Buble = styled.div`
margin-top:30px;
flex-direction: row;
display:flex;
align-items: center;
border-radius: ${ptr(6)};
background-color: ${colors.black._5};
position: relative;
cursor: pointer;
padding: 0 ${ptr(20)};
height: ${ptr(50)};  
color: ${colors.black._100};
font-size: 16px;
font-weight: 700; 
font-family: ${fonts.inter.regular};

:hover {
  opacity: 0.8;
}
@media screen and (min-device-width: 280px) and (max-device-width: 420px){    
  margin-right:40px;
  width: 91%;
}
`;

export {
    Slider,
    Buble,
  Wrapper,
};
