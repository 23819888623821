import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Container = styled.div``;

const Head = styled.div`
  display: flex;
  flex-direction:row;
  align-items: center;
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};
gap:20px;
  flex-wrap:wrap;
  width: 100%;
justify-content:space-between;
`;

const HeaderText = styled.div`
display: flex;
width: 100%;

// flex-wrap:wrap;
 
// justify-content:space-between;

gap:6px;
flex-direction:column;
`;


const HeaderTextTop = styled.div`
display: flex;
gap:16px; 
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  flex: 1;
  justify-content: flex-end;
  padding: 0;

  > svg {
    margin-left: ${ptr(14)};
  }
`;

const Input = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
 
  justify-content: flex-end;
  padding: 0;

  > svg {
    margin-left: ${ptr(14)};
  }
`;

const TabNav = styled.div`
 
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; 
     white-space:nowrap;
    
  > a {
    text-decoration: none;

    margin-bottom:10px; 
  }

  > :not(:last-child) {
    margin-right: ${ptr(48)};
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
 /*  display: none; Chrome, Safari and Opera */
}

::-webkit-scrollbar {
  width: 10px;
  background:#C5CAD7;
  height:12px;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  background:#FCFDFF;
} 


`;

const Wrapper = styled.div`
  display: flex;
   background:unset;
  flex-wrap:wrap;
  gap:0px;
  justify-content:space-between;

`;

const FirstContainer = styled.div`
//  width:100%;
 display:flex;
 
 
margin-top: ${ptr(16)};
// margin-bottom: ${ptr(12)}; 
justify-content:space-between;
flex-wrap: wrap;

:first-child {
  margin-top: ${ptr(0)};
}

@media (max-width: ${breakpoints.md}px) {
 
:first-child {


>div{ 
width:100%;
display: flex;
flex-direction:row;
}
}
  }
`;

const LeftContainer = styled.div`
   //flex: 1;
  display: flex;
// background:pink; 
   max-width:520px; 


  flex-direction: column;
  gap: ${ptr(32)};


  >:last-child {
    width:506px ;
    // width:595px;
    margin-right:126px !important;
    
  }

  @media (max-width: ${breakpoints.lg}px) {
    
    max-width:100%;

    >:last-child {
      margin-right:0px !important;
      width:92.5%;
      
    }

  }




`;

 

const RigthContainer = styled.div`
  // flex: 0.5;
  max-width:432px; 
  margin-left: ${ptr(32)};
  display: flex;
  flex-direction: column;
  gap: ${ptr(32)};
  //  background:orange;

  >:nth-child(3) { 

    // width:347px !important;
    margin-left:26px !important;
    
    // margin-top:-10px !important;
  }

  @media (max-width: ${breakpoints.lg}px) {
    max-width:100%;

    >:nth-child(3) { 
      width: 100%; 
      margin-left:0px !important;
      }

       >:last-child {
      width:100%;
      
    }
  }
`;

const PreloadContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${ptr(94)} 0;
`;

export {
  Container,FirstContainer, TabNav, Head, Button,Input, Wrapper, LeftContainer, RigthContainer, PreloadContainer, HeaderText, HeaderTextTop,
};
