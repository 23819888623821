import { ActionInterface } from '../IAction';
import { SET, RESET } from './actions';
import { IPaginationSettings } from './Ipagination';

const initialState = {
  paginationLimit: [0, 5],
  count: 1,
};

const reducer = (state = initialState,
  action: ActionInterface<IPaginationSettings>): IPaginationSettings => {
  switch (action.type) {
    case SET:
      if (action.payload) {
        return {
          paginationLimit: action.payload.paginationLimit,
          count: action.payload.count,
        };
      }
      return state;
    case RESET:
      if (action.payload) {
        return {
          paginationLimit: [0, 5],
          count: 1,
        };
      }
      return state;

    default:
      return state;
  }
};

export default reducer;
