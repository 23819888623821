import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { AuthInterface } from '../../redux/authorization/IAuthorization';
import NoAccess from '../NoAccess';

export enum EPermissions {
	CREATE_PAYMENT = 'create-payment',
	VIEW_PAYMENT = 'view-payment',
	UPDATE_PAYMENT = 'update-payment',
	DELETE_PAYMENT = 'delete-payment',
	APPROVE_PAYMENT = 'approve-payment',
	CREATE_OPERATOR = 'create-operator',
	VIEW_OPERATOR = 'view-operator',
	UPDATE_OPERATOR = 'update-operator',
	DELETE_OPERATOR = 'delete-operator',
	APPROVE_OPERATOR = 'approve-operator',
}

interface IAccessControl {
	requiredPermission: Array<string>;
	children: ReactNode;
	isPage?: boolean;
}

const AccessControl: React.FC<IAccessControl> = ({
	requiredPermission,
	children,
	isPage,
}) => {
	const userPermission = useSelector(
		(state: { authorization: AuthInterface }) => state.authorization.permissions
	);

	const permitted = (): boolean => {
		let allow = false;
		if (requiredPermission.length === 0) {
			allow = true;
		}
		requiredPermission.forEach((permission) => {
			if (userPermission.includes(permission)) {
				allow = true;
			}
		});

		return allow;
	};

	const renderContent = () => {
		if (permitted()) {
			return children;
		}
		if (isPage) {
			return <NoAccess />;
		}
		return null;
	};

	return <>{renderContent()}</>;
};

export default AccessControl;
