/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import validator from 'validator';

import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { defaultLeaderDirectorShareholder } from '../../../redux/onboarding/reducer';
import { OnboardingInterface, BusinessType } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { BasicLeaderDirectorShareholderInterface, LeaderDirectorShareholderInterface } from '../../../services/internal/onboarding/IOnboarding';
import debug from '../../../services/internal/debbug.service';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import { SimpleForm, SimpleFormProps } from '../../Onboarding/components/SimpleForm';
import { Modal } from '../../../components/LoadingModal';

import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { errorToString, excludeObjectEntries, objectToFormData } from '../../../services/internal/app/index';
import { getSignatureFormData } from '../../Onboarding/steps/common.api/index';
import { FileUpload } from '../../../components/FileUpload';

type Files = Array<File & { preview: string }>;

const SCHEME = {
  nin: (value: string) => value.length === 11,
  bvn: (value: string) => value.length === 11,
  email: (value: string) => validator.isEmail(value),
  signatures: (data?: File) => typeof data !== 'undefined',
};

const directorDefaultItem = {
  nin: '',
  bvn: '', 
  email: '',
  signatures: undefined,
};

const CommonCompanyEnterManagement: React.FC = () => { 
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();

  /**
   * Redux store initiation region
   */
  const business = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.business as Record<string, string>,
  );
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.directors as Array<LeaderDirectorShareholderInterface>,
  );
  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.businessType as BusinessType,
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.reference as string,
  );

  const route = useHistory();

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<BasicLeaderDirectorShareholderInterface>>(
    leaders?.length
      ? leaders.map((item) => ({
        nin: item.nin,
        bvn: item.bvn,
        email: item.email,
        signatures: item.signatures,
      }))
      : [directorDefaultItem, directorDefaultItem],
  );
  const [validationCollection, setValidationCollection] = useState<ValidateArrayResult>();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const push = () => {
    setCollection([...collection, directorDefaultItem]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection((old) => old.map((el: BasicLeaderDirectorShareholderInterface, i: number) => {
      if (i === index) {
        return {
          ...el,
          [property]: value,
        };
      }
      return el;
    }) as Array<BasicLeaderDirectorShareholderInterface>);
  };

  const continueHandle = () => {
    const formattedCollection = collection.map((item) => {
      const director = { ...defaultLeaderDirectorShareholder };

      delete director.cac;
      director.image = '';
      director.type = director.type || 'company';

      director.isDirector = true;
      director.isShareholder = false;
      director.isSignatory = false;

      director.bvn = item.bvn;
      director.nin = item.nin;
      director.email = item.email;
      director.signatures = item.signatures;

      return director;
    });
    if (businessType === 'hasNotCompany') {
      (async () => {
        setLoading(true);
        try {
          const response = await onboardingService.submitBusinessDirectorInfo(
            await getSignatureFormData(formattedCollection, reference),
          );
          batch(() => {
            dispatch(OnboardingActions.handleRequiredAction(response.actionRequired));
            dispatch(OnboardingActions.handleSetData({
              directors: formattedCollection,
              reference: response.data.reference,
            }));
            dispatch(OnboardingActions.handleSetServerDirectorResponse(response.data.directors));
            dispatch(OnboardingActions.handleNextStep());
          });
          route.push('/onboarding/confirm-director-details', { actionRequired: response.actionRequired });
          debug.info('', response); // TEMPORARY
        } catch (err) {
          dispatch(OnboardingActions.handleSetError(errorToString(err)));
        }
        setLoading(false);
      })();
    } else {
      batch(() => {
        dispatch(OnboardingActions.handleSetData({
          directors: formattedCollection,
        }));
      });
      route.push('/onboarding/operators');
    }
  };
  /**
   * useEffect region
   */
  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  return (
    <S.Container>
      <Title title="Managment details" />

      <S.Wrapper>
        {collection.map((item, index) => (
          <div>
            <SimpleForm
              nin={item.nin}
              bvn={item.bvn}
              email={item.email}
              index={index}
              removeFromIndex={1}
              remove={remove}
              onChange={onChangeValue}
              emailIsValid={validationCollection?.data[index]?.data.email.isValid}
              bvnIsValid={validationCollection?.data[index]?.data.bvn.isValid}
              ninIsValid={validationCollection?.data[index]?.data.nin.isValid}
              title="Director’s details"
            />

            <S.Wrapper>
              <Text color={colors.black._60}>
                Upload JPEG signatures of the director and relevant PSC forms as applicable.
              </Text>
              <S.Block>
                <S.DropzoneContainer>
                  <Text bold color={colors.black._100}>Directors’ signatures</Text>
                  <FileUpload file={item.signatures} acceptedFormats={['image/jpg', 'image/jpeg']} onChange={(file) => onChangeValue(index, 'signatures', file)} />
                  {/* <MultiFileUploader
                    files={[item.signatures] as Files}
                    onChange={(files) => onChangeValue(index, 'signatures', files)}
                    accept="image/jpeg"
                  /> */}
                </S.DropzoneContainer>
              </S.Block>
            </S.Wrapper>
          </div>
        ))}
      </S.Wrapper>

      <S.Add onClick={push}>
        <Icon name="plus-in-circle" color={colors.pink._100} />
        <Text color={colors.pink._100}>
          Add director
        </Text>
      </S.Add>

      <Button
        label="Continue"
        loading={loading}
        disabled={!validationCollection?.isValid || loading}
        onClick={continueHandle}
      />
      <Modal visible={loading} />
    </S.Container>
  );
};
export default CommonCompanyEnterManagement;
