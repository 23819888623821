import React from 'react';
import AccessControl, { EPermissions } from '../../components/AccessControl';

import { BackBar } from '../../components/BackBar';
import { PaymentDetails } from '../../components/PaymentDetails';
import * as S from './styles';

export interface TransferToOwnAccountsProps {
  /**
   * Example page property
   */
  example?: string,
}

export const TransferToOwnAccounts: React.FC<TransferToOwnAccountsProps> = () => (
  <>
    <AccessControl isPage requiredPermission={[EPermissions.CREATE_PAYMENT]}>
      <BackBar name="Transfer to own accounts" />
      <S.Blank />
      <PaymentDetails />
    </AccessControl>
  </>
);
