import debug from '../debbug.service';
import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';
import ErrorBanks from './banks.error';

class BanksService {
  private apiEndpoints = {
    resolve: 'banks/resolve',
    resolveExternal: 'banks/resolve/external',
    banksExternal: 'banks/external/list',
  };

  public async checkResolve(nuban: string, bankCode: string): Promise<void> {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.resolve}?nuban=${nuban}&bankCode=${bankCode}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = await request.json();

      debug.success('Result of check resolve', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to resolve bank', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(
            new ErrorBanks({
              message: result.message,
              code: result.code,
            })
          );
        }

        return Promise.reject(
          new ErrorBanks({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async bankExternalResolve(
    nuban: string,
    bankCode: string
  ): Promise<void> {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.resolveExternal}?nuban=${nuban}&bankCode=${bankCode}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = await request.json();

      debug.success('Result of check resolve', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to resolve bank', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(
            new ErrorBanks({
              message: result.message,
              code: result.code,
            })
          );
        }

        return Promise.reject(
          new ErrorBanks({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getBankNames() {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.banksExternal}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      debug.success('Result of receiving bank names', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get bank names', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorBanks({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorBanks({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }
}

const banksService = new BanksService();
export default banksService;
