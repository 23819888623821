import React, { useState } from 'react';
import { FormTitle } from '../../../../components/FormTitle';
import { OtpInput } from '../../../../components/OtpInput';
import { ResendCode } from '../../../SignUp/components/ResendCode';

import * as S from './styles';

const OTP_CODE_LENGTH = 6;

export interface StepFourthProps {
  /**
   * handler on click continue
   */
  onContinue?: (otpCode: string) => void,
}

export const StepFourth: React.FC<StepFourthProps> = ({ onContinue = () => {} }) => {
  const [showResendCode, setShowResendCode] = useState(0);

  // const sentOTP = async () => {
  //   try {
  //     console.log('OK');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   sentOTP();
  // }, []);

  const onResendCode = () => {
    setShowResendCode(showResendCode + 1);
  };

  const onOtpCodeChanged = (value: string) => {
    if (value.length === OTP_CODE_LENGTH) {
      onContinue(value);
    }
  };

  return (
    <>
      <FormTitle
        title="OTP Code"
        description="We have sent OTP Code to phone number + 28 (23) *** *** 10. Enter this code to verify your account."
      />
      <OtpInput
        codeLength={OTP_CODE_LENGTH}
        onCodeChange={onOtpCodeChanged}
        secure
      />
      <S.ResendCodeWrapper>
        <ResendCode
          duration={10}
          resetTimer={showResendCode}
          onClick={onResendCode}
        />
      </S.ResendCodeWrapper>
    </>
  );
};
