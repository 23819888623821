import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import * as S from './styles';
import { colors } from '../../styles/variables';

export interface CircularProgressbarProps {
  /**
   * Progress bar percentage value
   */
  progress?: number,
  /**
   * Text type
   */
  textType?: 'progress' | 'of',
  /**
   * Big text if textType = 'of'
   */
  bigText?: string,
  /**
   * Small text if textType = 'of'
   */
  smallText?: string,
}

export const CircularProgressbar: React.FC<CircularProgressbarProps> = ({ progress = 0, textType = 'progress', ...props }) => (
  <S.Wrapper>
    <CircularProgressbarWithChildren
      value={progress}
      styles={{
        root: {},
        path: {
          stroke: colors.pink._100,
          strokeLinecap: 'butt',
        },
        trail: {
          stroke: colors.pink._10,
        },
      }}
    >
      <S.TextWrapper>
        {textType === 'progress' && <S.BigText>{progress}</S.BigText>}
        {textType === 'of' && (
        <>
          <S.BigText>
            {props.bigText}
            /
          </S.BigText>
          <S.SmallText>{props.smallText}</S.SmallText>
        </>
        )}
      </S.TextWrapper>
    </CircularProgressbarWithChildren>
  </S.Wrapper>
);
