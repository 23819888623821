import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Wrapper = styled.div`
  padding: ${ptr(64)};
  box-sizing: border-box;
  width: 44.445vw;
  overflow: auto; 
  > span {
    display: block;
    margin-bottom: ${ptr(24)};
  }

  > button {
    width: 100%;
    margin-top: ${ptr(40)};
  }
  ::-webkit-scrollbar {
    display: none;
  }
    -ms-overflow-style: none;
    scrollbar-width: none;

     @media (max-width: ${breakpoints.sm}px) {
    flex: 1;
    width: inherit;
    height: 70vh;
    padding:30px;
    /* overflow: scroll;  */
  }
  @media (max-height: 600px) {
    height: 70vh;
  }
`;

const InputRow = styled.div`
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-bottom: ${ptr(24)};
  }

  > div {
    &:not(:last-child) {
      margin-bottom: ${ptr(12)};
    }
  }
`;

export { Wrapper, InputRow };
