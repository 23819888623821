import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { breakpoints } from '../../../styles/variables';

const Container = styled.div`
  width: 50%;
  button{
    width:100%;
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;
const LoginPasswordInputWrapper = styled.div`
 margin-top: ${ptr(10)};
`;

const InputContainer = styled.div`
  margin-top: ${ptr(50)};
  margin-bottom: ${ptr(30)};
  /* width:335px; */
`;

const PasswordDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${ptr(4)} 0 ${ptr(24)} 0;
  
  > * {
    margin-bottom: ${ptr(8)};
  }
`;

const PasswordDescriptionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > * {
    margin-right: ${ptr(8)};
  }
`;

export {
  LoginPasswordInputWrapper,
  Container,
  InputContainer,
  PasswordDescriptionWrapper,
  PasswordDescriptionItemWrapper,
};
