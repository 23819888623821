export const credentialsActions: { [key: string]: string } = {
  PERSONAL_INFO: "/onboarding/personal-information",
  CREATE_CREDENTIALS: "/onboarding/credentials",
  CREATE_PIN: "/onboarding/credentials/pin",
  CONFIRM_PIN: "/onboarding/credentials/confirm-pin",
  SUBMIT_OTP: "/onboarding/otp",
};

export const businessInfoActions: { [key: string]: string } = {
  SELECT_BUSINESS_TYPE: "/onboarding/business-type",
  COMPANY_DETAILS: "/onboarding/company-details",
  PERSONAL_COMPANY_DETAILS: "/onboarding/personal/business-details",
};

export const otherActions: { [key: string]: string } = {
  CONFIRM_DATA: "/onboarding/check-other-operators",
  SET_APPROVAL_LIMIT: "/onboarding/approval-limit",
  null: "/onboarding/other/completed",
};

export const actions: { [key: string]: string } = {
  SUBMIT_OTP: "/onboarding/otp",
  SUBMIT_PHONE_OTP: "/onboarding/otp/phone",
  SUBMIT_EMAIL_OTP: "/onboarding/otp/email",
  SUBMIT_AUTH: "/onboarding/create-password",
  SUBMIT_BUSINESS_INFO: '/onboarding/company-information',
  SUBMIT_PROFILE_PASSWORD: "/onboarding/credentials",
  // SUBMIT_DIRECTORS: "/onboarding/director-details",
  SUBMIT_SHAREHOLDERS: "/onboarding/shareholder-details",
  // SUBMIT_OPERATORS: "/onboarding/add-operators",
  CONFIRM_DATA: "/onboarding/preview-details",
  SUBMIT_BUSINESS_TYPE: "/onboarding/type-of-business",
  SUBMIT_DIRECTORS: "/onboarding/management-details",
  SUBMIT_PROPRIETORS: "/onboarding/proprietors-details",
  SUBMIT_OPERATORS: "/onboarding/operators",
  SUBMIT_CONSENT_LETTER: "/onboarding/directors",
  SUBMIT_PIN: "/onboarding/credentials/pin",
  SET_APPROVAL_LIMIT: "/onboarding/approval-limit",
  SUBMIT_TRUSTEES: "/onboarding/management-details",
  SUBMIT_PARTNERS: "/onboarding/management-details",
  SUBMIT_SIGNATORIES: "/onboarding/management-details",
  SUBMIT_UPLOADED_FILES: "/onboarding/upload-documents",
  SUBMIT_REFERENCES: "/onboarding/reference-information",
  SUBMIT_UBO: "/onboarding/ubo",
  SUBMIT_BVN_CONSENT: '/onboarding/verify-bvn',
  INITIATE_SIGNUP: '/onboarding/signup/personal-information',
  null: "/onboarding/profile/completed",
};
