import styled from 'styled-components';

import { ptr } from '../../styles/utils';

import { breakpoints } from '../../styles/variables';

const Container = styled.div`
svg{
  margin-left: 10px;
}
cursor: pointer;
`;

const ButtonWidthContainer = styled.div`
button{
  width: 100%;
}
`;

const ResponsiveContainer = styled.div`
width: 55%;
`;

const IconWrapper = styled.div`
margin-top: 40px;
margin-bottom: 10px;
`;
const TitleContainer = styled.div`
margin: ${ptr(40)} ${ptr(0)} ${ptr(20)} ${ptr(0)} ;
`;
const DivWrapper = styled.div`
background-color: #F5F6FA;
padding: ${ptr(30)} ${ptr(20)};
border: ${ptr(5)};
`;

const UploadPaddingContainer = styled.div`
padding-left: ${ptr(30)};
`;

const UploadMarginContainer = styled.div`
margin-bottom: ${ptr(40)};
`;

const MarginBottom = styled.div`
margin-bottom: ${ptr(15)};;
`;

const HrMargin = styled.div`
margin: ${ptr(20)} 0;

hr{
  border:1px solid #00000003;
}
`;
const MinMarginBottom = styled.div`
margin-bottom: ${ptr(5)};;
`;

const MarginRight = styled.div`
margin-right: ${ptr(20)};;
`;

const DisplayContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

// const DisplayContainer = styled.div`
// display: flex;
// justify-content: space-between;
// align-items: center;
// `;

const DisplayColumnContainer = styled.div`
display: flex;
flex-direction: column;

`;
// Level up

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${ptr(40)};
`;

const CompanyDetailsContainer = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

const DetailsContainer = styled.div`
  margin-top:${ptr(40)} ;
`;

// User management styles
const UserWrapper = styled.div`
  width: 100%;
`;
const TableWrapper = styled.div`
  margin-top: ${ptr(30)};
`;

const TableControls = styled.div`
  display:flex; 
  justify-content: space-between;
    @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
   /* height: 200px; */
  }
`;
const SearchContainer = styled.div`
  width:60%;
  margin-right: ${ptr(30)};
    @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
display: flex;
justify-content: space-between;
Button{
  margin-right: 10px
}
 @media (max-width: ${breakpoints.sm}px) {
   margin-top: 20px;
   justify-content: space-around;
    flex-direction: row;

    Button{
      margin-right: 20px;
    };
    /* width: 100%;
    margin-bottom: ${ptr(30)} */
  }
`;

const LoginPasswordInputWrapper = styled.div`
 margin-top: ${ptr(15)};
`;
const textwrap = styled.div`
margin-right: 12px;
 `;
const rowWrapper = styled.div`
display: flex;
 `;

export {
  ResponsiveContainer,
  Container,
  TitleContainer,
  DivWrapper,
  MarginBottom,
  ButtonWidthContainer,
  MarginRight,
  UploadPaddingContainer,
  UploadMarginContainer,
  HrMargin,
  IconWrapper,
  MinMarginBottom,
  DisplayContainer,
  DisplayColumnContainer,
  Row,
  CompanyDetailsContainer,
  DetailsContainer,
  UserWrapper,
  TableWrapper,
  TableControls,
  SearchContainer,
  ButtonContainer,
  LoginPasswordInputWrapper,
  textwrap,
  rowWrapper,
};
