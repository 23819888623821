import { BackBar } from "../../components/BackBar";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { CompanyInterface } from "../../redux/company/ICompany";
import paymentsService from "../../services/internal/payments/payments.service";
import debug from "../../services/internal/debbug.service";
import { Icon } from "../../components/Icon";
import { Text } from "../../components/Text";
import * as S from "./styles";
import { colors } from "../../styles/variables";
import { Table, TableFooterProps } from "../../components/Table";
import { Preload } from "../../components/Preload";
import { CheckBox } from "../../components/CheckBox";
import SearchInput from "../../components/SearchInput";
import { formatCash } from "../../utils/money";
import { timeDateMonthString } from "../../utils/datetime";
import { IPagination, Pagination } from "../../components/Pagination";
import { IGetQueries } from "../../interface";
import getQueryString from "../../utils/getQueryString";
import { PaginationActions } from "../../redux/paginationSettings/actions";
import AccessControl, { EPermissions } from "../../components/AccessControl";
import { failed } from "../../components/Toasts";
import { ModalWrapper } from "../../components/ModalWrapper";
import { FilterResult } from "./Component/FilterResult";
import { BatchPaymentViews } from "./Component/BatchPaymentViews";

const separator = "=";
export interface BatchSingleHistory {
  id: string;
  userId: string;
  businessId: string;
  accountId: string;
  reference: string;
  amount: number;
  approvers: Array<IApprovers>;
  isFlagged: boolean;
  sessionId: string;
  receiverName: string;
  gatewayResponseCode: string;
  receiverBvn: string;
  receiverNuban: string;
  gatewayResponse: string;
  receiverBankCode: string;
  lastFailureResponse: string;
  batchId: string;
  receiverClientId: string;
  receiverSavingsId: string;
  comment: string;
  status: string;
  transactionId: string;
  requestReference: string;
  retryCount: number;
  createdAt: string;
  updatedAt: string;
  senderName: string;
  fromAccountName: string;
  fromAccountNo: string;
  destinationBank: string;
}

export interface IApprovers {
  action: string;
  createdAt: number;
  currentRole: string;
  email: string;
  name: string;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  requestReference: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
}

export interface ModalViewComponent {
  FilterResult: any;
  BatchPaymentDetails: any;
}

export interface ViewComponentProps {
  Bulkpayment: any;
  BatchPayment: any;
}

export const BatchPaymentView: React.FC = () => {
  /**
   * Hooks initiation region
   */

  const history = useHistory();
  const location = useLocation<{ batchId: string }>().state;

  /**
   * Redux store initiation region
   */

  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list
  );

  /**
   * Local state initiation region
   */

  const [currentCompanyId, setCurrentCompanyId] = useState<string>("");
  const [payments, setPayments] = useState<Array<BatchSingleHistory>>([]);
  const route = useHistory();
  const [empty, setEmpty] = useState<boolean>(false);
  const [bottomLoading, setBottomLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showmodalView, setshowmodalView] = useState<boolean>(false);
  const [currentView, setcurrentView] = useState<string>("");
  const [currentPaymentDetails, setcurrentPaymentDetails] = useState<
    BatchSingleHistory
  >();
  const [selectedPayment, setselectedPayment] = useState<
    Array<BatchSingleHistory>
  >([]);
  const [text, setText] = useState<string>("");
  const [pagination, setPagination] = useState<IPagination>({
    limit: 50,
    currentPage: 1,
    pageItems: 1,
    totalPages: 1,
  });

  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});

  useEffect(() => {
    PaginationActions.reset();
    getBatchDetails();
  }, []);

  const getStatusButton = (el: BatchSingleHistory) => {
    if (el.status === "success") {
      return (
        <S.BtnSuccess>
          <Text size={6}>{el.status}</Text>
        </S.BtnSuccess>
      );
    }

    if (el.status === "pending") {
      return (
        <S.BtnWarning>
          <Text size={6}>{el.status}</Text>
        </S.BtnWarning>
      );
    }

    if (el.status === "failed") {
      return (
        <S.BtnDanger>
          <Text size={6}>{el.status}</Text>
        </S.BtnDanger>
      );
    }

    if (el.status === "unknown") {
      return (
        <S.BtnUnknwn>
          <Text size={6}>{el.status}</Text>
        </S.BtnUnknwn>
      );
    }
    return (
      <S.BtnWarning>
        <Text size={6}>{el.status}</Text>
      </S.BtnWarning>
    );
  };

  const UserModalView = currentView as keyof typeof ModalView;

  const ModalView: ModalViewComponent = {
    FilterResult: <FilterResult showModal={setshowmodalView} />,
    BatchPaymentDetails: <BatchPaymentViews data={currentPaymentDetails} />,
  };

  const getCompanyFromUrl = (location: Location | any) => {
    const companyId = location.search.split(separator)[1];
    if (companyId) {
      const company = companies.find((item) => item.id === companyId);
      setCurrentCompanyId(company?.id || "");
    }
  };

  const getBatchDetails = async (queriesData?: IGetQueries): Promise<void> => {
    try {
      if (!location.batchId) return;
      if (!queriesData?.search) setLoading(true);

      const queries =
        queriesData && queriesData.search
          ? { ...queriesData }
          : {
              page: 1,
              limit: 50,
              ...additionalQueries,
              ...queriesData,
            };

      if (!queriesData?.search) {
        delete queries.search;
        setText("");
      }

      const result = await paymentsService.getBatchId(
        location.batchId,
        getQueryString(queries)
      );
      setAddtionalQueries(queries);
      setLoading(false);
      const paymentdata = result.data.map((el: IPayments) => ({
        ...el,
        check: false,
      }));

      setPagination({
        limit: result.meta.limit,
        currentPage: result.meta.page,
        pageItems: result.meta.pageCount,
        totalPages: result.meta.totalPages,
      });

      setPayments(paymentdata);
    } catch (err) {
      debug.error("Error retrieving payments", err);
      failed("Failed", err.message || "Error retrieving payments");
    }
  };

  const handleSearchText = (value: string) => {
    setText(value);
    if (!value.length) {
      getBatchDetails({ page: 1 });
    }
  };

  const searchPayments = async () => {
    if (text) {
      setAddtionalQueries({ search: text, page: 1 });
      await getBatchDetails({ search: text, page: 1 });
    }
  };

  useEffect(() => {
    let delayDebounceFn: any = null;

    if (text.length) {
      delayDebounceFn = setTimeout(() => {
        getBatchDetails({ search: text, page: 1 });
      }, 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [text]);

  const renderTableItem = () =>
    payments ? (
      <>
        <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]} isPage>
          <S.TopTitle>
            <BackBar />
          </S.TopTitle>

          <S.TopWrapper>
            <S.TableConatiner>
              <S.TableIIConatiner>
                <SearchInput
                  onChange={handleSearchText}
                  value={text}
                  onSubmitSearch={searchPayments}
                  placeholder="Search for using account or reference."
                />
                <S.MarginLeftComp onClick={() => FilterResultHandler()}>
                  <Icon
                    name="settings"
                    color={colors.pink._100}
                    width={12}
                    height={12}
                  />
                </S.MarginLeftComp>
              </S.TableIIConatiner>
            </S.TableConatiner>
          </S.TopWrapper>

          <S.TableContainer loading={loading}>
            <Table
              header={{
                cells: {
                  0: {
                    align: "left",
                    children: (
                      <CheckBox
                        onChange={(isChecked) => {
                          if (isChecked) {
                            setPayments((old) =>
                              old.map((el) => ({ ...el, check: true }))
                            );
                          } else {
                            setPayments((old) =>
                              old.map((el) => ({ ...el, check: false }))
                            );
                          }
                        }}
                      />
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  1: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        DESCRIPTION
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  2: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        BENEFICIARY ACCOUNT
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  3: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        DATE
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  4: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        AMOUNT
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  5: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        STATUS
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  6: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        REFERENCE{" "}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  7: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        PAYMENT REFERENCE
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  8: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        {" "}
                        APPROVED BY
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  9: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        {" "}
                        DATE UPDATED
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  10: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        {" "}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                },
                padding: "1.25rem 0",
              }}
              footer={((): TableFooterProps | undefined => {
                if (bottomLoading) {
                  return {
                    backgroundColor: colors.white._100,
                    cells: {
                      0: {
                        align: "center",
                        children: (
                          <Icon
                            name="small-loading"
                            color={colors.black._100}
                          />
                        ),
                        padding: "1.25rem 1.5rem",
                        colspan: 6,
                      },
                    },
                  };
                }
                if (empty) {
                  return {
                    backgroundColor: colors.white._100,
                    cells: {
                      0: {
                        align: "center",
                        children: <Text>Result not found</Text>,
                        padding: "1.25rem 1.5rem",
                        colspan: 6,
                      },
                    },
                  };
                }
                return undefined;
              })()}
              rows={payments?.map((el) => ({
                padding: "1.25rem 0",
                border: {
                  color: colors.black._10,
                  width: 1,
                  style: "solid",
                  sides: ["top"],
                },
                cells: {
                  0: {
                    align: "left",
                    children: (
                      <CheckBox
                        checked={el.isFlagged}
                        onChange={() => toggleItemInArray(el, selectedPayment)}
                      />
                    ),
                    padding: "1.25rem 1.5rem",
                    display: "inline-block",
                  },
                  1: {
                    align: "left",

                    children: (
                      <S.Column>
                        <Text size={7}>{el.comment}</Text>
                      </S.Column>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  2: {
                    align: "left",

                    children: <Text size={6}>{el.receiverNuban}</Text>,
                    padding: "1.25rem 1.5rem",
                  },
                  3: {
                    align: "left",
                    children: (
                      <Text size={6}>
                        {timeDateMonthString(new Date(el.createdAt))}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  4: {
                    align: "left",
                    children: (
                      <Text size={6}>{`₦ ${formatCash(el.amount)}`}</Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  5: {
                    align: "right",
                    children: getStatusButton(el),
                    padding: "1.25rem 1.5rem",
                  },

                  6: {
                    align: "right",
                    children: <Text size={6}>{el.reference}</Text>,
                    padding: "1.25rem 1.5rem",
                  },

                  7: {
                    align: "right",
                    children: <Text size={6}>{el.requestReference}</Text>,
                    padding: "1.25rem 1.5rem",
                  },

                  8: {
                    align: "right",
                    children: (
                      <Text size={6}>
                        {el.approvers &&
                          el.approvers.length >= 1 &&
                          el.approvers.map((pay) => pay.name).join(",")}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  9: {
                    align: "right",
                    children: (
                      <Text size={6}>
                        {timeDateMonthString(new Date(el.updatedAt))}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  10: {
                    align: "right",
                    onClick: () => setcurrentPaymentdetails(el),
                    children: (
                      <Text size={6} color="#C6145E">
                        View
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                },
              }))}
            />

            {payments.length > 0 && (
              <Pagination {...pagination} action={getBatchDetails} />
            )}
          </S.TableContainer>
        </AccessControl>
      </>
    ) : null;

  const renderModalView = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="inherit"
      maxHeight="96vh"
      showModal={showmodalView}
      onCloseModal={() => setshowmodalView(false)}
      closeBackground={
        UserModalView === "BatchPaymentDetails" ? colors.pink._20 : ""
      }
      crossColor={colors.black._100}
    >
      {ModalView[UserModalView]}
    </ModalWrapper>
  );

  const setcurrentPaymentdetails = (details: BatchSingleHistory) => {
    setcurrentPaymentDetails(details);
    setcurrentView("BatchPaymentDetails");
    setshowmodalView(true);
  };

  const toggleItemInArray = (identifier: any, bucketArray: any[] = []) => {
    const uniqueKey = identifier.id;
    if (identifier === "empty") {
      bucketArray = [];
      return;
    }

    const check = bucketArray.findIndex((wizard) => wizard.id === uniqueKey);
    if (check !== -1) {
      bucketArray.splice(check, 1);
    } else {
      bucketArray[bucketArray.length] = identifier;
    }

    setselectedPayment(bucketArray);
  };

  const FilterResultHandler = () => {
    setcurrentView("FilterResult");
    setshowmodalView(true);
    const queries = additionalQueries;
    delete queries.to;
    delete queries.from;
    setAddtionalQueries(queries);
  };

  /**
   * Listeners region
   */
  history.listen((location) => {
    getCompanyFromUrl(location);
  });

  return (
    <S.Container>
      {renderModalView()}
      {loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      ) : (
        renderTableItem()
      )}
    </S.Container>
  );
};
