import { BusinessTypesEnum } from "../pages/Onboard/constants";

export const getStringHashCode = (s: string): number =>
  s.split("").reduce((a, b) => {
    // eslint-disable-next-line no-param-reassign
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const getNumberWithSpaces = (x: number): string => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

export const camelSentence = (str: string): string => {
  const result = ` ${str}`.replace(/[^a-zA-Z0-9]+(.)/g, (_match, chr) =>
    chr.toUpperCase()
  );
  return result[0].toUpperCase() + result.slice(1);
};

/**
 * Format bytes to KB, MB, GB etc.
 */
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const kilobyte = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(kilobyte));

  return `${parseFloat((bytes / kilobyte ** i).toFixed(dm))} ${sizes[i]}`;
};

export const removeSpecialCharsAndSpace = (value: string): string =>
  value.replace(/[^\w]/g, "");

const handleMixedNumbers = (text: string, matcher: RegExpMatchArray | null) => {
  if (matcher && matcher.length) {
    for (const index in matcher) {
      text = text.replace(matcher[index], ` ${matcher[index]} `);
    }
    return text;
  }
  return text;
};

export const handleCamelcasing = (text: string) => {
  text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
  text = text.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  text = `${text[0].toUpperCase()}${text.substring(1)}`;
  const matcher = text.match(/\d+/g);
  if (matcher) text = handleMixedNumbers(text, matcher);
  return text.trim();
};

export const capitalize = (text: string) => {
  if (!text) return ''
  return `${text.charAt(0).toUpperCase()}${text.substring(1).toLowerCase()}`
}

export const getBusinessTypeIdLabel = (businessType: BusinessTypesEnum) => {
  switch (businessType) {
    case BusinessTypesEnum.partnership:
      return "Partner"
    case BusinessTypesEnum.cooperativeSociety:
      return "Principal officer"
    case BusinessTypesEnum.trusteeNgo:
      return "Trustee"
    case BusinessTypesEnum.soleProprietorship:
      return "Proprietor"
    default:
      return "Director"
  }
}
