import React from 'react';

import * as S from './styles';
import { Text } from '../Text';
import { colors, fonts } from '../../styles/variables';
import { Logo } from '../Logo';
import { ContentContainer } from '../../styles/wrappers';
import { SmallLogo } from '../SmallLogo';
import Slider from 'react-slick';
import { Icon } from '../Icon';
import './styles.css';

export interface WelcomeSliderProps {
  image?: string;
}

export const WelcomeSlider: React.FC<WelcomeSliderProps> = ({
  image = '/images/onboarding/1.jpeg',
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
  };

  function slideOne() {
    return (
      <S.SlideWrapper>
        <S.ImgSlideOneContainer>
          <S.Img src='/images/onboarding/login_slide_1.svg' alt='..' />
          <S.SlideOneItemOne>
            <Icon name='card' />
            Transactions
          </S.SlideOneItemOne>
          <S.SlideOneItemTwo>
            <Icon name='bank_account' />
            Business Accounts
          </S.SlideOneItemTwo>

          <S.SlideOneItemThree>
            <Icon name='save' />
            Save and Invest
          </S.SlideOneItemThree>

          <S.SlideOneItemFour>
            <Icon name='growth' />
            Growth
          </S.SlideOneItemFour>
        </S.ImgSlideOneContainer>

        <S.WelcomeContainer style={{ textAlign: 'center' }}>
          <Text color={colors.black._30} size={3} bold>
            Welcome to
            <SmallLogo marginLeft={0} marginTop={0} />
          </Text>
          <div style={{ marginTop: -10 }}>
            <Text size={5} bold color={colors.black._20}>
              Smart banking for businesses
            </Text>
          </div>
          <div style={{ opacity: '0.7', marginTop: 10, marginBottom: 30 }}>
            <Text size={6} color={colors.black._20}>
              Enjoy our ever-evolving platform that empowers your business with
              transformative banking.
            </Text>
          </div>
        </S.WelcomeContainer>
      </S.SlideWrapper>
    );
  }

  function slideTwo() {
    return (
      <S.SlideWrapper>
        <S.ImgSlideOneContainer>
          <S.Img src='/images/onboarding/login_slide_2.svg' alt='..' />

          <S.slideFourItems>
            <S.slideFourItem type='one'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Text color='#353F50' size={6}>
                  Saved beneficiaries
                </Text>
                <Text color='#848F9F' size={7}>
                  Pick from your saved beneficiary
                </Text>
              </div>
              <div style={{ marginTop: 10 }}>
                <Icon name='arrow-right' color='#394455' />
              </div>
            </S.slideFourItem>

            <S.slideFourItem type='two'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Text color='#353F50' size={5}>
                  Bulk payment
                </Text>
                <Text color='#848F9F' size={6}>
                  Upload a list of your beneficiary
                </Text>
              </div>
              <div style={{ marginTop: 10 }}>
                <Icon name='cloud-download' color='#394455' />
              </div>
            </S.slideFourItem>
          </S.slideFourItems>
        </S.ImgSlideOneContainer>

        <S.WelcomeContainer style={{ textAlign: 'center', marginTop: 40 }}>
          <Text color={colors.black._30} size={3} bold>
            Manage your expense
          </Text>

          <div style={{ opacity: '0.7', marginTop: 10 }}>
            <Text size={6} color={colors.black._20}>
              Monitor and analyze your cash flow activities, including incoming
              and outgoing payments.
            </Text>
          </div>
        </S.WelcomeContainer>
      </S.SlideWrapper>
    );
  }

  function slideThree() {
    return (
      <S.SlideWrapper>
        <S.ImgSlideOneContainer>
          <S.Img src='/images/onboarding/login_slide_3.svg' alt='..' />
        </S.ImgSlideOneContainer>

        <S.WelcomeContainer style={{ textAlign: 'center', marginTop: 40 }}>
          <Text color={colors.black._30} size={3} bold>
            Request Cards on Vbiz
          </Text>
          <div
            style={{
              width: 77,
              height: 18,
              fontWeight: 500,
              fontSize: 10,
              background: '#ECFDF3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#027A48',
              margin: '0 auto',
              fontFamily: fonts.inter.regular,
            }}
          >
            Coming Soon
          </div>
          <div style={{ opacity: '0.7', marginTop: 10 }}>
            <Text size={6} color={colors.black._20}>
              Track your company's expenses with our cards and enjoy enhanced
              convenience for your employees.
            </Text>
          </div>
        </S.WelcomeContainer>
      </S.SlideWrapper>
    );
  }

  function slideFour() {
    return (
      <S.SlideWrapper>
        <S.ImgSlideOneContainer>
          <S.Img src='/images/onboarding/login_slide_4.svg' alt='..' />
        </S.ImgSlideOneContainer>

        <S.WelcomeContainer style={{ textAlign: 'center', marginTop: 40 }}>
          <Text color={colors.black._30} size={3} bold>
            Request POS on VBiz
          </Text>
          <div
            style={{
              width: 77,
              height: 18,
              fontWeight: 500,
              fontSize: 10,
              background: '#ECFDF3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#027A48',
              margin: '0 auto',
              fontFamily: fonts.inter.regular,
            }}
          >
            Coming Soon
          </div>
          <div style={{ opacity: '0.7', marginTop: 10 }}>
            <Text size={6} color={colors.black._20}>
              Experience the difference our POS can make in enhancing your
              business growth.
            </Text>
          </div>
        </S.WelcomeContainer>
      </S.SlideWrapper>
    );
  }

  function slideFive() {
    return (
      <S.SlideWrapper>
        <S.ImgSlideOneContainer>
          <S.Img src='/images/onboarding/login_slide_5.svg' alt='..' />
        </S.ImgSlideOneContainer>

        <S.WelcomeContainer style={{ textAlign: 'center', marginTop: 40 }}>
          <Text color={colors.black._30} size={3} bold>
            Request POS on VBiz
          </Text>
          <div
            style={{
              width: 77,
              height: 18,
              fontWeight: 500,
              fontSize: 10,
              background: '#ECFDF3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#027A48',
              margin: '0 auto',
              fontFamily: fonts.inter.regular,
            }}
          >
            Coming Soon
          </div>
          <div style={{ opacity: '0.7', marginTop: 10 }}>
            <Text size={6} color={colors.black._20}>
              Enjoy our ever-evolving platform that helps your monitor your
              business payments, pay bills and grow.
            </Text>
          </div>
        </S.WelcomeContainer>
      </S.SlideWrapper>
    );
  }

  function slideSix() {
    return (
      <S.SlideWrapper>
        <S.ImgSlideSixContainer>
          <S.Img src='/images/onboarding/login_slide_6_2.svg' alt='..' />
          <div style={{ position: 'absolute', right: 0, top: 10 }}>
            <S.Img
              style={{ width: 300 }}
              src='/images/onboarding/login_slide_6_1.svg'
              alt='..'
            />
          </div>
        </S.ImgSlideSixContainer>

        <S.WelcomeContainer style={{ textAlign: 'center', marginTop: 40 }}>
          <Text color={colors.black._30} size={3} bold>
            Request Cards on Vbiz
          </Text>
          <div
            style={{
              width: 77,
              height: 18,
              fontWeight: 500,
              fontSize: 10,
              background: '#ECFDF3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#027A48',
              margin: '0 auto',
              fontFamily: fonts.inter.regular,
            }}
          >
            Coming Soon
          </div>
          <div style={{ opacity: '0.7', marginTop: 10 }}>
            <Text size={6} color={colors.black._20}>
              Enjoy our ever-evolving platform that helps your monitor your
              business payments, pay bills and grow.
            </Text>
          </div>
        </S.WelcomeContainer>
      </S.SlideWrapper>
    );
  }
  // const sliderData = [slideOne(), slideOne(), slideOne(), slideOne()];

  return (
    <div className='welcome-slide'>
      <S.WelcomeWrapper
        style={{
          background: colors.pink._50,
        }}
      >
        <S.BgContainer>
          {/* <ContentContainer> */}
          <S.ImgContainer>
            <Slider {...settings}>
              {slideOne()}
              {slideTwo()}
              {slideThree()}
              {slideFour()}
              {/* {slideFive()}
            {slideSix()} */}
            </Slider>
          </S.ImgContainer>
        </S.BgContainer>
        {/* </div> */}
        {/* </ContentContainer> */}
      </S.WelcomeWrapper>
    </div>
  );
};
