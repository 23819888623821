/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { CompanyActions } from '../../redux/company/actions';
import { CompanyInterface } from '../../redux/company/ICompany';

import paymentsService from '../../services/internal/payments/payments.service';
import { PaymementAccountInterface } from '../../services/internal/payments/IPayments';
import debug from '../../services/internal/debbug.service';

import { Text } from '../../components/Text';
import { Icon } from '../../components/Icon';
import { CompanyBalance } from './components/CompanyBalance';
import { RecentTransactions } from './components/RecentTransactions';
import { UpcomingTransactions } from './components/UpcomingTransactions';
import { DonutChart } from './components/DonutChart';
import { Bar } from './components/Bar';

import { useWindowSize } from '../../utils/hooks';

import { breakpoints, colors } from '../../styles/variables';
import * as S from './styles';
import { OnboardingActions } from '../../redux/onboarding/actions';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { failed } from '../../components/Toasts';
import authService from '../../services/internal/auth/auth.service';
import WelcomeNote from '../Welcome';
import { ModalWrapper } from '../../components/ModalWrapper';

// import {WelcomeNote} form from '../Welcome/'
import getQueryString from '../../utils/getQueryString';
import { AuthorizationActions } from '../../redux/authorization/actions';
import { Preload } from '../../components/Preload';
import SearchInput from '../../components/SearchInput';
import { QuickLinks } from './components/QuickLinks';
import {
  FrequentBeneficiaries,
  MenuItem,
} from './components/FrequentBeneficiaries';
import { CashFlow } from './components/CashFlow';
import SelectOperationType from '../Onboard/common.operator.switcher';
import ApproverRoles from '../Onboard/common.other.approver-operators';
import NonApproverRoles from '../Onboard/common.other.non-approver-operators';
import { CommonApprovalLimit } from '../Onboarding/steps/common.approval.limit';
import { SuccessModal } from '../../components/SuccessModal';
import beneficiaryService from '../../services/internal/beneficiary/beneficiary.service';
import GlobalSearch from './components/GlobalSearch';
import TwoFactorModal from '../../components/2faModal';

const separator = '=';
const prefix = `?company${separator}`;

export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  fromAccountName: string;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
  actionRequired: string;
}

export const Dashboard: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const [width] = useWindowSize();

  /**
   * Redux store initiation region
   */
  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list
  );

  const UserProfile = useSelector(
    (state: { currentUser: any }) => state.currentUser.data
  );

  /**
   * Local state initiation region
   */
  const [currentCompanyId, setCurrentCompanyId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPaymentAccId, setCurrentPaymentAccId] = useState<number>(0);
  const [payments, setPayments] = useState<RecentTransactionsProps>();
  const [paymentAccounts, setPaymentAccounts] = useState<
    Array<PaymementAccountInterface & { isChecked: boolean }>
  >();

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [showWelcomeModal, setshowWelcomeModal] = useState<boolean>(true);

  const [currentAccountId, setcurrentAccountId] = useState<string>('');
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [months] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);
  const [monthIndex] = useState(new Date().getMonth());
  const [month, setMonth] = useState<string>(months[monthIndex]);
  const [totalCreditAmount, settotalCreditAmount] = useState<number>(0);
  const [totalDebitAmount, settotalDebitAmount] = useState<number>(0);
  const [Categorydata, setCategorydata] = useState<Array<any>>([]);
  const [showOperatorModal, setShowOperatorModal] = useState(false);
  const [showOperatorSuccessModal, setShowOperatorSuccessModal] = useState(
    false
  );
  const [operatorStage, setOperatorStage] = useState<string>('/set-operators');
  const [frequentBeneficiaries, setFrequentBeneficiaries] = useState<
    MenuItem[]
  >([]);
  const [analyticData, setAnalyticData] = useState<Record<string, any>>();
  const [show2fa, setShow2fa] = useState(false);
  /**
   * Custom handlers initiation region
   */

  useEffect(() => {
    if (UserProfile) {
      setShow2fa(!UserProfile.use2fa);
    }
  }, [UserProfile]);

  const toggle2fa = () => setShow2fa((prev) => !prev);
  const getPayments = async (
    queryString?: string
  ): Promise<Array<IPayments> | boolean> => {
    try {
      const result = await paymentsService.getPayments(queryString);
      setPayments(result);
      return await Promise.resolve(result);
    } catch (error) {
      const err = error as any;
      debug.error('Error retrieving payments', err);

      failed('Failed', err.message || 'Can not get payment at the moment');
      return false;
    }
  };

  const getPaymentAccounts = async (id: string): Promise<void> => {
    try {
      const result = await paymentsService.getPaymentAccounts(id);
      setPaymentAccounts(
        result.map((item, index) => ({ ...item, isChecked: index === 0 }))
      );
    } catch (err) {
      debug.error('Error while getting payment accounts on dashboard', err);
    }
  };

  const getFreQuentBeneficiaries = async (): Promise<void> => {
    try {
      const result = await beneficiaryService.getFrequentBeneficiary();
      setFrequentBeneficiaries(result);
    } catch (err) {
      debug.error('Error while getting payment accounts on dashboard', err);
    }
  };

  const getCompanyFromUrl = (location: Location | any) => {
    const companyId = location.search.split(separator)[1];
    if (companyId) {
      const company = companies.find((item) => item.id === companyId);
      setCurrentCompanyId(company?.id || '');
    }
  };

  const addCompany = () => {
    dispatch(OnboardingActions.handleSetEnv());
    history.push('/onboarding/');
  };
  const switchCompany = async (id: string) => {
    try {
      const result = await paymentsService.SwitchCompnany(id);
      await authService.refreshToken();
      window.location.reload();
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'Error Switching account');
      if (err.code === 'INVALID_TOKEN') {
        dispatch(AuthorizationActions.logOut());
        setTimeout(() => {
          history.push('/');
        }, 200);
      }
    }
  };

  /**
   * Listeners region
   */
  history.listen((location) => {
    getCompanyFromUrl(location);
  });

  /**
   * useEffect region
   */
  useEffect(() => {
    if (!companies?.length) {
      dispatch(CompanyActions.getCompaniesList());
    }

    if (!activeCompany) {
      dispatch(CompanyActions.getActiveCompany());
    } else {
      setCurrentCompanyId(activeCompany.id);
    }

    if (companies?.length && activeCompany) {
      if (!payments) {
        getPayments();
      }
      if (!frequentBeneficiaries?.length) {
        getFreQuentBeneficiaries();
      }

      getCompanyFromUrl(history.location);
    }
  }, [companies, activeCompany]);

  useEffect(() => {
    if (activeCompany) {
      getPaymentAccounts(currentCompanyId || activeCompany.id);
    }
  }, [currentCompanyId]);

  const getAnalytics = async (data: {
    year?: number;
    month?: string;
  }): Promise<void> => {
    try {
      if (currentAccountId && currentAccountId !== '') {
        const result = await paymentsService.getAnalytics(
          currentAccountId,
          getQueryString({
            year: data.year ? data.year : null,
            month: data.month ? data.month : null,
          })
        );
        setAnalyticData(result);
        const totalcreditamount = result.total_credit_amount;
        const totaldebitamount = result.total_debit_amount;
        // const categorystatistics = result.category_statistics;
        settotalCreditAmount(totalcreditamount);
        settotalDebitAmount(totaldebitamount);
        // const categoryData: { id: string; color: string; value: any }[] = [];
        // Object.entries(categorystatistics).forEach(([key, value]) => {
        //   const template = {
        //     id: key,
        //     color: colors.pink._100,
        //     value,
        //   };
        //   categoryData.push(template);
        // });
        // setCategorydata(categoryData);
      }
    } catch (err) {
      // failed('Failed', err.message || 'Error loading analytics');
      // TODO add proper function to handle when a user just switch
      // window.location.reload();
    }
  };

  // useEffect(() => {
  //   getAnalytics({ year });
  // }, [currentAccountId, year]);

  useEffect(() => {
    getAnalytics({ month, year });
  }, [month, currentAccountId]);

  useEffect(() => {
    const currentAccid =
      paymentAccounts && paymentAccounts.filter((el) => el.isChecked === true);
    if (currentAccid && currentAccid.length > 0) {
      const accountId = currentAccid[0].id;
      setcurrentAccountId(accountId);
    }
  }, [paymentAccounts]);
  const FilterByYear = (value: number | string) => {
    if (typeof value === 'number') {
      setYear(value);
    } else {
      setMonth(value);
    }
  };
  const CloseWelcomeModal = () => {
    setshowWelcomeModal(false);
    toggleOperatorModal();
  };

  const toggleOperatorSuccessModal = () => {
    setShowOperatorSuccessModal((prev) => !prev);
  };

  const toggleOperatorModal = (data?: { success: boolean }) => {
    setShowOperatorModal((prev) => {
      if (prev && data?.success) {
        toggleOperatorSuccessModal();
      }
      return !prev;
    });
  };

  const operatorContinue = ({
    workflowSelected,
    actionRequired,
  }: {
    workflowSelected?: string;
    actionRequired?: string;
  }) => {
    if (actionRequired) {
      setOperatorStage(actionRequired);
    } else if (workflowSelected) {
      setOperatorStage(workflowSelected);
    }
  };

  return (
    <S.Container>
      {activeCompany && activeCompany?.setWorkflow ? (
        <ModalWrapper
          showModal={showWelcomeModal}
          crossColor={colors.black._100}
          overflowY="scroll"
          overflowX="inherit"
          maxWidth="60%"
        >
          <WelcomeNote
            currentCompany={activeCompany}
            closeModal={CloseWelcomeModal}
          />
        </ModalWrapper>
      ) : null}

      <SuccessModal
        title="Operators set successfully!"
        description="You have successfully set operators on your account. They will be notified through email provided."
        onClose={toggleOperatorSuccessModal}
        show={showOperatorSuccessModal}
        buttonLabel="Done"
      />

      <ModalWrapper
        showModal={showOperatorModal}
        crossColor={colors.black._100}
        overflowY="scroll"
        overflowX="inherit"
        maxWidth="100%"
      >
        <div style={{ padding: 40 }}>
          {operatorStage === '/set-operators' && (
            <SelectOperationType
              closeOperator={toggleOperatorModal}
              handleContinue={operatorContinue}
            />
          )}
          {operatorStage === '/onboarding/role' && (
            <ApproverRoles
              closeOperator={toggleOperatorModal}
              handleContinue={operatorContinue}
            />
          )}
          {operatorStage === '/onboarding/non-approval-role' && (
            <NonApproverRoles
              closeOperator={toggleOperatorModal}
              handleContinue={operatorContinue}
            />
          )}
          {operatorStage === '/onboarding/approval-limit' && (
            <CommonApprovalLimit
              closeOperator={toggleOperatorModal}
              handleContinue={operatorContinue}
            />
          )}
        </div>
      </ModalWrapper>

      {UserProfile && <TwoFactorModal show={show2fa} toggle={toggle2fa} />}

      <S.Head>
        <S.TabNav>
          <S.HeaderText>
            <S.HeaderTextTop>
              <Text bold size={4} color="#2C2A3B">
                Hello, {activeCompany?.name}
              </Text>

              <Text
                style={{
                  color:
                    activeCompany?.status === 'active'
                      ? '#027A48'
                      : colors.amber._100,
                  background: '#ECFDF3',
                  padding: '0px 6px',
                  borderRadius: '16px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                bold
                size={7}
                color="#2C2A3B"
              >
                {activeCompany?.status}
              </Text>
            </S.HeaderTextTop>

            <Text size={6} color="#2C2A3B">
              Overview of your account activities
            </Text>
          </S.HeaderText>
        </S.TabNav>

        <GlobalSearch />
      </S.Head>
      <S.Wrapper>
        <S.FirstContainer
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '20px',
          }}
        >
          <div style={{ flex: 1.1, position: 'static', width: '100%' }}>
            {paymentAccounts && paymentAccounts[currentPaymentAccId] ? (
              <CompanyBalance
                balance={paymentAccounts[
                  currentPaymentAccId
                ].balance.toString()}
                accountId={paymentAccounts[currentPaymentAccId].id}
                nuban={paymentAccounts[currentPaymentAccId].nuban}
                od={
                  paymentAccounts[currentPaymentAccId].allowOverdraft === true
                }
                odLimit={paymentAccounts[currentPaymentAccId].overdraftLimit}
                outflow={{
                  balance: totalDebitAmount,
                  sub: '',
                }}
                inflow={{
                  balance: totalCreditAmount,
                  sub: '',
                }}
                open={openDropdown}
                onDropdown={() => {
                  setOpenDropdown(!openDropdown);
                }}
                year={year}
                month={month}
                FilterByYear={FilterByYear}
                category={paymentAccounts[currentPaymentAccId].category}
                accounts={paymentAccounts.map((item, index) => ({
                  nuban: item.nuban,
                  type: item.type,
                  category: item.category,
                  balance: `${item.balance}`,
                  isChecked: item.isChecked,
                  setIsChecked: (value: boolean) => {
                    const temp = [...paymentAccounts];
                    temp.map((acc, i) => {
                      if (i === index) {
                        acc.isChecked = value;
                        setCurrentPaymentAccId(index);
                        getPayments(getQueryString({ accountId: acc.id }));
                      } else {
                        acc.isChecked = false;
                      }
                      return acc;
                    });
                    // temp[index].isChecked = value;
                    setPaymentAccounts(temp);
                  },
                }))}
              />
            ) : (
              <S.PreloadContainer style={{ padding: '60px 0px' }}>
                <Preload />
              </S.PreloadContainer>
            )}
          </div>
          <div style={{ flex: 1, width: '100%' }}>
            <Bar />
          </div>
        </S.FirstContainer>

        <S.FirstContainer
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '20px',
          }}
        >
          <div style={{ flex: 1.1, width: '100%', position: 'static' }}>
            <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]}>
              <QuickLinks showEditButton />
            </AccessControl>
          </div>
          {frequentBeneficiaries?.length < 0 && (
            <div
              style={{
                flex: 1,
                width: '100%',
                position: 'static',
              }}
            >
              <FrequentBeneficiaries details={frequentBeneficiaries} />
            </div>
          )}
        </S.FirstContainer>

        <S.FirstContainer
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '20px',
          }}
        >
          <div style={{ flex: 1, width: '100%', position: 'static' }}>
            <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]}>
              <RecentTransactions payments={payments} />
            </AccessControl>
          </div>
          <div
            style={{
              flex: 1,
              width: '100%',
              position: 'static',
            }}
          >
            {paymentAccounts && paymentAccounts[currentPaymentAccId] ? (
              <CashFlow
                debits={{
                  balance: totalDebitAmount,
                  sub: '',
                }}
                credits={{
                  balance: totalCreditAmount,
                  sub: '',
                }}
                year={year}
                month={month}
                category={paymentAccounts[currentPaymentAccId].category}
                FilterByYear={FilterByYear}
                balance={paymentAccounts[
                  currentPaymentAccId
                ].balance.toString()}
                nuban={paymentAccounts[currentPaymentAccId].nuban}
                data={analyticData}
                accounts={paymentAccounts.map((item, index) => ({
                  nuban: item.nuban,
                  type: item.type,
                  category: item.category,
                  balance: `${item.balance}`,
                  isChecked: item.isChecked,
                  setIsChecked: (value: boolean) => {
                    const temp = [...paymentAccounts];
                    temp.map((acc, i) => {
                      if (i === index) {
                        acc.isChecked = value;
                        setCurrentPaymentAccId(index);
                        getPayments(getQueryString({ accountId: acc.id }));
                      } else {
                        acc.isChecked = false;
                      }
                      return acc;
                    });
                    // temp[index].isChecked = value;
                    setPaymentAccounts(temp);
                  },
                }))}
              />
            ) : (
              <S.PreloadContainer>
                <Preload />
              </S.PreloadContainer>
            )}
          </div>
        </S.FirstContainer>
      </S.Wrapper>
    </S.Container>
  );
};
