import React from 'react';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';

import * as S from './styles';

export interface RowTextProps {
  param: string,
  value: string,
}

export const RowText: React.FC<RowTextProps> = ({
  param,
  value,
}) => (
  <S.Container>
    <Text bold color={colors.black._100} size={6}>{param}</Text>
    <Text color={colors.black._100} size={6}>{value}</Text>
  </S.Container>
);
