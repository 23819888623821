import React, { useEffect, useState } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import validator from 'validator';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { actions } from '../config';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../../components/DropdownInput';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';

import { validateObject, ValidateResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { credentialsActions } from '../config';
import { idTypes, validIdTypes } from '../constants';
import { OnboardingFormHeader } from '../../../components/OnboardingFormHeader';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { ButtonContainer } from '../../../styles/wrappers';

const SCHEME = {
  phone: (value: string) => value.length === 11,
  bvn: (value: string) => value.length === 11,
  idType: (value: { value: string }) => value?.value?.length > 0,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CommonPersonalInformation: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const savedBvn = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.bvn as string
  );

  const credentials = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.credentials as Record<string, string>
  );

  /**
   * Local state initiation region
   */
  // const [email, setEmail] = useState<string>(credentials.email || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [bvn, setBvn] = useState<string>(() => credentials?.bvn ?? '');
  const [phone, setPhone] = useState<string>(() => credentials?.phone ?? '');
  const [idType, setIdType] = useState<DropdownOptionProps | null>(
    () => validIdTypes.find((id) => id.value === credentials?.idType) ?? null
  );
  const [idNumber, setIdNumber] = useState<string>(
    () => credentials?.idNumber ?? ''
  );
  const [validation, setValidation] = useState<ValidateResult>();
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();
  const [validInProgress, setValidInProgress] = useState(false);
  const [bvnValid, setBvnValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [idNumberValid, setNumberValid] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const continueHandle = async () => {
    setLoading(true);
    if (idType && idType.value) {
      try {
        const response = await onboardingService.submitProfileInfo({
          auth: {
            password: credentials?.password ?? '',
            email: credentials?.personalEmail ?? '',
            phone,
            idNumber,
            idType: idType.value,
            bvn: bvn || savedBvn,
          },
          reference,
        });
        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              credentials: {
                email: credentials?.personalEmail ?? '',
                bvn: bvn || savedBvn,
                phone,
                idNumber,
                idType: idType.value,
              },
              reference: response.data.reference,
            })
          );
        });
        route.push(actions[response.actionRequired]);
      } catch (err) {
        if (err.code === 'INVALID_ACTION_REQUIRED') {
          route.push(actions[err.message.substring(50)], {
            reference,
          });
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || 'Can not complete request at the moment'
            )
          );
        }
      }
    }
    setLoading(false);
  };

  /**
   * useEffect region
   */

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            bvn: bvn || savedBvn,
            phone,
            idType,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [bvn, phone, idType]);

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setIdNumberValidation(
        validateObject(
          {
            idNumber,
          },
          idNumberScheme
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [idNumber, idType]);

  return (
    <S.Container>
      <OnboardingFormHeader
        description='Tell us about yourself by providing your personal details.'
        // descriptionColor='#6E7191'
        title='Super admin’s details'
        titleColor={colors.black._20}
      />
      <OnboardingCard>
        <S.Wrapper>
          <S.ItemRow>
            {!savedBvn && (
              <div style={{ flex: 1 }}>
                <TextInput
                  label='BVN'
                  type='currency'
                  placeholder='Enter your BVN'
                  mask={createNumberMask(numberMaskOptions)}
                  value={bvn}
                  valid={!bvnValid && validation?.data.bvn.isValid}
                  onChange={setBvn}
                />
              </div>
            )}
            <div style={{ flex: 1 }}>
              <TextInput
                label='Phone number'
                type='tel'
                mask={createNumberMask(numberMaskOptions)}
                value={phone}
                valid={!phoneValid && validation?.data.phone.isValid}
                onChange={setPhone}
                placeholder='Enter phone number'
              />
            </div>
          </S.ItemRow>
          <S.ItemRow>
            <div style={{ flex: 1 }}>
              <DropdownInput
                placeholder='Select valid ID'
                label='Valid ID Type'
                options={[...validIdTypes]}
                inputValue={idType?.label}
                // options={convertBankNamesArray(bankNames || [])}
                //title={destinationBank?.label}
                onSelect={setIdType}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TextInput
                label='ID Number'
                type='text'
                placeholder='Enter ID Number'
                value={idNumber}
                name='idNumber'
                valid={
                  !idNumberValid && idNumberValidation?.data.idNumber.isValid
                }
                onChange={setIdNumber}
              />
            </div>
          </S.ItemRow>
        </S.Wrapper>
        <ButtonContainer>
          <Button
            label='Continue'
            loading={loading}
            style={{ height: 40 }}
            disabled={
              !validation?.isValid || !idNumberValidation?.isValid || loading
            }
            onClick={continueHandle}
          />
        </ButtonContainer>
      </OnboardingCard>
    </S.Container>
  );
};
export default CommonPersonalInformation;
