import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import validator from 'validator';

import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { defaultCorporateOperator } from '../../../redux/onboarding/reducer';

import { CorporateOperatorInterface } from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import { CorporateForm, SimpleFormProps } from '../../Onboarding/components/CorporateForm';

import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { Modal } from '../../../components/LoadingModal';

// const SCHEME = {
//   role: (value: string) => value.length > 0,
// };

const COLLECTION_SCHEME = {
  name: (value: string) => value.length > 0,
  email: (value: string) => validator.isEmail(value),
  role: (value: string) => value.length > 0,
};

const directorDefaultItem = {
  name: '',
  email: '',
  role: '',
};

interface ExtendedSimpleFormProps extends SimpleFormProps {
  role: string;
}

const CorporateOperators: React.FC = () => {
  /**
   * Hooks initiation region
   */

  /**
   * Redux store initiation region
   */
  // const reference = useSelector(
  //   (state: { onboardingStack: OnboardingInterface }) => state
  //     .onboardingStack.data.reference as string,
  // );
  // const storedRole = useSelector(
  //   (state: { onboardingStack: OnboardingInterface }) => state
  //     .onboardingStack.data.role as string,
  // );
  const storedOperators = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.operators as Array<CorporateOperatorInterface>,
  );

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<ExtendedSimpleFormProps>>(
    storedOperators?.length ? storedOperators : [directorDefaultItem],
  );

  const [validationCollection, setValidationCollection] = useState<ValidateArrayResult>();
  const [loading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const push = () => {
    setCollection([...collection, directorDefaultItem]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: string) => {
    setCollection((old) => old.map((el: ExtendedSimpleFormProps, i: number) => {
      if (i === index) {
        return {
          ...el,
          [property]: value,
        };
      }
      return el;
    }) as Array<ExtendedSimpleFormProps>);
  };

  const continueHandle = () => {
    collection.map((item) => {
      const operator = { ...defaultCorporateOperator };

      operator.role = item.role?.toLowerCase();
      operator.email = item.email;
      operator.name = item.name;

      return operator;
    });
  };

  /**
   * useEffect region
   */
  // useEffect(() => {
  //   setValidation(validateObject({ role }, SCHEME));
  // }, [role]);

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, COLLECTION_SCHEME));
  }, [collection]);

  return (
    <S.Container>
      <Title title="Operators Details" />

      <S.Wrapper>
        {collection.map((item, index) => (
          <>
            <CorporateForm
              name={item.name}
              email={item.email}
              role={item.role}
              index={index}
              remove={remove}
              onChange={onChangeValue}
              emailIsValid={validationCollection?.data[index]?.data.email.isValid}
              title="Create and assign role to Operator "
            />

          </>
        ))}
      </S.Wrapper>
      <S.Add onClick={push}>
        <Icon name="plus-in-circle" color={colors.pink._100} />
        <Text color={colors.pink._100}>
          Add operator
        </Text>
      </S.Add>
      <Button loading={loading} label="Continue" onClick={continueHandle} disabled={!validationCollection?.isValid || loading} />

      <Modal visible={loading} />
    </S.Container>
  );
};
export default CorporateOperators;
