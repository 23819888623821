import ky, { NormalizedOptions } from 'ky';
import jwt_decode from 'jwt-decode';

import debug from '../debbug.service';
import authService from '../auth/auth.service';
import ErrorApi, { ErrorApiInterface } from './api.error';

let cachedPublicKey: CryptoKey | null = null;
export class ApiService {
  public request;

  // private apiPath = 'https://ec2-54-247-149-123.eu-west-1.compute.amazonaws.com/';
  // private apiPath = 'http://192.168.1.88:8080/';
  private apiPath = process.env.REACT_APP_API_PATH;

  public static refreshingToken = false;

  public httpStatus = {
    success: 200,
  };

  constructor() {
    this.request = ky.extend({
      retry: {
        limit: 1,
        methods: ['get', 'post', 'put', 'delete', 'patch'],
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
      },
      prefixUrl: this.apiPath,
      timeout: false,
      credentials: 'include',
      hooks: {
        beforeRequest: [
          ApiService.authorizationBeforeRequest,
          ApiService.encryptionBeforeRequest,
        ],
        afterResponse: [
          ApiService.handleJwtError,
          ApiService.refreshTokenAfterRequest,
        ],
      },
    });
  }

  private static async authorizationBeforeRequest(request: Request) {
    try {
      const accessToken = authService.getAccessToken();
      if (accessToken) {
        request.headers.set('Authorization', accessToken);
      }
    } catch (error) {
      debug.error('Failed to set token before request hook', error);
    }
  }

  private static async handleJwtError(
    _request: Request,
    _options: NormalizedOptions,
    response: Response
  ) {
    try {
      if (response && !response.ok) {
        const result = await response.json();
        const userDataUrl = `${process.env.REACT_APP_API_PATH}/auth`;
        if (
          result.name &&
          (result.name === 'TokenExpiredError' ||
            result.name === 'JsonWebTokenError') &&
          _request.url !== userDataUrl
        ) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          ErrorApi.throwError(ErrorApi.JwtError());
        }
      }
    } catch (error) {
      const err = error as ErrorApiInterface;
      ErrorApi.throwError(err);
    }
  }

  private static async refreshTokenAfterRequest(): Promise<void> {
    try {
      const decoded = jwt_decode(authService.getAccessToken() as string);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const expireDate = new Date(decoded.exp * 1000).getTime();
      const now = new Date().getTime();
      const diff = expireDate - now;
      const minutes = Math.floor(diff / (1000 * 60));
      if (minutes <= 5 && !ApiService.refreshingToken) {
        ApiService.refreshingToken = true;
        await authService.refreshToken();
        ApiService.refreshingToken = false;
      }
    } catch (error) {
      debug.error('Failed to refresh token after response hook', error);
    }
  }

  static async encryptionBeforeRequest(request: Request) {
    try {
      // check method....
      if (request.method === 'GET') return;

      if (process.env.REACT_APP_ENABLE_PAYLOAD_ENCRYPTION === 'false') return;

      const clonedRequest = request.clone();
      if (clonedRequest.headers.get('Content-Type') === 'application/json') {
        let data = await clonedRequest.json();
        if (Object.keys(data).length) {
          data = JSON.stringify(data);

          // let savedKey = currentState.authorization.publicKey;
          if (!cachedPublicKey) {
            const pemHeader = '-----BEGIN PUBLIC KEY-----';
            const pemFooter = '-----END PUBLIC KEY-----';
            const pubKeyPem = (await authService.getPublicKey()) as string;
            const pubKeyContent = pubKeyPem
              .replace(pemHeader, '')
              .replace(pemFooter, '')
              .replace(/\s/g, '');
            cachedPublicKey = await window.crypto.subtle.importKey(
              'spki',
              ApiService.str2ab(atob(pubKeyContent)),
              {
                name: 'RSA-OAEP',
                hash: { name: 'SHA-256' },
              },
              true,
              ['encrypt']
            );
          }
          // Generate AES key
          const aesKey = await window.crypto.subtle.generateKey(
            {
              name: 'AES-GCM',
              length: 256,
            },
            true,
            ['encrypt', 'decrypt']
          );
          const encodedData = new TextEncoder().encode(data);

          const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

          const encryptedData = await window.crypto.subtle.encrypt(
            {
              name: 'AES-GCM',
              iv: iv,
            },
            aesKey,
            encodedData
          );

          const exportedAESKey = await window.crypto.subtle.exportKey(
            'raw',
            aesKey
          );
          const encryptedAESKey = await window.crypto.subtle.encrypt(
            {
              name: 'RSA-OAEP',
            },
            cachedPublicKey,
            exportedAESKey
          );

          // Convert to base64
          const encryptedAESKeyBase64 = ApiService.arrayBufferToBase64(
            encryptedAESKey
          );
          const ivBase64 = ApiService.arrayBufferToBase64(iv);
          const encryptedDataBase64 = ApiService.arrayBufferToBase64(
            encryptedData
          );

          // Combine encrypted AES key, IV, and encrypted data into a single object
          const newBody = {
            dataKey: encryptedAESKeyBase64, //encryptedAESKey
            iv: ivBase64,
            data: encryptedDataBase64,
          };

          // // Create a new Request with the updated body

          const newRequest = new Request(request.url, {
            method: request.method,
            headers: request.headers,
            body: JSON.stringify(newBody),
            credentials: request.credentials,
            mode: request.mode,
            cache: request.cache,
            redirect: request.redirect,
            referrer: request.referrer,
            integrity: request.integrity,
          });

          // // Update the original request
          Object.assign(request, newRequest);
          return newRequest;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // return result;
        }
      }
    } catch (error) {
      // debug.error('Data encryption failed', error);
      return undefined;
    }
  }
  static arrayBufferToBase64(buffer: ArrayBuffer) {
    const uintArray = new Uint8Array(buffer);
    let binaryString = '';
    for (let i = 0; i < uintArray.length; i++) {
      binaryString += String.fromCharCode(uintArray[i]);
    }
    return btoa(binaryString);
  }
  static str2ab(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
}
const apiService = new ApiService();
export default apiService;
