import { ThunkAction } from 'redux-thunk';
import {
  ILimitInfoResponse,
  ILimitRequestResponse,
  PaymementAccountInterface,
} from '../../services/internal/payments/IPayments';
import paymentsService from '../../services/internal/payments/payments.service';
import { ActionInterface } from '../IAction';
import { AnyAction } from 'redux';
import { AppDispatch } from '..';

export const SET_VALIDATING = 'SET_VALIDATING';
export const SET_PAYMENT_ACCOUNTS = 'SET_PAYMENT_ACCOUNTS';
export const SET_ERROR = 'SET_ERROR';
export const RE_SET_ERROR = 'RE_SET_ERROR';
export const RESET_STATE = 'RESET_STATE';
export const SET_LIMIT_INFO = 'SET_LIMIT_INFO';
export const SET_LIMIT_REQUEST_INFO = 'SET_LIMIT_REQUEST_INFO';
export const SET_LIMIT_REQUEST = 'SET_LIMIT_REQUEST';
export const RESET_LIMIT_REQUEST = 'RESET_LIMIT_REQUEST';

const setValidating = (payload: boolean): ActionInterface<unknown> => ({
  type: SET_VALIDATING,
  payload,
});
const setPaymentAccounts = (
  payload: Array<PaymementAccountInterface>
): ActionInterface<unknown> => ({
  type: SET_PAYMENT_ACCOUNTS,
  payload,
});

const setLimitInfo = (
  payload: ILimitInfoResponse['data']
): ActionInterface<unknown> => ({
  type: SET_LIMIT_INFO,
  payload,
});

const setLimitRequest = (
  payload: ILimitRequestResponse
): ActionInterface<unknown> => ({
  type: SET_LIMIT_REQUEST,
  payload,
});

const clearLimitRequest = (): ActionInterface<unknown> => ({
  type: RESET_LIMIT_REQUEST,
});

const resetPaymentState = (): ActionInterface<unknown> => ({
  type: RESET_STATE,
});
// const setErrors = (payload: PaymentValidationErrorInterface): ActionInterface<unknown> => ({
//   type: SET_ERROR,
//   payload,
// });

// const reSetErrors = (): ActionInterface<unknown> => ({
//   type: RE_SET_ERROR,
// });

export class PaymentActions {
  public static setValidating(val: boolean): ActionInterface<unknown> {
    return setValidating(val);
  }

  public static savePaymentAccounts(
    paymentAccounts: Array<PaymementAccountInterface>
  ): ActionInterface<unknown> {
    return setPaymentAccounts(paymentAccounts);
  }

  public static saveLimitInformation(
    data: ILimitInfoResponse['data']
  ): ActionInterface<unknown> {
    return setLimitInfo(data);
  }

  public static resetPaymentState(): ActionInterface<unknown> {
    return resetPaymentState();
  }

  public static saveLimitRequestResponse(
    data: ILimitRequestResponse
  ): ActionInterface<unknown> {
    return setLimitRequest(data);
  }

  public static clearSavedLimitRequest(): ActionInterface<unknown> {
    return clearLimitRequest();
  }

  public static getLimitInfo(
    nuban: string,
    toast: (title: string, description: string) => void,
    setLimitInfoLoading?: (val: boolean) => void,
    callback?: () => void
  ): ThunkAction<Promise<void>, any, any, AnyAction> {
    return async (dispatch: AppDispatch) => {
      try {
        const result = (await paymentsService.getLimitInfo(
          nuban
        )) as ILimitInfoResponse;
        // setLimitInfo(result.data);

        dispatch(PaymentActions.saveLimitInformation(result.data));
        if (setLimitInfoLoading) setLimitInfoLoading(false);
        if (callback) callback();
      } catch (error) {
        const err = error as Error;
        toast('Error', err.message ?? 'Unable to get current limit');
        if (setLimitInfoLoading) setLimitInfoLoading(false);
      }
    };
  }

  // public static setValidatingErrors(val:PaymentValidationErrorInterface)
  // : ActionInterface<unknown> {
  //   return setErrors(val);
  // }

  // public static reSetErrors(): ActionInterface<unknown> {
  //   return reSetErrors();
  // }
}
