import styled, { css } from 'styled-components';

import { breakpoints, colors, transition } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
border-radius: 8px;
border: 1px solid ${colors.black._1};
background:  #EFF0F6;
height: 68px;
display: flex;
align-items: center;
justify-content: space-between;
padding:0 15px;
`
const ActionButtons = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: row;
`

export {
    Wrapper,
    ActionButtons

}
