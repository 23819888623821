import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';

import jwt_decode from 'jwt-decode';
import debug from '../debbug.service';
import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';
import ErrorAuth from './auth.error';
import { AuthorizationActions } from '../../../redux/authorization/actions';
import { IInit2faResponse } from './interface';

export interface IUpdatePassword {
  newPasswordConfirmation: string;
  newPassword: string;
  oldPassword: string;
  otp: string;
}

export interface IResetPin {
  newPinConfirmation: string;
  newPin: string;
  password: string;
  otp: string;
}

export interface ISetPin {
  newPinConfirmation: string;
  newPin: string;
  password: string;
}

class AuthService {
  private accessTokenKey = 'user/accessToken';

  private refreshTokenKey = 'user/refreshToken';

  private fingerPrintJs: Agent | undefined;

  private clientFingerprint: string | undefined;

  constructor() {
    (async () => {
      this.fingerPrintJs = await FingerprintJS.load();
      const result = await this.fingerPrintJs.get();
      this.clientFingerprint = result.visitorId;
    })();
  }

  private apiEndpoints = {
    auth: 'auth',
    refresh: 'auth/refresh',
    logout: 'auth/logout',
    forgot: 'auth/password/reset',
    password: 'auth/password/reset',
    updatePassword: 'users/password',
    updatePin: 'users/pin',
    setPin: 'users/set-pin',
    concentForm: (token: string) => `onboarding/business/${token}/acknowledge`,
    publicKey: `auth/public-key`,
    initTwoFactor: 'auth/init2fa',
    verifyTwoFactor: 'auth/finalize2fa',
  };

  private setAccessToken(token: string): void {
    window.localStorage.setItem(this.accessTokenKey, token);
  }

  public getAccessToken(): string | null {
    return window.localStorage.getItem(this.accessTokenKey);
  }

  private setRefreshToken(token: string): void {
    window.localStorage.setItem(this.refreshTokenKey, token);
  }

  private getRefreshToken(): string | null {
    return window.localStorage.getItem(this.refreshTokenKey);
  }

  private removeAccessToken(): void {
    window.localStorage.removeItem(this.accessTokenKey);
  }

  private removeRefreshToken(): void {
    window.localStorage.removeItem(this.refreshTokenKey);
  }

  public async logOut(): Promise<void> {
    try {
      // TEMPORARY
      // await apiService.request.post(this.apiEndpoints.logout, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });

      this.removeAccessToken();
      this.removeRefreshToken();
      localStorage.removeItem('inApp');
      return;
    } catch (err) {
      const error = err as any;
      if (!(error instanceof Error)) {
        const localError = await error.response?.json();
        this.removeAccessToken();
        throw localError;
      }
    }
  }

  public async authentication(
    email: string,
    password: string
  ): Promise<{ permissions: string[]; roles: string[] } | void> {
    try {
      const request = await apiService.request.post(this.apiEndpoints.auth, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          fingerprint: this.clientFingerprint,
        }),
      });

      const result = (await request.json()) as {
        accessToken: string;
        refreshToken: string;
      };
      this.setAccessToken(result.accessToken);
      this.setRefreshToken(result.refreshToken);
      const decoded: any = jwt_decode(result.accessToken as string);
      return { permissions: decoded.scopes, roles: decoded.roles };
    } catch (error) {
      const globalError = error as any;

      debug.error('Failed authentication', email, password, globalError);
      try {
        const result = await globalError.response.json();
        return Promise.reject(
          new ErrorAuth({
            message: result.message,
            code: result.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public getPermissions() {
    const token: string | null = this.getAccessToken();
    if (token) {
      const decoded: any = jwt_decode(token);
      return decoded.scopes;
    }
    return [];
  }

  public getRoles() {
    const token: string | null = this.getAccessToken();
    if (token) {
      const decoded: any = jwt_decode(token);
      return decoded.roles;
    }
    return [];
  }

  public async refreshToken(): Promise<void> {
    try {
      const request = await apiService.request.post(this.apiEndpoints.refresh, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          fingerprint: this.clientFingerprint,
          refreshToken: this.getRefreshToken(),
        }),
      });

      const tokens = await request.json();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (tokens.accessToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.setAccessToken(tokens.accessToken);
      }

      return;
    } catch (err) {
      const error = err as any;
      // throw new ErrorApi(ErrorApi.ServerError);
      const localError = await error.response?.json();
      throw localError;
    }
  }

  public async forgorPassword(email: string): Promise<void> {
    try {
      await apiService.request.post(this.apiEndpoints.forgot, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      return;
    } catch (err) {
      const error = err as any;
      const localError = await error.response?.json();
      throw localError;
    }
  }

  public async newPassword(
    password: string,
    passwordConfirmation: string,
    token: string,
    otp?: string
  ): Promise<void> {
    try {
      await apiService.request.put(this.apiEndpoints.password, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ password, passwordConfirmation, token, otp }),
      });
      return;
    } catch (err) {
      const error = err as any;
      const localError = await error.response?.json();
      throw localError;
    }
  }

  public async updatePassword(data: IUpdatePassword): Promise<void> {
    try {
      await apiService.request.put(this.apiEndpoints.updatePassword, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return;
    } catch (err) {
      const error = err as any;
      if (error.response) {
        const localError = await error.response?.json();
        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async resetTransactionPin(data: IResetPin): Promise<void> {
    try {
      await apiService.request.put(this.apiEndpoints.updatePin, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      return;
    } catch (err) {
      const error = err as any;
      if (error.response) {
        const localError = await error.response?.json();

        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async setTransactionPin(data: ISetPin): Promise<void> {
    try {
      await apiService.request.put(this.apiEndpoints.setPin, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      return;
    } catch (err) {
      const error = err as any;
      if (error.response) {
        const localError = await error.response?.json();

        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async ProcessConcentForm(
    email: string,
    token: string,
    isAcknowledged: boolean
  ) {
    try {
      const request = await apiService.request.post(
        this.apiEndpoints.concentForm(token),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            isAcknowledged,
            email,
          }),
        }
      );

      const result = await request.json();

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to Change Account', globalError);
      // return ErrorApi.throwError(ErrorApi.ServerError);
      try {
        const result = await globalError.response.json();
        return Promise.reject(
          new ErrorAuth({
            message: result.message,
            code: result.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getPublicKey(): Promise<string> {
    try {
      const request = await apiService.request.get(
        this.apiEndpoints.publicKey,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      const result = await request.json();
      return result;
    } catch (err) {
      const error = err as any;
      if (error.response) {
        const localError = await error.response?.json();

        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async initiateTwoFactorSetup(
    pin: string
  ): Promise<IInit2faResponse | void> {
    try {
      const request = await apiService.request.post(
        this.apiEndpoints.initTwoFactor,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pin,
          }),
        }
      );

      const result = (await request.json()) as IInit2faResponse;
      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed 2fa initialization', globalError);

      try {
        const result = await globalError.response.json();
        return Promise.reject(
          new ErrorAuth({
            message: result.message,
            code: result.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async verifyTwoFactorSetup(token: string): Promise<unknown | void> {
    try {
      const request = await apiService.request.post(
        this.apiEndpoints.verifyTwoFactor,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
          }),
        }
      );

      const result = (await request.json()) as unknown;
      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed 2fa initialization', globalError);

      try {
        const result = await globalError.response.json();
        return Promise.reject(
          new ErrorAuth({
            message: result.message,
            code: result.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }
}

const authService = new AuthService();
export default authService;
