import { FC, useEffect, useState } from 'react';
import { colors } from '../../../styles/variables';
import { ModalWrapper } from '../../../components/ModalWrapper';
import StepOne from './stepOne';
import ManualStep from './manualStep';
import StepTwo from './stepTwo';
import Success from './success';
import authService from '../../../services/internal/auth/auth.service';
import { failed } from '../../../components/Toasts';
import { useLocation } from 'react-router-dom';

interface IProps {
  toggle: () => void;
  show: boolean;
  qrCode: string;
  tokenKey: string;
  fromRoute?: string;
}
const ActivateTwoFactorModal: FC<IProps> = ({
  toggle,
  show,
  qrCode,
  tokenKey,
  fromRoute,
}) => {
  const [step, setStep] = useState(1);

  const [manualSetup, setManualSetup] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setStep(1);
      setManualSetup(false);
      setSuccess(false);
    }
  }, [show]);

  const handleNext = () => {
    setManualSetup(false);
    setStep((prev) => {
      return prev + 1;
    });
  };

  const handlePrev = () => {
    setManualSetup(false);
    setStep((prev) => {
      if (prev === 1) return 1;
      return prev - 1;
    });
  };

  const handleManual = () => {
    setManualSetup(true);
  };

  const handleActivation = () => {
    setSuccess(true);
  };

  const verify2fa = async (token: string) => {
    setLoading(true);
    try {
      await authService.verifyTwoFactorSetup(token);
      handleActivation();
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'An error occured');
    }
    setLoading(false);
  };

  const viewer: Record<number, JSX.Element> = {
    1: (
      <StepOne
        handleCancel={toggle}
        handleManualSetup={handleManual}
        handleNext={handleNext}
        qrCode={qrCode}
      />
    ),
    2: (
      <StepTwo
        handleCancel={toggle}
        handlePrev={handlePrev}
        loading={loading}
        onSubmit={verify2fa}
      />
    ),
  };

  if (success) {
    return (
      <ModalWrapper
        overflowY="auto"
        overflowX="auto"
        maxHeight="96vh"
        showModal={show}
        //   onCloseModal={toggle}
        crossColor={colors.black._100}
      >
        <Success toggle={toggle} fromRoute={fromRoute} />
      </ModalWrapper>
    );
  }
  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      //   onCloseModal={toggle}
      crossColor={colors.black._100}
    >
      {!manualSetup ? (
        <>
          {viewer[step as number]}
          {/* use condition to add manual step here */}
        </>
      ) : (
        <ManualStep
          handleCancel={toggle}
          goBack={() => setManualSetup(false)}
          handleNext={handleNext}
          tokenKey={tokenKey}
        />
      )}
    </ModalWrapper>
  );
};

export default ActivateTwoFactorModal;
