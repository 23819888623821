import styled from 'styled-components';
import { colors } from '../../styles/variables';

const LimitSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LimitSliderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #eaecf0;
  border-radius: 25px;
  height: 8px;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2); */
`;

// Styled component for the progress bar filler
const Filler = styled.div<{ completed: number; bgColor?: string }>`
  height: 100%;
  width: ${(props) => props.completed}%;
  background-color: ${(props) => props.bgColor || colors.pink._15};
  border-radius: inherit;
  text-align: right;
  transition: width 0.2s ease-in;

  animation: fillAnimation 1s ease-in-out forwards;

  @keyframes fillAnimation {
    0% {
      width: 0%;
    }
    100% {
      width: ${(props) => props.completed}%;
    }
  }
`;

// Styled component for the label inside the progress bar
const Label = styled.span`
  padding: 5px;
  color: white;
  font-weight: bold;
`;

const Dot = styled.div<{ color?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 3px;
  background: ${(props) => props.color || colors.pink._5};
`;
const OtherContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export {
  LimitSliderContainer,
  LimitSliderContent,
  Label,
  ProgressBarContainer,
  Filler,
  Dot,
  OtherContent,
};
