import React, { useState, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import validator from "validator";

import { useHistory, useLocation } from "react-router-dom";
import { OnboardingActions } from "../../../redux/onboarding/actions";
import { OnboardingInterface } from "../../../redux/onboarding/IOnboarding";
import { defaultOperator } from "../../../redux/onboarding/reducer";

import onboardingService from "../../../services/internal/onboarding/onboarding.service";
import { OperatorInterface } from "../../../services/internal/onboarding/IOnboarding";
import debug from "../../../services/internal/debbug.service";

import { Button } from "../../../components/Button";
import { Title } from "../../Onboarding/components/Title";
import { Text } from "../../../components/Text";
import { Icon } from "../../../components/Icon";
import {
  SimpleForm3,
  SimpleFormProps,
} from "../../Onboarding/components/SimpleFrom3";
import { CheckAreaGroup } from "../../../components/CheckAreaGroup";

import {
  validateArrayOfObjects,
  ValidateArrayResult,
  validateObject,
  ValidateResult,
} from "../../../utils/validation";
import {
  DropdownInput,
  DropdownOptionProps,
} from "../../../components/DropdownInput";
import { colors } from "../../../styles/variables";
import * as S from "./styles";
import { actions, otherActions } from "../config";
import { Modal } from "../../../components/LoadingModal";
import { RootState } from "../../../redux";
import { idTypes, BusinessTypesEnum } from "../constants";
import { CompanyInterface } from "../../../redux/company/ICompany";
import {
  setOperatorsApproverRoute,
  setOperatorsLimitRoute,
} from "../../../routing/setOperators";
import { InfoConponent } from '../../../components/Info';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { MinimizedInfo } from '../../../components/MinimizedInfo';
import { ptr } from '../../../styles/utils';
import { warning } from '../../../components/Toasts';

const SCHEME = {
  role: (value: string) => value.length > 0,
};

const COLLECTION_SCHEME = {
  idNumber: (value: string) => value.length > 5,
  idType: (value: string) => !!value,
  phone: (value: string) => value.length === 11,
  bvn: (value: string) => value.length === 11,
  email: (value: string) => validator.isEmail(value),
  role: (value: string) => value.length > 0,
};

const directorDefaultItem = {
  idNumber: '',
  idType: '',
  phone: '',
  bvn: '',
  email: '',
  role: '',
};

interface ExtendedSimpleFormProps extends SimpleFormProps {
  role: string;
}

interface IProps {
  closeOperator?: () => void;
  handleContinue?: (data: {
    workflowSelected?: string;
    actionRequired?: string;
  }) => void;
}

const CommonOtherApproverOperators: React.FC<IProps> = ({ ...props }) => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  const location = useLocation();

  const [idNumber, setIdNumber] = useState<string>('');
  const [idType, setIdType] = useState<DropdownOptionProps | null>(null);
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();
  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );
  const storedRole = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.role as string
  );
  const storedOperators = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.operators as Array<OperatorInterface>
  );

  const preference = useSelector((store: RootState) => store.preference);

  /**
   * Local state initiation region
   */
  const [role, setRole] = useState<string>(
    storedRole?.toLowerCase() !== 'superadmin' ? storedRole : ''
  );
  const [collection, setCollection] = useState<Array<ExtendedSimpleFormProps>>(
    () => (storedOperators?.length ? storedOperators : [directorDefaultItem])
  );

  const [validation, setValidation] = useState<ValidateResult>();
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);
  const [verificationRequired, setVerificationRequired] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const push = () => {
    if (validationCollection?.isValid) {
      const newCollection = collection.map((coll) => {
        coll.isAdded = true;
        coll.isEdit = false;

        return coll;
      });
      setCollection([...newCollection, directorDefaultItem]);
    }
  };

  const editInfo = (index: number) => {
    const newCollection = [...collection];
    newCollection[index].isEdit = true;
    setCollection([...newCollection]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: string) => {
    setCollection(
      (old) =>
        old.map((el: ExtendedSimpleFormProps, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }
          return el;
        }) as Array<ExtendedSimpleFormProps>
    );
  };

  const continueHandle = () => {
    const operators = collection.map((item) => {
      const operator = { ...defaultOperator };

      operator.role = item.role?.toLowerCase();
      operator.email = item.email;
      // operator.nin = item.nin;
      operator.phone = item.phone;
      operator.idType = item.idType;
      operator.idNumber = item.idNumber;
      operator.bvn = item.bvn;

      return operator;
    });

    (async () => {
      setLoading(true);
      try {
        let response: any;
        if (
          location.pathname === setOperatorsApproverRoute ||
          props.handleContinue
        ) {
          response = await onboardingService.submitBusinessOperators({
            ownRole: role,
            operators,
            workflow: preference.workflow.initVerAuth,
          });
          dispatch(
            OnboardingActions.handleSetData({
              operators: response.operators || operators,
              role,
              workflow: preference.workflow.initVerAuth,
            })
          );
          if (response.actionRequired === 'SUBMIT_OPERATORS_BVN_VERIFICATION') {
            setVerificationRequired(true);
            warning(
              'Pending',
              'An email has been sent to your operator(s) to verify the provided bvn.'
            );
            setLoading(false);
            return;
          }
          //enable reload and trigger state change
          if (props.handleContinue) {
            props.handleContinue({
              workflowSelected: '/onboarding/approval-limit',
            });
          } else {
            window.location.href = '/dashboard';
          }
        } else {
          response = await onboardingService.submitBusinessOperatorsInfo({
            ownRole: role,
            operators,
            reference,
            workflow: preference.workflow.initVerAuth,
          });
          const userOperators =
            response.data?.operators ||
            response?.data?.data?.operators ||
            operators;
          batch(() => {
            dispatch(
              OnboardingActions.handleSetData({
                reference: response.data.reference,
                operators: userOperators,
                role,
                workflow: preference.workflow.initVerAuth,
              })
            );
            dispatch(
              OnboardingActions.handleSetOnBoardingSummaryResponse(
                response.data
              )
            );
          });
          if (response.actionRequired === 'SUBMIT_OPERATORS_BVN_VERIFICATION') {
            setVerificationRequired(true);
            warning(
              'Pending',
              'An email has been sent to your operator(s) to verify the provided bvn.'
            );
            setLoading(false);
            return;
          }
          route.push(actions[response.actionRequired]);
          debug.info('', response); // TEMPORARY
        }
      } catch (error) {
        const err = error as any;
        if (err.code === 'INVALID_ACTION_REQUIRED') {
          route.push(
            actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
          );
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || 'Can not complete request at the moment'
            )
          );
        }
      }
      setLoading(false);
    })();
  };

  const verifyBvn = async () => {
    setLoading(true);
    try {
      let response: any;
      if (
        location.pathname === setOperatorsApproverRoute ||
        props.handleContinue
      ) {
        const operators = collection.map((item) => {
          const operator = { ...defaultOperator };

          operator.role = item.role?.toLowerCase();
          operator.email = item.email;
          // operator.nin = item.nin;
          operator.phone = item.phone;
          operator.idType = item.idType;
          operator.idNumber = item.idNumber;
          operator.bvn = item.bvn;

          return operator;
        });
        response = await onboardingService.submitBusinessOperators({
          ownRole: role,
          operators,
          workflow: preference.workflow.initToAuth,
          confirm: true,
        });
        dispatch(
          OnboardingActions.handleSetData({
            operators: response.operators || operators,
            role,
            workflow: preference.workflow.initToAuth,
          })
        );
        // route.push(setOperatorsLimitRoute);
        //enable reload and trigger state change
        if (props.handleContinue) {
          props.handleContinue({
            workflowSelected: '/onboarding/approval-limit',
          });
          return;
        }
        window.location.href = '/dashboard';
      } else {
        response = await onboardingService.verifyBusinessOperatorsInfo({
          reference,
        });
        const userOperators = response.data.operators;
        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              reference: response.data.reference,
              operators: userOperators,
              role,
            })
          );
          dispatch(
            OnboardingActions.handleSetOnBoardingSummaryResponse(response.data)
          );
        });

        route.push(actions[response.actionRequired]);
        debug.info('', response); // TEMPORARY
      }
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    if (collection && collection.length) {
      setValidationCollection(
        validateArrayOfObjects(collection, COLLECTION_SCHEME)
      );
    }
    setIdNumberValidation(
      validateObject(
        {
          idType,
          idNumber,
        },
        idNumberScheme
      )
    );
  }, [collection, idType, idNumber]);

  useEffect(() => {
    setValidation(validateObject({ role }, SCHEME));
  }, [role]);

  return (
    <S.Container>
      <Title title='Operators' color={colors.black._20} />
      <S.InfoContainer>
        <InfoConponent text='You will also have the role of super admin who can create, disable, or modify other user roles.' />
      </S.InfoContainer>

      <OnboardingCard>
        <S.Head>
          <Text bold color={colors.black._100}>
            Select your role
          </Text>

          <CheckAreaGroup
            onChange={setRole}
            chosenIndex={
              role
                ? preference.defaultRoles
                    ?.filter(
                      (defaultRole) => defaultRole.value !== 'Superadmin'
                    )
                    .findIndex((ro) => ro.value === role)
                : undefined
            }
            items={
              preference.defaultRoles?.filter(
                (defaultRole) => defaultRole.value !== 'Superadmin'
              ) || []
            }
          />
        </S.Head>
        <S.Wrapper>
          {collection.map((item, index) => (
            <>
              {!item.isAdded || item.isEdit ? (
                <SimpleForm3
                  idType={item.idType}
                  idNumber={item.idNumber}
                  phone={item.phone}
                  bvn={item.bvn}
                  email={item.email}
                  index={index}
                  remove={remove}
                  //   onSelectId={setIdType}
                  // onChangeId={setIdNumber}
                  onChange={onChangeValue}
                  emailIsValid={
                    validationCollection?.data[index]?.data.email.isValid
                  }
                  bvnIsValid={
                    validationCollection?.data[index]?.data?.bvn?.isValid
                  }
                  idNumberIsValid={
                    validationCollection?.data[index]?.data?.idNumber?.isValid
                  }
                  phoneIsValid={
                    validationCollection?.data[index]?.data?.phone?.isValid
                  }
                  title='Provide details of other operators'
                />
              ) : (
                <MinimizedInfo
                  title={`${item.email} - ${item.role}`}
                  description={`${item.phone} || ${item.idType} || ${item.idNumber}`}
                  handleEdit={() => editInfo(index)}
                  handleDelete={() => remove(index)}
                />
              )}
              {(!item.isAdded || item.isEdit) && (
                <S.OperatorRole>
                  <Text bold color={colors.black._100}>
                    Operator’s roles
                  </Text>
                  <CheckAreaGroup
                    onChange={(value) => onChangeValue(index, 'role', value)}
                    chosenIndex={
                      item.role
                        ? preference.defaultRoles
                            ?.filter(
                              (defaultRole) =>
                                defaultRole.value !== 'Superadmin'
                            )
                            .findIndex(
                              (ro) =>
                                ro.value?.toLowerCase() ===
                                item.role?.toLowerCase()
                            )
                        : undefined
                    }
                    items={
                      preference.defaultRoles?.filter(
                        (defaultRole) => defaultRole.value !== 'Superadmin'
                      ) || []
                    }
                  />
                </S.OperatorRole>
              )}
            </>
          ))}
        </S.Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <S.Add onClick={push}>
            <Icon name='plus' color={colors.black._100} />
            <Text color={colors.black._80}>Add another operator</Text>
          </S.Add>

          {collection.length > 1 && (
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                marginBottom: ptr(40),
                cursor: 'pointer',
              }}
              onClick={() =>
                collection.length > 1 && remove(collection.length - 1)
              }
            >
              <Icon name='cancel' color={colors.red._100} />
              <Text size={6} color={colors.red._100}>
                Cancel
              </Text>
            </div>
          )}
        </div>
        <S.ButtonContainer>
          <Button
            loading={loading}
            label='Continue'
            onClick={verificationRequired ? verifyBvn : continueHandle}
            disabled={
              !validation?.isValid || !validationCollection?.isValid || loading
            }
          />
        </S.ButtonContainer>
      </OnboardingCard>
      <Modal visible={loading} />
    </S.Container>
  );
};
export default CommonOtherApproverOperators;
