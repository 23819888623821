import React, { useState, useEffect } from 'react';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';
import { Button } from '../Button';
import { TextInput } from '../TextInput';
import { validateObject, ValidateResult } from '../../utils/validation';

export interface AddNewBeneficiaryTypeModalProps {
  onCloseModal?: () => void,
  visible?: boolean,
  setType: (item: { name: string }) => void,
  loading: boolean
}

const SCHEME = {
  type: (value = '') => value.length > 0,
};

export const AddNewBeneficiaryTypeModal: React.FC<AddNewBeneficiaryTypeModalProps> = ({
  visible = false,
  onCloseModal = () => {},
  ...props
}) => {
  const [type, setType] = useState<string>('');
  const [validation, setValidation] = useState<ValidateResult | undefined>(undefined);

  useEffect(() => {
    setValidation(validateObject({ type }, SCHEME));
  }, [type]);

  useEffect(() => {
    if (!visible) {
      setType('');
    }
  }, [visible]);

  return (
    <ModalWrapper showModal={visible} onCloseModal={onCloseModal} crossColor={colors.black._100}>
      <S.Wrapper>
        <Text size={2} bold color={colors.pink._100}>
          Add new beneficiary type
        </Text>
        <TextInput label="Beneficiary type" value={type} onChange={setType} />
        <Button
          size="medium"
          label="Add new beneficiary type"
          disabled={!validation?.isValid || props.loading}
          onClick={() => {
            props.setType({
              name: type,
            });
          }}
        />
      </S.Wrapper>
    </ModalWrapper>
  );
};
