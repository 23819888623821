import React, { } from 'react';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';
import { Button } from '../Button';
import { RoundButton } from '../RoundButton';

export interface ModalProps {
  onCloseModal?: () => void
  visible?: boolean
  showRoundButton?:boolean
  title?: string
  description?: string
  buttonTitle?: string
  action?: () => void
  secondButtonTitle?: string
  prompt?: boolean
  actionLoading?:boolean
}

export const Modal: React.FC<ModalProps> = ({
  visible = false,
  onCloseModal = () => {},
  showRoundButton = true,
  title = 'Done!',
  description = 'Your transaction pin has been changed successfully',
  action = () => {},
  prompt = false,
  buttonTitle = 'Got it',
  actionLoading = false,
  secondButtonTitle = 'Close',
}) => (
  <ModalWrapper showModal={visible} onCloseModal={onCloseModal} overflowX="hidden" crossColor={colors.black._100}>
    <S.Wrapper>
      {showRoundButton && <RoundButton icon="check-mark" />}
      <S.TextContainer>
        <Text bold color={colors.pink._100} size={2}>{title}</Text>
      </S.TextContainer>

      <S.TextContainer>
        <Text color={colors.black._60} size={5}>{description}</Text>
      </S.TextContainer>
      {!prompt ? (
        <S.ModalButton>
          <Button
            onClick={action}
            disabled={actionLoading}
            loading={actionLoading}
            label={buttonTitle}
          />
        </S.ModalButton>
      )

        : (
          <S.ModalPromptButtons>
            <Button
              onClick={onCloseModal}
              backgroundColor={colors.pink._20}
              color={colors.pink._100}
              label={secondButtonTitle}
            />
            <Button loading={actionLoading} onClick={action} label={buttonTitle} />
          </S.ModalPromptButtons>
        )}
    </S.Wrapper>

  </ModalWrapper>
);
