import styled from 'styled-components';
import { colors } from '../../../../styles/variables';
import { ptr } from '../../../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${ptr(40)};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(6)};
  margin-top: ${ptr(32)};
  flex-wrap: wrap;
`;
const IconWrapper = styled.div`
  width: ${ptr(48)};
  height: ${ptr(48)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black._5};
  border-radius: ${ptr(6)};
  margin-right: ${ptr(16)};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${ptr(8)};
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const AmountWrapper = styled.div`
  margin-top: ${ptr(4)};
`;
export {
  Wrapper,
  IconWrapper,
  Row,
  Column,
  AmountWrapper,
};
