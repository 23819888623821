import { FC } from 'react';
import * as S from './styles';
import { Text } from '../Text';
import { colors } from '../../styles/variables';
import { Icon } from '../Icon';

export const MinimizedInfo: FC<{
  title: string;
  description: string;
  handleEdit: () => void;
  handleDelete: () => void;
}> = ({ title, description, handleEdit, handleDelete }) => {
  return (
    <S.Wrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div>
          <Text bold size={5} color={colors.black._20}>
            {title}
          </Text>
        </div>
        <div>
          <Text size={5} color={colors.black._20}>
            {description}
          </Text>
        </div>
      </div>
      <S.ActionButtons>
        <div style={{ cursor: 'pointer' }}>
          <Icon name='edit' onClick={handleEdit} />
        </div>
        <div style={{ cursor: 'pointer' }}>
          <Icon name='delete' onClick={handleDelete} />
        </div>
      </S.ActionButtons>
    </S.Wrapper>
  );
};
