import styled from 'styled-components';

import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${ptr(8)};
  border: 1px solid ${colors.black._10};
  background:white;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Table = styled.table`
background:white;
  display: table;
  width: 100%;
`;

const Label = styled.div`
  padding: 30px;
`;
export {
  Label,
  TableWrapper,
  Table,
};
