import styled from 'styled-components';

const Container = styled.div``;

const Image = styled.img`
  max-width: 86vw;
  max-height: 96vh;
`;

export { Container, Image };
