/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { Title } from '../../../Onboarding/components/Title';
import { Text } from '../../../../components/Text';
import { Icon } from '../../../../components/Icon';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
  ValidateResult,
  validateObject,
} from '../../../../utils/validation';
import { colors } from '../../../../styles/variables';
import * as S from '../styles';
import { InfoForm2 } from './InfoFormSubaccount';
import { idTypes, BusinessTypesEnum } from '../../constants';
import { MinimizedInfo } from '../../../../components/MinimizedInfo';
import { OnboardingCard } from '../../../../components/OnboardingCard';
import { ptr } from '../../../../styles/utils';
import { InfoConponent } from '../../../../components/Info';
import { SubaccountInterface } from '../../../../redux/subaccount/ISubaccount';
import { CompanyInterface } from '../../../../redux/company/ICompany';
import { ConsentSample } from '../../../Onboarding/components/ConsentSample';
import subaccountService from '../../../../services/internal/subaccount/subaccount.service';
import { failed } from '../../../../components/Toasts';

const SCHEME = {
  phone: (value: string) => value.length === 11,
  idType: (value: string) => value.length > 1,
  email: (value: string) => validator.isEmail(value),
};

const directorDefaultItem = {
  phone: '',
  email: '',
  idNumber: '',
  idType: '',
};

const AddDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();
  const [idNumber, setIdNumber] = useState<string>('');
  const [idType, setIdType] = useState<any>();
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();
  /**
   * Redux store initiation region
   */

  const subaccountRequest = useSelector(
    (state: { subaccount: SubaccountInterface }) =>
      state.subaccount.subaccountRequest
  );

  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );


  const businessType = activeCompany?.accounts[0]?.type;

  const leaders = subaccountRequest?.business?.BusinessLeader ?? [];

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<any>>(
    leaders?.length
      ? leaders.map((item) => ({
          phone: item.phone,
          email: item.email,
          idNumber: item.idNumber,
          idType: item.idType,
        }))
      : [directorDefaultItem]
  );
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);
  const [modalVisible, seModalVisible] = useState<boolean>(false);

  /**
   * Custom handlers initiation region
   */

  const onCloseModal = () => {
    seModalVisible(false);
  };

  const push = () => {
    if (validationCollection?.isValid) {
      const newCollection = collection.map((coll) => {
        coll.isAdded = true;
        coll.isEdit = false;
        return coll;
      });
      setCollection([...newCollection, directorDefaultItem]);
    }
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: any, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }

          return el;
        }) as Array<any>
    );
  };

  const getIdType = (value: any) => {
    setIdType(value);
  };

  const getIdNumber = (value: any) => {
    setIdNumber(value);
  };

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
    setIdNumberValidation(
      validateObject(
        {
          idType,
          idNumber,
        },
        idNumberScheme
      )
    );
  }, [collection, idType, idNumber]);

  const continueHandle = async () => {
    setLoading(true);
    const newCollection = collection.map((_collection) => {
      delete _collection.isAdded;
      delete _collection.isEdit;
      return _collection;
    });
    try {
      if (subaccountRequest) {
        const response = await subaccountService.createConsent({
          requestId: subaccountRequest?.id,
          data: newCollection,
        });
        route.push('/subaccount/preview-details');
      }
    } catch (error) {
      const err = error as any;
      failed('failed', err.message || 'An error occured, please try again');
    }
    setLoading(false);
  };

  const editInfo = (index: number) => {
    const newCollection = [...collection];
    newCollection[index].isEdit = true;
    setCollection([...newCollection]);
  };

  function getLabel() {
    switch (businessType) {
      case BusinessTypesEnum.soleProprietorship:
        return 'proprietor';
      case BusinessTypesEnum.partnership:
        return 'partner';
      case BusinessTypesEnum.trusteeNgo:
        return 'trustee';
      case BusinessTypesEnum.cooperativeSociety:
        return 'signatory';
      default:
        return 'director';
    }
  }

  return (
    <S.Container>
      <ConsentSample
        visible={modalVisible}
        onClose={onCloseModal}
        image='../../images/documents/certificate-3.jpeg'
      />
      <S.TopTitle>
        <Title
          color={colors.black._20}
          title='Who will consent to onboarding the account on VBiz?'
          subTitle={`The option is from the ${getLabel()} provided during the main account opening.`}
        />
      </S.TopTitle>
      <S.TopTextInfo onClick={() => seModalVisible(true)}>
        <Icon color='#B10180' name='info' />
        <Text bold size={7} color='#B10180'>
          View sample of consent letter
        </Text>
      </S.TopTextInfo>
      <OnboardingCard>
        <S.InfoContainer>
          <InfoConponent
            style={{
              background: '#FFF9EF',
            }}
            text={`Sorry no ${getLabel()} information was found for this business.`}
          />
        </S.InfoContainer>
        <div style={{ marginBottom: 20 }}>
          <Text bold color={colors.black._20}>
            {`Provide details of the ${getLabel()} that will sign the consent letter`}
          </Text>
        </div>
        {collection.map((item, index) => (
          <div
            style={{
              marginBottom: '30px',
            }}
          >
            {!item.isAdded || item.isEdit ? (
              <InfoForm2
                title={`${
                  businessType === BusinessTypesEnum.partnership
                    ? "Partner's"
                    : businessType === BusinessTypesEnum.cooperativeSociety
                    ? "Signatory's"
                    : businessType === BusinessTypesEnum.trusteeNgo
                    ? "Trustee's"
                    : "Director's"
                } details`}
                phone={item.phone}
                bvn={item.bvn}
                idNumber={item.idNumber}
                idType={item.idType}
                email={item.email}
                onSelectId={getIdType}
                onChangeId={getIdNumber}
                index={index}
                removeFromIndex={1}
                remove={remove}
                onChange={onChangeValue}
                emailIsValid={
                  validationCollection?.data[index]?.data.email.isValid
                }
                phoneIsValid={
                  validationCollection?.data[index]?.data.phone.isValid
                }
              />
            ) : (
              <MinimizedInfo
                title={`${item.idNumber} - (${item.idType})`}
                description={`${item.phone} || ${item.email}`}
                handleEdit={() => editInfo(index)}
                handleDelete={() => remove(index)}
              />
            )}
          </div>
        ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {businessType !== BusinessTypesEnum.soleProprietorship && (
            <S.Add onClick={push}>
              <Icon name='plus' color='#000000' />
              <Text color='#7F91A8'>{`Add ${
                businessType === BusinessTypesEnum.partnership
                  ? 'Partner'
                  : businessType === BusinessTypesEnum.cooperativeSociety
                  ? 'Signatory'
                  : businessType === BusinessTypesEnum.trusteeNgo
                  ? 'Trustee'
                  : 'Director'
              }`}</Text>
            </S.Add>
          )}

          {collection.length > 1 && (
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                marginBottom: ptr(40),
                cursor: 'pointer',
              }}
              onClick={() =>
                collection.length > 1 && remove(collection.length - 1)
              }
            >
              <Icon name='cancel' color={colors.red._100} />
              <Text size={6} color={colors.red._100}>
                Cancel
              </Text>
            </div>
          )}
        </div>

        <S.ButtonGroup>
          <div>
            <Button
              label='Continue'
              loading={loading}
              disabled={
                !validationCollection?.isValid ||
                !idNumberValidation?.isValid ||
                loading
              }
              onClick={continueHandle}
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
export default AddDetails;
