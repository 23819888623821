import React from 'react';

import * as S from './styles';

interface TwoColumnsWithFirstColumnTitle {
  title: string,
  description: string,
}

export interface TableGeneratorProps {
  /**
   * Table data
   */
  data: Array<TwoColumnsWithFirstColumnTitle>,
}

export interface CellProps {
  bold?: boolean,
}

export const TableGenerator: React.FC<TableGeneratorProps> = ({ ...props }) => (
  <S.TableWrapper>
    {props.data.map((row) => (
      <S.TableRow>
        <S.TableCell bold>{row.title}</S.TableCell>
        <S.TableCell>{row.description}</S.TableCell>
      </S.TableRow>
    ))}
  </S.TableWrapper>
);
