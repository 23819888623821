import React,
{ useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { BusinessType, getBusinessRequestType, OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';

import { validateObject, ValidateResult } from '../../../utils/validation';

import * as S from './styles';
import { errorToString } from '../../../services/internal/app/index';
import { actions } from '../config';

const SCHEME = {
  businessName: (value: string) => value.length > 0,
};

const CommonPersonalAccBusinessName: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const credentials = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.credentials as Record<string, string>,
  );
  const business = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.business as Record<string, string>,
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.reference as string,
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.businessType as BusinessType,
  );

  // const hasVBankAcc = useSelector(
  //   (state: { onboardingStack: OnboardingInterface }) => state
  //     .onboardingStack.hasVBankAccount as boolean,
  // );
  /**
   * Local state initiation region
   */
  const [businessName, setBusinessName] = useState<string>(business.name || '');
  const [validation, setValidation] = useState<ValidateResult>();
  const [loading, setLoading] = useState(false);
  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.submitBusinessInfo({
          business: {
            name: businessName,
            phone: business.phone || '',
            primaryEmail: credentials.personalEmail,
            secondaryEmail: credentials.businessEmail,
            bvn: business.bvn || '',
            tin: business.tin || '',
            cac: business.cac || '',
            type: getBusinessRequestType(businessType),
          },
          reference,
        });
        batch(() => {
          dispatch(OnboardingActions.handleSetData({
            reference: response.data.reference,
            business: {
              ...business,
              name: businessName,
            },
          }));
        });
        route.push(actions[response.actionRequired]);
        debug.info('', response); // TEMPORARY
      } catch (err) {
        dispatch(OnboardingActions.handleSetError(errorToString(err)));
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(validateObject({ businessName }, SCHEME));
  }, [businessName]);

  return (
    <S.Container>
      <Title title="Business name" />
      <S.Wrapper>
        <TextInput label="Enter your business name" value={businessName} onChange={setBusinessName} />
      </S.Wrapper>
      <Button label="Continue" loading={loading} onClick={continueHandle} disabled={!validation?.isValid || loading} />
    </S.Container>
  );
};
export default CommonPersonalAccBusinessName;
