import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Wrapper = styled.div`
  padding: ${ptr(64)};
  width: 36vw;
  overflow: auto; 
  /* ::-webkit-scrollbar {
  display: none;
}
 -ms-overflow-style: none;
  scrollbar-width: none; */
  > span {
    margin-top: ${ptr(16)}
  }
    @media (max-width: ${breakpoints.sm}px) {
    flex: 1;
    width: inherit;
    height: 70vh;
    padding:30px;
    overflow: scroll; 
  }
  @media (max-height: 600px) {
    height: 60vh;
  }
`;

const TextContainer = styled.div`
  margin-top: ${ptr(4)};
  margin-bottom: ${ptr(32)};

  & + div {
    height: ${ptr(240)}
  }
`;

const Link = styled.a`
  cursor: pointer;
  color: ${colors.pink._100};
  text-decoration: underline;
`;

const HelpText = styled.div`
  margin-top: ${ptr(12)};
  margin-bottom: ${ptr(40)};

  & + button {
    display: block;
    margin: 0 auto;
  }
`;

export {
  Wrapper,
  TextContainer,
  Link,
  HelpText,
};
