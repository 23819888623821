import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Zendesk from 'react-zendesk';
import reduxStore from './redux';
import SentryService from './services/external/sentry.service';
import Routing from './routing/routing';
import { init as initApm } from '@elastic/apm-rum';

initApm({
  serviceName: 'vbiz-frontend',
  serverUrl:
    'https://4ef2904e5f5d441891dc1083cf6fa76d.apm.eu-west-1.aws.cloud.es.io:443',
  // Set the service version (required for source map feature)
  serviceVersion: '',
  environment: process.env.NODE_ENV,
});

toast.configure();

const App: React.FC = () => {
  useEffect(() => {
    SentryService.getInstance().init();
  }, []);

  const setting = {
    color: {
      theme: '#FF4C25',
    },
    launcher: {
      chatLabel: {
        'en-US': 'Need Help',
      },
      webWidget: {
        chat: {
          departments: {
            enabled: [
              'Account Creation',
              'Transfers',
              'Account Statement',
              'General Issues',
            ],
          },
        },
      },
    },

    contactForm: {
      fields: [
        { id: 'description', prefill: { '*': 'My pre-filled description' } },
      ],
    },
  };

  const ZENDESK_KEY = '2067a893-9995-44c7-b2d5-1d0989df8dff';

  return (
    <Provider store={reduxStore}>
      {/* <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        // onLoaded={() => console.log('is loaded')}
      /> */}
      <Routing />
    </Provider>
  );
};

export default App;
