import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';
import { Text } from '../../../../components/Text';
import { Icon, IconName, IconProps } from '../../../../components/Icon';
import { colors } from '../../../../styles/variables';
import { timeDateMonthString, monthName } from '../../../../utils/datetime';
import {
  DropdownSelect,
  YearDropList,
} from '../../../../components/YearDropList';
import { formatCash } from '../../../../utils/money';
import { YearDropListUpdated } from '../../../../components/YearDropListUpdated';

export interface ItemProps {
  title?: string;
  value?: string;
  sub?: string;
  income?: boolean;
  colour?: string;
  background?: string;
}

export interface AccountItemProps {
  id?: string;
  balance: string;
  nuban: string;
  type: string;
  category?: string;
  isChecked?: boolean;
  setIsChecked?: (value: boolean) => void;
}

export interface CashProps {
  debits: { balance: number; debitCount?: number; sub: string };
  credits: { balance: number; creditCount?: number; sub: string };
  accounts: Array<AccountItemProps>;
  onDropdown?: () => void;
  FilterByYear: any;
  balance: string;
  open?: boolean;
  nuban: string;
  year: number;
  month?: string;
  category?: string;
  data?: Record<string, any>;
}

export interface CashFlowProps {
  open?: boolean;
  FilterByYear?: any;
  year?: number;
  month?: string;
}

export interface UpcomingTransactionsProps {
  /**
   * Example component property
   */
  example?: string;
}

interface MenuItem {
  icon: IconProps['name'];
  name: string;
  amount: string;
  percent: string;
}

const details: Array<MenuItem> = [
  {
    icon: 'profile-bg',
    name: 'Credits',
    amount: '₦1,200,000.00',
    percent: '98.3%',
  },
  {
    icon: 'profile-bg',
    name: 'Debits',
    amount: '₦1,200,000.00',
    percent: '98.3%',
  },
];

const Item: React.FC<ItemProps> = ({
  income = false,
  title = 'aaa',
  sub = '',
  value = 'aa',
  colour = '#fffff',
  background = '',
}) => (
  <S.ItemBox>
    <S.Article1>
      <Text bold color={colors.black._60} size={7}>
        {title}
      </Text>
      <S.Icon income={income}>
        <Icon name='trending-up' color={colour} />
      </S.Icon>
    </S.Article1>
    <S.Article>
      <S.Data>
        <Text color={colors.black._20} size={5}>
          {value}
        </Text>
        <S.Percent style={{ background: background ? '#84B4FC' : '#FC8484' }}>
          <Text size={6}>{sub}</Text>
          <S.Icon income={income}>
            <Icon name='trending-small' color={colour} />
          </S.Icon>
        </S.Percent>
      </S.Data>
    </S.Article>
  </S.ItemBox>
);

const AccountItem: React.FC<AccountItemProps> = ({
  nuban = '',
  category = '',
  isChecked = false,
  setIsChecked = () => {},
}) => {
  const [visibility, setVisibility] = useState(true);
  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <S.AccountItemBox
      onClick={() => {
        setIsChecked(true);
      }}
    >
      <S.AccountItemInfoBlock>
        <div>
          <Text bold color={colors.black._20}>
            Account Name
          </Text>
        </div>
        <div>
          <Text color='#6E7191'>{`(${nuban})`}</Text>
        </div>
      </S.AccountItemInfoBlock>
      <S.AccountItemBalanceBlock>
        {isChecked ? <Icon name='check' /> : ''}
      </S.AccountItemBalanceBlock>
    </S.AccountItemBox>
  );
};

export const CashFlow: React.FC<CashProps> = ({
  nuban,
  debits,
  credits,
  year,
  month,
  category,
  FilterByYear,
  open = false,
  balance = '0',
  accounts = [],
  data,
  // onDropdown = () => {},
}) => {
  const [filterType, setFilterType] = useState<string>('February 2023');
  const [drop, setDrop] = useState(false);

  const dropdownA = (): void => {
    setDrop(!drop);
  };
  return (
    <S.Container data-disabled>
      <S.Head>
        <Text size={5} bold>
          Cashflow Count
        </Text>

        <S.Calendar open={open}>
          <div>
            <YearDropListUpdated
              type='month'
              text={!month ? 'Month' + 'Year' : month + ' ' + year.toString()}
              onChange={FilterByYear}
            />
          </div>
        </S.Calendar>

        {/* <S.Calendar open={open}>
          <DropdownSelect
            color='#ffffff'
            items={['February 2023']}
            text={filterType}
            onChange={(value) => setFilterType(value)}
          />
        </S.Calendar> */}
      </S.Head>

      <S.Company>
        <S.Account>
          <div>
            <Text bold color={colors.black._20} size={5}>
              Account Name
            </Text>
            <div>
              <Text style={{ display: 'flex' }} color='#6E7191' size={5}>
                {category}
              </Text>

              <Text style={{ fontWeight: 600 }} color='#6E7191' size={5}>
                {nuban}
              </Text>
            </div>
          </div>

          <S.IconWrapper onClick={dropdownA} open={open}>
            <Icon
              name='dropdown'
              color={colors.black._100}
              width={24}
              height={24}
            />
          </S.IconWrapper>
        </S.Account>

        {drop ? (
          <S.DropownContent open={open}>
            <div>
              <Text color={colors.black._20} size={4}>
                Available company
              </Text>
              {accounts.map((item, index) => (
                <AccountItem
                  key={`${index.toString()}`}
                  balance={item.balance}
                  nuban={item.nuban}
                  type={item.type}
                  category={item.category}
                  isChecked={item.isChecked}
                  setIsChecked={item.setIsChecked}
                />
              ))}{' '}
            </div>
          </S.DropownContent>
        ) : (
          ''
        )}
      </S.Company>
      <S.Transaction>
        <Text color='#6E7191' size={5}>
          {data?.total_transaction_count ?? 0} Transactions worth
        </Text>

       { data && <Text bold color={colors.black._20} size={4}>
          {`₦ ${formatCash(
            Number(data?.total_debit_amount) + Number(data?.total_credit_amount)
          )}`}
        </Text>}
      </S.Transaction>

      {/* <S.Charts>
        {details.map((items, index) => {
          return (
            <S.ChartsInner>
              <Icon
                width={36}
                height={36}
                name={items.icon}
                color={colors.black._100}
              />
            </S.ChartsInner>
          );
        })}
      </S.Charts> */}

      <S.BottomContainer>
        <Item
          title='Credits'
          value={` ${+credits.balance < 0 ? '-' : ''}₦ ${
            formatCash(credits.balance) || '0'
          }`}
          // sub={` ${+credits.sub}%  `}
          colour={colors.green._20}
        />
        <Item
          income
          title='Debits'
          value={` ${+debits.balance < 0 ? '-' : ''}₦ ${formatCash(
            debits.balance
          )}`}
          // sub={` ${+debits.balance}%  `}
          colour='#ED405C'
          background='#84B4FC'
        />
      </S.BottomContainer>
    </S.Container>
  );
};
