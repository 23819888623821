 
import { breakpoints, colors, transition } from '../../styles/variables';
import styled, { css } from 'styled-components';
import { crossBrowserTransition, ptr } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  
`;

const DropWrapper = styled.div`
display: flex;
align-items: center; 
`;

const PhotoWrapper = styled.div`
  height: ${ptr(48)};
  width: ${ptr(48)};
  padding: 0 ${ptr(14)} 0 ${ptr(40)};
  @media (max-width: ${breakpoints.sm}px) {
    padding: 0 ${ptr(14)} 0 0;
  }
`;

const PhotoWrapper2 = styled.div`
  height: ${ptr(48)};
  width: ${ptr(48)};
  margin-right:${ptr(10)} ;
  /* padding: 0 ${ptr(14)} 0 ${ptr(40)}; */
`;

const IconName = styled.div`
display:flex;
flex-direction:column;
gap:4px;
`;

const Photo = styled.img`
  height: ${ptr(48)};
  width: ${ptr(48)};
  border-radius: 50%;
  object-fit: cover;
`;

const BellWrapper = styled.div<{ open: boolean }>`
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;
const LogOutWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  gap:8px; 
  align-items: center;
  /* padding-left: ${ptr(19)}; */
  padding-top:${ptr(10)};
  margin-top:${ptr(32)};
  border-top: 1px solid ${colors.black._10};
`;

const ButtonWrapper= styled.div`
/* padding: 0 ${ptr(14)} 0 ${ptr(40)}; */
margin-top: ${ptr(14)};
`;

const DropdownWrapper = styled.div`
cursor: pointer;
padding-left: ${ptr(12)};

`;



const Company = styled.div`
  position: relative;
`;

const DropownContent = styled.div`
display:block;
  position: absolute;
  top: 49px;
  right: 0;
  min-width: 344px;
width: fit-content; 
  height: fit-content;
  overflow: auto;
  background: ${colors.white._100};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  z-index: 9999;
  padding: 33px 15px 26px 15px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 8px 24px -6px rgba(0, 0, 0, 0.06);
  border-top: ${ptr(1)} solid ${colors.black._10};
  ${crossBrowserTransition(transition.slow)}

  @media (max-width: ${breakpoints.sm}px) {
   /* left:50px; */
   right:-30px;
  }
`;


const DropownContent1 = styled.div<{ open: boolean }>`
display: ${(props) => (props.open ? css`block` : css`block`)};
  position: absolute;
  top: 49px;
  right: 0;
  width: 424px; 
  height: fit-content;
   
  background: ${colors.white._100};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  z-index: 9999;
  padding: 33px 0px 26px 0px;

  ${(props) =>
    props.open
      ? css`
          border-top: ${ptr(1)} solid ${colors.black._10};
        `
      : css`
          border-top: ${ptr(0)} solid ${colors.black._10};
        `}

  ${crossBrowserTransition(transition.slow)}
`;

const NotificationTop = styled.div`
padding: 24px;
display: flex;
align-items: center; 
justify-content: space-between;
border-bottom: 1px solid #D0D5DD;
`;

const SeeAll = styled.div`
  border-radius: 28px;
  border: 0.8px solid var(--gray-300, #d0d5dd);
  background: var(--white, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 6px 12px; 
  align-items: center; 
`;

const NotificationBottom = styled.div`
 display:flex;
 gap:16px; 

 padding: 16px 24px;

 :hover {
  cursor: pointer;
  background:#EFF0F6;
}
`;


const BottomLhs = styled.div`
 

>svg{
  background: #F7F7FC;
  display: flex;
  border-radius: 30px;
padding: 12px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
`;

const BottomRhs = styled.div`
display:flex;
flex-direction:column;
`;
 
export {
  Wrapper,
  PhotoWrapper,
  PhotoWrapper2,
  IconName,
  Photo,
  Company,
  SeeAll,
  BottomLhs,
  BottomRhs,
  BellWrapper,
  LogOutWrapper,
  ButtonWrapper,
  DropdownWrapper,
  DropownContent1,
  DropWrapper,
  DropownContent, 
  NotificationTop,
  NotificationBottom
};
