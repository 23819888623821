import { ActionInterface } from '../IAction';
import { MainMenuInterface } from './IMainMenu';
import { OPEN, CLOSE, TOGGLE } from './actions';

const initialState = {
  opened: false,
  firstOpen: true,
};

const reducer = (state = initialState, action: ActionInterface<unknown>): MainMenuInterface => {
  switch (action.type) {
    case OPEN:
      return {
        opened: true,
        firstOpen: false,
      };
    case CLOSE:
      return {
        ...state,
        opened: false,
      };
    case TOGGLE:
      return {
        opened: !state.opened,
        firstOpen: false,
      };
    default:
      return state;
  }
};

export default reducer;
