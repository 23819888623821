import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';

import ErrorPayments from './payments.error';

import {
  BusinessInterface,
  ActiveBusinessInterface,
  AccountInterface,
  PaymementAccountInterface,
  PaymentsNonParsedInterface,
  BulkPaymentsNonParsedInterface,
  BulkPaymentsSendTransferInterface,
  IGetStatement,
  IApprovePayment,
  IGenerateUssdCodeData,
  IInitializeLimitRequest,
  ILimitRequestResponse,
} from './IPayments';

import debug from '../debbug.service';

import { groupBy } from '../../../utils/collections';
import { PaymentValidationErrorInterface } from '../../../redux/payment/IPayment';

class PaymentsService {
  accountValidationErrors: PaymentValidationErrorInterface[] = [];

  private apiEndpoints = {
    payments: (queryString: string) => `payments${queryString}`,
    batchPayment: (queryString: string) => `batches${queryString}`,
    batchPaymentId: (id: string, queryString: string) =>
      `batches/${id}${queryString}`,
    approvePayments: 'payments/approve',
    rejectPayments: 'payments/reject',
    bulkPayments: 'payments/bulk',
    business: {
      root: 'business',
      active: 'business/active',
      accounts: (id: string) => `business/${id}/accounts`,
      users: (id: string) => `business/${id}/users`,
      switch: (id: string) => `business/switch/${id}`,
      statement: (queryString: string, accountId: string) =>
        `payments/accounts/${accountId}/statement${queryString}`,
    },
    bulk: 'bulk',
    getBankNames: 'banks',
    Analytics: (accountId: string, queryString: string) =>
      `payments/accounts/${accountId}/analytics${queryString}`,
    switchaccount: (businessId: string) => `business/switch/${businessId}`,
    generateRebateUssdCode: 'rebate',
    dailyLimit: 'daily-limits',
  };

  //
  public async makeTransfer(
    data: BulkPaymentsNonParsedInterface,
    checkPin: boolean,
    pin?: string,
    otp?: string,
    batch?: string
  ) {
    try {
      const userPin = pin;
      const userOtp = otp;

      const groupByPaymentAccount = groupBy(data.payments, 'paymentAccount');
      const formattedData: BulkPaymentsSendTransferInterface = {
        transactions: Object.entries(groupByPaymentAccount).map(
          ([paymentAccount, payments]) => ({
            ...(batch ? { batch } : {}),
            to: (payments as Array<PaymentsNonParsedInterface>).map((item) => ({
              ...(item.beneficiary?.length && {
                beneficiary: item.beneficiary,
              }),
              ...(item.receiverBankCode && { bankCode: item.receiverBankCode }),

              ...(item.receiverNuban && { nuban: item.receiverNuban }),

              ...(item.receiverPaymentAccount && {
                accountId: item.receiverPaymentAccount,
              }),
              comment: item.comment,
              amount: item.amount,
            })),
            from: {
              accountId: paymentAccount,
            },
          })
        ),
        checkPin,
        pin: userPin,
        otp: userOtp,
      };

      const request = await apiService.request.post(
        this.apiEndpoints.bulkPayments,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(formattedData),
        }
      );

      const result = await request.json();

      debug.success('Transfer result', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed make transfer', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getBankNames() {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.getBankNames}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      debug.success('Result of receiving bank names', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get bank names', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getPayments(queryString?: string) {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.payments(queryString || '')}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      debug.success('The result of receiving payments', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get payments', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code || result.name,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }
  // public async getBatchPayment(queryString?: string) {
  //   try {
  //     const request = await apiService.request.get(`${this.apiEndpoints.batchPayment(queryString || '')}`, {
  //       headers: {
  //         'content-type': 'application/json',
  //       },
  //     });

  //     const result = await request.json();
  //     debug.success('The result of receiving batch', result);
  //     return result;
  //   } catch (globalError: any) {

  //     debug.error('Failed to get batch payments', globalError);
  //     try {
  //       if (globalError.response) {
  //         const result = await globalError.response.json();
  //         return Promise.reject(new ErrorPayments({
  //           message: result.message,
  //           code: result.code || result.name,
  //         }));
  //       }

  //       return Promise.reject(new ErrorPayments({
  //         message: globalError.message,
  //         code: globalError.code,
  //       }));

  //     } catch (localError) {
  //       return ErrorApi.throwError(ErrorApi.ServerError);
  //     }
  //   }
  // }

  public async getBatchPayment(queryString?: string) {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.batchPayment(queryString || '')}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      debug.success('The result of receiving batch', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get batch payments', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code || result.name,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async approvePayments(data: IApprovePayment) {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.approvePayments}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const result = await request.json();

      debug.success('The result of approving payments', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to approve payments', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code || result.name,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async rejectPayments(data: IApprovePayment) {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.rejectPayments}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const result = await request.json();

      debug.success('The result of rejecting payments', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to reject payments', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code || result.name,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getBusinesses(): Promise<Array<BusinessInterface>> {
    try {
      const request = await apiService.request.get(
        this.apiEndpoints.business.root,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      return (await request.json()) as Array<BusinessInterface>;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get payments', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code || result.name,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  public async getSingleBusiness(id: string): Promise<ActiveBusinessInterface> {
    try {
      // For getting special business payment accounts with business ID
      const response = await apiService.request.get(
        `${this.apiEndpoints.business.root}/${id}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      const activeBusiness = (await response.json()) as ActiveBusinessInterface;
      return activeBusiness;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed get payment accounts', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  public async getStatement(
    queryString: string,
    accountId: string
  ): Promise<IGetStatement> {
    try {
      const request = await apiService.request.get(
        this.apiEndpoints.business.statement(queryString, accountId),
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      return (await request.json()) as IGetStatement;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get statement', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  public async getActiveBusiness(): Promise<ActiveBusinessInterface> {
    try {
      const request = await apiService.request.get(
        this.apiEndpoints.business.active,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      return (await request.json()) as ActiveBusinessInterface;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get payments', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  public async getPaymentAccounts(
    id: string | undefined = undefined
  ): Promise<Array<PaymementAccountInterface>> {
    try {
      let result: Array<AccountInterface>;
      let activeBusiness: ActiveBusinessInterface;

      if (id) {
        // For getting special business payment accounts with business ID
        const response = await apiService.request.get(
          `${this.apiEndpoints.business.root}/${id}`,
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        );
        activeBusiness = (await response.json()) as ActiveBusinessInterface;
        result = activeBusiness?.accounts;
      } else {
        // For getting ACTIVE business payment accounts
        activeBusiness = await this.getActiveBusiness();
        result = activeBusiness?.accounts;
      }

      if (!result) {
        throw new ErrorApi(
          new ErrorPayments({
            message: 'There are no active business payment accounts.',
            code: 'NOT_FOUND',
          })
        );
      }

      return result.map((item) => ({
        ...item,
        category: activeBusiness.name,
      }));
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed get payment accounts', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  // public async getAnalytics(accountId: string): Promise<any> {
  // public async getAnalytics(accountId: string) {
  //   try {
  //     const request = await apiService.request
  //       .get(this.apiEndpoints.business.Analytics(accountId), {
  //         headers: {
  //           'content-type': 'application/json',
  //         },
  //       });
  //     return await request.json();
  //   } catch (globalError) {
  //     debug.error('Failed to get Analytics', globalError);

  //     // try {
  //     //   const result = await globalError.response.json();

  //     //   return Promise.reject(new ErrorPayments({
  //     //     message: result.message,
  //     //     code: result.code,
  //     //   }));
  //     // } catch (localError) {
  //     //   throw new ErrorApi(ErrorApi.ServerError);
  //     // }
  //   }
  // }

  public async getAnalytics(accountId: string, queryString: string) {
    try {
      const endpoint = this.apiEndpoints.Analytics(accountId, queryString);

      const response = await apiService.request.get(endpoint, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await response.json();

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed get getAnalytics info', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  //batchPaymentId: (id: string) => `batches/${id}`,
  public async getBatchId(id: string, queryString: string) {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.batchPaymentId(id, queryString)}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      const result = await request.json();
      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to Change Account', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }
  //

  public async SwitchCompnany(id: string) {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.switchaccount(id)}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to Change Account', globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  public async ussdRebate(data: IGenerateUssdCodeData) {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.generateRebateUssdCode}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const result = await request.json();

      return result;
    } catch (error) {
      const globalError = error as any;
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        throw new ErrorApi(ErrorApi.ServerError);
      }
    }
  }

  public async getLimitInfo(nuban: string) {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.dailyLimit}/info/${nuban}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      debug.success('Result of retrieving limit information', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to get limimt info', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async initializeLimitRequest(
    data: IInitializeLimitRequest
  ): Promise<ILimitRequestResponse | void> {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.dailyLimit}/init`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const result = await request.json();

      debug.success('Result of initializing limit request', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to initialize limit request', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async uploadLimitRequestDocument(data: FormData) {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.dailyLimit}/upload`,
        {
          body: data,
        }
      );

      const result = await request.json();

      debug.success('Result of uploading limit document', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to upload document', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async confirmLimitRequest(reference: string, otp: string) {
    try {
      const request = await apiService.request.post(
        `${this.apiEndpoints.dailyLimit}/confirm`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({ reference, otp }),
        }
      );

      const result = await request.json();

      debug.success('Result of confirming limit request', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to confirm limit request', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getLimitRequestByReference(
    reference: string
  ): Promise<ILimitRequestResponse | void> {
    try {
      const request = await apiService.request.get(
        `${this.apiEndpoints.dailyLimit}/find/${reference}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );

      const result = await request.json();

      debug.success('Result of getting limit request by reference', result);

      return result;
    } catch (error) {
      const globalError = error as any;
      debug.error('Failed to initialize limit request', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorPayments({
              message: result.message,
              code: result.code,
            })
          );
        }
        return Promise.reject(
          new ErrorPayments({
            message: globalError.message,
            code: globalError.code,
          })
        );
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async setError(data: PaymentValidationErrorInterface) {
    if (this.accountValidationErrors.length) {
      this.accountValidationErrors = [
        ...this.accountValidationErrors,
        { ...data },
      ];
    } else {
      this.accountValidationErrors = [{ ...data }];
    }
  }

  public async clearError() {
    this.accountValidationErrors = [];
  }
}

const paymentsService = new PaymentsService();
export default paymentsService;
