import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';

import * as S from './styles';
import { AccountCard, AccountCardProps } from '../AccountCard';
import { Text } from '../Text';
import { colors } from '../../styles/variables';

export interface AccountsListProps {
  /**
   * List of accounts
   */
  accounts: Array<AccountCardProps>;
  /**
   * Checked account index
   */
  checkedIndex?: number;
  /**
   * Indexes of disabled elements
   */
  disabled?: Array<number>;
  /**
   * Type of accounts
   */
  type?: 'payment' | 'receiving',
  /**
   * Change chosen account action
   */
  onChange?: (index: number | null) => void;
}

const AccountsListComponent: React.FC<AccountsListProps> = ({ type = 'payment', ...props }) => {
  const [checkedItemIndex, setCheckedItemIndex] = useState<number | null>(null);

  const getTitle = () => {
    switch (type) {
      case 'payment':
        return 'Payment Account';
      case 'receiving':
        return 'Receiving Account';
      default:
        return '';
    }
  };

  useEffect(() => {
    setCheckedItemIndex(props.checkedIndex as number);
  }, [props.checkedIndex]);

  const onChange = (index: number) => {
    const checkedIndex = index === checkedItemIndex ? null : index;

    setCheckedItemIndex(checkedIndex);

    if (props.onChange) {
      props.onChange(checkedIndex);
    }
  };

  return (
    <S.Wrapper>
      <Text color={colors.black._100} bold>{getTitle()}</Text>
      <S.AccountsWrapper>
        {/* <Slider {...sliderSettings}> */}
        {props.accounts.map((accountItem, index) => (
          <S.AccountWrapper>
            <AccountCard
              key={`account-card-${+index}`}
              balance={accountItem.balance}
              checked={index === checkedItemIndex}
              number={accountItem.number}
              currency={accountItem.currency}
              disabled={props.disabled?.includes(index)}
              onClick={() => onChange(index)}
            />
          </S.AccountWrapper>
        ))}
        {/* </Slider> */}
      </S.AccountsWrapper>
    </S.Wrapper>
  );
};

export const AccountsList = React.memo(AccountsListComponent);
