import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components/Button';
import { DropdownInput, DropdownOptionProps } from '../../../../components/DropdownInput';
import { RoundCheckBoxGroup } from '../../../../components/RoundCheckboxGroup';
import { Text } from '../../../../components/Text';
import { TextInput } from '../../../../components/TextInput';
import { colors } from '../../../../styles/variables';
import Datepicker from '../../../../components/DatePicker';
import paymentsService from '../../../../services/internal/payments/payments.service';

import * as S from './styles';
import { AccountItemProps } from '../CompanyBalance';
import getQueryString from '../../../../utils/getQueryString';
import { failed } from '../../../../components/Toasts';
import debug from '../../../../services/internal/debbug.service';
import { getDateWithYearFirst, timeDateMonthString } from '../../../../utils/datetime';

interface IGetStatement {
  start?: Date | string | null;
  end?: Date | string | null;
  page?: number;
  download?: boolean
}
interface IRequestStatementProps {
  accounts: Array<AccountItemProps>,
  onCloseModal: () => void
  openSuccessModal: () => void

}

export const RequestAccountStatement: React.FC<IRequestStatementProps> = ({
  accounts,
  onCloseModal,
  openSuccessModal,
}) => {
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [data, setData] = useState<IGetStatement>({ download: true });
  const [dropdownData, setDropdownData] = useState<DropdownOptionProps[]>([]);
  const [dropdownSublabel, setDropdownSublabel] = useState<string>();
  const [accountId, setAccountId] = useState<string>();
  const [custom, setCustom] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accounts && accounts.length) {
      let accountData:DropdownOptionProps[] = [];
      let count = 0;
      accounts.forEach((account) => {
        accountData = [...accountData, {
          label: account.nuban, value: account.id, subLabel: account.type,
        }];
        count += 1;
        if (count === accounts.length) {
          setDropdownData(accountData);
        }
      });
    }
  }, [accounts]);

  const toggleStart = () => {
    setOpenStart(!openStart);
  };
  const toggleEnd = () => {
    setOpenEnd(!openEnd);
  };

  const onChangeStart = async (date:Date) => {
    setData({ ...data, start: getDateWithYearFirst(new Date(date)), end: null });

    toggleStart();
  };

  const onChangeEnd = async (date:Date) => {
    setData({ ...data, end: getDateWithYearFirst(new Date(date)) });
    toggleEnd();
  };

  const selectAccount = (value: DropdownOptionProps | null) => {
    setAccountId(value?.value);
    setDropdownSublabel(value?.subLabel);
  };

  const onChange = (value: boolean, name?: string) => {
    if (!value) {
      setData({ ...data, start: null, end: null });
      setCustom(false);
    } else if (name && name === 'Custom') {
      setCustom(true);
    } else if (name && name !== 'Custom') {
      setCustom(false);
      const dateValue = name.split(' ');
      const date = new Date();
      if (dateValue[1].toUpperCase() === 'MONTHS' || dateValue[1].toUpperCase() === 'MONTH') {
        date.setMonth(date.getMonth() - Number(dateValue[0]));
        setData({
          ...data,
          start: getDateWithYearFirst(date),
          end: getDateWithYearFirst(new Date()),
        });
      } else if (dateValue[1].toUpperCase() === 'YEARS' || dateValue[1].toUpperCase() === 'YEAR') {
        date.setFullYear(date.getFullYear() - Number(dateValue[0]));
        setData({
          ...data,
          start: getDateWithYearFirst(date),
          end: getDateWithYearFirst(new Date()),
        });
      }
    }
  };

  const onChangeDocument = (value: boolean, name?: string) => {
    setDocumentType(value ? name?.toLowerCase() : '');
  };

  const getStatement = async (): Promise<void> => {
    if (accountId) {
      setLoading(true);
      try {
        await paymentsService.getStatement(getQueryString({
          ...data, downloadFormat: documentType,
        }), accountId);
        onCloseModal();
        setLoading(false);
        openSuccessModal();
      } catch (err) {
        setLoading(false);

        debug.error('Error while downloading statement', err);
        failed('Failed!', 'Can not download statement at the moment');
      }
    }
  };

  return (
    <S.InnerContainer>
      <S.Container>
        <S.ModalWrapper>
          <S.Header>
            <S.MarginBottom>
              <S.TextMarginBottom>
                <Text color={colors.pink._100} size={2} bold>
                  Request account statement
                </Text>
              </S.TextMarginBottom>

              <S.MarginBottom>
                <Text color={colors.black._60} size={5}>
                  The account statement will be sent to the mail linked to the account.
                </Text>
              </S.MarginBottom>
            </S.MarginBottom>
          </S.Header>

          <S.DropDownMarginBottom>
            <DropdownInput
              label="Linked account"
              onSelect={selectAccount}
              options={dropdownData}
              placeholder=""
              subLabel=""
              value={dropdownSublabel}
            />
          </S.DropDownMarginBottom>

          <div>
            <S.MarginBottom>
              <Text color={colors.black._100} size={5} bold>
                Account statement period
              </Text>
            </S.MarginBottom>

            <S.CustomMarginBottom>

              <RoundCheckBoxGroup onChange={onChange} items={[{ text: '1 Month' }, { text: '3 Months' }, { text: '6 Months' }, { text: '1 Year' }, { text: '2 Years' }, { text: '3 Years' }, { text: 'Custom' }]} />

            </S.CustomMarginBottom>

            {custom && (
            <div>
              <S.MarginBottom>
                <S.Container>
                  <Text color={colors.black._100} size={5} bold>
                    Custom period
                  </Text>
                </S.Container>

              </S.MarginBottom>
              <S.CustomMarginBottom>
                <S.Container>
                  <S.TextInputContainer>
                    <S.TextInputWrapper>
                      <TextInput icon="calender" iconColor="#7F8EA4" label="Start period" type="text" value={data.start ? timeDateMonthString(new Date(data.start)) : ''} onIconClick={toggleStart} />
                      <Datepicker isOpen={openStart} dateChange={onChangeStart} />
                    </S.TextInputWrapper>
                    <S.SpaceWrapper><span>{' '}</span></S.SpaceWrapper>
                    <S.TextInputWrapper>
                      <TextInput icon="calender" iconColor="#7F8EA4" label="End period" type="text" value={data.end ? timeDateMonthString(new Date(data.end)) : ''} onIconClick={toggleEnd} />
                      <Datepicker isOpen={openEnd} dateChange={onChangeEnd} />
                    </S.TextInputWrapper>
                  </S.TextInputContainer>
                </S.Container>
              </S.CustomMarginBottom>
            </div>
            )}

            <S.MarginBottom>
              <S.MarginBottom>
                <S.Container>
                  <Text color={colors.black._100} size={5} bold>
                    Document format
                  </Text>
                </S.Container>

              </S.MarginBottom>
              <S.MarginBottom>

                <RoundCheckBoxGroup onChange={onChangeDocument} items={[{ text: 'PDF' }, { text: 'Excel' }]} />

              </S.MarginBottom>
            </S.MarginBottom>
          </div>

          <S.ButtonWrapper1>
            <Button label={loading ? 'Please wait...' : 'Request statement'} disabled={loading} onClick={getStatement} />
          </S.ButtonWrapper1>
        </S.ModalWrapper>

      </S.Container>

    </S.InnerContainer>

  );
};
