import React, { useState, useEffect } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';

import { ValidateResult, validateObject } from '../../../utils/validation';
import { actions } from '../config';

import * as S from './styles';

const SCHEME = {
  accountNumber: (value: string) => value.length === 10,
};

const VbankAccountSwitcherHasEnterAccNumber: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const storedVbankAccount = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.vbankAccount as string,
  );

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.inApp as boolean,
  );
  /**
   * Local state initiation region
   */
  const [accountNumber, setAccountNumber] = useState<string>(storedVbankAccount || '');
  const [validation, setValidation] = useState<ValidateResult>();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const numberMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    integerLimit: 10,
    allowNegative: false,
    allowLeadingZeroes: true,
  };

  const continueHandle = async () => {
    try {
      setLoading(true);
      const response = inApp ? await onboardingService.sendOtpForBusiness({
        nuban: accountNumber,
      }) : await onboardingService.sendClientOtpForBusiness({
        nuban: accountNumber,
      });
      batch(() => {
        dispatch(OnboardingActions.handleSetData({
          vbankAccount: accountNumber,
          maskedEmail: response.data.maskedEmail,
          maskedPhone: response.data.maskedPhone,
          reference: response.data.reference,
        }));
      });
      route.push(actions[response.actionRequired]);

      debug.info('', response); // TEMPORARY
    } catch (err: any) {
      dispatch(OnboardingActions.handleSetError(err.message || 'Can not complete request at the moment'));
    }
    setLoading(false);
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(validateObject({ accountNumber }, SCHEME));
  }, [accountNumber]);

  return (
    <S.Container>
      <Title title="Enter Vbank account number" />
      <S.TextFieldWrapper>
        <TextInput
          type="currency"
          mask={createNumberMask(numberMaskOptions)}
          label="Account number"
          onChange={setAccountNumber}
          value={accountNumber}
        />
      </S.TextFieldWrapper>
      <Button label="Continue" loading={loading} onClick={continueHandle} disabled={!validation?.isValid || loading} />
    </S.Container>
  );
};

export default VbankAccountSwitcherHasEnterAccNumber;
