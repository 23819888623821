import React, { useState } from 'react';
import * as S from '../styles';
import { FileUploadUpdated } from '../../../components/FileUploadUpdated';

export const Signature: React.FC<{ onChangeFile?: any }> = ({
  onChangeFile,
}) => {
  const [filetoUpload, setfiletoUpload] = useState('');
  const onChangeValue = (value: any) => {
    if (onChangeFile) {
      onChangeFile(value);
    }

    setfiletoUpload(value);
  };
  return (
    <S.TextDrag>
      <FileUploadUpdated
        // acceptedFormats={['image/jpg', 'image/jpeg']}
        onChange={(file) => onChangeValue(file)}
      />
    </S.TextDrag>
  );
};
