import styled, { css } from 'styled-components';

import { ptr } from './utils';
import { breakpoints, colors, fonts } from './variables';

interface ContainerFlex {
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  flex?: number;
}

interface IRight {
  inApp?: boolean;
}

const ContentContainer = styled.div<ContainerFlex>`
  display: flex;
  width: 100%;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}

  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 ${ptr(60)} 0 ${ptr(30)};
`;

const OneCenter = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    justify-content: center;
    align-items: center;
  `,
  ContentWrapper: styled.div`
    width: ${ptr(510)};
    /* padding: ${ptr(30)}; */
    @media (max-width: ${ptr(510 + 30 + 30)}) {
      width: 100%;
    }
  `,
};

const TwoSide = {
  Wrapper: styled.div<{ inApp?: boolean }>`
    display: flex;
    flex-direction: row;
    
    ${(props) =>
      props.inApp &&
      css`
        position: unset;
        @media (max-width: ${breakpoints.sm}px) {
          position: absolute;
        }
      `}

    @media (max-width: ${ptr(1024)}) {
      flex-direction: column;
    }
  `,
  Left: styled.div`
    width: 50%;
    @media (max-width: ${ptr(1024)}) {
      width: 100%;
    }
  `,
  Right: styled.div<IRight>`
    width: 50%;
    display: flex;
    justify-content: center;
    height:100vh;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    ${(props) =>
      props.inApp &&
      css`
        width: 100%;
        @media (max-width: ${breakpoints.sm}px) {
          margin-left: 0rem;
        }
        /* margin-left:23.5rem; */
      `}

    @media (max-width: ${ptr(1024)}) {
      width: 100%;
      padding: ${ptr(32)};
      overflow-y: initial;
      ::-webkit-scrollbar {display:none;}
    }
/*
    :after {
      content:" © 2022 VFD MFB, all rights reserved.";
      position:absolute;
      bottom:10px;
      top:100%;
      font-family:${fonts.inter.regular}
    } */
  `,
};

const TwoSideMenu = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Wrapper: styled.div`
    width: 1440px;
    width: 100%;
  `,
  Right: styled.div`
    display: flex;
    flex: 1;
    margin-left: ${ptr(376)};
    justify-content: center;

    @media (max-width: ${ptr(breakpoints.lg)}) {
      margin-left: 0;
    }
  `,
  RightHeaderWrapper: styled.div`
    width: 100%;
    top: 0;
    z-index: 1;
    position: fixed;

    border-bottom: ${ptr(1)} solid ${colors.black._10};
  `,
  RightWrapper: styled.div`
    display: flex;
    width: 100%;

    flex-direction: column;
  `,
  RightContent: styled.div<{ showMenu?: boolean }>`
    width: 100%;
    max-width: 100%;
    //min-width:100vw;
    //  min-width:fit-content;
    background: #ffffff;
    /* height: fit-content; */
    min-height: 100vh;
    padding: ${ptr(32)};
    padding-left: ${ptr(360)};
    box-sizing: border-box;
    margin-top: 90px;
    @media (max-width: ${breakpoints.xxl}px) {
      padding: ${ptr(32)};
      padding-left: ${(props) =>
        props.showMenu &&
        css`
          ${ptr(360)};
        `};
    }

    @media (max-width: ${breakpoints.lg}px) {
      padding: ${ptr(32)};
    }
    ::-webkit-scrollbar {
      display: none;
    }
  `,
};

const Columns = {
  Wrapper: styled.div`
    display: flex;
    width: ${ptr(510)};
    height: 100%;
    flex-direction: column;
  `,
  ContentWrapper: styled.div<{ padding?: string }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: ${ptr(32)} 0;
    ${(props) =>
      props.padding &&
      css`
        padding: ${props.padding};
      `}
  `,
};

const FormTitle = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${ptr(40)};
  `,
  TitleWrapper: styled.div``,
  DescriptionWrapper: styled.div`
    margin-top: ${ptr(8)};
  `,
};

const FormButton = {
  Wrapper: styled.div`
    display: flex;
    margin-top: ${ptr(40)};
  `,
  WrapperEnd: styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    /* justify-content: flex-end; */
    margin-top: ${ptr(20)};
  `,
};

const FormInput = {
  Wrapper: styled.div`
    margin-bottom: ${ptr(12)};
  `,
};

const PageTitle = {
  Wrapper: styled.div`
    margin-bottom: ${ptr(24)};
  `,
};

const ComponentWrapper = styled.div`
  margin-top: ${ptr(40)};
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: ${ptr(-16)};
`;

const SettingsTypeWrapper = styled.div`
  width: ${ptr(220)};
  margin-right: ${ptr(16)};
  margin-top: ${ptr(16)};
  @media (max-width: ${breakpoints.md}px) {
    flex: 1;
    width: auto;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${ptr(30)};
  display: flex;
  justify-content: flex-end;
  border-top: solid 4px #dbdfe4;
  /* height: 80px; */
  padding-top: 30px;
  align-items: center;
  button {
    width: 45%;
  }
`;

export {
  ContentContainer,
  FlexColumn,
  OneCenter,
  TwoSide,
  TwoSideMenu,
  Columns,
  FormTitle,
  FormButton,
  FormInput,
  PageTitle,
  ComponentWrapper,
  SettingsWrapper,
  ButtonContainer,
  SettingsTypeWrapper,
};
