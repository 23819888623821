import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';

import { validateObject, ValidateResult } from '../../../utils/validation';

import remoteLinks from '../../../configs/links';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { actions } from '../config';

const SCHEME = {
  accountNumber: (value: string) => value.length === 10,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CommonPersonalAccCreateVbank: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const storedVbankAccount = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.vbankAccount as string,
  );

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.inApp as boolean,
  );

  /**
   * Local state initiation region
   */
  const [accountNumber, setAccountNumber] = useState<string>(storedVbankAccount || '');
  const [validation, setValidation] = useState<ValidateResult>();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = inApp ? await onboardingService.sendOtpForBusiness({
          nuban: accountNumber,
        }) : await onboardingService.sendClientOtpForBusiness({
          nuban: accountNumber,
        });
        batch(() => {
          dispatch(OnboardingActions.handleSetData({
            vbankAccount: accountNumber,
            maskedEmail: response.data.maskedEmail,
            maskedPhone: response.data.maskedPhone,
            reference: response.data.reference,
          }));
          dispatch(OnboardingActions.handleHasAccount());
        });
        route.push(actions[response.actionRequired]);
        debug.info('', response); // TEMPORARY
      } catch (err: any) {
        dispatch(OnboardingActions.handleSetError(err.message));
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(validateObject({ accountNumber }, SCHEME));
  }, [accountNumber]);

  return (
    <S.Container>
      <Title title="Create Vbank account and enter Vbank account number" />
      <S.Wrapper>
        <Text color={colors.black._60}>
          To work with your personal account, you need to register in the
          {' '}
          <Link
            to={{
              pathname: remoteLinks.mobileApp,
            }}
            target="_blank"
          >
            <Text color={colors.pink._100}>
              Vbank app
            </Text>
          </Link>
          {' '}
          and enter your account number
        </Text>
        <TextInput
          type="currency"
          mask={createNumberMask(numberMaskOptions)}
          label="Account number"
          value={accountNumber}
          onChange={setAccountNumber}
        />
      </S.Wrapper>
      <Button label="Continue" loading={loading} onClick={continueHandle} disabled={!validation?.isValid || loading} />
    </S.Container>
  );
};
export default CommonPersonalAccCreateVbank;
