import styled, { css } from 'styled-components';

import { PreviewProps } from '.';
import {
  fonts, colors, transition, breakpoints,
} from '../../styles/variables';
import { crossBrowserTransition, ptr } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
 
 
  border-radius: ${ptr(6)};
  font-family: ${fonts.inter.regular};
  box-sizing: border-box;

     @media (max-width: ${breakpoints.md}px) {
        padding:0;
     }
     @media (max-width: 1396px) {
    
     }
`;

const WrapperEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextDrag = styled.span`
  color: ${colors.black._40};
  cursor:pointer;
`;

const TextSelect = styled.span`
  color: ${colors.pink._100};
  cursor: pointer;

  ${crossBrowserTransition(transition.slow)};
  
  :hover {
    color: ${colors.pink._60};
  }
`;

const FileIconWrapper = styled.div`
 cursor:pointer;
`;

const DeleteCrossWrapper = styled.div`
  position: absolute;
  right: ${ptr(-8)};
  top: ${ptr(-8)};
  z-index: 1;
`;

const DeleteCross = styled.button`
  width: ${ptr(24)};
  height: ${ptr(24)};
  border: 0;
  border-radius: 50%;
  box-sizing: border-box;
  border: ${ptr(2)} solid ${colors.white._100};
  background-color: ${colors.pink._100};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewWrapper = styled.div<PreviewProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${ptr(104)};
  
  ${(props) => props.withPreviewImage && css`
    width: ${ptr(140)};
  `}
`;

const ImagePreview = styled.img`
  background-color: ${colors.pink._20};
  border-radius: ${ptr(6)};
  width: ${ptr(140)};
  height: ${ptr(124)};
  object-fit: cover;
`;

const FileNameWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  padding-top: ${ptr(12)};
  line-height: 140%;
  font-size: ${ptr(14)};
  font-family: ${fonts.inter.bold};
  text-overflow: ellipsis;
  text-align:center;
  cursor:pointer;
`;

const FileName = styled.span``;

const FileFormat = styled.span``;

const FileSizeWrapper = styled.div`
 text-align:center;
 `;

const FileSize = styled.span`
  line-height: 140%;
  font-size: ${ptr(14)};
  font-family: ${fonts.inter.regular};
  text-overflow: ellipsis;
  color:#6E7191;
`;

const FileIconContainer = styled.div`
  width: ${ptr(104)};
  height: ${ptr(120)};
  position: relative;
`;

const FileIconBackground = styled.div`
  width: ${ptr(94)};
  height: ${ptr(120)};
  border-radius: ${ptr(6)};
  background-color: ${colors.black._10};
  float: right;
`;

const FileIconTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(94)};
  height: ${ptr(45)};
  padding: ${ptr(8)};
  background-color: #F15642;
  border-radius: ${ptr(6)};
  position: absolute;
  bottom: ${ptr(18)};
  box-sizing: border-box;
`;

const FileIconTitle = styled.span`
  color: ${colors.white._100};
  font-family: ${fonts.inter.bold};
  font-size: ${ptr(24)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export {
  Wrapper,
  WrapperEmpty,
  TextDrag,
  TextSelect,
  FileIconWrapper,
  DeleteCrossWrapper,
  DeleteCross,
  PreviewWrapper,
  ImagePreview,
  FileNameWrapper,
  FileName,
  FileFormat,
  FileSizeWrapper,
  FileSize,
  FileIconContainer,
  FileIconBackground,
  FileIconTitleWrapper,
  FileIconTitle,
};
