import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { Title } from '../../Onboarding/components/Title';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';

import { validateObject, ValidateResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { actions } from '../config';
import { Modal } from '../../../components/LoadingModal';
import { Select } from '../../Onboarding/components/MultiFileUploader/styles';

const SCHEME = {
  bvn: (value: string) => value.length === 11,
  nin: (value: string) => value.length === 11,
  dateOfBirth: (value: string) => value.length > 0,
  phoneNumber: (value: string) => value.length > 0,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const VbankAccountSwitcherHasNotEnterDetails: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const business = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.business as Record<string, string>,
  );
  const storedDob = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.dob as string,
  );

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean,
  );

  // const onboarding = useSelector(
  //   (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack,
  // );

  // const inApp = useSelector((state: { onboardingStack: OnboardingInterface }) => state
  //   .onboardingStack.inApp);

  /**
   * Local state initiation region
   */
  const [bvn, setBvn] = useState<string>(business.bvn || '');
  const [nin, setNin] = useState<string>(business.nin || '');
  const [dateOfBirth, setDateOfBirth] = useState<string>(storedDob || '');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [validation, setValidation] = useState<ValidateResult | null>(null);
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = inApp
          ? await onboardingService.sendOtpForBusiness({
              bvn: bvn.trim(),
              nin: nin.trim(),
              dob: dateOfBirth,
              phone: phoneNumber.replace(/ /g, ''),
            })
          : await onboardingService.sendGuestOtpForBusiness({
              bvn,
              nin,
              dob: dateOfBirth,
              phone: phoneNumber.replace(/ /g, ''),
            });

        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              maskedEmail: response.data.maskedEmail,
              maskedPhone: response.data.maskedPhone,
              reference: response.data.reference,
              dob: dateOfBirth,
              business: {
                ...business,
                bvn,
                nin,
                phone: phoneNumber,
              },
            }),
          );
        });
        route.push(actions[response.actionRequired]);

        debug.info('', response); // TEMPORARY
      } catch (err) {
        dispatch(OnboardingActions.handleSetError(err.message));
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(
      validateObject(
        {
          bvn,
          nin,
          dateOfBirth,
          phoneNumber,
        },
        SCHEME,
      ),
    );
  }, [bvn, nin, dateOfBirth, phoneNumber]);

  return (
    <S.Container>
      <Title title="Please, enter your details" />
      <S.Wrapper>
        <TextInput
          label="BVN"
          type="currency"
          mask={createNumberMask(numberMaskOptions)}
          value={bvn}
          onChange={setBvn}
        />
        <S.ErrorView>
          {!validation?.data.bvn.isValid && 'BVN must be 11 digits'}
        </S.ErrorView>
        <TextInput
          label="NIN"
          type="currency"
          mask={createNumberMask(numberMaskOptions)}
          value={nin}
          onChange={setNin}
        />
        <S.ErrorView>
          {!validation?.data.nin.isValid && 'NIN must be 11 digits'}
        </S.ErrorView>
        <TextInput
          type="date"
          label="Date of birth"
          value={dateOfBirth}
          onChange={setDateOfBirth}
        />
        <S.ErrorView>
          {!validation?.data.dateOfBirth.isValid &&
            'Enter a valid date of birth'}
        </S.ErrorView>
        <TextInput
          label="Phone number"
          type="tel"
          mask={[
            /[0-9]/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        <S.ErrorView>
          {!validation?.data.phoneNumber.isValid &&
            'Enter a valid phone number'}
        </S.ErrorView>
      </S.Wrapper>
      <Text size={7} color={colors.black._60}>
        We will send a confirmation code to it
      </Text>
      <S.ButtonContainer>
        <Button
          label="Continue"
          loading={loading}
          onClick={continueHandle}
          disabled={!validation?.isValid || loading}
        />
      </S.ButtonContainer>

      <Modal visible={loading} />
    </S.Container>
  );
};
export default VbankAccountSwitcherHasNotEnterDetails;
