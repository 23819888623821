import { LeaderDirectorShareholderInterface } from '../../../../services/internal/onboarding/IOnboarding';
import { excludeObjectEntries, renameFile } from '../../../../services/internal/app/index';

type LDSI = LeaderDirectorShareholderInterface[];
export function getSignatureFormData(collection: LDSI, reference: string):Promise<FormData> {
  return new Promise((resolve) => {
    const leadersData: Array<LeaderDirectorShareholderInterface> = [];
    const signatures: Array<File> = [];
    const formData = new FormData();
    collection.forEach(async (item, index) => {
      if (item.signatures) {
        signatures.push(await renameFile(item.signatures, item.email));
      }
      const temp = { ...item } as Record<string, unknown>;
      leadersData.push(excludeObjectEntries<LeaderDirectorShareholderInterface>(temp, ['signatures']));
      if (index === collection.length - 1) {
        formData.append('leaders', JSON.stringify(leadersData));
        signatures.forEach((signature) => formData.append('signatures', signature));
        formData.append('reference', reference);
        resolve(formData);
      }
    });
  });
}
