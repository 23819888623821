import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${ptr(40)};
`;
const TransfersWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: ${ptr(-16)};
`;

const TransferTypeWrapper = styled.div`
  width: ${ptr(220)};
  margin-right: ${ptr(16)};
  margin-top: ${ptr(16)};
  @media (max-width: ${breakpoints.md}px) {
    flex: 1;
    width: auto;
  }
`;

export {
  Row,
  TransfersWrapper,
  TransferTypeWrapper,
};
