import React, { useState } from 'react';

import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
import { Text } from '../Text';
import { CSSProperties } from 'styled-components';

interface LinkProperty {
  title: string;
  to: string;
}

export interface CheckBoxProps {
  /**
   * Is check box disabled
   */
  disabled?: boolean;
  /**
   * Is checked
   */
  checked?: boolean;
  /**
   * Text right of check box
   */
  text?: string;
  color?: CSSProperties;
  background?: CSSProperties;
  /**
   * Link after text
   */
  link?: LinkProperty;
  /**
   * On change event
   */
  onChange?: (value: number | string) => void;

  type?: 'month' | 'year';
}

export interface IDropdownSelectProps {
  /**
   * Is check box disabled
   */
  disabled?: boolean;
  /**
   * Is checked
   */
  checked?: boolean;
  /**
   * Text right of check box
   */
  text?: string;

  color?: CSSProperties;

  /**
   * On change event
   */
  onChange?: (value: string) => void;

  items: Array<string>;
}

export const YearDropListUpdated: React.FC<CheckBoxProps> = ({
  type = 'year',
  ...props
}) => {
  const [showDropList, setShowDroplist] = useState<boolean>(false);
  const [months] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);

  const toggleDropList = () => {
    setShowDroplist(!showDropList);
  };

  const generateYears = () => {
    const max = new Date().getFullYear();
    const min = max - 9;
    const years = [];

    for (let i = max; i >= min; i -= 1) {
      years.push(i);
    }
    return years;
  };

  const onSelect = (value?: number | string) => {
    if (props.onChange && value) props.onChange(value);
    toggleDropList();
  };
  return (
    <div>
      <S.YearBadge onClick={toggleDropList}>
        <Text size={6} color='white'>
          {props.text}
        </Text>
        <Icon name='arrow-left' color='white' width={10} height={10} />
      </S.YearBadge>
      {showDropList === true && (
        <S.YearStyle>
          <S.PYear>{type === 'year' ? 'Year' : 'Month'}</S.PYear>
          {type === 'year' &&
            generateYears().map((year, index) => (
              <S.PYear
                onClick={() => onSelect(year)}
                active={props.text ? year === +props.text : false}
                key={`${index.toString()}-year`}
              >
                {year}
              </S.PYear>
            ))}

          {type === 'month' &&
            months.map((month, index) => (
              <S.PYear
                onClick={() => onSelect(month)}
                active={props.text ? month === props.text : false}
                key={`${index.toString()}-month`}
              >
                {month}
              </S.PYear>
            ))}
        </S.YearStyle>
      )}
    </div>
  );
};
// drop down select
export const DropdownSelect: React.FC<IDropdownSelectProps> = ({
  ...props
}) => {
  const [showDropList, setShowDroplist] = useState<boolean>(false);

  const toggleDropList = () => {
    setShowDroplist(!showDropList);
  };

  const onSelect = (value?: string) => {
    if (props.onChange && value) props.onChange(value);
    toggleDropList();
  };
  return (
    <div>
      <S.YearBadge onClick={toggleDropList}>
        <Text size={6} style={props.color} color='#FFFFFF'>
          {props.text}
        </Text>
        <Icon name='arrow-left' color='#FFFFFF' width={10} height={10} />
      </S.YearBadge>
      {showDropList === true && (
        <S.DropDownStyle>
          <S.PYear>Select</S.PYear>
          {props.items.map((item, index) => (
            <S.PYear
              onClick={() => onSelect(item)}
              active={props.text ? item === props.text : false}
              key={`${index.toString()}-item`}
            >
              {item}
            </S.PYear>
          ))}
        </S.DropDownStyle>
      )}
    </div>
  );
};
