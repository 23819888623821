import styled from 'styled-components';
import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';

const Container = styled.div` 
  height: 353px;
  width: 375px; 
  background: ${colors.white._100};
  box-shadow: 0px 4px 40px -3px #00000033;
  margin-top: ${ptr(20)};
  z-index:9000;
  border-radius:4px;
    }
  `;

  const TopSection = styled.div` 
   display:flex;
     padding: 21px 18px 14px 24px;
   justify-content: space-between;
   align-content:center; 
   border-bottom: 1px solid #F3F5F6;
    }
  `;

  const MainBody = styled.div` 
display:flex; 
flex-direction:column; 
padding: 26px 22px 38px 21px;
>span{  
  width:282px;
margin: auto;
margin-top: ${ptr(17)};
 margin-bottom: ${ptr(48)};
 text-align: center;
}
   }
 `;

 const Icon = styled.div`  
display:flex;
justify-content: center;  
}
`;

 const ActionButtons = styled.div`  
 display:flex;
 justify-content: space-between;
}
`;

export { Container, TopSection, MainBody, ActionButtons, Icon };
