import React from 'react';

import * as S from './styles';
import { TableCell, TableCellProps } from '../TableCell';
import { colors } from '../../styles/variables';

export interface TableHeaderProps {
  /**
   * Header cells collection
   */
  cells: {
    readonly [columnId: number]: TableCellProps & {
      /**
       * ASC and DESC sotring
       */
      order?: 'ASC' | 'DESC' | 'default';
    };
  };
  /**
   * Header cext cell align
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Header height
   */
  height?: number;

  styleBg?: React.CSSProperties;
  /**
   * Header height
   */
  color?: string;
  backgroundColor?: string;
  /**
   * Cell's padding
   */
  padding?: number | string;
  /**
   * onClick header handler
   */
  onClick?: () => void;
}

const TableHeaderComponent: React.FC<TableHeaderProps> = ({
  cells = {},
  styleBg,
  backgroundColor = colors.black._10,
  ...props
}) => (
  <S.TableRowWrapper
    style={styleBg}
    height={props.height}
    // backgroundColor={backgroundColor}
    backgroundColor={props.color || backgroundColor}
  >
    {Object.entries(cells).map(([columnId, cell]) => (
      <TableCell tag='th' key={columnId} padding={props.padding} {...cell}>
        {cell.order ? (
          <S.TableCellWrapper align={cell.align}>
            {cell.children}
            <S.FigureGroup order={cell.order}>
              <S.Triangle />
              <S.Triangle />
            </S.FigureGroup>
          </S.TableCellWrapper>
        ) : (
          cell.children
        )}
      </TableCell>
    ))}
  </S.TableRowWrapper>
);

export const TableHeader = React.memo(TableHeaderComponent);

// import React from 'react';

// import * as S from './styles';
// import { TableCell, TableCellProps } from '../TableCell';
// import { colors } from '../../styles/variables';

// export interface TableHeaderProps {
//   /**
//    * Header cells collection
//    */
//   cells: {
//     readonly [columnId: number]: TableCellProps & {
//       /**
//        * ASC and DESC sotring
//        */
//       order?: 'ASC' | 'DESC' | 'default';
//     };
//   };
//   /**
//    * Header cext cell align
//    */
//   align?: 'left' | 'center' | 'right';
//   /**
//    * Header height
//    */
//   height?: number;
//   /**
//    * Header height
//    */
//   backgroundColor?: string;
//   color?: string;
//   /**
//    * Cell's padding
//    */
//   padding?: number | string;
//   /**
//    * onClick header handler
//    */
//   onClick?: () => void;
// }

// const TableHeaderComponent: React.FC<TableHeaderProps> = ({
//   cells = {},
//   backgroundColor = colors.black._10,
//   ...props
// }) => (
//   <S.TableRowWrapper
//     height={props.height}
//     backgroundColor={props.color || backgroundColor}
//   >
//     {Object.entries(cells).map(([columnId, cell]) => (
//       <TableCell tag='th' key={columnId} padding={props.padding} {...cell}>
//         {cell.order ? (
//           <S.TableCellWrapper align={cell.align}>
//             {cell.children}
//             <S.FigureGroup order={cell.order}>
//               <S.Triangle />
//               <S.Triangle />
//             </S.FigureGroup>
//           </S.TableCellWrapper>
//         ) : (
//           cell.children
//         )}
//       </TableCell>
//     ))}
//   </S.TableRowWrapper>
// );

// export const TableHeader = React.memo(TableHeaderComponent);
