import styled, { css } from 'styled-components';

import { CheckBoxProps } from '.';
import { fonts, colors, transition } from '../../styles/variables';
import {
  crossBrowserDisableSelection,
  crossBrowserTransition,
  ptr,
} from '../../styles/utils';

const Wrapper = styled.div<CheckBoxProps>`
  display: flex;

  ${crossBrowserDisableSelection()};

  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${(props) => props.disabled && css`
    cursor: default;
  `}
`;

const BoxWrapper = styled.div<CheckBoxProps>`
  width: ${ptr(24)};
  min-width: ${ptr(24)};
  height: ${ptr(24)};
  min-height: ${ptr(24)};
  border-radius: ${ptr(12)};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  ${crossBrowserTransition(transition.slow)}
  
  ${(props) => props.theme === 'light' && css`
    border: ${ptr(1)} solid ${colors.white._100};
  `}

  ${(props) => props.theme === 'dark' && css`
    border: ${ptr(1)} solid ${colors.black._40};
  `}
  
  ${(props) => props.checked && css`
    background-color: ${colors.pink._100};
    border: 0;
    
    ${props.disabled && `
      background-color: ${colors.pink._20};
    `}
  `}
  
  ${(props) => props.disabled && css`
    border-color: ${colors.black._5};
  `}
`;

const Text = styled.label<CheckBoxProps>`
  margin-left: ${ptr(12)};
  font-family: ${fonts.inter.regular};
  line-height: 140%;
  white-space: pre-wrap;
  cursor: pointer;
  
  ${(props) => props.disabled && css`
    cursor: default;
  `}

  ${(props) => props.theme === 'light' && css`
    color: ${colors.white._100};
  `}
  
  ${(props) => props.theme === 'dark' && css`
    color: ${colors.black._100};
  `}
`;

export {
  Wrapper,
  BoxWrapper,
  Text,
};
