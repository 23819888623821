import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './styles';
import { validateObject, ValidateResult } from '../../../../utils/validation';
import { success, failed } from '../../../../components/Toasts';
import { FormTitle } from '../../../../components/FormTitle';
import { FormButton, FormInput, FlexColumn } from '../../../../styles/wrappers';
import { TextInput } from '../../../../components/TextInput';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Button } from '../../../../components/Button';
import ConfigPassword from '../../../../configs/password';
import { Icon } from '../../../../components/Icon';
import authService from '../../../../services/internal/auth/auth.service';
import debug from '../../../../services/internal/debbug.service';
import { SuccessModal } from '../../../../components/SuccessModal';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const SCHEME_VALIDATION_PASSWORD = {
  minimumCharacters: (value: string) =>
    value.length >= ConfigPassword.minLength,
  uppercase: (value: string) => ConfigPassword.uppercase.test(value),
  lowercase: (value: string) => ConfigPassword.lowercase.test(value),
  special: (value: string) => ConfigPassword.special.test(value),
};

export const NewPasswordForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [successful, setSuccess] = useState(false);
  const [otp, setOtp] = useState('');

  const [validation, setValidation] = useState<ValidateResult | undefined>(
    undefined
  );

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const onCreateNewPassword = async () => {
    setLoading(true);
    if (token) {
      try {
        await authService.newPassword(password, repeatPassword, token, otp);
        success('Success!', 'New password has been successfully created.');
        setSuccess(true);
        setLoading(false);
      } catch (err) {
        failed('Failed!', 'Failed to change password');
        debug.error('Failed to change password', err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const validationResult = validateObject(
      {
        minimumCharacters: password,
        uppercase: password,
        lowercase: password,
        special: password,
      },
      SCHEME_VALIDATION_PASSWORD
    );

    setValidation({
      ...validationResult,
      isValid: validationResult.isValid,
    });
  }, [password, repeatPassword]);

  const onComplete = () => {
    history.push('/');
  };

  function onCloseModal() {
    onComplete();
  }

  return (
    <FlexColumn>
      <SuccessModal
        title="Done!"
        description="Your password has been created successfully."
        onClose={onCloseModal}
        show={successful}
        buttonLabel="Login"
      />
      <FormTitle
        title="New password"
        titleColor={colors.black._20}
        descriptionColor={colors.black._15}
        description="New password should be different from previously used password."
      />
      <FormInput.Wrapper>
        <TextInput
          label="New password"
          type="password"
          value={password}
          disabled={loading}
          maxLength={ConfigPassword.maxLength}
          onChange={setPassword}
          valid={validation?.isValid}
        />
      </FormInput.Wrapper>
      <S.PasswordDescriptionWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.minimumCharacters?.isValid && (
              <Icon name="check-mark" color={colors.green._100} />
            )}
            {!validation?.data.minimumCharacters?.isValid && (
              <Icon name="unknown_status_circle" />
            )}
            <Text color={colors.black._60} size={7}>
              At least 8 characters.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.uppercase?.isValid && (
              <Icon name="check-mark" color={colors.green._100} />
            )}
            {!validation?.data.uppercase?.isValid && (
              <Icon name="unknown_status_circle" />
            )}
            <Text color={colors.black._60} size={7}>
              At least one uppercase is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.lowercase?.isValid && (
              <Icon name="check-mark" color={colors.green._100} />
            )}
            {!validation?.data.lowercase?.isValid && (
              <Icon name="unknown_status_circle" />
            )}
            <Text color={colors.black._60} size={7}>
              At least one lowercase is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.special?.isValid && (
              <Icon name="check-mark" color={colors.green._100} />
            )}
            {!validation?.data.special?.isValid && (
              <Icon name="unknown_status_circle" />
            )}
            <Text color={colors.black._60} size={7}>
              At least one special character is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
      </S.PasswordDescriptionWrapper>
      <FormInput.Wrapper>
        <TextInput
          label="Repeat new password"
          type="password"
          value={repeatPassword}
          maxLength={ConfigPassword.maxLength}
          onChange={setRepeatPassword}
          disabled={loading}
          valid={validation?.isValid && password === repeatPassword}
        />
      </FormInput.Wrapper>

      <FormInput.Wrapper>
        <TextInput
          label="Enter the 6-digit code in your authenticator app"
          value={otp ?? ''}
          onChange={setOtp}
          type="password"
          mask={createNumberMask(numberMaskOptions)}
        />
      </FormInput.Wrapper>
      <FormButton.WrapperEnd>
        <Button
          label="Create new password"
          disabled={
            !validation?.isValid ||
            password !== repeatPassword ||
            loading ||
            otp?.length !== 6
          }
          style={{ width: '100%' }}
          onClick={onCreateNewPassword}
        />
      </FormButton.WrapperEnd>
    </FlexColumn>
  );
};
