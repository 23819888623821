import styled from 'styled-components';

import { TableRowProps } from '.';
import { fonts, colors } from '../../styles/variables';
import { borderParams, ptr } from '../../styles/utils';

const TableRowWrapper = styled.tr<Pick<TableRowProps, 'disabled' | 'height' | 'border' | 'backgroundColor'>>`
  width: 100%;
  ${(props) => props.height && `height: ${ptr(props.height)};`}
  font-family: ${fonts.inter.regular};

  ${(props) => props.border && borderParams(props.border)}

  ${(props) => (!props.disabled ? `
    :hover {
      background-color: ${colors.black._5}
    }` : `
    color: ${colors.black._40};
    cursor: not-allowed;
  `)}

  overflow: hidden;
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
`;

export {
  TableRowWrapper,
};
