/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import {
  OnboardingInterface,
  BusinessType,
} from '../../../../../redux/onboarding/IOnboarding';

import {
  BasicLeaderDirectorShareholderInterface,
  LeaderDirectorShareholderInterface,
  SignUpTrusteeInfoInterface,
} from '../../../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../../../components/Button';
import { Title } from '../../../../Onboarding/components/Title';
import { Text } from '../../../../../components/Text';
import { Icon } from '../../../../../components/Icon';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
  ValidateResult,
  validateObject,
} from '../../../../../utils/validation';
import { colors } from '../../../../../styles/variables';
import * as S from '../../styles';
import { InfoForm2 } from '../../../../Onboarding/components/InfoForm2';
import { OnboardingActions } from '../../../../../redux/onboarding/actions';
import { actions } from '../../../config';
import onboardingService from '../../../../../services/internal/onboarding/onboarding.service';
import { idTypes, BusinessTypesEnum } from '../../../constants';
import { MinimizedInfo } from '../../../../../components/MinimizedInfo';
import { OnboardingCard } from '../../../../../components/OnboardingCard';
import { ptr } from '../../../../../styles/utils';
import { getBusinessTypeIdLabel } from '../../../../../utils/strings';
import { InfoConponent } from '../../../../../components/Info';
import { RootState } from '../../../../../redux';

const SignUpManagementInfo: React.FC = () => {
                                               const dispatch = useDispatch();
                                               const route = useHistory();
                                               const [
                                                 idNumber,
                                                 setIdNumber,
                                               ] = useState<string>('');
                                               const [
                                                 idType,
                                                 setIdType,
                                               ] = useState<any>();
                                               const [
                                                 idNumberValidation,
                                                 setIdNumberValidation,
                                               ] = useState<ValidateResult>();
                                               /**
                                                * Redux store initiation region
                                                */
                                               const reference = useSelector(
                                                 (state: {
                                                   onboardingStack: OnboardingInterface;
                                                 }) =>
                                                   state.onboardingStack.data
                                                     .reference as string
                                               );

                                               const businessType = useSelector(
                                                 (state: {
                                                   onboardingStack: OnboardingInterface;
                                                 }) =>
                                                   state.onboardingStack.data
                                                     .businessType as string
                                               );

                                               const business = useSelector(
                                                 (state: {
                                                   onboardingStack: OnboardingInterface;
                                                 }) =>
                                                   state.onboardingStack.data
                                                     .business as Record<
                                                     string,
                                                     string
                                                   >
                                               );
                                               const leaders = useSelector(
                                                 (state: {
                                                   onboardingStack: OnboardingInterface;
                                                 }) =>
                                                   state.onboardingStack.data
                                                     .directors as Array<
                                                     SignUpTrusteeInfoInterface
                                                   >
                                               );

                                               const preference = useSelector(
                                                 (store: RootState) =>
                                                   store.preference
                                               );

                                               const SCHEME = {
                                                 phone: (value: string) =>
                                                   value.length === 11,
                                                 ...(!preference.bvnConsentEnabled
                                                   ? {
                                                       bvn: (value: string) =>
                                                         value.length === 11,
                                                     }
                                                   : {}),
                                                 idType: (value: string) =>
                                                   value.length > 1,
                                                 email: (value: string) =>
                                                   validator.isEmail(value),
                                               };

                                               const directorDefaultItem = {
                                                 phone: '',
                                                 ...(!preference.bvnConsentEnabled
                                                   ? { bvn: '' }
                                                   : {}),
                                                 email: '',
                                                 idNumber: '',
                                                 idType: '',
                                               };

                                               /**
                                                * Local state initiation region
                                                */
                                               const [
                                                 collection,
                                                 setCollection,
                                               ] = useState<
                                                 Array<
                                                   SignUpTrusteeInfoInterface
                                                 >
                                               >(() =>
                                                 leaders?.length
                                                   ? leaders.map((item) => ({
                                                       phone: item.phone,
                                                       ...(!preference.bvnConsentEnabled
                                                         ? { bvn: item.bvn }
                                                         : {}),
                                                       email: item.email,
                                                       idNumber: item.idNumber,
                                                       idType: item.idType,
                                                     }))
                                                   : [directorDefaultItem]
                                               );

                                               useEffect(() => {
                                                 if (leaders?.length) {
                                                   setCollection(() => {
                                                     return leaders.map(
                                                       (item) => ({
                                                         phone: item.phone,
                                                         ...(!preference.bvnConsentEnabled
                                                           ? { bvn: item.bvn }
                                                           : {}),
                                                         email: item.email,
                                                         idNumber:
                                                           item.idNumber,
                                                         idType: item.idType,
                                                       })
                                                     );
                                                   });
                                                   leaders.forEach(
                                                     (leader, i) => {
                                                       setTimeout(() => {
                                                         setIdType(
                                                           leader.idType
                                                         );
                                                         setIdNumber(
                                                           leader.idNumber
                                                         );
                                                       }, (i + 1) * 1000);
                                                     }
                                                   );
                                                 }
                                               }, [leaders]);
                                               const [
                                                 validationCollection,
                                                 setValidationCollection,
                                               ] = useState<
                                                 ValidateArrayResult
                                               >();
                                               const [
                                                 loading,
                                                 setLoading,
                                               ] = useState(false);
                                               /**
                                                * Custom handlers initiation region
                                                */
                                               const push = () => {
                                                 if (
                                                   validationCollection?.isValid
                                                 ) {
                                                   const newCollection = collection.map(
                                                     (coll) => {
                                                       coll.isAdded = true;
                                                       coll.isEdit = false;
                                                       return coll;
                                                     }
                                                   );
                                                   setCollection([
                                                     ...newCollection,
                                                     directorDefaultItem,
                                                   ]);
                                                 }
                                               };

                                               const remove = (
                                                 index: number
                                               ) => {
                                                 setCollection([
                                                   ...collection.filter(
                                                     (_item, i) => i !== index
                                                   ),
                                                 ]);
                                               };

                                               const onChangeValue = (
                                                 index: number,
                                                 property: string,
                                                 value: unknown
                                               ) => {
                                                 setCollection(
                                                   (old) =>
                                                     old.map(
                                                       (
                                                         el: SignUpTrusteeInfoInterface,
                                                         i: number
                                                       ) => {
                                                         if (i === index) {
                                                           return {
                                                             ...el,
                                                             [property]: value,
                                                           };
                                                         }

                                                         return el;
                                                       }
                                                     ) as Array<
                                                       SignUpTrusteeInfoInterface
                                                     >
                                                 );
                                               };

                                               const getIdType = (
                                                 value: any
                                               ) => {
                                                 setIdType(value);
                                               };

                                               const getIdNumber = (
                                                 value: any
                                               ) => {
                                                 setIdNumber(value);
                                               };

                                               const idNumberScheme = {
                                                 idNumber: (value: string) =>
                                                   idType?.value ===
                                                     idTypes.nin ||
                                                   idType === idTypes.nin
                                                     ? value.length === 11
                                                     : idType?.value ===
                                                         idTypes.voters_card ||
                                                       idType ===
                                                         idTypes.voters_card
                                                     ? value.length === 19
                                                     : idType?.value ===
                                                         idTypes.drivers_license ||
                                                       idType ===
                                                         idTypes.drivers_license
                                                     ? value.length > 10
                                                     : idType?.value ===
                                                         idTypes.international_passport ||
                                                       idType ===
                                                         idTypes.international_passport
                                                     ? value.length === 9
                                                     : false,
                                               };

                                               const saveManagement = (
                                                 response: any,
                                                 newCollection: any
                                               ) => {
                                                 let responseData: any = [
                                                   ...newCollection,
                                                 ];
                                                 if (response.data.directors) {
                                                   responseData =
                                                     response.data.directors;
                                                 }
                                                 if (response.data.partners) {
                                                   responseData =
                                                     response.data.partners;
                                                 }
                                                 if (
                                                   response.data.proprietors
                                                 ) {
                                                   responseData =
                                                     response.data.proprietors;
                                                 }
                                                 if (
                                                   response.data.signatories
                                                 ) {
                                                   responseData =
                                                     response.data.signatories;
                                                 }

                                                 if (response.data.trustees) {
                                                   responseData =
                                                     response.data.trustees;
                                                 }
                                                 return responseData;
                                               };

                                               useEffect(() => {
                                                 setValidationCollection(
                                                   validateArrayOfObjects(
                                                     collection,
                                                     SCHEME
                                                   )
                                                 );
                                                 setIdNumberValidation(
                                                   validateObject(
                                                     {
                                                       idType,
                                                       idNumber,
                                                     },
                                                     idNumberScheme
                                                   )
                                                 );
                                               }, [
                                                 collection,
                                                 idType,
                                                 idNumber,
                                               ]);

                                               const continueHandle = async () => {
                                                 setLoading(true);
                                                 const newCollection = collection.map(
                                                   (_collection) => {
                                                     delete _collection.isAdded;
                                                     delete _collection.isEdit;
                                                     return _collection;
                                                   }
                                                 );
                                                 try {
                                                   let response: any;
                                                   if (
                                                     businessType ===
                                                     BusinessTypesEnum.partnership
                                                   ) {
                                                     response = await onboardingService.submitPartnersDetails(
                                                       {
                                                         partners: newCollection,
                                                         reference,
                                                       }
                                                     );
                                                   } else if (
                                                     businessType ===
                                                     BusinessTypesEnum.cooperativeSociety
                                                   ) {
                                                     response = await onboardingService.submitSignatoriesDetails(
                                                       {
                                                         signatories: newCollection,
                                                         reference,
                                                       }
                                                     );
                                                   } else if (
                                                     businessType ===
                                                     BusinessTypesEnum.trusteeNgo
                                                   ) {
                                                     response = await onboardingService.submitTrusteesDetails(
                                                       {
                                                         trustees: newCollection,
                                                         reference,
                                                       }
                                                     );
                                                   } else {
                                                     response = await onboardingService.submitSignUpDirectorsDetails(
                                                       {
                                                         directors: newCollection,
                                                         reference,
                                                       }
                                                     );
                                                   }
                                                   batch(() => {
                                                     dispatch(
                                                       OnboardingActions.handleSetData(
                                                         {
                                                           directors: saveManagement(
                                                             response,
                                                             newCollection
                                                           ),
                                                           reference:
                                                             response.data
                                                               .reference,
                                                         }
                                                       )
                                                     );
                                                   });
                                                   route.push(
                                                     actions[
                                                       response.actionRequired
                                                     ]
                                                   );
                                                 } catch (error) {
                                                   const err = error as any;
                                                   if (
                                                     err.code ===
                                                     'INVALID_ACTION_REQUIRED'
                                                   ) {
                                                     route.push(
                                                       actions[
                                                         err.message
                                                           .trim()
                                                           .split(':')[1]
                                                           .replace(/\s/g, '')
                                                       ]
                                                     );
                                                   } else {
                                                     dispatch(
                                                       OnboardingActions.handleSetError(
                                                         err.message ||
                                                           'Can not complete request at the moment'
                                                       )
                                                     );
                                                   }
                                                 }
                                                 setLoading(false);
                                               };

                                               const editInfo = (
                                                 index: number
                                               ) => {
                                                 const newCollection = [
                                                   ...collection,
                                                 ];
                                                 newCollection[
                                                   index
                                                 ].isEdit = true;
                                                 setCollection([
                                                   ...newCollection,
                                                 ]);
                                               };

                                               return (
                                                 <S.Container>
                                                   <S.TopTitle>
                                                     <Title
                                                       title={`Provide details of all ${getBusinessTypeIdLabel(
                                                         businessType as BusinessTypesEnum
                                                       )}s`}
                                                       subTitle='Minimum requirement is 2. '
                                                     />
                                                   </S.TopTitle>

                                                   <S.InfoContainer>
                                                     <InfoConponent
                                                       style={{
                                                         background: '#FFF9EF',
                                                       }}
                                                       text='Note that the consent of person provided here will be required for the account to become operational on VBiz.'
                                                     />
                                                   </S.InfoContainer>
                                                   <OnboardingCard>
                                                     {collection.map(
                                                       (item, index) => (
                                                         <div
                                                           style={{
                                                             marginBottom:
                                                               '30px',
                                                           }}
                                                         >
                                                           {!item.isAdded ||
                                                           item.isEdit ? (
                                                             <InfoForm2
                                                               title={`${
                                                                 businessType ===
                                                                 BusinessTypesEnum.partnership
                                                                   ? "Partner's"
                                                                   : businessType ===
                                                                     BusinessTypesEnum.cooperativeSociety
                                                                   ? "Principal officer's"
                                                                   : businessType ===
                                                                     BusinessTypesEnum.trusteeNgo
                                                                   ? "Trustee's"
                                                                   : "Director's"
                                                               } details`}
                                                               phone={
                                                                 item.phone
                                                               }
                                                               bvn={
                                                                 !preference.bvnConsentEnabled
                                                                   ? item.bvn
                                                                   : ''
                                                               }
                                                               idNumber={
                                                                 item.idNumber
                                                               }
                                                               idType={
                                                                 item.idType
                                                               }
                                                               email={
                                                                 item.email
                                                               }
                                                               onSelectId={
                                                                 getIdType
                                                               }
                                                               onChangeId={
                                                                 getIdNumber
                                                               }
                                                               index={index}
                                                               removeFromIndex={
                                                                 1
                                                               }
                                                               remove={remove}
                                                               onChange={
                                                                 onChangeValue
                                                               }
                                                               emailIsValid={
                                                                 validationCollection
                                                                   ?.data[index]
                                                                   ?.data.email
                                                                   .isValid
                                                               }
                                                               bvnIsValid={
                                                                 preference.bvnConsentEnabled
                                                                   ? true
                                                                   : validationCollection
                                                                       ?.data[
                                                                       index
                                                                     ]?.data.bvn
                                                                       .isValid
                                                               }
                                                               phoneIsValid={
                                                                 validationCollection
                                                                   ?.data[index]
                                                                   ?.data.phone
                                                                   .isValid
                                                               }
                                                             />
                                                           ) : (
                                                             <MinimizedInfo
                                                               title={`${item.idNumber} - (${item.idType})`}
                                                               description={`${item.phone} || ${item.email}`}
                                                               handleEdit={() =>
                                                                 editInfo(index)
                                                               }
                                                               handleDelete={() =>
                                                                 remove(index)
                                                               }
                                                             />
                                                           )}
                                                         </div>
                                                       )
                                                     )}

                                                     <div
                                                       style={{
                                                         display: 'flex',
                                                         justifyContent:
                                                           'space-between',
                                                       }}
                                                     >
                                                       <S.Add onClick={push}>
                                                         <Icon
                                                           name='plus'
                                                           color='#000000'
                                                         />
                                                         <Text color='#7F91A8'>{`Add ${
                                                           businessType ===
                                                           BusinessTypesEnum.partnership
                                                             ? 'Partner'
                                                             : businessType ===
                                                               BusinessTypesEnum.cooperativeSociety
                                                             ? 'Signatory'
                                                             : businessType ===
                                                               BusinessTypesEnum.trusteeNgo
                                                             ? 'Trustee'
                                                             : 'Director'
                                                         }`}</Text>
                                                       </S.Add>

                                                       {collection.length >
                                                         1 && (
                                                         <div
                                                           style={{
                                                             display: 'flex',
                                                             gap: 10,
                                                             alignItems:
                                                               'center',
                                                             marginBottom: ptr(
                                                               40
                                                             ),
                                                             cursor: 'pointer',
                                                           }}
                                                           onClick={() =>
                                                             collection.length >
                                                               1 &&
                                                             remove(
                                                               collection.length -
                                                                 1
                                                             )
                                                           }
                                                         >
                                                           <Icon
                                                             name='cancel'
                                                             color={
                                                               colors.red._100
                                                             }
                                                           />
                                                           <Text
                                                             size={6}
                                                             color={
                                                               colors.red._100
                                                             }
                                                           >
                                                             Cancel
                                                           </Text>
                                                         </div>
                                                       )}
                                                     </div>

                                                     <S.ButtonGroup>
                                                       <div>
                                                         <Button
                                                           label='Continue'
                                                           loading={loading}
                                                           disabled={
                                                             !validationCollection?.isValid ||
                                                             !idNumberValidation?.isValid ||
                                                             loading ||
                                                             collection.length <
                                                               2
                                                           }
                                                           onClick={
                                                             continueHandle
                                                           }
                                                         />
                                                       </div>
                                                     </S.ButtonGroup>
                                                   </OnboardingCard>
                                                 </S.Container>
                                               );
                                             };
export default SignUpManagementInfo;
