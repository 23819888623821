import React from 'react';

import { OneCenter, ContentContainer } from '../../styles/wrappers';
import { Logo } from '../../components/Logo';
import { NewPasswordForm } from './components/NewPasswordForm';
import { WelcomeSlider } from '../../components/WelcomeSlider';
import { TwoSide, Columns, FormButton } from '../../styles/wrappers';
import { RoundButton } from '../../components/RoundButton';
import { FormTitle } from '../../components/FormTitle';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { validateObject, ValidateResult } from '../../utils/validation';
import * as LoginStyle from '../LogIn/styles';
import { SuccessModal } from '../../components/SuccessModal';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';
import { useHistory } from 'react-router-dom';
import { ptr } from '../../styles/utils';
import { SignUpButton } from '../../components/SignupButton';

const NewPassword: React.FC = () => {
  const history = useHistory();

  return (
    <TwoSide.Wrapper>
      <TwoSide.Left>
        <WelcomeSlider />
      </TwoSide.Left>
      <TwoSide.Right>
        <Columns.Wrapper>
          <Columns.ContentWrapper padding={`${ptr(10)} 0`}>
            <SignUpButton />
          </Columns.ContentWrapper>
          <div>
            <Columns.ContentWrapper>
              <OneCenter.Wrapper>
                <ContentContainer
                  flex={1}
                  justifyContent='center'
                  alignItems='center'
                >
                  <OneCenter.ContentWrapper>
                    <NewPasswordForm />
                  </OneCenter.ContentWrapper>
                </ContentContainer>
              </OneCenter.Wrapper>
            </Columns.ContentWrapper>
          </div>

          <Columns.ContentWrapper />
          <LoginStyle.Onboarding>
            <Text color='#6E7191'>
              © {new Date().getFullYear()} VFD MFB, all rights reserved.
            </Text>
          </LoginStyle.Onboarding>
        </Columns.Wrapper>
      </TwoSide.Right>
    </TwoSide.Wrapper>
  );
};

export default NewPassword;
