import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { UserDataInterface } from './ICurrentUser';
import userService from '../../services/internal/user/user.service';
import { ActionInterface } from '../IAction';
import { AppDispatch } from '../index';
import authService from '../../services/internal/auth/auth.service';

export const USER_REQUEST = 'AUTHORIZATION/REQUEST';
export const USER_SUCCESS = 'AUTHORIZATION/SUCCESS';
export const USER_FAILURE = 'AUTHORIZATION/FAILURE';

const userRequest = (): ActionInterface<unknown> => ({
  type: USER_REQUEST,
});

export const userSuccess = (payload: UserDataInterface) => ({
  type: USER_SUCCESS,
  payload,
});

const userFailure = (): ActionInterface<unknown> => ({
  type: USER_FAILURE,
});

export class CurrentUserActions {
  public static getUserData(): ThunkAction<Promise<void>, any, any, AnyAction> {
    return async (dispatch: AppDispatch) => {
      dispatch(userRequest());
      try {
        const result = await userService.getCurrentUserData() as UserDataInterface;
        dispatch(userSuccess(result));
      } catch (error) {
        authService.logOut();
        dispatch(userFailure());
      }
    };
  }
}
