import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { colors, fonts } from '../../styles/variables';

const adaptiveBreakPoint = 800;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BeneficiaryWrapper = styled.div`
  :not(:first-child) {
    margin: ${ptr(40)} 0;
  }

  :first-child {
    margin-bottom: ${ptr(40)};
  }
`;

const InputsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputsWrapper = styled.div`
  display: flex;
  margin-top: ${ptr(24)};
  flex-flow: wrap;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  min-width: calc(50% - ${ptr(6)});
  margin-bottom: ${ptr(12)};
  
  :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    width: 100%;
  }
`;

const DestinationInfo = styled.tbody`
  display: table;
  width: 100%;
  margin-top: ${ptr(12)};
`;

const DestinationRow = styled.tr`
  :not(:first-child) {
    margin-top: ${ptr(4)}:
  }
`;

const DestinationColumn = styled.td`
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(12)};
  color: ${colors.black._60};
  line-height: 140%;

  :not(:first-child) {
    text-align: right;
  }
`;

const SwitchWrapper = styled.div`
  min-width: calc(50% - ${ptr(6)});
  margin-top: ${ptr(8)};

  @media (max-width: ${ptr(adaptiveBreakPoint)}) {
    width: 100%;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${ptr(8)};
`;

const Separator = styled.div`
  width: 100%;
  height: ${ptr(1)};
  background-color: ${colors.black._10};
`;

export {
  Wrapper,
  BeneficiaryWrapper,
  InputsHeaderWrapper,
  InputsWrapper,
  InputWrapper,
  DestinationInfo,
  DestinationRow,
  DestinationColumn,
  SwitchWrapper,
  FooterWrapper,
  Separator,
};
