/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CompanyActions } from '../../redux/company/actions';
import { CompanyInterface } from '../../redux/company/ICompany';

import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';
import { Icon } from '../../components/Icon';

import { Text } from '../../components/Text';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { Table, TableFooterProps } from '../../components/Table';
import { Preload } from '../../components/Preload';
import { CheckBox } from '../../components/CheckBox';
import { ModalWrapper } from '../../components/ModalWrapper';
import SearchInput from '../../components/SearchInput';
import { PaymementAccountInterface } from '../../services/internal/payments/IPayments';
import { FilterResult } from '../AnalyticsNReport/Component/FilterResult';
import { PaymentDetailsViews } from '../AnalyticsNReport/Component/PaymentDetailsViews';
import { formatCash } from '../../utils/money';
import {
  getBeautyDateTimeString,
  getDateWithYearFirst,
} from '../../utils/datetime';
import { IPagination, Pagination } from '../../components/Pagination';
import { IGetQueries } from '../../interface';
import getQueryString from '../../utils/getQueryString';
import { PaginationActions } from '../../redux/paginationSettings/actions';
import { Button } from '../../components/Button';
import { BackBar } from '../../components/BackBar';
import { failed } from '../../components/Toasts';
import { PinAuth } from '../../components/PaymentConfirms/PinAuth';
import { FormTitle } from '../../components/FormTitle';
import { TextInput } from '../../components/TextInput';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { RoundButton } from '../../components/RoundButton';
import { AuthInterface } from '../../redux/authorization/IAuthorization';

const separator = '=';

interface ViewComponentProps {
  Individualpayment: any;

  Bulkpayment: any;
}
export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}
export interface PaymentHistory {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
  check: boolean;
  actionRequired: string;
  fromAccountNo: string;
  beneficiaryTypes: string;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
}
export interface ModalViewComponent {
  FilterResult: any;
  PaymentDetails: any;
}

export const IndividualPayment: React.FC<{
  batchPayments?: Array<PaymentHistory>;
  getBatchDetails?: (queriesData?: IGetQueries) => Promise<void>;
  showBackButton?: boolean;
  backAction?: () => void;
  checkAll?: boolean;
  batchPagination?: IPagination;
}> = ({
  batchPayments,
  getBatchDetails,
  showBackButton = false,
  backAction,
  checkAll = false,
  batchPagination,
}) => {
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Redux store initiation region
   */
  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );
  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list
  );

  const userRole = useSelector(
    (state: { authorization: AuthInterface }) => state.authorization.roles
  );

  /**
   * Local state initiation region
   */
  const [currentCompanyId, setCurrentCompanyId] = useState<string>('');

  const [paymentAccounts, setPaymentAccounts] = useState<
    Array<PaymementAccountInterface & { isChecked: boolean }>
  >();
  const [payments, setPayments] = useState<Array<PaymentHistory>>([]);
  const [empty] = useState<boolean>(false);
  const [bottomLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [executing, setExecuting] = useState(false);
  const [showmodalView, setshowmodalView] = useState<boolean>(false);
  const [currentView, setcurrentView] = useState<string>('');
  const [currentPaymentDetails, setcurrentPaymentDetails] = useState<
    PaymentHistory
  >();
  const [year, setYear] = useState<number>();
  const [text, setText] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>({
    limit: 50,
    currentPage: 1,
    pageItems: 1,
    totalPages: 1,
  });
  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});
  const [showAuthModal, setshowAuthModal] = useState<boolean>(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState('');
  const [approvalComment, setApprovalComment] = useState('');
  const [reject, setReject] = useState(false);
  const [approve, setApprove] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [CurrentUserView, ChangeuserView] = useState<string>(
    'Individualpayment'
  );

  useEffect(() => {
    PaginationActions.reset();
  }, []);

  useEffect(() => {
    if (batchPayments && batchPayments.length > 0) {
      if (checkAll) {
        setPayments(
          batchPayments.map((el) => ({
            ...el,
            check: el.status === 'pending',
          }))
        );
      } else {
        setPayments(batchPayments.map((el) => ({ ...el, check: false })));
      }
    }
  }, [batchPayments, checkAll]);

  useEffect(() => {
    if (batchPagination) setPagination(batchPagination);
  }, [batchPagination]);

  /**
   * Custom handlers initiation region
   */
  const getPayments = async (queriesData?: IGetQueries): Promise<void> => {
    try {
      if (!queriesData?.search) setLoading(true);
      const queries =
        queriesData && queriesData.search
          ? { ...queriesData }
          : {
              page: 1,
              limit: 50,
              ...additionalQueries,
              ...queriesData,
            };

      if (!queriesData?.search) {
        delete queries.search;
        setText('');
      }
      const result = await paymentsService.getPayments(getQueryString(queries));
      setAddtionalQueries(queries);
      setLoading(false);
      const paymentdata = result.data.map((el: IPayments) => ({
        ...el,
        check: false,
      }));
      setPagination({
        limit: result.meta.limit,
        currentPage: result.meta.page,
        pageItems: result.meta.pageCount,
        totalPages: result.meta.totalPages,
      });
      setPayments(paymentdata);
    } catch (err) {
      const error = err as Error;
      debug.error('Error retrieving payments', err);
      failed('Failed', error.message || 'Error retrieving payments');
    }
  };

  const handleSearchText = (value: string) => {
    setText(value);
    if (!value.length) {
      if (getBatchDetails) return getBatchDetails({ page: 1 });
      getPayments({ page: 1 });
    }
  };
  const getPaymentAccounts = async (id: string): Promise<void> => {
    try {
      const result = await paymentsService.getPaymentAccounts(id);
      setPaymentAccounts(result.map((item) => ({ ...item, isChecked: false })));
    } catch (err) {
      debug.error('Error while getting payment accounts on dashboard', err);
    }
  };

  const getCompanyFromUrl = (location: Location | any) => {
    const companyId = location.search.split(separator)[1];
    if (companyId) {
      const company = companies.find((item) => item.id === companyId);
      setCurrentCompanyId(company?.id || '');
    }
  };
  const UserModalView = currentView as keyof typeof ModalView;

  // useEffect(() => {
  // 	if (activeCompany) {
  // 		getPaymentAccounts(currentCompanyId || activeCompany.id);
  // 	}
  // }, [currentCompanyId]);

  useEffect(() => {
    let delayDebounceFn: any = null;
    if (text.length) {
      delayDebounceFn = setTimeout(() => {
        if (getBatchDetails) {
          getBatchDetails({ search: text, page: 1 });
        } else {
          getPayments({ search: text, page: 1 });
        }
      }, 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [text]);

  const ModalView: ModalViewComponent = {
    FilterResult: (
      <FilterResult
        action={getBatchDetails || getPayments}
        showModal={setshowmodalView}
      />
    ),
    PaymentDetails: <PaymentDetailsViews data={currentPaymentDetails} />,
  };

  const getStatusButton = (el: PaymentHistory) => {
    if (el.status === 'success') {
      return (
        <S.BtnSuccess>
          <Text size={6}>{el.status}</Text>
        </S.BtnSuccess>
      );
    }
    if (el.status === 'pending') {
      return (
        <S.BtnWarning>
          <Text size={6}>{el.status}</Text>
        </S.BtnWarning>
      );
    }
    if (el.status === 'failed') {
      return (
        <S.BtnDanger>
          <Text size={6}>{el.status}</Text>
        </S.BtnDanger>
      );
    }
    return (
      <S.BtnWarning>
        <Text size={6}>{el.status}</Text>
      </S.BtnWarning>
    );
  };

  const getYearFilter = (value: number) => {
    const from = getDateWithYearFirst(new Date(value, 0, 1));
    const to = getDateWithYearFirst(new Date(value, 11, 31));
    getPayments({ page: 1, to, from });
    setAddtionalQueries({ page: 1, to, from });
    setYear(value);
  };

  const searchPayments = async () => {
    if (text) {
      setAddtionalQueries({ search: text, page: 1 });
      if (getBatchDetails) return getBatchDetails({ search: text, page: 1 });
      await getPayments({ search: text, page: 1 });
    }
  };

  const executePayment = async (
    pin: string,
    checkPin: boolean,
    otp?: string
  ): Promise<any> => {
    setExecuting(true);
    try {
      const paymentsToExecute = payments
        .filter((pay) => pay.check)
        .map((payMap) => payMap.id.toString());

      const result = await paymentsService.approvePayments({
        otp,
        checkPin,
        pin,
        payments: paymentsToExecute,
        comment: approvalComment,
      });
      if (!result?.message) {
        setPayments(payments.map((pay) => ({ ...pay, check: false })));
        setshowAuthModal(false);
        setApprove(false);
        if (getBatchDetails) {
          getBatchDetails();
        } else {
          getPayments();
        }
        setApprovalComment('');
      }
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const rejectPayment = async (
    pin: string,
    checkPin: boolean,
    otp?: string
  ): Promise<any> => {
    setExecuting(true);
    try {
      const paymentsToReject = payments
        .filter((pay) => pay.check)
        .map((payMap) => payMap.id.toString());

      const result = await paymentsService.rejectPayments({
        otp,
        pin,
        checkPin,
        payments: paymentsToReject,
        comment,
      });
      if (!result?.message) {
        setPayments(payments.map((pay) => ({ ...pay, check: false })));
        setshowAuthModal(false);
        setReject(false);
      }
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getComment = (value: string) => {
    setComment(value);
  };

  const getApprovalComment = (value: string) => {
    setApprovalComment(value);
  };

  const getActionName = () => {
    if (reject) {
      return 'Confirm Rejection';
    }
    if (approve) {
      return 'Confirm Approval';
    }
    return 'Make Transfer';
  };

  const setcurrentPaymentdetails = (details: PaymentHistory) => {
    // const myArray = [];
    // myArray.push(details);
    setcurrentPaymentDetails(details);
    setcurrentView('PaymentDetails');
    setshowmodalView(true);
  };

  const renderModalRecord = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={showAuthModal}
      onCloseModal={() => {
        setshowAuthModal(false);
        window.location.reload();
      }}
      crossColor={colors.black._100}
    >
      <PinAuth
        action={reject ? rejectPayment : executePayment}
        actionName={getActionName()}
      />
    </ModalWrapper>
  );

  const continueHandle = () => {
    setshowAuthModal(true);
    setShowCommentModal(false);
  };

  const handleApproval = () => {
    if (approvalComment || !approvalComment) {
      setShowApprovalModal(false);
      setApprove(true);
      setshowAuthModal(true);
    }
  };

  const renderCommentModal = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={showCommentModal}
      onCloseModal={() => setShowCommentModal(false)}
      crossColor={colors.black._100}
    >
      <S.CommentContainer>
        <FormTitle title="Add a reason for this action" />
        <TextInput label="Comment" rightText="Optional" onChange={getComment} />
        <S.CommentButton>
          <Button label="Continue" size="medium" onClick={continueHandle} />
        </S.CommentButton>
      </S.CommentContainer>
    </ModalWrapper>
  );

  const renderApproveModal = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={showApprovalModal}
      onCloseModal={() => setShowApprovalModal(false)}
      crossColor={colors.black._100}
    >
      <S.CommentContainer>
        <div style={{ textAlign: 'center' }}>
          <FormTitle title="Add a reason for this approval" />
        </div>

        <TextInput
          label="Comment"
          rightText="Optional"
          onChange={getApprovalComment}
        />

        <S.CommentButton>
          <Button label="Continue" size="medium" onClick={handleApproval} />
        </S.CommentButton>
      </S.CommentContainer>
    </ModalWrapper>
  );

  const checkAllStatus = (el: PaymentHistory): boolean => {
    if (
      el.status === 'pending' &&
      el.actionRequired &&
      userRole.includes(el.actionRequired)
    )
      return true;
    return false;
  };

  const renderTableItem = () =>
    payments ? (
      <>
        {renderModalRecord()}
        {renderCommentModal()}
        {renderApproveModal()}
        {showBackButton && (
          <RoundButton
            icon="arrow-left"
            onClick={() => {
              if (backAction) return backAction();
              return;
            }}
          />
        )}
        <S.TopWrapper>
          <S.TableConatiner>
            <S.TableIIConatiner>
              <SearchInput
                onChange={handleSearchText}
                value={text}
                onSubmitSearch={searchPayments}
                placeholder="Search for..."
              />

              <S.MarginLeftComp
                type="icon"
                onClick={() => FilterResultHandler()}
              >
                <Icon
                  name="settings"
                  color={colors.pink._100}
                  width={12}
                  height={12}
                />
              </S.MarginLeftComp>

              <AccessControl
                requiredPermission={[EPermissions.APPROVE_PAYMENT]}
              >
                <S.MarginLeftComp>
                  <Button
                    label="Reject"
                    backgroundColor={colors.pink._10}
                    color={colors.pink._100}
                    disabled={
                      !payments.some(
                        (pay) =>
                          pay.check && userRole.includes(pay.actionRequired)
                      )
                    }
                    onClick={() => {
                      setReject(true);
                      setShowCommentModal(true);
                    }}
                  />
                </S.MarginLeftComp>
              </AccessControl>
              <AccessControl
                requiredPermission={[EPermissions.APPROVE_PAYMENT]}
              >
                <S.MarginLeftComp>
                  <Button
                    label="Approve"
                    theme="primary"
                    disabled={
                      !payments.some(
                        (pay) =>
                          pay.check && userRole.includes(pay.actionRequired)
                      )
                    }
                    onClick={() => {
                      setShowApprovalModal(true);
                    }}
                  />
                </S.MarginLeftComp>
              </AccessControl>
            </S.TableIIConatiner>
          </S.TableConatiner>
        </S.TopWrapper>

        <S.TableContainer loading={loading}>
          <Table
            header={{
              cells: {
                0: {
                  align: 'left',
                  children: (
                    <AccessControl
                      requiredPermission={[EPermissions.APPROVE_PAYMENT]}
                    >
                      <CheckBox
                        disabled={
                          !payments.some(
                            (pay) =>
                              pay.status === 'pending' &&
                              pay.actionRequired &&
                              userRole.includes(pay.actionRequired)
                          )
                        }
                        onChange={(isChecked) => {
                          if (isChecked) {
                            setPayments((old) =>
                              old.map((el) => ({
                                ...el,
                                check: checkAllStatus(el),
                              }))
                            );
                          } else {
                            setPayments((old) =>
                              old.map((el) => ({ ...el, check: false }))
                            );
                          }
                        }}
                      />
                    </AccessControl>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                1: {
                  align: 'left',
                  children: (
                    <Text size={7} bold>
                      REQUEST DATE
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                // 2: {

                //   align: 'left',
                //   children: <Text size={7} bold>COUNT</Text>,
                //   padding: '1.25rem 1.5rem',
                // },
                2: {
                  align: 'left',
                  children: (
                    <Text size={7} bold>
                      TOTAL AMOUNT
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                3: {
                  align: 'left',
                  children: (
                    <Text size={7} bold>
                      FROM ACCOUNT
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                4: {
                  align: 'left',
                  children: (
                    <Text size={7} bold>
                      TO
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                5: {
                  align: 'right',
                  children: (
                    <Text size={7} bold>
                      STATUS
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                6: {
                  align: 'right',
                  children: (
                    <Text size={7} bold>
                      DATE UPDATED
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                7: {
                  align: 'right',
                  children: (
                    <Text size={7} bold>
                      {' '}
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
              },
              padding: '1.25rem 0',
            }}
            footer={((): TableFooterProps | undefined => {
              if (bottomLoading) {
                return {
                  backgroundColor: colors.white._100,
                  cells: {
                    0: {
                      align: 'center',
                      children: (
                        <Icon name="small-loading" color={colors.black._100} />
                      ),
                      padding: '1.25rem 1.5rem',
                      colspan: 6,
                    },
                  },
                };
              }
              if (empty) {
                return {
                  backgroundColor: colors.white._100,
                  cells: {
                    0: {
                      align: 'center',
                      children: <Text>Result not found</Text>,
                      padding: '1.25rem 1.5rem',
                      colspan: 6,
                    },
                  },
                };
              }
              return undefined;
            })()}
            rows={payments?.map((el) => ({
              padding: '1.25rem 0',
              border: {
                color: colors.black._10,
                width: 1,
                style: 'solid',
                sides: ['top'],
              },
              cells: {
                0: {
                  align: 'left',
                  children:
                    el.status === 'pending' &&
                    el.actionRequired &&
                    userRole.includes(el.actionRequired) ? (
                      <AccessControl
                        requiredPermission={[EPermissions.APPROVE_PAYMENT]}
                      >
                        <CheckBox
                          checked={el.check}
                          onChange={(isChecked) => {
                            setPayments((old) =>
                              old.map((pay) => {
                                if (pay.id === el.id) pay.check = isChecked;
                                return pay;
                              })
                            );
                          }}
                        />
                      </AccessControl>
                    ) : (
                      <span />
                    ),
                  padding: '1.25rem 1.5rem',
                  display: 'inline-block',
                },
                1: {
                  align: 'left',

                  children: (
                    <S.Column>
                      <Text size={7}>
                        {getBeautyDateTimeString(new Date(el.createdAt))}
                      </Text>
                    </S.Column>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                2: {
                  align: 'left',
                  children: (
                    <Text size={6}>{`₦ ${formatCash(el.amount)}`}</Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                3: {
                  align: 'left',
                  children: <Text size={6}>{el.fromAccountNo}</Text>,
                  padding: '1.25rem 1.5rem',
                },
                4: {
                  align: 'left',
                  children: <Text size={6}>{el.receiverName}</Text>,
                  padding: '1.25rem 1.5rem',
                },
                5: {
                  align: 'right',
                  children: (
                    <div>
                      {el.status === 'pending' && el.actionRequired ? (
                        <S.BtnWarning>
                          <Text size={6}>{`Pending ${el.actionRequired}`}</Text>
                        </S.BtnWarning>
                      ) : (
                        getStatusButton(el)
                      )}
                    </div>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                6: {
                  align: 'left',

                  children: (
                    <S.Column>
                      <Text size={7}>
                        {getBeautyDateTimeString(new Date(el.updatedAt))}
                      </Text>
                    </S.Column>
                  ),
                  padding: '1.25rem 1.5rem',
                },
                7: {
                  align: 'right',
                  onClick: () => setcurrentPaymentdetails(el),
                  children: (
                    <Text size={6} color="#C6145E">
                      View
                    </Text>
                  ),
                  padding: '1.25rem 1.5rem',
                },
              },
            }))}
          />

          {payments.length > 0 && (
            <Pagination
              {...pagination}
              action={getBatchDetails || getPayments}
            />
          )}
        </S.TableContainer>
      </>
    ) : null;

  const renderModalView = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="inherit"
      maxHeight="96vh"
      showModal={showmodalView}
      onCloseModal={() => setshowmodalView(false)}
      closeBackground={
        UserModalView === 'PaymentDetails' ? colors.pink._20 : ''
      }
      crossColor={colors.black._100}
    >
      {ModalView[UserModalView]}
    </ModalWrapper>
  );

  const FilterResultHandler = () => {
    setcurrentView('FilterResult');
    setshowmodalView(true);
    const queries = additionalQueries;
    delete queries.to;
    delete queries.from;
    setAddtionalQueries(queries);
  };

  /**
   * Listeners region
   */
  history.listen((location) => {
    getCompanyFromUrl(location);
  });

  /**
   * useEffect region
   */
  useEffect(() => {
    if (!companies?.length) {
      dispatch(CompanyActions.getCompaniesList());
    }

    if (!activeCompany) {
      dispatch(CompanyActions.getActiveCompany());
    } else {
      setCurrentCompanyId(activeCompany.id);
    }

    if (companies?.length && activeCompany) {
      if (!getBatchDetails) {
        getPayments();
      }
      getCompanyFromUrl(history.location);
    }
  }, [companies, activeCompany]);

  return (
    <AccessControl isPage requiredPermission={[EPermissions.APPROVE_PAYMENT]}>
      <S.Container>
        {renderModalView()}

        {loading ? (
          <S.PreloadContainer>
            <Preload />
          </S.PreloadContainer>
        ) : (
          renderTableItem()
        )}
      </S.Container>
    </AccessControl>
  );
};
