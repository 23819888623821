/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { colors } from '../../styles/variables';
import * as S from './styles';
import { companyDetailsSettingsRoute } from '../../navigation';
import { insertParams } from '../../utils/navigation';
import { ActiveBusinessInterface } from '../../services/internal/payments/IPayments';
import welcome from '../../../src/assets/images/WelcomeImage.svg';
import { useDispatch } from 'react-redux';
import { OnboardingActions } from '../../redux/onboarding/actions';
import { setOperatorsRoute } from '../../routing/setOperators';
export interface WelcomesProps {
  currentCompany: ActiveBusinessInterface;
  closeModal: any;
}

const WelcomeNote: React.FC<WelcomesProps> = ({
  currentCompany,
  closeModal,
}) => {
  const route = useHistory();
  const dispatch = useDispatch();

  const gotWorkflow = () => {
    closeModal();
    dispatch(OnboardingActions.handleSetEnv());
    // const NavigationLink = insertParams(companyDetailsSettingsRoute, { id: currentCompany?.id });
    //  window.location.href = "/onboarding/corporate/select-operator";
    //  route.push(setOperatorsRoute);
  };
  return (
    <S.Wrapper>
      <S.Container>
        <S.Padding>
          <S.ImageWrapper>
            <Icon name='welcomeicon2' color={colors.black._100} />
          </S.ImageWrapper>
          <S.Main>
            <Text size={3} color={colors.pink._100} bold>
              Set up account operation
            </Text>
          </S.Main>
          <S.Margin>
            <Text size={4}>Set operators to have access to account</Text>
          </S.Margin>
          <S.Button>
            <Button label='Get started' size='medium' onClick={gotWorkflow} />
          </S.Button>
        </S.Padding>
      </S.Container>
    </S.Wrapper>
  );
};

export default WelcomeNote;
