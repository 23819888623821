import React from 'react';
import { FormTitle } from '../../../../components/FormTitle';
import { ClickablePanel } from '../../../../components/ClickablePanel';

import * as S from './styles';

export interface StepSecondProps {
  /**
   * handler on click continue
   */
  onContinue?: () => void,
}

export const StepSecond: React.FC<StepSecondProps> = ({ onContinue = () => {} }) => (
  <>
    <FormTitle
      title="How do you would like to register?"
      description="Select the way you would like to register below:"
    />
    <S.ClicableWrapper>
      <ClickablePanel
        circlesOptions={[
          {
            radius: 100,
            rotate: 46,
            position: {
              left: '25%',
              bottom: '-25%',
            },
          },
          {
            radius: 60,
            rotate: 0,
            position: {
              right: '-5%',
              bottom: '20%',
            },
          },
        ]}
        title="By BVN"
        onClick={onContinue}
      />
    </S.ClicableWrapper>
    <ClickablePanel
      circlesOptions={[
        {
          radius: 60,
          rotate: 66,
          position: {
            left: '-5%',
            bottom: '-20%',
          },
        },
        {
          radius: 60,
          rotate: 0,
          position: {
            right: '25%',
            bottom: '0%',
          },
        },
      ]}
      title="By Other Bank Account Number"
      onClick={onContinue}
    />
  </>
);
