import { FC } from 'react';
import { colors, fonts } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import * as S from './styles';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { Button } from '../Button';
import { useHistory } from 'react-router-dom';
import { limit, twoFactor } from '../../navigation';
import { useSelector } from 'react-redux';
import { InfoConponent } from '../Info';
import { formatCash } from '../../utils/money';
import { AuthInterface } from '../../redux/authorization/IAuthorization';
import { EPermissions } from '../AccessControl';

interface IProps {
  toggle: () => void;
  show: boolean;
  showSkipButton?: boolean;
  fromRoute?: string;
  totalLimit: number;
  usedLimit: number;
  availableLimit: number;
  showContinueTransferButton?: boolean;
  setContinue?: (val: boolean) => void;
}
const LimitInitiationModal: FC<IProps> = ({
  toggle,
  show,
  fromRoute,
  totalLimit,
  usedLimit,
  availableLimit,
  showContinueTransferButton = true,
  setContinue,
}) => {
  const history = useHistory();
  const userPermission = useSelector(
    (state: { authorization: AuthInterface }) => state.authorization.permissions
  );

  const requestLimit = () => history.push(`${limit}/request`);

  const handleContinue = () => {
    if (setContinue) setContinue(true);
    toggle();
  };

  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      onCloseModal={toggle}
      crossColor={colors.black._100}
      title="Daily transaction limit"
      titleStyle={{
        fontSize: 28,
        fontWeight: 700,
        color: '##2C2A3B',
        fontFamily: fonts.inter.bold,
      }}
    >
      <S.ContentContainer>
        <S.TextContainer>
          <S.TextKey>Current daily transaction limit </S.TextKey>
          <S.TextValue>N{formatCash(totalLimit)}</S.TextValue>
        </S.TextContainer>
        <S.TextContainer>
          <S.TextKey>Amount used </S.TextKey>
          <S.TextValue>N{formatCash(usedLimit)}</S.TextValue>
        </S.TextContainer>
        <S.TextContainer>
          <S.TextKey>Available transfer balance </S.TextKey>
          <S.TextSuccessValue>N{formatCash(availableLimit)}</S.TextSuccessValue>
        </S.TextContainer>

        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <InfoConponent
            style={{ background: '#F7F7FC', borderRadius: 5 }}
            infoIcon="info_with_bg"
            text={
              <span style={{ color: colors.black._20 }}>
                <strong
                  style={{
                    fontFamily: fonts.inter.bold,
                    color: colors.amber._90,
                  }}
                >
                  Notice:{' '}
                </strong>
                The transaction amount you entered exceeds your daily transfer
                limit balance.
              </span>
            }
          />
        </div>

        <S.ButtonContainer>
          <S.TransferButton>
            {showContinueTransferButton && (
              <Button
                style={{ fontSize: 14 }}
                theme="info"
                // size="small"
                label="Continue to transfer"
                onClick={handleContinue}
              />
            )}
          </S.TransferButton>
          <Button
            style={{ fontSize: 14, width: '100%' }}
            theme="primary"
            // size="small"
            disabled={!userPermission.includes(EPermissions.CREATE_OPERATOR)}
            label="Request limit increase upgrade"
            onClick={requestLimit}
          />
        </S.ButtonContainer>
      </S.ContentContainer>
    </ModalWrapper>
  );
};

export default LimitInitiationModal;
