export type Action = string | undefined;

export type Details = {
  title: string;
  info: Array<string>;
  list?: Array<string>;
  route?: string;

  clickablePanelList?: Array<{
    icon: string
    title: string
    info: string
    buttonLabel: string
    backgroundColor: string
    type: string
  }>
};

// export type SignupOptionDetails = {
//   title: string
//   info: Array<string>
//   clickablePanelList: Array<{
//     icon: string
//     title: string
//     info: string
//     buttonLabel: string
//     backgroundColor: string
//     type: string
//   }>



// }

const signUp = {
  title: "Open New Account",
  info: [
    "I have a registered business and will like to open a corporate account with Vbank",
  ],
  list: [
    "Bank verification number (BVN)",
    "Valid ID number",
    "Phone number",
    "Email address (Username)",
    "Password set up."
  ],
  // route: "/onboarding/signup/personal-information",
  route: "/onboarding/signup/verify-bvn",
};

const createProfile = {
  title: "Sign up with an existing account",
  info: ["I have an existing corporate account and will like to create a profile on VBiz"],
  list: [
    "Company account number",
    "Bank verification number (BVN)",
    "Valid ID number",
    "Phone number",
    "Email address (username)",
    "Password set up.",
  ],
  route: "/onboarding/corporate/create",
};

const signupOptions = {
  title: "Sign Up",
  info: ["I will like to set up a new or existing corporate account on VBiz"],
  clickablePanelList: [
    {
      icon: 'newAccount',
      title: 'Open New Account',
      info: 'I have a registered business and will like to open a corporate account with Vbank',
      buttonLabel: 'View requirements',
      backgroundColor: '#EEF7FF',
      type: 'signUp',
      route: '/onboarding/corporate/create'
    },
    {
      icon: 'existingAccount',
      title: 'Sign up with an existing account',
      info: 'I have an existing corporate account and will like to create a profile on VBiz',
      buttonLabel: 'View requirements',
      backgroundColor: '#FFF4DF',
      type: 'createProfile',
      route: '/onboarding/signup/personal-information'
    },
  ],

};


export const getOnboardModalDetail = (action: Action, bvnConsentEnabled: boolean): Details | null => {
  switch (action?.toLocaleLowerCase()) {
    case "signup":
      if (!bvnConsentEnabled) {
        signUp.route = '/onboarding/signup/personal-information'
      }
      return signUp;
    case "createprofile":
      return createProfile;
    case "signupoptions":
      return signupOptions;
    default:
      return null;
  }
};
