import styled from 'styled-components';

import { ptr } from '../../styles/utils';

const Wrapper = styled.div``;

const QuestionTitleAndDescriptionWrapper = styled.div`
  margin-bottom: ${ptr(24)};
`;

const QuestionTitleWrapper = styled.div``;

const QuestionDescriptionWrapper = styled.div`
  margin-top: ${ptr(4)};
`;

const QuestionCheckAreasWrapper = styled.div``;

const QuestionWrapper = styled.div`
  margin: ${ptr(32)} 0;
`;

const Separator = styled.div`
  height: ${ptr(16)};
`;

const QuestionCheckBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${ptr(-16)};
  
  > div {
    margin-top: ${ptr(16)};
    margin-right: ${ptr(40)};
  }
`;

export {
  Wrapper,
  QuestionTitleAndDescriptionWrapper,
  QuestionTitleWrapper,
  QuestionDescriptionWrapper,
  QuestionCheckAreasWrapper,
  QuestionWrapper,
  Separator,
  QuestionCheckBoxesWrapper,
};
