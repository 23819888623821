import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import validator from "validator";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  DropdownInput,
  DropdownOptionProps,
} from "../../../../components/DropdownInput";
import { TextInput } from "../../../../components/TextInput";
import { Button } from "../../../../components/Button";
import { Title } from "../../../Onboarding/components/Title";
import { Text } from "../../../../components/Text";
import { validateObject, ValidateResult } from "../../../../utils/validation";
import {
  BusinessType,
  getBusinessRequestType,
  OnboardingInterface,
} from "../../../../redux/onboarding/IOnboarding";
import { colors } from "../../../../styles/variables";
import * as S from "../styles";
import { industryTypes } from "../../constants";
import { useHistory } from "react-router-dom";
import { actions } from "../../config";
import onboardingService from "../../../../services/internal/onboarding/onboarding.service";
import { OnboardingActions } from "../../../../redux/onboarding/actions";
import { OnboardingCard } from '../../../../components/OnboardingCard';

const SCHEME = {
  name: (value: string) => value.length > 2,
  address: (value: string) => value.length > 2,
  email: (value: string) => value.length > 2,
  phone: (value: string) => value.length === 11,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CoperativeCompanyInfo: React.FC = () => {
                                                const dispatch = useDispatch();
                                                const route = useHistory();

                                                /**
                                                 * Redux store initiation region
                                                 */
                                                const reference = useSelector(
                                                  (state: {
                                                    onboardingStack: OnboardingInterface;
                                                  }) =>
                                                    state.onboardingStack.data
                                                      .reference as string
                                                );

                                                const businessInfo = useSelector(
                                                  (state: {
                                                    onboardingStack: OnboardingInterface;
                                                  }) =>
                                                    state.onboardingStack
                                                      .businessInfo as Record<
                                                      string,
                                                      string
                                                    >
                                                );

                                                /**
                                                 * Local state initiation region
                                                 */
                                                const [
                                                  validation,
                                                  setValidation,
                                                ] = useState<ValidateResult>();
                                                const [
                                                  name,
                                                  setName,
                                                ] = useState<string>(
                                                  () => businessInfo?.name ?? ''
                                                );
                                                const [
                                                  address,
                                                  setAddress,
                                                ] = useState<string>(
                                                  () =>
                                                    businessInfo?.address ?? ''
                                                );
                                                const [
                                                  email,
                                                  setEmail,
                                                ] = useState<string>(
                                                  () =>
                                                    businessInfo?.email ?? ''
                                                );
                                                const [
                                                  phone,
                                                  setPhone,
                                                ] = useState<string>(
                                                  () =>
                                                    businessInfo?.phone ?? ''
                                                );
                                                const [
                                                  passValidation,
                                                  setPassValidation,
                                                ] = useState<ValidateResult>();
                                                const [
                                                  validInProgress,
                                                  setValidInProgress,
                                                ] = useState(false);
                                                const [
                                                  loading,
                                                  setLoading,
                                                ] = useState(false);

                                                const [
                                                  businessNameValid,
                                                  setBusinessNameValid,
                                                ] = useState(false);
                                                /**
                                                 * useEffect region
                                                 */

                                                useEffect(() => {
                                                  setValidInProgress(true);
                                                  const timer = setTimeout(
                                                    () => {
                                                      setValidInProgress(false);
                                                      setValidation(
                                                        validateObject(
                                                          {
                                                            name,
                                                            address,
                                                            email,
                                                            phone,
                                                          },
                                                          SCHEME
                                                        )
                                                      );
                                                    },
                                                    500
                                                  );
                                                  return () =>
                                                    clearTimeout(timer);
                                                }, [
                                                  name,
                                                  address,
                                                  email,
                                                  phone,
                                                ]);

                                                const continueHandle = async () => {
                                                  setLoading(true);
                                                  try {
                                                    const response = await onboardingService.submitSignUpBusinessInfo(
                                                      {
                                                        businessInfo: {
                                                          name,
                                                          address,
                                                          email,
                                                          phone,
                                                        },
                                                        reference,
                                                      }
                                                    );
                                                    batch(() => {
                                                      dispatch(
                                                        OnboardingActions.handleSetBusinessInfo(
                                                          {
                                                            name,
                                                            email,
                                                            address,
                                                            phone,
                                                          }
                                                        )
                                                      );
                                                    });
                                                    route.push(
                                                      actions[
                                                        response.actionRequired
                                                      ]
                                                    );
                                                  } catch (error) {
                                                    const err = error as any;
                                                    if (
                                                      err.code ===
                                                      'INVALID_ACTION_REQUIRED'
                                                    ) {
                                                      route.push(
                                                        actions[
                                                          err.message
                                                            .trim()
                                                            .split(':')[1]
                                                            .replace(/\s/g, '')
                                                        ]
                                                      );
                                                    } else {
                                                      dispatch(
                                                        OnboardingActions.handleSetError(
                                                          err.message ||
                                                            'Can not complete request at the moment'
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setLoading(false);
                                                };

                                                return (
                                                  <S.Container>
                                                    <Title
                                                      color={colors.black._100}
                                                      title='Company Information'
                                                      subTitle='Tell us about your company by providing the following details.'
                                                    />
                                                    <S.Wrapper>
                                                      <OnboardingCard>
                                                        <S.ItemRow>
                                                          <div
                                                            style={{
                                                              flex: 1,
                                                            }}
                                                          >
                                                            <TextInput
                                                              label='Company name'
                                                              type='text'
                                                              value={name}
                                                              onChange={setName}
                                                              valid={
                                                                !businessNameValid &&
                                                                validation?.data
                                                                  .name.isValid
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            style={{
                                                              flex: 1,
                                                            }}
                                                          >
                                                            <TextInput
                                                              label='Company address'
                                                              type='text'
                                                              value={address}
                                                              onChange={
                                                                setAddress
                                                              }
                                                              valid={
                                                                validation?.data
                                                                  .address
                                                                  .isValid
                                                              }
                                                            />
                                                          </div>
                                                        </S.ItemRow>
                                                        <S.ItemRow>
                                                          <div
                                                            style={{
                                                              flex: 1,
                                                            }}
                                                          >
                                                            <TextInput
                                                              label='Email address'
                                                              type='text'
                                                              value={email}
                                                              onChange={
                                                                setEmail
                                                              }
                                                              valid={
                                                                validation?.data
                                                                  .email.isValid
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            style={{
                                                              flex: 1,
                                                            }}
                                                          >
                                                            <TextInput
                                                              label='Phone number'
                                                              type='tel'
                                                              placeholder='Enter Phone Number'
                                                              mask={createNumberMask(
                                                                numberMaskOptions
                                                              )}
                                                              value={phone}
                                                              valid={
                                                                validation?.data
                                                                  .phone.isValid
                                                              }
                                                              onChange={
                                                                setPhone
                                                              }
                                                            />
                                                          </div>
                                                        </S.ItemRow>

                                                        <S.ItemRow>
                                                          <div
                                                            style={{
                                                              flex: 1,
                                                            }}
                                                          >
                                                            <TextInput
                                                              label='Website '
                                                              type='text'
                                                              placeholder='Enter Website'
                                                              labelExtra=' (Optional)'
                                                            />
                                                          </div>
                                                        </S.ItemRow>
                                                        <S.ButtonGroup>
                                                          <div>
                                                            <Button
                                                              label='Continue'
                                                              loading={loading}
                                                              disabled={
                                                                !validation?.isValid ||
                                                                loading
                                                              }
                                                              onClick={
                                                                continueHandle
                                                              }
                                                            />
                                                          </div>
                                                        </S.ButtonGroup>
                                                      </OnboardingCard>
                                                    </S.Wrapper>
                                                  </S.Container>
                                                );
                                              };
export default CoperativeCompanyInfo;
