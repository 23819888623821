import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const Container = styled.div`
  > button {
    width: 100%
  }

  > button + span {
    display: block;
    margin-top: ${ptr(16)};

    > a {
      color: ${colors.pink._100}
    }
  }
`;

const Wrapper = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-bottom: ${ptr(32)};
  }
`;

export { Container, Wrapper };
