import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div``;

const Wrapper = styled.div`
  margin-top: ${ptr(32)};
  margin-bottom: ${ptr(8)};

  > :not(:last-child) {
    margin-bottom: ${ptr(12)};
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${ptr(40)};

  button {
    width: 100%;
  }
`;

const ErrorView = styled.span`
  color: red;
  font-size: 12px;
  margin-top: -30px;
  display: inline-block;
`;

export { Container, Wrapper, ButtonContainer, ErrorView };
