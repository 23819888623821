import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';

class OtpService {
  private apiEndpoints = {
    sendOtp: 'otp',
    verifyOtp: 'otp/verify',
  };

  public async sendOTP(accountNo: number) {
    try {
      const request = await apiService.request.post(this.apiEndpoints.sendOtp, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountNo: accountNo.toString(),
        }),
      });

      const result = await request.json();

      return result;
    } catch (globalError: any) {
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(result);
        }
        return Promise.reject(globalError);
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }
}

const otpService = new OtpService();
export default otpService;
