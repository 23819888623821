import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { LogoProps } from './index';

const Wrapper = styled.div<LogoProps>`
  ${(props) => props.marginLeft && css`
    margin-left: ${ptr(props.marginLeft)};
  `}
  ${(props) => props.marginTop && css`
    margin-top: ${ptr(props.marginTop)};
  `}
`;

const Img = styled.img`
  width: ${ptr(154)};
  height: ${ptr(130)};
`;

export {
  Wrapper,
  Img,
};
