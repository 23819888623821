import React from 'react';

import * as S from './styles';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';

export const NotFound: React.FC = () => (
  <S.Wrapper>
    <Text color={colors.pink._100} size={1} bold>404</Text>
    <Text color={colors.black._100} size={4}>Page not found</Text>
  </S.Wrapper>
);
