import styled from "styled-components";

import { ptr } from "../../../styles/utils";
import { breakpoints, colors } from "../../../styles/variables";

const Container = styled.div`
  margin-bottom: ${ptr(150)};
  > button {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(70.5)};

  > span {
    display: block;
    margin-bottom: ${ptr(32)};
  }
`;

const HelpText = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(12)};
`;

const File = styled.div`
  background-color: ${colors.black._40};
  border: ${ptr(1)} solid ${colors.black._5};
  box-sizing: border-box;
  border-radius: ${ptr(4)};
  width: ${ptr(104)};
  height: ${ptr(104)};
  margin-bottom: ${ptr(32)};
  background-image: url("../../images/documents/certificate.jpg");
  background-size: cover;
  position: relative;
`;

const FullScreen = styled.button`
  position: absolute;
  top: ${ptr(4)};
  right: ${ptr(4)};
  padding: 0;
  border: 0;
  background: transparent;
`;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};

  @media (max-width: ${breakpoints.md}px) {
    flex-wrap: wrap;
  }
`;

export { Container, Wrapper, HelpText, File, FullScreen, ItemRow };
