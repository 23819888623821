import React from 'react';

import { BorderType } from '../../styles/utils';
import * as S from './styles';

export interface TableCellProps {
  /**
   * Any children
   */
  children?: JSX.Element | string | number,
  /**
   * Cell width
   */
  tag?: 'td' | 'th';
  /**
   * Cell width
   */
  width?: number;
  /**
   * Header height
   */
  height?: number;
  /**
   * Text cell align
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Cell merging (combine)
   */
  colspan?: number;
  /**
   * Cell's padding
   */
  padding?: number | string;
  /**
   * Row border
   */
  border?: BorderType;
  /**
   * onClick cell handler
   */
  onClick?: () => void;
}

const TableCellComponent: React.FC<TableCellProps> = (props) => (
  <S.TableCell {...props} colspan={props.colspan}>
    {props.children}
  </S.TableCell>
);

export const TableCell = React.memo(TableCellComponent);
