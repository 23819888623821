import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';

// import { ptr } from '../../styles/utils';

// import { hexToRgbA, ptr } from '../../../../styles/utils';

const Container = styled.div`
  border-radius: 20px 20px 0 0;
`;

// payment
const PaymentWrapper = styled.div`
  background: #ffffff;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  @media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px) {
    padding: 2rem 10px;
  }
`;

const OtpWapper = styled.div`
  margin: 15px 0;
  @media (max-width: ${breakpoints.sm}px) {
    input:not(:first-child) {
      margin-left: 0.8rem !important;
      height: 4rem;
      width: 2rem;
    }
  }
`;

const BodyWrapper = styled.div`
  padding: 20px 3rem;
  display: flex;
  flex-direction: column;
  @media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px) {
    padding: 10px 0;
  }
`;

export { BodyWrapper, Container, PaymentWrapper, OtpWapper };
