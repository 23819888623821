import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as S from './styles';
import { colors } from '../../styles/variables';
import 'react-toastify/dist/ReactToastify.minimal.css';
import successSvg from './svg/success.svg';
import failedSvg from './svg/failed.svg';
import warningSvg from './svg/warning.svg';

export interface ToastProp {
  image?: string,
  title?: string,
  description?: string,
}

export const Toasts: React.FC<ToastProp> = ({ image, title, description }) => (
  <S.ToastWrapper>
    <S.Img src={image} />
    <div>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </div>
  </S.ToastWrapper>
);

export const success = (title: string, description: string): void => {
  toast.success(<Toasts image={successSvg} title={title} description={description} />, {
    position: 'bottom-left',
    autoClose: 3000,
    closeOnClick: false,
    style: {
      height: 112,
      width: 495,
      backgroundColor: colors.green._100,
    },
  });
};

export const failed = (title: string, description: string): void => {
  toast.error(<Toasts image={failedSvg} title={title} description={description} />, {
    position: 'bottom-left',
    autoClose: 3000,
    closeOnClick: false,
    style: {
      height: 112,
      width: 495,
      backgroundColor: colors.orange._90,
    },
  });
};
export const warning = (title: string, description: string): void => {
  toast.error(<Toasts image={warningSvg} title={title} description={description} />, {
    position: 'bottom-left',
    autoClose: 3000,
    closeOnClick: false,
    style: {
      height: 112,
      width: 495,
      backgroundColor: colors.amber._100,
    },
  });
};
