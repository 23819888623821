import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Wrapper = styled.div`
  padding: ${ptr(64)};
  box-sizing: border-box;
  width: 44.445vw;
  @media (max-width: ${breakpoints.sm}px) {
    width: inherit;
    padding: ${ptr(34)};
  }
  > span {
    display: block;
    margin-bottom: ${ptr(24)};
  }

  > button {
    width: 100%;
    margin-top: ${ptr(40)};
  }
`;

const InputRow = styled.div`
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-bottom: ${ptr(24)};
  }

  > div {
    &:not(:last-child) {
      margin-bottom: ${ptr(12)};
    }
  }
`;

export { Wrapper, InputRow };
