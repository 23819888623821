import React, { useState, useEffect } from 'react';

import * as S from './styles';
import { TextInput } from '../../../../components/TextInput';
import { validateObject, ValidateResult } from '../../../../utils/validation';
import { Button } from '../../../../components/Button';
import { FlexColumn, FormButton } from '../../../../styles/wrappers';
import { FormTitle } from '../../../../components/FormTitle';
import { CheckBox } from '../../../../components/CheckBox';
import userService from '../../../../services/internal/user/user.service';
// import { failed, success } from '../../../../components/Toasts';

export interface AccountNumberProps {
  /**
   * Add button press event
   */
  onAdd?: (accountInfo: any|null) => void,
}

const SCHEME = {
  terms: (value = false) => value,
  indemnity: (value = false) => value,
};

let verifyDelayTimerId: NodeJS.Timeout;

export const AccountNumber: React.FC<AccountNumberProps> = ({ ...props }) => {
  const [terms, setTerms] = useState(false);
  const [indemnity, setIndemnity] = useState(false);

  const [accountNumber, setAccountNumber] = useState({
    value: '',
    isValid: false,
    verifyLoading: false,
    accountInfo: null,
  });

  const [validation, setValidation] = useState<ValidateResult | undefined>(undefined);

  const verifyAccountNumber = async (value: string) => {
    try {
      if (value === '123123123') {
        setAccountNumber((old) => ({ ...old, isValid: true, verifyLoading: false }));
      } else {
        const result = await userService.getAccountInfo(value);
        setAccountNumber((old) => ({
          ...old, isValid: true, verifyLoading: false, accountInfo: result,
        }));
      }
    } catch (err) {
      setAccountNumber((old) => ({ ...old, isValid: false, verifyLoading: false }));
    }
  };

  const onChangeAccountNumber = (value: string) => {
    setAccountNumber((old) => ({ ...old, value, verifyLoading: true }));
    clearTimeout(verifyDelayTimerId);
    if (value?.length > 3) {
      verifyDelayTimerId = setTimeout(() => verifyAccountNumber(value), 1000);
    }
  };

  useEffect(() => {
    setValidation(validateObject({ terms, indemnity }, SCHEME));
  }, [terms, indemnity]);

  return (
    <FlexColumn>
      <FormTitle
        title="VFD MFB Account number"
        description="Enter your VFD MFB Account number to sign up."
      />
      <S.TextInputWrapper>
        <TextInput
          label="Corporate Account Number"
          valid={accountNumber.isValid}
          value={accountNumber.value}
          invalid={
            accountNumber.value
            && !accountNumber.verifyLoading
            && !accountNumber.isValid
              ? 'Not found'
              : ''
            }
          onChange={onChangeAccountNumber}
        />
      </S.TextInputWrapper>
      <S.CheckBoxesWrapper>
        <S.CheckBoxWrapper>
          <CheckBox
            theme="dark"
            text="I Agree with"
            link={{
              title: 'Terms & Conditions ',
              to: '/',
            }}
            onChange={(isChecked) => {
              setTerms(isChecked);
            }}
          />
        </S.CheckBoxWrapper>
        <S.CheckBoxWrapper>
          <CheckBox
            theme="dark"
            text="I Agree with"
            link={{
              title: 'Indemnity',
              to: '/',
            }}
            onChange={(isChecked) => {
              setIndemnity(isChecked);
            }}
          />
        </S.CheckBoxWrapper>
      </S.CheckBoxesWrapper>
      <FormButton.WrapperEnd>
        <Button
          label="Add"
          disabled={
            !validation?.isValid
            || !accountNumber.isValid
            || accountNumber.verifyLoading
          }
          onClick={() => {
            if (props.onAdd) {
              props.onAdd(accountNumber.accountInfo);
            }
          }}
        />
      </FormButton.WrapperEnd>
    </FlexColumn>
  );
};
