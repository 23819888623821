import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { IntraBankTransfer } from '../pages/IntraBankTransfer';
// import { TwoSideMenu } from '../styles/wrappers';
import { Header } from '../components/Header';
import { MainMenu } from '../components/MainMenu';
import { Analytics } from '../pages/Analytics';
import { AnalyticsNReport } from '../pages/AnalyticsNReport';
import { TransactionDetails } from '../pages/AnalyticsNReport/TransactionDetails';
import { BeneficiariesManagement } from '../pages/BeneficiariesManagement';
import { Dashboard } from '../pages/Dashboard';
import { NotFound } from '../pages/NotFound';
import { OtherBankTransfer } from '../pages/OtherBankTransfer';
import { SavedBeneficiariesTransfer } from '../pages/SavedBeneficiariesTransfer';
import { SelfTransfer } from '../pages/SelfTransfer';
import { SettingsAndSupport } from '../pages/SettingsAndSupport';
import LoginPassword from '../pages/SettingsAndSupport/LoginPassword';
import { CompanyDetails } from '../pages/SettingsAndSupport/companyDetails';
import TransactionPin from '../pages/SettingsAndSupport/transactionPin';
import ChangeTransactionPin from '../pages/SettingsAndSupport/transactionPin/changeTransactionPin';
import ResetTransactionPin from '../pages/SettingsAndSupport/transactionPin/resetTransactionPin';
import { TransferToOwnAccounts } from '../pages/TransferToOwnAccounts';
import { Transfers } from '../pages/Transfers';
import { UserManagement } from '../pages/UserManagement';
import { TwoSideMenu } from '../styles/menuWrapper';
import Requests from '../pages/SettingsAndSupport/Requests';
// import LoginPassword2 from '../pages/SettingsAndSupport/LoginPassword/step2';
import {
  batchDetailsRoute,
  changeTransactionPinSettingsRoute,
  companyDetailsSettingsRoute,
  editQuickLinksRoute,
  limit,
  loginPasswordSettingsRoute,
  passwordStep2SettingsRoute,
  paymentlistRoute,
  // passwordStep3SettingsRoute,
  resetTransactionPinSettingsRoute,
  settingsRoute,
  transactionDetailsRoute,
  transactionPinSettingsRoute,
  twoFactor,
} from '../navigation';
import LoginPassword3 from '../pages/SettingsAndSupport/LoginPassword/step3';
// import { Onboarding } from '../pages/Onboarding';
import DownloadStatement from '../components/DownloadStatement';
import { ModalWrapper } from '../components/ModalWrapper';
import { BatchPaymentView } from '../pages/AnalyticsNReport/BatchPaymentView';
import EditQuicklinks from '../pages/EditQuickLinks';
import { SubaccountAccountInfo } from '../pages/Onboard/subaccount.account.info';
import { CommonBoardResolution } from '../pages/Onboard/subaccount.board.resolution';
import { CommonConfirmDetails } from '../pages/Onboard/subaccount.confirm.details';
import CommonSubConsent from '../pages/Onboard/subaccount.sub.consent';
import { CommonSubPreviewDetails } from '../pages/Onboard/subaccount.summary';
import LimitRequest from '../pages/TransactionLimit/Request';
import TwoFactor from '../pages/TwoFactor';
import WelcomeNotApproved from '../pages/WelcomeNotApproved';
import CreateProfileWelcomeNotApproved from '../pages/WelcomeNotApprovedCreateProfile';
import { CompanyInterface } from '../redux/company/ICompany';
import { OnboardingInterface } from '../redux/onboarding/IOnboarding';
import { colors } from '../styles/variables';
import Onboard from './onboardingContainer';
import SetOperators from './setOperators';
import LimitUpload from '../pages/TransactionLimit/Upload';
import WhatNewModal from '../components/WhatsNew';

export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  fromAccountName: string;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
  actionRequired: string;
}

export enum RegistrationTypes {
  EXISTING_ACCOUNT = 'EXISTING_ACCOUNT',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
}
const PrivateRoutes: React.FC = () => {
  const location = useLocation();
  const onboarding = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack
  );

  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const [showNotApprovedModal, setshowNotApprovedModal] = useState<boolean>(
    true
  );

  const [showMenu, setShowMenu] = useState(true);
  const [showWhatsNew, setShowWhatsNew] = useState(false);

  useEffect(() => {
    if (location.pathname.includes(twoFactor)) {
      setShowMenu(false);
    } else {
      if (!showMenu) setShowMenu(true);
    }
  }, [location.pathname]);

  const closeNotApprovedModal = () => {
    setshowNotApprovedModal(false);
  };

  const toggleWhatsNew = () => setShowWhatsNew((prev) => !prev);

  useEffect(() => {
    if (activeCompany && activeCompany?.status === 'pending') {
      if (!showWhatsNew) toggleWhatsNew();
    }
  }, [activeCompany]);

  return (
    <>
      {activeCompany &&
      activeCompany?.status === 'pending' &&
      activeCompany.registrationType === RegistrationTypes.NEW_ACCOUNT ? (
        <ModalWrapper
          showModal={showNotApprovedModal}
          crossColor={colors.black._100}
          overflowY="scroll"
          overflowX="inherit"
          maxWidth="50%"
        >
          <WelcomeNotApproved
            currentCompany={activeCompany}
            closeModal={closeNotApprovedModal}
          />
        </ModalWrapper>
      ) : (
        activeCompany &&
        activeCompany?.status === 'pending' &&
        activeCompany.registrationType ===
          RegistrationTypes.EXISTING_ACCOUNT && (
          <ModalWrapper
            showModal={showNotApprovedModal}
            crossColor={colors.black._100}
            overflowY="scroll"
            overflowX="inherit"
            maxWidth="50%"
          >
            <CreateProfileWelcomeNotApproved
              currentCompany={activeCompany}
              closeModal={closeNotApprovedModal}
            />
          </ModalWrapper>
        )
      )}

      <WhatNewModal show={showWhatsNew} toggle={toggleWhatsNew} />

      <TwoSideMenu.Container>
        <TwoSideMenu.Wrapper>
          {/* <TwoSideMenu.Right> */}
          <TwoSideMenu.RightHeaderWrapper>
            <Header />
          </TwoSideMenu.RightHeaderWrapper>
          <TwoSideMenu.RightWrapper>
            {showMenu && <MainMenu />}

            <TwoSideMenu.RightContent showMenu={showMenu}>
              <Switch>
                <Route path="/transfers/other-bank">
                  <OtherBankTransfer />
                </Route>

                <Route path="/transfers/own-accounts">
                  <TransferToOwnAccounts />
                </Route>

                <Route path="/transfers/beneficiaries">
                  <BeneficiariesManagement />
                </Route>

                <Route path="/transfers/saved-beneficiaries">
                  <SavedBeneficiariesTransfer />
                </Route>

                <Route path="/transfers/intra-bank">
                  <IntraBankTransfer />
                </Route>

                <Route path="/transfers/bulk">
                  <SelfTransfer />
                </Route>

                <Route path="/transfers">
                  <Transfers />
                </Route>

                <Route path="/dashboard">
                  <Dashboard />
                </Route>

                <Route path="/dashboard/analytics">
                  <Analytics />
                </Route>

                <Route path={editQuickLinksRoute}>
                  <EditQuicklinks />
                </Route>
                {/* <Route path='/ussd-rebate'>
						<UssdRebate />
					</Route> */}
                <Route path={transactionDetailsRoute}>
                  <TransactionDetails />
                </Route>

                <Route path={batchDetailsRoute}>
                  <BatchPaymentView />
                </Route>

                <Route path={paymentlistRoute}>
                  <AnalyticsNReport />
                </Route>
                <Route path="/users">
                  <UserManagement />
                </Route>

                <Route exact path={settingsRoute}>
                  <SettingsAndSupport />
                </Route>
                <Route path={companyDetailsSettingsRoute}>
                  <CompanyDetails />
                </Route>
                {/* <Route path={usersSettingsRoute}>
<Users />
</Route> */}
                <Route exact path={transactionPinSettingsRoute}>
                  <TransactionPin />
                </Route>
                <Route path={changeTransactionPinSettingsRoute}>
                  <ChangeTransactionPin />
                </Route>
                <Route path={resetTransactionPinSettingsRoute}>
                  <ResetTransactionPin />
                </Route>
                <Route exact path={loginPasswordSettingsRoute}>
                  <LoginPassword />
                </Route>
                <Route path={passwordStep2SettingsRoute}>
                  <LoginPassword3 />
                </Route>

                {/* <Route path={passwordStep3SettingsRoute}>
<LoginPassword3 />
</Route> */}
                {onboarding.inApp ? (
                  <div>
                    <Route path="/onboarding">
                      <Onboard />
                    </Route>

                    <Route path="/subaccount">
                      <Route path="/subaccount/confirm-details" exact>
                        <CommonConfirmDetails />
                      </Route>

                      <Route path="/subaccount/account-information" exact>
                        <SubaccountAccountInfo />
                      </Route>
                      {/* <Route path='/sub/funds-flow' exact>
                        <CommonFundsFlow />
                      </Route> */}
                      <Route path="/subaccount/board-resolution" exact>
                        <CommonBoardResolution />
                      </Route>
                      {/* <Route path='/sub/select-workflow' exact>
                        <CommonSelectWorkflow />
                      </Route> */}
                      <Route path="/subaccount/subconsent-form" exact>
                        <CommonSubConsent />
                      </Route>
                      {/* <Route path='/sub/approval-limit' exact>
                        <CommonSubApprovalLimit />
                      </Route> */}
                      <Route path="/subaccount/preview-details" exact>
                        <CommonSubPreviewDetails />
                      </Route>

                      <Redirect
                        from="**"
                        to="/subaccount/confirm-details"
                        exact
                      />
                    </Route>
                  </div>
                ) : (
                  <Redirect from="/onboarding" to="/dashboard" exact />
                )}
                <Route path="/set-operators">
                  <SetOperators />
                </Route>
                <Route path="/statement">
                  <DownloadStatement />
                </Route>
                <Route path={twoFactor}>
                  <TwoFactor />
                </Route>
                <Route path={`${limit}/request`}>
                  <LimitRequest />
                </Route>
                <Route path={`${limit}/upload`}>
                  <LimitUpload />
                </Route>
                <Route path="/settings/requests">
                  <Requests />
                </Route>
                <Redirect from="/login" to="/dashboard" />
                <Redirect from="/" to="/dashboard" exact />

                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </TwoSideMenu.RightContent>
            {/*  */}
          </TwoSideMenu.RightWrapper>
          {/* </TwoSideMenu.Right> */}
        </TwoSideMenu.Wrapper>
      </TwoSideMenu.Container>
    </>
  );
};
export default PrivateRoutes;
