import { BusinessTypesEnum } from "../pages/Onboard/constants";

export type Action = string | undefined;

export type Details = {
  title: string;
  color: string; 
  info: Array<string>;
  list: Array<{details:string , extras?:string}>; 
};

const sole_proprietorship = {
  title: "SOLE PROPRIETOR",
  info: [
    "The business is owned by one person who will oversee the account operations.",
  ],
  color:'#EEF7FF',
  
  list: [
    {
      details: 'Proprietor’s details',
      extras:'(BVN, valid ID number, phone number and email)',
    },
    {
      details: 'Copy of proprietor\'s govt issued ID card' 
    },
    {
      details:'CAC Number.' 
    },
    {
      details:'CAC documents',
      extras: ' (Certificate of registration and other registration documents).'
    },
    {
      details:'TIN',
      extras:'(Tax Identification Number)'
    },
    {
      details: 'Proof of company’s address ',
      extras:'(not more than 3months old).'
    },
    {
      details: 'Two independent current or corporate references from any bank' 
    }, 
    {
      details: 'SCUML Certificate',
      extras: ' (depending on the nature of business)'
    }
    
  ],
};

const partnership = {
  title: "Partnership",
  color:'#FFF9EF',
  info: [
    "The business is co-owned by partners who will jointly manage the account.",
  ],

  list: [
    {
      details: 'Partners’ details',
      extras: '(BVN, ID number, phone number and email address).',
    },
    {
      details: 'Copy of ID card of all Partners' 
    },
    {
      details:'CAC Number.' 
    },
    {
      details:'CAC documents', 
      extras: '(Certificate of registration/incorporation and other registration/incorporation documents).'
    },
    {
      details: 'Partnership deed or agreement', 
    },
    {
      details:'TIN',
      extras:'(Tax Identification Number)'
    },
    {
      details: 'Proof of company’s address',
      extras:'(not more than 3months old).'
    },
    {
      details: 'Two independent current or corporate references from any bank'
    },

    {
      details: 'SCUML Certificate' 
    }, 
   
  ],
  
};

const limited_liability = {
  title: "Limited Liability Company",
  color:'#FFF3F8',
  info: [
    "The business entity is owned by shareholders and overseen by a board.",
  ],

  list: [
    {
      details: 'Directors’ details ',
      extras: '(ID number, phone number and email address).',
    },
    {
      details: 'Copy of govt issued ID card of all Directors.' 
    },
    {
      details: 'Board Resolution on company’s letterhead.' 
    },
    {
      details: 'CAC documents ((Certificate of Incorporation, Statement of share capital (CAC 2a), Particulars of directors (CAC 7a), Particulars of secretary and Memorandum and Articles of Association, MEMART.',
    },
  
    {
      details:'TIN',
      extras:'(Tax Identification Number)'
    },
    {
      details: 'Proof of company’s address',
      extras:'(not more than 3months old).'
    },
    {
      details: 'Two independent current or corporate references from any bank' 
    }, 
    {
      details: 'SCUML – depending on nature of business '
    }, 
  ],
 
};

const co_operative_society = {
  title: "Associations, Clubs and Societies",
  color:'#EFEEFB',
  info: [
    "We pursue a common interest, put funds together to achieve the objectives of the entity.",
  ],


  list: [
    {
      details: 'Principal Officers’ details ',
      extras: '(BVN, ID number, phone, and email)',
    },
    {
      details: 'Copy of Principal Officers’ valid ID card.' 
    },
    {
      details: 'Minute of the meeting for account opening.' 
    },
    {
      details: 'Proof of registration', 
    },
    {
      details: 'Rules/Constitution of the society', 
    }, 
    {
      details: 'Proof of company’s address ',
      extras:'(not more than 3months old).'
    }, 
    {
      details: 'Two independent current or corporate references from any bank.'
    }
   
  ],
 
};

const trustee_ngo = {
  title: "Trustee/NGO",
  color:'#EFF0F6',
  info: [
    "The business is acting as a guardian holding authority and responsible for managing the purpose of the trust",
  ],

  list: [
    {
      details: 'Trustees’ details',
      extras: '(BVN, Valid ID, phone number and email address)',
    },
    {
      details: 'Copy of govt issues ID card of all Trustees.' 
    },
    {
      details: 'Board of trustee’s resolution for account opening.' 
    },
    {
      details: 'Certified true copy of constitution/Trustee deed.',
    },
  
    {
      details: 'CAC Documents',
      extras: '(Certificate of Incorporation and other incorporation documents, secretary’s details).'
      
    },

    {
      details: 'CAC Number',
      
    },

    {
      details: 'TIN',
      extras: '(Tax Identification Number).'
    },
    {
      details: 'Proof of company’s address',
      extras: '(Utility bill).'
    }, 
    {
      details: 'Two independent current or corporate references from any bank.'
    },
    {
      details: 'SCUML Certificate'
    }
   
  ],
 
};

export const getTypeOfBusinessModalDetails = (action: Action): Details | null => {
  switch (action?.toLocaleLowerCase()) {
    case BusinessTypesEnum.soleProprietorship:
      return sole_proprietorship;
    case BusinessTypesEnum.partnership:
      return partnership;
    case BusinessTypesEnum.limitedLiability:
      return limited_liability;
    case BusinessTypesEnum.cooperativeSociety:
      return co_operative_society;
    case BusinessTypesEnum.trusteeNgo:
      return trustee_ngo;
    default:
      return null;
  }
};
