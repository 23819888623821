import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { breakpoints } from '../../../styles/variables';

const Container = styled.div`
  width: 50%;
    @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

const OTPContainer = styled.div`
width: ${ptr(410)}; 
    @media (max-width: ${breakpoints.xl}px) {
    width: ${ptr(350)};
  };

`;

const TextContainer = styled.div`
margin-top: ${ptr(10)};
`;

const Body = styled.div`
margin-top: ${ptr(50)};
 display: flex;
  flex-direction: column;
  gap:20px;
`;

const InputContainer = styled.div`
  margin-bottom: ${ptr(30)};
  /* width:335px; */
`;

const OtpWrapper = styled.div`
 margin-top: ${ptr(15)};
  @media (max-width: ${breakpoints.sm}px) {
    width:unset
  };
`;

// const ButtonWrapper = styled.div`
// width: inherit;
// `;
export {
  Container,
  OTPContainer,
  TextContainer,
  Body,
  InputContainer,
  OtpWrapper,
  // ButtonWrapper,
};
