import React, { MouseEvent, useEffect, useState } from 'react';
import * as S from './styles';
import { PageTitle } from '../../styles/wrappers';
import { BackBar } from '../../components/BackBar';
import { Text } from '../../components/Text';
import { fonts } from '../../styles/variables';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import twoFactorImage from '../../assets/images/2fa_Img.svg';
import ActivateTwoFactorModal from './ActivateTwoFactor';
import { failed, success } from '../../components/Toasts';
import authService from '../../services/internal/auth/auth.service';
import PinComponent from '../../components/PinComponent';
import { IInit2faResponse } from '../../services/internal/auth/interface';

const TwoFactor = () => {
  const { fromRoute } = useLocation().state as { fromRoute?: string };

  const [showActivateTwoFactor, setShowActivateTwoFactor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [tokenKey, setTokenKey] = useState('');

  const togglePin = () => setShowPin((prev) => !prev);

  const toggleActivateTwoFactor = () =>
    setShowActivateTwoFactor((prev) => !prev);
  const list = [
    'Fund transfers',
    'Beneficiary management (creation, update, and deletion)',
    'User management (creation and update)',
    'Account password reset',
    'Transaction PIN reset',
    'Profile information update',
  ];

  const gotoAuthenticator = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    window.open(
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en',
      '_blank'
    );
  };

  const gotoAuthenticatorAppStore = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    window.open(
      'https://apps.apple.com/us/app/google-authenticator/id388497605',
      '_blank'
    );
  };

  const initiate2fa = async (pin: string) => {
    setLoading(true);
    try {
      if (!pin) {
        failed('error', 'Pin is required');
        return;
      }
      const result = (await authService.initiateTwoFactorSetup(
        pin
      )) as IInit2faResponse;
      setQrCode(result.generateQR);
      setTokenKey(result.key);
      togglePin();
      toggleActivateTwoFactor();
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'An error occurred');
    }
    setLoading(false);
  };

  return (
    <S.Container>
      <S.TitleWrapper>
        <PageTitle.Wrapper>
          <BackBar name="Two Factor Authentication" />
        </PageTitle.Wrapper>
      </S.TitleWrapper>

      <S.SectionContent>
        <S.FirstContent>
          <Text size={4} bold color="#2C2A3B">
            To enhance the security of your account, multifactor authentication
            will now be required for the following actions:
          </Text>
          <ul style={{ fontFamily: fonts.inter.regular, listStyle: 'unset' }}>
            {list.map((li, index) => (
              <li key={index}>{li}</li>
            ))}
          </ul>

          <Text size={4}>
            First, download Google Authenticator from the{' '}
            <Link onClick={gotoAuthenticator} to="">
              {' '}
              Google Play Store
            </Link>{' '}
            or{' '}
            <Link to="" onClick={gotoAuthenticatorAppStore}>
              the iOS App Store.
            </Link>
          </Text>

          <S.ButtonContainer>
            <Button label="Set up Authenticator" onClick={togglePin} />
          </S.ButtonContainer>
        </S.FirstContent>

        <S.SectionTwo>
          <img src={twoFactorImage} alt="2 factor" />
        </S.SectionTwo>
      </S.SectionContent>

      {qrCode && tokenKey && (
        <ActivateTwoFactorModal
          show={showActivateTwoFactor}
          toggle={toggleActivateTwoFactor}
          qrCode={qrCode}
          tokenKey={tokenKey}
          fromRoute={fromRoute}
        />
      )}

      <PinComponent
        loading={loading}
        onSubmit={initiate2fa}
        show={showPin}
        toggle={togglePin}
      />
    </S.Container>
  );
};

export default TwoFactor;
