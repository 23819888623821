import styled, { css } from 'styled-components';
import {
  crossBrowserTransition,
  ptr,
} from '../../../../styles/utils';
import { breakpoints, colors, transition } from '../../../../styles/variables';
import { ItemProps } from '.';

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: left;
  background: ${colors.black._2};
  border: ${ptr(1)} solid ${colors.black._10};

  ${crossBrowserTransition(transition.slow)}

  border-radius: ${ptr(8)};
  color: ${colors.white._100};
`;

const ItemWrapper = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
`;
const ItemContainer = styled.div`
margin-right: ${ptr(10)};
padding:${ptr(30)} 0;
`;

const SpanSpace = styled.div`
margin:0 ${ptr(1)};
`;
const BorderStyle = styled.span`
  width:${ptr(2)};
  background:${colors.black._10};
`;
const Wrapper = styled.aside`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  text-align: left;
  padding: ${ptr(32)}  ${ptr(32)} 0  ${ptr(32)};

  ${crossBrowserTransition(transition.slow)}
`;

const BottomContainer = styled.div<{ open: boolean }>`
  // border-radius: ${ptr(8)};
  border-top: solid 1px #E9EBF1;
  /* padding: ${ptr(32)} 0; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${ptr(32)};

  > div {
    /* flex: 1; */
  }

  ${(props) => (props.open && css`
    svg {
      display: none;
    }
  `)}
`;

const ItemBox = styled.div`
  display: flex;
`;

const Icon = styled.div<Pick <ItemProps, 'income'>>`
  background: ${colors.black._5};
  border-radius: ${ptr(6)};
  min-width: ${ptr(48)};
  min-height: ${ptr(48)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${ptr(16)};
  height: 30px;

  ${(props) => props.income && css`
    & > svg {
      transform: rotate(270deg);
    }
  `}
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Data = styled.aside`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  > :first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${ptr(176)};
  }

  > :last-child {
    display: flex;
    /* margin-left: ${ptr(16)}; */
    color: ${colors.green._100};
  }
  /* span{
    margin:0;
    padding:0
  } */
`;

const Content = styled.div`
  display: flex;

  @media(max-width: ${ptr(breakpoints.xxl)}) {
    position: relative;
    flex-direction: column;
  }
`;

const Company = styled.div`
  flex: 1;
`;

const Balance = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${ptr(4)};

  > svg {
    margin-left: ${ptr(18)};
    transform: rotate(${(props) => (props.open ? css`+` : css`-`)}90deg);
    ${crossBrowserTransition(transition.slow)}
  }
`;

const AccountItemBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-top:15px;
  margin-bottom: ${ptr(8)};
  border: '1px solid #000';
`;

const Account = styled.div<{ open: boolean }>`
  padding-bottom: ${ptr(16)};

  ${(props) => (props.open ? css`
    border-bottom: ${ptr(0)} solid ${colors.black._10};
  ` : css`
    border-bottom: ${ptr(1)} solid ${colors.black._10};
  `)}
`;

const Calendar = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  /* margin-top: ${ptr(16)};
  margin-bottom: ${ptr(24)}; */

  ${(props) => (props.open && css`
    svg {
      display: none;
    }
  `)}

  > * {
    margin-right: ${ptr(12)};
  }

  @supports (gap: ${ptr(12)}) {
    /* gap: ${ptr(12)}; */

    > * {
      margin-right: 0;
    }
  }
`;

const AddNew = styled.div`
  flex: 1;
  @media screen and (min-width: ${breakpoints.md}px) {
    position: absolute;
    right: 0;
    }  

  > button {
    margin-left: auto;
    /* margin-left: 0; */
  }
`;

const AddNew2 = styled.div`
  flex: 1;
  @media screen and (min-width: ${breakpoints.md}px) {
    position: absolute;
    right: 250px;
        > button {
        margin-left: auto;
        margin-top: 0px!important;
        /* margin-left: 0; */
      }
    }  

  > button {
    margin-left: auto;
    margin-top: 10px;
    /* margin-left: 0; */
  }
`;

const YearBadge = styled.button`
  border: ${ptr(1)} solid ${colors.black._40};
  box-sizing: border-box;
  border-radius: ${ptr(50)};
  display: flex;
  align-items: center;
  padding: ${ptr(8)} ${ptr(16)};
  background: transparent;

  > span {
    line-height: ${ptr(14)};
  }

  > svg {
    margin-left: ${ptr(6)};
    transform: rotate(-90deg);
  }
`;

const SubYearBadge = styled.button`
  border: ${ptr(1)} solid ${colors.black._40};
  box-sizing: border-box;
  border-radius: ${ptr(50)};
  display: flex;
  align-items: center;
  padding: ${ptr(8)} ${ptr(16)};
  background: transparent;

  > span {
    line-height: ${ptr(14)};
  }

  > svg {
    :first-child {
      margin-right: ${ptr(6)};
    }

    :last-child {
      margin-left: ${ptr(6)};
      transform: rotate(-180deg);
    }
  }
`;

const DropownContent = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? css`block` : css`none`)};
  position: absolute;
  width: 90%;
  z-index: 9999;
  max-height: ${ptr(110)};
  overflow: auto;
  min-height: ${ptr(100)};
  background: ${colors.white._100};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  padding: 0 ${ptr(32)};
   @media (max-width: ${breakpoints.sm}px) {
    width: 85%;
  }

  ${(props) => (props.open ? css`
    border-top: ${ptr(1)} solid ${colors.black._10};
  ` : css`
    border-top: ${ptr(0)} solid ${colors.black._10};
  `)}

  ${crossBrowserTransition(transition.slow)}
`;

const Circle = styled.div<{ checked: boolean, theme: 'dark' | 'light' }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${ptr(22)};
  height: ${ptr(22)};
  border-radius: ${ptr(12)};

  ${crossBrowserTransition(`${transition.slow}`)}
  
   ${(props) => css`
    ${props.theme === 'dark' && `border: ${ptr(1)} ${colors.black._40} solid;`}
    ${props.theme === 'light' && `border: ${ptr(1)} ${colors.white._100} solid;`}
  `}
  
  ${(props) => props.checked && css`
    ${props.theme === 'dark' && `border: ${ptr(1)} ${colors.pink._100} solid;`}
    ${props.theme === 'light' && `border: ${ptr(1)} ${colors.white._100} solid;`}
  `}
`;

const InsideCircle = styled.div<{ theme: 'dark' | 'light' }>`
  width: ${ptr(17)};
  height: ${ptr(17)};
  border-radius: ${ptr(9)};
  justify-content: center;
  align-items: center;

  ${(props) => css`
    ${props.theme === 'dark' && `background-color: ${colors.pink._100};`}
    ${props.theme === 'light' && `background-color: ${colors.white._100};`}
  `}
`;

const CircleBlock = styled.div`
  justify-content: center;
  align-items: center;
`;

const AccountItemInfoBlock = styled.div`
  flex: 2;
  padding: 0 ${ptr(8)};
`;

const AccountItemBalanceBlock = styled.div`
  flex: 1;
  text-align: right;
  margin-right: 25px;
`;

export {
  ItemWrapper,

  Wrapper,
  Container,

  Content,
  Company,
  Balance,
  Account,
  Calendar,
  AddNew,

  BottomContainer,
  ItemBox,
  Icon,
  Article,
  Data,
  YearBadge,
  SubYearBadge,

  DropownContent,
  AccountItemBox,
  Circle,
  InsideCircle,
  CircleBlock,
  AccountItemInfoBlock,
  AccountItemBalanceBlock,

  // new
  ItemContainer,
  BorderStyle,
  SpanSpace,
  AddNew2,
};
