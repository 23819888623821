import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px; 
  padding: ${ptr(10.5)} ${ptr(54)} ${ptr(10.5)} ${ptr(62)};
 position:sticky;
  box-sizing: border-box;
  background-color: ${colors.white._100};
  z-index:999999; 
`;

const LeftSideWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoWrapper = styled.div`
  display: block;


`;

const MiniProfileWrapper = styled.div`
  display: flex;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: none;
  }
`;

const MenuButtonWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: flex;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
`;

export interface TabProps {
  active?: boolean,
}

const TabWrapper = styled.div<TabProps>`
  margin-right: ${ptr(48)};
  cursor: pointer;
  color: ${colors.black._40};

  :hover {
    color: ${colors.pink._100};
  }
  ${(props) => props.active && css`
    color: ${colors.pink._100};
  `}

`;

export {
  Wrapper,
  LeftSideWrapper,
  RightSideWrapper,
  LogoWrapper,
  MiniProfileWrapper,
  MenuButtonWrapper,
  TabsWrapper,
  TabWrapper,
};
