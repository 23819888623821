/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';

import { validateObject, ValidateResult } from '../../../utils/validation';

import * as S from './styles';
import { colors } from '../../../styles/variables';
import { actions } from '../config';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { OnboardingFormHeader } from '../../../components/OnboardingFormHeader';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { success } from '../../../components/Toasts';
import { OnboardingOtpModal } from '../../../components/OnboardingOtpModal';

const SCHEME = {
  bvn: (value: string) => value.length === 11,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const BvnConsent: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */

  const maskedEmail = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.maskedEmail as string
  );

  const maskedPhone = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.maskedPhone as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  /**
   * Local state initiation region
   */
  const [bvn, setBvn] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [
    accountValidation,
    setAccountValidation,
  ] = useState<ValidateResult | null>(null);
  const [actionRequired, setActionRequired] = useState<string>();
  const [bvnVerified, setBvnVerified] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  const timer = useRef<NodeJS.Timeout>();

  /**
   * Custom handlers initiation region
   */

  /**
   * useEffect region
   */
  useEffect(() => {
    setAccountValidation(
      validateObject(
        {
          bvn,
        },
        SCHEME
      )
    );
  }, [bvn]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const confirmBvn = async () => {
    try {
      setLoading(true);
      const response = await onboardingService.confirmBvnConsent({
        bvn: bvn,
        reference: reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            reference: response.data.reference,
            bvn: response.data.bvn,
          })
        );
      });
      success('BVN Verified', 'Successful');
      if (response.actionRequired === 'SUBMIT_OTP') {
        toggleOtp();

        return
      }
    route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      dispatch(
        OnboardingActions.handleSetError(
          err.message || 'Can not complete request at the moment'
        )
      );
    }
    setLoading(false);
  };

  const verifyBvn = async () => {
    try {
      setLoading(true);
      const response = await onboardingService.bvnConsent({
        bvn: bvn,
        reference: reference,
      });
      setLoading(true);
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            reference: response.data.reference,
            url: response.data.url,
          })
        );
      });
      success('Successful', 'You are being redirected to verify your BVN');
      setLoading(false);
      setBvnVerified(true);
      if (response.data.url) {
        timer.current = setTimeout(() => {
          openInNewTab(response.data.url);
        }, 3000);

        return;
      }
      if (response.actionRequired) route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      dispatch(
        OnboardingActions.handleSetError(
          err.message || 'Can not complete request at the moment'
        )
      );
    }
    setLoading(false);
  };

  const toggleOtp = () => {
    setShowOtp((prev) => !prev);
  };

  const next = () => {
    if (actionRequired) route.push(actions[actionRequired]);
  };

  return (
    <>
      <S.Container>
        <OnboardingFormHeader
          // description='Provide your company’s details'
          descriptionColor='#6E7191'
          title='Bvn verification'
          titleColor={colors.black._20}
        />
        <OnboardingCard>
          <S.Wrapper>
            <TextInput
              type='currency'
              mask={createNumberMask(numberMaskOptions)}
              label='Enter BVN'
              value={bvn}
              onChange={setBvn}
              disabled={loading}
            />
          </S.Wrapper>

          <S.ButtonContainer>
            <Button
              label='Continue'
              // size='small'
              style={{ height: 40 }}
              loading={loading}
              onClick={() => (bvnVerified ? confirmBvn() : verifyBvn())}
              disabled={!accountValidation?.isValid || loading}
            />
          </S.ButtonContainer>
        </OnboardingCard>
        <OnboardingOtpModal
        showModal={showOtp}
        data={{ email:maskedEmail, phoneNumber:maskedPhone }}
        onCloseModal={toggleOtp}
      />
      </S.Container>
    </>
  );
};
export default BvnConsent;
