import { ActionInterface } from "../IAction";
import {
  SubaccountInterface,
  SubaccountType,
} from "./ISubaccount";

import {
  SET_SUBACCOUNT_REQUEST,
  CLEAR_SUBACCOUNT_REQUEST
} from "./actions";

const key = 'SUBACOUNT_REQUEST'
let request: any = localStorage.getItem(key)
if (request) {
  request = JSON.parse(request)
}

const initialState: SubaccountInterface = {
  subaccountRequest: request,

};

const reducer = (
  state = initialState,
  action: ActionInterface<
    | SubaccountType
  >
): SubaccountInterface => {
  switch (action.type) {
    case SET_SUBACCOUNT_REQUEST: {
      localStorage.setItem(key, JSON.stringify(action.payload))
      return {
        ...state,
        subaccountRequest: action.payload,
      };
    }
    case CLEAR_SUBACCOUNT_REQUEST: {
      localStorage.removeItem(key)
      return {
        ...state,
        subaccountRequest: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
