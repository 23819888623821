import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';

import * as S from './styles';
import { TextInput } from '../../../../components/TextInput';
import { DropdownInput } from '../../../../components/DropdownInput';

export interface SimpleFormProps {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  index?: number;
  removeFromIndex?: number;
  emailIsValid?: boolean;
  remove?: (index: number) => void;
  onChange?: (index: number, property: string, value: string) => void;
  title?: string;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const InfoForm: React.FC<SimpleFormProps> = ({
  firstName,
  lastName,
  phone,
  email,
  index,
  removeFromIndex = 0,
  emailIsValid,
  title,
  remove = () => {},
  onChange = () => {},
}) => (
  <S.ItemContainer>
    <S.HeaderRow>
      <Text bold color={colors.black._100}>
        {title}
      </Text>
      {index && index > removeFromIndex ? (
        <S.Remove onClick={() => remove(index)}>
          <Icon name='trash' color={colors.pink._100} />
        </S.Remove>
      ) : null}
    </S.HeaderRow>

    <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
      <div style={{ flex: 1 }}>
        <TextInput
          value={firstName}
          label='First name'
          type='text'
          placeholder='Enter BVN'
          onChange={(value) => onChange(index as number, 'firstName', value)}
        />
      </div>

      <div style={{ flex: 1 }}>
        <TextInput
          label='Phone number'
          placeholder='Enter phone number'
          type='number'
          mask={createNumberMask(numberMaskOptions)}
          value={phone}
          onChange={(value) => onChange(index as number, 'phone', value)}
        />
      </div>
    </S.ItemRow>

    <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
      <div style={{ flex: 1 }}>
        <DropdownInput
          label='Valid ID Type'
          placeholder='Select ID type'
          // options={businessTypes.map((business) => ({
          //   label: business.name,
          //   value: business.value,
          // }))}
          // onSelect={setBusinessType}
        />
      </div>

      <div style={{ flex: 1 }}>
        <TextInput
          label='ID Number'
          type='text'
          value={lastName}
          // value={idNumber}
          // onChange={setIdNumber}
          placeholder='Enter ID number'
          onChange={(value) => onChange(index as number, 'lastName', value)}
          // valid={validation?.data.email.isValid}
        />
      </div>
    </S.ItemRow>

    <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
      <div style={{ flex: 1 }}>
        <TextInput
          label='Email'
          value={email}
          placeholder='Enter email address'
          onChange={(value) => onChange(index as number, 'email', value)}
          valid={emailIsValid}
        />
      </div>
    </S.ItemRow>
  </S.ItemContainer>
);
