import styled from 'styled-components';
import { ptr } from '../../../../styles/utils';

// import { UnregisteredBusinessProps } from '.';
// import { colors } from '../../styles/variables';

const InputWrapper = styled.div`
  margin-bottom: ${ptr(40)};
`;

export {
  InputWrapper,
};
