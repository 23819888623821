import React, { useEffect } from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";

import { colors } from "../../styles/variables";
import { Icon } from "../Icon";
import * as S from "../OnBoardModal/styles";
import { Details } from "../../utils/businessTypeModalDetails";
import { Text } from "../Text";

export interface UseDetailsModalProps {
  onCloseModal?: () => void;
  /**
   * Show modal
   */
  showModal?: boolean;
  crossColor?: string;
  overflowY?: string;
  overflowX?: string;
  margin?: string;
  maxHeight?: string | null;
  maxWidth?: string | null;
  onBottom?: () => void;
  closeBackground?: string;
  details?: Details | null;
  continueWithDetails: () => void;
  continueWithoutDetails: () => void;
}

export const UseDetailsModal: React.FC<UseDetailsModalProps> = ({
  showModal = false,
  crossColor = colors.black._100,
  overflowY = "auto",
  overflowX = "auto",
  margin = "0.2rem 0rem",
  maxHeight = null,
  maxWidth = "90%",
  onBottom = () => {},
  ...props
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  return (
    <BottomScrollListener onBottom={onBottom}>
      {(scrollRef: any) => (
        <S.Container showModal={showModal}>
          <S.Wrapper
            overflowY={overflowY}
            overflowX={overflowX}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            ref={scrollRef}
          >
            <S.Header>
              <S.HeaderTitle>
                {props.details && props.details.title}
              </S.HeaderTitle>
              {props.onCloseModal && (
                <S.CrossWrapper background={props.closeBackground}>
                  <Icon
                    width={14}
                    height={14}
                    name="cross"
                    color={crossColor}
                    onClick={props.onCloseModal}
                  />
                </S.CrossWrapper>
              )}
            </S.Header>
            <S.ListWrapper>
             <div style={{marginBottom:'0.5rem'}}>
             <Text size={4} color={colors.pink._100} bold>
                BVN and Valid ID
              </Text>
             </div>
              <br />
              <Text size={6}>Do you want to use same BVN and ID provided earlier?</Text>
            </S.ListWrapper>
            <S.ButtonsWrapper>
              <S.Button
                background={colors.black._40}
                hover={colors.black._60}
                onClick={props.continueWithoutDetails}
              >
                NO
              </S.Button>
              <S.Button
                background={colors.pink._100}
                hover={colors.pink._80}
                onClick={props.continueWithDetails}
              >
                YES
              </S.Button>
            </S.ButtonsWrapper>
            {props.children}
          </S.Wrapper>
        </S.Container>
      )}
    </BottomScrollListener>
  );
};
