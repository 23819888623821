/* eslint-disable max-len */
import * as XLSX from 'xlsx';
import paymentsService from '../payments/payments.service';

export const errorToString = (error: Error | unknown):string => {
  if (error instanceof Error) {
    return error.message;
  }
  return '';
};

export const getExtFromMime = (mime: string, prefixDot?: boolean): string => {
  const slashIndex = mime.lastIndexOf('/');
  if (slashIndex >= 0) {
    return prefixDot ? `.${mime.substring(slashIndex + 1)}` : mime.substring(slashIndex + 1);
  }
  return '';
};

export const stringToCamelCase = (gapCase: string): string => {
  let data = '';
  let hasSpace = false;
  Array.from(gapCase).forEach((e) => {
    if (e === ' ') {
      hasSpace = true;
    } else if (hasSpace) {
      data += e.toUpperCase();
      hasSpace = false;
    } else {
      data += e;
    }
  });
  return data.replace(data.charAt(0), data.charAt(0).toLocaleLowerCase());
};
export const csvToJSON = (csv: string, rowDelimeter: string, columnsDelimeter: string): Record<string, string>[] => {
  const rows: string[] = csv.split(rowDelimeter);

  const header: string | undefined = rows.shift();

  if (typeof header === 'string') {
    const tempHeaders: string[] = header.split(columnsDelimeter);
    const headers: string[] = [];
    tempHeaders.reduce((mHeader: string[], item) => {
      mHeader.push(stringToCamelCase(item));
      return mHeader;
    }, headers);

    return rows.map((row) => {
      const columns: string[] = row.split(columnsDelimeter);

      const json: { [key: string]: string } = {};

      for (let i = 0; i < columns.length; i += 1) {
        json[headers[i]] = columns[i];
      }
      return json;
    });
  }
  return [];
};

export const readFile = (file: File, resultType: 'blob'|'arraybuffer'|'binary'|'text'): Promise<string | ArrayBuffer> => new Promise((resolve, reject) => {
  try {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result) {
        resolve(result);
      } else {
        throw new Error(`Result is ${result}. Check file or responseType.`);
      }
    };
    switch (resultType) {
      case 'blob':
        reader.readAsDataURL(file);
        break;
      case 'binary':
        reader.readAsBinaryString(file);
        break;
      case 'arraybuffer':
        reader.readAsArrayBuffer(file);
        break;
      default:
        reader.readAsText(file);
        break;
    }
  } catch (e) {
    reject(e);
  }
});

export const sheetToBulkJsonArray = (file: File, callback?: (jsonArray: Record<string, string>[]) => void): Promise<Record<string, string>[]> => {
  const rowDelimeter = '^^^';
  const columnDelimeter = '%%%';

  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const buffer = e.target?.result;

        const workbook = XLSX.read(buffer, { type: 'binary' });
        const sheetOption = {
          skipHidden: true, strip: true, RS: rowDelimeter, FS: columnDelimeter,
        };
        let data = csvToJSON(XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]], sheetOption), rowDelimeter, columnDelimeter);
        data = data.map((dat) => ({ ...dat, narration: !dat.narration ? '' : dat.narration }));

        const validKeyLength = Object.keys(data[0]).length;
        const filterValidRows = data.filter((row) => Object.keys(row).length === validKeyLength);

        const banks = await paymentsService.getBankNames();

        const nipCodes = banks.map((bank:any) => bank.nipCode);

        const errors: string[] = [];

        filterValidRows.forEach((fil) => {
          if (!fil.accountNumber || fil.accountNumber.length < 10 || fil.accountNumber.length > 10) {
            errors.push(`Account number ${fil.accountNumber} should be 10 digits`);
          }
          if (!fil.bankCode || fil.bankCode.length < 6 || fil.bankCode.length > 6) {
            errors.push(`Bank code ${fil.bankCode} should be 6 digits`);
          }
          if (!nipCodes.includes(fil.bankCode)) {
            errors.push(`Bank code ${fil.bankCode} does not exist`);
          }
        });

        if (errors.length) reject(errors);

        // if (filterValidRows.some((dat) => dat.accountNumber.length < 10 || dat.accountNumber.length > 10)) reject(new Error('Account number(s) should be 10 digit'));

        // if (filterValidRows.some((dat) => dat.bankCode.length < 6 || dat.bankCode.length > 6)) reject(new Error('Bank code(s) should be 6 digit'));

        if (callback) {
          callback(filterValidRows);
        }
        resolve(filterValidRows);
      };
      fileReader.readAsBinaryString(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const sheetToJsonArray = (file: File, callback?: (jsonArray: Record<string, string>[]) => void): Promise<Record<string, string>[]> => {
  const rowDelimeter = '^^^';
  const columnDelimeter = '%%%';

  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const buffer = e.target?.result;

        const workbook = XLSX.read(buffer, { type: 'binary' });
        const sheetOption = {
          skipHidden: true, strip: true, RS: rowDelimeter, FS: columnDelimeter,
        };
        const data = csvToJSON(XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]], sheetOption), rowDelimeter, columnDelimeter);
        const validKeyLength = Object.keys(data[0]).length;
        const filterValidRows = data.filter((row) => Object.keys(row).length === validKeyLength);
        if (callback) {
          callback(filterValidRows);
        }
        resolve(filterValidRows);
      };
      fileReader.readAsBinaryString(file);
    } catch (e) {
      reject(e);
    }
  });
};

export const getFetchRequest = (methodType: 'POST' | 'PUT', body: Record<string, unknown>[] | Record<string, string>): RequestInit => {
  const requestInit: RequestInit = {
    method: methodType,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return requestInit;
};

export const renameFile = async (file: File, newName: string):Promise<File> => {
  const arrayBuffer = await file.arrayBuffer();
  return new File([new Int8Array(arrayBuffer)], newName + getExtFromMime(file.type, true), { type: file.type, lastModified: file.lastModified });
};

export function excludeObjectEntries<T>(object: Record<string, unknown>, entryKeys: Array<string>): T {
  let newObject: Record<string, unknown> = {};
  Object.entries(object).forEach((item: [string, unknown]) => {
    if (!entryKeys.includes(item[0])) {
      newObject = { ...newObject, [item[0]]: item[1] };
    }
  });
  return newObject as T;
}

export function objectToFormData(object: Record<string, string|Blob>): FormData {
  const form = new FormData();
  return Object.entries(object).reduce((result: FormData, [key, value]) => {
    result.append(key, value);
    return result;
  }, form);
}
