import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BackBar } from '../../../components/BackBar';
import * as S from './styles';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import { OtpInput } from '../../../components/OtpInput';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/SimpleModal';
import { TextInput } from '../../../components/TextInput';
import authService from '../../../services/internal/auth/auth.service';
import { failed } from '../../../components/Toasts';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const ResetTransactionPin: React.FC = () => {
  const route = useHistory();
  const { state } = useLocation<{ password: string }>();
  const [errors, setErrors] = React.useState({
    password: '',
    newPin: '',
    confirmPin: '',
  });
  const [data, setData] = React.useState<{
    password: string;
    newPin: string;
    confirmPin: string;
  }>({
    password: state && state.password ? state.password : '',
    newPin: '',
    confirmPin: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const onChange = (value: string, name: string) => {
    setData({ ...data, [name]: value });
    if (value && value.length < 4)
      setErrors({ ...errors, [name]: 'PIN must be 4 digits' });

    if (value && value.length >= 4) {
      setErrors({ ...errors, [name]: '' });
      if (name === 'confirmPin' && data.newPin !== value) {
        setErrors({
          ...errors,
          [name]: 'PIN does not match',
        });
      }
    }
  };

  const onChangePassword = (value: string) => {
    setData({ ...data, password: value });
    if (!value) {
      setErrors({ ...errors, password: ' ' });
    } else {
      setErrors({ ...errors, password: '' });
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (state?.password) {
        await authService.setTransactionPin({
          password: data.password,
          newPin: data.newPin,
          newPinConfirmation: data.confirmPin,
        });
      } else {
        await authService.resetTransactionPin({
          password: data.password,
          newPin: data.newPin,
          newPinConfirmation: data.confirmPin,
          otp: token,
        });
      }

      setShowModal(true);
      setLoading(false);
    } catch (err) {
      const error = err as any;
      setLoading(false);
      failed(
        'Failed!',
        error.message ||
          'Can not reset pin at the moment, please try again later'
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setTimeout(() => {
      route.goBack();
    }, 1000);
  };

  const checkIfTokenIsValid = () => {
    if (state?.password && !token) return true;
    if (!state?.password && !token) {
      return false;
    }
    if (!state?.password && token) return true;
  };

  return (
    <S.OTPContainer>
      <BackBar
        name={
          state && state.password
            ? 'Set Transaction PIN'
            : 'Reset Transaction PIN'
        }
      />

      <S.Body>
        {state && state.password ? null : (
          <S.InputContainer>
            <Text color={colors.black._100} bold>
              Enter your password
            </Text>
            <S.OtpWrapper>
              <TextInput
                invalid={errors.password}
                label="Password"
                onChange={(value) => onChangePassword(value)}
                // onEnterPressed={function noRefCheck() {}}
                // onIconClick={function noRefCheck() {}}
                type="password"
              />
            </S.OtpWrapper>
          </S.InputContainer>
        )}

        <S.InputContainer>
          <Text color={colors.black._100} bold>
            Enter new PIN
          </Text>
          <S.OtpWrapper>
            <OtpInput
              errorMessage={errors.newPin}
              onCodeChange={(value) => onChange(value, 'newPin')}
              secure
              codeLength={4}
            />
          </S.OtpWrapper>
        </S.InputContainer>

        <S.InputContainer>
          <Text color={colors.black._100} bold>
            Repeat new PIN
          </Text>
          <S.OtpWrapper>
            <OtpInput
              errorMessage={errors.confirmPin}
              onCodeChange={(value) => onChange(value, 'confirmPin')}
              secure
              codeLength={4}
            />
          </S.OtpWrapper>
        </S.InputContainer>
        {!state?.password && (
          <S.InputContainer>
            <S.OtpWrapper>
              <TextInput
                label="Enter the 6-digit code in your authenticator app"
                value={token}
                onChange={setToken}
                type="password"
                mask={createNumberMask(numberMaskOptions)}
              />
            </S.OtpWrapper>
          </S.InputContainer>
        )}
        <Button
          onClick={onSubmit}
          label={loading ? 'Please wait...' : 'Reset PIN'}
          disabled={
            !(data.confirmPin && data.newPin && data.password) ||
            loading ||
            Object.values(errors).filter((val) => val !== '').length >= 1 ||
            !checkIfTokenIsValid()
          }
        />
      </S.Body>

      <Modal
        visible={showModal}
        title="Done!"
        description="Your transaction pin has been changed successfully"
        onCloseModal={closeModal}
        action={closeModal}
      />
    </S.OTPContainer>
  );
};

export default ResetTransactionPin;
