import React, { useEffect, useState } from 'react';

import * as S from './styles';
import { PageTitle } from '../../styles/wrappers';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';
import { ClickablePanel } from '../../components/ClickablePanel';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TwoFactorModal from '../../components/2faModal';

export const Transfers: React.FC = () => {
  const state = useLocation().state as { type?: string };
  const route = useHistory();
  const location = useLocation();
  const UserProfile = useSelector(
    (state: { currentUser: any }) => state.currentUser.data
  );

  const [show2fa, setShow2fa] = useState(false);

  const toggle2fa = () => setShow2fa((prev) => !prev);

  useEffect(() => {
    if (UserProfile) {
      setShow2fa(!UserProfile.use2fa);
    }
  }, [UserProfile]);

  useEffect(() => {
    if (route.action === 'PUSH') {
      if (state?.type === 'own') {
        route.push('/transfers/own-accounts');
      } else if (state?.type === 'others') {
        route.push('/transfers/other-bank');
      }
    }
  }, [state]);
  return (
    <div>
      <AccessControl requiredPermission={[EPermissions.CREATE_PAYMENT]}>
        <PageTitle.Wrapper>
          <Text color={colors.black._100} size={4} bold>
            Make a Payment
          </Text>
        </PageTitle.Wrapper>

        <S.TransfersWrapper>
          <S.TransferTypeWrapper>
            <ClickablePanel
              icon="git-branch"
              to="/transfers/own-accounts"
              description="Transfer to own accounts"
            />
          </S.TransferTypeWrapper>

          <S.TransferTypeWrapper>
            <ClickablePanel
              icon="two-opposite-arrows-rotated"
              to="/transfers/other-bank"
              description="Transfer to other accounts"
            />
          </S.TransferTypeWrapper>
        </S.TransfersWrapper>
      </AccessControl>

      <S.Row>
        <AccessControl requiredPermission={[EPermissions.CREATE_PAYMENT]}>
          <PageTitle.Wrapper>
            <Text color={colors.black._100} size={4} bold>
              Other
            </Text>
          </PageTitle.Wrapper>
        </AccessControl>
        <S.TransfersWrapper>
          <AccessControl requiredPermission={[EPermissions.CREATE_PAYMENT]}>
            <S.TransferTypeWrapper>
              <ClickablePanel
                icon="users"
                to="/transfers/beneficiaries"
                description="Beneficiaries managment"
              />
            </S.TransferTypeWrapper>
          </AccessControl>

          <AccessControl requiredPermission={[EPermissions.APPROVE_PAYMENT]}>
            <S.TransferTypeWrapper>
              <ClickablePanel
                icon="file-check"
                to="/transfers/bulk"
                description="Payment Approvals"
              />
            </S.TransferTypeWrapper>
          </AccessControl>
        </S.TransfersWrapper>
      </S.Row>
      {UserProfile && (
        <TwoFactorModal
          showSkipButton={false}
          show={show2fa}
          toggle={toggle2fa}
          fromRoute={location.pathname}
        />
      )}
    </div>
  );
};
