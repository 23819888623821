import { ActionInterface } from '../IAction';
import { CompanyInterface } from './ICompany';
import { ActiveBusinessInterface, BusinessInterface } from '../../services/internal/payments/IPayments';
import {
  SET_LIST, SET_ACTIVE, SET_ERROR, REMOVE_ERROR,
} from './actions';

type ReducerTypes = Array<BusinessInterface> | BusinessInterface | string;

const initialState: CompanyInterface = {
  active: null,
  list: [],
  error: '',
};

const reducer = (state = initialState, action: ActionInterface<ReducerTypes>): CompanyInterface => {
  switch (action.type) {
    case SET_LIST:
      return {
        ...state,
        list: action.payload as Array<BusinessInterface>,
      };
    case SET_ACTIVE:
      return {
        ...state,
        active: action.payload as ActiveBusinessInterface,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload as string,
      };
    case REMOVE_ERROR:
      return {
        ...state,
        error: '',
      };

    default:
      return state;
  }
};

export default reducer;
