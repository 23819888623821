import React, { CSSProperties } from 'react';

import * as S from './styles';
import { TableHeader, TableHeaderProps } from '../TableHeader';
import { TableRow, TableRowProps } from '../TableRow';
import { Text } from '../Text/index';

export type TableFooterProps = TableHeaderProps;

export interface TableProps {
  /**
   * Table header
   */
  header?: TableHeaderProps;
  /**
   * Table rows collection
   */
  rows: Array<TableRowProps>;
  /**
   * Table footer
   */
  footer?: TableFooterProps | undefined;

  style?: string;
  headerStyle?: CSSProperties;

  bgColor?: string;
  styleBg?: React.CSSProperties;
}

const TableComponent: React.FC<TableProps> = ({
  header,
  rows,
  footer,
  style,
  bgColor,
  styleBg,
  headerStyle,
}) => {
  let lastRow = '';
  const render = (row: TableRowProps) => {
    if (row.label && row.label !== lastRow) {
      lastRow = row.label;
      return (
        <>
          <S.Label>
            <Text bold>{row.label}</Text>
          </S.Label>
          <TableRow {...row} />
        </>
      );
    }
    return <TableRow {...row} />;
  };

  return (
    <S.TableWrapper>
      <S.Table>
        <thead style={headerStyle}>
          {/* <TableHeader color={style} {...header} /> */}
          {header && (
            <TableHeader color={style} styleBg={styleBg} {...header} />
          )}
        </thead>
        {footer && (
          <tfoot>
            {/* <TableHeader {...footer} /> */}
            <TableHeader backgroundColor={bgColor} {...footer} />
          </tfoot>
        )}
        <tbody>{rows.map(render)}</tbody>
      </S.Table>
    </S.TableWrapper>
  );
};
export const TableUpdated = React.memo(TableComponent);
