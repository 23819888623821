export const settingsRoute = '/settings';
export const companyDetailsSettingsRoute = '/settings/company/:id';
export const usersSettingsRoute = '/settings/users';
export const transactionPinSettingsRoute = '/settings/t-pin';
export const changeTransactionPinSettingsRoute = '/settings/t-pin/change';
export const resetTransactionPinSettingsRoute = '/settings/t-pin/reset';
export const loginPasswordSettingsRoute = '/settings/login-password';
export const passwordStep2SettingsRoute = '/settings/login-password/step-2';
export const passwordStep3SettingsRoute = '/settings/login-password/step-3';
export const addCompanyRoute = '/settings/add-company';
export const paymentlistRoute = '/payments';
export const transactionDetailsRoute = '/payments/all/details';
export const batchDetailsRoute = '/payments/view-batch';
export const addBusinessRoute = '/onboarding/add-business';
export const editQuickLinksRoute = '/quickLinks/edit';
export const twoFactor = '/2fa';
export const limit = '/limit';
