import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LineChart,
  Line,

} from 'recharts';

import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';
import { Icon } from '../../components/Icon';
import * as S from './styles';
import { DonutChart } from '../Dashboard/components/DonutChartAnalytics';
import { AnalyticsdataProps, Monthlystatistics } from '.';

export interface AnalyticsProps {
  /**
   * Example page property
   */
  example?: string,
  Data: AnalyticsdataProps
  Year: number
}

export const AnalyticReport: React.FC<AnalyticsProps> = ({ ...props }) => {
  const [IncomeExpensesData, setIncomeExpensesData] = useState<Array<Monthlystatistics>>([]);
  const [Categorydata, setCategorydata] = useState<Array<any>>([]);
  const [ExpensesData, setExpensesData] = useState<Array<any>>([]);
  const [inflowOutFlow, setinflowOutFlow] = useState<Array<any>>([]);
  useEffect(() => {
    const monthlystatistics = props.Data.monthly_statistics;
    const categorystatistics = props.Data.category_statistics;
    setIncomeExpensesData(monthlystatistics);
    const catcate: { id: string; color: string; value: number; }[] = [];
    const expensesd = [];
    Object.entries(categorystatistics).forEach(
      ([key, value]) => {
        const template = {
          id: key,
          color: colors.pink._100,
          value,
        };
        catcate.push(template);
      },
    );
    const ExpenseInnerData = {
      name: `Jan ${props.Year} - Dec  ${props.Year}`,
      'Total Credit': props.Data.total_credit_amount,
      'Planed Debit': props.Data.total_debit_amount,

    };
    const inflow = monthlystatistics.map((value) => ({

      name: value.name,
      inflow: value.income,
      outflow: value.expenses,

    }));
    expensesd.push(ExpenseInnerData);
    setCategorydata(catcate);
    setExpensesData(expensesd);
    setinflowOutFlow(inflow);
  }, [props.Data]);
  return (
    <>

      <S.BackgroundWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              {' '}
              <Text size={5} color={colors.black._100} bold>Credit Vs Debit N</Text>
              {' '}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ marginRight: '40px' }}>
              <S.IconMargin>
                <Icon
                  name="dotheavy"
                  color={colors.green._100}
                  width={10}
                  height={10}
                />
              </S.IconMargin>
              <Text size={5} color={colors.black._100}>Credit</Text>
              {' '}
            </div>
            <div>
              <S.IconMargin>
                <Icon
                  name="dotlight"
                  color={colors.red._100}
                  width={10}
                  height={10}
                />
              </S.IconMargin>
              <Text size={5} color={colors.black._100}>Debit</Text>
              {' '}
            </div>
          </div>
        </div>
        <S.ChartPosition>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={IncomeExpensesData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="income" fill={`${colors.green._100}`} />
              <Bar dataKey="expenses" fill={`${colors.red._100}`} />
            </BarChart>
          </ResponsiveContainer>
        </S.ChartPosition>
      </S.BackgroundWrapper>

      {!Categorydata && (
      <div style={{ border: '1px solid #E9EBF1', borderRadius: 5, padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ marginRight: '40px' }}>
              {' '}
              <Text size={5} color={colors.black._100} bold>Transactions value</Text>
              {' '}
            </div>
          </div>
        </div>
        <div>
          <div style={{ width: '100%', marginTop: '40px' }}>
            <DonutChart CatData={Categorydata} />
          </div>
        </div>
      </div>
      )}

      <S.Flex2Style>
        <S.Background2Wrapper>
          <div style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap',
          }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                {' '}
                <Text size={5} color={colors.black._100} bold>Transfers, mln ₦ </Text>
                {' '}
              </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ marginRight: '40px' }}>
                <S.IconMargin>
                  <Icon
                    name="dotheavy"
                    color={colors.green._100}
                    width={10}
                    height={10}
                  />
                </S.IconMargin>
                <Text size={5} color={colors.black._100}>Inflow</Text>
                {' '}
              </div>
              <div>
                <S.IconMargin>
                  <Icon
                    name="dotlight"
                    color={colors.red._100}
                    width={10}
                    height={10}
                  />
                </S.IconMargin>
                <Text size={5} color={colors.black._100}>OutFlow</Text>
                {' '}
              </div>
            </div>
          </div>
          <div>
            <S.ChartPosition>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={inflowOutFlow}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="inflow"
                    stroke={`${colors.green._100}`}
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="outflow" stroke={`${colors.red._100}`} />
                </LineChart>
              </ResponsiveContainer>
            </S.ChartPosition>

          </div>
        </S.Background2Wrapper>

        <S.Background3Wrapper>
          <S.Container>
            <S.TextContainer>
              <S.Container>
                <Text size={5} color={colors.black._100} bold>
                  Total Expense & Planned Expense
                </Text>
              </S.Container>

            </S.TextContainer>
            <S.FlexStyle>
              <S.MarginRight>
                <S.IconMargin>
                  <Icon
                    name="dotheavy"
                    color={colors.green._100}
                    width={10}
                    height={10}
                  />
                </S.IconMargin>
                <Text size={5} color={colors.black._100}>Total Credit</Text>
                {' '}
              </S.MarginRight>
              <div>
                <S.IconMargin>
                  <Icon
                    name="dotlight"
                    color={colors.red._100}
                    width={10}
                    height={10}
                  />
                </S.IconMargin>
                <Text size={5} color={colors.black._100}>Total Debit</Text>
                {' '}
              </div>
            </S.FlexStyle>
          </S.Container>

          <S.Container>
            <S.Chart2Position>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={ExpensesData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Total Credit" fill={`${colors.green._100}`} />
                  <Bar dataKey="Planed Debit" fill={`${colors.red._100}`} />
                </BarChart>
              </ResponsiveContainer>
            </S.Chart2Position>

          </S.Container>
        </S.Background3Wrapper>
      </S.Flex2Style>
    </>
  );
};
