import * as S from './styles';
import { RootState } from '../../../../redux';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { Text } from '../../../../components/Text';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../../../utils/hooks';
import { Icon, IconName, IconProps } from '../../../../components/Icon';
import { breakpoints, colors } from '../../../../styles/variables';
import {
  Link as ReactRouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  MenuItem,
  QuickLinkInterface,
} from '../../../../redux/quicklinks/IQuickLinks';
import { Button } from '../../../../components/Button';
import { editQuickLinksRoute } from '../../../../navigation';
import { setActiveLinks } from '../../../../redux/quicklinks/actions';
import AccessControl from '../../../../components/AccessControl';
import { Draggable, Droppable } from 'react-beautiful-dnd';

const QuickLinkItem: FC<{
  index: number;
  items: MenuItem;
  isActive?: boolean;
  onMenuClick?: (item: MenuItem) => void;
  showDeleteButton: boolean;
  showSelectedIcon: boolean;
  removeItem: (e: any, label: string) => void;
}> = ({ items, showDeleteButton, showSelectedIcon, ...props }) => {
  const route = useHistory();
  const mainMenuStore = useSelector((store: RootState) => store.mainMenu);
  const [width] = useWindowSize();

  return (
    <Draggable
      key={items.label}
      draggableId={props.index.toString()}
      index={props.index}
    >
      {(provided) => (
        <div
          // to={items.to || ''}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // onClick={() => onMenuItemClick(items)}
          // style={{ cursor: 'pointer' }}
          ref={provided.innerRef}
        >
          <AccessControl
            requiredPermission={
              items.requiredPermission ? [...items.requiredPermission] : []
            }
            key={items.label}
          >
            <S.LinksInner svgBg={props.isActive ? '#FFFFFF' : undefined}>
              <Icon
                width={22}
                height={20}
                name={items.icon as IconName['name']}
                color={colors.black._100}
              />
              <Text
                // style={{ whiteSpace: 'nowrap' }}
                color={colors.black._100}
                bold
                size={6}
              >
                {items.label}
              </Text>
              {showDeleteButton && (
                <S.CloseBtn onClick={(e) => props.removeItem(e, items.label)}>
                  <Icon name='small_close' />
                </S.CloseBtn>
              )}
              {showSelectedIcon && items.isActive && (
                <S.CheckBtn>
                  <Icon name='small_check' />
                </S.CheckBtn>
              )}
            </S.LinksInner>
          </AccessControl>
        </div>
      )}
    </Draggable>
  );
};

export const DraggableQuickLinks: FC<{
  data?: MenuItem[];
  isActive?: boolean;
  showDeleteButton?: boolean;
  title?: string;
  showEditButton?: boolean;
  showSelectedIcon?: boolean;
  onMenuClick?: (item: MenuItem) => void;
  id?: string;
  onRemoveItem?: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    label: string
  ) => void;
}> = ({
  showDeleteButton = false,
  title,
  showEditButton = false,
  showSelectedIcon = false,
  ...props
}) => {
  const route = useHistory();
  const dispatch = useDispatch();
  const quickLinks = useSelector(
    (state: { quickLinks: QuickLinkInterface }) =>
      state.quickLinks.activeQuickLinks as MenuItem[]
  );

  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [data, setData] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setLoading(false);
    } else if (quickLinks) {
      setData(quickLinks);
      setLoading(false);
    }
  }, [props.data, quickLinks]);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const removeItem = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    label: string
  ) => {
    if (props.onRemoveItem) return props.onRemoveItem(e, label);
    e.stopPropagation();
    const newData = data.filter((theData) => theData.label !== label);
    dispatch(setActiveLinks(newData));
    // setData(newData);
  };

  const mainMenuStore = useSelector((store: RootState) => store.mainMenu);
  const [width] = useWindowSize();

  const onMenuItemClick = (item: MenuItem) => {
    // const pathName = window.location.pathname;
    if (props.onMenuClick) {
      props.onMenuClick(item);
      return;
    }
    const pathName = item.to;
    if (pathName) {
      route.push(pathName, { ...item.routeData });
      setCurrentPath(pathName);
    }

    if (mainMenuStore.opened && width <= breakpoints.lg) {
      close();
    }
  };

  return (
    <S.Container background={props.isActive ? '#EAECF0' : undefined}>
      <S.TopText>
        <Text color={colors.black._20} bold size={5}>
          {title ?? 'Quick Links'}
        </Text>

        {showEditButton && (
          <Button
            label='Edit'
            theme='light'
            size='small'
            style={{ fontWeight: 700 }}
            onClick={() => route.push(editQuickLinksRoute)}
          />
        )}
      </S.TopText>
      <Droppable droppableId={props.id || '1'} direction='horizontal'>
        {(provided) => (
          <S.Links ref={provided.innerRef} {...provided.droppableProps}>
            {!loading &&
              data.map((items, index) => {
                return (
                  <QuickLinkItem
                    key={index}
                    index={index}
                    items={items}
                    isActive={props.isActive}
                    onMenuClick={props.onMenuClick}
                    removeItem={removeItem}
                    showDeleteButton={showDeleteButton}
                    showSelectedIcon={showSelectedIcon}
                  />
                );
              })}
            {provided.placeholder}
          </S.Links>
        )}
      </Droppable>
    </S.Container>
  );
};
