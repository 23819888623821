import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { CompanyInterface } from '../../redux/company/ICompany';
import { CompanyActions } from '../../redux/company/actions';
import { MainMenuActions } from '../../redux/main-menu/actions';

import { Logo } from '../Logo';
import { Text } from '../Text';
import { Icon, IconProps } from '../Icon';
import { RootState } from '../../redux';
import { Link } from '../Link';
import { MiniProfile } from '../MiniProfile';
import { Link as Links } from 'react-router-dom';
import { useWindowSize } from '../../utils/hooks';

import { breakpoints, colors } from '../../styles/variables';
import * as S from './styles';
import AccessControl, { EPermissions } from '../AccessControl';
import { failed } from '../Toasts';
import paymentsService from '../../services/internal/payments/payments.service';
import authService from '../../services/internal/auth/auth.service';
import { AuthorizationActions } from '../../redux/authorization/actions';

interface MenuItem {
  icon: IconProps['name'];
  to: string;
  text: string;
  badge?: string;
  requiredPermission: Array<string>;
}

const menuItems: Array<MenuItem> = [
  {
    icon: 'cubes',
    text: 'Dashboard',
    to: '/dashboard',
    requiredPermission: [EPermissions.VIEW_PAYMENT],
  },
  // {
  //   icon: 'database',
  //   text: 'Pay Bills',
  //   badge: '3',
  // },
  {
    icon: 'horizontal-lines',
    text: 'Payments',
    to: '/transfers',
    requiredPermission: [
      EPermissions.CREATE_PAYMENT,
      EPermissions.APPROVE_PAYMENT,
    ],
  },

  // {
  //   icon: 'grid',
  //   text: 'More',
  //   to: '/transferss',
  //   requiredPermission: [
  //     EPermissions.CREATE_PAYMENT,
  //     EPermissions.APPROVE_PAYMENT,
  //   ],
  // },
  // {
  //   icon: 'watches',
  //   text: 'Pending payments',
  // },
  // {
  //   icon: 'credit-card',
  //   text: 'Cards & loans',
  // },
  // {
  //   icon: 'clipboard',
  //   text: 'Budgeting & Planning',
  // },
  // {
  //   icon: 'building',
  //   text: 'Company',
  // },
  {
    icon: 'report',
    text: 'Report',
    to: '/payments',
    requiredPermission: [EPermissions.VIEW_PAYMENT],
  },
  {
    icon: 'profile',
    text: 'Users',
    to: '/users',
    requiredPermission: [EPermissions.VIEW_OPERATOR],
  },

  // {
  //   icon: 'wrench',
  //   text: 'Profile',
  //   to: '/user',
  //   requiredPermission: [EPermissions.VIEW_OPERATOR],
  // },
  // {
  // 	icon: 'database',
  // 	text: 'USSD Rebate',
  // 	to: '/ussd-rebate',
  // 	requiredPermission: [],
  // },

  {
    icon: 'question-mark',
    text: 'Help and Support',
    to: '/settings',
    requiredPermission: [EPermissions.VIEW_OPERATOR, EPermissions.VIEW_PAYMENT],
  },
];
const separator = '=';
const prefix = `?company${separator}`;
export interface AccountItemProps {
  idB?: any;
  onClick?: any;
  nuban: string;
  isChecked?: boolean;
  setIsChecked?: (value: boolean) => void;
}

export interface ProfileProps {
  open?: boolean;
  idB?: any;
  nuban?: any;
  onDropdown?: () => void;
  accounts?: Array<AccountItemProps>;
}

export const MainMenu: React.FC<ProfileProps> = ({
  idB,
  nuban,
  open = false,
  accounts = [],
  onDropdown = () => {},
}) => {
  /**
   * Hooks initiation region
   */
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const dispatch = useDispatch();
  const [width] = useWindowSize();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  /**
   * Redux store initiation region
   */
  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const companyError = useSelector(
    (state: { company: CompanyInterface }) => state.company.error
  );

  const mainMenuStore = useSelector((store: RootState) => store.mainMenu);

  useEffect(() => {
    if (companyError) {
      failed('Failed', companyError);
    }
  }, [companyError]);

  /**
   * Custom handlers initiation region
   */
  const close = () => {
    dispatch(MainMenuActions.close());
  };

  const onMenuItemClick = (item: MenuItem) => {
    // const pathName = window.location.pathname;
    const pathName = item.to;
    if (pathName) {
      setCurrentPath(pathName);
    }

    if (mainMenuStore.opened && width <= breakpoints.lg) {
      close();
    }
  };
  const renderMenuItems = (items: Array<MenuItem>) =>
    items.map((item, index) => (
      <AccessControl
        key={index.toString()}
        requiredPermission={item.requiredPermission}
      >
        <ReactRouterLink
          to={item.to || ''}
          onClick={() => onMenuItemClick(item)}
          style={{ textDecoration: 'none', outline: 'none' }}
        >
          <S.MenuItem>
            <S.MenuItemLeftWrapper
              style={
                item.to === currentPath || currentPath.includes(item.to)
                  ? { background: '#EFF0F6' }
                  : {}
              }
            >
              <S.MenuItemIconWrapper>
                <Icon
                  name={item.icon}
                  color={
                    item.to === currentPath || currentPath.includes(item.to)
                      ? '#14142B'
                      : '#6E7191'
                  }
                />
              </S.MenuItemIconWrapper>
              <S.MenuItemTextWrapper>
                <Text
                  bold={
                    item.to === currentPath || currentPath.includes(item.to)
                  }
                  color={
                    item.to === currentPath || currentPath.includes(item.to)
                      ? '#14142B'
                      : '#6E7191'
                  }
                >
                  {item.text}
                </Text>
              </S.MenuItemTextWrapper>
            </S.MenuItemLeftWrapper>

            {item.badge && (
              <S.Badge>
                <Text color={colors.white._100} size={8} bold>
                  {item.badge}
                </Text>
              </S.Badge>
            )}
          </S.MenuItem>
        </ReactRouterLink>
      </AccessControl>
    ));
  /**
   * useEffect region
   */
  useEffect(() => {
    if (width >= breakpoints.lg) {
      dispatch(MainMenuActions.open());
    } else if (mainMenuStore.opened) {
      close();
    }
  }, [width]);

  useEffect(() => {
    dispatch(CompanyActions.getActiveCompany());
  }, []);

  const [drop, setDrop] = useState(false);

  const dropdownA = (): void => {
    setDrop(!drop);
  };

  const AccountItem: React.FC<AccountItemProps> = ({
    idB = '',
    onClick,
    nuban = '',
    isChecked,
    setIsChecked = () => {},
  }) => {
    const [visibility, setVisibility] = useState(true);
    const toggleVisibility = () => {
      setVisibility(!visibility);
    };

    return (
      <S.AccountItemBox
        onClick={() => {
          setIsChecked(true);
        }}
      >
        <S.AccountItemInfoBlock>
          <div
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflowX: 'hidden',
              maxWidth: 200,
            }}
          >
            <Text size={5} bold color='#394455'>{`${nuban}`}</Text>
          </div>
          <div>
            {/* <Text size={4} color='#6E7191'>{`ID: ${idB}`}</Text> */}
          </div>
        </S.AccountItemInfoBlock>
        <S.AccountItemBalanceBlock>
          {isChecked ? <Icon name='check' /> : ''}
        </S.AccountItemBalanceBlock>
      </S.AccountItemBox>
    );
  };

  const [currentCompanyId, setCurrentCompanyId] = useState<string>('');
  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list
  );
  useEffect(() => {
    if (!companies?.length) {
      dispatch(CompanyActions.getCompaniesList());
    }

    if (!activeCompany) {
      dispatch(CompanyActions.getActiveCompany());
    } else {
      setCurrentCompanyId(activeCompany.id);
    }
  }, [companies, activeCompany]);

  const switchCompany = async (id: string) => {
    try {
      const result = await paymentsService.SwitchCompnany(id);
      await authService.refreshToken();
      window.location.reload();
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'Error Switching account');
      if (err.code === 'INVALID_TOKEN') {
        dispatch(AuthorizationActions.logOut());
        setTimeout(() => {
          // history.push('/');
        }, 200);
      }
    }
  };

  return (
    <S.Wrapper
      firstOpen={mainMenuStore.firstOpen}
      opened={mainMenuStore.opened}
    >
      <S.ContentWrapper>
        <S.HeaderWrapper>
          <S.CloseMenuButtonWrapper>
            <Link icon='big-thin-cross' onClick={close} />
          </S.CloseMenuButtonWrapper>
        </S.HeaderWrapper>
        <S.CompanyNameWrapper>
          <S.CompanyNameTextWrapper>
            <Text color={colors.pink._100} size={3} bold>
              <S.EntityDiv>
                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                  <Icon name='bank' />
                  <S.EntityDetails>
                    <Text color={colors.black._20} size={5}>
                      Switch business
                    </Text>
                    <Text
                      color={colors.black._20}
                      size={5}
                      bold
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflowX: 'hidden',
                        maxWidth: 210,
                      }}
                    >
                      {activeCompany?.name}
                    </Text>
                  </S.EntityDetails>
                </div>

                <S.DropdownWrapper onClick={dropdownA} openDrop={open}>
                  <Icon
                    color='#394455'
                    name='dropdown'
                    rotate={drop ? 180 : 0}
                  />
                </S.DropdownWrapper>
              </S.EntityDiv>

              {drop ? (
                <S.DropownContent openDrop={open}>
                  {' '}
                  <div>
                    <Text color={colors.black._20} size={5}>
                      Available company
                    </Text>

                    {companies.map((el, index) => (
                      <Links
                        key={el.id}
                        to={`${prefix}${el.id}`}
                        onClick={() => {
                          switchCompany(el.id);
                        }}
                        style={{ textDecoration: 'none' }}
                      >
                        <AccountItem
                          key={`${index.toString()}`}
                          idB={el.vClientId}
                          nuban={el.name}
                          isChecked={activeCompany?.id === el.id}
                        />
                      </Links>
                    ))}
                  </div>
                </S.DropownContent>
              ) : (
                ''
              )}
            </Text>
          </S.CompanyNameTextWrapper>
        </S.CompanyNameWrapper>
        <S.CompanyNameAndProfileWrapper>
          <MiniProfile />
        </S.CompanyNameAndProfileWrapper>
        <S.MenuItemsWrapper>
          <S.MenuMainItemsWrapper>
            {renderMenuItems(menuItems)}
          </S.MenuMainItemsWrapper>
        </S.MenuItemsWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
