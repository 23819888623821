export const baseFontSize = 16;

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const fonts = {
  poppins: {
    regular: 'Poppins-Regular',
    bold: 'Poppins-Bold',
  },
  inter: {
    regular: 'Inter-Regular',
    bold: 'Inter-Bold',
  },
};

export const transition = {
  fast: '.04s',
  slow: '.2s',
  superSlow: '1s',
};

export const colors = {
  black: {
    _100: '#333754',
    _80: '#7F91A8',
    _60: '#7F8EA4',
    _40: '#C5CAD7',
    _30: '#14142B',
    _25: '#21202E',
    _20: '#394455',
    _15: '4B5563',
    _10: '#E9EBF1',
    _7: '#EFF0F6',
    _5: '#F5F6FA',
    _2: '#FCFDFF',
    _1: '#D9DBE9',
  },
  pink: {
    _100: '#C6145E',
    _80: '#DA3178',
    _60: '#DD729F',
    _50: '#FFF3F8',
    _40: '#E8A1BF',
    _20: '#F4D0DF',
    _15: '#ED2E7E',
    _10: '#F9E7EF',
    _5: '#B10180',
  },
  white: {
    _100: '#FFF',
    _50: 'FAFAFA',
    _20: '#F8F8F7',
  },
  red: {
    _100: '#FF4C25',
    _90: '#ED405C',
    _10: '#FAD6CE',
  },
  orange: {
    _100: '#F3A100',
    _90: '#F83023',
    _10: '#FFF4D8',
  },
  green: {
    _100: '#36B83B',
    _90: '#E7F9D5',
    _20: '#19D6B2',
    _10: '#D6F8D7',
  },
  amber: {
    _100: '#FFBF00',
    _90: '#DB9308',
  },
  blue: {
    _100: '#2F80ED',
  },
  gray: {
    _100: '#A0A3BD',
    _80: '#D0D5DD',
  },
};
