import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";

import authorization from "./authorization/reducer";
import mainMenu from "./main-menu/reducer";
import currentUser from "./current-user/reducer";
import onboardingStack from "./onboarding/reducer";
import company from "./company/reducer";
import beneficiaryManagement from "./beneficiary.management/reducer";
import paginationSettings from "./paginationSettings/reducer";
import preference from "./preference/reducer";
import payment from "./payment/reducer";
import subaccount from "./subaccount/reducer";
import quickLinks from "./quicklinks/reducer";

const appReducer = combineReducers({
  authorization,
  mainMenu,
  currentUser,
  onboardingStack,
  company,
  beneficiaryManagement,
  paginationSettings,
  preference,
  payment,
  subaccount,
  quickLinks
});

const middleware = [thunk];

function configureStore() {
  return createStore(
    appReducer,
    composeWithDevToolsDevelopmentOnly(applyMiddleware(...middleware))
  );
}

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
