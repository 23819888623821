const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Returns month name from Date
 * @param {Date} d - date
 * @returns {String}
 */
const monthName = (d: Date = new Date()): string => months[d.getMonth()];

/**
 * Argument is N, then returns 0N.
 * Argument is NN, then returns NN.
 * @param {Number} n - number
 */
const addZeroIfOneNumber = (n = 0): number | string => {
  if (n <= 9) {
    return `0${n}`;
  }
  return n;
};

/**
 * Returns string from date like March 1
 * @param {Date} d
 */
const getBeautyDateString = (d = new Date()):string => `${monthName(d)} ${d.getDate()}`;

/**
 * Returns string from date like [March 1 | Today | Yesterday], 12:00
 * @param {Date} d
 * @param {Boolean} shouldConvertDayPhrase
 */
const getBeautyDateTimeString = (d: Date = new Date(), shouldConvertDayPhrase = true): string => {
  const now = new Date();
  const hours = d.getHours();
  const minutes = addZeroIfOneNumber(d.getMinutes());
  const time = ((h, m) => `${h % 12 || 12}:${m} ${h > 11 ? 'PM' : 'AM'}`)(hours, minutes);
  const dateStr = getBeautyDateString(d);
  // eslint-disable-next-line no-nested-ternary
  const datePhrase = d.toDateString() === now.toDateString()
    ? 'Today'
    : d.toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
      ? 'Yesterday'
      : dateStr;
  return `${shouldConvertDayPhrase ? datePhrase : dateStr}, ${time}`;
};

const getTimeString = (d: Date = new Date(), shouldConvertDayPhrase = true): string => {
  const now = new Date();
  const hours = d.getHours();
  const minutes = addZeroIfOneNumber(d.getMinutes());
  const time = ((h, m) => `${h % 12 || 12}:${m} ${h > 11 ? 'pm' : 'am'}`)(hours, minutes);
 
  return ` ${time}`;
};

/**
 * Returns string like `March 1, 2008`
 * @param {Date} d - date
 */
const timeDateMonthString = (
  d: Date = new Date(),
  format = (dateArgument: Date, date: number, year: number) => `${monthName(dateArgument)} ${date}, ${year}`,
): string => {
  const date = d.getDate();

  const year = d.getFullYear();

  return format(d, date, year);
};

/**
 * Returns string like `23 April, 2023`
 * @param {Date} d - date
 */
const dateTimeMonthString = (
  d: Date = new Date(),
  format = (dateArgument: Date, date: number, year: number) => `${date} ${monthName(dateArgument)}, ${year}`,
): string => {
  const date = d.getDate();

  const year = d.getFullYear();

  return format(d, date, year);
};

/**
 * Returns string from date like 01/03/2020
 * @param {Date} d
 */
const getBeautyDateStringWithSeparator = (d = new Date()) => `${addZeroIfOneNumber(d.getDate())}/${addZeroIfOneNumber(
  d.getMonth() + 1,
)}/${d.getFullYear()}`;

/**
 * Returns string from date like 2021-03-01
 * @param {Date} d
 */
const getDateWithYearFirst = (d = new Date()) => `${d.getFullYear()}-${addZeroIfOneNumber(d.getMonth() + 1)}-${addZeroIfOneNumber(d.getDate())}`;

export {
  monthName,
  getTimeString,
  addZeroIfOneNumber,
  timeDateMonthString,
  getBeautyDateTimeString,
  getBeautyDateString,
  getBeautyDateStringWithSeparator,
  getDateWithYearFirst,
  dateTimeMonthString
};
