import React from 'react';
import { colors } from '../../styles/variables';
import { Button } from '../Button';
import { CheckBox } from '../CheckBox';
import { Icon } from '../Icon';
import { Text } from '../Text';
import * as S from './styles';

export interface FilterData {
  name: string;
  options: Array<string>;
}

export interface FilterProps {
  /**
   * Filter options
   */
  data: Array<FilterData>;

  /** On check action */
  onCheck?: (value: boolean, option: string, name: string) => void;

  onReset?: () => void;

  apply?: () => void;
}
export const FilterButton: React.FC<FilterProps> = ({
  data,
  onCheck,
  ...props
}) => {
  const [checkValues, setValues] = React.useState<{ [key: string]: boolean }>(
    {},
  );
  const [show, setShow] = React.useState<boolean>(false);

  const handleCheck = (value: boolean, option: string, name: string) => {
    setValues({ ...checkValues, [option]: value });
    if (onCheck) onCheck(value, option, name);
  };

  const onReset = () => {
    setValues({});
    if (props.onReset) props.onReset();
  };

  const close = () => {
    setShow(false);
  };

  const toggle = () => {
    setShow(!show);
  };
  return (
    <S.Dropdown>
      <Button onClick={toggle} righIcon="filter" theme="info" size="small" />
      {show && (
        <S.Content>
          <S.CloseIcon onClick={close}>
            <Icon name="cancel" color={colors.black._100} />
          </S.CloseIcon>
          <S.ContentWrapper>
            {data.map((dat, index) => (
              <S.ContentContainer key={`${+index}${dat.name}`}>
                <Text bold>{dat.name}</Text>
                {dat.options.map((opt, ind) => (
                  <S.Item key={`${+ind}${opt}`}>
                    <CheckBox
                      checked={checkValues[opt]}
                      onChange={(value: boolean) =>
                        handleCheck(value, opt, dat.name)
                      }
                      text={opt}
                    />
                  </S.Item>
                ))}
              </S.ContentContainer>
            ))}
          </S.ContentWrapper>
          <S.ActionContainer>
            <S.ActionLayout>
              <Button
                label="Reset"
                onClick={onReset}
                backgroundColor="transparent"
                disabled={!Object.values(checkValues).includes(true)}
                theme="info"
              />
              <Button
                label="Apply filters"
                onClick={props.apply}
                disabled={!Object.values(checkValues).includes(true)}
              />
            </S.ActionLayout>
          </S.ActionContainer>
        </S.Content>
      )}
    </S.Dropdown>
  );
};
