import React from 'react';
import * as S from './styles';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { CSSProperties } from 'styled-components';

export interface TitleProps {
  title: string;
  color?: string;
  subTitle?: string;
  subtitleStyle?: CSSProperties;
}

export const Title: React.FC<TitleProps> = ({ title, subTitle, color, subtitleStyle }) => (
  <S.Container>
    <Text bold size={2} color={color || colors.pink._100}>
      {title}
    </Text>
    {subTitle && (
      <Text style={subtitleStyle?{...subtitleStyle}: {}} size={5} color='#6E7191'>
        {subTitle}
      </Text>
    )}
  </S.Container>
);
