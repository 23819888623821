import { FC, useState } from 'react';

import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import { TextInput } from '../TextInput';
import * as S from './styles';
import { Icon } from '../Icon';
import { Text } from '../Text';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button } from '../Button';
import { SuccessModal } from '../SuccessModal';

interface IProps {
  toggle: () => void;
  show: boolean;
  callback?: (val?: string) => void;
  loading: boolean;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
};
const TwoFactorAuthenticationModal: FC<IProps> = ({
  toggle,
  show,
  loading,
  callback,
}) => {
  const [token, setToken] = useState('');
  const handleClick = () => {
    if (token && callback) callback(token);
  };
  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      // onCloseModal={toggle}
      crossColor={colors.black._100}
    >
      <S.ManualContainer>
        <S.TitleContainer>
          <Text bold size={2} color="#2C2A3B">
            2FA Verification
          </Text>
        </S.TitleContainer>

        <div>
          <Text size={5} color={colors.black._25} bold>
            Enter the 6-digit code you see in your authenticator app
          </Text>
        </div>

        <S.InputContainer>
          <TextInput
            label="2FA code"
            placeholder="Enter code"
            value={token}
            onChange={setToken}
            type="password"
            mask={createNumberMask(numberMaskOptions)}
          />
        </S.InputContainer>

        <S.ButtonContainerTwo>
          <div style={{ width: '200px' }}>
            <Button
              style={{ width: '100%' }}
              label="Proceed"
              onClick={handleClick}
              loading={loading}
              disabled={!token || token?.length !== 6 || loading}
            />
          </div>
        </S.ButtonContainerTwo>
      </S.ManualContainer>
    </ModalWrapper>
  );
};

export default TwoFactorAuthenticationModal;
