import React from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { DirectorPreviewDetails } from '../../../services/internal/onboarding/IOnboarding';
import debug from '../../../services/internal/debbug.service';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';

import * as S from './styles';
import { PurviewDetails } from '../common.company.check-management';
import { Text } from '../../../components/Text/index';
import { colors } from '../../../styles/variables';

// TEMPORARY

const CommonCompanyCheckShareholders: React.FC = () => {
  /**
   * Hooks initiation region
   */
  // const dispatch = useDispatch();
  // const {actionRequired} = useLocation<{actionRequired: string}>().state
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const shareHolders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.shareholders as Array<DirectorPreviewDetails>,
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.reference as string,
  );

  /**
   * Custom handlers initiation region
   */

  const continueHandle = () => {
    debug.info('', [shareHolders, reference]); // TEMPORARY
    route.push('/onboarding/operators');
  };

  return (
    <S.Container>
      <Title title="Check management details" />
      <S.Wrapper>
        <Text color={colors.pink._80}>Shareholders Info</Text>
        <PurviewDetails purviewData={shareHolders} />
      </S.Wrapper>
      <Button label="Continue" onClick={continueHandle} />
    </S.Container>
  );
};
export default CommonCompanyCheckShareholders;
