import styled from 'styled-components';
import { ptr } from '../../styles/utils';

// import { NewUserProps } from '.';
// import { colors } from '../../styles/variables';

const ItemWrapper = styled.div`
  width: 100%;
  
  :not(:last-child) {
    margin-bottom: ${ptr(20)};
  }
`;
const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: ${ptr(32)};
`;

export {
  ItemWrapper,
  TitleWrapper,
};
