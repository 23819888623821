import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OnboardingActions } from '../../../redux/onboarding/actions';

import { success } from '../../../components/Toasts';
import { SuccessSection } from '../../Onboarding/components/SuccessSection';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { SIGN_UP } from '../constants';

export interface ModalProps {
  onClose?: () => void;
  visible?: boolean;
}

const CommonPersonalAccAccCompleted: React.FC<ModalProps> = ({
  visible,
  onClose,
}) => {
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();

  const onboardingData = useSelector(
    (state: { onboardingStack: any }) => state.onboardingStack.data
  );

  /**
   * Custom handlers initiation region
   */
  const gotItHandler = () => {
    batch(() => {
      dispatch(OnboardingActions.handleResetStacks());
      dispatch(OnboardingActions.handleUnSetEnv());
    });
    success('Success!', "You've created an account successfully.");
    history.push('/login');
  };

  return (
    <ModalWrapper maxWidth='100%' showModal={visible}>
      <SuccessSection
        title={
          onboardingData.type === SIGN_UP
            ? 'Account created successfully!'
            : 'Profile created successfully'
        }
        description={
          onboardingData.type === SIGN_UP
            ? 'Login to complete account set up (Workflow and Operators) '
            : 'Login to access your profile'
        }
        onClick={gotItHandler}
      />
    </ModalWrapper>
  );
};
export default CommonPersonalAccAccCompleted;
