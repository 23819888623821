import React from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { Text } from "../../../../components/Text";
import { colors } from "../../../../styles/variables";
import { Icon } from "../../../../components/Icon";

import * as S from "../SimpleForm/styles";
import { TextInput } from "../../../../components/TextInput";
import { DropdownInput } from "../../../../components/DropdownInput";
import { validIdTypes } from "../../../Onboard/constants";

export interface SimpleFormProps {
  idNumber: string;
  idType: string;
  phone: string;
  bvn: string;
  email: string;
  index?: number;
  onSelectId?: any;
  onChangeId?: any;
  removeFromIndex?: number;
  emailIsValid?: boolean;
  remove?: (index: number) => void;
  bvnIsValid?: boolean;
  idNumberIsValid?: boolean;
  phoneIsValid?: boolean;
  onChange?: (index: number, property: string, value: string) => void;
  title?: string;
  isAdded?: boolean;
  isEdit?: boolean;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const idNumberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 30,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const SimpleForm3: React.FC<SimpleFormProps> = ({
  //nin,
  idNumber,
  idType,
  phone,
  bvn,
  email,
  index,
  onSelectId,
  onChangeId,
  removeFromIndex = 0,
  emailIsValid,
  bvnIsValid,
  idNumberIsValid,
  phoneIsValid,
  title,
  remove = () => {},
  onChange = () => {},
}) => {
  return (
    <S.ItemContainer>
      <S.HeaderRow>
        <Text bold color={colors.black._100}>
          {title}
        </Text>
        {/* {index && index > removeFromIndex ? (
        <S.Remove onClick={() => remove(index)}>
          <Icon name='trash' color={colors.pink._100} />
        </S.Remove>
      ) : null} */}
      </S.HeaderRow>
      {/* <S.ItemRow>
      <TextInput label="NIN" type="currency" mask={createNumberMask(numberMaskOptions)} value={nin} onChange={(value) => onChange(index as number, 'nin', value)} />
      <TextInput label="BVN" type="currency" mask={createNumberMask(numberMaskOptions)} value={bvn} onChange={(value) => onChange(index as number, 'bvn', value)} />
    </S.ItemRow> */}

      <S.ItemRow>
        <div style={{ flex: 1 }}>
          <TextInput
            label='BVN'
            type='currency'
            mask={createNumberMask(numberMaskOptions)}
            value={bvn}
            valid={bvnIsValid}
            onChange={(value) => onChange(index as number, 'bvn', value)}
          />
        </div>

        <DropdownInput
          // placeholder="Select or enter destination bank"
          label='Valid ID Type'
          options={[...validIdTypes]}
          // options={convertBankNamesArray(bankNames || [])}
          //title={destinationBank?.label}
          // value={idType}
          inputValue={
            idType
              ? validIdTypes.filter((type) => type.value === idType)[0]?.label
              : ''
          }
          title={
            validIdTypes.filter((type) => type.value === idType)[0]?.label || ''
          }
          onSelect={(value: any) =>
            onChange(index as number, 'idType', value?.value ?? '')
          }
          // onSelect={onSelectId}
        />
      </S.ItemRow>
      <S.ItemRow>
        <div style={{ flex: 1 }}>
          <TextInput
            label='Valid ID Number'
            type='text'
            // mask={createNumberMask(numberMaskOptions)}
            value={idNumber}
            name='idNumber'
            onChange={(value) => onChange(index as number, 'idNumber', value)}
            //onChange = {onChangeId}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TextInput
            label='Phone number'
            type='tel'
            mask={createNumberMask(numberMaskOptions)}
            value={phone}
            valid={phoneIsValid}
            onChange={(value) => onChange(index as number, 'phone', value)}
          />
        </div>
      </S.ItemRow>
      <TextInput
        label='Email'
        value={email}
        onChange={(value) => onChange(index as number, 'email', value)}
        valid={emailIsValid}
      />
    </S.ItemContainer>
  );
};