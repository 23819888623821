import { ActionInterface } from '../IAction';
import { SubaccountType } from './ISubaccount';

export const SET_SUBACCOUNT_REQUEST = 'SET_SUBACCOUNT_REQUEST';
export const CLEAR_SUBACCOUNT_REQUEST = 'CLEAR_SUBACCOUNT_REQUEST';


const setSubaccountRequest = (payload: SubaccountType): ActionInterface<unknown> => ({
  type: SET_SUBACCOUNT_REQUEST,
  payload,
});

const clearSubaccountRequest = (): ActionInterface<unknown> => ({
  type: CLEAR_SUBACCOUNT_REQUEST,
});

export class SubaccountActions {

  public static handleSetSubaccountRequest(payload: SubaccountType): ActionInterface<unknown> {
    return setSubaccountRequest(payload);
  }

  public static handleClearSubaccountRequest(): ActionInterface<unknown> {
    return clearSubaccountRequest();
  }
}
