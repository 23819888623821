import React, { useState, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { OtpInput } from '../../../components/OtpInput';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { actions } from '../config';
import { success } from '../../../components/Toasts';

const CommonOtp: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const maskedPhone = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.maskedPhone as string
  );

  const maskedEmail = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.maskedEmail as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );
  const storedOtp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.otp as string
  );
  const storedVbankAccount = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.vbankAccount as string
  );

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean
  );

  /**
   * Local state initiation region
   */
  const [code, setCode] = useState<string>(storedOtp || '');
  const [loading, setLoading] = useState(false);
  const [reSend, setReSend] = useState(false);
  const [countDown, setCountDown] = useState<string>('04:00');

  const timer = useRef<NodeJS.Timeout | null>(null);

  const accountNumber = storedVbankAccount;

  useEffect(() => {
    const currentData = new Date();
    const countDownDate = new Date(currentData.getTime() + 4 * 60000).getTime();
    timer.current = setInterval(() => {
      setCountDown(() => {
        const now = new Date().getTime();
        const diff = countDownDate - now;
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        if (minutes === 0 && seconds === 0) {
          setReSend(true);
          if (timer.current) clearInterval(timer.current);
        }
        return `${minutes}:${seconds}`;
      });
    }, 1000);
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [reSend]);

  /**
   * Custom handlers initiation region
   */

  const resendOtp = async () => {
    if (accountNumber) {
      try {
        setLoading(true);
        const response = inApp
          ? await onboardingService.sendOtpForBusiness({
              nuban: accountNumber,
            })
          : await onboardingService.sendGuestOtpForBusiness({
              nuban: accountNumber,
            });
        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              vbankAccount: accountNumber,
              maskedEmail: response.data.maskedEmail,
              maskedPhone: response.data.maskedPhone,
              reference: response.data.reference,
            })
          );
        });
        success('Done!', 'Another OTP has been sent');
      } catch (err) {
        if (err.code === 'INVALID_ACTION_REQUIRED') {
          route.push(
            actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
          );
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || 'Can not complete request at the moment'
            )
          );
        }
      }
      setLoading(false);
    }
  };

  const continueHandle = () => {
    (async () => {
      setLoading(true);
      if (reference)
        try {
          const response = await onboardingService.validateOtpForBusiness({
            otp: code,
            reference,
          });
          batch(() => {
            dispatch(
              OnboardingActions.handleSetData({
                reference: response.data.reference,
              })
            );
          });

          if (response.actionRequired === 'SUBMIT_PROFILE') {
            route.push(actions[response.actionRequired + '_PASSWORD']);
          } else {
            route.push(actions[response.actionRequired]);
          }

          debug.info('', response); // TEMPORARY
        } catch (err) {
          if (err.code === 'INVALID_ACTION_REQUIRED') {
            route.push(
              actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
            );
          } else {
            dispatch(
              OnboardingActions.handleSetError(
                err.message || 'Can not complete request at the moment'
              )
            );
          }
        }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    if (code.length > 5) {
      continueHandle();
    }
  }, [code]);

  return (
    <S.Container>
      <Title title='OTP Code' />
      <S.Wrapper>
        <Text color={colors.black._60}>
          {/* We have sent OTP Code to phone number + 28 (23) *** *** 10. */}
          We have sent OTP Code to your phone number {`${
            maskedPhone || ''
          }`}{' '}
          and email {`${maskedEmail || ''}. `}
          Enter this code to verify your identity.
        </Text>
        <S.OtpWrapper>
          <OtpInput
            secure
            codeLength={6}
            value={code}
            loading={loading}
            onCodeChange={setCode}
          />
        </S.OtpWrapper>
        <S.TextContainer>
          <Text color={colors.black._100} size={5}>
            <span
              style={{
                opacity: reSend ? 1 : 0.3,
                cursor: !reSend ? 'default' : 'pointer',
              }}
              onClick={resendOtp}
              aria-hidden='true'
            >
              Resend code
            </span>
            ({countDown})
          </Text>
        </S.TextContainer>
      </S.Wrapper>
    </S.Container>
  );
};

export default CommonOtp;
