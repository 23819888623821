/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { Text } from '../../components/Text';
import { ActiveBusinessInterface } from '../../services/internal/payments/IPayments';
import { colors } from '../../styles/variables';
import * as S from '../Welcome/styles';
export interface WelcomesProps {
  currentCompany: ActiveBusinessInterface;
  closeModal: any;
}

const WelcomeNotApproved: React.FC<WelcomesProps> = ({
  currentCompany,
  closeModal,
}) => {
  const close = () => {
    closeModal();
  };
  return (
    <S.Wrapper>
      <S.Container>
        <S.Padding>
          <S.Main>
            <Text
              size={3}
              style={{ fontWeight: 600, fontSize: 18 }}
              color={colors.red._90}
              bold
            >
              Account Status: Post No Debit
            </Text>
          </S.Main>
          <S.Margin>
            <Text size={5} style={{ fontWeight: 700 }} color={colors.black._20}>
              Your account will become operational on VBiz upon fulfilling the
              following requirements.
            </Text>
            <S.List>
              <li>Selected company management has consented.</li>
              <li>Your account is funded with ₦10,050 for Corporate Search.</li>
              <li>
                Selected referees have completed and submitted reference forms.
              </li>
            </S.List>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                alignItems: 'center',
              }}
            >
              <Icon color={colors.amber._90} name='info' />
              <Text
                style={{ textAlign: 'left', fontSize: 12 }}
                size={5}
                color={'#000000'}
              >
                Please note that these are prerequisites for approval from the
                bank.
              </Text>
            </div>
          </S.Margin>
          <S.Button>
            <Button label='OK' size='medium' onClick={close} />
          </S.Button>
        </S.Padding>
      </S.Container>
    </S.Wrapper>
  );
};

export default WelcomeNotApproved;