import styled from 'styled-components';

import { SearchInputProps } from '.';
import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SearchInput = styled.input<SearchInputProps>`
  width: inherit;
  box-sizing: border-box;
  border:solid 1px ${colors.black._10};
  border-radius: 8px;
  height: ${ptr(50)};
  padding: 0px 20px;
  color: ${colors.black._100};

::placeholder {
  color:${colors.black._40};
}
`;

const Form = styled.form`
width: inherit;
`;

const IconContainer = styled.div`
  position: absolute;
  top:${ptr(13)};
  right: 15px;
  bottom: 0;
`;

export {
  Wrapper,
  SearchInput,
  IconContainer,
  Form,
};
