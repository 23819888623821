import { IBeneficiaries } from '../../pages/BeneficiariesManagement/view';
import { ActionInterface } from '../IAction';
import { CLEAR, MAKE_TRANSFER, REMOVE_SAVED } from './action';
import BeneficiaryInterface from './IBeneficiary';

const INITIAL_STATE = {
  transfer: [] as Array<IBeneficiaries>,
};

const reducer = (state = INITIAL_STATE,
  action: ActionInterface<unknown>):BeneficiaryInterface => {
  switch (action.type) {
    case MAKE_TRANSFER:
      if (action.payload) {
        return { ...state, transfer: action.payload as Array<IBeneficiaries> };
      }
      return state;

    case REMOVE_SAVED:
      if (action.payload) {
        return { ...state, transfer: state.transfer.filter((tf) => tf.nuban !== action.payload) };
      }
      return state;

    case CLEAR:
      if (state.transfer && state.transfer.length) {
        return { ...state, transfer: [] };
      }
      return state;

    default:
      return state;
  }
};

export default reducer;
