import styled from "styled-components";
import { ptr } from "../../../../styles/utils";
import { colors } from "../../../../styles/variables";

const Container = styled.div``;

const TopText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${ptr(25)};
`;

const PersonalInfoWrapper = styled.div`
  margin-bottom: ${ptr(16)};

  > div {
  }
`;
const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  flex-wrap: wrap;
  gap: ${ptr(12)};
`;

const PersonalInfo = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  background: pink;
  justify-content: space-between;
`;

const Left = styled.div`
  tr {
    background: purple;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 16px;
    font-weight: 700;
    color: ${colors.black._100};
  }
`;

const EditSection = styled.div`
gap:4px;
 display:flex;
 cursor:pointer;
 align-items:center;
 justify-content:flex-end;
`;

const Right = styled.div`
  tr {
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 16px;
    margin-bottom: -46px;
    padding-bottom: -80px;
    color: ${colors.black._100};
  }
`;

const LoginInfoWrapper = styled.div`
  margin-bottom: ${ptr(16)};
`;

const CompanyInfoWrapper = styled.div`
  margin-bottom: ${ptr(16)};
`;

const ReferenceInfoWrapper = styled.div`
  margin-bottom: ${ptr(16)};
`;

const CustomTable = styled.table`
  margin-top: ${ptr(16)};
  width: 100%;
  border-bottom: 4px solid #DBDFE4;
   padding-bottom:40px;

   :last-child{
    border-bottom:none;
    padding-bottom:0px;
}
  th,
  td {
    border: none;
    border-collapse: collapse;
  }
  th,
  td {
    margin-bottom: ${ptr(16)};

    text-align: left;
  }
  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${ptr(8)};
  }

  tr:nth-child(1) {
    text-align: right;
  }
`;

const BottomWrapper = styled.div``;

const TinyButton = styled.div`
  margin-bottom: ${ptr(67.89)};
  button {
    height: 36px;
  }
`;

// const ButtonGroup = styled.div`
//   margin-top: ${ptr(16)};
//   width: 100%;
//   > :first-child {
//   }

//   button {
//     width: 100%;
//   }
// `;

const ButtonGroup= styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
align-items:center;
display:flex;
padding-top:14px;
margin-top:20px; 
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  background:#B10180;
  display:flex; 
    width:80%;
}
`;

const TermsText = styled.div`
  display: flex;
  gap: 16px;
`;

const TermsAndConditions = styled.div` 
  display: flex;
  align-items:center;

 
  a {
    color: '#B10180';
  }
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const TermsLink = styled.a`
color: ${colors.pink._100};
`

export {
  Container,
  TopText,
  ItemRow,
  EditSection,
  PersonalInfoWrapper,
  CustomTable,
  LoginInfoWrapper,
  CompanyInfoWrapper,
  ReferenceInfoWrapper,
  BottomWrapper,
  TinyButton,
  ButtonGroup,
  TermsText,
  TermsAndConditions, 
  PersonalInfo,
  Left,
  Right,
  Capitalize,
  TermsLink
};
