import styled, { css } from "styled-components";
import { colors, fonts, transition } from "../../styles/variables";
import { OnboardModalProps } from "./index";
import { crossBrowserTransition, ptr } from "../../styles/utils";

const Container = styled.div<OnboardModalProps>`
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  ${crossBrowserTransition(transition.slow)}
  ${(props) => css`
    display: ${props.showModal ? "flex" : "none"};
  `}
`;
const Wrapper = styled.div<OnboardModalProps>`
  background-color: ${colors.white._100};
  border-radius: ${ptr(12)};
  position: relative;
  max-height: 95%;
  
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth ? props.maxWidth : "90%"};
    `} 

  /* overflow: hidden; */

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}

  ${(props) =>
    props.overflowY &&
    css`
      overflow-y: ${props.overflowY || "visible"};
    `}

  ${(props) =>
    props.overflowX &&
    css`
      overflow-x: ${props.overflowX || "hidden"};
    `}
  /* ::-webkit-scrollbar {
  display: none;
}
 -ms-overflow-style: none;
  scrollbar-width: none; */
  
  padding: ${ptr(0)} ${ptr(18)};
`;


const BackButton = styled.div<{ regularFont?: boolean }>`
display: flex;
justify-content: space-between;
align-items: center;
font-family:  ${fonts.inter.bold};
${(props) => props.regularFont && css`
font-family:  ${fonts.inter.regular};
`}
margin-bottom: ${ptr(8)};
width: 100%;
border-bottom: 1px solid ${colors.black._10};
${(props) => props.regularFont && css`
font-family:  ${fonts.inter.regular};
border-bottom:none;
margin-bottom: 30px;
`}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.inter.bold};
  margin-bottom: ${ptr(8)};
  width: 100%;
  border-bottom: 1px solid ${colors.black._10};
`;

const HeaderTitle = styled.h3``;

const CrossWrapper = styled.div<{ background?: string }>`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${colors.white._100};
  ${(props) =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 9999;
`;

const InfoWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  gap: ${ptr(8)};
  background: ${colors.orange._10};
  padding: ${ptr(12)};
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(14)};
  line-height: ${ptr(18)};
  border-radius: 5px;
  border: 1px solid #EAECF0;
  ${(props) => props.backgroundColor && css`
  background: ${props.backgroundColor}
  `}
`;

const ListWrapper = styled.div`
padding-bottom: ${ptr(24)};
font-family: ${fonts.inter.regular};
font-size: ${ptr(12)};

`;

// const ListItem = styled.p<OnboardModalProps>`
// padding: ${ptr(12)};
// margin: ${(props) => props.margin || `${ptr(12)} ${ptr(0)}`};
// background: ${colors.white._50};
// border: 1px solid ${colors.black._1};
// border-radius: 6px;
// `;


const ListItem = styled.div<OnboardModalProps>`
color: #394455;
font-size: 12px;
font-weight: 400;
line-height: 22px;
>span{
  color:#DB9308;
}
::before {
  content: '✓';
  font-weight: 700;
  margin-right: ${ptr(10)};
}
`;

const ButtonsWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: ${ptr(30)};
padding: ${ptr(12)} ${ptr(24)};
background: ${colors.black._5};
font-family: ${fonts.inter.regular};
margin-bottom: ${ptr(24)};
`;

const Button = styled.button<{ background?: string,hover:string }>`
width: 100%;
height: ${ptr(46)};
border-radius: ${ptr(6)};
color: ${colors.white._100};
${(props) =>
  props.background &&
  css`
  background: ${props.background};
  border: 1px solid ${props.background};
  &:hover {
    background-color: ${props.hover};
  }
  `}
`

const BottomDiv = styled.div<{ justifyContent?: string }>`
  height:60px;
  display: flex;
  align-items: center;
 
  border-bottom: dashed 2px rgba(110, 113, 145, 0.42);
  padding-bottom:20px;
  ${(props) => props.justifyContent && css`
    justify-content: ${props.justifyContent};
  `}
`

const MidTitle = styled.h6`
  color:  #394455;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  font-family: ${fonts.inter.regular};
  margin-top:20px;
  margin-bottom:8px;

  >span {
    text-transform: lowercase;
  }
`

export {
  Container,
  BackButton,
  Wrapper,
  Header,
  MidTitle,
  BottomDiv,
  HeaderTitle,
  CrossWrapper,
  InfoWrapper,
  ListWrapper,
  ListItem,
  ButtonsWrapper,
  Button
};
