import React from 'react';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';

import * as S from './styles';
import { TextInput } from '../../../../components/TextInput';
import { DropdownInput } from '../../../../components/DropdownInput';

export interface SimpleFormProps {
  name: string,
  email: string,
  role: string,
  index?: number,
  removeFromIndex?: number,
  emailIsValid?: boolean,
  remove?: ((index: number) => void),
  onChange?: ((index: number, property: string, value: string) => void),
  title?: string,
}

export const CorporateForm: React.FC<SimpleFormProps> = ({
  name,
  email,
  index,
  removeFromIndex = 0,
  emailIsValid,
  title,
  remove = () => {},
  onChange = () => {},
}) => (
  <S.ItemContainer>
    <S.HeaderRow>
      <Text bold color={colors.black._100}>{title}</Text>
      {index && index > removeFromIndex ? (
        <S.Remove onClick={() => remove(index)}>
          <Icon name="trash" color={colors.pink._100} />
        </S.Remove>
      ) : null}
    </S.HeaderRow>
    <S.ItemRow>
      <TextInput label="Name" value={name} onChange={(value) => onChange(index as number, 'name', value)} />
    </S.ItemRow>
    <S.ItemRow>
      <TextInput label="Email" value={email} onChange={(value) => onChange(index as number, 'email', value)} valid={emailIsValid} />
    </S.ItemRow>
    <S.ItemRow>
      <DropdownInput
        onSelect={(value) => onChange(index as number, 'role', value?.value || '')}
        options={[
          {
            label: 'Viewer',
            value: 'Viewer',
          },
          {
            label: 'Initiator',
            value: 'Initiator',
          },
          {
            label: 'Authoriser',
            value: 'Authoriser',
          },
        ]}
      />
    </S.ItemRow>

  </S.ItemContainer>
);
