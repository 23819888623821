import { FC, useState } from 'react';
import * as S from './styles';
import SuccessImg from '../../../assets/images/success.gif';

import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import userService from '../../../services/internal/user/user.service';
import { userSuccess } from '../../../redux/current-user/actions';
import { useDispatch } from 'react-redux';
import { colors, fonts } from '../../../styles/variables';

interface IProps {
  toggle: () => void;
  fromRoute?: string;
}
const Success: FC<IProps> = ({ toggle, fromRoute }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const updateUser = async () => {
    setLoading(true);
    try {
      const user = await userService.getCurrentUserData();
      if (user) dispatch(userSuccess(user));
      toggle();
      history.push(fromRoute ?? '/dashboard');
    } catch (error) {
      window.location.href = fromRoute ? fromRoute : '/dashboard';
    }
    setLoading(false);
  };

  return (
    <S.SuccessContainer>
      <img src={SuccessImg} alt="success" width={84} height={84} />

      <Text bold size={2} color="#2C2A3B">
        2FA Verification successful
      </Text>

      <Text size={4} color="#2C2A3B" style={{ lineHeight: '30px' }}>
        In case you lost your device and you need to reset your 2FA on another
        device, kindly reach out to support via <br />
        <span
          style={{
            color: colors.pink._5,

            fontFamily: fonts.inter.bold,
          }}
        >
          support@vbiz.ng
        </span>{' '}
      </Text>

      <S.SuccessButtonContainer>
        <Button
          style={{ width: '100%' }}
          label="Got it"
          onClick={updateUser}
          loading={loading}
          disabled={loading}
        />
      </S.SuccessButtonContainer>
    </S.SuccessContainer>
  );
};

export default Success;
