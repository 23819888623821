import styled, { css } from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../styles/utils';
import { fonts, breakpoints, colors, transition } from '../../../styles/variables';

const Container = styled.div` 
 
`;

const TopTitle = styled.div`
/* border-bottom: 1px solid #E9EBF1; */
 padding-bottom: ${ptr(24)}; 
`;

const Wrapper = styled.div<any>`
margin-top: ${ptr(34)};
`;

const ItemRow = styled.div`
margin-top: ${ptr(16)};
margin-bottom: ${ptr(12)};
display: flex;
flex-wrap: wrap;
gap: ${ptr(12)};
`;

const ButtonGroup = styled.div` 
  display:flex;
  gap: ${ptr(10)};
  margin-top: ${ptr(23)};
  justify-content:flex-end;  

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center; 
  }

border-top: 4px solid #DBDFE4;
padding-top:14px;
/* 
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right; */

`;

const InfoWrapper = styled.div`
display: flex;
gap: ${ptr(8)};
// flex-wrap:wrap;
align-items:center;
border-radius: 4px;
background: #F7F7FC;
padding: ${ptr(10)};
font-family: ${fonts.inter.regular};
font-size: ${ptr(14)};
margin-top: -4px;
`;



const TableAlign2 = styled.div`
display:flex;
align-items:center;
margin-top:15px;
border-radius:8px;
 height:43px;
 gap:14px;
width:fit-content; 
@media screen and (max-width: ${breakpoints.sm}px) {  
  padding:10px 0px;
  display: flex;
  flex-direction:column;
  height:fit-content;
  gap:20px
}
`;

const Tab1 = styled.div`
padding:8px;
max-width:fit-content;
border-radius: 8px;
display:flex;
gap:8px;
align-items:center; 
 
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
 > span{
 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media screen and (max-width: ${breakpoints.sm}px) { 
   width:100% 
  }
`;

const TypeTabs = styled.div`
margin-bottom:14px;
`;
const MarginLeftComp1 = styled.div`
padding:8px;
max-width:fit-content;
border-radius: 8px;
display:flex;
align-items:center; 
 
gap:8px;
 > span{
border-radius: 6px;
 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }
:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media screen and (max-width: ${breakpoints.sm}px) { 
    width:100% 
    margin-left:0px;
  
    > span{ 
       
      width:100%; 
        }
   }
`;
const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(18)}; 
  height: ${ptr(18)};
  border-radius: ${ptr(12)};
 border: ${ptr(1)} #B10180 solid;
 
  ${crossBrowserTransition(`${transition.slow}`)}
  
`;

const CheckCircle = styled.div`
display: flex; 
width:100%;
justify-content: center;
align-items: center; 
  
margin-bottom:30px; 

`;



const InsideCircle = styled.div`
  width: ${ptr(10)};
  height: ${ptr(10)};
  border-radius: ${ptr(6)};
background-color: #B10180
 
`;



const TextDrag = styled.div` 
white-space:nowrap; 
display: flex;
align-items: center;
flex-direction: column;  
justify-content:center;
padding:4px;
`;

const SigningOptionsDiv = styled.div` 
 margin-top:5px;
`;

const UploadDiv = styled.div` 
border-radius: 8px;
border: 1px dashed var(--grayscale-placeholder, #A0A3BD);
display: flex;
 
padding: 16px 40px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 14px;
`;

const Lists = styled.div` 
 margin: 14px 0px;
 display:flex;
 flex-direction:column;
 gap:2px;
`;

const InfoComponent = styled.div` 
 width: 64%;
 @media (max-width: ${breakpoints.sm}px) {
  width: 100%;
}
`;


export {
  Container,
  TopTitle,
  Wrapper,
  ItemRow,
  ButtonGroup,
  InfoWrapper,
  Tab1,
  Lists,
  TextDrag,
  UploadDiv,
  InfoComponent,
  SigningOptionsDiv,
  TableAlign2, TypeTabs, MarginLeftComp1, Circle, InsideCircle, CheckCircle,
};
