import React from 'react';

import * as S from './styles';
import { CSSProperties } from 'styled-components';

export type TextSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export interface TextProps {
  /**
   * Tag of the text
   */
  tag?: 'span' | 'h';
  /**
   * Size from 1 to 8
   */
  size?: TextSize;
  /**
   * Is text bold
   */
  bold?: boolean;
  /**
   * Custom color
   */
  color?: string;

  background?: string;
  /**
   * Any children
   */
  children?: JSX.Element | string | number | any;

  onClick?: () => void;

  style?:React.CSSProperties;
}

const TextComponent: React.FC<TextProps> = ({ children = null, ...props }) => {
  return (
    <S.StyledText
      {...props}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {children || ''}
    </S.StyledText>
  );
};

export const Text = React.memo(TextComponent);
