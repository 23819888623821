import React from 'react';
import { Preload } from '../../components/Preload';

import * as S from './styles';

type Props = { fade?: boolean };

export const Splash: React.FC<Props> = (props) => (props.fade ? (
  <S.Wrapper>
    <Preload />
  </S.Wrapper>
) : (
  <S.Container>
    <Preload />
  </S.Container>
));
