/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as S from './styles';
import { BackBar } from '../../components/BackBar';
import { TableGenerator } from '../../components/TableGenerator';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { FileUpload } from '../../components/FileUpload';
import { colors } from '../../styles/variables';
import paymentsService from '../../services/internal/payments/payments.service';
import { failed, success } from '../../components/Toasts';
import debug from '../../services/internal/debbug.service';
import userService from '../../services/internal/user/user.service';
import { ActiveBusinessInterface } from '../../services/internal/payments/IPayments';

export const CompanyDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [donotShow, setDonotShow] = useState(true);
  const [ShowUploadscreen, setShowUploadscreen] = useState(false);
  const [filetoUpload, setfiletoUpload] = useState('');
  const [docType, setdocType] = useState('');
  const [doctoUpload, setDoctoUpload] = useState('');
  const [UploadingFileLoader, setUploadingFileLoader] = useState(false);

  const [data, setData] = useState<Array<{ title: string; description: string }>>([]);
  const [business, setBusiness] = useState<ActiveBusinessInterface>();

  const getBusiness = async () => {
    try {
      const response = await paymentsService.getSingleBusiness(id);
      setBusiness(response);
      const companyData = [
        {
          title: 'Business owner',
          description: response.name || '-',
        },
        { title: 'Business Type', description: response.accounts[0].type?.toUpperCase() || 'NOT SET' },
        { title: 'BVN', description: response.bvn?.toUpperCase() || 'NOT SET' },
        { title: 'Phone Number', description: response.phone?.toUpperCase() || 'NOT SET' },
        { title: 'Email', description: response.primaryEmail?.toUpperCase() || 'NOT SET' },
        { title: 'Staff Referral ID', description: response.referralCode?.toUpperCase() || 'NOT SET' },
        { title: 'KYC Level', description: response.kycLevel || '-' },
      ];
      setData(companyData);
    } catch (error) {
      failed('Failed', 'Unable to get business details');
    }
  };

  useEffect(() => {
    setDonotShow(true);
    getBusiness();
  }, []);
  const toggleUploadDocument = (status = true, currentdoc = '', doctype = '') => {
    setShowUploadscreen(status);
    setDoctoUpload(currentdoc);
    setdocType(doctype);
  };
  const onChangeValue = (value: any) => {
    setfiletoUpload(value);
  };
  const uploadDocument = async () => {
    if (filetoUpload) {
      setUploadingFileLoader(true);
      const bodydata = new FormData();
      bodydata.append('type', docType);
      bodydata.append('document', filetoUpload);
      try {
        await userService.uploadUserDocument(bodydata);
        setUploadingFileLoader(false);
        success('Successfull', 'File successfully uploaded');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        setUploadingFileLoader(false);

        debug.error('Failed to send file', err);
        failed('Fail', 'Fail to upload file');
      }
    }
  };

  return (
    <>
      {
        ShowUploadscreen === false
          ? (
            <>
              <S.CompanyDetailsContainer>
                <BackBar name="Company info" />

                <S.DetailsContainer>
                  <TableGenerator data={data} />
                </S.DetailsContainer>
              </S.CompanyDetailsContainer>
              <S.Container>
                <S.TitleContainer>
                  <Text size={4} bold>Documents</Text>

                </S.TitleContainer>
                <S.ResponsiveContainer>
                  {
                    business && !business?.cacUrl
                      ? (
                        <S.DivWrapper onClick={() => toggleUploadDocument(true,
                          ' Certificate of Incorporation', 'cac')}
                        >
                          <S.MarginBottom>
                            <S.DisplayContainer>
                              <S.DisplayContainer>
                                <S.MarginRight>
                                  <Text size={6} bold>
                                    <Icon name="fileicon" />

                                  </Text>
                                </S.MarginRight>
                                <S.DisplayColumnContainer>
                                  <S.MinMarginBottom>
                                    <Text
                                      size={5}
                                      bold
                                      color={colors.black._100}
                                    >
                                      Upload your Certificate of Incorporation

                                    </Text>
                                  </S.MinMarginBottom>
                                  <Text
                                    size={7}
                                    color={colors.black._100}
                                  >
                                    Click to upload a document

                                  </Text>
                                </S.DisplayColumnContainer>

                              </S.DisplayContainer>
                              <S.Container>
                                <Icon name="arrow-right" />
                              </S.Container>
                            </S.DisplayContainer>
                          </S.MarginBottom>
                        </S.DivWrapper>
                      )

                      : (
                        <S.DivWrapper>
                          <S.MarginBottom>
                            <S.DisplayContainer>
                              <S.DisplayContainer>
                                <S.MarginRight>
                                  <Text size={6} bold>
                                    <Icon name="blackandwhite-file" />

                                  </Text>
                                </S.MarginRight>
                                <S.DisplayColumnContainer>
                                  <S.MinMarginBottom>
                                    <Text size={5} bold>Certificate of Incorporation.pdf</Text>
                                  </S.MinMarginBottom>
                                  <S.rowWrapper>

                                    <S.textwrap onClick={() => window.open(business?.cacUrl, '_blank')}>
                                      <Text size={6} color={colors.pink._100}>View</Text>

                                    </S.textwrap>
                                    <S.textwrap onClick={() => toggleUploadDocument(true,
                                      ' Certificate of Incorporation', 'cac')}
                                    >
                                      <Text
                                        size={6}
                                        color={colors.pink._100}
                                      >
                                        {' '}
                                        Update

                                      </Text>
                                    </S.textwrap>
                                  </S.rowWrapper>

                                </S.DisplayColumnContainer>

                              </S.DisplayContainer>

                            </S.DisplayContainer>
                          </S.MarginBottom>
                        </S.DivWrapper>
                      )
                  }

                  <S.HrMargin>
                    <hr />
                  </S.HrMargin>
                  {
                    business && !business?.memartUrl

                      ? (
                        <S.DivWrapper onClick={() => toggleUploadDocument(true, 'MEMART (Memorandum and Articles of Association)', 'memart')}>
                          <S.MarginBottom>
                            <S.DisplayContainer>
                              <S.DisplayContainer>
                                <S.MarginRight>
                                  <Text size={6} bold>
                                    <Icon name="fileicon" />

                                  </Text>
                                </S.MarginRight>
                                <S.DisplayColumnContainer>
                                  <S.MinMarginBottom>
                                    <Text size={5} bold>
                                      Upload MEMART (Memorandum and Articles
                                      of Association)

                                    </Text>
                                  </S.MinMarginBottom>
                                  <Text
                                    size={7}
                                    color={colors.black._60}
                                  >
                                    Click to upload a document

                                  </Text>
                                </S.DisplayColumnContainer>

                              </S.DisplayContainer>
                              <S.Container>
                                <Icon name="arrow-right" />
                              </S.Container>
                            </S.DisplayContainer>
                          </S.MarginBottom>
                        </S.DivWrapper>
                      )
                      : (
                        <S.DivWrapper>
                          <S.MarginBottom>
                            <S.DisplayContainer>
                              <S.DisplayContainer>
                                <S.MarginRight>
                                  <Text size={6} bold>
                                    <Icon name="blackandwhite-file" />

                                  </Text>
                                </S.MarginRight>
                                <S.DisplayColumnContainer>
                                  <S.MinMarginBottom>
                                    <Text size={5} bold>
                                      MEMART (Memorandum and Articles
                                      of Association)
                                    </Text>
                                  </S.MinMarginBottom>
                                  <S.rowWrapper>
                                    <S.textwrap onClick={() => window.open(business?.memartUrl, '_blank')}>
                                      <Text size={6} color={colors.pink._100}>View</Text>

                                    </S.textwrap>
                                    <S.textwrap onClick={() => toggleUploadDocument(true, 'MEMART (Memorandum and Articles of Association)', 'memart')}>
                                      <Text
                                        size={6}
                                        color={colors.pink._100}
                                      >
                                        {' '}
                                        Update

                                      </Text>
                                    </S.textwrap>
                                  </S.rowWrapper>

                                </S.DisplayColumnContainer>

                              </S.DisplayContainer>

                            </S.DisplayContainer>
                          </S.MarginBottom>
                        </S.DivWrapper>
                      )
                  }

                </S.ResponsiveContainer>
              </S.Container>
            </>

          )
          : (
            <S.Container>

              <S.ResponsiveContainer>
                <S.IconWrapper onClick={() => toggleUploadDocument(false)}>
                  <Icon name="cancel-black" />
                </S.IconWrapper>
                <S.UploadPaddingContainer>
                  <S.UploadMarginContainer>

                    <S.DisplayColumnContainer>
                      <S.MarginBottom>
                        <Text size={3} bold>
                          Document upload

                        </Text>
                      </S.MarginBottom>
                      <Text size={6} color={colors.black._60}>
                        Upload your official

                        <Text size={6} color={colors.black._100} bold>
                          <span>
                            {' '}
                            {doctoUpload}
                          </span>
                        </Text>
                      </Text>
                    </S.DisplayColumnContainer>

                  </S.UploadMarginContainer>
                  <S.Container>
                    <S.Container>
                      <div>

                        <FileUpload
                          acceptedFormats={['.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.pdf']}
                          onChange={(file) => onChangeValue(file)}
                        />
                      </div>
                    </S.Container>
                    <S.HrMargin>
                      <Text size={6} color={colors.black._40}>
                        Supporting file type: pdf or *.docx only
                      </Text>
                    </S.HrMargin>
                    <S.ButtonWidthContainer>
                      <Button
                        label={UploadingFileLoader ? 'Uploading...' : 'Add'}
                        size="medium"
                        color="white"
                        onClick={uploadDocument}
                      />
                    </S.ButtonWidthContainer>
                  </S.Container>
                </S.UploadPaddingContainer>
              </S.ResponsiveContainer>
              {/*  */}
            </S.Container>
          )

      }

      <S.CompanyDetailsContainer />
    </>
  );
};
