import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../../../components/Icon';
import { Text } from '../../../../components/Text';
import { Button } from '../../../../components/Button';
import { formatCash } from '../../../../utils/money';
import { colors } from '../../../../styles/variables';
import * as S from './styles';
import { ModalWrapper } from '../../../../components/ModalWrapper';
import { RequestAccountStatement } from '../RequestAccountStatement';
import { Modal } from '../../../../components/SimpleModal';
import { transactionDetailsRoute } from '../../../../navigation';

export interface ItemProps {
  title: string;
  value: string;
  sub: string;
  subtext: string;
  income?: boolean;
}

export interface AccountItemProps {
  balance: string;
  nuban: string;
  type: string;
  isChecked?: boolean;
  setIsChecked?: (value: boolean) => void;
  theme?: 'dark' | 'light';
}

export interface CompanyReportHeadProps {
  balance: string;
  nuban: string;
  accountId?: string;
  outflow: { balance: number; debitCount: number; sub: string };
  inflow: { balance: number; creditCount: number; sub: string };
  open?: boolean;
  onDropdown?: () => void;
  accounts: Array<AccountItemProps>;
  od?: boolean;
  odLimit?: number;
  openStatement?: boolean;
}

const Item: React.FC<ItemProps> = ({
  income = false,
  title = '',
  sub = '',
  subtext = '',
  value = '',
}) => (
  <S.ItemBox>
    <S.Icon income={income}>
      <Icon name='income' color={colors.black._100} />
    </S.Icon>
    <S.Article>
      <Text color={colors.black._60} size={7}>
        {title}
      </Text>
      <S.Data>
        {value ? (
          <Text size={4} bold color={colors.black._100}>
            {value}
          </Text>
        ) : null}
        <span>
          {sub ? <Text size={7}>{sub}</Text> : null}
          <S.SpanSpace />
          {subtext ? (
            <Text size={7} color={colors.black._60}>
              {subtext}
            </Text>
          ) : null}
        </span>
      </S.Data>
    </S.Article>
  </S.ItemBox>
);

const Item2: React.FC<ItemProps> = ({
  income = false,
  title = '',
  sub = '',
  subtext = '',
  value = '',
}) => (
  <S.ItemBox>
    <S.Icon income={income}>
      <Icon name='expense' color={colors.black._100} />
    </S.Icon>
    <S.Article>
      <Text color={colors.black._60} size={7}>
        {title}
      </Text>
      <S.Data>
        {value ? (
          <Text size={4} bold color={colors.black._100}>
            {value}
          </Text>
        ) : null}
        <span>
          {sub ? <Text size={7}>{sub}</Text> : null}
          <S.SpanSpace />
          {subtext ? (
            <Text size={7} color={colors.black._60}>
              {subtext}
            </Text>
          ) : null}
        </span>
      </S.Data>
    </S.Article>
  </S.ItemBox>
);

const Item3: React.FC<ItemProps> = ({
  income = false,
  title = '',
  sub = '',
  subtext = '',
  value = '',
}) => (
  <S.ItemBox>
    <S.Icon income={income}>
      <Icon name='value' color={colors.black._100} />
    </S.Icon>
    <S.Article>
      <Text color={colors.black._60} size={7}>
        {title}
      </Text>
      <S.Data>
        {value ? (
          <Text size={4} bold color={colors.black._100}>
            {value}
          </Text>
        ) : null}
        <span>
          {sub ? <Text size={7}>{sub}</Text> : null}
          <S.SpanSpace />
          {subtext ? (
            <Text size={7} color={colors.black._60}>
              {subtext}
            </Text>
          ) : null}
        </span>
      </S.Data>
    </S.Article>
  </S.ItemBox>
);

const Item4: React.FC<ItemProps> = ({
  income = false,
  title = '',
  sub = '',
  subtext = '',
  value = '',
}) => (
  <S.ItemBox>
    <S.Icon income={income}>
      <Icon name='pie' color={colors.black._100} />
    </S.Icon>
    <S.Article>
      <Text color={colors.black._60} size={7}>
        {title}
      </Text>
      <S.Data>
        {value ? (
          <Text size={4} bold color={colors.black._100}>
            {value}
          </Text>
        ) : null}
        <span>
          {sub ? <Text size={7}>{sub}</Text> : null}
          <S.SpanSpace />
          {subtext ? (
            <Text size={7} color={colors.black._60}>
              {subtext}
            </Text>
          ) : null}
        </span>
      </S.Data>
    </S.Article>
  </S.ItemBox>
);

const AccountItem: React.FC<AccountItemProps> = ({
  balance = '1000',
  nuban = '01111111111',
  type = 'corporate',
  isChecked = false,
  theme = 'dark',
  setIsChecked = () => {},
}) => (
  <S.AccountItemBox
    onClick={() => {
      setIsChecked(!isChecked);
    }}
  >
    <S.CircleBlock>
      <S.Circle theme={theme} checked={isChecked}>
        {isChecked && <S.InsideCircle theme={theme} />}
      </S.Circle>
    </S.CircleBlock>

    <S.AccountItemInfoBlock>
      <div>
        <Text color={colors.black._100}>{nuban}</Text>
      </div>
      {!!type && (
        <div>
          <Text color={colors.black._60}>{type}</Text>
        </div>
      )}
    </S.AccountItemInfoBlock>

    <S.AccountItemBalanceBlock>
      <Text color={colors.black._60}>{`₦ ${formatCash(balance)}`}</Text>
    </S.AccountItemBalanceBlock>
  </S.AccountItemBox>
);
export const CompanyReportHead: React.FC<CompanyReportHeadProps> = ({
  accounts = [],
  accountId,
  balance,
  nuban,
  outflow,
  inflow,
  open = false,
  od,
  odLimit,
  onDropdown = () => {},
  openStatement = false,
}) => {
        const [showmodalView, setshowmodalView] = useState<boolean>(
          openStatement ?? false
        );
        const [showModal, setShowModal] = useState(false);
        const history = useHistory();

        const closeModal = () => {
          setShowModal(false);
        };

        const openSuccessModal = () => {
          setShowModal(true);
        };

        const renderModalView = () => (
          <ModalWrapper
            overflowY='auto'
            overflowX='inherit'
            maxHeight='96vh'
            showModal={showmodalView}
            onCloseModal={() => setshowmodalView(false)}
            crossColor={colors.black._100}
          >
            <RequestAccountStatement
              openSuccessModal={openSuccessModal}
              onCloseModal={() => setshowmodalView(false)}
              accounts={accounts}
            />
          </ModalWrapper>
        );

        return (
          <S.Container>
            {renderModalView()}
            <S.Wrapper>
              <S.Content>
                <S.Company>
                  <S.Balance onClick={onDropdown} open={open}>
                    <Text
                      bold
                      color={colors.black._100}
                      size={2}
                    >{`₦ ${formatCash(balance)}`}</Text>
                    <Icon
                      name='arrow-left'
                      color={colors.pink._100}
                      width={12}
                      height={12}
                    />
                  </S.Balance>
                  <S.Account open={open}>
                    <Text color={colors.black._100} size={7}>
                      Account:{' '}
                    </Text>
                    <Text color={colors.black._100} bold size={7}>
                      {nuban}
                    </Text>
                  </S.Account>
                  <S.DropownContent open={open}>
                    {accounts.map((item) => (
                      <AccountItem
                        balance={item.balance}
                        nuban={item.nuban}
                        type={item.type}
                        isChecked={item.isChecked}
                        setIsChecked={item.setIsChecked}
                      />
                    ))}
                  </S.DropownContent>

                  <S.Calendar open={open} />
                  {od && odLimit && (
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <Text bold color={colors.black._100} size={5}>
                        Overdraft Limit:{' '}
                      </Text>
                      <Text
                        bold
                        color={colors.black._100}
                        size={6}
                      >{`₦ ${formatCash(odLimit)}`}</Text>
                    </div>
                  )}
                </S.Company>
                <S.AddNew>
                  <Button
                    onClick={() => setshowmodalView(true)}
                    label='Request account statement'
                    size='tiny'
                  />
                </S.AddNew>
                <S.AddNew2>
                  <Button
                    onClick={() =>
                      history.push(transactionDetailsRoute, { accountId })
                    }
                    backgroundColor={colors.orange._100}
                    label='Transaction Details'
                    size='tiny'
                  />
                </S.AddNew2>
              </S.Content>

              <S.BottomContainer open={open}>
                <S.ItemWrapper>
                  <S.ItemContainer>
                    <Item
                      income
                      title='Total Credits'
                      value={`₦ ${
                        formatCash(inflow.balance) || inflow.balance
                      }`}
                      sub=''
                      subtext=''
                    />
                  </S.ItemContainer>
                  <Item2
                    title='Total Debits'
                    value={`₦ ${
                      formatCash(outflow.balance) || outflow.balance
                    }`}
                    sub=''
                    subtext=''
                  />
                </S.ItemWrapper>
                <S.BorderStyle />
                <S.ItemWrapper>
                  <S.ItemContainer>
                    <Item3
                      title='Total Turn Over'
                      value={`${formatCash(inflow.balance + outflow.balance)}`}
                      sub=''
                      subtext='1 year'
                    />
                  </S.ItemContainer>
                  <Item4
                    title='Transaction Count'
                    value={`${inflow.creditCount}`}
                    sub=''
                    subtext='1 year'
                  />
                </S.ItemWrapper>
              </S.BottomContainer>
            </S.Wrapper>

            <Modal
              visible={showModal}
              title='Done!'
              description='Your statement is on its way to your email'
              onCloseModal={closeModal}
              action={closeModal}
            />
          </S.Container>
        );
      };
