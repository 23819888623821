import styled, { css } from 'styled-components';

import { crossBrowserTransition, ptr } from '../../styles/utils';
import { breakpoints, colors, fonts } from '../../styles/variables';

const ContentContainer = styled.div`
  width: ${ptr(600)};
  padding: ${ptr(20)} ${ptr(20)};

  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: solid 1px ${colors.black._1};
  height: ${ptr(56)};
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;
const TextKey = styled.div`
  color: #263238;
  font-size: 16px;
  font-family: ${fonts.inter.regular};
`;

const TextValue = styled.div`
  color: #263238;
  font-size: 16px;
  font-family: ${fonts.inter.bold};
`;
const TextSuccessValue = styled.div`
  color: #34a853;
  font-size: 16px;
  font-family: ${fonts.inter.bold};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  /* flex-wrap: wrap; */
  gap: 30px;
  width: 100%;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    button:nth-child(1) {
      width: 100% !important;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const TransferButton = styled.div`
  width: 70%;
  @media (max-width: ${breakpoints.sm}px) {
    width: 100% !important;
  }
`;

export {
  ContentContainer,
  TextContainer,
  TextKey,
  TextValue,
  TextSuccessValue,
  ButtonContainer,
  InfoContainer,
  TransferButton,
};
