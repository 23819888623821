import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BackBar } from '../../../components/BackBar';
import * as S from './styles';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import { Button } from '../../../components/Button';
import { TextInput } from '../../../components/TextInput';
import { success } from '../../../components/Toasts';
import authService from '../../../services/internal/auth/auth.service';
import { settingsRoute } from '../../../navigation';
import { WarningModal } from '../../../components/WarningModal';
import { ValidateResult, validateObject } from '../../../utils/validation';
import ConfigPassword from '../../../configs/password';
import { Icon } from '../../../components/Icon';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const SCHEME_VALIDATION_PASSWORD = {
  minimumCharacters: (value: string) =>
    value.length >= ConfigPassword.minLength,
  uppercase: (value: string) => ConfigPassword.uppercase.test(value),
  lowercase: (value: string) => ConfigPassword.lowercase.test(value),
  special: (value: string) => ConfigPassword.special.test(value),
  same: (passwords: { pass: string; repeat: string }) =>
    !!passwords.pass &&
    !!passwords.repeat &&
    passwords.pass === passwords.repeat,
};

const Step3: React.FC = () => {
  const route = useHistory();
  const { password } = useLocation<{ password: string }>().state;

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<string>();
  const [apiError, setApiError] = useState<string>();
  const [passValidation, setPassValidation] = useState<ValidateResult>();
  const [token, setToken] = useState('');

  useEffect(() => {
    setPassValidation(
      validateObject(
        {
          minimumCharacters: newPassword,
          uppercase: newPassword,
          lowercase: newPassword,
          special: newPassword,
          same: { newPassword, confirmPassword },
        },
        SCHEME_VALIDATION_PASSWORD
      )
    );
  }, [newPassword, confirmPassword]);

  const handleNewPassword = (value: string) => {
    setNewPassword(value);
  };

  const handleConfirmNewPassword = (value: string) => {
    setConfirmPassword(value);
    if (value !== newPassword) {
      setNewPasswordError('password does not match');
      return;
    }
    setNewPasswordError('');
  };

  const submit = async () => {
    try {
      setLoading(true);
      await authService.updatePassword({
        oldPassword: password,
        newPassword,
        newPasswordConfirmation: confirmPassword,
        otp: token,
      });
      success('Completed!', 'Password updated successfully');
      setLoading(false);

      route.push(settingsRoute);
    } catch (err) {
      const error = err as any;
      setLoading(false);
      setApiError(error.message || 'Can not update password at the moment');
    }
  };

  return (
    <S.Container>
      <BackBar name="Step 2/2  |  Login password" />

      <S.InputContainer>
        <Text color={colors.black._100} bold>
          Create new password
        </Text>
        {apiError && (
          <WarningModal
            title="Invalid password!"
            description={apiError}
            showModal={!!apiError}
            onCloseModal={() => {
              setApiError('');
            }}
          />
        )}
        <S.LoginPasswordInputWrapper>
          <TextInput
            label="New password"
            onChange={handleNewPassword}
            value={newPassword}
            maxLength={ConfigPassword.maxLength}
            type="password"
            valid={
              !!newPassword &&
              passValidation?.data.minimumCharacters?.isValid &&
              passValidation?.data.uppercase?.isValid &&
              passValidation?.data.lowercase?.isValid &&
              passValidation?.data.special?.isValid
            }
          />
        </S.LoginPasswordInputWrapper>

        <S.PasswordDescriptionWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {passValidation?.data.minimumCharacters?.isValid && (
                <Icon name="check-mark" color={colors.green._100} />
              )}
              <Text color={colors.black._60} size={7}>
                At least 8 characters.
              </Text>
            </>
          </S.PasswordDescriptionItemWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {passValidation?.data.uppercase?.isValid && (
                <Icon name="check-mark" color={colors.green._100} />
              )}
              <Text color={colors.black._60} size={7}>
                At least one uppercase is entered.
              </Text>
            </>
          </S.PasswordDescriptionItemWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {passValidation?.data.lowercase?.isValid && (
                <Icon name="check-mark" color={colors.green._100} />
              )}
              <Text color={colors.black._60} size={7}>
                At least one lowercase is entered.
              </Text>
            </>
          </S.PasswordDescriptionItemWrapper>
          <S.PasswordDescriptionItemWrapper>
            <>
              {passValidation?.data.special?.isValid && (
                <Icon name="check-mark" color={colors.green._100} />
              )}
              <Text color={colors.black._60} size={7}>
                At least one special character is entered.
              </Text>
            </>
          </S.PasswordDescriptionItemWrapper>
        </S.PasswordDescriptionWrapper>

        <S.LoginPasswordInputWrapper>
          <TextInput
            invalid={newPasswordError}
            label="New password again"
            onChange={handleConfirmNewPassword}
            // onEnterPressed={function noRefCheck() {}}
            // onIconClick={function noRefCheck() {}}
            type="password"
            valid={!!confirmPassword && passValidation?.data.same?.isValid}
          />
        </S.LoginPasswordInputWrapper>

        <S.LoginPasswordInputWrapper>
          <TextInput
            label="Enter the 6-digit code in your authenticator app"
            value={token}
            onChange={setToken}
            type="password"
            mask={createNumberMask(numberMaskOptions)}
          />
        </S.LoginPasswordInputWrapper>
      </S.InputContainer>

      <Button
        onClick={submit}
        label="Continue"
        disabled={
          !newPassword ||
          !confirmPassword ||
          !!newPasswordError ||
          loading ||
          token?.length !== 6
        }
      />
    </S.Container>
  );
};

export default Step3;
