import React, { useState, useEffect } from 'react';
import { Preload } from '../../../components/Preload';
import * as S from './styles';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { TextInput } from '../../../components/TextInput';
import { DropdownInput } from '../../../components/DropdownInput';
import { InfoForm4 } from '../../Onboarding/components/InfoForm4';
import { MinimizedInfo } from '../../../components/MinimizedInfo';
import { batch, useDispatch, useSelector } from 'react-redux';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { SignUpUboInterface } from '../../../services/internal/onboarding/IOnboarding';
import {
  ValidateArrayResult,
  ValidateResult,
  validateArrayOfObjects,
  validateObject,
} from '../../../utils/validation';
import { useHistory } from 'react-router-dom';
import { idTypes } from '../constants';
import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { actions } from '../config';

const DefaultUboItem = {
  bvn: '',
  idNumber: '',
  idType: '',
  uboType: '',
};

const SCHEME = {
  bvn: (value: string) => value.length === 11,
  idType: (value: string) => value.length > 1,
};

export const Individual: React.FC = () => {
  const ubos = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.ultimateBeneficialOwners as Array<
        SignUpUboInterface
      >
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const [collection, setCollection] = useState<Array<SignUpUboInterface>>(
    ubos?.length
      ? ubos?.map((item) => ({
          bvn: item.bvn,
          idNumber: item.idNumber,
          idType: item.idType,
          uboType: 'individual',
        }))
      : [DefaultUboItem]
  );
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const route = useHistory();
  const [idNumber, setIdNumber] = useState<string>('');
  const [idType, setIdType] = useState<any>();
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();

  useEffect(() => {
    ubos?.forEach((ubo, i) => {
      setTimeout(() => {
        setIdType(ubo.idType);
        setIdNumber(ubo.idNumber || '');
      }, (i + 1) * 1000);
    });
  }, [ubos]);

  const push = () => {
    if (validationCollection?.isValid) {
      const newCollection = collection.map((coll) => {
        coll.isAdded = true;
        coll.isEdit = false;
        return coll;
      });
      setCollection([...newCollection, DefaultUboItem]);
    }
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: SignUpUboInterface, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }

          return el;
        }) as Array<SignUpUboInterface>
    );
  };

  const getIdType = (value: any) => {
    setIdType(value);
  };

  const getIdNumber = (value: any) => {
    setIdNumber(value);
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
    setIdNumberValidation(
      validateObject(
        {
          idType,
          idNumber,
        },
        idNumberScheme
      )
    );
  }, [collection, idType, idNumber]);

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value || idType === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card ||
          idType === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license ||
          idType === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport ||
          idType === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  const editInfo = (index: number) => {
    const newCollection = [...collection];
    newCollection[index].isEdit = true;
    setCollection([...newCollection]);
  };

  const continueHandle = async (extraPayload?: { skip: true }) => {
    setLoading(true);
    const newCollection = collection.map((_collection) => {
      delete _collection.isAdded;
      delete _collection.isEdit;
      _collection.uboType = 'individual';
      return _collection;
    });
    try {
      const response = await onboardingService.submitUboDetails({
        ...(extraPayload?.skip && { skip: extraPayload.skip }),
        ...(!extraPayload?.skip && { ultimateBeneficialOwners: newCollection }),
        reference,
      });

      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            ultimateBeneficialOwners: [...newCollection],
            reference: response.data.reference,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  const renderModalView = () => (
    <>
      <Text bold size={6} color='#394455'>
        Provide individual’s details
      </Text>
      {/* <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
        <div style={{ flex: 1 }}>
          <TextInput
            label='BVN'
            type='text'
            //   value={lastName}
            // value={idNumber}
            // onChange={setIdNumber}
            placeholder='Enter BVN'
          />
        </div>
      </S.ItemRow>
      <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
        <div style={{ flex: 1 }}>
          <DropdownInput label='Valid ID Type' placeholder='Select ID type' />
        </div>

        <div style={{ flex: 1 }}>
          <TextInput
            label='ID Number'
            type='text'
            //   value={lastName}
            // value={idNumber}
            // onChange={setIdNumber}
            placeholder='Enter ID number'
          />
        </div>
      </S.ItemRow> */}
      {collection.map((item, index) => (
        <div style={{ marginBottom: '30px' }}>
          {!item.isAdded || item.isEdit ? (
            <InfoForm4
              bvn={item.bvn}
              idNumber={item.idNumber}
              idType={item.idType}
              onSelectId={getIdType}
              onChangeId={getIdNumber}
              index={index}
              removeFromIndex={1}
              remove={remove}
              onChange={onChangeValue}
              bvnIsValid={validationCollection?.data[index]?.data.bvn.isValid}
            />
          ) : (
            <MinimizedInfo
              title={`${item.idNumber} - (${item.idType}) || ${item.bvn}`}
              description=''
              handleEdit={() => editInfo(index)}
              handleDelete={() => remove(index)}
            />
          )}
        </div>
      ))}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <S.Add onClick={push}>
          <Icon name='plus' color='#000000' />
          <Text color='#7F91A8'>Add another UBO </Text>
        </S.Add>

        {collection.length > 1 && (
          <div
            style={{
              display: 'flex',
              gap: 10,
              alignItems: 'center',
              marginBottom: ptr(40),
              cursor: 'pointer',
            }}
            onClick={() =>
              collection.length > 1 && remove(collection.length - 1)
            }
          >
            <Icon name='cancel' color={colors.red._100} />
            <Text size={6} color={colors.red._100}>
              Cancel
            </Text>
          </div>
        )}
      </div>
      <S.ButtonGroup>
        <div>
          <Button
            style={{
              width: '161px',
              color: colors.black._20,
              background: '#F5F7FA',
            }}
            label='Skip'
            loading={loading}
            onClick={() => continueHandle({ skip: true })}
          />
          <Button
            style={{ width: '304px' }}
            label='Continue'
            loading={loading}
            disabled={
              !validationCollection?.isValid ||
              !idNumberValidation?.isValid ||
              loading
            }
            onClick={continueHandle}
          />
        </div>
      </S.ButtonGroup>
    </>
  );

  return <S.Container>{renderModalView()}</S.Container>;
};
