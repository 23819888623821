import styled from 'styled-components';
import { ptr } from '../../styles/utils';

const Onboarding = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  margin-top: 80px;
  /* height:100%;
  align-items: flex-end; */
  /* margin-bottom: ${ptr(32)}; */
`;
export { Onboarding };
