import styled from 'styled-components';
import { ptr } from '../../../../styles/utils';

const Container = styled.div`

`;

const Wrapper = styled.div` 

padding-bottom:30px;
border-bottom: 0.6px dotted rgba(110, 113, 145, 0.42);

  > :not(:first-child) {
    margin-top: ${ptr(30)};
  }


`;

const OuterWrapper= styled.div`
   margin-top: ${ptr(32)};
}
`;
export { Container, Wrapper, OuterWrapper };