// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
// import { LeaderDirectorShareholderInterface } from '../../../services/internal/onboarding/IOnboarding';
// import { Button } from '../../../components/Button';
// import { Title } from '../../Onboarding/components/Title';
// import { Icon } from '../../../components/Icon';
// import {
//   DropdownInput,
//   DropdownOptionProps,
// } from '../../../components/DropdownInput';
// import { Text } from '../../../components/Text';
// import { validateObject, ValidateResult } from '../../../utils/validation';
// import { colors } from '../../../styles/variables';
// import * as S from './styles';
// import { OnboardingCard } from '../../../components/OnboardingCard';
// import { ConsentSample } from '../../Onboarding/components/ConsentSample';
// import { BusinessTypesEnum } from '../constants';
// import { SubaccountInterface } from '../../../redux/subaccount/ISubaccount';

// const SCHEME = {
//   firstDirector: (data: DropdownOptionProps) => !!data?.key,
//   secondDirector: (data: DropdownOptionProps) => (data ? !!data?.key : true),
// };

// type DirectorsList = Array<DropdownOptionProps>;

// const CommonSubConsent: React.FC = () => {
//   /**
//    * Hooks initiation region
//    */
//   const dispatch = useDispatch();
//   const route = useHistory();

//   /**
//    * Redux store initiation region
//    */
//   // const leaders = useSelector(
//   //   (state: { onboardingStack: OnboardingInterface }) =>
//   //     state.onboardingStack.data.directors as Array<
//   //       LeaderDirectorShareholderInterface
//   //     >
//   // );

//   const businessType = useSelector(
//     (state: { onboardingStack: OnboardingInterface }) =>
//       state.onboardingStack.data.businessType as string
//   );
//   const reference = useSelector(
//     (state: { onboardingStack: OnboardingInterface }) =>
//       state.onboardingStack.data.reference as string
//   );

//   const subaccountRequest = useSelector(
//     (state: { subaccount: SubaccountInterface }) =>
//       state.subaccount.subaccountRequest
//   );

//   console.log(subaccountRequest, 'subaccountRequest');

//   const leaders = subaccountRequest?.business?.BusinessLeader ?? [];

//   /**
//    * Local state initiation region
//    */
//   const [directors, setDirectors] = useState<DirectorsList>([]);
//   const [
//     firstDirector,
//     setFirstDirector,
//   ] = useState<DropdownOptionProps | null>(null);
//   const [
//     secondDirector,
//     setSecondDirector,
//   ] = useState<DropdownOptionProps | null>(null);
//   const [modalVisible, seModalVisible] = useState<boolean>(false);
//   const [validation, setValidation] = useState<ValidateResult>();
//   const [loading, setLoading] = useState(false);

//   /**
//    * Custom handlers initiation region
//    */
//   const continueHandle = () => {
//     route.push('/sub/preview-details');
//     // (async () => {
//     //   setLoading(true);
//     //   try {
//     //     let emails: Array<any> = [];
//     //     firstDirector && emails.push(firstDirector.value);
//     //     secondDirector && emails.push(secondDirector.value);

//     //     const response = await onboardingService.submitConsentLetter({
//     //       email: emails,
//     //       reference,
//     //     });
//     //     batch(() => {
//     //       dispatch(
//     //         OnboardingActions.handleSetData({
//     //           reference: response.data.reference,
//     //           directors: response.data.directors,
//     //         })
//     //       );
//     //       dispatch(
//     //         OnboardingActions.handleSetOnBoardingSummaryResponse(
//     //           response.data.data
//     //         )
//     //       );
//     //     });
//     //     route.push(actions[response.actionRequired]);
//     //   } catch (error) {
//     //     const err = error as any;
//     //     if (err.code === 'INVALID_ACTION_REQUIRED') {
//     //       route.push(
//     //         actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
//     //       );
//     //     } else {
//     //       dispatch(OnboardingActions.handleSetError(errorToString(err)));
//     //     }
//     //   }
//     //   setLoading(false);
//     // })();
//   };

//   const onCloseModal = () => {
//     seModalVisible(false);
//   };

//   /**
//    * useEffect region
//    */
//   useEffect(() => {
//     setValidation(
//       validateObject(
//         {
//           firstDirector,
//           secondDirector,
//         },
//         SCHEME
//       )
//     );
//   }, [firstDirector, secondDirector]);

//   useEffect(() => {
//     if (leaders && leaders.length) {
//       setDirectors(
//         leaders.map((item, index) => ({
//           label: item.firstName
//             ? `${item.firstName} ${item.lastName}`
//             : item.email,
//           value: item.email,
//           category: 'Directors',
//           subLabel: item.email,
//           key: index.toString(),
//         }))
//       );
//     }
//   }, [leaders]);
//   //}, []);

//   function getLabel() {
//     switch (businessType) {
//       case BusinessTypesEnum.soleProprietorship:
//         return 'proprietor';
//       case BusinessTypesEnum.partnership:
//         return 'partner';
//       case BusinessTypesEnum.trusteeNgo:
//         return 'trustee';
//       case BusinessTypesEnum.cooperativeSociety:
//         return 'signatory';
//       default:
//         return 'director';
//     }
//   }

//   return (
//     <S.Container>
//       <ConsentSample
//         visible={modalVisible}
//         onClose={onCloseModal}
//         image='../../images/documents/certificate-3.jpeg'
//       />
//       <S.TopTitle>
//         <Title
//           color={colors.black._20}
//           title='Who will consent to onboarding the account on VBiz?'
//           subTitle={
//             businessType !== BusinessTypesEnum.soleProprietorship
//               ? `Select 2 ${getLabel()}s from the options earlier provided`
//               : 'The option is from the proprietor you provided earlier'
//           }
//         />
//       </S.TopTitle>

//       <S.TopTextInfo onClick={() => seModalVisible(true)}>
//         <Icon color='#B10180' name='info' />
//         <Text bold size={7} color='#B10180'>
//           View sample of consent letter
//         </Text>
//       </S.TopTextInfo>
//       <OnboardingCard>
//         <S.Wrapper>
//           {directors.length === 1 ? (
//             <>
//               <Text bold color={colors.black._100}>
//                 Select two partners who will sign the consent letter
//               </Text>
//               <div style={{ marginTop: '14px' }}>
//                 <Text size={6} color='#A0A3BD'>
//                   Partner 1
//                 </Text>
//                 <S.ItemRow
//                   style={{
//                     display: 'flex',
//                     gap: 15,
//                     width: '100%',
//                     marginBottom: 14,
//                   }}
//                 >
//                   <DropdownInput
//                     label={'Partner’s name'}
//                     placeholder={'Select partner'}
//                     options={directors.filter(
//                       (item) => item.key !== secondDirector?.key
//                     )}
//                     //value={firstDirector?.value}
//                     inputValue={firstDirector?.label}
//                     onSelect={setFirstDirector}
//                   />
//                   <DropdownInput
//                     label={'Partner’s email'}
//                     placeholder={'Select partner’s email'}
//                     options={directors.filter(
//                       (item) => item.key !== secondDirector?.key
//                     )}
//                     //value={firstDirector?.value}
//                     inputValue={firstDirector?.label}
//                     onSelect={setFirstDirector}
//                   />
//                 </S.ItemRow>

//                 <Text size={6} color='#A0A3BD'>
//                   Partner 2
//                 </Text>
//                 <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
//                   <DropdownInput
//                     label={'Partner’s name'}
//                     placeholder={'Select partner'}
//                     options={directors.filter(
//                       (item) => item.key !== secondDirector?.key
//                     )}
//                     //value={firstDirector?.value}
//                     inputValue={firstDirector?.label}
//                     onSelect={setFirstDirector}
//                   />
//                   <DropdownInput
//                     label={'Partner’s email'}
//                     placeholder={'Select partner’s email'}
//                     options={directors.filter(
//                       (item) => item.key !== secondDirector?.key
//                     )}
//                     //value={firstDirector?.value}
//                     inputValue={firstDirector?.label}
//                     onSelect={setFirstDirector}
//                   />
//                 </S.ItemRow>
//               </div>
//             </>
//           ) : (
//             <div>
//               <Text bold color={colors.black._100}>
//                 Select the proprietor that will sign the consent letter
//               </Text>
//               {businessType !== BusinessTypesEnum.soleProprietorship && (
//                 <div
//                   style={{
//                     flex: 1,
//                     marginTop: 32,
//                   }}
//                 >
//                   <DropdownInput
//                     label={'Proprietor'}
//                     placeholder={'select proprietor'}
//                     options={directors.filter(
//                       (item) => item.key !== secondDirector?.key
//                     )}
//                     //value={firstDirector?.value}
//                     inputValue={firstDirector?.label}
//                     onSelect={setFirstDirector}
//                   />
//                 </div>
//               )}
//               {businessType === BusinessTypesEnum.soleProprietorship && (
//                 <DropdownInput
//                   label={'Proprietor'}
//                   placeholder={'select proprietor'}
//                   options={directors.filter(
//                     (item) => item.key !== secondDirector?.key
//                   )}
//                   //value={firstDirector?.value}
//                   inputValue={firstDirector?.label}
//                   onSelect={setFirstDirector}
//                 />
//               )}
//             </div>
//           )}

//           <S.ButtonGroup>
//             <div>
//               <Button
//                 label='Continue'
//                 loading={loading}
//                 style={{
//                   width: 304,
//                 }}
//                 onClick={continueHandle}
//                 // disabled={!validation?.isValid || loading}
//               />
//             </div>
//           </S.ButtonGroup>
//         </S.Wrapper>
//       </OnboardingCard>
//     </S.Container>
//   );
// };
// export default CommonSubConsent;

import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { LeaderDirectorShareholderInterface } from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Icon } from '../../../components/Icon';
import { ImagePreviewModal } from '../../Onboarding/components/ImagePreviewModal';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../../components/DropdownInput';
import { Text } from '../../../components/Text';

import { validateObject, ValidateResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { errorToString } from '../../../services/internal/app/index';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { getSignatureFormData } from '../../Onboarding/steps/common.api/index';
import { actions } from '../config';
import { BusinessTypesEnum } from '../constants';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { ConsentSample } from '../../Onboarding/components/ConsentSample';
import { capitalize } from '../../../utils/strings';
import { SubaccountInterface } from '../../../redux/subaccount/ISubaccount';
import { CompanyInterface } from '../../../redux/company/ICompany';
import AddDetails from './components/AddDetails';
import subaccountService from '../../../services/internal/subaccount/subaccount.service';
import { failed } from '../../../components/Toasts';

const SCHEME = {
  firstDirector: (data: DropdownOptionProps) => !!data?.key,
  secondDirector: (data: DropdownOptionProps) => (data ? !!data?.key : true),
};

type DirectorsList = Array<DropdownOptionProps>;

const CommonSubConsent: React.FC = () => {
                                           /**
                                            * Hooks initiation region
                                            */
                                           const dispatch = useDispatch();
                                           const route = useHistory();

                                           /**
                                            * Redux store initiation region
                                            */
                                           // const leaders = useSelector(
                                           //   (state: { onboardingStack: OnboardingInterface }) =>
                                           //     state.onboardingStack.data.directors as Array<
                                           //       LeaderDirectorShareholderInterface
                                           //     >
                                           // );

                                           const reference = useSelector(
                                             (state: {
                                               onboardingStack: OnboardingInterface;
                                             }) =>
                                               state.onboardingStack.data
                                                 .reference as string
                                           );

                                           const subaccountRequest = useSelector(
                                             (state: {
                                               subaccount: SubaccountInterface;
                                             }) =>
                                               state.subaccount
                                                 .subaccountRequest
                                           );

                                           const activeCompany = useSelector(
                                             (state: {
                                               company: CompanyInterface;
                                             }) => state.company.active
                                           );

                                           const businessType =
                                             activeCompany?.accounts[0]?.type;

                                           const leaders =
                                             subaccountRequest?.business
                                               ?.BusinessLeader ?? [];

                                           /**
                                            * Local state initiation region
                                            */
                                           const [
                                             directors,
                                             setDirectors,
                                           ] = useState<DirectorsList>([]);
                                           const [
                                             firstDirector,
                                             setFirstDirector,
                                           ] = useState<DropdownOptionProps | null>(
                                             null
                                           );
                                           const [
                                             secondDirector,
                                             setSecondDirector,
                                           ] = useState<DropdownOptionProps | null>(
                                             null
                                           );
                                           const [
                                             modalVisible,
                                             seModalVisible,
                                           ] = useState<boolean>(false);
                                           const [
                                             validation,
                                             setValidation,
                                           ] = useState<ValidateResult>();
                                           const [
                                             loading,
                                             setLoading,
                                           ] = useState(false);

                                           /**
                                            * Custom handlers initiation region
                                            */
                                           const continueHandle = async () => {
                                             setLoading(true);
                                             try {
                                               if (subaccountRequest) {
                                                 let emails: Array<any> = [];
                                                 firstDirector &&
                                                   emails.push(
                                                     firstDirector.value
                                                   );
                                                 secondDirector &&
                                                   emails.push(
                                                     secondDirector.value
                                                   );
                                                 const businessLeaderId = leaders
                                                   .filter((leader) =>
                                                     emails.includes(
                                                       leader.leader.email
                                                     )
                                                   )
                                                   .map((leader) => leader.id);

                                                 const response = await subaccountService.createConsent(
                                                   {
                                                     requestId:
                                                       subaccountRequest?.id,
                                                     businessLeaderId,
                                                   }
                                                 );
                                                 route.push(
                                                   '/subaccount/preview-details'
                                                 );
                                               }
                                             } catch (error) {
                                               const err = error as any;
                                               failed(
                                                 'failed',
                                                 err.message ||
                                                   'An error occured, please try again'
                                               );
                                             }
                                             setLoading(false);
                                           };

                                           const onCloseModal = () => {
                                             seModalVisible(false);
                                           };

                                           /**
                                            * useEffect region
                                            */
                                           useEffect(() => {
                                             setValidation(
                                               validateObject(
                                                 {
                                                   firstDirector,
                                                   secondDirector,
                                                 },
                                                 SCHEME
                                               )
                                             );
                                           }, [firstDirector, secondDirector]);

                                           useEffect(() => {
                                             if (leaders && leaders.length) {
                                               const newLeader = leaders.filter(
                                                 (leader) => leader.leader.phone
                                               );

                                               setDirectors(
                                                 newLeader.map(
                                                   (item, index) => ({
                                                     label: item.leader.name
                                                       ? item.leader.name
                                                       : item.leader.email,
                                                     value: item.leader.email,
                                                     category: 'Directors',
                                                     subLabel:
                                                       item.leader.email,
                                                     key: index.toString(),
                                                   })
                                                 )
                                               );
                                             }
                                           }, [leaders]);
                                           //}, []);

                                           function getLabel() {
                                             switch (businessType) {
                                               case BusinessTypesEnum.soleProprietorship:
                                                 return 'proprietor';
                                               case BusinessTypesEnum.partnership:
                                                 return 'partner';
                                               case BusinessTypesEnum.trusteeNgo:
                                                 return 'trustee';
                                               case BusinessTypesEnum.cooperativeSociety:
                                                 return 'signatory';
                                               default:
                                                 return 'director';
                                             }
                                           }

                                           if (!directors.length) {
                                             return <AddDetails />;
                                           }

                                           return (
                                             <S.Container>
                                               <ConsentSample
                                                 visible={modalVisible}
                                                 onClose={onCloseModal}
                                                 image='../../images/documents/certificate-3.jpeg'
                                               />
                                               <S.TopTitle>
                                                 <Title
                                                   color={colors.black._20}
                                                   title='Who will consent to onboarding the account on VBiz?'
                                                   subTitle={
                                                     businessType !==
                                                     BusinessTypesEnum.soleProprietorship
                                                       ? `The option is from the ${getLabel()}s you provided  during the main account opening.`
                                                       : 'The option is from the proprietor provided during the main account opening.'
                                                   }
                                                 />
                                               </S.TopTitle>

                                               {/* <S.File>
          <S.FullScreen onClick={() => seModalVisible(true)}>
            <Icon name='fullscreen' color={colors.black._100} />
          </S.FullScreen>
        </S.File> */}
                                               <S.TopTextInfo
                                                 onClick={() =>
                                                   seModalVisible(true)
                                                 }
                                               >
                                                 <Icon
                                                   color='#B10180'
                                                   name='info'
                                                 />
                                                 <Text
                                                   bold
                                                   size={7}
                                                   color='#B10180'
                                                 >
                                                   View sample of consent letter
                                                 </Text>
                                               </S.TopTextInfo>
                                               <OnboardingCard>
                                                 <S.Wrapper>
                                                   {businessType !==
                                                   BusinessTypesEnum.soleProprietorship ? (
                                                     <Text
                                                       bold
                                                       color={colors.black._100}
                                                     >
                                                       Select{' '}
                                                       {directors.length > 2
                                                         ? `2 ${getLabel()}s`
                                                         : `a ${getLabel()}`}{' '}
                                                       who will consent to
                                                       onboarding the account on
                                                       VBiz?
                                                     </Text>
                                                   ) : (
                                                     <Text
                                                       bold
                                                       color={colors.black._100}
                                                     >
                                                       Select the proprietor
                                                       that will sign the
                                                       consent letter
                                                     </Text>
                                                   )}
                                                   {businessType !==
                                                     BusinessTypesEnum.soleProprietorship && (
                                                     <div
                                                       style={{
                                                         display: 'flex',
                                                         flexDirection:
                                                           'column',
                                                         gap: 10,
                                                       }}
                                                     >
                                                       <Text color={'#A0A3BD'}>
                                                         {capitalize(
                                                           getLabel()
                                                         )}{' '}
                                                         1
                                                       </Text>
                                                       <div
                                                         style={{
                                                           display: 'flex',
                                                           flexDirection: 'row',
                                                           justifyContent:
                                                             'space-between',
                                                           gap: 15,
                                                         }}
                                                       >
                                                         <div
                                                           style={{
                                                             flex: 1,
                                                           }}
                                                         >
                                                           <DropdownInput
                                                             label={`${capitalize(
                                                               getLabel()
                                                             )}'s name`}
                                                             placeholder={`Select ${capitalize(
                                                               getLabel()
                                                             )}`}
                                                             options={directors.filter(
                                                               (item) =>
                                                                 item.key !==
                                                                 secondDirector?.key
                                                             )}
                                                             //value={firstDirector?.value}
                                                             inputValue={
                                                               firstDirector?.label
                                                             }
                                                             onSelect={
                                                               setFirstDirector
                                                             }
                                                           />
                                                         </div>
                                                         <div
                                                           style={{
                                                             flex: 1,
                                                           }}
                                                         >
                                                           <DropdownInput
                                                             label={`${capitalize(
                                                               getLabel()
                                                             )}'s email`}
                                                             placeholder={`Select ${capitalize(
                                                               getLabel()
                                                             )}'s email`}
                                                             options={directors
                                                               .map((dir) => ({
                                                                 ...dir,
                                                                 label:
                                                                   dir.value,
                                                               }))
                                                               .filter(
                                                                 (item) =>
                                                                   item.key !==
                                                                   secondDirector?.key
                                                               )}
                                                             //value={firstDirector?.value}
                                                             onSelect={(item) =>
                                                               setFirstDirector(
                                                                 directors.find(
                                                                   (dir) =>
                                                                     dir.value ===
                                                                     item?.value
                                                                 ) || item
                                                               )
                                                             }
                                                             inputValue={
                                                               firstDirector?.value
                                                             }
                                                           />
                                                         </div>
                                                       </div>

                                                       <div>
                                                         <Text
                                                           color={'#A0A3BD'}
                                                         >
                                                           {capitalize(
                                                             getLabel()
                                                           )}{' '}
                                                           2
                                                         </Text>
                                                         <div
                                                           style={{
                                                             display: 'flex',
                                                             flexDirection:
                                                               'row',
                                                             justifyContent:
                                                               'space-between',
                                                             gap: 15,
                                                           }}
                                                         >
                                                           <div
                                                             style={{
                                                               flex: 1,
                                                             }}
                                                           >
                                                             <DropdownInput
                                                               label={`${capitalize(
                                                                 getLabel()
                                                               )}'s name`}
                                                               placeholder={`Select ${capitalize(
                                                                 getLabel()
                                                               )}`}
                                                               options={directors.filter(
                                                                 (item) =>
                                                                   item.key !==
                                                                   firstDirector?.key
                                                               )}
                                                               //value={firstDirector?.value}
                                                               inputValue={
                                                                 secondDirector?.label
                                                               }
                                                               onSelect={
                                                                 setSecondDirector
                                                               }
                                                             />
                                                           </div>
                                                           <div
                                                             style={{
                                                               flex: 1,
                                                             }}
                                                           >
                                                             <DropdownInput
                                                               label={`${capitalize(
                                                                 getLabel()
                                                               )}'s email`}
                                                               placeholder={`Select ${capitalize(
                                                                 getLabel()
                                                               )}'s email`}
                                                               options={directors
                                                                 .map(
                                                                   (dir) => ({
                                                                     ...dir,
                                                                     label:
                                                                       dir.value,
                                                                   })
                                                                 )
                                                                 .filter(
                                                                   (item) =>
                                                                     item.key !==
                                                                     firstDirector?.key
                                                                 )}
                                                               //value={firstDirector?.value}
                                                               inputValue={
                                                                 secondDirector?.value
                                                               }
                                                               onSelect={(
                                                                 item
                                                               ) =>
                                                                 setSecondDirector(
                                                                   directors.find(
                                                                     (dir) =>
                                                                       dir.value ===
                                                                       item?.value
                                                                   ) || item
                                                                 )
                                                               }
                                                             />
                                                           </div>
                                                         </div>
                                                       </div>
                                                     </div>
                                                   )}
                                                   {businessType ===
                                                     BusinessTypesEnum.soleProprietorship && (
                                                     <DropdownInput
                                                       label={
                                                         'Select Proprietor'
                                                       }
                                                       placeholder={
                                                         'Select proprietor'
                                                       }
                                                       options={directors.filter(
                                                         (item) =>
                                                           item.key !==
                                                           secondDirector?.key
                                                       )}
                                                       //value={firstDirector?.value}
                                                       onSelect={
                                                         setFirstDirector
                                                       }
                                                     />
                                                   )}

                                                   <S.ButtonGroup>
                                                     <div>
                                                       <Button
                                                         label='Continue'
                                                         loading={loading}
                                                         style={{
                                                           width: 304,
                                                         }}
                                                         onClick={
                                                           continueHandle
                                                         }
                                                         disabled={
                                                           !validation?.isValid ||
                                                           loading
                                                         }
                                                       />
                                                     </div>
                                                   </S.ButtonGroup>
                                                 </S.Wrapper>
                                               </OnboardingCard>
                                             </S.Container>
                                           );
                                         };
export default CommonSubConsent;
