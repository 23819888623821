import styled, { css } from 'styled-components';
import { crossBrowserBoxShadow, crossBrowserDisableSelection, crossBrowserTransition, ptr } from '../../styles/utils';
import { breakpoints, colors, transition } from '../../styles/variables';
import { SingleItemProps } from '../../components/DropdownInput';

interface DropdownState {
    open?: boolean,
    value?: string,
}


const CardContainer = styled.div`
    width: 100%;
    height: 100%;
    -webkit-box-shadow: -1px 0px 5px -2px rgba(16,24,40,0.43);
    -moz-box-shadow: -1px 0px 5px -2px rgba(16,24,40,0.43);
    box-shadow: -1px 0px 5px -2px rgba(16,24,40,0.43);

    border: solid 1px transparent;
    border-radius: 10px;
`;
const CardContentContainer = styled.div`
    min-height: 171px;
    background-color: #EAECF0;
    padding: 25px;
`;

const CardMainContentContainer = styled.div`
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;

`
const HeaderContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 47px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.sm}px) {
        margin-bottom: 60px!important;
        /* gap:15px; */
  }
`
const HeaderBackButton = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    @media (max-width: ${breakpoints.sm}px) {
        margin-bottom: 10px!important;
  }
`
const BackButton = styled.div`
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white._100};
    box-shadow: 0px 1px 2px 0px rgb(16,24,40, 0.5);
    box-shadow: 0px 1px 3px 0px rgb(16,24,40, 0.5);
    border-radius: 36px;
    cursor: pointer;

`
const Input = styled.div`
    width: 45%;
    position: relative;
    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
  }

`

const DropdownContent = styled.div<DropdownState>`
  display: none;
  position: absolute;
  z-index: 1000;
  max-height: ${ptr(450)};
  overflow: auto;
  overflow-x: hidden;
  flex-direction: column;
  background-color: ${colors.white._100};
  top: ${ptr(55)};
  width: 100%; 
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(6)};
  max-height: ${ptr(450)};
  ${crossBrowserTransition(transition.slow)};
  ${crossBrowserBoxShadow(`0 0 15px ${ptr(0.2)} ${colors.black._10}`)};
  ${(props) => props.open && css`
    display: flex;
  `}
`;

const SingleItemWrapper = styled.div<SingleItemProps>`
  padding: ${ptr(18)} ${ptr(2)};
  min-height: 70px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.black._10};
  ${(props) => !props.disabled && css`
    cursor: pointer;
    :hover {
      /* background-color: ${colors.black._7}; */
      > span {
        ${crossBrowserDisableSelection()}
        color: ${colors.black._20};
        font-weight: 600;
      }
    }
  `}
  ${(props) => props.first && css`
    border-top: 0;
    
  `}
`;

const LinksInner = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 6px;
  margin-bottom: 10px;
  width: 120px;
  > svg {
    background: #eff0f6;
    width: 22px;
    height: 20px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 45px;
    margin-bottom: 9px;
    overflow: visible;
  }
  position: relative;
`;

const CheckBtn = styled.div`
  position: absolute;
  right: 0;
`;

const ContentHeader = styled.div`

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 52px;
    gap: 10px;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: solid 1px #EAECF0;
    @media (max-width: ${breakpoints.sm}px) {
        margin-bottom: 50px;
  }
`



export { CardContainer, CardContentContainer, CardMainContentContainer, HeaderContainer, HeaderBackButton, BackButton, Input, ContentHeader, DropdownContent, SingleItemWrapper, LinksInner, CheckBtn };



