import React, { useEffect, useState, useRef } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { colors } from '../../styles/variables';
import { Icon, IconProps } from '../Icon';
import * as S from './styles';
import {
  Details,
} from '../../utils/onboardModalDetails';
import { useHistory } from 'react-router';
import { Button } from '../Button';
import { Link } from '../Link';
import { Text } from '../Text';
import { handleCamelcasing } from '../../utils/strings';
import { OtpInput } from '../OtpInput';
import { batch, useDispatch, useSelector } from 'react-redux';
import { OnboardingInterface } from '../../redux/onboarding/IOnboarding';
import onboardingService from '../../services/internal/onboarding/onboarding.service';
import { OnboardingActions } from '../../redux/onboarding/actions';
import { actions } from '../../pages/Onboard/config';
import debug from '../../services/internal/debbug.service';

export interface OnboardingOtpModalProps {
  onCloseModal?: () => void;
  /**
   * Show modal
   */
  showModal?: boolean;
  crossColor?: string;
  overflowY?: string;
  overflowX?: string;
  margin?: string;
  maxHeight?: string | null;
  maxWidth?: string | null;
  onBottom?: () => void;
  closeBackground?: string;
  details?: Details | null;
  signupOption?: boolean;
  data?: { email: string; phoneNumber: string };
  resendFn?: () => void;
}

export const OnboardingOtpModal: React.FC<OnboardingOtpModalProps> = ({
  showModal = false,
  crossColor = colors.black._100,
  overflowY = 'auto',
  overflowX = 'auto',
  maxHeight = null,
  maxWidth = '90%',
  onBottom = () => {},
  data,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const storedOtp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.otp as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );
  const ss = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data as any
  );

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });
  const [timer, setTimer] = useState(50);

  const route = useHistory();

  const timeout = useRef<NodeJS.Timeout>();

  const [code, setCode] = useState<string>(storedOtp || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showModal) {
      timeout.current = setInterval(() => {
        setTimer((prev) => {
          if (prev === 0 && timeout.current) {
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (timeout?.current) {
        clearInterval(timeout.current);
      }
    };
  }, [showModal]);

  const onCloseModal = () => {
    if (props.onCloseModal) props.onCloseModal();
    if (timeout.current) clearInterval(timeout.current);
    setTimer(50);
    setCode('');
  };

  const signup = (route?: string) => {
    if (route) history.push(route);
  };

  const continueHandle = () => {
    (async () => {
      setLoading(true);
      if (reference)
        try {
          const response = await onboardingService.validateOtpForBusiness({
            otp: code,
            reference,
          });
          batch(() => {
            dispatch(
              OnboardingActions.handleSetData({
                reference: response.data.reference,
                businessType: response.data?.businessType ?? '',
                businessInfo: response.data.businessInfo ?? '',
                credentials: {
                  email: response.data.user?.email ?? '',
                  bvn: response.data.user?.profile?.bvn ?? '',
                  phone: response.data.user?.phone ?? '',
                  idNumber: response.data.user?.profile?.idNumber ?? '',
                  idType: response.data.user?.profile?.idType ?? '',
                },
                directors: [
                  ...response.data.proprietors,
                  ...response.data.partners,
                  ...response.data.signatories,
                  ...response.data.trustees,
                  ...response.data.directors,
                ],

                operators: [...response.data.operators],
                role: response.data.client?.role ?? '',
                workflow: response.data.workflow,
                user: response.data.user,
                userId: response.data.userId,
                ultimateBeneficialOwners:
                  response.data.ultimateBeneficialOwners,
              })
            );
          });

          if (response.actionRequired === 'SUBMIT_PROFILE') {
            route.push(actions[response.actionRequired + '_PASSWORD']);
          } else {
            route.push(actions[response.actionRequired]);
          }

          debug.info('', response); // TEMPORARY
        } catch (error) {
          const err = error as any;
          if (err.code === 'INVALID_ACTION_REQUIRED') {
            route.push(
              actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
            );
          } else {
            dispatch(
              OnboardingActions.handleSetError(
                err.message || 'Can not complete request at the moment'
              )
            );
          }
        }
      setLoading(false);
    })();
  };
  const resendFn = () => {
    if (timer !== 0) return; 
    if (props.resendFn) props.resendFn();
    setTimer(50);
  };
  return (
    <BottomScrollListener onBottom={onBottom}>
      {(scrollRef: any) => (
        <S.Container showModal={showModal}>
          <S.Wrapper
            overflowY={overflowY}
            overflowX={overflowX}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            ref={scrollRef}
            style={{ padding: 15, width: 425 }}
          >
            <S.Header regularFont>
              <div>
                <S.HeaderTitle
                  style={{
                    fontSize: 18,
                    color: '#394455',
                    fontWeight: 700,
                  }}
                >
                  Verification
                </S.HeaderTitle>
              </div>
              {props.onCloseModal && (
                <S.CrossWrapper background={props.closeBackground}>
                  <Icon
                    width={14}
                    height={14}
                    name='cross'
                    color={crossColor}
                    onClick={onCloseModal}
                  />
                </S.CrossWrapper>
              )}
            </S.Header>

            <div style={{ marginBottom: 15 }}>
              <Text size={6}>
                An OTP has been sent to your phone number{' '}
                <strong style={{ fontWeight: 700 }}>
                  {data?.phoneNumber} and your email {data?.email}.
                </strong>
              </Text>
            </div>

            <div>
              <Text color='#6E7191' size={6}>
                Enter token to verify your identity
              </Text>
              <div style={{ justifyContent: 'unset', display: 'flex' }}>
                <OtpInput
                  style={{
                    width: 15,
                    height: 50,
                    border: 'solid 1px #D1D5DB',
                    marginTop: 15,
                  }}
                  codeLength={6}
                  secure
                  onCodeChange={setCode}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <div>
                  <Text size={6}>Didn’t get the code?</Text>
                </div>
                <Text size={6}>
                  <strong
                    onClick={resendFn}
                    style={{
                      fontWeight: 700,
                      color: colors.pink._100,
                      opacity: timer !== 0 ? 0.3 : 1,
                      cursor: timer === 0 ? 'pointer' : 'unset',
                    }}
                  >
                    Resend{' '}
                  </strong>
                  in{' '}
                  <strong style={{ fontWeight: 700, color: colors.pink._100 }}>
                    00 : {timer} secs
                  </strong>
                  .
                </Text>
              </div>
            </div>

            <S.BottomDiv>
              <div>
                <Button
                  label='Cancel'
                  //   backgroundColor='#F5F7FA'
                  color={colors.black._20}
                  style={{
                    marginTop: 15,
                    backgroundColor: '#F5F7FA',
                    height: 40,
                  }}
                  onClick={onCloseModal}
                />
              </div>

              <div>
                <Button
                  label='Confirm'
                  size='medium'
                  backgroundColor={colors.pink._100}
                  color={colors.white._100}
                  style={{ marginTop: 15, height: 40 }}
                  onClick={continueHandle}
                  loading={loading}
                />
              </div>
            </S.BottomDiv>
          </S.Wrapper>
        </S.Container>
      )}
    </BottomScrollListener>
  );
};
