/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
// import { RowText } from '../../components/RowText';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { errorToString } from '../../../services/internal/app/index';
import {
  OnboardingSummary, OperatorInfoSummary, DirectorPreviewDetails, IsDirectorPreviewDetails,
} from '../../../services/internal/onboarding/IOnboarding';
import { Row } from '../../Onboarding/components/ManDetails';
import { PurviewDetails } from '../../Onboarding/steps/common.company.check-management';

import { RowText } from '../../Onboarding/components/RowText';
import { getBeautyDateTimeString } from '../../../utils/datetime';
import { TextInput } from '../../../components/TextInput';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { Modal } from '../../../components/SimpleModal';

const CommonSoleCheckViewers: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  type ShareholdersAndOperatorData = {
    business: { name: string };
    operators: Array<OperatorInfoSummary>;
    shareholders: Array<DirectorPreviewDetails>
    directors: Array<IsDirectorPreviewDetails>
  };

  /* const hasVBankAccount = useSelector((state: { onboardingStack: OnboardingInterface }) => state
    .onboardingStack.hasVBankAccount as boolean); */

  const summary = useSelector((state: { onboardingStack: OnboardingInterface }) => state
    .onboardingStack.serverSummaryResponse as OnboardingSummary);

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.reference as string,
  );

  const shareOperators = useSelector((state: { onboardingStack: OnboardingInterface }) => state
    .onboardingStack.data as ShareholdersAndOperatorData);
  const directors = useSelector((state: { onboardingStack: OnboardingInterface }) => state
    .onboardingStack.serverResponse as Array<DirectorPreviewDetails>);

  const [loading, setLoading] = useState(false);
  const [isCurrentValue, setIsCurrentValue] = useState<'current' | 'notCurrent'>();
  const [currentAddress, setCurrentAddress] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [fullAddress, setFullAddress] = useState('');

  /**
   * Custom handlers initiation region
   */

  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.confirmViewersInfo({
          reference: summary.reference || reference,
          currentAddress: fullAddress,
        });
        batch(() => {
          dispatch(OnboardingActions.handleSetData({
            reference: response.data.reference,
          }));
        });
        route.push('/onboarding/sole/completed');
        // debug.info('', response); // TEMPORARY
      } catch (err) {
        dispatch(OnboardingActions.handleSetError(errorToString(err)));
      }
      setLoading(false);
    })();
  };

  const renderOperators = (operators: Array<OperatorInfoSummary>) => operators.map((item:
  OperatorInfoSummary, index) => (
    <S.ListWrapper key={index}>
      <RowText param="Name" value={`${item.firstName} ${item.middleName} ${item.lastName}`} />
      <RowText param="Phone Number" value={item.phone} />
      <RowText param="Role" value={item.role} />
    </S.ListWrapper>
  ));
  /**
   * Just for test
   */

  const hasVBankAccSummary = () => (
    <S.Content>
      <Text color={colors.pink._80}>Business Info</Text>
      <Row field="Name" value={summary?.businessInfo?.name} />
      <Row field="Registration Date" value={summary?.businessInfo?.cacRegistrationDate ? getBeautyDateTimeString(new Date(summary?.businessInfo?.cacRegistrationDate)) : '-'} />
      <Row field="Address" value={fullAddress || summary?.businessInfo?.address} />
      {isCurrentValue === 'current' || (isCurrentValue === 'notCurrent' && fullAddress) ? null : (
        <div style={{ marginTop: 50 }}>
          <Text>Is the address above still your current address?</Text>

          <S.ButtonContainer>
            <Button
              label="No"
              backgroundColor={colors.pink._20}
              color={colors.pink._100}
              onClick={() => {
                setIsCurrentValue('notCurrent');
                setShowAddressModal(true);
              }}
            />
            <Button label="Yes" onClick={() => setShowModal(true)} />
          </S.ButtonContainer>
        </div>
      )}
      <br />
      {isCurrentValue === 'current' || (isCurrentValue === 'notCurrent' && fullAddress) ? (
        <>
          <Text color={colors.pink._80}>Directors Info</Text>

          {summary?.leaders && summary?.leaders.length
            ? <PurviewDetails purviewData={summary?.leaders} /> : null}
          {summary?.operators?.length ? (
            <>
              <br />
              <Text color={colors.pink._80}>Operators Info</Text>
              {renderOperators(summary?.operators)}
            </>
          ) : '' }
        </>
      ) : null}
    </S.Content>
  );
  const hasNoVBankAccSummary = () => (
    <S.Content>
      <Text color={colors.pink._80}>Business Info</Text>
      <Row field="Name" value={shareOperators?.business?.name} />
      <br />
      <Text color={colors.pink._80}>Directors Info</Text>

      <PurviewDetails purviewData={directors} />
      <br />
      <Text color={colors.pink._80}>ShareHolders Info</Text>
      <PurviewDetails purviewData={shareOperators?.shareholders} />
      {shareOperators?.operators.length ? (
        <>
          <br />
          <Text color={colors.pink._80}>Operators Info</Text>
          {renderOperators(shareOperators?.operators)}
        </>
      ) : '' }
    </S.Content>
  );
  return (
    <S.Container>
      <Title title="Check the data" />
      <S.Wrapper>
        <Text color={colors.black._60}>
          Check that the data you have entered and pulled in is correct.
        </Text>
        {shareOperators.shareholders.length > 0 ? hasNoVBankAccSummary() : hasVBankAccSummary()}
      </S.Wrapper>
      {shareOperators.shareholders.length > 0 || isCurrentValue === 'current' || (isCurrentValue === 'notCurrent' && fullAddress) ? <Button label="Submit" onClick={continueHandle} disabled={loading} /> : null}

      <Modal
        visible={showModal}
        buttonTitle="Continue"
        description="This address will be verified, be sure to select the right option"
        action={() => {
          setShowModal(false);
          setIsCurrentValue('current');
        }}
        onCloseModal={() => setShowModal(false)}
        showRoundButton={false}
        prompt
        title="Are you sure this is your current address?"
      />

      <ModalWrapper
        overflowY="auto"
        overflowX="auto"
        maxHeight="96vh"
        showModal={showAddressModal}
        onCloseModal={() => {
          setFullAddress('');
          setCurrentAddress('');
          setShowAddressModal(false);
        }}
        crossColor={colors.black._100}
      >

        <S.ModalContainer>
          <div style={{ marginBottom: 30 }}>
            <Text
              color={colors.pink._100}
              size={4}
            >
              Enter your current address in the input below

            </Text>
          </div>
          <TextInput
            onChange={(value) => setCurrentAddress(value)}
            type="text"
            label="Current Address"
          />
          <Button
            label="Submit"
            onClick={() => {
              setFullAddress(currentAddress);
              setShowAddressModal(false);
            }}
          />
        </S.ModalContainer>
      </ModalWrapper>
    </S.Container>
  );
};

export default CommonSoleCheckViewers;
