import React, { useEffect } from 'react';
import { useElapsedTime } from 'use-elapsed-time';

import { Link } from '../../../../components/Link';

export interface ResendCodeProps {
  /**
   * Timer duration
   */
  duration: number;
  /**
   * Resend trigger
   */
  resetTimer?: number;
  /**
   * Resend code click action
   */
  onClick: () => void;
}

export const ResendCode: React.FC<ResendCodeProps> = ({
  duration,
  resetTimer,
  onClick,
}) => {
  const { elapsedTime } = useElapsedTime(true, {
    duration,
    autoResetKey: resetTimer,
  });
  let remainingTime = Math.ceil(duration - elapsedTime);

  useEffect(() => {
    remainingTime = duration;
  }, [resetTimer]);

  return (
    <>
      <Link
        theme="dark"
        text={`Resend code${remainingTime > 0 ? ` (${remainingTime})` : ''}`}
        icon="round-arrow-counter-clockwise"
        disabled={remainingTime > 0}
        onClick={onClick}
      />
    </>
  );
};
