import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import * as S from './styles';
import { Icon, IconName } from '../Icon';
import { colors } from '../../styles/variables';

export interface LinkProps {
  /**
   * Text of the link
   */
  text?: string;
  /**
   * Link href
   */
  to?: string;
  /**
   * Is small mode
   */
  small?: boolean;
  /**
   * Icon left of the text
   */
  icon?: IconName['name'];
  /**
   * Dark or light theme
   */
  theme?: 'dark' | 'light' | 'black';
  /**
   * Is link disabled
   */
  disabled?: boolean;
  /**
   * On click event
   */
  onClick?: () => void;

  target?: string;

  iconRight?: boolean;
}

const LinkComponent: React.FC<LinkProps> = ({
  theme = 'dark',
  target,
  iconRight = false,
  ...props
}) => {
  const getThemeColor = () => {
    switch (theme) {
      case 'dark':
        return colors.pink._100;
      case 'light':
        return colors.white._100;
      case 'black':
        return colors.black._20;
      default:
        return colors.white._100;
    }
  };

  const onClick = () => {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  };

  if (!props.to) {
    if (!iconRight) {
      return (
        <S.Wrapper onClick={onClick} disabled={props.disabled}>
          {props.icon && (
            <S.IconWrapper text={props.text}>
              <Icon name={props.icon} color={getThemeColor()} />
            </S.IconWrapper>
          )}
          {props.text && (
            <S.Text color={getThemeColor()} small={props.small}>
              {props.text}
            </S.Text>
          )}
        </S.Wrapper>
      );
    } else {
      return (
        <S.Wrapper onClick={onClick} disabled={props.disabled}>
          {props.text && (
            <S.Text color={getThemeColor()} small={props.small}>
              {props.text}
            </S.Text>
          )}
          {props.icon && (
            <S.IconWrapper iconRight={iconRight} text={props.text}>
              <Icon name={props.icon} color={getThemeColor()} />
            </S.IconWrapper>
          )}
        </S.Wrapper>
      );
    }
  }

  return (
    <ReactRouterLink to={props.to} style={{ color: '#C6145E' }} target={target}>
      <S.Wrapper onClick={onClick} disabled={props.disabled}>
        {props.icon && (
          <S.IconWrapper text={props.text}>
            <Icon name={props.icon} color={getThemeColor()} />
          </S.IconWrapper>
        )}
        {props.text && (
          <S.Text color={getThemeColor()} small={props.small}>
            {props.text}
          </S.Text>
        )}
      </S.Wrapper>
    </ReactRouterLink>
  );
};

export const Link = React.memo(LinkComponent);
