import { baseFontSize } from './variables';

type BorderStyleType =
  'dotted' |
  'dashed' |
  'solid' |
  'double' |
  'groove' |
  'ridge' |
  'inset' |
  'outset' |
  'none' |
  'hidden';

type BorderSideType = 'top' | 'bottom' | 'left' | 'right';

export type BorderType = {
  width: number,
  style: BorderStyleType,
  color: string,
  sides?: Array<BorderSideType>,
};

/**
 * Points to rem
 */
function ptr(points: number): string {
  return `${points / baseFontSize}rem`;
}

function hexToRgbA(hex = '#FFFFFf', alpha = 1): string {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${alpha / 100})`;
  }
  throw new Error('Bad Hex');
}

function borderParams(border: BorderType): string {
  const bw = border.sides ? border.sides.reduce((result: Array<string>, side: BorderSideType) => {
    result.push(`border-${side}-width: ${ptr(border.width)};`);
    return result;
  }, []).join('\n') : `border-width: ${ptr(border.width)};`;
  return `
    ${bw}
    border-style: ${border.style};
    border-color: ${border.color};
  `;
}

function crossBrowserTransition(transition: string): string {
  return `
    transition: ${transition};
    -moz-transition: ${transition};
    -webkit-transition: ${transition};
    -o-transition: ${transition};
  `;
}

function crossBrowserBoxShadow(boxShadow: string): string {
  return `
    box-shadow: ${boxShadow};
    -moz-box-shadow: ${boxShadow};
    -webkit-box-shadow: ${boxShadow};
  `;
}

function crossBrowserInlineFlex(): string {
  return `
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  `;
}

function crossBrowserPlaceholder(color: string): string {
  return `
    ::-webkit-input-placeholder {
      color: ${color};
    }
    :-ms-input-placeholder {
      color: ${color};
    }
    ::-moz-placeholder {
      color: ${color};
      opacity: 1;
    }
    :-moz-placeholder {
      color: ${color};
      opacity: 1;
    }
  `;
}

function crossBrowserDisableSelection(): string {
  return `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  
  `;
}

export {
  ptr,
  hexToRgbA,
  borderParams,
  crossBrowserTransition,
  crossBrowserBoxShadow,
  crossBrowserInlineFlex,
  crossBrowserPlaceholder,
  crossBrowserDisableSelection,
};
