import styled, { css } from 'styled-components';
import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';

const Container = styled.div`

`;

const TopTitle = styled.div`
/* border-bottom: 1px solid #E9EBF1; */
/* padding-bottom: ${ptr(32)}; */
`;

const Wrapper = styled.div<any>`
margin-top: ${ptr(34)};
`;

const InputDescription = styled.div`
  margin-top: ${ptr(8)};
`;

const DropDown = styled.div`
display:flex;
flex-direction:column;
gap: ${ptr(18)};
margin-bottom: ${ptr(18)};
`;

const ButtonGroup = styled.div` 
  display:flex;
  gap: ${ptr(10)};
  margin-top: ${ptr(23)};
  justify-content:flex-end;  

border-top: 4px solid #DBDFE4;
padding-top:14px;
/* 
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right; */

`;

const FullButton = styled.div`
display: flex;
justify-content: flex-end; 
> button {
    width: 250px;
   }
   /* width: 60%; 
   > button {
    width: 60%
   } */
`;

const RangeWrapper = styled.div`
  
`;

const Slider = styled.div`
position:relative;

input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  color:green;
  width: 100%;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#ff4500, #ff4500); 
  background-repeat: no-repeat;
}

`;

const Buble = styled.div`
position:absolute;
`;

const RangeTitles = styled.div`
display:flex;
 margin-bottom: ${ptr(18)};
justify-content:space-between;  
`;

const Table = styled.div`
  border-radius: 0px 0px 8px 8px;
border: 1px solid #EAECF0;
background:  #F7F7FC;
flex:1;
display: flex;
flex-direction: column;
height:100%;
`

const TableItem = styled.div`
  height:44px;
  padding:0 24px;
  display: flex;
  flex-direction: row;
  gap:15px;
  justify-content: space-between;
  align-items: center;
  border-bottom:solid 1px #EAECF0;
`

export {
  Container,
  TopTitle,
  Buble,
  FullButton,
  RangeWrapper,
  Slider,
  RangeTitles,
  Wrapper,
  InputDescription,
  DropDown,
  ButtonGroup,
  Table,
  TableItem
};
