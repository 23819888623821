import styled from 'styled-components';

import { CellProps } from '.';
import { fonts } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const TableWrapper = styled.tbody`
  display: table;
  flex: 1;
  width: 100%;
  font-family: ${fonts.inter.regular};
`;

const TableRow = styled.tr`
  width: 100%;
`;

const TableCell = styled.td<CellProps>`
  ${(props) => props.bold && `font-family: ${fonts.inter.bold};`}
  line-height: 140%;
  padding-bottom: ${ptr(24)};
  :not(:last-child) {
    padding-right: ${ptr(32)};
    text-align: left;
  }
  
  :not(:first-child) {
    text-align: right;
  }
`;

export {
  TableWrapper,
  TableRow,
  TableCell,
};
