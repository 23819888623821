import React, { useState, useEffect } from "react";
import { AnyIfEmpty, batch, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { OnboardingActions } from "../../../redux/onboarding/actions";
import { OnboardingInterface } from "../../../redux/onboarding/IOnboarding";

import onboardingService from "../../../services/internal/onboarding/onboarding.service";
import debug from "../../../services/internal/debbug.service";

import { Button } from "../../../components/Button";
import { Title } from "../../Onboarding/components/Title";
import { Text } from "../../../components/Text";
import { OtpInput } from "../../../components/OtpInput";

import { validateObject, ValidateResult } from "../../../utils/validation";

import { colors } from "../../../styles/variables";
import * as S from "./styles";
import { actions } from "../config";

const SCHEME = {
  pin: (value: string) => value.length === 4,
  confirmPin: (value: { old: string; new: string }) => value.old === value.new,
};

const CommonOtherTransactionPinConfirm: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const createdPin = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.pin as string
  );
 
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  /**
   * Local state initiation region
   */
  const [pin, setPin] = useState<string>("");
  const [validation, setValidation] = useState<ValidateResult>();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.submitPin({
          pin,
          reference,
        });

        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              reference: response.data.reference,
            })
          );
          dispatch(
            OnboardingActions.handleSetOnBoardingSummaryResponse(response.data.data)
          );
        });
        route.push(actions[response.actionRequired]);

        debug.info("", response); // TEMPORARY
      } catch (err:any) {
       if (err.code === "INVALID_ACTION_REQUIRED") {
          route.push(actions[err.message.trim().split(":")[1].replace(/\s/g, '')]);
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || "Can not complete request at the moment"
            )
          )
        }
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidation(
      validateObject(
        {
          pin,
          confirmPin: { old: createdPin, new: pin },
        },
        SCHEME
      )
    );
  }, [pin, createdPin]);

  return (
    <S.Container>
      <Title title="Confirm transaction PIN" />
      <S.Wrapper>
        <Text color={colors.black._60}>
          Enter 4-digit Transaction PIN once again
        </Text>
        <OtpInput secure codeLength={4} value={pin} onCodeChange={setPin} />
      </S.Wrapper>
      <Button
        label="Continue"
        loading={loading}
        onClick={continueHandle}
        disabled={!validation?.isValid || loading}
      />
      <Text color={colors.black._100} size={6}>
        By clicking Continue, you agree with{" "}
        <Link to="*">
          <Text color={colors.pink._100}>Terms & Conditions</Text>
        </Link>
      </Text>
    </S.Container>
  );
};
export default CommonOtherTransactionPinConfirm;
