import React from 'react';
import { Text, TextSize } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { getBeautyDateStringWithSeparator } from '../../../../utils/datetime';

import * as S from './styles';

export interface ManDetailsProps {
  name?: string,
  companyName?: string,
  representativeName?: string,
  email?: string,
  phone: string,
  dateOfBirth: string,
  address: string,
  image: string,
  onlyText?: boolean,
}

export const Row: React.FC<{ field: string, value: string, date?:
boolean, valueSize?: TextSize }> = ({
  field,
  value,
  date,
  valueSize = 6,
}) => (
  <S.Row>
    <Text bold size={6} color={colors.black._100}>
      {field}
    </Text>
    <S.DataBlock>
      {date ? (
        <Text size={valueSize} color={colors.black._100}>
          {getBeautyDateStringWithSeparator(new Date(value))}
        </Text>
      ) : (
        <Text size={valueSize} color={colors.black._100}>{value}</Text>
      )}
    </S.DataBlock>
  </S.Row>
);

export const ManDetails: React.FC<ManDetailsProps> = ({
  companyName,
  representativeName,
  name,
  email,
  phone,
  dateOfBirth,
  address,
  image,
  onlyText,
}) => (
  <S.Container>
    {!onlyText ? <S.Image image={image} /> : ''}
    <S.Data>
      {representativeName && <Row field="Representative name" value={representativeName} />}
      {companyName && <Row field="Company name" value={companyName} />}
      {name && <Row field="Name" value={name} />}
      <Row field="Phone No." value={phone} />
      {email && <Row field="Email" value={email} />}
      <Row field="Date of birth" date={Boolean(dateOfBirth)} value={dateOfBirth ?? 'Not Set'} />
      <Row field="Contact address" value={address} valueSize={8} />
    </S.Data>
  </S.Container>
);
