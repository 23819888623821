import styled from 'styled-components';

// import { ptr } from '../../../../styles/utils';

const Wrapper = styled.div`
`;

export {
  Wrapper,
};
