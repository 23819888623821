import React, { FC, useState } from 'react';
import { colors, fonts } from '../../styles/variables';
import { Icon } from '../Icon';
import { InfoConponent } from '../Info';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';
import * as S from './style';

interface IITemProps {
  label: string;
  value: string;
  subOptions?: Array<{
    label: string;
    value: string;
  }>;
  showSubOption?: boolean;
}

export interface MultiOptionProps {
  // toggle: () => void;
  items: IITemProps[];
  selectItem: (val: string) => void;
  // show: boolean;
  value?: string;
  placeholder: string;
}

const MultiOptions: FC<MultiOptionProps> = ({
  // toggle,
  // show,
  items,
  selectItem,
  value,
  placeholder,
}) => {
  const [itemsState, setItemsState] = useState(items);
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  function handleSelect(item: IITemProps, index: number) {
    if (item.subOptions?.length) {
      const newItems = itemsState.map((item, i) => {
        if (i == index) {
          item.showSubOption = !item.showSubOption;
        } else {
          item.showSubOption = false;
        }
        return item;
      });
      setItemsState(newItems);
    } else {
      selectItem(item.value);
      toggleModal();
    }
  }
  return (
    <>
      <ModalWrapper
        crossColor='#000000'
        onCloseModal={toggleModal}
        // maxWidth='100vw'
        maxHeight='100%'
        showModal={showModal}
        title='Select business sector'
      >
        <S.SectorContainer>
          <S.InfoContainer>
            <InfoConponent
              style={{ lineHeight: '25px' }}
              text='Businesses that fall under Designated Non-Financial Business and Professions 
(DNFBPs) will be required to provide SCUML certificate'
            />
          </S.InfoContainer>

          <S.Items>
            {itemsState.map((item, i) => (
              <S.Item key={i} onClick={() => handleSelect(item, i)}>
                <Text color={colors.black._20}>{item.label} </Text>
                {item.subOptions && (
                  <Icon name='arrow-right' color={colors.black._100} />
                )}
                {item.subOptions &&
                  item.subOptions.length > 0 &&
                  item.showSubOption && (
                    <S.DropDown>
                      <div
                        style={{
                          height: 50,
                          display: 'flex',
                          alignItems: 'center',
                          borderBottom: `solid 1px ${colors.black._1}`,
                          fontWeight: 700,
                        }}
                      >
                        Subcategories:
                      </div>
                      {item.subOptions?.map((subItem, i) => (
                        <S.DropdownItem
                          onClick={() => handleSelect(subItem, i)}
                        >
                          {subItem.label}
                        </S.DropdownItem>
                      ))}
                    </S.DropDown>
                  )}
              </S.Item>
            ))}
          </S.Items>
        </S.SectorContainer>
      </ModalWrapper>

      <div>
        <div
          style={{
            marginBottom: 7,
            fontFamily: fonts.inter.regular,
          }}
        >
          Business Sector
        </div>
        <S.MultiInput onClick={toggleModal}>
          {value && <Text color={colors.black._20}>{value}</Text>}

          {placeholder && !value && (
            <Text color={colors.black._1} size={6}>
              {placeholder}
            </Text>
          )}
          <Icon
            name='arrow-left'
            width={6}
            color={colors.black._100}
            rotate={-90}
          />
        </S.MultiInput>
      </div>
    </>
  );
};

export default MultiOptions;
