import styled, { css } from 'styled-components';

import { OtpInputProps } from '.';
import { fonts, colors, breakpoints } from '../../styles/variables';
import { ptr, crossBrowserPlaceholder } from '../../styles/utils';

const MAX_SIZE = 52;
const MAX_SIZE_WIDTH = 24;
const MARGIN = 20;
const FONT_SIZE = 22;
const SMALL_VERSION_MULTIPLIER = 1.7;

const Wrapper = styled.div`
  display: flex;
  // justify-content: center;
`;

const Input = styled.input<OtpInputProps>`
  width: ${ptr(MAX_SIZE)};
  height: ${ptr(MAX_SIZE)};
  max-width: ${ptr(MAX_SIZE)};
  max-height: ${ptr(MAX_SIZE)};
  background-color: ${colors.white._100};
  border-radius: ${ptr(6)};
  border: 0;
  font-size: ${ptr(FONT_SIZE)};
  color: #14142B;
  font-family: ${fonts.inter.bold};
  text-align: center;
  padding: 0px 16px;
  border-radius: 4px;
border: 1px solid  #D1D5DB; 
  @media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px){
  height: 4rem;
  width: 2rem;
}
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-ms-reveal, ::-ms-clear {
    display: none;
  }
  -moz-appearance: textfield;
  ${(props) => props.secure && css`
    -webkit-text-security: disc;
  `}
  ${crossBrowserPlaceholder(colors.black._40)}

  :not(:first-child) {
    margin-left: ${ptr(MARGIN)};
    @media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px){
 /* margin-left:5px; */
  }

  ${(props) => props.codeLength && css`
    @media (max-width: ${ptr(props.codeLength * MAX_SIZE + ((props.codeLength - 1) * MARGIN) + MARGIN * 2)}) {
      width: ${ptr(MAX_SIZE / SMALL_VERSION_MULTIPLIER)};
      height: ${ptr(MAX_SIZE / SMALL_VERSION_MULTIPLIER)};
      font-size: ${ptr(FONT_SIZE / SMALL_VERSION_MULTIPLIER)};
      
      :not(:first-child) {
        margin-left: ${ptr(MARGIN / SMALL_VERSION_MULTIPLIER)};
      }
    }
  `}
`;
const errorText = styled.p`
  color: ${colors.red._100};
 font-family: ${fonts.inter.regular};
 margin-top: 10px;
 margin-bottom: 0;
   display: flex;
  justify-content: center;
  `;

const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Wrapper,
  Input,
  errorText,
  PreloadContainer,
};
