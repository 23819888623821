import styled from 'styled-components';
import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border-radius: ${ptr(6)};
  background-color: ${colors.black._5};
  border: 1px solid ${colors.black._10};
  position: relative;
  cursor: pointer;
  padding: ${ptr(32)};
  min-width: ${ptr(370)};
  box-sizing: border-box;
  @media (max-width: ${breakpoints.sm}px) {
    min-width: ${ptr(260)};
    padding: ${ptr(16)};
  }
  :hover {
    opacity: 0.8;
  }
`;
const IconWrapper = styled.div<{ background?: string }>`
  width: ${ptr(40)};
  height: ${ptr(40)};
  border-radius: 100%;
  background-color: ${(props) =>
    props.background ? props.background : colors.pink._10};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`;
const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${ptr(16)};
  @media (max-width: ${breakpoints.sm}px) {
    margin-left: ${ptr(0)};
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: ${ptr(4)};
`;
const ArrowWrapper = styled.div`
  padding-left: ${ptr(16)};
`;
export { Wrapper, IconWrapper, TextsWrapper, TitleWrapper, ArrowWrapper };
