import React, { useEffect, useState } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import validator from 'validator';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';

import { validateObject, ValidateResult } from '../../../utils/validation';

import ConfigPassword from '../../../configs/password';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { credentialsActions } from '../config';
import { OnboardingFormHeader } from '../../../components/OnboardingFormHeader';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { ButtonContainer } from '../../../styles/wrappers';
import { InfoTab } from '../../../components/InfoTab';

interface IPasswords {
  pass: string;
  repeat: string;
}

const SCHEME_VALIDATION_PASSWORD = {
  minimumCharacters: (value: string) =>
    value.length >= ConfigPassword.minLength,
  uppercase: (value: string) => ConfigPassword.uppercase.test(value),
  lowercase: (value: string) => ConfigPassword.lowercase.test(value),
  special: (value: string) => ConfigPassword.special.test(value),
  same: (passwords: { pass: string; repeat: string }) =>
    !!passwords.pass &&
    !!passwords.repeat &&
    passwords.pass === passwords.repeat,
};

const SCHEME = {
  personalEmail: (value: string) => validator.isEmail(value),
};

const CommonCreateCredentials: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const credentials = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.credentials as Record<string, string>
  );

  /**
   * Local state initiation region
   */
  const [personalEmail, setPersonalEmail] = useState<string>(
    credentials.personalEmail || ''
  );
  const [passwords, setPasswords] = useState<IPasswords>({
    pass: '',
    repeat: '',
  });
  const [validation, setValidation] = useState<ValidateResult>();
  const [passValidation, setPassValidation] = useState<ValidateResult>();
  const [validInProgress, setValidInProgress] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    batch(() => {
      dispatch(
        OnboardingActions.handleSetData({
          credentials: {
            ...credentials,
            personalEmail,
            password: passwords.pass,
          },
        })
      );
    });

    route.push(credentialsActions.PERSONAL_INFO);
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setPassValidation(
      validateObject(
        {
          minimumCharacters: passwords.pass,
          uppercase: passwords.pass,
          lowercase: passwords.pass,
          special: passwords.pass,
          same: passwords,
        },
        SCHEME_VALIDATION_PASSWORD
      )
    );
  }, [passwords.pass, passwords.repeat]);

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            personalEmail,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [personalEmail]);

  return (
    <S.Container>
      <OnboardingFormHeader
        // description='Provide your company’s details'
        // descriptionColor='#6E7191'
        title='Set up login information'
        titleColor={colors.black._20}
      />

      <InfoTab
        style={{ marginTop: -50, marginBottom: 30, width: '85%' }}
        text='Note that the information provided here will become the super admin
          who will be able to create, disable and modify user roles. '
      />
      <OnboardingCard>
        <S.Wrapper>
          <S.EmailContainer>
            <S.FormInput.Wrapper>
              <TextInput
                label='Email address'
                value={personalEmail}
                valid={
                  !validInProgress && validation?.data.personalEmail.isValid
                }
                onChange={(value) => setPersonalEmail(value?.trim())}
              />
              {!validInProgress &&
                !!personalEmail &&
                !validation?.data.personalEmail.isValid && (
                  <S.InvalidTextWrapper>
                    <S.InvalidIconWrapper>
                      <Icon name='exclamation-mark' color={colors.red._100} />
                    </S.InvalidIconWrapper>
                    <Text color={colors.black._60} size={7}>
                      Invalid email
                    </Text>
                  </S.InvalidTextWrapper>
                )}
            </S.FormInput.Wrapper>
          </S.EmailContainer>

          <S.Passwords>
            <S.FormInput.Wrapper>
              <TextInput
                label='Password'
                type='password'
                value={passwords.pass}
                maxLength={ConfigPassword.maxLength}
                onChange={(value) =>
                  setPasswords({ ...passwords, pass: value })
                }
                // valid={
                //   !!passwords.pass &&
                //   passValidation?.data.minimumCharacters?.isValid &&
                //   passValidation?.data.uppercase?.isValid &&
                //   passValidation?.data.lowercase?.isValid &&
                //   passValidation?.data.special?.isValid
                // }
              />
            </S.FormInput.Wrapper>
            <S.PasswordDescriptionWrapper>
              <S.PasswordDescriptionItemWrapper>
                <>
                  {passValidation?.data.minimumCharacters?.isValid ? (
                    <Icon name='checked_validation' />
                  ) : (
                    <Icon name='uncheck_validation' />
                  )}
                  <Text color={colors.black._100} size={7}>
                    At least 8 characters.
                  </Text>
                </>
              </S.PasswordDescriptionItemWrapper>
              <S.PasswordDescriptionItemWrapper>
                <>
                  {passValidation?.data.uppercase?.isValid ? (
                    <Icon name='checked_validation' color={colors.green._100} />
                  ) : (
                    <Icon name='uncheck_validation' />
                  )}
                  <Text color={colors.black._100} size={7}>
                    At least one uppercase is entered.
                  </Text>
                </>
              </S.PasswordDescriptionItemWrapper>
              <S.PasswordDescriptionItemWrapper>
                <>
                  {passValidation?.data.lowercase?.isValid ? (
                    <Icon name='checked_validation' color={colors.green._100} />
                  ) : (
                    <Icon name='uncheck_validation' />
                  )}
                  <Text color={colors.black._100} size={7}>
                    At least one lowercase is entered.
                  </Text>
                </>
              </S.PasswordDescriptionItemWrapper>
              <S.PasswordDescriptionItemWrapper>
                <>
                  {passValidation?.data.special?.isValid ? (
                    <Icon name='checked_validation' color={colors.green._100} />
                  ) : (
                    <Icon name='uncheck_validation' />
                  )}
                  <Text color={colors.black._100} size={7}>
                    At least one special character is entered.
                  </Text>
                </>
              </S.PasswordDescriptionItemWrapper>
            </S.PasswordDescriptionWrapper>
            <S.FormInput.Wrapper>
              <TextInput
                label='Confirm password'
                type='password'
                value={passwords.repeat}
                maxLength={ConfigPassword.maxLength}
                onChange={(value) =>
                  setPasswords({ ...passwords, repeat: value })
                }
                // valid={!!passwords.repeat && passValidation?.data.same?.isValid}
              />
            </S.FormInput.Wrapper>
          </S.Passwords>
        </S.Wrapper>

        <ButtonContainer>
          <Button
            label='Continue'
            disabled={!validation?.isValid || !passValidation?.isValid}
            style={{ height: 40 }}
            onClick={continueHandle}
          />
        </ButtonContainer>
      </OnboardingCard>
    </S.Container>
  );
};
export default CommonCreateCredentials;
