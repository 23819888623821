import styled, { css } from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../styles/utils';
import { breakpoints } from '../../../styles/variables';
import { colors, fonts, transition } from '../../../styles/variables';

const Container = styled.div`
`;

const Wrapper = styled.div`
`;

const TopContainer = styled.div`
display: flex; 
gap:14px;
margin-bottom:14px;
flex-direction:column; 
`;

const ItemRow = styled.div`
margin-top: ${ptr(16)};
margin-bottom: ${ptr(12)};
display: flex;
gap: ${ptr(12)};
flex-wrap: wrap;
`;

const Rhs = styled.div`
display: flex; 
gap:24px;
cursor:pointer;
`;

const AddedTrusteeDiv = styled.div`
padding:12px 16px;
border-radius: 8px;
border: 1px solid #D9DBE9;
display: flex; 
justify-content: space-between;
align-items: center;
margin-bottom:20px;
background: var(--grayscale-input-background, #EFF0F6); 
`;

const TopTitle = styled.div`
display:flex;
flex-direction:column;
gap:30px;
margin-bottom:${ptr(30)};
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
    gap:10px;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const Add = styled.button`
   display: flex;
  align-items: center;
  max-width: fit-content;
  border: 0;
  padding: 5px 13px;
  border-radius: 20px;
  border: 1px solid #C8D2DF;
  background: #F1F1F5;
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-left: ${ptr(7)};
  }
`;

const InfoContainer
  = styled.div`
// width:85%;
  margin: 14px 0px 14px 0;

display: flex;
align-items: center;
gap:8px;

`;



const Tab1 = styled.div`
padding:8px;
max-width:fit-content;
border-radius: 8px;
display:flex;
gap:8px;
align-items:center; 
 

:hover {
    cursor: pointer;
    opacity: 0.9;
  }


 > span{
 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }

:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  @media screen and (max-width: ${breakpoints.sm}px) { 
   width:100% 
  }
`;

const TableAlign2 = styled.div`
display:flex;
align-items:center;
margin-top:15px;
border-radius:8px;
 height:43px;
 gap:14px;
width:fit-content; 

@media screen and (max-width: ${breakpoints.sm}px) {  
  padding:10px 0px;
  display: flex;
  flex-direction:column;
  height:fit-content;
  gap:20px
}
`;

const TypeTabs = styled.div`
margin-bottom:14px;
`;

const MarginLeftComp1 = styled.div`
padding:8px;
max-width:fit-content;
border-radius: 8px;
display:flex;
align-items:center; 
margin-left:  ${ptr(4)};
gap:8px;
 > span{
border-radius: 6px;
 
-webkit-transition: background-color 0.7s; /* For Safari 3.1 to 6.0 */
transition: background-color 0.7s;
  }

:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  @media screen and (max-width: ${breakpoints.sm}px) { 
    width:100% 
    margin-left:0px;

    > span{ 
      padding:7px 68px;
      width:100%; 
        }
   }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(18)}; 
  height: ${ptr(18)};
  border-radius: ${ptr(12)};

 border: ${ptr(1)} #B10180 solid;

 

  ${crossBrowserTransition(`${transition.slow}`)}
  
 
  

`;

const InsideCircle = styled.div`
  width: ${ptr(12)};
  height: ${ptr(12)};
  border-radius: ${ptr(9)};
background-color: #B10180
 
`;

const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ptr(94)} 0;
`;



export {
  Container, PreloadContainer, TypeTabs, Circle, InsideCircle, TableAlign2, Tab1, MarginLeftComp1, ItemRow, TopContainer, Wrapper, TopTitle, ButtonGroup, Add, InfoContainer, AddedTrusteeDiv, Rhs,
};
