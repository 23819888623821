import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const Container = styled.div`
  > button {
    width: 100%
  }
`;

const Head = styled.div`
  border-bottom: ${ptr(1)} solid ${colors.black._10};
  padding-bottom: ${ptr(32)};

  > span {
    margin-top: ${ptr(32)};
    display: block;
  }

  > :nth-child(2) {
    margin-top: ${ptr(4)};
    margin-bottom: ${ptr(16)};
  }
`;

const Wrapper = styled.div`
  margin: ${ptr(32)} 0;

  > :not(:first-child) {
    margin-top: ${ptr(20)};
  }
`;

const Add = styled.button`
  display: flex;
  align-items: center;
  max-width: fit-content;
  border: 0;
  background: none;
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-left: ${ptr(16)};
  }
`;

const OperatorRole = styled.div`
  > span {
    display: block;
    margin-bottom: ${ptr(16)};
    margin-top: ${ptr(32)};
  }
`;

export {
  Container, Wrapper, Add, Head, OperatorRole,
};
