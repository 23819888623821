import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import validator from "validator";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  DropdownInput,
  DropdownOptionProps,
} from "../../../components/DropdownInput";
import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import { Title } from "../../Onboarding/components/Title";
import { Text } from "../../../components/Text";
import { validateObject, ValidateResult } from "../../../utils/validation";
import {
  BusinessType,
  getBusinessRequestType,
  OnboardingInterface,
} from "../../../redux/onboarding/IOnboarding";
import { colors } from "../../../styles/variables";
import * as S from "./styles";
import { BusinessTypesEnum, idTypes, industryTypes, validIdTypes } from "../constants";
import onboardingService from "../../../services/internal/onboarding/onboarding.service";
import { OnboardingActions } from "../../../redux/onboarding/actions";
import { useHistory } from "react-router-dom";
import { actions } from "../config";
import CoperativeCompanyInfo from "../common.company.info/co-op.company.info";

const SCHEME = {
  businessName: (value: string) => value.length > 2,
  bvn: (value: string) => value.length === 11,
  idType: (value: { value: string }) => value?.value?.length > 0,
  phone: (value: string) => value.length === 11,
  cac: (value: string) => value.length > 0,
  tin: (value: string) => value.length > 0,
  personalEmail: (value: string) => validator.isEmail(value),
  industryType: (value: { value: string }) => value?.value?.length > 0,
};

const numberMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const CommonCompanyEnterDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const credentials = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.credentials as Record<string, string>
  );

  /**
   * Local state initiation region
   */

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.businessType as string
  );

  const corporateBusinessName = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporateBusinessName as string
  );

  const [bvn, setBvn] = useState<string>("");

  const [tin, setTin] = useState<string>("");
  const [cac, setCac] = useState<string>("");
  const [idType, setIdType] = useState<DropdownOptionProps | null>(null);
  const [idNumber, setIdNumber] = useState<string>("");
  const [validation, setValidation] = useState<ValidateResult>();
  const [personalEmail, setPersonalEmail] = useState<string>(
    credentials.personalEmail || ""
  );
  const [businessName, setBusinessName] = useState<string>(
    corporateBusinessName || ""
  );
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();
  const [validInProgress, setValidInProgress] = useState(false);
  const [industryType, setIndustryType] = useState<DropdownOptionProps | null>(
    null
  );
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState(false);

  /**
   * useEffect region
   */

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            businessName,
            bvn,
            idType,
            phone,
            cac,
            tin,
            personalEmail,
            industryType,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [businessName, bvn, idType, phone, cac, tin, personalEmail, industryType]);

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setIdNumberValidation(
        validateObject(
          {
            idNumber,
          },
          idNumberScheme
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [idNumber, idType]);

  const continueHandle = async () => {
    setLoading(true);
    const businessInfo = {
      name: businessName,
      cac,
      tin,
      industryType: industryType?.value ?? "",
      phone,
      email: personalEmail,
      idNumber,
      idType: idType?.value ?? "",
      bvn,
    };
    try {
      const response = await onboardingService.submitSignUpBusinessInfo({
        businessInfo: {
          ...businessInfo,
        },
        reference,
      });
      batch(() => {
        dispatch(OnboardingActions.handleSetBusinessInfo({ ...businessInfo }));
      });
      route.push(actions[response.actionRequired]);
    } catch (err : any) {
      if (err.code === "INVALID_ACTION_REQUIRED") {
        route.push(
          actions[err.message.trim().split(":")[1].replace(/\s/g, "")]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || "Can not complete request at the moment"
          )
        );
      }
    }
    setLoading(false);
  };

  if (businessType === BusinessTypesEnum.cooperativeSociety)
  return <CoperativeCompanyInfo />;

  return (
    <S.Container>
      <Title title="Company Information" />
      <S.Wrapper>
        <Text color={colors.black._60}>Tell us about your business</Text>

        <TextInput
          label="Business name"
          type="text"
          value={businessName}
          valid={validation?.data.businessName.isValid}
          onChange={setBusinessName}
        />

        <S.ItemRow>
          <TextInput
            label="Owner's BVN (Signatory)"
            type="currency"
            mask={createNumberMask(numberMaskOptions)}
            value={bvn}
            valid={validation?.data.bvn.isValid}
            onChange={(value) => setBvn(value)}
          />
          <DropdownInput
            label="Valid ID Type"
            options={[...validIdTypes]}
            onSelect={setIdType}
          />
        </S.ItemRow>
        <S.ItemRow>
          <TextInput
            label="Phone number"
            type="tel"
            mask={createNumberMask(numberMaskOptions)}
            value={phone}
            valid={validation?.data.phone.isValid}
            onChange={(value) => setPhone(value)}
          />
          <TextInput
            label="Valid ID Number"
            type="text"
            value={idNumber}
            name="idNumber"
            valid={idNumberValidation?.data.idNumber.isValid}
            onChange={setIdNumber}
          />
        </S.ItemRow>

        <S.ItemRow>
          <TextInput
            label="RC/BN Number"
            type="text"
            value={cac}
            name="cac"
            valid={validation?.data.cac.isValid}
            onChange={setCac}
          />

          <TextInput
            label="TIN (Tax identification No.)"
            type="text"
            value={tin}
            valid={validation?.data.tin.isValid}
            onChange={setTin}
          />
        </S.ItemRow>

        <S.ItemRow>
          <DropdownInput
            label="Industry type"
            options={industryTypes.map((industry) => ({
              label: industry,
              value: industry,
            }))}
            onSelect={setIndustryType}
          />
        </S.ItemRow>

        <S.ItemRow>
          <TextInput
            label="Email address"
            value={personalEmail}
            valid={!validInProgress && validation?.data.personalEmail.isValid}
            onChange={(value) => setPersonalEmail(value?.trim())}
          />
        </S.ItemRow>
      </S.Wrapper>
      <Button
        label="Continue"
        loading={loading}
        disabled={
          !validation?.isValid || !idNumberValidation?.isValid || loading
        }
        onClick={continueHandle}
      />
    </S.Container>
  );
};
export default CommonCompanyEnterDetails;
