import React, { CSSProperties, useEffect } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { colors } from '../../styles/variables';
import { Icon } from '../Icon';
import * as S from './styles';

export interface ModalWrapperProps {
  onCloseModal?: () => void;
  /**
   * Show modal
   */
  showModal?: boolean;
  crossColor?: string;
  overflowY?: string;
  overflowX?: string;
  maxHeight?: string | null;
  maxWidth?: string | null;
  title?: string;
  onBottom?: () => void;
  closeBackground?: string;
  titleStyle?: CSSProperties;
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  showModal = false,
  crossColor = colors.pink._100,
  overflowY = 'auto',
  overflowX = 'auto',
  maxHeight = null,
  maxWidth = '90%',
  onBottom = () => {},
  ...props
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });
  return (
    <BottomScrollListener onBottom={onBottom}>
      {(scrollRef: any) => (
        <S.Container showModal={showModal}>
          <S.Wrapper
            overflowY={overflowY}
            overflowX={overflowX}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            ref={scrollRef}
          >
            {props.onCloseModal && (
              <S.CrossWrapper background={props.closeBackground}>
                <S.Title style={props.titleStyle ?? {}}>{props.title}</S.Title>
                <Icon
                  width={12}
                  height={12}
                  name="cross"
                  color={crossColor}
                  onClick={props.onCloseModal}
                />
              </S.CrossWrapper>
            )}
            {props.children}
          </S.Wrapper>
        </S.Container>
      )}
    </BottomScrollListener>
  );
};
