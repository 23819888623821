import React from 'react';

import * as S from './styles';
import { Button } from '../Button';
import { Text } from '../Text';
import { colors } from '../../styles/variables';
import { Icon } from '../Icon';

export interface InfoPanelProps {
  /**
   * Info title text
   */
  title?: string;
  /**
   * Info description text
   */
  description?: string;
  /**
   * Button text
   */
  buttonText?: string;
  /**
   * Button click action
   */
  onButtonClick?: () => void;

  showInfoIcon?: boolean
}

const InfoPanelComponent: React.FC<InfoPanelProps> = ({ showInfoIcon = false, ...props }) => (
  <S.Wrapper>
    {showInfoIcon && <Icon name="pink-info" width={50} height={50} />}
    <S.TextWrapper>
      <Text color={colors.black._100} bold>{props.title}</Text>
      <S.DescriptionWrapper>
        <Text color={colors.black._100} size={6}>{props.description}</Text>
      </S.DescriptionWrapper>
    </S.TextWrapper>
    {props.buttonText && (
      <S.ButtonWrapper>
        <Button label={props.buttonText} onClick={props.onButtonClick} />
      </S.ButtonWrapper>
    )}
  </S.Wrapper>
);

export const InfoPanel = React.memo(InfoPanelComponent);
