import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { LeaderDirectorShareholderInterface } from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Icon } from '../../../components/Icon';
import { ImagePreviewModal } from '../../Onboarding/components/ImagePreviewModal';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../../components/DropdownInput';
import { Text } from '../../../components/Text';

import { validateObject, ValidateResult } from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { errorToString } from '../../../services/internal/app/index';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { getSignatureFormData } from '../../Onboarding/steps/common.api/index';
import { actions } from '../config';
import { BusinessTypesEnum } from '../constants';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { ConsentSample } from '../../Onboarding/components/ConsentSample';
import { capitalize } from '../../../utils/strings';

const SCHEME = {
  firstDirector: (data: DropdownOptionProps) => !!data?.key,
  secondDirector: (data: DropdownOptionProps) => (data ? !!data?.key : true),
};

type DirectorsList = Array<DropdownOptionProps>;

const CommonSoleSelectDirectors: React.FC = () => {
                                                    /**
                                                     * Hooks initiation region
                                                     */
                                                    const dispatch = useDispatch();
                                                    const route = useHistory();

                                                    /**
                                                     * Redux store initiation region
                                                     */
                                                    const leaders = useSelector(
                                                      (state: {
                                                        onboardingStack: OnboardingInterface;
                                                      }) =>
                                                        state.onboardingStack
                                                          .data
                                                          .directors as Array<
                                                          LeaderDirectorShareholderInterface
                                                        >
                                                    );
                                                    const consentEmails = useSelector(
                                                      (state: {
                                                        onboardingStack: OnboardingInterface;
                                                      }) =>
                                                        state.onboardingStack
                                                          .data
                                                          .consentEmails as Array<
                                                          string
                                                        >
                                                    );

                                                    const businessType = useSelector(
                                                      (state: {
                                                        onboardingStack: OnboardingInterface;
                                                      }) =>
                                                        state.onboardingStack
                                                          .data
                                                          .businessType as string
                                                    );
                                                    const reference = useSelector(
                                                      (state: {
                                                        onboardingStack: OnboardingInterface;
                                                      }) =>
                                                        state.onboardingStack
                                                          .data
                                                          .reference as string
                                                    );

                                                    /**
                                                     * Local state initiation region
                                                     */
                                                    const [
                                                      directors,
                                                      setDirectors,
                                                    ] = useState<DirectorsList>(
                                                      []
                                                    );
                                                    const [
                                                      firstDirector,
                                                      setFirstDirector,
                                                    ] = useState<DropdownOptionProps | null>(
                                                      null
                                                    );
                                                    const [
                                                      secondDirector,
                                                      setSecondDirector,
                                                    ] = useState<DropdownOptionProps | null>(
                                                      null
                                                    );
                                                    const [
                                                      modalVisible,
                                                      seModalVisible,
                                                    ] = useState<boolean>(
                                                      false
                                                    );
                                                    const [
                                                      validation,
                                                      setValidation,
                                                    ] = useState<
                                                      ValidateResult
                                                    >();
                                                    const [
                                                      loading,
                                                      setLoading,
                                                    ] = useState(false);

                                                    /**
                                                     * Custom handlers initiation region
                                                     */
                                                    const continueHandle = () => {
                                                      (async () => {
                                                        setLoading(true);
                                                        try {
                                                          let emails: Array<any> = [];
                                                          firstDirector &&
                                                            emails.push(
                                                              firstDirector.value
                                                            );
                                                          secondDirector &&
                                                            emails.push(
                                                              secondDirector.value
                                                            );

                                                          const response = await onboardingService.submitConsentLetter(
                                                            {
                                                              email: emails,
                                                              reference,
                                                            }
                                                          );
                                                          batch(() => {
                                                            dispatch(
                                                              OnboardingActions.handleSetData(
                                                                {
                                                                  reference:
                                                                    response
                                                                      .data
                                                                      .reference,
                                                                  // directors: response.data.directors,
                                                                  consentEmails: emails,
                                                                }
                                                              )
                                                            );
                                                            dispatch(
                                                              OnboardingActions.handleSetOnBoardingSummaryResponse(
                                                                response.data
                                                                  .data
                                                              )
                                                            );
                                                          });
                                                          route.push(
                                                            actions[
                                                              response
                                                                .actionRequired
                                                            ]
                                                          );
                                                        } catch (error) {
                                                          const err = error as any;
                                                          if (
                                                            err.code ===
                                                            'INVALID_ACTION_REQUIRED'
                                                          ) {
                                                            route.push(
                                                              actions[
                                                                err.message
                                                                  .trim()
                                                                  .split(':')[1]
                                                                  .replace(
                                                                    /\s/g,
                                                                    ''
                                                                  )
                                                              ]
                                                            );
                                                          } else {
                                                            dispatch(
                                                              OnboardingActions.handleSetError(
                                                                errorToString(
                                                                  err
                                                                )
                                                              )
                                                            );
                                                          }
                                                        }
                                                        setLoading(false);
                                                      })();
                                                    };

                                                    const onCloseModal = () => {
                                                      seModalVisible(false);
                                                    };

                                                    /**
                                                     * useEffect region
                                                     */
                                                    useEffect(() => {
                                                      setValidation(
                                                        validateObject(
                                                          {
                                                            firstDirector,
                                                            secondDirector,
                                                          },
                                                          SCHEME
                                                        )
                                                      );
                                                    }, [
                                                      firstDirector,
                                                      secondDirector,
                                                    ]);

                                                    useEffect(() => {
                                                      if (
                                                        leaders &&
                                                        leaders.length
                                                      ) {
                                                        setDirectors(
                                                          leaders.map(
                                                            (item, index) => ({
                                                              label: item.firstName
                                                                ? `${item.firstName} ${item.lastName}`
                                                                : item.email,
                                                              value: item.email,
                                                              category:
                                                                'Directors',
                                                              subLabel:
                                                                item.email,
                                                              key: index.toString(),
                                                            })
                                                          )
                                                        );
                                                      }
                                                    }, [leaders]);
                                                    //}, []);

                                                    useEffect(() => {
                                                      if (
                                                        directors?.length &&
                                                        consentEmails?.length
                                                      ) {
                                                        setFirstDirector(
                                                          () =>
                                                            directors.find(
                                                              (dir) =>
                                                                dir.value ===
                                                                consentEmails[0]
                                                            ) ?? null
                                                        );
                                                        setSecondDirector(
                                                          () =>
                                                            directors.find(
                                                              (dir) =>
                                                                dir.value ===
                                                                consentEmails[1]
                                                            ) ?? null
                                                        );
                                                      }
                                                    }, [
                                                      consentEmails,
                                                      directors,
                                                    ]);
                                                    function getLabel() {
                                                      switch (businessType) {
                                                        case BusinessTypesEnum.soleProprietorship:
                                                          return 'proprietor';
                                                        case BusinessTypesEnum.partnership:
                                                          return 'partner';
                                                        case BusinessTypesEnum.trusteeNgo:
                                                          return 'trustee';
                                                        case BusinessTypesEnum.cooperativeSociety:
                                                          return 'signatory';
                                                        default:
                                                          return 'director';
                                                      }
                                                    }

                                                    return (
                                                      <S.Container>
                                                        <ConsentSample
                                                          visible={modalVisible}
                                                          onClose={onCloseModal}
                                                          image='../../images/documents/certificate-3.jpeg'
                                                        />
                                                        <S.TopTitle>
                                                          <Title
                                                            color={
                                                              colors.black._20
                                                            }
                                                            title='Who will consent to onboarding the account on VBiz?'
                                                            subTitle={
                                                              businessType !==
                                                              BusinessTypesEnum.soleProprietorship
                                                                ? `Select 2 ${getLabel()}s from the options earlier provided`
                                                                : 'The option is from the proprietor you provided earlier'
                                                            }
                                                          />
                                                        </S.TopTitle>

                                                        {/* <S.File>
          <S.FullScreen onClick={() => seModalVisible(true)}>
            <Icon name='fullscreen' color={colors.black._100} />
          </S.FullScreen>
        </S.File> */}
                                                        <S.TopTextInfo
                                                          onClick={() =>
                                                            seModalVisible(true)
                                                          }
                                                        >
                                                          <Icon
                                                            color='#B10180'
                                                            name='info'
                                                          />
                                                          <Text
                                                            bold
                                                            size={7}
                                                            color='#B10180'
                                                          >
                                                            View sample of
                                                            consent letter
                                                          </Text>
                                                        </S.TopTextInfo>
                                                        <OnboardingCard>
                                                          <S.Wrapper>
                                                            <Text
                                                              bold
                                                              color={
                                                                colors.black
                                                                  ._100
                                                              }
                                                            >
                                                              Select{' '}
                                                              {directors.length >
                                                              2
                                                                ? `2 ${getLabel()}s`
                                                                : `a ${getLabel()}`}{' '}
                                                              who will consent
                                                              to onboarding the
                                                              account on VBiz?
                                                            </Text>
                                                            {businessType !==
                                                              BusinessTypesEnum.soleProprietorship && (
                                                              <div
                                                                style={{
                                                                  display:
                                                                    'flex',
                                                                  flexDirection:
                                                                    'column',
                                                                  gap: 10,
                                                                }}
                                                              >
                                                                <Text
                                                                  color={
                                                                    '#A0A3BD'
                                                                  }
                                                                >
                                                                  {capitalize(
                                                                    getLabel()
                                                                  )}{' '}
                                                                  1
                                                                </Text>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      'flex',
                                                                    flexDirection:
                                                                      'row',
                                                                    justifyContent:
                                                                      'space-between',
                                                                    gap: 15,
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      flex: 1,
                                                                    }}
                                                                  >
                                                                    <DropdownInput
                                                                      label={`${capitalize(
                                                                        getLabel()
                                                                      )}'s name`}
                                                                      placeholder={`Select ${capitalize(
                                                                        getLabel()
                                                                      )}`}
                                                                      options={directors.filter(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.key !==
                                                                          secondDirector?.key
                                                                      )}
                                                                      //value={firstDirector?.value}
                                                                      inputValue={
                                                                        firstDirector?.label
                                                                      }
                                                                      onSelect={
                                                                        setFirstDirector
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      flex: 1,
                                                                    }}
                                                                  >
                                                                    <DropdownInput
                                                                      label={`${capitalize(
                                                                        getLabel()
                                                                      )}'s email`}
                                                                      placeholder={`Select ${capitalize(
                                                                        getLabel()
                                                                      )}'s email`}
                                                                      options={directors
                                                                        .map(
                                                                          (
                                                                            dir
                                                                          ) => ({
                                                                            ...dir,
                                                                            label:
                                                                              dir.value,
                                                                          })
                                                                        )
                                                                        .filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.key !==
                                                                            secondDirector?.key
                                                                        )}
                                                                      //value={firstDirector?.value}
                                                                      onSelect={(
                                                                        item
                                                                      ) =>
                                                                        setFirstDirector(
                                                                          directors.find(
                                                                            (
                                                                              dir
                                                                            ) =>
                                                                              dir.value ===
                                                                              item?.value
                                                                          ) ||
                                                                            item
                                                                        )
                                                                      }
                                                                      inputValue={
                                                                        firstDirector?.value
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>

                                                                <div>
                                                                  <Text
                                                                    color={
                                                                      '#A0A3BD'
                                                                    }
                                                                  >
                                                                    {capitalize(
                                                                      getLabel()
                                                                    )}{' '}
                                                                    2
                                                                  </Text>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        'flex',
                                                                      flexDirection:
                                                                        'row',
                                                                      justifyContent:
                                                                        'space-between',
                                                                      gap: 15,
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        flex: 1,
                                                                      }}
                                                                    >
                                                                      <DropdownInput
                                                                        label={`${capitalize(
                                                                          getLabel()
                                                                        )}'s name`}
                                                                        placeholder={`Select ${capitalize(
                                                                          getLabel()
                                                                        )}`}
                                                                        options={directors.filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.key !==
                                                                            firstDirector?.key
                                                                        )}
                                                                        //value={firstDirector?.value}
                                                                        inputValue={
                                                                          secondDirector?.label
                                                                        }
                                                                        onSelect={
                                                                          setSecondDirector
                                                                        }
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        flex: 1,
                                                                      }}
                                                                    >
                                                                      <DropdownInput
                                                                        label={`${capitalize(
                                                                          getLabel()
                                                                        )}'s email`}
                                                                        placeholder={`Select ${capitalize(
                                                                          getLabel()
                                                                        )}'s email`}
                                                                        options={directors
                                                                          .map(
                                                                            (
                                                                              dir
                                                                            ) => ({
                                                                              ...dir,
                                                                              label:
                                                                                dir.value,
                                                                            })
                                                                          )
                                                                          .filter(
                                                                            (
                                                                              item
                                                                            ) =>
                                                                              item.key !==
                                                                              firstDirector?.key
                                                                          )}
                                                                        //value={firstDirector?.value}
                                                                        inputValue={
                                                                          secondDirector?.value
                                                                        }
                                                                        onSelect={(
                                                                          item
                                                                        ) =>
                                                                          setSecondDirector(
                                                                            directors.find(
                                                                              (
                                                                                dir
                                                                              ) =>
                                                                                dir.value ===
                                                                                item?.value
                                                                            ) ||
                                                                              item
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                            {businessType ===
                                                              BusinessTypesEnum.soleProprietorship && (
                                                              <DropdownInput
                                                                label={
                                                                  'Select Proprietor'
                                                                }
                                                                placeholder={
                                                                  'Select proprietor'
                                                                }
                                                                options={directors.filter(
                                                                  (item) =>
                                                                    item.key !==
                                                                    secondDirector?.key
                                                                )}
                                                                inputValue={
                                                                  firstDirector?.value
                                                                }
                                                                onSelect={
                                                                  setFirstDirector
                                                                }
                                                              />
                                                            )}

                                                            <S.ButtonGroup>
                                                              <div>
                                                                <Button
                                                                  label='Continue'
                                                                  loading={
                                                                    loading
                                                                  }
                                                                  style={{
                                                                    width: 304,
                                                                  }}
                                                                  onClick={
                                                                    continueHandle
                                                                  }
                                                                  disabled={
                                                                    !validation?.isValid ||
                                                                    loading
                                                                  }
                                                                />
                                                              </div>
                                                            </S.ButtonGroup>
                                                          </S.Wrapper>
                                                        </OnboardingCard>
                                                      </S.Container>
                                                    );
                                                  };
export default CommonSoleSelectDirectors;
