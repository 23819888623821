import styled, { css } from 'styled-components';

import { colors, breakpoints, fonts } from '../../../../styles/variables';
import { ptr } from '../../../../styles/utils';

const Container = styled.div`
  // padding: ${ptr(32)};
  // padding: ${ptr(12)};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  // background: ${colors.black._2};
  background: white;
  // width: 60%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${ptr(18)};
  margin-bottom: ${ptr(10)};
  flex-wrap: wrap;
  gap: 4px;
  > button {
    width: auto;
  }

  @media (max-width: ${ptr(breakpoints.md)}) {
    > button {
      margin-top: ${ptr(16)};
      margin-left: 0;
    }
  }
`;

const Body = styled.div``;

const SeeAll = styled.div`
  border-radius: 28px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--white, #fff);
  cursor: pointer;
  white-space: nowrap;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  > a {
    text-decoration: none;
    color: ${colors.black._100};
  }
`;

const Wrapper = styled.div`
  padding: ${ptr(16)} 0;
  display: flex;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:first-child) {
    border-top: ${ptr(1)} solid ${colors.black._5};
  }
`;

const Aside = styled.div`
  display: flex;
`;

const Icon = styled.div`
  width: ${ptr(48)};
  height: ${ptr(48)};
  border-radius: ${ptr(6)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${ptr(16)};

  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `}

  & > svg {
    transform: rotate(45deg);
  }
`;

const ShortData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: block;

    :first-child {
      margin-bottom: ${ptr(4)};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: ${ptr(300)};

      @media (max-width: ${ptr(1380)}) {
        max-width: ${ptr(200)};
      }

      @media (max-width: ${ptr(breakpoints.sm)}) {
        max-width: ${ptr(120)};
      }
    }
  }
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: block;

    :first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: ${ptr(200)};
      margin-bottom: ${ptr(4)};
      text-align: right;

      @media (max-width: ${ptr(1380)}) {
        max-width: ${ptr(90)};
      }

      @media (max-width: ${ptr(breakpoints.xl)}) {
        max-width: ${ptr(200)};
      }
    }

    :last-child {
      margin-left: auto;
      width: fit-content;
    }
  }
`;

const More = styled.div`
  text-align: center;
  font-family: ${fonts.inter.regular};
  margin-top: 20px;
  a {
    text-decoration: none;
    color: ${colors.pink._100};
  }
`;

const TableContainer = styled.div<{ loading: boolean }>`
  ${(props) =>
    props.loading &&
    css`
      opacity: 0.8;
    `}
`;

const BtnSuccess = styled.div`
  background: #f2f4f7;
  color: #36b83b;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 1px 6px 1px 6px;
  width: fit-content;
  border-radius: 10px;
`;

const BtnPending = styled.div`
  background: #f3fdf3;
  color: #344054;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 1px 6px 1px 6px;
  width: fit-content;
  border-radius: 10px;
`;

const BtnWarning = styled.div`
  background: #fff4d8;
  color: #f3a100;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 1px 6px 1px 6px;
  width: fit-content;
  border-radius: 10px;
`;

const BtnDanger = styled.div`
  background: #fef3f2;
  color: #b42318;
  display: flex;
  gap: 6px;
  align-items: center;
  text-align: center;
  padding: 1px 6px 1px 6px;
  width: fit-content;
  border-radius: 10px;
`;

const BtnUnknwn = styled.div`
  background: ${colors.amber._100};
  display: flex;
  gap: 6px;
  color: #ffffff;
  text-align: center;
  align-items: center;
  padding: 1px 6px 1px 6px;
  width: fit-content;
  border-radius: 10px;
`;

const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ptr(94)} 0;
`;

export {
  PreloadContainer,
  TableContainer,
  BtnPending,
  BtnSuccess,
  BtnWarning,
  BtnDanger,
  Container,
  BtnUnknwn,
  Header,
  Body,
  Wrapper,
  Aside,
  Icon,
  ShortData,
  SeeAll,
  Data,
  More,
};
