import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { colors } from '../../styles/variables';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { Button } from '../Button';

export interface LogoProps {
  title?: string;
  description: string;
  onClose: () => void;
  onClick?: () => void;
  show: boolean;
  buttonLabel?: string;
  buttonAction?: () => void;
}

export const SuccessModal: React.FC<LogoProps> = React.memo(
  ({
    title = 'Done!',
    description,
    onClose,
    onClick,
    show,
    buttonLabel,
    buttonAction,
  }) => {
    return (
      <ModalWrapper
        showModal={show}
        onCloseModal={onClose}
        crossColor={colors.black._100}
        overflowY="auto"
        overflowX="hidden"
        maxWidth="100%"
      >
        <div
          style={{
            width: '340px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 20px',
            gap: 20,
            textAlign: 'center',
            marginBottom: 50,
          }}
        >
          <Icon name="check_circle" />

          <div>
            <Text size={3} bold color={'#21202E'}>
              {title}
            </Text>
          </div>
          <div>
            <Text color={colors.black._60}>{description}</Text>
          </div>

          <Button
            style={{ width: '100%' }}
            onClick={buttonAction || onClose}
            label={buttonLabel || 'Got it'}
          />
        </div>
      </ModalWrapper>
    );
  }
);
