/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { Individual } from './Individual';
import { BusinessEntity } from './BusinessEntity';

export interface ViewComponentProps {
  Individual: any;

  BusinessEntity: any;
}

const CommonUbo: React.FC = () => {
  const { state } = useLocation<{ tab: string }>();
  const [CurrentUserView, ChangeuserView] = useState<string>('Individual');

  const ChangeUserDisplay = (userView: string) => {
    ChangeuserView(userView);
  };
  const UserViews: ViewComponentProps = {
    Individual: <Individual />,

    BusinessEntity: <BusinessEntity />,
  };

  const CurrentRenderView = CurrentUserView as keyof typeof UserViews;

  useEffect(() => {
    const view =
      state?.tab && state?.tab === 'UserReport' ? 'Individual' : 'Individual';
    ChangeuserView(view);
  }, []);

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedb, setIsCheckedb] = useState(false);

  //   useEffect(() => {
  //     setIsChecked(checked);
  //   }, [checked]);

  return (
    <S.Container>
      <S.TopTitle>
        <Title
          subtitleStyle={{ width: '90%', fontSize: 16 }}
          color={colors.black._20}
          title='Unveil Ultimate Beneficial Owners (UBO)  '
          subTitle='This applies to all individuals or business entities who hold 5% or more management control and ownership stake directly or indirectly in your company.'
        />
      </S.TopTitle>

      <S.InfoContainer>
        <Icon color='#B10180' name='info' />
        <Text bold size={7} color='#B10180'>
          Click here{' '}
          <Text bold size={7} color='#6E7191'>
            to know more about Ultimate Beneficial Owners (UBO)
          </Text>{' '}
        </Text>
      </S.InfoContainer>

      <OnboardingCard>
        <S.TopContainer>
          <Text bold size={7} color='#6E7191'>
            Select UBO type
          </Text>
        </S.TopContainer>

        <S.TypeTabs>
          <S.TableAlign2>
            <div>
              <S.Tab1
                style={{
                  border:
                    CurrentUserView === 'Individual'
                      ? '1px solid #B10180'
                      : '1px solid #EAECF0',
                }}
                onClick={() => {
                  ChangeUserDisplay('Individual');
                  setIsChecked(true);
                  setIsCheckedb(false);
                }}
              >
                <S.Circle>{isChecked && <S.InsideCircle />}</S.Circle>
                <Text
                  tag='span'
                  size={5}
                  background={
                    CurrentUserView === 'Individual' ? 'unset' : 'unset'
                  }
                  color={
                    CurrentUserView === 'Individual' ? '#394455' : '#394455'
                  }
                >
                  Individual
                </Text>
              </S.Tab1>
            </div>
            <div>
              <S.MarginLeftComp1
                style={{
                  border:
                    CurrentUserView === 'Individual'
                      ? '1px solid #EAECF0'
                      : '1px solid #B10180',
                }}
                onClick={() => {
                  ChangeUserDisplay('BusinessEntity');
                  setIsCheckedb(true);
                  setIsChecked(false);
                }}
              >
                <S.Circle>{isCheckedb && <S.InsideCircle />}</S.Circle>
                <Text
                  tag='span'
                  size={5}
                  background={
                    CurrentUserView === 'BusinessEntity' ? 'unset' : 'unset'
                  }
                  color={
                    CurrentUserView === 'BusinessEntity' ? '#394455' : '#394455'
                  }
                >
                  Business entity
                </Text>
              </S.MarginLeftComp1>
            </div>
          </S.TableAlign2>
        </S.TypeTabs>

        {UserViews[CurrentRenderView]}
      </OnboardingCard>
    </S.Container>
  );
};
export default CommonUbo;
