import { DirectorPreviewDetails, OnboardingSummary } from '../../services/internal/onboarding/IOnboarding';

export interface StackInterface {
	sideBarImage?: string;
	steps: Array<string>;
}

export type BusinessType = 'hasCompany' | 'hasNotCompany' | 'personalAccount' | undefined;

export type BusinessInfo = {
	phone?: string;
	email?: string;
	idNumber?: string;
	idType?: string;
	bvn?: string;
	name: string;
	industryType?: string;
	businessType?: string;
	tin?: string;
	cac?: string;
	address?: string;

};
export interface OnboardingInterface {
	inApp: boolean;
	requiredAction: string;
	currentStep: number;
	hasVBankAccount: boolean;
	businessType: BusinessType;
	stacks: Array<StackInterface>;
	error: string;
	businessInfo: BusinessInfo;
	data: Record<string, unknown>;
	serverResponse: Array<DirectorPreviewDetails>;
	serverSummaryResponse: OnboardingSummary;
	corporateBusinessName: string;
	corporateAccountNumber: string;
	corporateEmailAddress: string;
	corporatePhoneNumber: string;
	useDetails: boolean;
}

export const getBusinessRequestType = (businessType: BusinessType): string => {
	switch (businessType) {
		case 'hasCompany':
			return 'existing-company';
		case 'hasNotCompany':
			return 'new-company';
		case 'personalAccount':
			return 'individual';
		default:
			return '';
	}
};
