/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import paymentsService from '../../services/internal/payments/payments.service';
import { Icon } from '../../components/Icon';

import { Text } from '../../components/Text';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { Table, TableFooterProps } from '../../components/Table';
import { Preload } from '../../components/Preload';
import SearchInput from '../../components/SearchInput';
import { formatCash } from '../../utils/money';
import { IPagination, Pagination } from '../../components/Pagination';
import { IGetQueries } from '../../interface';
import getQueryString from '../../utils/getQueryString';
import { PaginationActions } from '../../redux/paginationSettings/actions';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { failed } from '../../components/Toasts';
import { getDateWithYearFirst, timeDateMonthString } from '../../utils/datetime';
import { PageTitle } from '../../styles/wrappers';
import { BackBar } from '../../components/BackBar';
import { Button } from '../../components/Button';
import { downloadCSV } from '../../utils/download';

export interface RecentTransactionsProps {
  data: Array<IPayments>,
  last: boolean,
}
export interface Transactions {
  amount: number
  created_at: string
  credit: number |string
  debit: string | number
  remarks: string
  running_balance: number
  serial: number
  submited_at: string
  trx_id: number
  type: string
  sessionId: string
}

export interface IPayments {
  accountId: number,
  amount: number,
  businessId: number,
  comment: string,
  createdAt: string,
  flagReason: any // TODO:
  gatewayResponse: string,
  gatewayResponseCode: string,
  id: number,
  isFlagged: boolean
  lastFailureResponse: any // TODO:
  receiverBankCode: string,
  receiverBvn: string,
  receiverClientId: string,
  receiverName: string,
  receiverNuban: string,
  receiverSavingsId: string,
  reference: string,
  retryCount: number,
  status: string, 
  updatedAt: string,
  userId: number,
}
export interface ModalViewComponent {
  FilterResult: any,
  PaymentDetails: any,

}

export const TransactionDetails: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const { accountId } = useLocation().state as { accountId: string };

  /**
   * Local state initiation region
   */

  const [payments, setPayments] = useState<Array<Transactions>>([]);
  const [empty, setEmpty] = useState<boolean>(false);
  const [bottomLoading, setBottomLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [currentPaymentDetails, setcurrentPaymentDetails] = useState<PaymentHistory>();
  // const [selectedPayment, setselectedPayment] = useState<Array<PaymentHistory>>([]);
  const [text, setText] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>({
    limit: 50, currentPage: 1, pageItems: 1, totalPages: 1,
  });
  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});

  useEffect(() => {
    PaginationActions.reset();
    getTransactions();
  }, []);

  const getTransactions = async (queriesData?:{ page:number }) => {
    setLoading(true);
    try {
      const queries = {
        page: 1,
        limit: 50,
        download: false,
        // start: getDateWithYearFirst(new Date()),
        // end: getDateWithYearFirst(new Date()),
        ...additionalQueries,
        ...queriesData,
      };
      const result:any = await paymentsService.getStatement(getQueryString({ ...queries, download: false }), accountId);
      setAddtionalQueries(queries);
      setPagination({
        limit: result.meta.limit, currentPage: result.meta.page, pageItems: result.meta.pageCount, totalPages: result.meta.totalPages,
      });
      if (result.data && result.data.transactions.length > 0) {
        setPayments(result.data.transactions.sort((a:Transactions, b:Transactions) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime() ? -1 : 1)));
      } else {
        setPayments([]);
      }
    } catch (error) {
      failed('Error', 'Failed to get transactions');
    }
    setLoading(false);
  };

  const handleSearchText = (value: string) => {
    setText(value);
    if (!value.length) {
      getTransactions();
    }
  };

  const searchPayments = async () => {
    if (text) {
      const newPayment = payments.filter((p) => p.trx_id.toString().search(text) !== -1);
      setPayments(newPayment);
    }
  };
  useEffect(() => {
    let delayDebounceFn: any = null;
    if (text.length) {
      delayDebounceFn = setTimeout(() => {
        const newPayment = payments.filter((p) => p.trx_id.toString().search(text) !== -1);
        setPayments(newPayment);
      }, 1000);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [text]);

  const downloadCsv = () => {
    let paymentToDownload = payments;
    if (paymentToDownload.length) {
      paymentToDownload = paymentToDownload.sort((a, b) => (a.serial > b.serial ? 1 : -1)).map((pay) => ({
        ...pay, 'S/N': pay.serial, 'Transaction ID': pay.trx_id, Remarks: pay.remarks, Amount: pay.amount, 'Session ID': pay.sessionId, Type: pay.type, Credit: pay.credit, Debit: pay.debit, Balance: pay.running_balance, Date: timeDateMonthString(new Date(pay.created_at)),
      }));
      const fields = ['S/N', 'Remarks', 'Amount', 'Transaction ID', 'Session ID', 'Type', 'Credit', 'Debit', 'Balance', 'Date'];
      downloadCSV(paymentToDownload, 'Transactions', fields);
    }
  };

  const renderTableItem = () => (

    <>
      <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]} isPage>
        <PageTitle.Wrapper>
          <BackBar name="Transaction Details" />
        </PageTitle.Wrapper>
        <S.TopWrapper>

          <S.TableConatiner>
            <S.TableIIConatiner>
              <SearchInput onChange={handleSearchText} value={text} onSubmitSearch={searchPayments} placeholder="Search for using reference and press enter" />
            </S.TableIIConatiner>
            <Button size="medium" onClick={downloadCsv} label="Download" />

          </S.TableConatiner>

        </S.TopWrapper>

        { payments && payments.length > 0
          ? (
            <S.TableContainer loading={loading}>
              <Table
                header={{
                  cells: {
                    0: {

                      align: 'left',
                      children: <Text size={7} bold>DESCRIPTION</Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    1: {
                      align: 'left',
                      children: <Text size={7} bold>DATE</Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    2: {
                      align: 'left',
                      children: <Text size={7} bold>AMOUNT</Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    // 3: {
                    //   align: 'right',
                    //   children: <Text size={7} bold>TYPE</Text>,
                    //   padding: '1.25rem 1.5rem',

                    // },

                    3: {
                      align: 'right',
                      children: <Text size={7} bold>TRN ID </Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    4: {
                      align: 'right',
                      children: <Text size={7} bold>SESSION ID </Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    5: {
                      align: 'right',
                      children: <Text size={7} bold>CREDIT</Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    6: {
                      align: 'right',
                      children: <Text size={7} bold>DEBIT</Text>,
                      padding: '1.25rem 1.5rem',

                    },
                    7: {
                      align: 'right',
                      children: <Text size={7} bold>BALANCE</Text>,
                      padding: '1.25rem 1.5rem',

                    },
                  },
                  padding: '1.25rem 0',
                }}
                footer={((): TableFooterProps | undefined => {
                  if (bottomLoading) {
                    return {
                      backgroundColor: colors.white._100,
                      cells: {
                        0: {
                          align: 'center',
                          children: <Icon name="small-loading" color={colors.black._100} />,
                          padding: '1.25rem 1.5rem',
                          colspan: 6,
                        },
                      },
                    };
                  }
                  if (empty) {
                    return {
                      backgroundColor: colors.white._100,
                      cells: {
                        0: {
                          align: 'center',
                          children: <Text>Result not found</Text>,
                          padding: '1.25rem 1.5rem',
                          colspan: 6,
                        },
                      },
                    };
                  }
                  return undefined;
                })()}
                rows={payments?.map((el:Transactions) => ({
                  padding: '1.25rem 0',
                  border: {
                    color: colors.black._10,
                    width: 1,
                    style: 'solid',
                    sides: ['top'],
                  },
                  cells: {
                    0: {
                      align: 'left',

                      children: (
                        <S.Column>
                          <Text size={7}>
                            {el.remarks}
                          </Text>
                        </S.Column>
                      ),
                      padding: '1.25rem 1.5rem',
                    },
                    1: {
                      align: 'left',

                      children: <Text size={6}>{timeDateMonthString(new Date(el.created_at))}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    2: {
                      align: 'left',
                      children: <Text size={6}>{`₦ ${formatCash(el.amount)}`}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    // 3: {
                    //   align: 'left',
                    //   children: <Text size={6}>{el.type}</Text>,
                    //   padding: '1.25rem 1.5rem',
                    // },
                    3: {
                      align: 'right',
                      children: <Text size={6}>{el.trx_id}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    4: {
                      align: 'right',
                      children: <Text size={6}>{el.sessionId}</Text>,
                      padding: '1.25rem 1.5rem',
                    },

                    5: {
                      align: 'right',
                      // onClick: () => setcurrentPaymentdetails(el),
                      children: <Text size={6}>{el.credit}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    6: {
                      align: 'right',
                      // onClick: () => setcurrentPaymentdetails(el),
                      children: <Text size={6}>{el.debit}</Text>,
                      padding: '1.25rem 1.5rem',
                    },
                    7: {
                      align: 'right',
                      // onClick: () => setcurrentPaymentdetails(el),
                      children: <Text size={6}>{el.running_balance}</Text>,
                      padding: '1.25rem 1.5rem',
                    },

                    // 7: {
                    //   align: 'right',
                    //   onClick: () => setcurrentPaymentdetails(el),
                    //   children: <Text size={6} color="#C6145E">View</Text>,
                    //   padding: '1.25rem 1.5rem',
                    // },
                  },
                }))}
              />

              {payments.length > 0 && <Pagination {...pagination} action={getTransactions} />}
            </S.TableContainer>
          ) : null}
      </AccessControl>
    </>

  );

  return (
    <S.Container>
      {/* { renderModalView()} */}

      { loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      )
        : (
          renderTableItem()

        )}

    </S.Container>
  );
};
