import styled from 'styled-components';

import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  > :not(:first-child) {
    margin-top: ${ptr(12)};
  }
`;

const AddOneMoreWrapper = styled.div`
  margin-top: ${ptr(28)}!important;
`;

export {
  Wrapper,
  AddOneMoreWrapper,
};
