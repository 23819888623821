import styled from 'styled-components';
import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const ManualContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${ptr(30)};
  width: ${ptr(634)};
  gap: ${ptr(25)};

  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export { ManualContainer, TitleContainer, InputContainer, ButtonContainerTwo };
