import styled, { css } from 'styled-components';

import { ClickablePanelProps } from '.';
import { fonts, colors, transition } from '../../styles/variables';
import {
  crossBrowserInlineFlex, crossBrowserBoxShadow, crossBrowserTransition, ptr,
} from '../../styles/utils';

const Wrapper = styled.button<ClickablePanelProps>`
  ${crossBrowserInlineFlex()}
  
  flex-direction: column;

  width: 100%;
  height: ${ptr(120)};
  
  position: relative;
  overflow: hidden;
  text-align: left;
  border: 0;
  padding: 0;

  ${crossBrowserTransition(transition.slow)}

  border-radius: ${ptr(6)};
  background-color: ${colors.pink._100};
  color: ${colors.white._100};

  :hover {
    cursor: pointer;
    background-color: ${colors.pink._80};
  }

  :focus {
    ${crossBrowserBoxShadow(`inset 0 0 0 ${ptr(2)} ${colors.pink._20}`)}
  }

  ${(props) => props.disabled && css`
    :hover {
      cursor: default;
      background-color: ${colors.pink._40};
    }

    background-color: ${colors.pink._40};
  `};

  ${(props) => props.theme === 'light' && css`
    background-color: ${colors.black._5};
    color: ${colors.black._100};
    :hover {
       background-color: ${colors.black._2};
    }
  `}
`;

const Paddings = styled.div<ClickablePanelProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: ${ptr(20)} ${ptr(24)};
  
  ${(props) => props.icon && css`
    justify-content: space-between;
  `}
`;

const Title = styled.div<ClickablePanelProps>`
  font-family: ${fonts.inter.bold};
  font-size: ${ptr(24)};
  line-height: 140%;
  z-index: 2;
  text-decoration: none;

  ${(props) => !props.description && !props.subDescription && css`
    text-align: center;
  `}
`;

const Description = styled.div<ClickablePanelProps>`
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(14)};
  padding-top: ${ptr(2)};
  padding-right: ${ptr(20)};
  line-height: 140%;
  z-index: 2;
  text-decoration: none;

  ${(props) => props.theme === 'light' && css`
    font-family: ${fonts.inter.bold};
    font-weight: 900;
  `}
`;

const SubDescription = styled.div<ClickablePanelProps>`
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(10)};
  padding-top: ${ptr(2)};
  padding-right: ${ptr(20)};
  line-height: 140%;
  opacity: 0.7;
  z-index: 2;
  text-decoration: none;
`;

const Badge = styled.div`
  padding: ${ptr(4)} ${ptr(8)};
  background-color: ${colors.white._100};
  position: absolute;
  top: ${ptr(20)};
  right: ${ptr(20)};
  border-radius: ${ptr(4)};
  font-family: ${fonts.inter.regular};
  color: ${colors.pink._100};
  font-size: ${ptr(14)};
`;

export {
  Wrapper,
  Paddings,
  Title,
  Description,
  Badge,
  SubDescription,
};
