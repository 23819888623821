import styled from 'styled-components';
import { ptr } from '../../../styles/utils';

const PasswordDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${ptr(4)} 0 ${ptr(24)} 0;
  
  > * {
    margin-bottom: ${ptr(8)};
  }
`;

const PasswordDescriptionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > * {
    margin-right: ${ptr(8)};
  }
`;

const buttonContainer = styled.div`
width: 100%!important;
button{
  width: 100%!important;
}
`;

export {
  PasswordDescriptionWrapper,
  PasswordDescriptionItemWrapper,
  buttonContainer,
};
