import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components/LoadingModal';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { InfoConponent } from '../../../components/Info';
import { FormTitle } from '../../../components/FormTitle';
import { TextInput } from '../../../components/TextInput';
import { Icon } from '../../../components/Icon';
import { CompanyInterface } from '../../../redux/company/ICompany';
import subaccountService from '../../../services/internal/subaccount/subaccount.service';
import { failed } from '../../../components/Toasts';
import { SubaccountInterface } from '../../../redux/subaccount/ISubaccount';
import { SubaccountActions } from '../../../redux/subaccount/actions';

export const SubaccountAccountInfo: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  const [loading, setLoading] = useState(false);

  const [accountName, setAccountName] = useState<string>('');

  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const subaccountRequest = useSelector(
    (state: { subaccount: SubaccountInterface }) =>
      state.subaccount.subaccountRequest
  );

  useEffect(() => {
    if (subaccountRequest && subaccountRequest.name && activeCompany) {
      const existingName = subaccountRequest.name
        .replace(activeCompany.name, '')
        .replace('-', '');
      setAccountName(existingName || '');
    }
  }, [subaccountRequest, activeCompany]);

  const continueHandle = async () => {
    try {
      setLoading(true);
      if (activeCompany && subaccountRequest) {
        await subaccountService.createSubaccount({
          businessId: activeCompany.id,
          name: accountName,
          requestId: subaccountRequest?.id,
        });
        dispatch(
          SubaccountActions.handleSetSubaccountRequest({
            ...subaccountRequest,
            name: accountName,
          })
        );

        route.push('/subaccount/board-resolution');
      }
    } catch (error) {
      const err = error as any;
      failed('Error', err.message);
    }
    setLoading(false);
  };

  return (
    <S.Container>
      <Modal visible={loading} />
      <S.TopTitle>
        <FormTitle
          margin={-3}
          title='Account information'
          titleColor={colors.black._20}
        />
        <S.InfoComponent>
          <InfoConponent
            style={{ background: '#FFF9EF' }}
            text='You are opening this sub account based on the information and documents of the main account.'
          />
        </S.InfoComponent>
      </S.TopTitle>

      <OnboardingCard>
        <S.Wrapper>
          {/* <S.AccountName> */}
          <Text color={colors.black._20}>Account name </Text>
          <Text size={6} color='#A0A3BD'>
            (Main account name - Sub account name){' '}
          </Text>
          {/* </S.AccountName> */}
          <S.ItemRowB
            style={{
              display: 'flex',
              gap: 15,
              width: '100%',
              marginTop: '3px',
            }}
          >
            {activeCompany && (
              <S.TextInputOuterText>
                <Text color={colors.black._20}>{activeCompany.name}</Text>
              </S.TextInputOuterText>
            )}

            <S.TextInputLine>
              <div></div>
            </S.TextInputLine>

            <S.TextInputB style={{ flex: 1 }}>
              <TextInput
                value={accountName}
                type='text'
                // showBorder={false}
                borderStyle='none'
                labelExtraColor='#A0A3BD'
                placeholder='Enter name'
                onChange={setAccountName}
              />
            </S.TextInputB>
          </S.ItemRowB>

          {/* <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
            <div style={{ flex: 1 }}>
              <TextInput
                value={accountName}
                label='Account name'
                labelExtra='(Main account name - Sub account name)'
                type='text'
                labelExtraColor='#A0A3BD'
                placeholder='Enter first name'
                onChange={setAccountName}
              />
            </div>
          </S.ItemRow> */}

          <S.InfoWrapper>
            <div>
              <Icon name='file-mini' />
            </div>

            <div>
              <Text color='#6E7191'>
                Your sub account opening name will be:{'  '}
              </Text>
              <Text style={{ marginLeft: 1 }} bold color={colors.black._20}>
                {'    '}
                {activeCompany?.name ?? ''}
              </Text>
              <Text size={7} style={{ fontStyle: 'italic' }} color='#A0A3BD'>
                -{accountName || 'sub account name'}
              </Text>
            </div>
          </S.InfoWrapper>

          {/* <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
            <div style={{ flex: 1 }}>
              <TextInput
                value={phone}
                label='Phone number '
                labelExtra='(optional)'
                type='currency'
                labelExtraColor='#946200'
                placeholder='Enter phone number'
                onChange={setPhone}
              />
            </div>

            <div style={{ flex: 1 }}>
              <TextInput
                value={address}
                label='Address '
                labelExtra='(optional)'
                type='text'
                labelExtraColor='#946200'
                placeholder='Enter Address'
                onChange={setAddress}
              />
            </div>
          </S.ItemRow> */}

          <S.ButtonGroup>
            <div>
              <Button
                loading={loading}
                style={{ width: 304 }}
                label='Continue'
                onClick={() => continueHandle()}
                disabled={!accountName || loading}
              />
            </div>
          </S.ButtonGroup>
        </S.Wrapper>
      </OnboardingCard>
    </S.Container>
  );
};
