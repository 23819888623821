/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OnboardingActions } from '../../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../../redux/onboarding/IOnboarding';

import { DirectorPreviewDetails } from '../../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../../components/Button';
import { Title } from '../../components/Title';
import { ManDetails } from '../../components/ManDetails';

import { actionsRequired } from '../../onboardingConfig';

import * as S from './styles';

const SUBMIT_SHAREHOLDERS = 'SUBMIT_SHAREHOLDERS';

// TEMPORARY

export const PurviewDetails:FC<{ purviewData: Array<DirectorPreviewDetails>, hasRoles?:
boolean, onlyText?: boolean }> = ({ purviewData, onlyText }) => (
  <>
    {purviewData && purviewData.length && purviewData.map((data, index) => (
      <ManDetails
        key={index}
        name={`${data.firstName} ${data.middleName} ${data.lastName}`}
        phone={data.phone || '+xxx xxx xxx xxxx'}
        dateOfBirth={data.dob}
        address={data.companyAddress || data.address || 'Not Set'}
        image={data.avatarUrl}
        onlyText={onlyText}
      />
    ))}
  </>
);

export const CommonCompanyCheckManagement: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();

  const onboarding = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack,
  );

  const inApp = useSelector((state: { onboardingStack: OnboardingInterface }) => state
    .onboardingStack.inApp);

  /**
   * Redux store initiation region
   */

  const directorPreviewDetails = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.serverResponse as Array<DirectorPreviewDetails>,
  );

  /**
   * Custom handlers initiation region
   */
  const continueHandle = () => {
    // debug.info('', [leaders, reference]); // TEMPORARY
    if (onboarding.requiredAction === SUBMIT_SHAREHOLDERS && inApp) {
      const lastStack = onboarding.stacks[onboarding.stacks.length - 1];
      lastStack.steps.splice(onboarding.currentStep + 1, 0,
        ...actionsRequired.SUBMIT_SHAREHOLDERS);
      dispatch(OnboardingActions.handleNextStep());
      return;
    }
    dispatch(OnboardingActions.handleNextStep());
  };
  return (
    <S.Container>
      <Title title="Check management details" />
      <S.Wrapper>
        <PurviewDetails purviewData={directorPreviewDetails} />
      </S.Wrapper>
      <Button label="Continue" onClick={continueHandle} />
    </S.Container>
  );
};
