/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { batch, useSelector, useDispatch } from 'react-redux';
import validator from 'validator';
import { useHistory, useLocation } from 'react-router-dom';
import {
  OnboardingInterface,
  BusinessType,
} from '../../../redux/onboarding/IOnboarding';

import {
  BasicLeaderDirectorShareholderInterface,
  LeaderDirectorShareholderInterface,
  TrusteeInfoInterface,
} from '../../../services/internal/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
  validateObject,
  ValidateResult,
} from '../../../utils/validation';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { InfoForm } from '../../Onboarding/components/InfoForm';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { actions } from '../config';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import SignUpProprietorsDetails from './components/SignUpProprietorsDetails';
import { businessTypes, idTypes, SIGN_UP, validIdTypes } from '../constants';
import { InfoConponent } from '../../../components/Info';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { TextInput } from '../../../components/TextInput';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../../components/DropdownInput';

const SCHEME = {
  firstName: (value: string) => value.length > 1,
  lastName: (value: string) => value.length > 1,
  phone: (value: string) => value.length === 11,
  email: (value: string) => validator.isEmail(value),
  // bvn: (value: string) => value.length === 11,
  // idType: (value: { value: string }) => value?.value?.length > 0,
  // businesType: (value: { value: string }) => value?.value?.length > 0,
};

const directorDefaultItem = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

const CommonProprietorsDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  const [idNumber, setIdNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [bvn, setBvn] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [validation, setValidation] = useState<ValidateResult>();
  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();
  /**
   * Redux store initiation region
   */

  const onboardingType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.type as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const business = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.business as Record<string, string>
  );
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<TrusteeInfoInterface>
  );

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<TrusteeInfoInterface>>(
    leaders?.length
      ? leaders.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
          email: item.email,
        }))
      : [directorDefaultItem]
  );
  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const push = () => {
    setCollection([...collection, directorDefaultItem]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: TrusteeInfoInterface, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }
          return el;
        }) as Array<TrusteeInfoInterface>
    );
  };

  const continueHandle = async () => {
    setLoading(true);
    const proprietor = [{ firstName, lastName, phone, email }];
    try {
      const response = await onboardingService.submitProprietorsDetails({
        proprietors: proprietor,
        reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            directors: [...proprietor],
            reference: response.data.reference,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  const [idType, setIdType] = useState<DropdownOptionProps | null>(null);
  const [validInProgress, setValidInProgress] = useState(false);
  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };
  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject({ firstName, lastName, phone, email }, SCHEME)
      );
      setIdNumberValidation(
        validateObject(
          {
            idNumber,
          },
          idNumberScheme
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [idNumber, idType, firstName, lastName, email, phone]);

  if (onboardingType === SIGN_UP) return <SignUpProprietorsDetails />;

  const [businesType, setBusinessType] = useState<DropdownOptionProps | null>();
  return (
    <S.Container>
      <Title
        color={colors.black._20}
        title='Management Details'
        subTitle='Provide details of the sole proprietor '
      />

      <S.InfoContainer>
        <InfoConponent text='Note that the consent of person provided here will be required for the account to become operational on VBiz.' />
      </S.InfoContainer>

      <OnboardingCard>
        <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
          <div style={{ flex: 1 }}>
            <TextInput
              label='First name'
              type='text'
              value={firstName}
              onChange={setFirstName}
              placeholder='Enter first name'
              valid={validation?.data.firstName.isValid}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextInput
              label='Last name'
              type='text'
              value={lastName}
              onChange={setLastName}
              placeholder='Enter last name'
              valid={validation?.data.lastName.isValid}
            />
          </div>
        </S.ItemRow>

        <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
          <div style={{ flex: 1 }}>
            <TextInput
              label='Phone number'
              type='text'
              value={phone}
              onChange={setPhone}
              placeholder='Enter phone number'
              valid={validation?.data.phone.isValid}
            />
          </div>

          <div style={{ flex: 1 }}>
            <TextInput
              label='Email address'
              type='text'
              value={email}
              onChange={setEmail}
              placeholder='Enter email address'
              valid={validation?.data.email.isValid}
            />
          </div>
        </S.ItemRow>

        <S.ButtonGroup>
          <div>
            <Button
              onClick={continueHandle}
              loading={loading}
              label='Continue'
              disabled={!validation?.isValid || loading}
            />
          </div>
        </S.ButtonGroup>
      </OnboardingCard>
    </S.Container>
  );
};
export default CommonProprietorsDetails;
