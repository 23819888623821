import styled, { css } from 'styled-components';

import { breakpoints, colors, transition } from '../../styles/variables';
import { crossBrowserTransition, ptr } from '../../styles/utils';

interface MainMenu {
  active?: boolean;
  opened?: boolean;
  firstOpen?: boolean;
}

const EntityDetails = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  position: relative;
`;

const EntityDiv = styled.div`
  background: #eff0f0;
  border: 1px solid #d9dbe9;
  height: 4rem !important;
  border-radius: 8px;
  // width:100%;
  padding: 0.3125rem 0.5rem 0.4375rem 0.5rem;
  display: flex;
  justify-content: space-between;

  align-items: center;
`;

const Wrapper = styled.div<MainMenu>`
  position: fixed;
  display: none;
  width: ${ptr(343)};
  /* z-index: 9; */
  margin-top: 91px;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    z-index: 999;
  }

  ${(props) =>
    props.opened &&
    !props.firstOpen &&
    css`
      display: block !important;
      animation: ${transition.slow} open;
    `};

  ${(props) =>
    !props.opened &&
    !props.firstOpen &&
    css`
      display: block !important;
      animation: ${transition.slow} close forwards;
    `};

  @media (max-width: ${ptr(breakpoints.lg)}) {
    height: 100%;
    width: 100%;
  }

  @keyframes open {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
    }
    100% {
      display: none;
      transform: translateX(-100%);
      opacity: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: ${ptr(0)} ${ptr(13)} ${ptr(8)} ${ptr(21)};

  min-height: 100%;
  height: 100vh;
  overflow-y: auto;
  border-right: ${ptr(1)} solid ${colors.black._10};
  z-index: 2;
  background-color: ${colors.white._100};

  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${ptr(breakpoints.lg)}) {
    padding: ${ptr(16)} ${ptr(32)} ${ptr(32)} ${ptr(32)};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    padding-bottom: ${ptr(16)};
  }
`;

const CloseMenuButtonWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: flex;
  }
`;

const CompanyNameWrapper = styled.div`
  padding: ${ptr(44)} ${ptr(24)} ${ptr(0)} ${ptr(0)};
  width: 100%;
  display: block;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    /* display: none; */
  }
`;

const CompanyNameAndProfileWrapper = styled.div`
  padding: ${ptr(44)} 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${ptr(1)} solid ${colors.black._10};

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: flex;
  }
`;

const CompanyNameTextWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 12px;
`;

const MenuItemsWrapper = styled.div`
  padding: 0;
  height: 100%;
  overflow-y: auto;
`;

const MenuMainItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MenuItemLeftWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 43px;
  margin-bottom: ${ptr(12)};
  padding: ${ptr(8)} ${ptr(24)};
  border-radius: 5px;

  :hover {
    background: #eff0f6;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  // padding: ${ptr(24)} 0;
  justify-content: space-between;
`;

const MenuItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(24)};
`;

const MenuItemTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${ptr(16)};
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: ${ptr(20)};
  height: ${ptr(20)};
  border-radius: 50%;
  background-color: ${colors.pink._100};
`;

const Company = styled.div``;

const DropownContent = styled.div<{ openDrop: boolean }>`
  display: ${(props) => (props.openDrop ? css`none` : css`block`)};
  position: absolute;
  //   top: 49px;
  //   right: 0;
  margin-top: 10px;
  min-width: 220px;
  width: fit-content;
  max-height: 250px;
  // height: 400px;
  overflow: auto;
  background: ${colors.white._100};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  z-index: 9999;
  padding: 33px 0px 26px 0px;
  padding: 12px;

  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 8px 24px -6px rgba(0, 0, 0, 0.06);

  ${(props) =>
    props.openDrop
      ? css`
          border-top: ${ptr(1)} solid ${colors.black._10};
        `
      : css`
          border-top: ${ptr(0)} solid ${colors.black._10};
        `}

  ${crossBrowserTransition(transition.slow)}
`;

const DropdownWrapper = styled.div<{ openDrop: boolean }>`
  cursor: pointer;
  padding-left: ${ptr(4)};
`;

const AccountItemInfoBlock = styled.div`
  padding: 0 ${ptr(8)};
`;

const AccountItemBox = styled.div`
  display: flex;
  flexdirection: 'row';
  flex: 1;
  width: 260px;
  margin-top: 15px;
  align-items: center;
  margin-bottom: ${ptr(8)};
  border: '1px solid #000';
  justify-content: space-between;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  background: rgba(219, 223, 228, 0.39);
  @media (max-width: ${ptr(breakpoints.sm)}) {
    width: 250px;
  }
`;

const AccountItemBalanceBlock = styled.div`
  display: flex;
  height: inherit;
  align-items: center;
`;

export {
  Wrapper,
  ContentWrapper,
  HeaderWrapper,
  AccountItemBalanceBlock,
  CloseMenuButtonWrapper,
  CompanyNameWrapper,
  CompanyNameAndProfileWrapper,
  CompanyNameTextWrapper,
  MenuItemsWrapper,
  DropownContent,
  Company,
  AccountItemInfoBlock,
  AccountItemBox,
  MenuMainItemsWrapper,
  MenuItemLeftWrapper,
  MenuItem,
  DropdownWrapper,
  MenuItemIconWrapper,
  MenuItemTextWrapper,
  EntityDetails,
  EntityDiv,
  Badge,
};
