import React, { FC } from 'react';
import { colors, fonts } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import * as S from './style';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { Button } from '../Button';
import { useHistory, useLocation } from 'react-router-dom';
import { limit, twoFactor } from '../../navigation';
import { useSelector } from 'react-redux';
import { InfoConponent } from '../Info';
import { formatCash } from '../../utils/money';
import WhatsNew from '../../assets/images/whatsnew.svg';

interface IProps {
  toggle: () => void;
  show: boolean;
}
const WhatNewModal: FC<IProps> = ({ toggle, show }) => {
  const history = useHistory();

  const checkItOut = () => {
    history.push(`/settings/requests`);
    toggle();
  };

  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      // onCloseModal={toggle}
      crossColor={colors.black._100}
      // title="Daily transaction limit"
      titleStyle={{
        fontSize: 28,
        fontWeight: 700,
        color: '##2C2A3B',
        fontFamily: fonts.inter.bold,
      }}
    >
      <S.ContentContainer>
        <S.InfoContainer>
          <div>
            <img src={WhatsNew} alt="Whats new" />
          </div>

          <Text bold size={2}>
            What's New
          </Text>

          <Text size={4} style={{ textAlign: 'center' }}>
            Check out the{' '}
            <strong style={{ fontFamily: fonts.inter.bold }}>
              Transaction Limit Increase Feature.
            </strong>{' '}
            With this update, you can enjoy higher transaction limits, making it
            easier to manage your finances.
          </Text>

          <S.ButtonContainer>
            <Button
              theme="primary"
              // size="small"
              label="Check it out"
              onClick={checkItOut}
            />
            <Button
              theme="light"
              // size="small"
              label="Skip for now"
              onClick={toggle}
            />
          </S.ButtonContainer>
        </S.InfoContainer>
      </S.ContentContainer>
    </ModalWrapper>
  );
};

export default WhatNewModal;
