import styled, { } from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';

// import { ptr } from '../../styles/utils';

// import { hexToRgbA, ptr } from '../../../../styles/utils';

type ContainerType = {
  backgroundColor?: string;
};

const Container = styled.div`
 border-radius: 20px 20px 0 0;
`;

const TopHeader = styled.div`
background: #c6145e;
color: #fff;
padding:3rem ;
display:flex;
flex-direction: column;
position: sticky;
top: 40px;
 
`;
// #36B83B
const SuccessHeader = styled.div<ContainerType>`
 

background: ${(props: ContainerType) => props.backgroundColor};
color: #fff;
padding:1rem 0 ;
text-align: center;
display:flex;
flex-direction: column;
 `;

const CloseButton = styled.div`
text-align:right;
margin-right: 35px;
`;

const AccountDetails = styled.div`
margin-top:1rem;
padding:3rem ;
table{
width:100%;
border-spacing: 10px;
border-collapse: separate;

}

`;

const CustomTable = styled.table`
th, td {
border: none;
border-collapse: collapse;
}
th, td {
padding: 5px;

text-align: left;    
}
tr{
padding: 15px;
}

th:nth-child(2){
text-align: right;  
}
`;

const ButtonStyle = styled.div`
display: flex;
justify-content: center;
width:100%;
margin-top:2rem;

button{
width:100%;
}
`;

const HrStyled = styled.hr`
margin:1rem 1rem;
 
 border-top: 1px solid #E9EBF1;
`;

const VertialHrStyled = styled.hr`
   width: '1'; padding: '5px'; margin: '5px'; border-top: '1px solid #E9EBF1';
`;

const TotalStyle = styled.div`
 display: flex; 
 justify-content: space-between; 
 padding: 1rem 1rem ;
`;

// payment
const PaymentWrapper = styled.div`
background: #FFFFFF;
 padding:2rem ;
text-align:center;
display:flex;
flex-direction: column;
border-radius: 20px ;
@media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px){
  padding: 2rem 10px
}
`;
const WarningWrapper = styled.div`
background: #FFFFFF;
 padding:2rem ;
 padding-top:0 ;
text-align:center;
display:flex;
flex-direction: column;
border-radius: 20px ;
@media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px){
  padding: 2rem 10px
}
`;

const OtpWapper = styled.div`
margin:15px 0;
@media (max-width: ${breakpoints.sm}px) {
   input:not(:first-child) {
    margin-left: 0.8rem!important;
    height: 4rem;
    width: 2rem;
}
  }
`;

const PaymentButton = styled.div`
display: flex;
justify-content: center;
width:100%;
/* margin-top:2rem; */


button{
width:60%;
/* background: #C5CAD7;
padding:1rem 0; */
}
`;

const TransactionWrapper = styled.div`
background: #F3A100;
border:1px solid black;
padding:2rem 0 ;
text-align:center;
display:flex;
flex-direction: column;
border-radius: 20px ;


`;
const BodyWrapper = styled.div`
padding: 20px 3rem;
display: flex;
flex-direction: column;
@media screen and (min-device-width: ${breakpoints.xs}px) and (max-device-width: ${breakpoints.md}px){
  padding: 10px 0
}
`;

const ImageIcon = styled.div`
width:100px;
height:100px;
overflow:hidden;
margin-bottom: 1rem;
margin:0 auto;
`;

const TextWrapper = styled.div`
margin-bottom: 10px;
`;

const GroupButton = styled.div`
display: flex;
background:#C6145E;
margin-top: 1rem;
button{
  flex:1;
}
`;
const LeftSpaceImage = styled.span`
margin-left: 15px;
`;

const TableStyle = styled.table`
width:100%;
border-collapse: separate;
  /* border-spacing: 15px 30px; */
  border-spacing: 20px;
 
  padding: 0 1rem;

td:nth-child(1){
text-align: left;
}
td:nth-child(2){
text-align: right;
}


`;

const TableHeaderStyle = styled.div`
background:${colors.pink._20};
color: #fff;
padding:1rem 0 ;
text-align: center;
display:flex;
flex-direction: column;
`;
export {
  Container,
  ButtonStyle,
  CustomTable,
  AccountDetails,
  CloseButton,
  TopHeader, HrStyled, SuccessHeader,
  TotalStyle,
  PaymentWrapper,
  OtpWapper,
  PaymentButton,
  TransactionWrapper,
  BodyWrapper,
  ImageIcon,
  TextWrapper,
  GroupButton,
  VertialHrStyled, LeftSpaceImage,
  WarningWrapper,
  TableStyle,
  TableHeaderStyle,
};
