import styled from "styled-components";

const Card = styled.div`
  border-radius: 8px;
border: 1px solid #EAECF0;
background:  #FFF;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
padding:10px 30px 20px 30px;
`

export { Card }