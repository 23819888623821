import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Wrapper = styled.div<{ fixed?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${ptr(80)};
  padding: ${ptr(16)} ${ptr(32)};
  padding-left: ${ptr(64)};
  ${(props) => props.fixed && css`
  position: fixed;
  top: 0;
  width:100%;
  margin-bottom: ${ptr(81)};
  `};
  

  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${colors.white._100};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
`;

const LeftSideWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoWrapper = styled.div`
  display: none;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: block;
  }
`;

const MiniProfileWrapper = styled.div`
  display: flex;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: none;
  }
`;

const MenuButtonWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  @media (max-width: ${ptr(breakpoints.lg)}) {
    display: flex;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
`;

export interface TabProps {
    active?: boolean,
}

const TabWrapper = styled.div<TabProps>`
  margin-right: ${ptr(48)};
  cursor: pointer;
  color: ${colors.black._40};

  :hover {
    color: ${colors.pink._100};
  }
  ${(props) => props.active && css`
    color: ${colors.pink._100};
  `}

`;

export {
    Wrapper,
    //   LeftSideWrapper,
    //   RightSideWrapper,
    //   LogoWrapper,
    //   MiniProfileWrapper,
    //   MenuButtonWrapper,
    //   TabsWrapper,
    //   TabWrapper,
};
