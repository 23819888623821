import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '../Icon';
import { Text } from '../Text';

import { colors } from '../../styles/variables';
import * as S from './styles';

export interface DropdownInputProps {
  /**
   * Label value
   */
  label?: string;
  /**
   * sub label value
   */
  subLabel?: string;
  /**
   * dropdown value
   */
  value?: string;
  /**
   * dropdown placeholder
   */
  placeholder?: string;
  /**
   * Is input disabled
   */
  disabled?: boolean;
  /**
   * Dropdown options
   */
  options?: Array<DropdownOptionProps>;
  /**
   * Dropdown options is multiply
   */
  multiOptions?: boolean;
  /**
   * On select item
   */
  onSelect?: (item: DropdownOptionProps | null) => void;
  loading?: boolean;
  title?: string;
  inputValue?: string;
}

export interface DropdownOptionProps {
  /**
   * Main label of dropdown item
   */
  label?: string;
  extra?: string;
  /**
   * Right text of dropdown item (multiplOptions === true)
   */
  value?: string;
  /**
   * Category (multiplOptions === true)
   */
  category?: string | undefined;
  /**
   * Text under label (multiplOptions === true)
   */
  subLabel?: string;
  /**
   * Item key
   */
  key?: string;

  od?: string;
}

export interface SingleItemProps {
  item?: DropdownOptionProps;
  first?: boolean;
  onSelect?: (item: DropdownOptionProps) => void;
  disabled?: boolean;
}
export interface MultiItemProps {
  item?: DropdownOptionProps;
  first?: boolean;
  onSelect?: (item: DropdownOptionProps) => void;
  disabled?: boolean;
}

const SingleItem: React.FC<SingleItemProps> = ({
  item = {},
  first,
  onSelect = () => {},
  disabled = false,
}) => (
  <S.SingleItemWrapper
    disabled={disabled}
    onClick={() => onSelect(item)}
    first={first}
  >
    <Text size={6} color={colors.black._100}>
      {item?.label ?? 'unknown'}{' '}
      <span style={{ color: '#DB9308' }}>{item?.extra}</span>
    </Text>
  </S.SingleItemWrapper>
);

const MultiItem: React.FC<MultiItemProps> = ({
  item = {},
  onSelect = () => {},
  disabled = false,
}) => (
  <S.MultiItemWrapper disabled={disabled} onClick={() => onSelect(item)}>
    <S.MultiItemLabelsWrapper>
      <Text size={6} color={colors.black._100}>
        {item?.label ?? 'unknown'}{' '}
        <span style={{ color: '#DB9308' }}>{item?.extra}</span>
      </Text>
      <Text size={6} color={colors.black._60}>
        {item?.subLabel ?? 'unknown'}{' '}
        <span style={{ color: '#DB9308' }}>{item?.extra}</span>
      </Text>
    </S.MultiItemLabelsWrapper>
    <Text size={6} color={colors.black._60}>
      {item?.value ?? 'unknown'}{' '}
      <span style={{ color: '#DB9308' }}>{item?.extra}</span>
    </Text>
  </S.MultiItemWrapper>
);

const SectionTitle: React.FC<{ title: string; first: boolean }> = ({
  title = '',
  first = false,
}) => (
  <S.MultiCategoryWrapper disabled first={first}>
    <Text size={5} color={colors.black._100} bold>
      {title}
    </Text>
  </S.MultiCategoryWrapper>
);

export const DropdownInput: React.FC<DropdownInputProps> = ({
  onSelect = () => {},
  ...props
}) => {
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [sortedOptions, setSortedOptions] = useState<Array<
    DropdownOptionProps
  > | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.inputValue) setValue(props.inputValue);
  }, [props.inputValue]);

  const onChange = (inputValue: string) => {
    setValue(inputValue);
    onSelect(null);
    if (inputValue) {
      const sorted =
        props.options?.filter((el) =>
          el.label?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
        ) || [];
      setSortedOptions(sorted);
    } else {
      setSortedOptions(null);
    }
  };

  const onChangeInput = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const inputValue: string = (e.target.value as string) || '';
    onChange(inputValue);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', (event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpen(false);
        }
      });
    }
    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, [open]);

  const onClickBlock = (e: { target: unknown }) => {
    if (inputRef.current !== e.target) {
      if (!open) {
        inputRef.current?.focus();
      } else {
        setOpen(false);
      }
    }
  };

  const handleSelect = (item: DropdownOptionProps) => {
    setValue(item.label || '');
    setOpen(false);
    onSelect(item);
  };

  useEffect(() => {
    if (props.title) setValue(props.title || '');
  }, [props.title]);

  const renderContentItem = (item: DropdownOptionProps, index: number) => {
    if (!props.multiOptions) {
      return (
        <SingleItem first={index === 0} onSelect={handleSelect} item={item} />
      );
    }
    return (
      <MultiItem first={index === 0} onSelect={handleSelect} item={item} />
    );
  };

  const optionsForRender: Array<DropdownOptionProps> =
    sortedOptions || props.options || [];
  return (
    <S.Container ref={ref}>
      <S.StyledLabel placeholder={props.placeholder} value={value} open={open}>
        {props.label}
      </S.StyledLabel>
      <S.Wrapper open={open} onClick={onClickBlock} disabled={props.disabled}>
        <S.StyledInput
          disabled={props.disabled}
          ref={inputRef}
          onFocus={() => setOpen(true)}
          // placeholder={props.placeholder && open ? props.placeholder : ''}
          placeholder={props.placeholder || ''}
          value={value ?? props.inputValue ?? ''}
          onChange={onChangeInput}
        />
        {/* {props.value && (
          <S.SubLabelWrapper>
            <Text size={5} color={colors.black._60}>
              {props.value}
            </Text>
          </S.SubLabelWrapper>
        )} */}
        {props.loading && (
          <S.Loading>
            <Icon name="small-loading" color={colors.black._40} />
          </S.Loading>
        )}
        <S.ArrowIconWrapper rotate={open ? 90 : -90}>
          <Icon name="arrow-left" width={6} color={colors.black._100} />
        </S.ArrowIconWrapper>
      </S.Wrapper>

      <S.DropdownContent open={open}>
        {optionsForRender?.length ? (
          optionsForRender
            .sort((a, b) =>
              props.multiOptions
                ? a.category?.localeCompare(b.category ?? '') ?? 0
                : 0
            )
            .map((item, index) => (
              <div key={item.key}>
                {props.multiOptions &&
                (index === 0 ||
                  item.category !== props.options?.[index - 1]?.category) ? (
                  <SectionTitle
                    first={index === 0}
                    title={item.category || ''}
                  />
                ) : null}
                {renderContentItem(item, index)}
              </div>
            ))
        ) : (
          <SingleItem
            disabled
            item={{
              label: 'No options',
              value: '0',
            }}
          />
        )}
      </S.DropdownContent>
    </S.Container>
  );
};
