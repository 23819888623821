import debug from '../debbug.service';
import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';
import ErrorBeneficiary from './beneficiary.error';
import { IBeneficiaries } from '../../../pages/BeneficiariesManagement/view';

export interface IBeneficiaryFilter {
  bankCode?: Array<string>;
  beneficiaryTypeId?: Array<string>;
}

export interface NewBeneficiaryType {
  name: string,
  businessId?: number,
}
class BeneficiaryService {
  private apiEndpoints = {
    beneficiary: (queryString: string) => `beneficiaries${queryString}`,
    frequentBeneficiary: `beneficiaries/frequent`,
    beneficiaries: 'beneficiaries',
    createBeneficiary: 'beneficiaries',
    types: 'tags?type=beneficiary',
    addTypes: 'tags',
    bulk: 'beneficiaries/bulk',
  };

  public settings = {
    limit: 8,

    sorting: {
      beneficiaryName: 'name',
      company: 'company.name',
      bankName: 'bank.name',
      accountNumber: 'nuban',
      lastAmountSent: 'defaultAmount',
    },
  };

  public async getBeneficiary(queryString?: string) {
    try {
      const request = await apiService.request.get(this.apiEndpoints.beneficiary(queryString || ''), {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await request.json();

      debug.success('Result of reaching the list of beneficiaries', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed get beneficiary', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }

        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getFrequentBeneficiary() {
    try {
      const request = await apiService.request.get(this.apiEndpoints.frequentBeneficiary, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await request.json();

      debug.success('Result of reaching the list of beneficiaries', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed get beneficiary', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }

        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }


  public async getBeneficiaryTypes() {
    try {
      const request = await apiService.request.get(this.apiEndpoints.types, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await request.json();

      debug.success('The result of getting beneficiaries types', result);

      return result;
    } catch (globalError:any) {
      debug.error('Failed get beneficiaries types', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }

        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async createBeneficiaryType(data: NewBeneficiaryType) {
    try {
      const request = await apiService.request.post(this.apiEndpoints.addTypes, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await request.json();

      debug.success('Result of creating a new beneficiary type', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed create beneficiary type', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async editBeneficiary(id: number, data: {
    tags?: string,
    defaultAmount?: string,
    nuban?: string,
    bankCode?: string,
  }) {
    try {
      const request = await apiService.request.put(`${this.apiEndpoints.beneficiaries}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await request.json();

      debug.success('Result of editing beneficiary', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed to edit beneficiary', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async createBeneficiary(data: {
    nuban: string,
    bankCode: string,
    beneficiaryType: Array<string>,
    defaultAmount?: number,
  }) {
    try {
      const request = await apiService.request.post(this.apiEndpoints.createBeneficiary, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await request.json();

      debug.success('Result of creating a new beneficiary', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed create beneficiary', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async saveFile(data: Record<string, string>[]) {
    try {
      const request = await apiService.request.post(this.apiEndpoints.createBeneficiary, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await request.json();

      debug.success('Result of creating a new beneficiary', result);

      return result as IBeneficiaries[];
    } catch (globalError: any) {
      debug.error('Failed create beneficiary', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async loadFile(data: FormData) {
    try {
      const request = await apiService.request.post(this.apiEndpoints.bulk, {
        body: data,
      });

      const result = await request.json();

      debug.success('Result of sending file', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed to send file', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async downloadTemplate() {
    try {
      const request = await apiService.request.get(this.apiEndpoints.bulk, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const file = URL.createObjectURL(await request.blob());

      debug.success('The result of downloading beneficiaries template', file);

      return file;
    } catch (globalError: any) {
      debug.error('Failed download beneficiaries template', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async deleteBeneficiaries(ids: Array<number>): Promise<void> {
    try {
      await apiService.request.delete(`${this.apiEndpoints.beneficiaries}?ids=${ids.join(',')}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('The beneficiaries success deleted');
      return await Promise.resolve();
    } catch (globalError: any) {
      debug.error('Failed delete beneficiaries', globalError);

      try {
        if (globalError.response) {
          const result = await globalError.response.json();

          return Promise.reject(new ErrorBeneficiary({
            message: result.message,
            code: result.code,
          }));
        }
        return Promise.reject(new ErrorBeneficiary({
          message: globalError.message,
          code: globalError.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }
}

const beneficiaryService = new BeneficiaryService();
export default beneficiaryService;
