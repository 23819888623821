import { ActionInterface } from '../IAction';
import { IPaginationSettings } from './Ipagination';

export const SET = 'SET';
export const RESET = 'RESET';

const setLimit = (payload: IPaginationSettings): ActionInterface<IPaginationSettings> => ({
  type: SET,
  payload,
});

const resetLimit = (): ActionInterface<unknown> => ({
  type: RESET,
});

export class PaginationActions {
  public static setPaginationLimit(payload:IPaginationSettings): ActionInterface<unknown> {
    return setLimit(payload);
  }

  public static reset(): ActionInterface<unknown> {
    return resetLimit();
  }
}
