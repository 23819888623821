import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const CheckContainer = styled.div`
  display: flex;
  gap: 15px;
  cursor: pointer;
`;

const CheckCircle = styled.div<{ checked?: boolean }>`
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: solid 1px
    ${(props) => (props.checked ? colors.pink._100 : colors.gray._80)};
  background-color: ${(props) =>
    props.checked ? colors.pink._100 : colors.white._100};
`;

const CheckLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export { CheckContainer, CheckCircle, CheckLabel, Container };
