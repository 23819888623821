import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div`
  > button {
    width: 100%
  }
`;

const Wrapper = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};
`;

export { Container, Wrapper };
