import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import * as S from './styles';
import { CircleDecorators, CircleOptions } from '../CircleDecorator';
import { Icon, IconProps } from '../Icon';
import { colors } from '../../styles/variables';

export interface ClickablePanelProps {
  /**
   * Title text
   */
  title?: string,
  /**
   * Icon name
   */
  icon?: IconProps['name'],
  /**
   * Link href
   */
  to?: string,
  /**
   * Description text
   */
  description?: string,
  /**
   * Sub description text
   */
  subDescription?: string,
  /**
   * Circles option
   */
  circlesOptions?: Array<CircleOptions>,
  /**
   * Coming soon badge
   */
  comingSoon?: boolean,
  /**
   * Click action
   */
  onClick?: () => void,
  /**
   * Theme
   */
  theme?: 'default' | 'light'
}

const DEFAULT_CIRCLES_OPTIONS = [
  {
    radius: 80,
    rotate: 66,
    position: {
      left: '-5%',
      bottom: '-5%',
    },
  },
  {
    radius: 60,
    rotate: 0,
    position: {
      right: '-5%',
      bottom: '-20%',
    },
  },
];

export const ClickablePanel: React.FC<ClickablePanelProps> = ({
  title,
  description,
  subDescription,
  onClick,
  circlesOptions = DEFAULT_CIRCLES_OPTIONS,
  theme = 'default',
  ...props
}) => {
  if (props.to) {
    return (
      <ReactRouterLink to={props.to || ''} style={{ textDecoration: 'none' }}>
        <S.Wrapper theme={theme} disabled={props.comingSoon} onClick={onClick}>
          {props.comingSoon && <S.Badge>Coming soon</S.Badge>}
          <CircleDecorators
            circles={circlesOptions}
          >
            <S.Paddings icon={props.icon}>
              {props.icon && <Icon name={props.icon} color={theme === 'light' ? colors.black._100 : undefined} />}
              <S.Title description={description}>{title}</S.Title>
              {description && <S.Description>{description}</S.Description>}
              {subDescription && <S.SubDescription>{subDescription}</S.SubDescription>}
            </S.Paddings>
          </CircleDecorators>
        </S.Wrapper>
      </ReactRouterLink>
    );
  }

  return (
    <S.Wrapper theme={theme} disabled={props.comingSoon} onClick={onClick}>
      {props.comingSoon && <S.Badge>Coming soon</S.Badge>}
      <CircleDecorators
        circles={circlesOptions}
      >
        <S.Paddings icon={props.icon}>
          {props.icon && <Icon name={props.icon} color={theme === 'light' ? colors.black._100 : undefined} />}
          <S.Title description={description} subDescription={subDescription}>{title}</S.Title>
          {description && <S.Description theme={theme}>{description}</S.Description>}
          {subDescription && <S.SubDescription>{subDescription}</S.SubDescription>}
        </S.Paddings>
      </CircleDecorators>
    </S.Wrapper>
  );
};
