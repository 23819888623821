import React, { useState, useEffect } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import validator from 'validator';

import { useHistory } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { defaultLeaderDirectorShareholder } from '../../../redux/onboarding/reducer';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { LeaderDirectorShareholderInterface } from '../../../services/internal/onboarding/IOnboarding';
import debug from '../../../services/internal/debbug.service';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import { CheckAreaGroup } from '../../../components/CheckAreaGroup';

import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../../utils/validation';

import { colors } from '../../../styles/variables';
import * as S from './styles';
import { errorToString } from '../../../services/internal/app';
import { getSignatureFormData } from '../../Onboarding/steps/common.api';
import { FileUpload } from '../../../components/FileUpload/index';
import { Modal } from '../../../components/LoadingModal';

interface IDirectorDetail {
  nin: string,
  bvn: string,
  cac?: string,
  email: string,
  type: string,
  signatures: File|undefined,
  index?: number,
  emailIsValid?: boolean,
  remove?: ((index: number) => void),
  onChange?: ((index: number, property: string, value: string) => void),
}

const SCHEME = {
  nin: (value: string) => value.length === 11,
  bvn: (value: string) => value.length === 11,
  cac: (value: string) => value.length > 3,
  email: (value: string) => validator.isEmail(value),
  type: (value: string) => !!value,
  signatures: (data: Array<File>) => typeof data !== 'undefined',
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const directorDefaultItem = {
  nin: '',
  bvn: '',
  email: '',
  type: '',
  signatures: undefined,
};

const shareholderTypes = {
  individual: {
    label: 'It’s individual',
    key: 'individual',
  },
  company: {
    label: 'It’s company',
    key: 'company',
  },
};

const SingleItem: React.FC<IDirectorDetail> = ({
  nin,
  bvn,
  cac,
  email,
  type,
  index,
  emailIsValid,
  remove = () => {},
  onChange = () => {},
}) => {
  const isCompanyType = Object.values(shareholderTypes).find(
    (el) => el.label === type,
  )?.key === shareholderTypes.company.key;

  return React.useMemo(() => (
    <S.ItemContainer>
      <S.HeaderRow>
        <Text bold>Shareholder’s details</Text>
        {index && index > 0 ? (
          <S.Remove onClick={() => remove(index)}>
            <Icon name="trash" color={colors.pink._100} />
          </S.Remove>
        ) : null}
      </S.HeaderRow>
      <S.CheckAreaGroupWrapper>
        <CheckAreaGroup
          onChange={(value) => onChange(index as number, 'type', value)}
          items={[
            {
              value: shareholderTypes.individual.label,
            },
            {
              value: shareholderTypes.company.label,
            },
          ]}
        />
      </S.CheckAreaGroupWrapper>
      {isCompanyType && (
        <TextInput
          label="RC number"
          value={cac || ''}
          onChange={(value) => onChange(index as number, 'cac', value)}
        />
      )}
      <S.ItemRow>
        <TextInput
          label={isCompanyType ? 'NIN representative' : 'NIN'}
          value={nin}
          type="currency"
          mask={createNumberMask(numberMaskOptions)}
          onChange={(value) => onChange(index as number, 'nin', value)}
        />
        <TextInput
          label={isCompanyType ? 'BVN representative' : 'BVN'}
          value={bvn}
          type="currency"
          mask={createNumberMask(numberMaskOptions)}
          onChange={(value) => onChange(index as number, 'bvn', value)}
        />
      </S.ItemRow>
      <TextInput
        label={isCompanyType ? 'Email representative' : 'Email'}
        value={email}
        onChange={(value) => onChange(index as number, 'email', value)}
        valid={emailIsValid}
      />
    </S.ItemContainer>
  ), [type]);
};

const CommonCompanyEnterShareholders: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  /**
   * Redux store initiation region
   */
  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.shareholders as Array<LeaderDirectorShareholderInterface>,
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state
      .onboardingStack.data.reference as string,
  );

  /**
   * Local state initiation region
   */
  const [collection, setCollection] = useState<Array<IDirectorDetail>>(
    leaders?.length
      ? leaders.map((item) => ({
        nin: item.nin,
        bvn: item.bvn,
        ...item.cac && { cac: item.cac },
        email: item.email,
        type: item.type,
        signatures: item.signatures,
      })) as Array<IDirectorDetail>
      : [directorDefaultItem],
  );
  const [validationCollection, setValidationCollection] = useState<ValidateArrayResult>();
  const [loading, setLoading] = useState(false);

  /**
   * Custom handlers initiation region
   */
  const push = () => {
    setCollection([...collection, directorDefaultItem]);
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection((old) => old.map((el: IDirectorDetail, i: number) => {
      if (i === index) {
        const temp = {
          ...el,
          [property]: value,
        };
        if (property === 'type') {
          if (value === shareholderTypes.company.label) {
            temp.cac = el.cac || '';
          } else {
            delete temp.cac;
          }
        }
        return temp;
      }
      return el;
    }) as Array<IDirectorDetail>);
  };

  const continueHandle = () => {
    const formattedCollection = collection.map((item) => {
      const shareholder = { ...defaultLeaderDirectorShareholder };

      shareholder.type = Object.values(shareholderTypes).find(
        (el) => el.label === item.type,
      )?.key || '';
      shareholder.image = '';

      shareholder.isDirector = false;
      shareholder.isShareholder = true;
      shareholder.isSignatory = false;

      shareholder.bvn = item.bvn;
      shareholder.nin = item.nin;
      shareholder.email = item.email;

      if (item.cac !== undefined) {
        shareholder.cac = item.cac;
      }

      shareholder.signatures = item.signatures;

      return shareholder;
    });
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.submitBusinessShareholderInfo(
          await getSignatureFormData(formattedCollection, reference),
        );
        const shareholders = Array.isArray(response.data.directors)
          ? [...response.data.directors]
          : [{ ...response.data.directors }];

        batch(() => {
          dispatch(OnboardingActions.handleRequiredAction(response.actionRequired));
          dispatch(OnboardingActions.handleSetData({
            shareholders,
            reference: response.data.reference,
          }));
        });
        route.push('/onboarding/confirm-shareholder-details', { actionRequired: response.actionRequired });
        debug.info('', response); // TEMPORARY
      } catch (err) {
        dispatch(OnboardingActions.handleSetError(errorToString(err)));
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  return (
    <S.Container>
      <Title title="Shareholders details" />

      <S.Wrapper>
        {collection.map((item, index) => (
          <div>
            <SingleItem
              signatures={item.signatures}
              nin={item.nin}
              bvn={item.bvn}
              cac={item.cac}
              type={item.type}
              email={item.email}
              index={index}
              remove={remove}
              onChange={onChangeValue}
              emailIsValid={validationCollection?.data[index]?.data.email.isValid}
            />

            <S.Wrapper>
              <Text color={colors.black._60}>
                Upload JPEG signatures of the shareholder and relevant PSC forms as applicable.
              </Text>
              <S.Block>
                <S.DropzoneContainer>
                  <Text bold color={colors.black._100}>Shareholders’ signatures</Text>
                  <FileUpload
                    file={item.signatures}
                    onChange={(files) => onChangeValue(index, 'signatures', files)}
                  />
                </S.DropzoneContainer>
              </S.Block>
            </S.Wrapper>
          </div>
        ))}
      </S.Wrapper>

      <S.Add onClick={push}>
        <Icon name="plus-in-circle" color={colors.pink._100} />
        <Text color={colors.pink._100}>
          Add shareholder
        </Text>
      </S.Add>

      <Button
        label="Continue"
        loading={loading}
        disabled={!validationCollection?.isValid || loading}
        onClick={continueHandle}
      />
      <Modal visible={loading} title="Please wait while we verify shareholder details" />
    </S.Container>
  );
};
export default CommonCompanyEnterShareholders;
