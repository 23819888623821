import styled, { css } from 'styled-components';

import { fonts, transition } from '../../styles/variables';
import {
  crossBrowserDisableSelection, crossBrowserInlineFlex, crossBrowserTransition, ptr,
} from '../../styles/utils';
import { LinkProps } from './index';

const Wrapper = styled.div<LinkProps>`
  ${crossBrowserInlineFlex()}

  flex-direction: row;
  align-items: center;
  cursor: default;
  
  ${(props) => !props.disabled && css`
    cursor: pointer;
    
    :hover {
      opacity: 0.6;
    }
  `}
  
  ${(props) => props.disabled && css`
    opacity: 0.6;
  `}

  ${crossBrowserTransition(transition.slow)}
  ${crossBrowserDisableSelection()}
`;

const IconWrapper = styled.div<LinkProps>`
  ${(props) => props.text && css`
    margin-right: ${ptr(16)};
  `};
  ${(props) => props.iconRight && css`
    margin-left: ${ptr(16)};
  `}
`;

const Text = styled.span<LinkProps>`
  font-family: ${fonts.inter.regular};
  line-height: 140%;
  display: flex;

  ${(props) => css`
    color: ${props.color};
  `}

  ${(props) => props.small && css`
    font-size: ${ptr(14)};
  `}
`;

export {
  Wrapper,
  IconWrapper,
  Text,
};
