
import { BusinessInterface } from '../../services/internal/payments/IPayments';
import { ActionInterface } from '../IAction';
import { MenuItem } from './IQuickLinks';

export const SET_ACTIVE_LINKS = 'SET_ACTIVE_LINKS';


export const setActiveLinks = (
  payload: Array<MenuItem>,
): ActionInterface<Array<MenuItem>> => ({
  type: SET_ACTIVE_LINKS,
  payload,
});


