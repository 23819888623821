import React, { useState } from 'react';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { CheckAreaProps } from '../CheckArea';
import { Text } from '../Text';
import { CheckAreaGroup } from '../CheckAreaGroup';
import { FileUpload } from '../FileUpload';
import { TextInput, TextInputProps } from '../TextInput';
import { Switch, SwitchProps } from '../Switch';
import { CheckBox, CheckBoxProps } from '../CheckBox';
import { TextInputGroup } from '../TextInputGroup';
import { FormTitle } from '../FormTitle';

interface Question {
  id: string,
  title?: string,
  description?: string,
  type: 'check-area' | 'input' | 'switch' | 'file' | 'check-box' | 'input-group',
}

interface TextInputQuestion extends Question {
  items: Array<TextInputProps>
}

interface CheckAreaQuestion extends Question {
  items: Array<CheckAreaProps>
}

interface SwitchQuestion extends Question {
  items: Array<SwitchProps>
}

interface CheckBoxQuestion extends Question {
  items: Array<CheckBoxProps>
}

export interface QuestionsGeneratorProps {
  /**
   * Title of the questions list
   */
  title?: string,
  /**
   * Description of the questions list
   */
  description?: string,
  /**
   * Questions list
   */
  questions?: Array<TextInputQuestion | CheckAreaQuestion | SwitchQuestion | CheckBoxQuestion>,
}

export const QuestionsGenerator: React.FC<QuestionsGeneratorProps> = ({
  questions = [],
  ...props
}) => {
  const [answers, setAnswers] = useState({});

  const onChange = (
    id: string,
    value: string | File | boolean | undefined | Array<string | null>,
  ) => {
    setAnswers({
      ...answers,
      [id]: value,
    });
  };

  return (
    <S.Wrapper>
      <FormTitle
        title={props.title}
        description={props.description}
      />

      {questions.map((question) => (
        <S.QuestionWrapper>
          <S.QuestionTitleAndDescriptionWrapper>
            <S.QuestionTitleWrapper>
              {props.title && <Text color={colors.black._100} bold>{question.title}</Text>}
            </S.QuestionTitleWrapper>
            <S.QuestionDescriptionWrapper>
              {props.description && <Text color={colors.black._100}>{question.description}</Text>}
            </S.QuestionDescriptionWrapper>
          </S.QuestionTitleAndDescriptionWrapper>
          <S.QuestionCheckAreasWrapper>
            {(() => {
              switch (question.type) {
                case 'check-area':
                  return (
                    <CheckAreaGroup
                      items={question.items as Array<CheckAreaProps>}
                      onChange={(value) => {
                        onChange(question.id, value);
                      }}
                    />
                  );
                case 'input':
                  return (
                    <TextInput
                      {...question.items[0]}
                      onChange={(value) => {
                        onChange(question.id, value);
                      }}
                    />
                  );
                case 'file':
                  return (
                    <FileUpload
                      onChange={(value) => {
                        onChange(question.id, value);
                      }}
                    />
                  );
                case 'switch':
                  return question.items.map((switchItem: any, index: number) => (
                    <>
                      <Switch
                        {...switchItem}
                        onClick={(value) => {
                          onChange(`${question.id}/${switchItem.text}`, value);
                        }}
                      />
                      {index !== question.items.length - 1 && <S.Separator />}
                    </>
                  ));
                case 'check-box':
                  return (
                    <S.QuestionCheckBoxesWrapper>
                      {question.items.map((checkBoxItem: any) => (
                        <CheckBox
                          {...checkBoxItem}
                          onChange={(value) => {
                            onChange(`${question.id}/${checkBoxItem.text}`, value);
                          }}
                        />
                      ))}
                    </S.QuestionCheckBoxesWrapper>
                  );
                case 'input-group':
                  return (
                    <>
                      <TextInputGroup
                        item={question.items[0] as TextInputProps}
                        onChange={(values) => {
                          onChange(question.id, values);
                        }}
                      />
                    </>
                  );

                default:
                  return null;
              }
            })()}
          </S.QuestionCheckAreasWrapper>
        </S.QuestionWrapper>
      ))}
    </S.Wrapper>
  );
};
