import storage from 'react-secure-storage';

export enum storageKey {
  DAILY_LIMIT_REQUEST = 'DAILY_LIMIT_REQUEST',
}
export const setItem = (key: storageKey, data: string): void => {
  storage.setItem(key, data);
};

export const getItem = (key: storageKey) => {
  const savedData = storage.getItem(key);
  if (!savedData) return;
  return savedData;
};

export const removeItem = (key: string): void => {
  storage.removeItem(key);
};
