interface DefaultObjectType {
  [key: string]: unknown;
}

type DefaultCollectionType = Array<DefaultObjectType>;

interface GroupByCollectionType {
  [groupByKey: string]: DefaultCollectionType;
}

export const groupBy = (
  list: DefaultCollectionType,
  key: string
): GroupByCollectionType =>
  list.reduce((groups: GroupByCollectionType, item: DefaultObjectType) => {
    const property = `${item[key]}`;
    const group = (groups[property] || []) as DefaultCollectionType;
    group.push(item);
    // eslint-disable-next-line no-param-reassign
    groups[property] = group;
    return groups;
  }, {} as GroupByCollectionType);

export function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export const copyToClipboard = async (text: string) => {
  if (!text) return;

  await navigator.clipboard.writeText(text);
};
