import React from 'react';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';

export interface LoadingModalProps {
  onCloseModal?: () => void;
  visible?: boolean;
  title?: string;
}

export const Modal: React.FC<LoadingModalProps> = ({
  visible = false,
  title,
  // onCloseModal = () => {},
}) => (
  <ModalWrapper
    showModal={visible}
    // onCloseModal={onCloseModal}
    crossColor={colors.black._100}
  >
    <S.Wrapper>
      <S.TextContainer>
        <Text bold color={colors.pink._100} size={6}>
          {title || 'Please wait while we verify the information provided..'}
        </Text>
      </S.TextContainer>
    </S.Wrapper>
  </ModalWrapper>
);
