import styled, { css } from 'styled-components';

import { AccountCardCheckedProps } from './index';
import { colors, transition } from '../../styles/variables';
import { crossBrowserTransition, ptr } from '../../styles/utils';

const Wrapper = styled.a<AccountCardCheckedProps>`
  height: ${ptr(160)};
  border-radius: ${ptr(8)};
  background-color: ${colors.pink._100};
  display: flex;
  flex: 1;

  text-decoration: none;

  ${crossBrowserTransition(transition.slow)}

  ${(props) => props.checked && css`
    background-color: ${colors.black._100};
  `}

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
  
  ${(props) => props.disabled && css`
    cursor: default;
    opacity: 0.7;

    :hover {
      cursor: default;
      opacity: 0.7;
    }
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${ptr(20)} ${ptr(20)} ${ptr(28)} ${ptr(32)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: ${ptr(24)};
  align-items: flex-end;
  justify-content: space-between;
`;

const CheckBox = styled.div<AccountCardCheckedProps>`
  display: flex;
  width: ${ptr(24)};
  height: ${ptr(24)};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: ${ptr(1.5)} solid ${colors.white._100};
  border-radius: 50%;

  ${crossBrowserTransition(transition.slow)}
  
  ${(props) => props.checked && css`
    background-color: ${colors.white._100};
  `}
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: ${ptr(4)};
  overflow-wrap: anywhere;
`;

const AccountNumberWrapper = styled.div`
  display: flex;
  white-space: pre-wrap;
`;

export {
  Wrapper,
  ContentWrapper,
  HeaderWrapper,
  CheckBox,
  BalanceWrapper,
  AccountNumberWrapper,
};
