import styled from 'styled-components';

import { crossBrowserTransition, ptr } from '../../../styles/utils';
import { colors, transition } from '../../../styles/variables';

const Container = styled.div``;

const InfoContainer = styled.div`
width:85%;
  margin: 30px 0;
`;

const Wrapper = styled.div`
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
  }

  & + button {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const CheckBoxContainer = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(32)};
`;

const EmailContainer = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(32)};

  > :first-child {
    margin-bottom: ${ptr(12)};
  }
`;

const FormInput = {
  Wrapper: styled.div`
    margin-bottom: ${ptr(12)};
  `,
};

const InvalidTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  ${crossBrowserTransition(transition.slow)};
`;
const InvalidIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ptr(24)};
  height: ${ptr(24)};
  border-radius: 50%;
  background-color: ${colors.red._10};
  margin-right: ${ptr(12)};
`;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};
  flex-wrap: wrap;
`;

export {
  ItemRow,
  Container,
  Wrapper,
  ButtonGroup,
  CheckBoxContainer,
  EmailContainer,
  FormInput,
  InfoContainer,
  InvalidTextWrapper,
  InvalidIconWrapper,
};
