import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';

import * as S from '../../../Onboarding/components/InfoForm/styles';
import { TextInput } from '../../../../components/TextInput';
import { DropdownInput } from '../../../../components/DropdownInput';
import { validIdTypes } from '../../../Onboard/constants';

export interface SimpleFormProps {
  phone?: string;
  idNumber?: string;
  idType?: string;
  bvn?: string;
  email?: string;
  onSelectId?: any;
  onChangeId?: any;
  index?: number;
  removeFromIndex?: number;
  emailIsValid?: boolean;
  bvnIsValid?: boolean;
  phoneIsValid?: boolean;
  idNumberIsValid?: boolean;
  remove?: (index: number) => void;
  onChange?: (index: number, property: string, value: string) => void;
  title?: string;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const InfoForm2: React.FC<SimpleFormProps> = ({
         phone,
         idNumber,
         bvn,
         idType,
         onSelectId,
         onChangeId,
         email,
         index,
         removeFromIndex = 0,
         emailIsValid,
         bvnIsValid,
         phoneIsValid,
         idNumberIsValid,
         title,
         remove = () => {},
         onChange = () => {},
       }) => {
         return (
           <S.ItemContainer>
             <S.HeaderRow>
               <Text bold color={colors.black._100}>
                 {title}
               </Text>
               {index && index > removeFromIndex ? (
                 <S.Remove onClick={() => remove(index)}>
                   <Icon name='trash' color={colors.pink._100} />
                 </S.Remove>
               ) : null}
             </S.HeaderRow>
             <S.ItemRow>
               <div style={{ flex: 1 }}>
                 <TextInput
                   label='Email'
                   value={email}
                   onChange={(value) =>
                     onChange(index as number, 'email', value)
                   }
                   valid={emailIsValid}
                 />
               </div>
               <div style={{ flex: 1 }}>
                 <TextInput
                   label='Phone number'
                   type='number'
                   mask={createNumberMask(numberMaskOptions)}
                   value={phone}
                   valid={phoneIsValid}
                   onChange={(value) =>
                     onChange(index as number, 'phone', value)
                   }
                 />
               </div>
             </S.ItemRow>

             <S.ItemRow>
               <div style={{ flex: 1 }}>
                 <DropdownInput
                   label='Valid ID Type'
                   options={[...validIdTypes]}
                   value={idType}
                   inputValue={
                     idType
                       ? validIdTypes.find((type) => type.value === idType)
                           ?.label
                       : ''
                   }
                   onSelect={(value: any) => {
                     onChange(index as number, 'idType', value?.value ?? '');
                     onSelectId(value);
                   }}
                 />
               </div>
               <div style={{ flex: 1 }}>
                 <TextInput
                   label='ID Number'
                   type='text'
                   value={idNumber}
                   name='idNumber'
                   onChange={(value) => {
                     onChange(index as number, 'idNumber', value);
                     onChangeId(value);
                   }}
                   valid={idNumberIsValid}
                 />
               </div>
             </S.ItemRow>
           </S.ItemContainer>
         );
       };
