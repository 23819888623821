import styled from 'styled-components';

// import { UnregisteredBusinessProps } from '.';
// import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const BackButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: absolute;
  left: ${ptr(200)};

  @media (max-width: ${ptr(1024)}) {
    position: relative;
    left: 0;
    margin-bottom: ${ptr(40)};
  }
`;
const ProgressWrapper = styled.div`
  margin-bottom: ${ptr(40)};
`;
const Test = styled.div`
  border: 1px solid;
`;

export {
  Wrapper,
  BackButtonWrapper,
  Test,
  ProgressWrapper,
};
