import styled from 'styled-components';
import { ptr } from '../../../../styles/utils';

// import { UnregisteredBusinessProps } from '.';
// import { colors } from '../../styles/variables';

const ClicableWrapper = styled.div`
  margin-bottom: ${ptr(20)};
`;

export {
  ClicableWrapper,
};
