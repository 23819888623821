import React from 'react';

import { OneCenter, ContentContainer } from '../../styles/wrappers';
import { Logo } from '../../components/Logo';
import { CreatePasswordForm } from './components/CreatePasswordForm';

const CreatePassword: React.FC = () => (
  <OneCenter.Wrapper>
    <ContentContainer justifyContent="flex-start">
      <Logo marginLeft={80} marginTop={32} />
    </ContentContainer>
    <ContentContainer flex={1} justifyContent="center" alignItems="center">
      <OneCenter.ContentWrapper>
        <CreatePasswordForm />
      </OneCenter.ContentWrapper>
    </ContentContainer>
  </OneCenter.Wrapper>
);

export default CreatePassword;
