import React from 'react';
import { colors } from '../../styles/variables';

export interface PreloadProps {
  width?: number,
  height?: number,
}

export const Preload: React.FC<PreloadProps> = ({ width = 100, height = 100 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <rect x="18" y="31" width="14" height="38" fill={colors.pink._100}>
      <animate attributeName="y" repeatCount="indefinite" dur="1.0526315789473684s" calcMode="spline" keyTimes="0;0.5;1" values="19.599999999999998;31;31" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.21052631578947367s" />
      <animate attributeName="height" repeatCount="indefinite" dur="1.0526315789473684s" calcMode="spline" keyTimes="0;0.5;1" values="60.800000000000004;38;38" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.21052631578947367s" />
    </rect>
    <rect x="43" y="31" width="14" height="38" fill={colors.pink._80}>
      <animate attributeName="y" repeatCount="indefinite" dur="1.0526315789473684s" calcMode="spline" keyTimes="0;0.5;1" values="22.449999999999996;31;31" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.10526315789473684s" />
      <animate attributeName="height" repeatCount="indefinite" dur="1.0526315789473684s" calcMode="spline" keyTimes="0;0.5;1" values="55.10000000000001;38;38" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.10526315789473684s" />
    </rect>
    <rect x="68" y="31" width="14" height="38" fill={colors.pink._60}>
      <animate attributeName="y" repeatCount="indefinite" dur="1.0526315789473684s" calcMode="spline" keyTimes="0;0.5;1" values="22.449999999999996;31;31" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" />
      <animate attributeName="height" repeatCount="indefinite" dur="1.0526315789473684s" calcMode="spline" keyTimes="0;0.5;1" values="55.10000000000001;38;38" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" />
    </rect>
  </svg>
);
