import styled, { css } from 'styled-components';
import { ptr } from '../../../styles/utils';
import { breakpoints, colors, fonts } from '../../../styles/variables';

const Container = styled.div` 
 

`;

const TopTitle = styled.div`
/* border-bottom: 1px solid #E9EBF1; */
 padding-bottom: ${ptr(24)}; 
`;

const Wrapper = styled.div<any>`
 
`;

const InfoComponent = styled.div` 
 width: 64%;
 @media (max-width: ${breakpoints.sm}px) {
  width: 100%;
}
`;

const TextInputLine = styled.div`
border-radius: 8px;
display: flex;
align-items: center;
gap: 14px;
justify-content: center;

>div{
  display: flex;
width: 16px;
height: 3px; 
  background: var(--grayscale-placeholder, #A0A3BD);
}
`;

const TextInputOuter = styled.div`
border-radius: 8px;
border: 1px solid #D9DBE9;
// align-self: stretch;
// position:relative;
display:flex
flex-direction:row;
//  padding:12px 16px;
//  height:48px; 
`;

const TextInputB = styled.div`
//  width:10px; 
`;

const TextInputOuterText = styled.div`
border-radius: 8px 0px 0px 8px;
border-top: 1px solid #D9DBE9;
border-bottom: 1px solid #D9DBE9;
border-left: 1px solid #D9DBE9;
background: var(--grayscale-input-background, #EFF0F6);
 width:auto;
padding: 0 10px;
 display:flex;
 align-items:center;
 text-align:center;
 justify-content:center;
`;

const ItemRow = styled.div`
margin-top: ${ptr(16)};
margin-bottom: ${ptr(12)};
display: flex;
flex-wrap: wrap;
gap: ${ptr(12)};
`;

const AccountName = styled.div`
 
`;

const ItemRowB = styled.div`
margin-top: ${ptr(16)};
margin-bottom: ${ptr(12)}; 
display: flex;
flex-wrap: wrap;
gap: ${ptr(12)}; 
align-center:center;
border-radius: 8px;
border: 1px solid #D9DBE9;
`;

const ButtonGroup = styled.div` 
  display:flex;
  gap: ${ptr(10)};
  margin-top: ${ptr(23)};
  justify-content: flex-end;  

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center; 
  }

border-top: 4px solid #DBDFE4;
padding-top:14px;
/* 
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right; */

`;

const InfoWrapper = styled.div`
display: flex;
gap: ${ptr(8)};
// flex-wrap:wrap;
align-items:center;
border-radius: 4px;
background: #F7F7FC;
padding: ${ptr(10)};
font-family: ${fonts.inter.regular};
font-size: ${ptr(14)};
margin-top: -4px;
`;

export {
  Container,
  TopTitle,
  Wrapper,
  ItemRow,
  ItemRowB,
  AccountName,
  ButtonGroup,
  InfoWrapper,
  TextInputB,
  InfoComponent,
  TextInputLine,
  TextInputOuter,
  TextInputOuterText,
};
