import React from 'react';
import { colors } from '../../styles/variables';
import { Icon, IconProps } from '../Icon';
import { Text } from '../Text';

import * as S from './styles';

export interface EventPanelProps {
  /**
   * Icon props
   */
  icon?: IconProps;
  /**
   * Panel title
   */
  title?: string;
  /**
   * Panel description
   */
  description?: string;
  /**
   * Show or hide icon
   */
  showIcon?: boolean;
  /**
   * On click handler
   */
  onClick?: () => void;

  iconBg?: string;
  arrowColor?: string;
}

export const EventPanel: React.FC<EventPanelProps> = ({
  icon = {
    name: 'users',
  },
  iconBg,
  title = 'unknown',
  description = 'unknown description',
  showIcon = true,
  arrowColor,
  onClick = () => {},
}) => (
  <S.Wrapper onClick={onClick}>
    {showIcon ? (
      <S.IconWrapper background={iconBg}>
        <Icon
          width={icon.width}
          height={icon.height}
          rotate={icon.rotate}
          name={icon.name}
          color={icon.color ?? colors.pink._100}
        />
      </S.IconWrapper>
    ) : null}
    <S.TextsWrapper>
      <S.TitleWrapper>
        <Text size={5} color={colors.black._100} bold>
          {title}
        </Text>
      </S.TitleWrapper>
      <Text size={6} color={colors.black._60}>
        {description}
      </Text>
    </S.TextsWrapper>
    <S.ArrowWrapper>
      <Icon
        width={8}
        height={12}
        name="arrow-left"
        rotate={180}
        color={arrowColor ?? colors.pink._100}
      />
    </S.ArrowWrapper>
  </S.Wrapper>
);
