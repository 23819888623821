import React, { useState, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import debug from '../../../services/internal/debbug.service';

import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { OtpInput } from '../../../components/OtpInput';

import { colors } from '../../../styles/variables';
import * as S from '../common.otp/styles';
import { actions } from '../config';
import { success } from '../../../components/Toasts';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { Button } from '../../../components/Button';

const CommonEmailOtp: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  // const email = useSelector(
  //   (state: { onboardingStack: any }) =>
  //     state.onboardingStack.data.credentials.email as string
  // );

  const maskedEmail = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.maskedEmail as string
  );

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const storedOtp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.otp as string
  );

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean
  );

  /**
   * Local state initiation region
   */
  const [code, setCode] = useState<string>(storedOtp || '');
  const [loading, setLoading] = useState(false);
  const [reSend, setReSend] = useState(false);
  const [countDown, setCountDown] = useState<string>('01:00');

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!reSend) {
      const currentData = new Date();
      const countDownDate = new Date(
        currentData.getTime() + 1 * 60000
      ).getTime();
      timer.current = setInterval(() => {
        setCountDown(() => {
          const now = new Date().getTime();
          const diff = countDownDate - now;
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          if (minutes === 0 && seconds === 0) {
            setReSend(true);
            // if (timer.current) clearInterval(timer.current);
            return '0:0';
          }
          return `${minutes}:${seconds}`;
        });
      }, 1000);
    }
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [reSend]);

  /**
   * Custom handlers initiation region
   */

  const resendOtp = async () => {
    try {
      if (countDown !== '0:0') return;
      setLoading(true);
      const response = await onboardingService.resendOtp({
        type: 'email',
        reference,
      });
      success('Done!', 'Another OTP has been sent');
      setReSend(false);
    } catch (err) {
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  const continueHandle = () => {
    (async () => {
      setLoading(true);
      try {
        const response = await onboardingService.validateEmailOtp({
          otp: code,
          reference,
        });
        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              reference: response.data.reference,
              businessType: response.data?.businessType?.toLowerCase() ?? '',
            })
          );
        });
        if (response.actionRequired === 'SUBMIT_PROFILE') {
          route.push(actions[response.actionRequired + '_PASSWORD']);
        } else {
          route.push(actions[response.actionRequired]);
        }

        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              reference: response.data.reference,
              businessType: response.data?.businessType ?? '',
              businessInfo: response.data.businessInfo ?? '',
              ...(response.data.user && response.data.user.profile
                ? {
                    credentials: {
                      email: response.data.user?.email ?? '',
                      bvn: response.data.user?.profile?.bvn ?? '',
                      phone: response.data.user?.phone ?? '',
                      idNumber: response.data.user?.idNumber ?? '',
                      idType: response.data.user?.idType ?? '',
                    },
                  }
                : {}),
              directors: [
                ...response.data.proprietors,
                ...response.data.partners,
                ...response.data.signatories,
                ...response.data.trustees,
                ...response.data.directors,
              ],
              referenceInfo: response.data.references,
              fileUploads: response.data.fileUploads,
              operators: [...response.data.operators],
              role: response.data.client?.role ?? '',
              workflow: response.data.workflow,
              user: response.data.user,
              userId: response.data.userId,
              ultimateBeneficialOwners: response.data.ultimateBeneficialOwners,
            })
          );
        });

        // debug.info('', response); // TEMPORARY
      } catch (err) {
        if (err.code === 'INVALID_ACTION_REQUIRED') {
          route.push(
            actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
          );
        } else {
          dispatch(
            OnboardingActions.handleSetError(
              err.message || 'Can not complete request at the moment'
            )
          );
        }
      }
      setLoading(false);
    })();
  };

  /**
   * useEffect region
   */
  useEffect(() => {
    if (code.length > 5) {
      continueHandle();
    }
  }, [code]);

  return (
    <S.Container>
      <Title color={colors.black._20} title='Email OTP verification' />
      <S.Wrapper>
        <Text color='#000000'>
          An OTP has been your email address
          {` ${maskedEmail || ''}.`}
          Input token to verify your identity
        </Text>
        <OnboardingCard>
          <S.OtpWrapper>
            <Text bold color={colors.black._20} size={4}>
              Enter OTP sent to your email address
            </Text>
            <OtpInput
              secure
              codeLength={6}
              value={code}
              loading={loading}
              onCodeChange={setCode}
            />
          </S.OtpWrapper>
          <S.TextContainer
            style={{
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Text
              style={{
                cursor: countDown === '0:0' ? 'pointer' : 'default',
              }}
              onClick={resendOtp}
              color={countDown === '0:0' ? colors.pink._100 : '#000000'}
              size={5}
              aria-hidden='true'
            >
              Resend code in
            </Text>

            <Text color='#B10180' size={5}>
              {countDown} secs
            </Text>
          </S.TextContainer>
          <S.ButtonGroup>
            <div>
              <Button
                style={{ width: 304, padding: 20 }}
                label='Continue'
                loading={loading}
                onClick={continueHandle}
              />
            </div>
          </S.ButtonGroup>
        </OnboardingCard>
      </S.Wrapper>
    </S.Container>
  );
};

export default CommonEmailOtp;
