import styled from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../../styles/utils';
import { colors, transition } from '../../../../styles/variables';

const Container = styled.div``;

const TopText = styled.div`
margin-bottom:12px;
`;

const TopTextInner = styled.div`
display: flex;
gap:4px;
flex-direction:column;
`;

const TopTextInfo = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
margin-bottom:12px;
margin-top:32px;
cursor:pointer;
gap: 8px;`;

const Wrapper = styled.div`
gap: 9px;
  margin-top: ${ptr(8)};
`;

const ButtonGroup= styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const TopRhs = styled.div`
 
`;

const TopLhs = styled.div`

`;

const TopTextLhs = styled.div`
  display:flex;
  gap:8px; 
  align-items:center;
`;


const TopTextRhs = styled.div`
 
`;

const Top= styled.div`
display:flex;
margin-bottom:14px;
justify-content: space-between;
`;

const FirstDropdown = styled.div`
margin-top: ${ptr(32)};
margin-bottom: ${ptr(32)};
display:flex;
flex-direction:column;
gap:32px;
`;

const SecondDropdown = styled.div`
margin-top: ${ptr(32)};
margin-bottom: ${ptr(32)};
display:flex;
flex-direction:column;
gap:32px;
`;

const UploadBody = styled.div`
margin-top: ${ptr(32)};
margin-bottom: ${ptr(32)};
`;

const RegCertificateBody = styled.div`
margin-top: ${ptr(32)};
margin-bottom: ${ptr(32)};
display:flex;
flex-direction:column;
gap:32px;
`;

const UtilityBody = styled.div`
display:flex;
flex-direction:column;
gap:32px;
margin-bottom: ${ptr(84)};
`;

const ManageButton = styled.div`  
    display: flex;
    justify-content: flex-end;  
    > button {
     width: 100%
    }
  `;



const ContainerB = styled.div`
margin-top:-20px;
  padding: 10px 10px 10px 10px; 
  /* width:50vw; */
`;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};
  flex-wrap: wrap;
  width: inherit;
`;


const InnerItem = styled.div`
width:100%;
display:flex; 
margin-bottom: ${ptr(-12)};
 position:'relative';
gap:10px;
border-radius: ${ptr(4)};
`;

const FileOuter = styled.div`
  border: 1px solid none;
  padding: 12px;
  width: 180px;
  display: flex;
 
  justify-content: space-around;
  flex-direction: column;
`;

const File = styled.div`
  background-color: ${colors.black._40};
  border: ${ptr(1)} solid ${colors.black._5};
  box-sizing: border-box;
  border-radius: ${ptr(4)};
  width: ${ptr(192)};
  height: ${ptr(235)};
  // margin-bottom: ${ptr(82)};
  background-size: cover;
 position: relative;

  // > span {
  //   position: absolute;
  //   margin-top: ${ptr(140)};
  // }
`;

const FileTexts = styled.div`
margin-top:12px;
`;

const FullScreen = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  width: ${ptr(192)};

  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  justify-content: space-between;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(18)}; 
  height: ${ptr(18)};
  border-radius: ${ptr(12)};
 border: ${ptr(1)} #B10180 solid;
 

  
 ${crossBrowserTransition(`${transition.slow}`)}
  
`;




export { Container, Wrapper, UploadBody, SecondDropdown, FirstDropdown, RegCertificateBody, UtilityBody, ManageButton, TopTextInner, TopText, TopTextInfo, TopLhs, TopRhs, Top, ButtonGroup, TopTextLhs, TopTextRhs, ContainerB, InnerItem, ItemRow, FileOuter, File, FileTexts, FullScreen, Circle };
