import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const Container = styled.div`
  > button {
    width: 100%
  }

  > button + span {
    display: block;
    margin-top: ${ptr(16)};

    > a {
      color: ${colors.pink._100}
    }
  }
`;

const Wrapper = styled.div`
  // margin-top: ${ptr(8)};
  // margin-bottom: ${ptr(40)};

  > span {
    display: block;
     margin-bottom: ${ptr(14)};
  }
`;

const InfoContainer
= styled.div`
width:85%;
  margin: 4px 0px 32px 0;
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;


const IconCircleWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
   gap:4px;
   cursor:pointer;
   align-items: center;
  // width: ${ptr(24)};
  // height: ${ptr(24)};
  // border-radius: 50%;
  margin-top:15px;
`;

export { Container, Wrapper, InfoContainer,IconCircleWrapper, ButtonGroup };
