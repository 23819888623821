import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './styles';
import { validateObject, ValidateResult } from '../../../utils/validation';
import { success, failed } from '../../../components/Toasts';
import { FormTitle } from '../../../components/FormTitle';
import { FormButton, FormInput } from '../../../styles/wrappers';
import { TextInput } from '../../../components/TextInput';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import { Button } from '../../../components/Button';
import ConfigPassword from '../../../configs/password';
import { Icon } from '../../../components/Icon';
import authService from '../../../services/internal/auth/auth.service';
import debug from '../../../services/internal/debbug.service';

const SCHEME_VALIDATION_PASSWORD = {
  minimumCharacters: (value: string) => value.length >= ConfigPassword.minLength,
  uppercase: (value: string) => ConfigPassword.uppercase.test(value),
  lowercase: (value: string) => ConfigPassword.lowercase.test(value),
  special: (value: string) => ConfigPassword.special.test(value),
};

export const CorporateNewPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [validation, setValidation] = useState<ValidateResult | undefined>(undefined);

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const onCreateNewPassword = async () => {
    setLoading(true);
    if (token) {
      try {
        await authService.newPassword(password, repeatPassword, token);
        success('Success!', 'New password has been successfully created.');
        history.push('/login');
        setLoading(false);
      } catch (err) {
        failed('Failed!', 'Failed to change password');
        debug.error('Failed to change password', err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const validationResult = validateObject({
      minimumCharacters: password,
      uppercase: password,
      lowercase: password,
      special: password,
    }, SCHEME_VALIDATION_PASSWORD);

    setValidation({
      ...validationResult,
      isValid: validationResult.isValid,
    });
  }, [password, repeatPassword]);

  return (
    <>
      <FormTitle
        title="Hello Tobi"
        description="Create a new password."
      />
      <FormInput.Wrapper>
        <TextInput
          label="New password"
          type="password"
          value={password}
          disabled={loading}
          maxLength={ConfigPassword.maxLength}
          onChange={setPassword}
          valid={validation?.isValid}
        />
      </FormInput.Wrapper>
      <S.PasswordDescriptionWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.minimumCharacters?.isValid && (
              <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least 8 characters.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.uppercase?.isValid && (
            <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least one uppercase is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.lowercase?.isValid && (
            <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least one lowercase is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
        <S.PasswordDescriptionItemWrapper>
          <>
            {validation?.data.special?.isValid && (
            <Icon name="check-mark" color={colors.green._100} />
            )}
            <Text
              color={colors.black._60}
              size={7}
            >
              At least one special character is entered.
            </Text>
          </>
        </S.PasswordDescriptionItemWrapper>
      </S.PasswordDescriptionWrapper>
      <FormInput.Wrapper>
        <TextInput
          label="Repeat new password"
          type="password"
          value={repeatPassword}
          maxLength={ConfigPassword.maxLength}
          onChange={setRepeatPassword}
          disabled={loading}
          valid={validation?.isValid && password === repeatPassword}
        />
      </FormInput.Wrapper>
      <FormButton.WrapperEnd>
        <S.buttonContainer>
          <Button
            label="Log In"
            disabled={!validation?.isValid || password !== repeatPassword || loading}
            onClick={onCreateNewPassword}
          />
        </S.buttonContainer>
      </FormButton.WrapperEnd>
    </>
  );
};
