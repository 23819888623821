/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Text } from '../../components/Text';
import * as S from './styles';
import { colors } from '../../styles/variables';
import { PaymentHistory, TransactionReport } from './TransactionReport';
import { YearDropList } from '../../components/YearDropList';
import { AnalyticReport } from './AnalyticReport';
import { CompanyReportHead } from '../Dashboard/components/CompanyReportHead';
import { PaymementAccountInterface } from '../../services/internal/payments/IPayments';
import { ErrorApiInterface } from '../../services/internal/api/api.error';
import { IPayments } from '../../components/PaymentConfirms/ConfirmPayment';
import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';
import { CompanyInterface } from '../../redux/company/ICompany';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { failed } from '../../components/Toasts';
import { CompanyActions } from '../../redux/company/actions';
import getQueryString from '../../utils/getQueryString';
import { Preload } from '../../components/Preload';
import { TransactionNReport } from './TransactionNReport';

export interface ViewComponentProps {
  Analytics: any;
  UserReport: any;
}
export interface Categorystatistics {
  Analytics: any;
  UserReport: any;
}
export interface Monthlystatistics {
  expenseCount: number;
  expenses: number;
  income: number;
  incomeCount: number;
  name: string;
}
export interface AnalyticsdataProps {
  // data: Array<IPayments>,
  account_balance: number;
  total_credit_amount: number;
  total_credit_count: number;
  total_debit_amount: number;
  total_debit_count: number;
  total_transaction_count: number;
  category_statistics: {
    billPayments: number;
    others: number;
    pension: number;
    salary: number;
    transfers: number;
  };
  monthly_statistics: Array<Monthlystatistics>;
}
const defaultResponseData: AnalyticsdataProps = {
  account_balance: 0,
  total_credit_amount: 0,
  total_credit_count: 0,
  total_debit_amount: 0,
  total_debit_count: 0,
  total_transaction_count: 0,
  category_statistics: {
    billPayments: 0,
    others: 0,
    pension: 0,
    salary: 0,
    transfers: 0,
  },
  monthly_statistics: [],
};

const separator = '=';

export const AnalyticsNReport: React.FC = () => {
  /**
   * Hooks initiation region
   */
  /**
   * Redux store initiation region
   */
  const dispatch = useDispatch();
  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active,
  );
  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list,
  );

  /**
 * Local state initiation region
 */
  const { state } = useLocation<{ tab: string } & { openStatement: boolean }>();

  const [CurrentUserView, ChangeuserView] = useState<string>('Analytics');
  const [paymentAccounts, setPaymentAccounts] = useState<
    Array<PaymementAccountInterface & { isChecked: boolean }>
  >();
  const [currentPaymentAccId] = useState<number>(0);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [currentCompanyId, setCurrentCompanyId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [payments, setPayments] = useState<Array<PaymentHistory>>([]);
  const [dataAnalytics, setdataAnalytics] = useState<AnalyticsdataProps>(
    defaultResponseData
  );
  const [currentAccountId, setcurrentAccountId] = useState<string>('');
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [totalCreditAmount, settotalCreditAmount] = useState<number>(0);
  const [totalDebitAmount, settotalDebitAmount] = useState<number>(0);
  const [totalcreditcount, settotalcreditcount] = useState<number>(0);
  const [totaldebitcount, settotaldebitcount] = useState<number>(0);

  const UserViews: ViewComponentProps = {
    Analytics: <AnalyticReport Data={dataAnalytics} Year={year} />,
    UserReport: <TransactionNReport />,
  };
  const CurrentRenderView = CurrentUserView as keyof typeof UserViews;

  const ChangeUserDisplay = (userView: string) => {
    ChangeuserView(userView);
  };

  const getPayments = async (): Promise<
    ErrorApiInterface | Array<IPayments>
  > => {
    try {
      setLoading(true);
      const result = await paymentsService.getPayments();
      setLoading(false);
      const paymentdata = result.data.map((el: IPayments) => ({
        ...el,
        check: false,
      }));
      setPayments(paymentdata);
      return await Promise.resolve(result);
    } catch (err) {
      debug.error('Error retrieving payments', err);
      return await Promise.reject(err);
    }
  };

  const getPaymentAccounts = async (id: string): Promise<void> => {
    try {
      const result = await paymentsService.getPaymentAccounts(id);
      setPaymentAccounts(
        result.map((item, index) => ({ ...item, isChecked: index === 0 }))
      );
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error while getting payment accounts');
    }
  };

  const getCompanyFromUrl = (location: Location | any) => {
    const companyId = location.search.split(separator)[1];
    if (companyId) {
      const company = companies.find((item) => item.id === companyId);
      setCurrentCompanyId(company?.id || '');
    }
  };
  const getAnalytics = async (): Promise<void> => {
    try {
      if (currentAccountId && currentAccountId !== '') {
        const result = await paymentsService.getAnalytics(
          currentAccountId,
          getQueryString({ year })
        );
        const totalcreditamount = result.total_credit_amount;
        const totaldebitamount = result.total_debit_amount;
        const debitcount = result.total_debit_count;
        const creditcount = result.total_transaction_count;
        settotalCreditAmount(totalcreditamount);
        settotalDebitAmount(totaldebitamount);
        settotalcreditcount(creditcount);
        settotaldebitcount(debitcount);
        setdataAnalytics(result);
      }
    } catch (err) {
      const error = err as Error;
      failed('Failed', error.message || 'Error loading analytics');
    }

    // setLoading(true);
  };

  useEffect(() => {
    if (state && state.tab) {
      ChangeUserDisplay(state.tab);
    }
    if (state && state.openStatement) {
    }
  }, []);

  useEffect(() => {
    if (activeCompany) {
      getPaymentAccounts(currentCompanyId || activeCompany.id);
    } else {
      dispatch(CompanyActions.getActiveCompany());
    }
  }, [currentCompanyId, activeCompany]);

  useEffect(() => {
    getAnalytics();
  }, [currentAccountId, year]);

  useEffect(() => {
    const currentAccid =
      paymentAccounts && paymentAccounts.filter((el) => el.isChecked === true);
    if (currentAccid && currentAccid.length > 0) {
      const accountId = currentAccid[0].id;
      setcurrentAccountId(accountId);
    }
  }, [paymentAccounts]);

  const FilterByYear = (value: number | string) => {
    if (typeof value === 'number') setYear(value);
  };
  return (
    <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]} isPage>
      <S.Container>
        <S.TopWrapper>
          <S.TableConatiner>
            <S.TableAlign>
              <S.Tab
                onClick={() => {
                  ChangeUserDisplay('Analytics');
                }}
              >
                <Text
                  size={4}
                  color={
                    CurrentUserView === 'Analytics'
                      ? colors.pink._100
                      : colors.black._60
                  }
                  bold
                >
                  Analytics
                </Text>
              </S.Tab>
              <S.MarginLeftComp
                onClick={() => {
                  ChangeUserDisplay('UserReport');
                }}
              >
                <Text
                  size={4}
                  color={
                    CurrentUserView === 'UserReport'
                      ? colors.pink._100
                      : colors.black._60
                  }
                  bold
                >
                  Transaction Report
                </Text>
              </S.MarginLeftComp>
            </S.TableAlign>

            {CurrentUserView === 'Analytics' && (
              <YearDropList
                text={!year ? 'Year' : year.toString()}
                onChange={FilterByYear}
              />
            )}
          </S.TableConatiner>
        </S.TopWrapper>
        <S.TopWrapper>
          <S.TopContainer>
            {CurrentUserView === 'Analytics' ? (
              <>
                {paymentAccounts && paymentAccounts[currentPaymentAccId] ? (
                  <CompanyReportHead
                    openStatement={state?.openStatement ?? false}
                    balance={
                      paymentAccounts
                        .filter((pay) => pay.isChecked)[0]
                        ?.balance.toString() || ''
                    }
                    accountId={
                      paymentAccounts.filter((pay) => pay.isChecked)[0]?.id
                    }
                    nuban={
                      paymentAccounts.filter((pay) => pay.isChecked)[0]
                        ?.nuban || ''
                    }
                    outflow={{
                      balance: totalDebitAmount,
                      debitCount: totaldebitcount,
                      sub: '',
                    }}
                    inflow={{
                      balance: totalCreditAmount,
                      creditCount: totalcreditcount,
                      sub: '',
                    }}
                    od={
                      paymentAccounts.filter((pay) => pay.isChecked)[0]
                        ?.allowOverdraft === true
                    }
                    odLimit={
                      paymentAccounts.filter((pay) => pay.isChecked)[0]
                        ?.overdraftLimit
                    }
                    open={openDropdown}
                    onDropdown={() => {
                      setOpenDropdown(!openDropdown);
                    }}
                    accounts={paymentAccounts.map((item, index) => ({
                      id: item.id,
                      nuban: item.nuban,
                      type: item.type,
                      balance: `${item.balance}`,
                      isChecked: item.isChecked,
                      setIsChecked: (value: boolean) => {
                        const temp = [...paymentAccounts];
                        temp.map((acc, i) => {
                          if (i === index) {
                            acc.isChecked = value;
                          } else {
                            acc.isChecked = false;
                          }
                          return acc;
                        });
                        // temp[index].isChecked = value;
                        setPaymentAccounts(temp);
                      },
                    }))}
                  />
                ) : (
                  <S.PreloadContainer2>
                    <Preload />
                  </S.PreloadContainer2>
                )}
              </>
            ) : null}
          </S.TopContainer>
        </S.TopWrapper>
        {UserViews[CurrentRenderView]}
      </S.Container>
    </AccessControl>
  );
};
