import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './styles';
import {
  PageTitle,
  SettingsWrapper,
  SettingsTypeWrapper,
} from '../../styles/wrappers';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';
import { ClickablePanel } from '../../components/ClickablePanel';
import { insertParams } from '../../utils/navigation';
import {
  addBusinessRoute,
  companyDetailsSettingsRoute,
  loginPasswordSettingsRoute,
  transactionPinSettingsRoute,
} from '../../navigation';
import { OnboardingActions } from '../../redux/onboarding/actions';
import { CompanyInterface } from '../../redux/company/ICompany';
import { CompanyActions } from '../../redux/company/actions';
import AccessControl, { EPermissions } from '../../components/AccessControl';

export const SettingsAndSupport: React.FC = () => {
  const dispatch = useDispatch();
  const state = useLocation().state as { type: string };

  const route = useHistory();

  const addCompany = () => {
    dispatch(OnboardingActions.handleSetEnv());
    // route.push(addCompanyRoute);
    route.push(addBusinessRoute);
  };

  const company = useSelector(
    (state: { company: CompanyInterface }) => state.company
  );

  React.useEffect(() => {
    if (!company.list.length) {
      dispatch(CompanyActions.getCompaniesList());
    }
  }, [company.list]);

  React.useEffect(() => {
    if (route.action === 'PUSH') {
      if (state?.type === 'pin') {
        route.push(transactionPinSettingsRoute);
      } else if (state?.type === 'password') {
        route.push(loginPasswordSettingsRoute);
      }
    }
  }, [state]);

  return (
    <div>
      <PageTitle.Wrapper>
        <Text color={colors.black._100} size={4} bold>
          Companies
        </Text>
      </PageTitle.Wrapper>

      <SettingsWrapper>
        <SettingsTypeWrapper>
          <ClickablePanel
            onClick={addCompany}
            icon="add"
            description="Add company"
            subDescription="Register a new company"
            theme="light"
          />
        </SettingsTypeWrapper>
        {company.list &&
          company.list.length >= 1 &&
          company.list
            .filter((data) => data.status === 'active')
            .map((comp, index) => (
              <SettingsTypeWrapper key={index.toString()}>
                <ClickablePanel
                  icon="brief-case"
                  to={insertParams(companyDetailsSettingsRoute, {
                    id: comp.id,
                  })}
                  description={
                    comp.name
                      ? `${comp.name.substring(0, 20)}${
                          comp.name.length > 20 ? '...' : ''
                        }`
                      : '-'
                  }
                  subDescription={`RC: ${comp.cac ? comp.cac : '-'}`}
                />
              </SettingsTypeWrapper>
            ))}
      </SettingsWrapper>

      <S.Row>
        <PageTitle.Wrapper>
          <Text color={colors.black._100} size={4} bold>
            Settings
          </Text>
        </PageTitle.Wrapper>
        <SettingsWrapper>
          <AccessControl requiredPermission={[EPermissions.VIEW_OPERATOR]}>
            <SettingsTypeWrapper>
              <ClickablePanel
                to="/users"
                icon="employee"
                description="User managment"
              />
            </SettingsTypeWrapper>
          </AccessControl>

          <SettingsTypeWrapper>
            <ClickablePanel
              to={transactionPinSettingsRoute}
              icon="pin"
              description="Change transaction pin"
            />
          </SettingsTypeWrapper>

          <SettingsTypeWrapper>
            <ClickablePanel
              to={loginPasswordSettingsRoute}
              icon="password"
              description="Change password"
            />
          </SettingsTypeWrapper>
        </SettingsWrapper>
      </S.Row>

      <S.Row>
        <PageTitle.Wrapper>
          <Text color={colors.black._100} size={4} bold>
            Support
          </Text>
        </PageTitle.Wrapper>
        <SettingsWrapper>
          {/* <SettingsTypeWrapper>
            <ClickablePanel icon="faq" description="FAQ" />
          </SettingsTypeWrapper> */}

          <SettingsTypeWrapper>
            <ClickablePanel icon="ba" description="Business advisory" />
          </SettingsTypeWrapper>
          <AccessControl requiredPermission={[EPermissions.CREATE_OPERATOR]}>
            <SettingsTypeWrapper>
              <ClickablePanel
                icon="git-branch"
                description="Requests"
                to="/settings/requests"
              />
            </SettingsTypeWrapper>
          </AccessControl>
        </SettingsWrapper>
      </S.Row>
    </div>
  );
};
