import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

interface DatePickerProps {
  isOpen: boolean;
  dateChange?: (date:Date) => void;
  selected?: Date
}
const Datepicker: FC<DatePickerProps> = ({ isOpen, dateChange, selected }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const onChange = (date: Date) => {
    if (selected) {
      setSelectedDate(selected);
    } else {
      setSelectedDate(date);
    }

    if (dateChange) dateChange(date);
  };

  return (
    <div>
      {isOpen && (
        <DatePicker
          selected={selectedDate}
          onChange={onChange}
          inline
        />
      )}
    </div>
  );
};

export default Datepicker;
