/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import paymentsService from '../../services/internal/payments/payments.service';
import debug from '../../services/internal/debbug.service';
import successImg from './assets/Images/success.png';
import { Text } from '../Text';
import { OtpInput } from '../OtpInput';
import * as S from './styles';
import { colors } from '../../styles/variables';
import { Button } from '../Button';
import { PaymentDetails } from '../../pages/OtherBankTransfer';
import { success, failed, warning } from '../Toasts';
import { formatCash } from '../../utils/money';
import { downloadPDF } from '../../utils/download';
import BeneficaryActions from '../../redux/beneficiary.management/action';
import { Icon } from '../Icon';
import { getBeautyDateTimeString } from '../../utils/datetime';

export interface PinAuthProps {
  paymentsDetails?: Array<PaymentDetails>;
  action?: (pin: string, checkPin: boolean, otp?: string) => Promise<any>;
  actionName?: string;
  batchName?: string;
}
export interface PaymentSuccessDetailsProps {
  payresponse: Array<PaymentSuccessDetails>;
}
export interface PaymentSuccessDetails {
  id: string;
  isFlagged: boolean;
  retryCount: number;
  userId: string;
  businessId: string;
  accountId: string;
  reference: string;
  amount: number;
  receiverName: string;
  receiverBvn: number;
  receiverNuban: number;
  receiverBankCode: number;
  receiverClientId: number;
  receiverSavingsId: number;
  comment: null;
  status: string;
  destinationBank: string;
  lastFailureResponse: string;
  actionRequired: string;
  fromAccountNo: string;
  senderName: string;
  createdAt: string;
  requestReference: string;
  sessionId: string;
  transactionId: string;
}
export interface TransaferResponse {
  data: Array<PaymentSuccessDetails>;
  meta: {
    failed: number;
    successful: number;
    total: number;
    pending: number;
  };
}
const defaultResponseData: TransaferResponse = {
  data: [],
  meta: {
    failed: 0,
    successful: 0,
    total: 0,
    pending: 0,
  },
};
export const PinAuth: React.FC<PinAuthProps> = ({
  paymentsDetails,
  ...props
}) => {
  // const [width] = useWindowSize();
  const dispatch = useDispatch();
  /**
   * Local state initiation region
   */
  const [currentView, setcurrentView] = useState<string>('Pin');
  const [pin, setPin] = useState<string>('');
  const [warningTitle, setWarninTitle] = useState(
    'Some of your payments failed.'
  );
  const [warningMessage, setWarningMessage] = useState(
    'Please check your recent transactions before re-initiating!'
  );
  const [isproccessing, setIsproccessing] = useState<boolean>(false);
  const [paymentSuccessResponseData, setPaymentSuccessResponseData] = useState<
    TransaferResponse
  >(defaultResponseData);
  const [reSend, setReSend] = useState(false);
  const [countDown, setCountDown] = useState<string>('04:00');
  const [validatingOtp, setValidatingOtp] = useState(false);
  const [checkPin, setCheckPin] = useState(true);

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!reSend && currentView === 'otp') {
      const currentData = new Date();
      const countDownDate = new Date(
        currentData.getTime() + 4 * 60000
      ).getTime();
      timer.current = setInterval(() => {
        setCountDown(() => {
          const now = new Date().getTime();
          const diff = countDownDate - now;
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          if (minutes === 0 && seconds === 0) {
            setReSend(true);
            if (timer.current) clearInterval(timer.current);
          }
          return `${minutes}:${seconds}`;
        });
      }, 1000);
    }
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [reSend, currentView]);
  const determineTransferInfoDisplay = (
    successcount: number,
    failurecount: number,
    totalcount: number,
    pendingcount: number
  ) => {
    let responseObject = {
      bgcolor: '',
      bodyText: '',
      headers: '',
      type: '',
    };
    // const fullSuccess = successcount + pendingcount;
    const fullSuccess = successcount;
    if (failurecount === totalcount) {
      responseObject = {
        bgcolor: 'red',
        bodyText: 'Failed',
        headers: 'Operation Failed',
        type: 'fail',
      };
    } else if (fullSuccess === totalcount) {
      responseObject = {
        bgcolor: '#36B83B',
        bodyText: 'Success!',
        headers: 'Your bank transfer was successful!',
        type: 'sucess',
      };
    } else if (fullSuccess < totalcount) {
      responseObject = {
        bgcolor: '#FFBF00',
        bodyText: '',
        headers: 'One or more transactions are still processing',
        type: 'warning',
      };
    }

    // else {
    //   responseObject = {
    //     bgcolor: '#FFBF00',
    //     bodyText: '',
    //     headers: 'Operation successful!',
    //     type: 'warning',
    //   };
    // }

    return responseObject;
  };

  const getStatusColor = (status: string) => {
    if (status === 'pending') {
      return colors.orange._90;
    }
    if (status === 'failed') {
      return colors.pink._80;
    }
    if (status === 'success') {
      return colors.green._100;
    }
    if (status === 'unknown') {
      return colors.amber._100;
    }
    return colors.orange._90;
  };

  const getMaskedAccountNumber = (number: string) => {
    const first6 = number.substring(0, 6).replace(/\d/g, '*');
    const last4 = number.substring(6, 10);
    return `${first6}${last4}`;
  };

  const verifyUserPin = async (otp?: string): Promise<void> => {
    // setcurrentView('otp');
    setIsproccessing(true);
    try {
      if (paymentsDetails) {
        const otpValue = otp || '';
        const payments = paymentsDetails.map((item) => ({
          paymentAccount: item.paymentAccount?.key || '',
          receiverNuban: item.destinationAccount?.value || '',
          receiverBankCode: item.destinationBank?.key || '',
          amount: Number(item.amount?.value.replace(/[\s,₦%]/g, '')),
          comment: item.comment || '',
          beneficiary: item.beneficiaryTypes?.saveBeneficiary
            ? item.beneficiaryTypes.types.map((el) => el.id)
            : [],
        }));
        const result = await paymentsService.makeTransfer(
          {
            payments,
          },
          checkPin,
          pin,
          otpValue,
          props.batchName
        );

        if (result.message && result.message === 'otp generated') {
          setReSend(false);
          setCheckPin(false);
          success('Successful', 'Enter the OTP from your authenticator app');
          setcurrentView('otp');
        } else {
          const ThisPaymentResponse = determineTransferInfoDisplay(
            result.meta.successful,
            result.meta.failed,
            result.meta.total,
            result.meta.pending
          ).type;
          if (ThisPaymentResponse === 'sucess') {
            success('Successful', 'Transfer completed successfully');
          } else if (ThisPaymentResponse === 'warning') {
            warning('Successful', 'Operation successful!');
          } else {
            failed('Failed', 'Operation Failed');
          }
          if (paymentsDetails.length === 1) {
            result.data[0].senderName =
              paymentsDetails[0].paymentAccount?.category;
            result.data[0].fromAccountNo =
              paymentsDetails[0].paymentAccount?.label;
            result.data[0].destinationBank =
              paymentsDetails[0].destinationBank?.label;
          }
          setPaymentSuccessResponseData(result);
          setcurrentView('PaymentSuccess');
          setIsproccessing(false);
        }
      }
      if (props.action) {
        const result = await props.action(pin, checkPin, otp);
        if (result.message && result.message === 'otp generated') {
          setReSend(false);
          setCheckPin(false);
          success('Successful', 'Enter the OTP from your authenticator app');
          setcurrentView('otp');
        } else {
          setcurrentView('Pin');
          // success('Successful', 'Operation successful');
          const ThisPaymentResponse = determineTransferInfoDisplay(
            result.meta.successful,
            result.meta.failed,
            result.meta.total,
            result.meta.pending
          ).type;
          if (ThisPaymentResponse === 'sucess') {
            success('Successful', 'Operation successful');
          } else if (ThisPaymentResponse === 'warning') {
            warning('Successful', 'Operation successful!');
          } else {
            failed('Failed', 'Operation Failed');
          }

          setIsproccessing(false);
        }
      }
      dispatch(BeneficaryActions.clearBeneficiary());
    } catch (error) {
      const err = error as any;
      if (
        paymentsDetails &&
        paymentsDetails.length > 1 &&
        otp &&
        err.code !== 'EXPIRED_OTP' &&
        err.code !== 'INVALID_OTP'
      ) {
        setcurrentView('Warning');
        setWarninTitle('Some of your payments failed.');
        setWarningMessage(
          'Please check your recent transactions before re-initiating!'
        );
      } else if (err.code === 'DUPLICATE') {
        setcurrentView('Warning');
        setWarninTitle('Duplicate');
        setWarningMessage(err.message);
      } else {
        failed(
          'Error',
          err.message ||
            'Unable to make payment at the moment, please try later'
        );
        setIsproccessing(false);
      }
      //   debug.error('Error while payment creation', err);
      //   debug.error('Error while payment creation', err.message);
    }
    // debug.info('Result', paymentsDetails);
  };

  const resendOtp = async () => {
    await verifyUserPin();
  };

  const getOtpValue = async (value: string) => {
    if (value.length === 6) {
      setValidatingOtp(true);
      await verifyUserPin(value);
      setValidatingOtp(false);
    }
  };
  return (
    <>
      <S.Container>
        {currentView === 'Pin' ? (
          <S.PaymentWrapper>
            <S.BodyWrapper>
              <Text color={colors.black._100} size={2} bold>
                PIN Authentication
              </Text>
              <Text color={colors.black._100} size={5}>
                Enter your 4-digit PIN.
              </Text>
              <S.OtpWapper>
                <OtpInput secure codeLength={4} onCodeChange={setPin} />
              </S.OtpWapper>

              <Button
                disabled={pin.length < 4 || isproccessing}
                label={
                  isproccessing === true
                    ? 'Processing..'
                    : props.actionName || 'Make a transfer'
                }
                onClick={() => verifyUserPin()}
              />
            </S.BodyWrapper>
          </S.PaymentWrapper>
        ) : currentView === 'otp' ? (
          <S.PaymentWrapper>
            <S.BodyWrapper>
              <Text color={colors.black._100} size={2} bold>
                OTP Code
              </Text>
              <Text color={colors.black._100} size={5}>
                {/* We have sent an OTP to your registered phone number. */}
                Open your authenticator app
              </Text>
              <Text color={colors.black._100} size={5}>
                Enter the code below to proceed.
              </Text>
              <S.OtpWapper>
                <OtpInput
                  secure
                  codeLength={6}
                  loading={validatingOtp}
                  onCodeChange={(value) => getOtpValue(value)}
                />
              </S.OtpWapper>

              {/* <Text color={colors.black._100} size={5}>
                <span
                  style={{ opacity: reSend ? 1 : 0.3, cursor: 'pointer' }}
                  onClick={resendOtp}
                  aria-hidden="true"
                >
                  Resend code
                </span>
                ({countDown})
              </Text> */}
            </S.BodyWrapper>
          </S.PaymentWrapper>
        ) : currentView === 'PaymentSuccess' ? (
          <>
            <div id="pdf">
              {paymentSuccessResponseData.data.length === 1 ? (
                <S.TableHeaderStyle id="header">
                  <div>
                    <Icon
                      name="logo"
                      color={colors.black._100}
                      width={40}
                      height={40}
                    />
                  </div>
                  <Text color={colors.pink._100} size={3} bold>
                    Transaction Details
                  </Text>
                </S.TableHeaderStyle>
              ) : (
                <S.SuccessHeader
                  backgroundColor={
                    determineTransferInfoDisplay(
                      paymentSuccessResponseData.meta.successful,
                      paymentSuccessResponseData.meta.failed,
                      paymentSuccessResponseData.meta.total,
                      paymentSuccessResponseData.meta.pending
                    ).bgcolor
                  }
                >
                  <S.BodyWrapper>
                    <S.ImageIcon>
                      <img src={successImg} alt="" />
                    </S.ImageIcon>
                    <S.TextWrapper>
                      <Text color={colors.white._100} size={4} bold>
                        {
                          determineTransferInfoDisplay(
                            paymentSuccessResponseData.meta.successful,
                            paymentSuccessResponseData.meta.failed,
                            paymentSuccessResponseData.meta.total,
                            paymentSuccessResponseData.meta.pending
                          ).headers
                        }
                      </Text>
                    </S.TextWrapper>
                    <Text color={colors.white._100} size={6}>
                      {
                        determineTransferInfoDisplay(
                          paymentSuccessResponseData.meta.successful,
                          paymentSuccessResponseData.meta.failed,
                          paymentSuccessResponseData.meta.total,
                          paymentSuccessResponseData.meta.pending
                        ).bodyText
                      }
                    </Text>
                  </S.BodyWrapper>
                </S.SuccessHeader>
              )}

              <S.AccountDetails>
                {paymentSuccessResponseData.data.map((item, index) => {
                  const uniqueKey = index + 1;

                  return (
                    <>
                      <S.TableStyle key={uniqueKey}>
                        <tr>
                          <td>
                            <Text color={colors.black._100} size={5} bold>
                              Payment Description
                            </Text>
                          </td>
                          <td>
                            <Text color={colors.black._100} size={5}>
                              {item.comment}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text color={colors.black._100} size={5} bold>
                              Amount
                            </Text>
                          </td>
                          <td>
                            <Text color={colors.black._100} size={5}>
                              {`₦ ${formatCash(item.amount)}`}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text color={colors.black._100} size={5} bold>
                              Destination account
                            </Text>
                          </td>
                          <td>
                            {' '}
                            <Text color={colors.black._100} size={5}>
                              {item.receiverNuban}
                            </Text>
                          </td>
                        </tr>
                        {item.destinationBank && (
                          <tr>
                            <td>
                              <Text color={colors.black._100} size={5} bold>
                                Destination bank
                              </Text>
                            </td>
                            <td>
                              {' '}
                              <Text color={colors.black._100} size={5}>
                                {item.destinationBank || '-'}
                              </Text>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <Text color={colors.black._100} size={5} bold>
                              Receiver name
                            </Text>
                          </td>
                          <td>
                            {' '}
                            <Text color={colors.black._100} size={5}>
                              {item.receiverName}
                            </Text>
                          </td>
                        </tr>
                        {item.fromAccountNo && (
                          <tr>
                            <td>
                              <Text color={colors.black._100} size={5} bold>
                                Sender Account
                              </Text>
                            </td>
                            <td>
                              {' '}
                              <Text color={colors.black._100} size={5}>
                                {item.fromAccountNo
                                  ? getMaskedAccountNumber(item.fromAccountNo)
                                  : '-'}
                              </Text>
                            </td>
                          </tr>
                        )}
                        {item.senderName && item.senderName !== 'undefined' && (
                          <tr>
                            <td>
                              <Text color={colors.black._100} size={5} bold>
                                Sender Name
                              </Text>
                            </td>
                            <td>
                              {' '}
                              <Text color={colors.black._100} size={5}>
                                {item.senderName || '-'}
                              </Text>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <Text color={colors.black._100} size={5} bold>
                              Date
                            </Text>
                          </td>
                          <td>
                            {' '}
                            <Text color={colors.black._100} size={5}>
                              {/* Sept 16, 2021 | 5: 40 PM */}
                              {getBeautyDateTimeString(
                                new Date(item.createdAt),
                                false
                              )}
                            </Text>
                          </td>
                        </tr>
                      </S.TableStyle>

                      {paymentSuccessResponseData.data.length === 1 && <hr />}
                      {item && (
                        <S.TableStyle>
                          <tr>
                            <td>
                              <Text color={colors.black._100} size={5} bold>
                                Payment Reference
                              </Text>
                            </td>
                            <td>
                              <Text color={colors.black._100} size={5}>
                                {item.requestReference || item.reference}
                              </Text>
                            </td>
                          </tr>
                          {item.sessionId && (
                            <tr>
                              <td>
                                <Text color={colors.black._100} size={5} bold>
                                  Session ID
                                </Text>
                              </td>
                              <td>
                                <Text color={colors.black._100} size={5}>
                                  {item.sessionId}
                                </Text>
                              </td>
                            </tr>
                          )}
                          {item.transactionId && (
                            <tr>
                              <td>
                                <Text color={colors.black._100} size={5} bold>
                                  Transaction ID
                                </Text>
                              </td>
                              <td>
                                <Text color={colors.black._100} size={5}>
                                  {item.transactionId}
                                </Text>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <Text color={colors.black._100} size={5} bold>
                                Transaction Type
                              </Text>
                            </td>
                            <td>
                              <Text color={colors.black._100} size={5}>
                                Transfers
                              </Text>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Text color={colors.black._100} size={5} bold>
                                Transaction Status
                              </Text>
                            </td>
                            <td>
                              {' '}
                              <Text
                                color={getStatusColor(item.status)}
                                size={5}
                              >
                                {item.status}
                              </Text>
                            </td>
                          </tr>
                        </S.TableStyle>
                      )}

                      <S.HrStyled />
                    </>
                  );
                })}
                {/* {
                      paymentSuccessResponseData.meta.successful < paymentSuccessResponseData.meta.total
                        ? ( */}

                {paymentSuccessResponseData.data.length > 1 ? (
                  <S.CustomTable>
                    <tr>
                      <th>
                        <Text color={colors.black._100} size={5} bold>
                          Success Count:
                        </Text>
                      </th>
                      <th>
                        <Text color={colors.black._100} size={5}>
                          {paymentSuccessResponseData.meta.successful}
                        </Text>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <Text color={colors.black._100} size={5} bold>
                          Fail Count:
                        </Text>
                      </th>
                      <th>
                        <Text color={colors.black._100} size={5}>
                          {paymentSuccessResponseData.meta.failed}
                        </Text>
                      </th>
                    </tr>
                  </S.CustomTable>
                ) : null}

                <S.GroupButton id="action">
                  {/* <Button label="Share" backgroundColor="transparent" theme="info" />
                    <S.VertialHrStyled /> */}
                  {paymentSuccessResponseData.data.length === 1 && (
                    <Button
                      label="Download"
                      onClick={() => downloadPDF('pdf', 'action', 'Payment')}
                    />
                  )}
                </S.GroupButton>
              </S.AccountDetails>
            </div>
          </>
        ) : currentView === 'Warning' ? (
          <S.WarningWrapper>
            <S.BodyWrapper>
              <div style={{ textAlign: 'center' }}>
                <Icon
                  width={50}
                  height={50}
                  name="pink-info"
                  color={colors.amber._100}
                />
              </div>
              <Text color={colors.black._100} size={2} bold>
                {warningTitle}
              </Text>
              <Text color={colors.black._100} size={5}>
                {warningMessage}
              </Text>
            </S.BodyWrapper>
          </S.WarningWrapper>
        ) : null}
      </S.Container>
    </>
  );
};
