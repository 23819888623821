import React from 'react';
import { Button } from '../../../../components/Button';
import { QuestionsGenerator } from '../../../../components/QuestionsGenerator';
import { ContentContainer } from '../../../../styles/wrappers';

// import * as S from './styles';

export interface StepFirstProps {
  /**
   * handler on click continue
   */
  onContinue?: () => void,
}

export const StepFirst: React.FC<StepFirstProps> = ({ onContinue = () => {} }) => (
  <>
    <QuestionsGenerator
      questions={[
        {
          id: 'first',
          type: 'check-area',
          title: 'Are you the business owner?',
          items: [{
            value: 'Yes',
            theme: 'dark',
          }, {
            value: 'No',
          }],
        },
        {
          id: 'second',
          type: 'check-area',
          title: 'What is your type of business?',
          items: [{
            value: 'Sole Proprietor',
            theme: 'dark',
          }, {
            value: 'Limited Liability',
            theme: 'dark',
          }, {
            value: 'Partnership',
            theme: 'dark',
          }, {
            value: 'Trustee Account',
            theme: 'dark',
          }],
        },
        {
          id: 'third',
          type: 'check-area',
          title: 'Will you be the sole operator of this account?',
          items: [{
            value: 'Yes',
            theme: 'dark',
          }, {
            value: 'No',
            theme: 'dark',
          }],
        },
        {
          id: 'fourth',
          type: 'check-area',
          title: 'Is your business registred?',
          items: [{
            value: 'Yes',
            theme: 'dark',
          }, {
            value: 'No',
            theme: 'dark',
          }],
        },
      ]}
      title="More Information"
      description="Please answer a few questions below before you start creating your account."
    />
    <ContentContainer flex={1} justifyContent="flex-end">
      <Button label="Continue" onClick={onContinue} />
    </ContentContainer>
  </>
);
