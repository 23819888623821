import React, { useEffect, useState } from 'react';
import { Route, useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { OnboardingActions } from '../../redux/onboarding/actions';
import { OnboardingInterface } from '../../redux/onboarding/IOnboarding';

import { WelcomeSlider } from '../../components/WelcomeSlider';
import { Icon } from '../../components/Icon';
import { WarningModal } from '../../components/WarningModal';

import { TwoSide, Columns } from '../../styles/wrappers';
import { colors } from '../../styles/variables';
import * as S from './styles';

import PersonalInformation from '../../pages/Onboard/common.personal-info';
import OnboardingMethod from '../../pages/OnboardingMethod';
import VbankAccountSwitcher from '../../pages/Onboard/vbankAccount.switcher';
import EnterAccountNumber from '../../pages/Onboard/vbankAccount.switcher.has.enter-acc-number';
import Otp from '../../pages/Onboard/common.otp';
import Credentials from '../../pages/Onboard/common.create.credentials';
import CreatePassword from '../../pages/Onboard/common.create.password';
import CreatePin from '../../pages/Onboard/common.other.transaction-pin-create';
import ConfirmPin from '../../pages/Onboard/common.other.transaction-pin-confirm';
import BusinessType from '../../pages/Onboard/common.switcher';
import BusinessDetails from '../../pages/Onboard/common.company.enter-details';
import RegisterDetails from '../../pages/Onboard/vbankAccount.switcher.hasNot.enter-details';
import DirectorDetails from '../../pages/Onboard/common.company.enter-management';
import CheckDirectorDetails from '../../pages/Onboard/common.company.check-management';
import SelectOperationType from '../../pages/Onboard/common.operator.switcher';
import SelectDirector from '../../pages/Onboard/common.sole.select-directors';
import AddOperators from '../../pages/Onboard/common.sole.add-viewers';
import CheckOperators from '../../pages/Onboard/common.sole.check-viewers';
import CheckOtherOperators from '../../pages/Onboard/common.other.check-viewers';
import SoleCompleted from '../../pages/Onboard/common.sole.acc-completed';
import OtherCompleted from '../../pages/Onboard/common.other.acc-completed';
import SelectTransactionFlow from '../../pages/Onboard/common.operator.switcher.other.switcher';
import ApproverRoles from '../../pages/Onboard/common.other.approver-operators';
import NonApproverRoles from '../../pages/Onboard/common.other.non-approver-operators';
import PersonalAccountBusinessDetails from '../../pages/Onboard/common.personalAcc.business-name';
import ShareholderDetails from '../../pages/Onboard/common.company.enter-shareholders';
import CheckShareholderDetails from '../../pages/Onboard/common.company.check-shareholders';
import PersonalAccountCompleted from '../../pages/Onboard/common.personalAcc.acc-completed';
import PersonalAccountCreateVBank from '../../pages/Onboard/common.personalAcc.create-vbank';
import CorporateEnterDetails from '../../pages/Onboard/corporate-enter-details';
import CorporateOperatorSwitcher from '../../pages/Onboard/corporate-operator-switcher';
import CorporateOperator from '../../pages/Onboard/corporate-add-operator';
import CorporateLinkCompleted from '../../pages/Onboard/corporate-link-complete';
import CorporateCompleted from '../../pages/Onboard/corporate-acc-completed';
import { CorporateNewPassword } from '../../pages/Onboard/corporate-new-password';
import { CommonTypeOfBusiness } from '../../pages/Onboarding/steps/common.type.of.business';
import { CommonUploadDocuments } from '../../pages/Onboarding/steps/common.upload.documents';
import { CommonReferenceInfo } from '../../pages/Onboarding/steps/common.reference.info';
import { CommonApprovalLimit } from '../../pages/Onboarding/steps/common.approval.limit';
import CommonTrusteeInfo from '../../pages/Onboard/common.trustee.info';
import CommonManagementInfo from '../../pages/Onboard/common.management.info';
import Company from '../../pages/Onboard/common.company.info';
import { CommonPreviewDetails } from '../../pages/Onboarding/steps/common.preview.details';
import CommonProprietorsDetails from '../../pages/Onboard/common.proprietors.details';
import CommonSignupPersonalInformation from '../../pages/Onboard/common.signup.personal.info';
import CommonPhoneOtp from '../../pages/Onboard/common.otp.phone';
import CommonEmailOtp from '../../pages/Onboard/common.otp.email';
import CommonCompanyEnterDetails from '../../pages/Onboard/company.enter.details';
import LogIn from '../../pages/LogIn';
import { OnboardingTracker } from './components/onboardingTracker';
import { Text } from '../../components/Text';
import { useOnboarding } from '../../hooks/useOnboarding';
import CommonUbo from '../../pages/Onboard/common.ubo';
import VerifyBvn from '../../pages/Onboard/verify.bvn';
import VerifySignupBvn from '../../pages/Onboard/verify.signup.bvn';
import ReactApexChart from 'react-apexcharts';
import { AddBusiness } from '../../pages/AddBusiness';

const Onboarding: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data, tracker, label } = useOnboarding();

  const [image, setImage] = React.useState('');
  const [routeImage] = React.useState<{ [key: string]: string }>({
    '/onboarding': '/images/onboarding/2.jpg',
    '/onboarding/personal-information': '/images/onboarding/3.jpg',
    '/onboarding/signup/personal-information': '/images/onboarding/3.jpg',
    '/onboarding/account-number': '/images/onboarding/2.jpg',
    '/onboarding/otp': '/images/onboarding/2.jpg',
    '/onboarding/otp/phone': '/images/onboarding/2.jpg',
    '/onboarding/otp/email': '/images/onboarding/2.jpg',
    '/onboarding/credentials': '/images/onboarding/2.jpg',
    '/onboarding/proprietors-details': '/images/onboarding/4.jpg',
    '/onboarding/trustee-information': '/images/onboarding/4.jpg',
    '/onboarding/credentials/pin': '/images/onboarding/3.jpg',
    '/onboarding/credentials/confirm-pin': '/images/onboarding/3.jpg',
    '/onboarding/reference-information': '/images/onboarding/3.jpg',
    '/onboarding/upload-documents': '/images/onboarding/2.jpg',
    '/onboarding/business-type': '/images/onboarding/2.jpg',
    '/onboarding/preview-details': '/images/onboarding/3.jpg',
    '/onboarding/register-details': '/images/onboarding/4.jpg',
    '/onboarding/company-details': '/images/onboarding/3.jpg',
    '/onboarding/approval-limit': '/images/onboarding/3.jpg',
    '/onboarding/director-details': '/images/onboarding/3.jpg',
    '/onboarding/operators': '/images/onboarding/3.jpg',
    '/onboarding/directors': '/images/onboarding/3.jpg',
    '/onboarding/type-of-business': '/images/onboarding/2.jpg',
    '/onboarding/add-operators': '/images/onboarding/3.jpg',
    '/onboarding/check-operators': '/images/onboarding/3.jpg',
    '/onboarding/sole/completed': '/images/onboarding/3.jpg',
    '/onboarding/transaction-flow': '/images/onboarding/3.jpg',
    '/onboarding/role': '/images/onboarding/3.jpg',
    '/onboarding/other/completed': '/images/onboarding/3.jpg',
    '/onboarding/non-approval-role': '/images/onboarding/3.jpg',
    '/onboarding/personal/business-details': '/images/onboarding/4.jpg',
    '/onboarding/confirm-director-details': '/images/onboarding/4.jpg',
    '/onboarding/shareholder-details': '/images/onboarding/4.jpg',
    '/onboarding/confirm-shareholder-details': '/images/onboarding/4.jpg',
    '/onboarding/profile/completed': '/images/onboarding/5.jpg',
    '/onboarding/v-bank/create': '/images/onboarding/5.jpg',
    '/onboarding/create-password': '/images/onboarding/2.jpg',
    '/onboarding/management-details': '/images/onboarding/4.jpg',
    '/onboarding/company-information': '/images/onboarding/4.jpg',
  });

  useEffect(() => {
    setImage(routeImage[location.pathname]);
  }, [location.pathname]);

  const [donutSeries, setDonutSeries] = useState<Array<number>>([0, 100]);

  useEffect(() => {
    if (tracker.length) {
      const newTracker = tracker.filter((track) => track.key !== 'preview');
      const filteredDoneTracks = newTracker.filter((track) => track.done);
      const doneTrackerIndex = filteredDoneTracks.length - 1;
      if (doneTrackerIndex >= 0) {
        const percentageDivision = 100 / newTracker.length;
        const multiplier = doneTrackerIndex + 1;
        const currentPercentage = percentageDivision * multiplier;
        setDonutSeries([currentPercentage, 100 - currentPercentage]);
      }
    }
  }, [tracker]);

  const onboarding = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack
  );

  const handleBack = () => {
    history.goBack();
  };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: [colors.pink._100, colors.pink._10],
    dataLabels: { enabled: false },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: { show: false },
  };

  return (
    <>
      <TwoSide.Wrapper inApp={onboarding.inApp}>
        {!onboarding.inApp && (
          <TwoSide.LeftOnboardContainer>
            <div style={{ marginBottom: 40 }}>{label}</div>
            <OnboardingTracker
              trackerItems={tracker.slice(data.start, data.end)}
            />
          </TwoSide.LeftOnboardContainer>
        )}
        <TwoSide.Right inApp={onboarding.inApp}>
          <TwoSide.Donut>
            <ReactApexChart
              series={donutSeries}
              options={optionDonut}
              height='100px'
              width='100px'
              type='donut'
              className='apex-donut-two'
            />
          </TwoSide.Donut>
          <S.BackContainer inApp={onboarding.inApp}>
            <S.Back onClick={handleBack}>
              <Icon name='arrow-left' color={colors.pink._100} />
            </S.Back>
          </S.BackContainer>
          <Columns.Wrapper>
            <Columns.ContentWrapper />
            <Columns.ContentWrapper>
              {/*
               * Rendering the warning modal if a step got an API error
               */}
              {!!onboarding.error && (
                <WarningModal
                  title='Something went wrong!'
                  description={onboarding.error}
                  showModal={!!onboarding.error}
                  onCloseModal={() => {
                    dispatch(OnboardingActions.handleSetError(''));
                  }}
                />
              )}

              {/*
               * Rendering a step component
               */}

              {/* <Route path="/onboarding" exact>
                <VbankAccountSwitcher />
              </Route> */}

              <Route path='/' exact>
                {/* <OnboardingMethod /> */}
                <LogIn />
              </Route>

              <Route path='/onboarding/add-business' exact>
                {/* <OnboardingMethod /> */}
                <AddBusiness />
              </Route>

              <Route path='/onboarding/account-number' exact>
                <EnterAccountNumber />
              </Route>

              <Route path='/onboarding/otp' exact>
                <Otp />
              </Route>

              <Route path='/onboarding/credentials' exact>
                <Credentials />
              </Route>

              <Route path='/onboarding/credentials/pin' exact>
                <CreatePin />
              </Route>

              <Route path='/onboarding/credentials/confirm-pin' exact>
                <ConfirmPin />
              </Route>

              <Route path='/onboarding/business-type' exact>
                <BusinessType />
              </Route>

              <Route path='/onboarding/company-details' exact>
                <CommonCompanyEnterDetails />
              </Route>

              <Route path='/onboarding/register-details' exact>
                <RegisterDetails />
              </Route>

              <Route path='/onboarding/director-details' exact>
                <DirectorDetails />
              </Route>

              <Route path='/onboarding/confirm-director-details' exact>
                <CheckDirectorDetails />
              </Route>

              <Route path='/onboarding/operators' exact>
                <SelectOperationType />
              </Route>
              <Route path='/onboarding/directors' exact>
                <SelectDirector />
              </Route>

              <Route path='/onboarding/add-operators' exact>
                <AddOperators />
              </Route>

              <Route path='/onboarding/check-operators' exact>
                <CheckOperators />
              </Route>

              <Route path='/onboarding/check-other-operators' exact>
                <CheckOtherOperators />
              </Route>

              <Route path='/onboarding/sole/completed' exact>
                <SoleCompleted />
              </Route>

              <Route path='/onboarding/other/completed' exact>
                <OtherCompleted />
              </Route>

              <Route path='/onboarding/profile/completed' exact>
                <PersonalAccountCompleted />
              </Route>

              <Route path='/onboarding/transaction-flow' exact>
                <SelectTransactionFlow />
              </Route>

              <Route path='/onboarding/role' exact>
                <ApproverRoles />
              </Route>

              <Route path='/onboarding/non-approval-role' exact>
                <NonApproverRoles />
              </Route>

              <Route path='/onboarding/personal/business-details' exact>
                <PersonalAccountBusinessDetails />
              </Route>

              <Route path='/onboarding/shareholder-details' exact>
                <ShareholderDetails />
              </Route>

              <Route path='/onboarding/confirm-shareholder-details' exact>
                <CheckShareholderDetails />
              </Route>

              <Route path='/onboarding/v-bank/create' exact>
                <PersonalAccountCreateVBank />
              </Route>

              <Route path='/onboarding/corporate/create' exact>
                <CorporateEnterDetails />
              </Route>

              <Route path='/onboarding/corporate/select-operator' exact>
                <CorporateOperatorSwitcher />
              </Route>

              <Route path='/onboarding/corporate/add-operator' exact>
                <CorporateOperator />
              </Route>

              <Route path='/onboarding/corporate/link-complete' exact>
                <CorporateLinkCompleted />
              </Route>

              <Route path='/onboarding/corporate/new-password' exact>
                <CorporateNewPassword />
              </Route>

              <Route path='/onboarding/corporate/complete' exact>
                <CorporateCompleted />
              </Route>

              {/* New Routes for Vbiz_2.0 go here */}

              <Route path='/onboarding/otp/phone' exact>
                <CommonPhoneOtp />
              </Route>

              <Route path='/onboarding/otp/email' exact>
                <CommonEmailOtp />
              </Route>

              <Route path='/onboarding/upload-documents' exact>
                <CommonUploadDocuments />
              </Route>
              <Route path='/onboarding/type-of-business' exact>
                <CommonTypeOfBusiness />
              </Route>

              <Route path='/onboarding/personal-information' exact>
                <PersonalInformation />
              </Route>

              <Route path='/onboarding/signup/personal-information' exact>
                <CommonSignupPersonalInformation />
              </Route>

              <Route path='/onboarding/reference-information' exact>
                <CommonReferenceInfo />
              </Route>
              <Route path='/onboarding/approval-limit' exact>
                <CommonApprovalLimit />
              </Route>

              <Route path='/onboarding/trustee-information' exact>
                <CommonTrusteeInfo />
              </Route>

              <Route path='/onboarding/ubo' exact>
                <CommonUbo />
              </Route>

              <Route path='/onboarding/management-details' exact>
                <CommonManagementInfo />
              </Route>

              <Route path='/onboarding/create-password' exact>
                <CreatePassword />
              </Route>

              <Route path='/onboarding/company-information' exact>
                <Company />
              </Route>

              <Route path='/onboarding/proprietors-details' exact>
                <CommonProprietorsDetails />
              </Route>

              <Route path='/onboarding/verify-bvn' exact>
                <VerifyBvn />
              </Route>

              <Route path='/onboarding/signup/verify-bvn' exact>
                <VerifySignupBvn />
              </Route>

              <Route path='/onboarding/preview-details' exact>
                <CommonPreviewDetails />
              </Route>

              {/* <Redirect from='**' to='/' exact /> */}
              {/* <Redirect from='*' to='/onboarding' exact /> */}
            </Columns.ContentWrapper>
            <Columns.ContentWrapper />
          </Columns.Wrapper>
        </TwoSide.Right>
      </TwoSide.Wrapper>
    </>
  );
};

export default Onboarding;
