import styled from 'styled-components';
import { ptr } from '../../../styles/utils';

const Container = styled.div`
  > button {
    width: 100%
  }
`;

const TextFieldWrapper = styled.div`
  margin: ${ptr(32)} 0;
`;

export { Container, TextFieldWrapper };
