import styled from 'styled-components';

import { ptr } from '../../../../styles/utils';

const ItemContainer = styled.div``;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`;

export {
  ItemContainer,
  ItemRow,
  HeaderRow,
  Remove,
};
