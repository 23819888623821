import styled from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../../styles/utils';
import { colors, transition } from '../../../../styles/variables';

const ItemContainer = styled.div`
 
`;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  flex-wrap: wrap;
  gap: ${ptr(12)};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
 
`;

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`;

const ContainerB = styled.div`
margin-top:-20px;
  padding: 10px 10px 10px 20px; 
  width:50vw;
`;

const InnerItem = styled.div`
width:100%;
display:flex; 
margin-bottom: ${ptr(-12)};
 
gap:10px;
`;

const FileOuter = styled.div`
  border: 1px solid none;
  padding: 12px;
  width: 216px;
  display: flex;
 
  justify-content: space-around;
  flex-direction: column;
`;

const File = styled.div`
  background-color: ${colors.black._40};
  border: ${ptr(1)} solid ${colors.black._5};
  box-sizing: border-box;
  border-radius: ${ptr(4)};
  width: ${ptr(192)};
  height: ${ptr(235)};
  // margin-bottom: ${ptr(82)};
  background-size: cover;
 position: relative;

  // > span {
  //   position: absolute;
  //   margin-top: ${ptr(140)};
  // }
`;

const FileTexts = styled.div`
margin-top:12px;
`;

const FullScreen = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  width: ${ptr(192)};

  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  justify-content: space-between;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(18)}; 
  height: ${ptr(18)};
  border-radius: ${ptr(12)};
 border: ${ptr(1)} #B10180 solid;
 

  
 ${crossBrowserTransition(`${transition.slow}`)}
  
`;
const InsideCircle = styled.div`
display: flex;
justify-content: center;
align-items: center;
  width: ${ptr(10)};
  height: ${ptr(10)};
  border-radius: ${ptr(9)};
background-color: #B10180
 
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;


export {
  ItemContainer,
  ItemRow,
  HeaderRow,
  Remove,
  ContainerB, InnerItem, FileOuter, File, FileTexts, FullScreen, Circle, InsideCircle, ButtonGroup
};
