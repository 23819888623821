import EmptyBox from '../../assets/images/empty_box.svg';
import { Text } from '../Text';

const EmptyState = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        height: '295px',
      }}
    >
      <img src={EmptyBox} />
      <p
        style={{
          marginBottom: 0,
          fontWeight: 700,
          fontSize: 18,
          color: '#394455',
        }}
      >
        No records
      </p>
      <Text
        style={{
          marginBottom: 0,
          fontWeight: 500,
          color: '#6E7191',
          textAlign: 'center',
          width: 200,
          marginTop: 10,
          fontSize: 14,
        }}
      >
        You currently don’t have any transactions records
      </Text>
    </div>
  );
};

export default EmptyState;
