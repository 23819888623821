import styled from "styled-components";
import { WelcomeSliderProps } from "./index";
import { ptr } from "../../../../styles/utils";
import { colors, breakpoints } from "../../../../styles/variables";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  /* //width: 520px; */
  // width: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #eaecf0;
  background-image: url("/images/banner/slide_one.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Bg shadown loocue */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  position: relative;
  min-height: 228px;
`;

const Container1 = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
  // width: 520px;
  // width: 100%;
  border-radius: 5px;
  border: 1px solid #eaecf0;
  background-image: url("/images/banner/slide_two.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  /* Bg shadown loocue */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  height: 228px;
`;

const Bars = styled.div`
  position: absolute;
  border-radius: 5px;
  right: 0;
`;

const BgImg = styled.div`
  position: absolute;
  border-radius: 5px;
  right: 0;
  bottom: 0px;
  margin-left: 300px;
`;

const ContainerLhs = styled.div`
  display: flex;
  gap: 8px;
  max-width: 322px;
  flex-direction: column;
  padding: 26px 21px 21px 22px;
`;

const ContainerRhs = styled.div``;

const WelcomeWrapper = styled.div<WelcomeSliderProps>`
  // width: 500px;
  // max-width: 426px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.7px;
  border-radius: 5px;
  border: 1px solid #eaecf0;
  background: var(--success-light, #f2fffb);
  //   /* Bg shadown loocue */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);

  height: 229px;
  @media (max-width: ${breakpoints.xl}px) {
    min-width: 400px;
    //   width: 100%;
  }

  @media (max-width: ${breakpoints.md}px) {
    min-width: 400px;
    //min-width: 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    min-width: 82dvw;
    //min-width: 100%;
  }
`;

const BgContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::before {
    background-image: url("../../images/onboarding/spiral.svg");

    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
  }
`;
const SlideWrapper = styled.div`
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center;
`;

const Block = styled.div`
  min-width: ${ptr(40)};
  height: ${ptr(40)};
  // background: ${colors.pink._10};
  border-radius: ${ptr(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${ptr(12)};

`;

const ImgContainer = styled.div`
  width: 100%;
  > .slick-dots li.slick-active button:before {
    /* color: var(--blue-900); */
    color: green;
    margin-top: -290px;
    opacity: 1;
  }
`;

const Img = styled.img`
// display:flex;
// justify-content:right;
position:absolute;
right:0px

  // width: ${ptr(400)};
  // height: ${ptr(400)};
`;

const Wrapper = styled.div`
  // background: ${colors.black._2};
  // border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  flex: 1;
  padding: ${ptr(16)};
  margin: ${ptr(8)};
  min-width: 40%;
  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.6;
  }

  > a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

export {
  Block,
  Wrapper,
  Container,
  Container1,
  ContainerLhs,
  Bars,
  BgImg,
  ContainerRhs,
  WelcomeWrapper,
  ImgContainer,
  Img,
  BgContainer,
};
