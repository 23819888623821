import React from 'react';

import * as S from './styles';
import { Text } from '../Text';
import { colors } from '../../styles/variables';

export interface FormTitleProps {
  /**
   * Title (big text)
   */
  title?: string;
  /**
   * Description (small text)
   */
  margin?: number;
  description?: string;
  titleColor?: string;
  descriptionColor?: string;
}

export const FormTitle: React.FC<FormTitleProps> = ({
  title = '',
  description = '',
  ...props
}) => (
  <S.Wrapper style={{ marginBottom: props.margin || 40 }}>
    <S.TitleWrapper>
      <Text color={props.titleColor || colors.pink._100} size={2} bold>
        {title}
      </Text>
    </S.TitleWrapper>
    <S.DescriptionWrapper>
      <Text color={props.descriptionColor || colors.black._100}>
        {description}
      </Text>
    </S.DescriptionWrapper>
  </S.Wrapper>
);
