import React from 'react';
import { AccountBalance } from './components/AccountBalance';
import { TotalResults } from './components/TotalResults';

// import * as S from './styles';
// import { IncomeExpences } from './components/IncomeExpences';

export interface AnalyticsProps {
  /**
   * Example page property
   */
  example?: string,
}

export const Analytics: React.FC<AnalyticsProps> = () => (
  <>
    <AccountBalance />
    <TotalResults />
    {/* <IncomeExpences /> */}
  </>
);
