import React from 'react';
import * as S from './styles'; 
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables'; 
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';

interface IDelete {
    onClick?: any;
    onCancel?: () => void,
  }

export const DeletePopup: React.FC<IDelete> = ({onClick, onCancel}) => {
  return (
    <S.Container>
          <S.TopSection>
          <Text bold color={colors.black._100}>Delete file</Text>
          <Icon onClick={onCancel} color='black' name="cross" />
          </S.TopSection>
          
          <S.MainBody>
            <S.Icon>
               <Icon width={55} height={55} name="trash-can" />
            </S.Icon>
          <span>
            <Text color={colors.black._100}>Are you sure you want to delete this file ?</Text>
          </span>
          
          <S.ActionButtons> 
            <div style={{border: "1px solid #2C2A3B", borderRadius:"6px"}}>
          <Button 
          backgroundColor={colors.white._100} 
          color="#2C2A3B"
            label="CANCEL"
            onClick={onCancel}
          /></div>
          <Button
          backgroundColor={colors.orange._90} 
            label="DELETE"
            onClick={onClick}
          />
        </S.ActionButtons>
          </S.MainBody>
    </S.Container>
  );
};
