import React from 'react';

import * as S from './styles';
import { Text } from '../Text';
import { CircleDecorators } from '../CircleDecorator';
import { colors } from '../../styles/variables';
import { Icon } from '../Icon';
import { Currency, getCurrencySign } from '../../utils/currency';
import { getNumberWithSpaces } from '../../utils/strings';

export interface AccountCardProps {
  /**
   * Account balance
   */
  balance: number;
  /**
   * Account number
   */
  number: string;
  /**
   * Currency
   */
  currency: Currency;
  /**
   * Disable card
   */
  disabled?: boolean;
  /**
   * Click action
   */
  onClick?: () => void;
}

export interface AccountCardCheckedProps {
  /**
   * Is card checked
   */
  checked?: boolean;
  /**
   * Disable card
   */
  disabled?: boolean;
  /**
   * Click action
   */
  onClick?: () => void;
}

const AccountCardComponent: React.FC<AccountCardProps & AccountCardCheckedProps> = ({
  checked = false,
  ...props
}) => (
  <S.Wrapper
    checked={checked}
    disabled={props.disabled}
    onClick={!props.disabled ? props.onClick : undefined}
  >
    <CircleDecorators
      circles={props.number}
    >
      <S.ContentWrapper>
        <S.HeaderWrapper>
          <Text color={colors.white._100} size={7}>Balance</Text>
          <S.CheckBox checked={checked}>
            {checked && <Icon name="check-mark" color={colors.pink._100} />}
          </S.CheckBox>
        </S.HeaderWrapper>
        <S.BalanceWrapper>
          <Text color={colors.white._100} size={3} bold>
            {`${getCurrencySign(props.currency)} ${getNumberWithSpaces(props.balance)}`}
          </Text>
        </S.BalanceWrapper>
        <S.AccountNumberWrapper>
          <Text color={colors.white._100} size={7}>Account No: </Text>
          <Text color={colors.white._100} size={7} bold>{props.number}</Text>
        </S.AccountNumberWrapper>
      </S.ContentWrapper>
    </CircleDecorators>
  </S.Wrapper>
);

export const AccountCard = React.memo(AccountCardComponent);
