import styled from "styled-components";
// import { colors, transition } from '../styles/variables';
// import { crossBrowserTransition, ptr } from '../../styles/utils';
import { ptr } from "../../styles/utils";
import { fonts, colors, breakpoints } from "../../styles/variables";

// const Container = styled.div<ModalWrapperProps>`
//   position: fixed;
//   z-index: 10000;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: rgba(0,0,0,0.3);
//   align-items: center;
//   justify-content: center;
//   ${crossBrowserTransition(transition.slow)}
//   ${(props) => css`
//       display: ${props.showModal ? 'flex' : 'none'};
//     `
// }
// `;
// const Wrapper = styled.div<ModalWrapperProps>`
//   background-color: ${colors.white._100};
//   border-radius: ${ptr(12)};
//   position: relative;

//   ${(props) => props.maxWidth && css`
//     max-width: ${props.maxWidth ? props.maxWidth : '90%'};
//   `}
//   ${(props) => props.overflowY && css`
//     overflow-y: ${props.overflowY || 'scroll'};
//   `}

//   overflow: hidden;

//   ${(props) => props.maxHeight && css`
//     max-height: ${props.maxHeight};
//   `}

//   ${(props) => props.overflowY && css`
//     overflow-y: ${props.overflowY || 'scroll'};
//   `}

//   ${(props) => props.overflowX && css`
//     overflow-x: ${props.overflowX || 'scroll'};
//   `}
//   ::-webkit-scrollbar {
//   display: none;
// }
//  -ms-overflow-style: none;
//   scrollbar-width: none;
// `;
// const CrossWrapper = styled.div`
//   position: absolute;
//   right: ${ptr(30)};
//   top: ${ptr(30)};
//   cursor: pointer;
//   z-index: 2;
// `;
// export {
//   Container,
//   Wrapper,
//   CrossWrapper,
// };
const Main = styled.div``;
const Container = styled.div`
  width: 600px;
  margin: ${ptr(10)} ${ptr(0)};
  text-align: center;
  padding: ${ptr(20)};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Align = styled.div`
  text-align: right;
`;
const Padding = styled.div`
  padding: ${ptr(0)} ${ptr(70)};

  @media (max-width: ${breakpoints.md}px) {
    padding: ${ptr(0)};
  }
`;

const ImageWrapper = styled.div`
  margin: ${ptr(30)} ${ptr(0)};
`;

const Margin = styled.div`
  margin-bottom: ${ptr(20)};
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 35px;
  button {
    width: 100%;
  }
`;

const List = styled.ol`
text-align: start;
margin auto;
list-style-type: decimal;
font-family: ${fonts.inter.regular};
line-height: ${ptr(32)};
padding: ${ptr(16)};
font-size: 12px;
color:#000000
}
`;

export {
  Main,
  Container,
  Wrapper,
  Align,
  Padding,
  ImageWrapper,
  Margin,
  Button,
  List,
};
