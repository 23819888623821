import styled from 'styled-components';

import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const Title = styled.div`
  color: ${colors.white._100};
  font-weight: bold;
  font-size: ${ptr(20)};
  line-height: 140%;
  margin-top: ${ptr(16)};
`;

const Description = styled.div`
  color: ${colors.white._100};
  font-size: ${ptr(16)};
  line-height: 140%;
`;

const Img = styled.img`
  width: ${ptr(48)};
  padding: ${ptr(16)};
`;

const ToastWrapper = styled.div`
  display: flex;
`;

export {
  Title,
  Description,
  Img,
  ToastWrapper,
};
