import styled from "styled-components";
import { fonts } from '../../styles/variables';
import { colors } from "../../styles/variables";
colors


const SectorContainer = styled.div`
  width:650px;
  font-family: ${fonts.inter.regular};
  padding:0 20px;
  position:relative;
`

const InfoContainer = styled.div`
margin: 0px 0 10px 0;
`

const Items = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`

const Item = styled.div`
border-radius: 8px;
border: 0.5px solid  #EAECF0;
background: #FFF;
height: 48px;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 15px 0 15px;
width:25%;
margin-bottom: 15px;
margin-right:15px;
cursor: pointer;
position: relative;

`

const DropDown = styled.div`
  position:absolute;
  width: 300px;
  min-height:300px;
  background-color: ${colors.white._100};
  border-radius: 7px;
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 8px 24px -6px rgba(0, 0, 0, 0.16);
padding: 0 10px;
z-index: 1000;
top:60px;
right:0;
left:0;
`

const DropdownItem = styled.div`
background: #FFF;
height: 40px;
display: flex;
justify-content: space-between;
align-items:center;
cursor: pointer;
`

const MultiInput = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
border: 1px solid ${colors.black._1};
background:#FFF;
padding: 0 15px;
cursor: pointer;
`


export { SectorContainer, InfoContainer, Items, Item, DropDown, DropdownItem, MultiInput };
