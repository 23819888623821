import React from 'react';
import { useDispatch } from 'react-redux';

import * as S from './styles';
import { Logo } from '../Logo';
import { Link } from '../Link';
import { MainMenuActions } from '../../redux/main-menu/actions';
import { MiniProfile } from '../MiniProfile';
import { Icon } from '../Icon';

export const HeaderMenu: React.FC<{ fixed: boolean }> = ({ fixed = false }) => {
  const dispatch = useDispatch();

  const onMenuButtonClick = () => {
    dispatch(MainMenuActions.toggle());
  };

  return (
    <S.Wrapper fixed={fixed}>
      <Icon name='v_logo' />
    </S.Wrapper>
  );
};
