import styled, { css } from 'styled-components';

import { TextInputProps, IconCircleProps } from '.';
import { fonts, colors, transition } from '../../styles/variables';
import { crossBrowserBoxShadow, crossBrowserTransition, ptr } from '../../styles/utils';

const Wrapper = styled.div<TextInputProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: ${ptr(6)};
  background-color: ${colors.black._5};
  box-sizing: border-box;
  position: relative;
  min-width:fit-content;
  
  ${(props) => props.invalid && css`
    ${crossBrowserBoxShadow(`inset 0 0 0 ${ptr(1)} ${colors.red._10}`)}
  `}

  ${(props) => props.disabled && css`
    background-color: white;

  `}
`;

const StyledInput = styled.input<TextInputProps>`
  width: 100%;

  font-family: ${fonts.inter.regular};
  line-height: ${ptr(24)};
  border: 0;
  padding: ${ptr(10)} ${ptr(15)} ${ptr(10)} ${ptr(15)};
  color: ${colors.black._100};
  display: block;
  box-sizing: border-box;
  background-color: ${colors.white._100};
  ${(props) => props.disabled && css`
    background-color: white;
  `}
  border-radius: 8px;
border: 1px solid #D9DBE9;
:focus {
  border-color: ${colors.blue._100}
}
 
::placeholder {
  color: ${colors.black._40};
font-size: 13px; 
font-weight: 400;
line-height: 22px;
}
::-ms-reveal, ::-ms-clear {
    display: none;
  }

${(props) => props.type === 'number' && css`
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`}

  ${crossBrowserTransition(transition.superSlow)}

  /* ${(props) => props.value && props.value.length > 0 && css`
    & ~ label {
      top: ${ptr(-8)};  
      line-height: 140%;
      
      ${crossBrowserTransition(transition.slow)}
      
      font-size: ${ptr(12)};
    }
  `} */

  /* :focus ~ label {
    top: ${ptr(-8)};  
    line-height: 140%;
    
    ${crossBrowserTransition(transition.slow)}
    
    font-size: ${ptr(12)};
  } */
`;

const Label = styled.label<TextInputProps>`
  pointer-events: none;
      white-space:nowrap;
  line-height: ${ptr(24)};
  font-family: ${fonts.inter.regular};
  color: ${colors.black._100};
  
  ${crossBrowserTransition(transition.slow)}
  ${(props) => props.invalid && css`
    color: ${colors.red._100};
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  background-color: ${colors.white._100};
  border-radius: 0 ${ptr(6)} ${ptr(6)} 0;
 
  align-items: center;
  background-color: transparent;  
  position:absolute;
  right: ${ptr(20)};   
height:100%;
`;

const RightBox = styled.div`
  display: flex;
  padding-right: ${ptr(20)};
  align-items: center;
  cursor: pointer;
  background-color: transparent;  
  
`;

const IconCircleWrapper = styled.div<IconCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ptr(24)};
  height: ${ptr(24)};
  border-radius: 50%;
  

  ${(props) => css`
    background-color: ${props.color};
  `}

  ${(props) => props.clickable && css`
    cursor: pointer;
    
    :hover {
      opacity: 0.6;
    }
  `}
`;

const InvalidText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(12)};
  color: ${colors.red._100};
  line-height: 140%;
  margin-right: ${ptr(8)};
  max-width: ${ptr(150)};
  min-width: ${ptr(90)};
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ValidText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.inter.regular};
  font-size: ${ptr(12)};
  color: ${colors.black._60};
  line-height: 140%;
  margin-right: ${ptr(8)};
  max-width: ${ptr(240)};
  min-width: fit-content;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SymbolText = styled.span`
  color: ${colors.black._60};
`;

const Loading = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${ptr(16)};
`;

export {
  Wrapper,
  RightBox,
  StyledInput,
  Label,
  IconWrapper,
  ValidText,
  IconCircleWrapper,
  InvalidText,
  SymbolText,
  Loading,
};
