import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { LeaderDirectorShareholderInterface } from '../../../services/internal/onboarding/IOnboarding';
import debug from '../../../services/internal/debbug.service';

import { Title } from '../../Onboarding/components/Title';
import { InfoSection } from '../../Onboarding/components/InfoSection';

import * as S from './styles';
import { getSignatureFormData } from '../../Onboarding/steps/common.api';
import { errorToString } from '../../../services/internal/app/index';
import { Preload } from '../../../components/Preload';
import { actions } from '../config';
import { OperatorsModal } from '../../../components/OperatorsModal';
import { RootState } from '../../../redux';
import { CompanyInterface } from '../../../redux/company/ICompany';
import {
  setOperatorsApproverRoute,
  setOperatorsNonApproverRoute,
  setOperatorsRoute,
} from '../../../routing/setOperators';
import { colors } from '../../../styles/variables';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Icon } from '../../../components/Icon';
import { InfoConponent } from '../../../components/Info';

export interface IOperatorProps {
  closeOperator?: (data?: { success: true }) => void;
  handleContinue?: (data: {
    workflowSelected?: string;
    actionRequired?: string;
  }) => void;
}
const CommonOperatorSwitcher: React.FC<IOperatorProps> = ({ ...props }) => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();
  const location = useLocation();

  /**
   * Redux store initiation region
   */

  const workflow = useSelector((store: RootState) => store.preference.workflow);

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const savedWorkflow = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.workflow as any
  );

  const workflowSet = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.workflowSet as boolean
  );

  const directors = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as string
  );

  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(() =>
    savedWorkflow === null && workflowSet ? true : false
  );


  const [isCheckedb, setIsCheckedb] = React.useState(() =>
    savedWorkflow &&
    (savedWorkflow === workflow.initToAuth ||
      savedWorkflow === workflow.initVerAuth)
      ? true
      : false
  );

  const [isOperatorA, setOperateorA] = React.useState(() =>
    savedWorkflow === workflow.initToAuth ? true : false
  );
  const [isOperatorB, setOperateorB] = React.useState(() =>
    savedWorkflow === workflow.initVerAuth ? true : false
  );

  /**
   * Custom handlers initiation region
   */
  const sole = async () => {
    // dispatch(OnboardingActions.handleNextStack(
    // onboardingStacksConfig['common.operator.switcher.sole']));
    //route.push('/onboarding/directors');
    setLoading(true);
    try {
      let response: any;
      if (location.pathname === setOperatorsRoute || props.handleContinue) {
        response = await onboardingService.submitBusinessOperators({
          operators: [],
          workflow: null,
        });
        //enable reload and trigger state change
        if (props.handleContinue) {
          if (props.closeOperator) {
            props.closeOperator({ success: true });
          }
        } else {
          window.location.href = '/dashboard';
        }
      } else {
        response = await onboardingService.submitBusinessOperatorsInfo({
          operators: [],
          workflow: null,
          reference,
        });
        batch(() => {
          dispatch(
            OnboardingActions.handleSetData({
              workflow: null,
              reference: response.data.reference,
              workflowSet: true,
            })
          );
        });
        route.push(actions[response.actionRequired]);
      }
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  const other = (workflowSelected: string) => {
    const onboardingRoute =
      workflowSelected === workflow.initToAuth
        ? '/onboarding/non-approval-role'
        : '/onboarding/role';

    const setOperatorsSelectedRoute =
      workflowSelected === workflow.initToAuth
        ? setOperatorsNonApproverRoute
        : setOperatorsApproverRoute;

    const selectedRoute =
      location.pathname === setOperatorsRoute
        ? setOperatorsSelectedRoute
        : onboardingRoute;
    if (props.handleContinue) {
      props.handleContinue({ workflowSelected: selectedRoute });
    } else {
      route.push(selectedRoute);
    }
    // batch(() => {
    //   dispatch(
    //     OnboardingActions.handleSetData({
    //       reference: response.data.reference,
    //     })
    //   );
    // dispatch(OnboardingActions.handleNextStack
    // (onboardingStacksConfig['common.operator.switcher.other']));
  };

  function handleContinue() {
    if (isChecked === true) {
      sole();
    } else {
      if (isOperatorA) {
        other(workflow.initToAuth);
      } else if (isOperatorB) {
        other(workflow.initVerAuth);
      }
    }
  }

  return (
    <S.Container>
      <OperatorsModal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      >
        <S.ModalChild>
          <div onClick={() => other(workflow.initToAuth)}>
            <p>Initiator - Authoriser</p>
          </div>
          <div onClick={() => other(workflow.initVerAuth)}>
            <p>Initiator - Verifier - Authoriser</p>
          </div>
        </S.ModalChild>
      </OperatorsModal>

      <div style={{ marginBottom: '32px' }}>
        <Title
          color={colors.black._20}
          title='Select workflow'
          subTitle='Choose how funds will leave your account.'
        />
      </div>
      {loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      ) : (
        <OnboardingCard>
          <S.Wrapper>
            <S.WrapperItemsOne>
              <S.WrapperItemsInner
                style={
                  isChecked
                    ? {
                        background: '#F7F7FC',
                        padding: '12px 8px',
                        borderRadius: '8px',
                      }
                    : {}
                }
              >
                <S.Lhs>
                  <Icon name='single-profile' color='#B10180' />
                  <div>
                    <Text bold color={colors.black._20}>
                      Single operator
                    </Text>
                    <Text color={colors.black._20}>
                      One person will initiate and authorize transactions
                    </Text>
                  </div>
                </S.Lhs>

                <S.Rhs>
                  <S.Circle
                    onClick={() => {
                      setIsCheckedb(false);
                      setIsChecked(!isChecked);
                    }}
                  >
                    {isChecked && <S.InsideCircle />}
                  </S.Circle>
                </S.Rhs>
              </S.WrapperItemsInner>
              <div style={{ marginTop: '16px' }}>
                {isChecked && (
                  <InfoConponent
                    style={{ background: '#FFF9EF' }}
                    text='As a single operator, one person will initiate and authorize transactions.'
                  />
                )}
              </div>
            </S.WrapperItemsOne>

            <S.WrapperItems>
              <S.WrapperItemsInner
                style={
                  isCheckedb
                    ? {
                        background: '#F7F7FC',
                        padding: '12px 8px',
                        borderRadius: '8px',
                      }
                    : {}
                }
              >
                <S.Lhs>
                  <Icon name='multiple-profile' color='#B10180' />
                  <div>
                    <Text bold color={colors.black._20}>
                      Multiple operators
                    </Text>
                    <Text color={colors.black._20}>
                      Other users will have other roles either as initiator or
                      verifier or authorizer
                    </Text>
                  </div>
                </S.Lhs>

                <S.Rhs>
                  <S.Circle
                    onClick={() => {
                      setIsChecked(false);
                      setIsCheckedb(!isCheckedb);
                    }}
                  >
                    {isCheckedb && <S.InsideCircle />}
                  </S.Circle>
                </S.Rhs>
              </S.WrapperItemsInner>

              <div
                style={{
                  marginTop: '16px',
                  width: '100%',
                }}
              >
                {isCheckedb && (
                  <S.PreferredOperator>
                    <Text color='#6E7191'>Select preferred operator flow</Text>
                    <S.PreferredOperatorItemDiv>
                      <S.PreferredOperatorItem
                        style={
                          isOperatorA
                            ? { border: '1px solid #B10180' }
                            : { border: '1px solid #EAECF0' }
                        }
                      >
                        <S.Circle
                          onClick={() => {
                            setOperateorB(false);
                            setOperateorA(!isOperatorA);
                          }}
                        >
                          {isOperatorA && <S.InsideCircle />}
                        </S.Circle>

                        <Text
                          size={6}
                          color={isOperatorA ? '#B10180' : colors.black._20}
                        >
                          Initiator - Authorizer
                        </Text>
                      </S.PreferredOperatorItem>

                      <S.PreferredOperatorItem
                        style={
                          isOperatorB
                            ? { border: '1px solid #B10180' }
                            : { border: '1px solid #EAECF0' }
                        }
                      >
                        <S.Circle
                          onClick={() => {
                            setOperateorA(false);
                            setOperateorB(!isOperatorB);
                          }}
                        >
                          {isOperatorB && <S.InsideCircle />}
                        </S.Circle>

                        <Text
                          size={6}
                          color={isOperatorB ? '#B10180' : colors.black._20}
                        >
                          Initiator - Verifier - Authorizer
                        </Text>
                      </S.PreferredOperatorItem>
                    </S.PreferredOperatorItemDiv>
                  </S.PreferredOperator>
                )}
              </div>
            </S.WrapperItems>
          </S.Wrapper>

          <S.ButtonGroup>
            <div>
              <Button
                onClick={handleContinue}
                disabled={
                  isChecked === false && isCheckedb === false ? true : false
                }
                style={{ width: '304px', background: '#B10180' }}
                loading={loading}
                label='Continue'
              />
            </div>
          </S.ButtonGroup>
        </OnboardingCard>
      )}
    </S.Container>
  );
};
export default CommonOperatorSwitcher;
