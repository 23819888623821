import React, { CSSProperties } from 'react';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';

import * as S from './styles';

export interface InfoSectionProps {
  title: string;
  style?: CSSProperties;
  subTitle?: string;
  onClick?: () => void;
  onAdditionalButtonClick?: () => void;
  buttonLabel?: string;
  additionalButton?: boolean;
}

export const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  style,
  subTitle,
  onClick = () => {},
  buttonLabel,
  additionalButton = false,
  onAdditionalButtonClick = () => {},
}) => (
  <S.Wrapper
    onClick={() => (additionalButton ? null : onClick())}
    additionalButton={additionalButton}
  >
    <S.Container onClick={onClick}>
      <S.RootContent>
        <div style={style}>
          <Text size={5} color={colors.black._100}>
            {title}
          </Text>
        </div>

        {subTitle && <Text color={colors.black._60}>{subTitle}</Text>}
      </S.RootContent>
      <S.ArrowBlock>
        <Icon name='vector' width={5} color={colors.black._100} />
      </S.ArrowBlock>
    </S.Container>
    {additionalButton && (
      <S.AdditionalButton>
        <Button
          onClick={onAdditionalButtonClick}
          backgroundColor='transparent'
          color={colors.pink._100}
          label={buttonLabel}
        />
      </S.AdditionalButton>
    )}
  </S.Wrapper>
);
