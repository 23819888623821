import React, { useEffect, useState } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import { colors } from '../../styles/variables';
import { Icon, IconProps } from '../Icon';
import * as S from './styles';
import {
  Details,
  getOnboardModalDetail,
} from '../../utils/onboardModalDetails';
import { useHistory } from 'react-router';
import { Button } from '../Button';
import { Link } from '../Link';
import { Text } from '../Text';
import { handleCamelcasing } from '../../utils/strings';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { OnboardingInterface } from '../../redux/onboarding/IOnboarding';

export interface SignupOptionModalProps {
  onCloseModal?: () => void;
  /**
   * Show modal
   */
  showModal?: boolean;
  crossColor?: string;
  overflowY?: string;
  overflowX?: string;
  margin?: string;
  maxHeight?: string | null;
  maxWidth?: string | null;
  onBottom?: () => void;
  closeBackground?: string;
  details?: Details | null;
  signupOption?: boolean;
}

export const SignupOptionModal: React.FC<SignupOptionModalProps> = ({
  showModal = false,
  crossColor = colors.black._100,
  overflowY = 'auto',
  overflowX = 'auto',
  maxHeight = null,
  maxWidth = '90%',
  onBottom = () => {},
  ...props
}) => {
  const history = useHistory();
  const preference = useSelector((store: RootState) => store.preference);

  const inApp = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.inApp as boolean
  );

  const [viewRequirement, setViewRequirement] = useState(false);
  const [requirementData, setRequirementData] = useState<Details | null>();
  const [requirementType, setRequirementType] = useState('');

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  const route = useHistory();

  const proceedRoute = props.details?.route ? props.details.route : '/';

  const onCloseModal = () => {
    setRequirementData(null);
    if (props.onCloseModal) props.onCloseModal();
  };

  const handleViewRequirement = (action: string) => {
    const data = getOnboardModalDetail(action, preference.bvnConsentEnabled);
    setRequirementData(data);
    setRequirementType(action);
  };

  const signup = (route?: string) => {
    if (route) history.push(route);
  };

  if (requirementData) {
    return (
      <BottomScrollListener onBottom={onBottom}>
        {(scrollRef: any) => (
          <S.Container showModal={showModal}>
            <S.Wrapper
              overflowY={overflowY}
              overflowX={overflowX}
              maxHeight={maxHeight}
              maxWidth={maxWidth}
              ref={scrollRef}
              style={{ padding: 35, width: 650 }}
            >
              <S.Header regularFont>
                <Link
                  icon='arrow-left'
                  theme='black'
                  text='Back'
                  onClick={() => setRequirementData(null)}
                />
              </S.Header>

              <S.InfoWrapper
                backgroundColor={
                  requirementType === 'createProfile' ? '#FFF9EF' : '#EEF7FF'
                }
              >
                <div style={{ flex: 1 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 15 }}>
                    {requirementData.title}
                  </p>
                  {requirementData.info.map((info, index) => (
                    <p key={index}>{info}</p>
                  ))}
                </div>
              </S.InfoWrapper>

              <S.MidTitle>
                Requirements to{' '}
                {handleCamelcasing(requirementType)?.toLowerCase()} on VBiz.
              </S.MidTitle>

              <S.ListWrapper>
                {requirementData?.list &&
                  requirementData.list.map((item, index) => (
                    <S.ListItem key={index}>{item}</S.ListItem>
                  ))}
              </S.ListWrapper>

              <S.BottomDiv justifyContent='flex-end'>
                <Button
                  onClick={() => signup(requirementData.route)}
                  label='Proceed to sign up'
                />
              </S.BottomDiv>
            </S.Wrapper>
          </S.Container>
        )}
      </BottomScrollListener>
    );
  }

  return (
    <BottomScrollListener onBottom={onBottom}>
      {(scrollRef: any) => (
        <S.Container showModal={showModal}>
          <S.Wrapper
            overflowY={overflowY}
            overflowX={overflowX}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            ref={scrollRef}
            style={{ padding: 35, width: 650 }}
          >
            <S.Header regularFont>
              <div>
                <S.HeaderTitle
                  style={{
                    fontSize: 32,
                    color: '#394455',
                    fontWeight: 700,
                  }}
                >
                  {props.details && props.details.title}
                </S.HeaderTitle>

                <div>
                  {props.details &&
                    props.details.info.map((info, index) => (
                      <p
                        style={{
                          color: '#6E7191',
                          fontSize: 16,
                          fontWeight: 400,
                          lineHeight: '24px',
                          marginTop: 10,
                        }}
                        key={index}
                      >
                        {info}
                      </p>
                    ))}
                </div>
              </div>
              {props.onCloseModal && (
                <S.CrossWrapper background={props.closeBackground}>
                  <Icon
                    width={14}
                    height={14}
                    name='cross'
                    color={crossColor}
                    onClick={onCloseModal}
                  />
                </S.CrossWrapper>
              )}
            </S.Header>
            {props.details?.clickablePanelList?.length &&
              props.details.clickablePanelList.map((panel, i) => (
                <S.ClickPanel backGroundColor={panel.backgroundColor} key={i}>
                  <S.PanelTitle>
                    <Icon name={panel.icon as IconProps['name']} />
                    <div>
                      <p
                        style={{
                          marginBottom: 5,
                          fontWeight: 600,
                        }}
                      >
                        {panel.title}
                      </p>
                      <p
                        style={{
                          marginBottom: 0,
                          fontWeight: 400,
                          lineHeight: '18px',

                          fontSize: 14,
                        }}
                      >
                        {panel.info}
                      </p>
                    </div>
                  </S.PanelTitle>
                  <div
                    style={{
                      alignSelf: 'flex-end',
                      fontSize: 12,
                      fontWeight: 600,
                      flex: 1,
                      flexWrap: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    <Link
                      icon='arrow-right'
                      text={panel.buttonLabel}
                      iconRight
                      onClick={() => handleViewRequirement(panel.type)}
                    />
                  </div>
                </S.ClickPanel>
              ))}

            {!inApp && (
              <S.BottomDiv>
                <div style={{ marginTop: 10, textAlign: 'right' }}>
                  <Text color={colors.black._20} size={6} bold>
                    Already signed on VBiz?{' '}
                    <Text
                      color={colors.pink._100}
                      size={6}
                      bold
                      //   onClick={() => history.push('/onboarding')}
                      onClick={() => props.onCloseModal && props.onCloseModal()}
                      style={{ cursor: 'pointer' }}
                    >
                      Log In
                    </Text>
                  </Text>
                </div>
              </S.BottomDiv>
            )}
          </S.Wrapper>
        </S.Container>
      )}
    </BottomScrollListener>
  );
};
