import styled, { css } from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../../styles/utils';
import { breakpoints, colors, transition } from '../../../../styles/variables';
import { ItemProps } from '.';

const Container = styled.div`
  width: 100%; 
  position: relative;
  /* overflow: hidden; */
  text-align: left;
  background: ${colors.black._2};
  border: ${ptr(1)} solid ${colors.black._10};
height:233px;
  border-radius: 10px;
background: var(--gradient, linear-gradient(180deg, #B10180 41.67%, rgba(77, 18, 42, 0.87) 99.98%, rgba(35, 5, 18, 0.69) 99.99%, rgba(177, 1, 128, 0.00) 100%));
 
/* Cards Shadow */
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 8px 24px -6px rgba(0, 0, 0, 0.16);
  ${crossBrowserTransition(transition.slow)}

  border-radius: ${ptr(8)};
  color: ${colors.white._100};

  // @media (max-width: ${breakpoints.lg}px) {
  //   height:fit-content;
  //   max-width:100%;
  width:100%;

  // }

  @media (max-width: ${breakpoints.md}px) {
     height:fit-content;    
    //  min-width:100%;

    }
  
`;

const Wrapper = styled.aside`
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  /* overflow: hidden; */
  text-align: left;
  padding: ${ptr(24)} ${ptr(30)} ${ptr(22)} ${ptr(20)};

  ${crossBrowserTransition(transition.slow)}
`;

const Bars = styled.div`
  position: absolute;
  border-radius: 5px;
  right: 0;
`;

const BgImg = styled.div`
  position: absolute;
  border-radius: 5px;
  right: 0;
  top: 57px;
`;

const BottomContainer = styled.div<{ open: boolean }>`
  border-radius: ${ptr(8)};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${ptr(32)};

  > div {
    flex: 1;
  }

  ${(props) =>
    props.open &&
    css`
      svg {
        display: none;
      }
    `}
`;

const ItemBox = styled.div`
  display: flex;
`;

const DropownContentText = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
`;

const Icon = styled.div<Pick<ItemProps, 'income'>>`
  background: ${colors.black._5};
  border-radius: ${ptr(6)};
  min-width: ${ptr(48)};
  min-height: ${ptr(48)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${ptr(16)};

  ${(props) =>
    props.income &&
    css`
      & > svg {
        transform: rotate(270deg);
      }
    `}
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Data = styled.aside`
  display: flex;
  align-items: baseline;

  > :first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${ptr(176)};
  }

  > :last-child {
    display: flex;
    margin-left: ${ptr(16)};
    color: ${colors.green._100};
  }
`;

const Content = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: ${ptr(breakpoints.xxl)}) {
    position: relative;
    flex-direction: column;
  }
`;

const Company = styled.div`
  width: 100%;
`;

const IconWrapper = styled.div`
  margin-left: ${ptr(24)};
  cursor: pointer;
`;

const BalanceText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${ptr(4)};
`;

const Balance = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
  margin-bottom: ${ptr(15)};
  padding-bottom: ${ptr(20)};
  border-bottom: ${ptr(1)} solid ${colors.black._10};
  > svg {
    margin-left: ${ptr(60)};
    ${crossBrowserTransition(transition.slow)}
  }
`;

const Balance1 = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-left: ${ptr(24)};
    ${crossBrowserTransition(transition.slow)}
  }
`;

const AccountItemBox = styled.div`
  display: flex;
  flexdirection: 'row';
  flex: 1;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: center;
  margin-bottom: ${ptr(8)};
  border: '1px solid #000';
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
  background: rgba(219, 223, 228, 0.39);
`;

const Account = styled.div`
  padding-bottom: ${ptr(6)};
  display: flex;
  width: fit-content;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  > div {
    > svg {
      cursor: pointer;
    }
  }
`;

const AccountIcon = styled.div<{ open: boolean }>`
  /* margin-left: ${ptr(60)}; */
  background: #ffffff;
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  /* z-index: 9; */
  gap: 4px;
  cursor: pointer;
  ${crossBrowserTransition(transition.slow)}
`;

const Calendar = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(24)};

  ${(props) =>
    props.open &&
    css`
      svg {
        display: none;
      }
    `}

  > * {
    margin-right: ${ptr(12)};
  }

  @supports (gap: ${ptr(12)}) {
    gap: ${ptr(12)};

    > * {
      margin-right: 0;
    }
  }
`;

const AddNew = styled.div`
display:flex;
flex-wrap:wrap;
gap:16px;

  // @media (max-width: ${ptr(breakpoints.xxl)}) {
  //   position: absolute;
  //   right: 0;
  // }

  // > button {
  //   margin-left: auto;

  //   @media (max-width: ${ptr(breakpoints.xxl)}) {
  //     margin-left: 0;
  //   }
  // }
`;

const YearBadge = styled.button`
  border: ${ptr(1)} solid ${colors.black._40};
  box-sizing: border-box;
  border-radius: ${ptr(50)};
  display: flex;
  align-items: center;
  padding: ${ptr(8)} ${ptr(16)};
  background: transparent;

  > span {
    line-height: ${ptr(14)};
  }

  > svg {
    margin-left: ${ptr(6)};
    transform: rotate(-90deg);
  }
`;

const SubYearBadge = styled.button`
  border: ${ptr(1)} solid ${colors.black._40};
  box-sizing: border-box;
  border-radius: ${ptr(50)};
  display: flex;
  align-items: center;
  padding: ${ptr(8)} ${ptr(16)};
  background: transparent;

  > span {
    line-height: ${ptr(14)};
  }

  > svg {
    :first-child {
      margin-right: ${ptr(6)};
    }

    :last-child {
      margin-left: ${ptr(6)};
      transform: rotate(-180deg);
    }
  }
`;

const DropownContent = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? css`block` : css`none`)};
  position: absolute;
  top: 45px;
  right: 0;
  width: calc(100% - ${ptr(64)});
  height: fit-content;
  overflow: auto;
  background: ${colors.white._100};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  z-index: 102;
  padding: 31px 25px;

  ${(props) =>
    props.open
      ? css`
          border-top: ${ptr(1)} solid ${colors.black._10};
        `
      : css`
          border-top: ${ptr(0)} solid ${colors.black._10};
        `}

  ${crossBrowserTransition(transition.slow)}
`;

const Circle = styled.div<{ checked: boolean; theme: 'dark' | 'light' }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${ptr(22)};
  height: ${ptr(22)};
  border-radius: ${ptr(12)};

  ${crossBrowserTransition(`${transition.slow}`)}
  
   ${(props) => css`
     ${props.theme === 'dark' && `border: ${ptr(1)} ${colors.black._40} solid;`}
     ${props.theme === 'light' &&
     `border: ${ptr(1)} ${colors.white._100} solid;`}
   `}
  
  ${(props) =>
    props.checked &&
    css`
      ${props.theme === 'dark' &&
      `border: ${ptr(1)} ${colors.pink._100} solid;`}
      ${props.theme === 'light' &&
      `border: ${ptr(1)} ${colors.white._100} solid;`}
    `}
`;

const InsideCircle = styled.div<{ theme: 'dark' | 'light' }>`
  width: ${ptr(17)};
  height: ${ptr(17)};
  border-radius: ${ptr(9)};
  justify-content: center;
  align-items: center;

  ${(props) => css`
    ${props.theme === 'dark' && `background-color: ${colors.pink._100};`}
    ${props.theme === 'light' && `background-color: ${colors.white._100};`}
  `}
`;

const CircleBlock = styled.div`
  justify-content: center;
  align-items: center;
`;

const AccountItemInfoBlock = styled.div`
  flex: 4;
  padding: 0 ${ptr(8)};
`;

const AccountItemBalanceBlock = styled.div`
  flex: 1;
  text-align: right;
`;

export {
  Wrapper,
  Container,
  Content,
  Company,
  Balance,
  Balance1,
  BalanceText,
  Account,
  Calendar,
  AddNew,
  BottomContainer,
  ItemBox,
  Icon,
  Article,
  Data,
  YearBadge,
  SubYearBadge,
  DropownContent,
  AccountItemBox,
  DropownContentText,
  Circle,
  Bars,
  BgImg,
  InsideCircle,
  CircleBlock,
  IconWrapper,
  AccountIcon,
  AccountItemInfoBlock,
  AccountItemBalanceBlock,
};
