import React from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OnboardingActions } from '../../../../redux/onboarding/actions';

import { success } from '../../../../components/Toasts';
import { SuccessSection } from '../../../Onboarding/components/SuccessSection';
import { ModalWrapper } from '../../../../components/ModalWrapper';

export interface ModalProps {
  onClose?: () => void;
  visible?: boolean;
}

const PreviewDetails: React.FC<ModalProps> = ({ visible, onClose }) => {
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Custom handlers initiation region
   */
  const gotItHandler = () => {
    // success('Success!', "You've created a ");
    history.push('/dashboard');
  };

  return (
    <ModalWrapper maxWidth='100%' showModal={visible}>
      <SuccessSection
        title='Account created successfully!'
        description='Your request will be approved once consent has been received '
        onClick={gotItHandler}
        buttonLabel='Dashboard'
      />
    </ModalWrapper>
  );
};
export default PreviewDetails;
