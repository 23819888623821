/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';

import { validateObject, ValidateResult } from '../../../utils/validation';

import * as S2 from './styles';
import * as S from '../styles';
import { colors } from '../../../styles/variables';
// import { actions } from '../config';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { OnboardingFormHeader } from '../../../components/OnboardingFormHeader';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { failed, success } from '../../../components/Toasts';
import { OnboardingOtpModal } from '../../../components/OnboardingOtpModal';
import { Logo } from '../../../components/Logo';
import { Text } from '../../../components/Text';
import { dateTimeMonthString } from '../../../utils/datetime';

const SCHEME = {
  bvn: (value: string) => value.length === 11,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const BvnConsent: React.FC<{
  setBvnInitiated: (val: boolean) => void;
  email: string;
  token: string;
  reference: string;
  setBvnData: (val: string) => void;
  data?: any;
}> = ({ setBvnInitiated, email, token, reference, setBvnData, data }) => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */

  /**
   * Local state initiation region
   */
  const [bvn, setBvn] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [
    accountValidation,
    setAccountValidation,
  ] = useState<ValidateResult | null>(null);
  const [actionRequired, setActionRequired] = useState<string>();
  const [bvnVerified, setBvnVerified] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  const timer = useRef<NodeJS.Timeout>();

  /**
   * Custom handlers initiation region
   */

  /**
   * useEffect region
   */
  useEffect(() => {
    setAccountValidation(
      validateObject(
        {
          bvn,
        },
        SCHEME
      )
    );
  }, [bvn]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const verifyBvn = async () => {
    try {
      setLoading(true);
      const response = await onboardingService.verifyConsent({
        email,
        token,
        reference,
        bvn,
      });
      setLoading(true);

      success('Successful', 'You are being redirected to verify your BVN');
      setLoading(false);
      setBvnData(bvn);
      if (response.data?.bvnUrl) {
        timer.current = setTimeout(() => {
          setBvnInitiated(true);
          openInNewTab(response.data.bvnUrl);
        }, 3000);

        return;
      }
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'An error occured');
    }
    setLoading(false);
  };

  return (
    <>
      {/* <S.Container>
       
      </S.Container> */}

      <S.MainWrapper>
        <S.LogoWrapper>
          <Logo />
        </S.LogoWrapper>

        <S.MainWrapperInner>
          <S.TopText>
            <S.TopTextLhs>
              <S.Img alt='logo' src='/images/logo/logo.svg' />
            </S.TopTextLhs>

            <S.TopTextRhs>
              <Text bold size={7} color='#000000'>
                Date :
              </Text>

              <Text size={7} color='#000000'>
                {dateTimeMonthString()}
              </Text>
              <div />
            </S.TopTextRhs>
          </S.TopText>
          <S.ConsentTexts>
            <Text bold size={8} color='#000000'>
              Hello {data?.leader?.name || 'Board member'},
            </Text>

            <Text bold size={6} color='#000000'>
              Verify your BVN.
            </Text>

            {/* <Text size={7} color='#000000'>
              This is to formally request for your consent to proceed with
              onboarding your company on VBiz
            </Text> */}
          </S.ConsentTexts>
          {/* <OnboardingFormHeader
            // description='Provide your company’s details'
            descriptionColor='#6E7191'
            title='Bvn verification'
            titleColor={colors.black._20}
          /> */}
          <OnboardingCard>
            <S2.Wrapper>
              <TextInput
                type='currency'
                mask={createNumberMask(numberMaskOptions)}
                label='Enter BVN'
                value={bvn}
                onChange={setBvn}
                disabled={loading}
              />
            </S2.Wrapper>

            <S2.ButtonContainer>
              <Button
                label='Continue'
                // size='small'
                style={{ height: 40 }}
                loading={loading}
                onClick={() => verifyBvn()}
                disabled={!accountValidation?.isValid || loading}
              />
            </S2.ButtonContainer>
          </OnboardingCard>
        </S.MainWrapperInner>
      </S.MainWrapper>
    </>
  );
};
export default BvnConsent;
