import React from "react";

import { useSelector } from "react-redux";
import { Text } from "../Text";

import * as S from "./styles";
import { colors } from "../../styles/variables";
import Delete from "./assets/Images/Trash.png";
import Edit from "./assets/Images/PencilSimple.png";
import { Button } from "../Button";
import { PaymentDetails } from "../../pages/OtherBankTransfer";
import { formatCash, getWithdrawalFee } from "../../utils/money";
import { RootState } from "../../redux";

export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}
export interface ConfirmPaymentProps {
  beneficiaries: Array<PaymentDetails>;
  proceedHandler: any;
  handleRemoveBeneficiary: any;
  editBeneficiaryHandler: any;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
}

export const ConfirmPayment: React.FC<ConfirmPaymentProps> = ({
  beneficiaries,
  proceedHandler,
  handleRemoveBeneficiary,
  editBeneficiaryHandler,
}) => {
  /**
   * Hooks initiation region
   */
  const preference = useSelector((store: RootState) => store.preference);

  let totalamount = 0;
  let totalFee = 0;

  return (
    <S.Container>
      <S.TopHeader>
        <Text color={colors.white._100} size={4} bold>
          Check the data
        </Text>
        <Text color={colors.white._100} size={6}>
          Check the data you entered to make a transfer
        </Text>
      </S.TopHeader>
      <S.AccountDetails>
        {beneficiaries.map((item, index) => {
          const uniqueKey = index + 1;
          let thisAmount = 0;
          if (item.amount?.value) {
            const realAmount = item.amount?.value.replace(/[\s,₦%]/g, "");
            if (
              item.destinationBank?.key &&
              item.destinationBank?.key !== "999999"
            ) {
              totalFee += Number(
                getWithdrawalFee({
                  amount: parseFloat(realAmount),
                  feeArray: preference?.withdrawalFee,
                })
              );
            }
            thisAmount = parseFloat(realAmount);
          }
          // const thisAmount = 1;
          totalamount += thisAmount;

          return (
            <>
              <S.CustomTable key={uniqueKey}>
                <tr>
                  <th>
                    <Text color={colors.black._100} size={5} bold>
                      Amount
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.black._100} size={5}>
                      ₦{formatCash(String(item.amount?.value))}
                    </Text>
                  </th>
                </tr>

                <tr>
                  <th>
                    <Text color={colors.black._100} size={5} bold>
                      Fee
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.black._100} size={5}>
                      ₦
                      {item.destinationBank?.key !== "999999"
                        ? getWithdrawalFee({
                            amount: parseFloat(
                              item.amount?.value.replace(/[\s,₦%]/g, "") ?? "0"
                            ),
                            feeArray: preference.withdrawalFee,
                          })
                        : formatCash(0)}
                    </Text>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Text color={colors.black._100} size={5} bold>
                      Destination account
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.black._100} size={5}>
                      {item.destinationAccount?.value}
                    </Text>
                  </th>
                </tr>

                <tr>
                  <th>
                    <Text color={colors.black._100} size={5} bold>
                      Destination bank
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.black._100} size={5}>
                      {item.destinationBank?.label}
                    </Text>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Text color={colors.black._100} size={5} bold>
                      Receiver name
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.black._100} size={5}>
                      {item.destinationAccount?.response.name}
                    </Text>
                  </th>
                </tr>
                <tr>
                  <th>
                    <Text color={colors.black._100} size={5} bold>
                      Comment
                    </Text>
                  </th>
                  <th>
                    <Text color={colors.black._100} size={5}>
                      {item.comment}
                    </Text>
                  </th>
                </tr>
                {beneficiaries.length > 1 ? (
                  <tr>
                    <th>
                      <Text color={colors.black._100} size={5} bold>
                        Action
                      </Text>
                    </th>
                    <th>
                      <S.LeftSpaceImage onClick={editBeneficiaryHandler}>
                        <img src={Edit} alt="" />
                      </S.LeftSpaceImage>
                      <S.LeftSpaceImage
                        onClick={() => handleRemoveBeneficiary(index)}
                      >
                        {" "}
                        <img src={Delete} alt="" />
                      </S.LeftSpaceImage>
                    </th>
                  </tr>
                ) : null}
              </S.CustomTable>
              <S.HrStyled />
            </>
          );
        })}

        <div>
          <S.TotalStyle>
            <Text color={colors.black._100} size={5} bold>
              TOTAL AMOUNT
            </Text>
            <Text color={colors.black._100} size={5} bold>
              ₦{formatCash(String(totalamount))}
            </Text>
          </S.TotalStyle>
          <S.TotalStyle>
            <Text color={colors.black._100} size={5} bold>
              TOTAL FEE
            </Text>
            <Text color={colors.black._100} size={5} bold>
              ₦{formatCash(String(totalFee))}
            </Text>
          </S.TotalStyle>
          <S.TotalStyle>
            <Text color={colors.black._100} size={5} bold>
              TOTAL AMOUNT + FEE
            </Text>
            <Text color={colors.black._100} size={5} bold>
              ₦{formatCash(String(totalamount + totalFee))}
            </Text>
          </S.TotalStyle>
          <S.ButtonStyle>
            <Button label="Proceed" onClick={proceedHandler} />
          </S.ButtonStyle>
        </div>
      </S.AccountDetails>
    </S.Container>
  );
};
