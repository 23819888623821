/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CompanyActions } from "../../redux/company/actions";
import { CompanyInterface } from "../../redux/company/ICompany";

import paymentsService from "../../services/internal/payments/payments.service";
import debug from "../../services/internal/debbug.service";
import { Icon } from "../../components/Icon";

import { Text } from "../../components/Text";

import * as S from "./styles";
import { colors } from "../../styles/variables";
import { Table, TableFooterProps } from "../../components/Table";
import { YearDropList } from "../../components/YearDropList";
import { Preload } from "../../components/Preload";
import { CheckBox } from "../../components/CheckBox";
import { ModalWrapper } from "../../components/ModalWrapper";
import SearchInput from "../../components/SearchInput";
import { PaymementAccountInterface } from "../../services/internal/payments/IPayments";
import { FilterResult } from "./Component/FilterResult";
import { PaymentDetailsViews } from "./Component/PaymentDetailsViews";
import { formatCash } from "../../utils/money";
import {
  getDateWithYearFirst,
  timeDateMonthString,
} from "../../utils/datetime";
import { IPagination, Pagination } from "../../components/Pagination";
import { IGetQueries } from "../../interface";
import getQueryString from "../../utils/getQueryString";
import { PaginationActions } from "../../redux/paginationSettings/actions";
import AccessControl, { EPermissions } from "../../components/AccessControl";
import { failed } from "../../components/Toasts";

const separator = "=";

export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}

export interface PaymentHistory {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  approvers: Array<IApprovers>;
  flagReason: any; // TODO:
  gatewayResponse: string;
  requestReference: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
  check: boolean;
  destinationBank: string;
  fromAccountNo: string;
}

export interface IApprovers {
  action: string;
  createdAt: number;
  currentRole: string;
  email: string;
  name: string;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  requestReference: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
}
export interface ModalViewComponent {
  FilterResult: any;
  PaymentDetails: any;
}

export const TransactionReport: React.FC = () => { 
  /**
   * Hooks initiation region
   */
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Redux store initiation region
   */
  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );
  const companies = useSelector(
    (state: { company: CompanyInterface }) => state.company.list
  );

  /**
   * Local state initiation region
   */
  const [currentCompanyId, setCurrentCompanyId] = useState<string>("");

  const [paymentAccounts, setPaymentAccounts] = useState<
    Array<PaymementAccountInterface & { isChecked: boolean }>
  >();
  const [payments, setPayments] = useState<Array<PaymentHistory>>([]);
  const [empty, setEmpty] = useState<boolean>(false);
  const [bottomLoading, setBottomLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showmodalView, setshowmodalView] = useState<boolean>(false);
  const [currentView, setcurrentView] = useState<string>("");
  const [currentPaymentDetails, setcurrentPaymentDetails] = useState<
    PaymentHistory
  >();
  const [selectedPayment, setselectedPayment] = useState<Array<PaymentHistory>>(
    []
  );
  const [year, setYear] = useState<number>();
  const [text, setText] = useState<string>("");
  const [pagination, setPagination] = useState<IPagination>({
    limit: 50,
    currentPage: 1,
    pageItems: 1,
    totalPages: 1,
  });
  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});

  useEffect(() => {
    PaginationActions.reset();
  }, []);

  /**
   * Custom handlers initiation region
   */
  const getPayments = async (queriesData?: IGetQueries): Promise<void> => {
    try {
      if (!queriesData?.search) setLoading(true);
      const queries =
        queriesData && queriesData.search
          ? { ...queriesData }
          : {
              page: 1,
              limit: 50,
              ...additionalQueries,
              ...queriesData,
            };

      if (!queriesData?.search) {
        delete queries.search;
        setText("");
      }
      const result = await paymentsService.getPayments(getQueryString(queries));
      setAddtionalQueries(queries);
      setLoading(false);
      const paymentdata = result.data.map((el: IPayments) => ({
        ...el,
        check: false,
      }));
      setPagination({
        limit: result.meta.limit,
        currentPage: result.meta.page,
        pageItems: result.meta.pageCount,
        totalPages: result.meta.totalPages,
      });
      setPayments(paymentdata);
    } catch (err) {
      debug.error("Error retrieving payments", err);
      failed("Failed", err.message || "Error retrieving payments");
    }
  };

  const getPaymentAccounts = async (id: string): Promise<void> => {
    try {
      const result = await paymentsService.getPaymentAccounts(id);
      setPaymentAccounts(result.map((item) => ({ ...item, isChecked: false })));
    } catch (err) {
      debug.error("Error while getting payment accounts on dashboard", err);
    }
  };

  const getCompanyFromUrl = (location: Location | any) => {
    const companyId = location.search.split(separator)[1];
    if (companyId) {
      const company = companies.find((item) => item.id === companyId);
      setCurrentCompanyId(company?.id || "");
    }
  };
  const UserModalView = currentView as keyof typeof ModalView;

  useEffect(() => {
    if (activeCompany) {
      getPaymentAccounts(currentCompanyId || activeCompany.id);
    }
  }, [currentCompanyId]);

  const ModalView: ModalViewComponent = {
    FilterResult: (
      <FilterResult action={getPayments} showModal={setshowmodalView} />
    ),
    PaymentDetails: <PaymentDetailsViews data={currentPaymentDetails} />,
  };

  const getStatusButton = (el: PaymentHistory) => {
    if (el.status === "success") {
      return (
        <S.BtnSuccess>
          <Text size={6}>{el.status}</Text>
        </S.BtnSuccess>
      );
    }
    if (el.status === "pending") {
      return (
        <S.BtnWarning>
          <Text size={6}>{el.status}</Text>
        </S.BtnWarning>
      );
    }
    if (el.status === "failed") {
      return (
        <S.BtnDanger>
          <Text size={6}>{el.status}</Text>
        </S.BtnDanger>
      );
    }
    if (el.status === "unknown") {
      return (
        <S.BtnUnknwn>
          <Text size={6}>{el.status}</Text>
        </S.BtnUnknwn>
      );
    }
    return (
      <S.BtnWarning>
        <Text size={6}>{el.status}</Text>
      </S.BtnWarning>
    );
  };

  const getYearFilter = (value: number | string) => {
    if (typeof value === "number") {
      const from = getDateWithYearFirst(new Date(value, 0, 1));
      const to = getDateWithYearFirst(new Date(value, 11, 31));
      getPayments({ page: 1, to, from });
      setAddtionalQueries({ page: 1, to, from });
      setYear(value);
    }
  };

  const handleSearchText = (value: string) => {
    setText(value);
    if (!value.length) {
      getPayments({ page: 1 });
    }
  };

  const searchPayments = async () => {
    if (text) {
      setAddtionalQueries({ search: text, page: 1 });
      await getPayments({ search: text, page: 1 });
    }
  };
  useEffect(() => {
    let delayDebounceFn: any = null;
    if (text.length) {
      delayDebounceFn = setTimeout(() => {
        getPayments({ search: text, page: 1 });
      }, 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [text]);

  const renderTableItem = () =>
    payments ? (
      <>
        <AccessControl requiredPermission={[EPermissions.VIEW_PAYMENT]} isPage>
          <S.TopWrapper>
            <S.TableConatiner>
              <YearDropList
                text={!year ? "Year" : year.toString()}
                onChange={getYearFilter}
              />
              <S.TableIIConatiner>
                <SearchInput
                  onChange={handleSearchText}
                  value={text}
                  onSubmitSearch={searchPayments}
                  placeholder="Search for using account or reference."
                />
                <S.MarginLeftComp onClick={() => FilterResultHandler()}>
                  <Icon
                    name="settings"
                    color={colors.pink._100}
                    width={12}
                    height={12}
                  />
                </S.MarginLeftComp>
              </S.TableIIConatiner>
            </S.TableConatiner>
          </S.TopWrapper>

          <S.TableContainer loading={loading}>
            <Table
              header={{
                cells: {
                  0: {
                    align: "left",
                    children: (
                      <CheckBox
                        onChange={(isChecked) => {
                          if (isChecked) {
                            setPayments((old) =>
                              old.map((el) => ({ ...el, check: true }))
                            );
                          } else {
                            setPayments((old) =>
                              old.map((el) => ({ ...el, check: false }))
                            );
                          }
                        }}
                      />
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  1: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        DESCRIPTION
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  2: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        BENEFICIARY ACCOUNT
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  3: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        DATE
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  4: {
                    align: "left",
                    children: (
                      <Text size={7} bold>
                        AMOUNT
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  5: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        STATUS
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  6: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        REFERENCE
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

              
                  7: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        APPROVED BY
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  8: {
                    align: "right",
                    children: (
                      <Text size={7} bold>
                        DATE UPDATED
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  9: {
                    align: "right",
                    children: <Text size={7} bold></Text>,
                    padding: "1.25rem 1.5rem",
                  },
                },
                padding: "1.25rem 0",
              }}
              footer={((): TableFooterProps | undefined => {
                if (bottomLoading) {
                  return {
                    backgroundColor: colors.white._100,
                    cells: {
                      0: {
                        align: "center",
                        children: (
                          <Icon
                            name="small-loading"
                            color={colors.black._100}
                          />
                        ),
                        padding: "1.25rem 1.5rem",
                        colspan: 6,
                      },
                    },
                  };
                }
                if (empty) {
                  return {
                    backgroundColor: colors.white._100,
                    cells: {
                      0: {
                        align: "center",
                        children: <Text>Result not found</Text>,
                        padding: "1.25rem 1.5rem",
                        colspan: 6,
                      },
                    },
                  };
                }
                return undefined;
              })()}
              rows={payments?.map((el) => ({
                padding: "1.25rem 0",
                border: {
                  color: colors.black._10,
                  width: 1,
                  style: "solid",
                  sides: ["top"],
                },
                cells: {
                  0: {
                    align: "left",
                    children: (
                      <CheckBox 
                        checked={el.check}
                        onChange={() => toggleItemInArray(el, selectedPayment)}
                      />
                    ),
                    padding: "1.25rem 1.5rem",
                    display: "inline-block",
                  },
                  1: {
                    align: "left",

                    children: (
                      <S.Column>
                        <Text size={7}>
                          {el.comment?.substring(0, 15)}
                          {el.comment?.length > 15 ? "..." : ""}
                        </Text>
                      </S.Column>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  2: {
                    align: "left",

                    children: <Text size={6}>{el.receiverNuban}</Text>,
                    padding: "1.25rem 1.5rem",
                  },
                  3: {
                    align: "left",
                    children: (
                      <Text size={6}>
                        {timeDateMonthString(new Date(el.createdAt))}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  4: {
                    align: "left",
                    children: (
                      <Text size={6}>{`₦ ${formatCash(el.amount)}`}</Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                  5: {
                    align: "right",
                    children: getStatusButton(el),
                    padding: "1.25rem 1.5rem",
                  },

                  6: {
                    align: "right",
                    // onClick: () => setcurrentPaymentdetails(el),
                    children: <Text size={6}>{el.reference}</Text>,
                    padding: "1.25rem 1.5rem",
                  },

                  7: {
                    align: "right",
                    // onClick: () => setcurrentPaymentdetails(el),
                    children: (
                      <Text size={6}>
                        {el.approvers &&
                          el.approvers.length >= 1 &&
                          el.approvers.map((pay) => pay.name).join(",")}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  8: {
                    align: "right",
                    // onClick: () => setcurrentPaymentdetails(el),
                    children: (
                      <Text size={6}>
                        {timeDateMonthString(new Date(el.updatedAt))}
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },

                  9: {
                    align: "right",
                    onClick: () => setcurrentPaymentdetails(el),
                    children: (
                      <Text size={6} color="#C6145E">
                        View
                      </Text>
                    ),
                    padding: "1.25rem 1.5rem",
                  },
                },
              }))}
            />

            {payments.length > 0 && (
              <Pagination {...pagination} action={getPayments} />
            )}
          </S.TableContainer>
        </AccessControl>
      </>
    ) : null;

  const renderModalView = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="inherit"
      maxHeight="96vh"
      showModal={showmodalView}
      onCloseModal={() => setshowmodalView(false)}
      closeBackground={
        UserModalView === "PaymentDetails" ? colors.pink._20 : ""
      }
      crossColor={colors.black._100}
    >
      {ModalView[UserModalView]}
    </ModalWrapper>
  );
  const setcurrentPaymentdetails = (details: PaymentHistory) => {
    // const myArray = [];
    // myArray.push(details);
    setcurrentPaymentDetails(details);
    setcurrentView("PaymentDetails");
    setshowmodalView(true);
  };
  const toggleItemInArray = (identifier: any, bucketArray: any[] = []) => {
    const uniqueKey = identifier.id;
    if (identifier === "empty") {
      bucketArray = [];
      return;
    }
    const check = bucketArray.findIndex((wizard) => wizard.id === uniqueKey);

    if (check !== -1) {
      bucketArray.splice(check, 1);
    } else {
      bucketArray[bucketArray.length] = identifier;
    }

    setselectedPayment(bucketArray);
  };
  const FilterResultHandler = () => {
    setcurrentView("FilterResult");
    setshowmodalView(true);
    const queries = additionalQueries;
    delete queries.to;
    delete queries.from;
    setAddtionalQueries(queries);
  };

  /**
   * Listeners region
   */
  history.listen((location) => {
    getCompanyFromUrl(location);
  });

  /**
   * useEffect region
   */
  useEffect(() => {
    if (!companies?.length) {
      dispatch(CompanyActions.getCompaniesList());
    }

    if (!activeCompany) {
      dispatch(CompanyActions.getActiveCompany());
    } else {
      setCurrentCompanyId(activeCompany.id);
    }

    if (companies?.length && activeCompany) {
      getPayments();
      getCompanyFromUrl(history.location);
    }
  }, [companies, activeCompany]);

  return (
    <S.Container>
      {renderModalView()}

      {loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      ) : (
        renderTableItem()
      )}
    </S.Container>
  );
};
