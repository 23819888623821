import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TwoSide, Columns } from '../../styles/wrappers';
import { WelcomeSlider } from '../../components/WelcomeSlider';
import { AccountNumber } from './components/AccountNumber';
import { RoundButton } from '../../components/RoundButton';
import { OtpCode } from './components/OtpCode';
import { RequestSent } from './components/RequestSent';

const SignUp: React.FC = () => {
  const [accountInfo, setAccountInfo] = useState(null);

  const [step, setStep] = useState(0);

  const history = useHistory();

  const onBack = () => {
    if (step === 0) {
      history.goBack();
    } else {
      setStep(step - 1);
    }
  };

  if (step === 2) {
    return (
      <RequestSent onCreate={() => history.push('/login')} />
    );
  }
  return (
    <TwoSide.Wrapper>
      <TwoSide.Left>
        <WelcomeSlider />
      </TwoSide.Left>
      <TwoSide.Right>
        <Columns.Wrapper>
          <Columns.ContentWrapper>
            <RoundButton
              icon="arrow-left"
              onClick={onBack}
            />
          </Columns.ContentWrapper>
          <Columns.ContentWrapper>
            {step === 0 && (
              <AccountNumber
                onAdd={(_accountInfo) => {
                  setStep(1);
                  setAccountInfo(_accountInfo);
                }}
              />
            )}
            {/* {step === 1 && (
              <CheckData
                onContinue={() => {
                  setStep(2);
                }}
              />
            )}
            {step === 2 && (
              <CreateRequest
                onCreate={() => {
                  setStep(3);
                }}
              />
            )} */}
            {step === 1 && (
              <OtpCode
                accountInfo={accountInfo}
                onComplite={() => {
                  setStep(2);
                }}
              />
            )}
          </Columns.ContentWrapper>
          <Columns.ContentWrapper />
        </Columns.Wrapper>
      </TwoSide.Right>
    </TwoSide.Wrapper>
  );
};

export default SignUp;
