import React, {
  useEffect, useRef, useState,
} from 'react';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useDispatch } from 'react-redux';
import { PaymentDetails, BankNames } from '../..';
import { PaymementAccountInterface } from '../../../../services/internal/payments/IPayments';
import { DropdownInput, DropdownOptionProps } from '../../../../components/DropdownInput';
import { Switch } from '../../../../components/Switch';
import { Text } from '../../../../components/Text';
import { TextInput } from '../../../../components/TextInput';
import { colors } from '../../../../styles/variables';
import { ValidateResult } from '../../../../utils/validation';
import { formatCash } from '../../../../utils/money';
import { Link } from '../../../../components/Link';
import { IBeneficiary, SelectBeneficiaryType } from '../../../../components/SelectBeneficiaryType';

import * as S from './styles';
import debug from '../../../../services/internal/debbug.service';
import banksService from '../../../../services/internal/banks/banks.service';
import ErrorBanks from '../../../../services/internal/banks/banks.error';
import { PaymentActions } from '../../../../redux/payment/actions';
import paymentsService from '../../../../services/internal/payments/payments.service';

export interface OtherBankPaymentDetailsProps {
  data: PaymentDetails,
  onChangeData?: (fieldName: string, value: any) => void,
  validation: ValidateResult | null,
  paymentsAccounts: Array<PaymementAccountInterface>,
  bankNames: Array<BankNames>,
  index: number,
  onRemove: (index: number) => void,
  onChangeAmount: (value: any) => void,
  setBeneficiariesTypes: (value: any) => void,
  setBeneficiaryTypes: (name: string, value: any) => void
  onChangeDestinationAccount: (index: number, fieldName: string, value: any) => void,
  bankNamesLoading: boolean,
  paymentAccountsLoading: boolean,
  beneficiariesTypes: Array<IBeneficiary> 
}

export const OtherBankPaymentDetails: React.FC<OtherBankPaymentDetailsProps> = ({
  onChangeData = () => {},
  data,
  validation,
  paymentsAccounts,
  bankNames,
  index,
  onRemove = () => {},
  onChangeAmount = () => {},
  setBeneficiaryTypes,
  onChangeDestinationAccount,
  bankNamesLoading,
  paymentAccountsLoading,
  beneficiariesTypes,
  setBeneficiariesTypes,
}) => {
  const dispatch = useDispatch();
  const resolveTimerRef = useRef<any>();
  useEffect(() => (() => clearTimeout(resolveTimerRef.current)), []);

  const [destinationAccountLoading, setDestinationAccountLoading] = useState<boolean>(false);

  const numberMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    integerLimit: 10,
    allowNegative: false,
    allowLeadingZeroes: true,
  };

  const convertBankNamesArray = (
    banks: Array<BankNames>,
  ): Array<DropdownOptionProps> => banks.map((bank) => ({
    label: bank.name,
    key: bank.nipCode?.toString(),
  }));

  const convertPaymentAccountArray = (
    paymentAccounts: Array<PaymementAccountInterface>,
  ): Array<DropdownOptionProps> => paymentAccounts.map((account) => (
    account.allowOverdraft && account.overdraftLimit
      ? {
        label: account.nuban,
        key: account.id,
        value: `₦ ${formatCash(account.balance)}`,
        category: account.category,
        subLabel: account.type,
        od: `₦ ${formatCash(account.balance + account.overdraftLimit)}`,
      }
      : {
        label: account.nuban,
        key: account.id,
        value: `₦ ${formatCash(account.balance)}`,
        category: account.category,
        subLabel: account.type,
      }));

  const checkResolve = async ():Promise<void> => {
    setDestinationAccountLoading(true);
    clearTimeout(resolveTimerRef.current);
    dispatch(PaymentActions.setValidating(true));
    if (data.destinationAccount?.value?.length && data.destinationBank?.key) {
      try {
        const result = await banksService.checkResolve(
          data.destinationAccount.value, data.destinationBank?.key as string,
        );
        onChangeDestinationAccount(index, 'response', Object.assign(result, { code: 'success' }));
      } catch (err) {
        if (err instanceof ErrorBanks) {
          onChangeDestinationAccount(index, 'response', { code: err.code, message: err.message });
        }
        const errorData = {
          accountNumber: data.destinationAccount?.value,
          bankName: data.destinationBank.label || '',
        };
        paymentsService.setError(errorData);
        debug.error('Failed get payment accounts', err);
      }
    }
    setDestinationAccountLoading(false);
    resolveTimerRef.current = setTimeout(() => {
      dispatch(PaymentActions.setValidating(false));
    }, 500);
  };

  useEffect(() => {
    let delayDebounceFn: any = null;
    if (data.destinationAccount?.value.length === 10) {
      delayDebounceFn = setTimeout(() => {
        checkResolve();
      }, 500);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [data.destinationAccount?.value, data.destinationBank?.key]);

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <Text bold size={5} color={colors.black._100}>
          Payment details
        </Text>
        <Link icon='trash-basket' onClick={() => onRemove(index)} />
      </S.TitleWrapper>

      <S.InputsRow>
        <S.InputWrapper first>
          <DropdownInput
            options={convertPaymentAccountArray(paymentsAccounts)}
            onSelect={(value) => {
              onChangeData('paymentAccount', value);
              if (!value?.od)
                onChangeAmount({ value: value?.value, fieldName: 'pa' });
              if (value?.od) {
                onChangeAmount({ value: value?.od, fieldName: 'pa' });
              }
            }}
            value={data.paymentAccount?.value}
            multiOptions
            inputValue={data.paymentAccount?.label}
            label='Payment account'
            disabled={paymentAccountsLoading}
            loading={paymentAccountsLoading}
          />
        </S.InputWrapper>
        <div style={{ flex: 1 }}>
          <TextInput
            value={data.amount?.value}
            onChange={(value) => onChangeAmount({ value, fieldName: 'value' })}
            invalid={
              !data.amount ||
              validation === undefined ||
              validation?.data.amount?.isValid
                ? ''
                : 'Amount is greater than account balance'
            }
            symbol={!data.amount || validation?.data.amount?.isValid ? '₦' : ''}
            type='currency'
            label='Amount, ₦'
          />
        </div>
      </S.InputsRow>

      <S.InputsRow>
        <S.InputWrapper first>
          <DropdownInput
            options={convertBankNamesArray(bankNames)}
            title={data.destinationBank?.label}
            onSelect={(value) => onChangeData('destinationBank', value)}
            placeholder='Select or enter destination bank'
            label='Destination Bank'
            disabled={bankNamesLoading || !!data.saved}
            loading={bankNamesLoading}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <TextInput
            value={data.destinationAccount?.value}
            onChange={(value) =>
              onChangeDestinationAccount(index, 'value', value)
            }
            type='currency'
            mask={createNumberMask(numberMaskOptions)}
            invalid={
              data.destinationAccount?.response.code &&
              data.destinationAccount?.response.code === 'FORBIDDEN'
                ? 'User not found'
                : ''
            }
            label='Destination account'
            validText={data.destinationAccount?.response.name}
            valid={data.destinationAccount?.response.code === 'success'}
            loading={destinationAccountLoading}
            disabled={!!data.saved}
          />
        </S.InputWrapper>
      </S.InputsRow>

      <S.InputsRow>
        <S.InputWrapper first>
          <TextInput
            value={data.comment}
            onChange={(value) => onChangeData('comment', value)}
            type='text'
            label='Comment for payment'
            maxLength={140}
          />
        </S.InputWrapper>
        {!data.saved ? (
          <S.InputWrapper>
            <S.SwitchWrapper>
              <Switch
                checked={data.beneficiaryTypes?.saveBeneficiary}
                onClick={(isChecked) =>
                  setBeneficiaryTypes('saveBeneficiary', isChecked)
                }
                text='Save Beneficiary'
              />
            </S.SwitchWrapper>
          </S.InputWrapper>
        ) : (
          <S.InputWrapper>
            <S.SwitchWrapper />
          </S.InputWrapper>
        )}
      </S.InputsRow>

      {data.beneficiaryTypes?.saveBeneficiary && (
        <S.BeneficiaryContainer>
          <Text bold size={5} color={colors.black._100}>
            Select beneficiary type
          </Text>
          <S.InputWrapper>
            <SelectBeneficiaryType
              label='Select beneficiary type'
              list={beneficiariesTypes}
              setList={setBeneficiariesTypes}
              types={data.beneficiaryTypes.types}
              setTypes={setBeneficiaryTypes}
            />
          </S.InputWrapper>
        </S.BeneficiaryContainer>
      )}
    </S.Wrapper>
  );
};
