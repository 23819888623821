import React, { useState, useEffect, useRef } from 'react';
import MaskedInput, { maskArray } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import * as S from './styles';
import { Icon, IconName } from '../Icon';
import { colors } from '../../styles/variables';
import { Text } from '../Text';
import { removeSpecialCharsAndSpace } from '../../utils/strings';

export interface IconCircleProps {
  color: string;
  clickable?: boolean;
  onClick?: () => void;
}

export interface TextInputProps {
  /**
   * Label value
   */
  label?: string;
  labelExtra?: string;
  labelExtraColor?: string;
  /**
   * Type of input
   */
  type?: 'text' | 'password' | 'tel' | 'currency' | 'date' | 'number';
  /**
   * Input value
   */
  value?: string;
  /**
   * Input name
   */
  name?: string;
  /**
   * Is input valid
   */
  valid?: boolean;
  validText?: string;
  /**
   * Invalid text (show invalid mark if text length more than 0)
   */
  invalid?: string;
  /**
   * Is input disabled
   */
  disabled?: boolean;
  /**
   * Input mask (react-input-mask)
   */
  mask?: maskArray | ((value: string) => maskArray) | undefined;
  /**
   * Max length of text
   */
  maxLength?: number;
  showBorder?: boolean;
  borderStyle?: string;
  max?: number;
  min?: number;
  /**
   * Name of an icon of the Icon component
   */
  icon?: IconName['name'] | null;

  /**
   * Color of icon
   *
   */
  iconColor?: string;
  /**
   * Symbol like an icon
   */
  symbol?: string;
  /**
   * On icon click event
   */
  onIconClick?: () => void;
  /**
   * Disable onIconClick action
   */
  disabledOnIconClick?: boolean;
  /**
   * On change event
   */
  onChange?: (value: string) => void;
  /**
   * Enter press action
   */
  onEnterPressed?: () => void;
  loading?: boolean;
  rightText?: string;
  placeholder?: string;
}

const ENTER_KEY_CODE = 13;

const currencyMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 9,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const TextInput: React.FC<TextInputProps> = ({
  type = 'text',
  showBorder = true,
  ...props
}) => {
  const [value, setValue] = useState(props.value || '');

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const inputRef = useRef<any>();

  useEffect(() => {
    if (inputRef.current) {
      if (type === 'date') {
        inputRef.current.focus();
      }
    }
  }, [inputRef.current]);

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onChange = (e) => {
    if (e.target.name === 'idNumber' || e.target.name === 'cac')
      e.target.value = removeSpecialCharsAndSpace(e.target.value);
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  const onKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === ENTER_KEY_CODE && props.onEnterPressed) {
      props.onEnterPressed();
    }
  };

  const onFocus = () => {
    setShowPlaceholder(true);
  };

  return (
    <>
      <S.Label invalid={props.invalid}>
        {props.label}{' '}
        <span
          style={{ color: props.labelExtraColor || '#DB9308', fontSize: 12 }}
        >
          {props.labelExtra}
        </span>{' '}
      </S.Label>

      <S.Wrapper invalid={props.invalid} disabled={props.disabled}>
        {props.mask || type === 'currency' ? (
          <MaskedInput
            ref={inputRef}
            mask={
              type === 'currency'
                ? props.mask || createNumberMask(currencyMaskOptions)
                : props.mask
            }
            type={type === 'password' && passwordVisibility ? 'text' : type}
            value={value}
            onChange={onChange}
            disabled={props.disabled}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            placeholder={
              type === 'tel'
                ? // && showPlaceholder
                  props.placeholder || 'e.g 0801 234 5678'
                : props.placeholder
            }
            inputMode={type === 'currency' ? 'numeric' : undefined}
            render={(
              ref: (inputElement: HTMLElement) => void,
              localProps: any
            ) => <S.StyledInput ref={ref} {...localProps} value={value} />}
          />
        ) : (
          <S.StyledInput
            // style={
            //   showBorder ? { border: '1px solid #D9DBE9' } : { border: 'none' }
            // }
            style={{ border: props.borderStyle || '1px solid #D9DBE9' }}
            autoFocus={type === 'date'}
            defaultValue=''
            type={type === 'password' && passwordVisibility ? 'text' : type}
            value={
              props.label &&
              props.label
                ?.split(' ')
                .map((text) => text?.toLowerCase())
                .includes('email')
                ? value.trim()
                : value
            }
            maxLength={props.maxLength}
            max={props.max}
            min={props.min}
            name={props?.name}
            onChange={onChange}
            disabled={props.disabled}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            placeholder={
              type === 'tel' && showPlaceholder
                ? 'e.g 0801 234 5678'
                : props.placeholder
            }
          />
        )}

        {type === 'password' && (
          <S.IconWrapper>
            <S.IconCircleWrapper
              color='transparent'
              onClick={togglePasswordVisibility}
              clickable={!props.disabledOnIconClick}
            >
              {!props.invalid && <Icon name='eye' color={colors.black._100} />}
            </S.IconCircleWrapper>
          </S.IconWrapper>
        )}
        {props.loading && (
          <S.Loading>
            <Icon name='small-loading' color={colors.black._40} />
          </S.Loading>
        )}
        {props.invalid && (
          <>
            <S.InvalidText>{props.invalid}</S.InvalidText>
            <S.IconWrapper>
              <S.IconCircleWrapper color={colors.red._10}>
                <Icon name='exclamation-mark' color={colors.red._100} />
              </S.IconCircleWrapper>
            </S.IconWrapper>
          </>
        )}
        {props.valid && (
          <>
            {props.validText && <S.ValidText>{props.validText}</S.ValidText>}
            <S.IconWrapper>
              <S.IconCircleWrapper color={colors.green._10}>
                <Icon name='check-mark' color={colors.green._100} />
              </S.IconCircleWrapper>
            </S.IconWrapper>
          </>
        )}
        {props.icon && (
          <S.IconWrapper>
            <S.IconCircleWrapper
              color='transparent'
              onClick={props.onIconClick}
              clickable={!props.disabledOnIconClick}
            >
              <Icon
                name={props.icon}
                color={props.iconColor || colors.pink._100}
              />
            </S.IconCircleWrapper>
          </S.IconWrapper>
        )}
        {props.symbol && (
          <S.IconWrapper>
            <S.IconCircleWrapper
              color='transparent'
              onClick={props.onIconClick}
            >
              <S.SymbolText>{props.symbol}</S.SymbolText>
            </S.IconCircleWrapper>
          </S.IconWrapper>
        )}
        {props.rightText && (
          <S.RightBox>
            <Text size={7} color={colors.black._60}>
              {props.rightText}
            </Text>
          </S.RightBox>
        )}
      </S.Wrapper>
    </>
  );
};
