import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div`
  > button {
    width: 100%
  }
`;

const Wrapper = styled.div`
  margin: ${ptr(16)} 0;

  > div {
    margin-top: ${ptr(20)};
  }
`;

const ItemContainer = styled.div``;

const CheckAreaGroupWrapper = styled.div`
  margin-bottom: ${ptr(20)};
`;

const ItemRow = styled.div`
  margin-top: ${ptr(12)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};
`;

const Add = styled.button`
  display: flex;
  align-items: center;
  max-width: fit-content;
  border: 0;
  background: none;
  margin-bottom: ${ptr(40)};

  > span {
    display: block;
    margin-left: ${ptr(16)};
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${ptr(12)};
`;

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`;

const Block = styled.div`
  > :not(:first-child) {
    margin-top: ${ptr(32)};
  }
`;

const DropzoneContainer = styled.div`
  > span {
    display: block;
    margin-bottom: ${ptr(16)};
  }
`;

export {
  Container,
  Wrapper,
  ItemContainer,
  CheckAreaGroupWrapper,
  ItemRow,
  Add,
  HeaderRow,
  Remove,
  Block,
  DropzoneContainer,
};
