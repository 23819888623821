import React, { useState } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Icon } from '../../../../components/Icon';

import * as S from '../InfoForm/styles';
import { TextInput } from '../../../../components/TextInput';
import { DropdownInput } from '../../../../components/DropdownInput';
import { validIdTypes } from '../../../Onboard/constants';
import { ModalWrapper } from '../../../../components/ModalWrapper';
import { ImagePreviewModal } from '../../../Onboarding/components/ImagePreviewModal';
import { Button } from '../../../../components/Button';
import { DropdownInputModal } from '../../../../components/DropdownInputModal';

export interface SimpleFormProps {
  phone?: string;
  idNumber?: string;
  idType?: string;
  bvn?: string;
  email?: string;
  companyName?: string;
  cac?: string;
  businessType?: string;
  onSelectId?: any;
  onChangeId?: any;
  index?: number;
  removeFromIndex?: number;
  emailIsValid?: boolean;
  bvnIsValid?: boolean;
  phoneIsValid?: boolean;
  idNumberIsValid?: boolean;
  companyNameIsValid?: boolean;
  cacIsValid?: boolean;

  remove?: (index: number) => void;
  onChange?: (index: number, property: string, value: string) => void;
  // title?: string;
}

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export const InfoForm5: React.FC<SimpleFormProps> = ({
  companyName,
  cac,
  businessType,
  idType,
  onSelectId,
  onChangeId,
  index,
  removeFromIndex = 0,
  cacIsValid,
  companyNameIsValid,
  remove = () => {},
  onChange = () => {},
}) => {
  const [modalVisible, seModalVisible] = useState<boolean>(false);
  const [modalVisibleCo, setCoModalVisible] = useState<boolean>(false);
  const [modalVisibleIt, setItModalVisible] = useState<boolean>(false);
  const [modalVisibleBn, setBnModalVisible] = useState<boolean>(false);
  const [isCheckeda, setIsCheckeda] = useState(false);
  const [isCheckedb, setIsCheckedb] = useState(false);
  const [isCheckedc, setIsCheckedc] = useState(false);
  const [selected, setSelected] = useState('');
  const [businesType, setBusinessType] = useState('');

  const onCloseModal = () => {
    seModalVisible(false);
  };

  const onCloseModalBT = () => {
    setCoModalVisible(false);
    setItModalVisible(false);
    setBnModalVisible(false);
  };

  const continueHandleBT = async () => {
    onCloseModal();
    setBusinessType(selected);
  };

  const renderModalView = () => (
    <ModalWrapper
      crossColor='#000000'
      onCloseModal={onCloseModal}
      maxWidth='100vw'
      maxHeight='100%'
      showModal={modalVisible}
      title='Select business registration type'
    >
      <ImagePreviewModal
        visible={modalVisibleBn}
        onClose={onCloseModalBT}
        style={{ width: '50vw', height: '70vh' }}
        image='../../images/documents/business-name-new.png'
      />
      <ImagePreviewModal
        visible={modalVisibleIt}
        onClose={onCloseModalBT}
        style={{ width: '50vw', height: '70vh' }}
        image='../../images/documents/incorporated-trustee-new.png'
      />
      <ImagePreviewModal
        visible={modalVisibleCo}
        onClose={onCloseModalBT}
        style={{ width: '50vw', height: '70vh' }}
        image='../../images/documents/registered-corporation-new.png'
      />

      <S.ContainerB>
        <S.ItemRow>
          <S.InnerItem>
            <S.FileOuter
              style={isCheckeda ? { border: '1px solid #B10180' } : {}}
            >
              <S.File
                style={{
                  backgroundImage: `url("../../images/documents/business-name-new.png")`,
                }}
              />

              <S.FileTexts>
                <Text bold size={7} color={colors.black._20}>
                  BN{' '}
                  <Text size={7} color={colors.black._20}>
                    (Business Name )
                  </Text>
                </Text>
              </S.FileTexts>
              <S.FullScreen>
                <Text
                  onClick={() => setBnModalVisible(true)}
                  size={7}
                  color='#B10180'
                >
                  View full image
                </Text>
                <S.Circle
                  onClick={() => {
                    setIsCheckeda(true);
                    setIsCheckedb(false);
                    setIsCheckedc(false);
                    setSelected('BN (Business name)');
                    onChange(index as number, 'businessType', 'bn')
                  }}
                >
                  {isCheckeda && <S.InsideCircle />}
                </S.Circle>
              </S.FullScreen>
            </S.FileOuter>
            <S.FileOuter
              style={isCheckedb ? { border: '1px solid #B10180' } : {}}
            >
              <S.File
                style={{
                  backgroundImage: `url("../../images/documents/incorporated-trustee-new.png")`,
                }}
              />

              <S.FileTexts>
                <Text bold size={7} color={colors.black._20}>
                  RC{' '}
                  <Text size={7} color={colors.black._20}>
                    (Registered company)
                  </Text>
                </Text>
              </S.FileTexts>

              <S.FullScreen>
                <Text
                  onClick={() => setCoModalVisible(true)}
                  size={7}
                  color='#B10180'
                >
                  View full image
                </Text>
                <S.Circle
                  onClick={() => {
                    setIsCheckedb(true);
                    setIsCheckeda(false);
                    setIsCheckedc(false);
                    setSelected('RC (Registered company)');
                    onChange(index as number, 'businessType', 'co')
                   
                  }}
                >
                  {isCheckedb && <S.InsideCircle />}
                </S.Circle>
              </S.FullScreen>
            </S.FileOuter>
            <S.FileOuter
              style={isCheckedc ? { border: '1px solid #B10180' } : {}}
            >
              <S.File
                style={{
                  backgroundImage: `url("../../images/documents/registered-corporation-new.png")`,
                }}
              />
              <S.FileTexts>
                <Text bold size={7} color={colors.black._20}>
                  IT{' '}
                  <Text size={7} color={colors.black._20}>
                    (Incorporated Trustee)
                  </Text>
                </Text>
              </S.FileTexts>
              <S.FullScreen>
                <Text
                  onClick={() => setItModalVisible(true)}
                  size={7}
                  color='#B10180'
                >
                  View full image
                </Text>
                <S.Circle
                  onClick={() => {
                    setIsCheckedc(true);
                    setIsCheckedb(false);
                    setIsCheckeda(false);
                    setSelected('IT (Incorporated Trustee)');
                    onChange(index as number, 'businessType', 'it');

                  }}
                >
                  {isCheckedc && <S.InsideCircle />}
                </S.Circle>
              </S.FullScreen>
            </S.FileOuter>
          </S.InnerItem>
        </S.ItemRow>
        {/* {console.log(selected)} */}
        <S.ButtonGroup style={{ paddingBottom: '20px' }}>
          <div>
            <Button
              label='Continue'
              style={{ background: '#B10180', width: 304 }}
              onClick={continueHandleBT}
            />
          </div>
        </S.ButtonGroup>
      </S.ContainerB>
    </ModalWrapper>
  );
  return (
    <S.ItemContainer>
      {renderModalView()}
      {/* <S.HeaderRow>
      <Text bold color={colors.black._100}>
        {title}
      </Text>
      {index && index > removeFromIndex ? (
        <S.Remove onClick={() => remove(index)}>
          <Icon name='trash' color={colors.pink._100} />
        </S.Remove>
      ) : null}
    </S.HeaderRow> */}
      <S.ItemRow>
        <div style={{ flex: 1 }}>
          <TextInput
            label='Company name'
            type='text'
            value={companyName}
            valid={companyNameIsValid}
            onChange={(value) =>
              onChange(index as number, 'companyName', value)
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          <TextInput
            label='CAC number'
            type='text'
            value={cac}
            valid={cacIsValid}
            onChange={(value) => onChange(index as number, 'cac', value)}
          />
        </div>
      </S.ItemRow>

      <S.ItemRow>
        <div style={{ flex: 1 }}>
          <DropdownInputModal
            label='Business Registration Type'
            icon='dropdown'
            onIconClick={() => seModalVisible(true)}
            type='text'
            value={selected}
            name='cac'
            placeholder='RC (Registered Corporation)'
          />
        </div>
        {/* <div style={{ flex: 1 }}>
          <TextInput
            label='ID Number'
            type='text'
            value={idNumber}
            name='idNumber'
            onChange={(value) => {
              onChange(index as number, 'idNumber', value);
              onChangeId(value);
            }}
            valid={idNumberIsValid}
          />
        </div> */}
      </S.ItemRow>
    </S.ItemContainer>
  );
};
