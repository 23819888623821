import React, { useState, useEffect } from 'react';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import * as S from '../styles';
import { FileUpload } from '../../../components/FileUpload';
import { FileUploadUpdated } from '../../../components/FileUploadUpdated';

export const Signature: React.FC<{ onChangeFile?: any }> = ({
  onChangeFile,
}) => {
  const [filetoUpload, setfiletoUpload] = useState('');
  const onChangeValue = (value: any) => {
    if (onChangeFile) {
      onChangeFile(value);
    }

    setfiletoUpload(value);
  };
  return (
    <S.TextDrag>
      {/* <div style={{ width: '35px', height: '35px' }}>
        <Icon onClick={open} name='cloud-upload-small' width={35} height={35} />
      </div>

      <div style={{ textAlign: 'center' }}>
        <Text bold size={8} color='#000000'>
          Drag and drop or{'  '}
          <Text bold size={8} color='#B10180'>
            Browse{' '}
          </Text>
          file
        </Text>
      </div>

      <div>
        <Text size={8} color={colors.black._80}>
          Accepted file format: *.jpeg, *.png or *.pdf only
        </Text>
      </div> */}

      <FileUploadUpdated
        // acceptedFormats={['image/jpg', 'image/jpeg']}
        onChange={(file) => onChangeValue(file)}
      />
    </S.TextDrag>
  );
};
