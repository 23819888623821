import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import Papa from 'papaparse';

export const downloadPDF = (
  id: string,
  buttonId: string,
  fileName?: string,
  headerId?: string
): void => {
  const button = document.getElementById(buttonId);
  let header: HTMLElement | null;
  if (button) button.style.display = 'none';
  if (headerId) {
    header = document.getElementById(headerId);
    if (header) header.style.position = 'relative';
    if (header) header.style.top = '0';
  }

  const html = document.getElementById(id);
  if (html) {
    html2canvas(html).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0, 210, 0);
      pdf.save(`${fileName}.pdf`);
      if (button) button.style.display = 'flex';
      if (header) header.style.position = 'sticky';
      if (header) header.style.top = '40px';
    });
  }
};

export const downloadCSV = (
  data: { [key: string]: any }[],
  name: string,
  fields: string[]
): void => {
  const csv = Papa.unparse({ fields, data });
  const blob = new Blob([csv]);
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = `${name}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadFile = async (url: string, filename = 'download') => {
  try {
    const response = await fetch(url, { method: 'GET' });
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Failed to download file:', error);
  }
};
