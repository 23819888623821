import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';

// #region Onboarding imports
import ErrorOnboarding from './subaccount.error';
import {
  CreateSubAccountBody,
  CreateSubAccountResponse,
  IApproveConsent,
  IApproveConsentResponse,
  ICreateSubAccountData,
  IGetRequest,
  IVerifyConsent,
  IVerifyConsentResponse,
  OtpSubaccountResponseInterface, ValidateOtpBody, ValidateOtpResponse
} from './ISubaccount';
// #endregion

import debug from '../debbug.service';
import getQueryString from '../../../utils/getQueryString';

class SubaccountService {
  private apiEndpoints = {
    subaccount: {
      requestOtp: 'subaccount/request-otp',
      validateOtp: 'subaccount/validate-otp',
      createSubAccount: 'subaccount',
      uploadBoardResolution: 'subaccount/upload-boardresolution',
      leaderConsent: 'subaccount/leader-consent',
      confirm: 'subaccount/confirm',
      verifyConsent: 'subaccount/verify-consent',
      approveConsent: 'subaccount/approve-consent',
    },
  };

  /**
   * A wrapper for POST Form requests for onboarding flow
   * @param data - FormData type
   * @param url
   * @returns F type
   */
  private static async requestMultiPartWrapper<F>(data: FormData, url: string): Promise<F> {
    try {
      const request = await apiService.request.post(url, {
        body: data,
      });
      return await request.json() as F;
    } catch (globalError: any) {
      debug.error(globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(new ErrorApi({
            message: result.message,
            code: result.code || result.status || 500,
          }));
        }
        return Promise.reject(new ErrorApi(ErrorOnboarding.Fatal));
      } catch (localError: any) {
        debug.error(localError);
        throw new ErrorApi(ErrorOnboarding.Fatal);
      }
    }
  }

  /**
   * A wrapper for POST requests for onboarding flow
   * @param data - T type
   * @param url
   * @returns F type
   */
  private static async requestPostWrapper<T, F>(data: T, url: string): Promise<F> {
    try {
      const request = await apiService.request.post(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await request.json() as F;
    } catch (globalError: any) {
      debug.error(globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          if (result && result.message) {
            throw new ErrorApi({
              message: result.message,
              code: result.code || result.status || 500,
            });
          } else {
            throw new ErrorApi(ErrorOnboarding.Fatal);
          }
        } else {
          throw new ErrorApi(ErrorOnboarding.Fatal);
        }
      } catch (localError: any) {
        debug.error(localError);
        throw localError;
      }
    }
  }

  /**
   *  A wrapper for GET requests for onboarding flow
   * @param data - T type
   * @param url
   * @returns F type
   */
  private static async requestGetWrapper<T, F>(
    data: T,
    url: string
  ): Promise<F> {

    const queryString = getQueryString(data as any);
    try {
      const request = await apiService.request.get(
        url + queryString,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return (await request.json()) as F;
    } catch (globalError: any) {
      debug.error(globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          if (result && result.message) {
            throw new ErrorApi({
              message: result.message,
              code: result.code || result.status || 500,
            });
          } else {
            throw new ErrorApi(ErrorOnboarding.Fatal);
          }
        } else {
          throw new ErrorApi(ErrorOnboarding.Fatal);
        }
      } catch (localError: any) {
        debug.error(localError);
        throw localError;
      }
    }
  }


  public async requestOtpForSubaccount(
    businessId: string,
  ): Promise<OtpSubaccountResponseInterface> {
    return SubaccountService.requestGetWrapper<
      { businessId: string },
      OtpSubaccountResponseInterface
    >(
      { businessId },
      this.apiEndpoints.subaccount.requestOtp,
    );
  }

  public async validateOtpForSubaccount(
    data: ValidateOtpBody,
  ): Promise<ValidateOtpResponse> {
    return SubaccountService.requestPostWrapper<
      ValidateOtpBody,
      ValidateOtpResponse
    >(
      data,
      this.apiEndpoints.subaccount.validateOtp,
    );
  }

  public async createSubaccount(
    data: CreateSubAccountBody,
  ): Promise<CreateSubAccountResponse> {
    return SubaccountService.requestPostWrapper<
      CreateSubAccountBody,
      CreateSubAccountResponse
    >(
      data,
      this.apiEndpoints.subaccount.createSubAccount,
    );
  }

  public async uploadBoardResolution(
    data: FormData,
  ): Promise<CreateSubAccountResponse> {
    return SubaccountService.requestMultiPartWrapper<
      CreateSubAccountResponse
    >(
      data,
      this.apiEndpoints.subaccount.uploadBoardResolution,
    );
  }

  public async createConsent(
    data: ICreateSubAccountData,
  ): Promise<CreateSubAccountResponse> {
    return SubaccountService.requestPostWrapper<
      ICreateSubAccountData,
      CreateSubAccountResponse

    >(
      data,
      this.apiEndpoints.subaccount.leaderConsent,
    );
  }

  public async getRequest(
    data: IGetRequest,
  ): Promise<ValidateOtpResponse> {
    return SubaccountService.requestGetWrapper<
      IGetRequest,
      ValidateOtpResponse
    >(
      data,
      this.apiEndpoints.subaccount.createSubAccount,
    );
  }

  public async confirmRequest(
    data: IGetRequest,
  ): Promise<CreateSubAccountResponse> {
    return SubaccountService.requestPostWrapper<
      IGetRequest,
      CreateSubAccountResponse

    >(
      data,
      this.apiEndpoints.subaccount.confirm,
    );
  }

  public async verifyConsent(
    data: IVerifyConsent,
  ): Promise<IVerifyConsentResponse> {
    return SubaccountService.requestPostWrapper<
      IVerifyConsent,
      IVerifyConsentResponse

    >(
      data,
      this.apiEndpoints.subaccount.verifyConsent,
    );
  }

  public async approveConsent(
    data: FormData,
  ): Promise<IApproveConsentResponse> {
    return SubaccountService.requestMultiPartWrapper<
      IApproveConsentResponse
    >(
      data,
      this.apiEndpoints.subaccount.approveConsent,
    );
  }

}



const subaccountService = new SubaccountService();
export default subaccountService;
