import React from 'react';
import { Button } from '../../../../components/Button';
import { FormTitle } from '../../../../components/FormTitle';
import { ContentContainer } from '../../../../styles/wrappers';

import * as S from './styles';

export interface RequestSuccessProps {
  /**
   * handler on click continue
   */
  onContinue?: () => void,
}

export const RequestSuccess: React.FC<RequestSuccessProps> = ({ onContinue = () => {} }) => (
  <S.Wrapper>
    <FormTitle
      title="Request sent successfully!"
      description="After the OTP submission, you will receive an email with a link to create password in order to complete activation (link expires in 24 hours)."
    />
    <ContentContainer flex={1} justifyContent="flex-start" alignItems="center">
      <Button onClick={onContinue} label="Got it" />
    </ContentContainer>
  </S.Wrapper>
);
