/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import { BackBar } from '../../components/BackBar';
import { Button } from '../../components/Button';
import { DropdownOptionProps } from '../../components/DropdownInput';
import { EventPanel } from '../../components/EventPanel';
import LimitInitiationModal from '../../components/LimitModals';
import LimitProgress from '../../components/LimitProgress';
import { Link } from '../../components/Link';
import { Modal as LoadingModal } from '../../components/LoadingModal';
import { ModalWrapper } from '../../components/ModalWrapper';
import { ConfirmPayment } from '../../components/PaymentConfirms/ConfirmPayment';
import { PinAuth } from '../../components/PaymentConfirms/PinAuth';
import { SelectBeneficiariesModal } from '../../components/SelectBeneficiariesModal';
import { IBeneficiary } from '../../components/SelectBeneficiaryType';
import { Modal } from '../../components/SimpleModal';
import { Switch } from '../../components/Switch';
import { Text } from '../../components/Text';
import { TextInput } from '../../components/TextInput';
import { failed, success } from '../../components/Toasts';
import { UploadFileModal } from '../../components/UploadFileModal';
import { RootState } from '../../redux';
import BeneficiaryInterface from '../../redux/beneficiary.management/IBeneficiary';
import BeneficaryActions from '../../redux/beneficiary.management/action';
import { PaymentInterface } from '../../redux/payment/IPayment';
import { PaymentActions } from '../../redux/payment/actions';
import beneficiaryService from '../../services/internal/beneficiary/beneficiary.service';
import debug from '../../services/internal/debbug.service';
import paymentsService from '../../services/internal/payments/payments.service';
import { colors } from '../../styles/variables';
import { PageTitle } from '../../styles/wrappers';
import {
  validateArrayOfObjects,
  ValidateArrayResult,
} from '../../utils/validation';
import { onBulkTransactionUpload } from '../BeneficiariesManagement/action';
import { IBeneficiaries } from '../BeneficiariesManagement/view';
import { OtherBankPaymentDetails } from './components/PaymentDetails';
import * as S from './styles';

export interface IAmount {
  pa: string;
  value: string;
}

interface IAmountChange {
  value: string;
  fieldName: string;
}

interface IDestinationAccount {
  response: any;
  value: string;
}

export interface PaymentDetails {
  paymentAccount?: DropdownOptionProps;
  amount?: IAmount;
  destinationBank?: DropdownOptionProps;
  destinationAccount?: IDestinationAccount;
  comment?: string;
  batch?: string;
  beneficiaryTypes: {
    saveBeneficiary?: boolean;
    types: Array<IBeneficiary>;
  };
  saved?: boolean;
}

export interface BankAccount {
  currency?: string;
  money?: number;
}

export interface BankNames {
  nipCode?: number;
  name?: string;
  cbnCode?: string | number | null;
  createdAt?: string;
  id?: string;
  logoUrl?: string | null;
  updatedAt?: string;
  check?: boolean;
}
export interface OtherBankTransferProps {
  /**
   * Example page property
   */
  example?: string;
}

const defaultPaymentDetails: PaymentDetails = {
  paymentAccount: {
    label: '',
    value: '',
    category: undefined,
    subLabel: '',
    key: '',
  },
  amount: {
    pa: '',
    value: '',
  },
  destinationAccount: {
    response: {},
    value: '',
  },
  destinationBank: {
    label: '',
    value: '',
    category: undefined,
    subLabel: '',
    key: '',
  },
  comment: '',
  beneficiaryTypes: {
    saveBeneficiary: false,
    types: [],
  },
};

export const OtherBankTransfer: React.FC<OtherBankTransferProps> = () => {
  const dispatch = useDispatch();
  const [paymentsDetails, setPaymentsDetails] = useState<Array<PaymentDetails>>(
    [defaultPaymentDetails]
  );

  const [validation, setValidation] = useState<ValidateArrayResult | undefined>(
    undefined
  );
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState<boolean>(
    false
  );
  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);

  // const [paymentsAccount, setPaymentsAccount] = useState<
  //   Array<PaymementAccountInterface>
  // >([]);

  const [bankNames, setBankNames] = useState<Array<BankNames>>([]);
  const [beneficiariesTypes, setBeneficiariesTypes] = useState<
    Array<IBeneficiary>
  >([]);

  const [file, setFile] = useState<File & { mimetype?: string }>();
  const [uploadingFileLoader, setUploadingFileLoader] = useState<boolean>(
    false
  );

  const [bankNamesLoading, setBankNamesLoading] = useState<boolean>(false);
  const [paymentAccountsLoading, setPaymentAccountsLoading] = useState<boolean>(
    false
  );
  const [showAuthModal, setshowAuthModal] = useState<boolean>(false);
  const [currentView, setcurrentView] = useState<string>('confirmPage');
  const [bulk, setBulk] = useState(false);
  const [showBulkSaveModal, setShowBulkSaveModal] = useState(false);
  const [tempSingleBulk, setTempSingleBulk] = useState<{
    index: number;
    name: string;
    value: Array<IBeneficiaries>;
  } | null>();
  const beneficiaryTransfers = useSelector(
    (state: { beneficiaryManagement: BeneficiaryInterface }) =>
      state.beneficiaryManagement.transfer as Array<IBeneficiaries>
  );
  const [errors, setErrors] = useState<any[]>([]);
  const [addBatch, setAddBatch] = useState(false);
  const [checkSwitch, setCheckSwitch] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [continueWithoutLimitupdate, setContinueWithoutLimitupdate] = useState(
    false
  );
  const preference = useSelector((store: RootState) => store.preference);

  const validating = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.validating
  );
  const paymentsAccount = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.paymentAccounts
  );
  const limitInfo = useSelector(
    (state: { payment: PaymentInterface }) => state.payment.limitInfo
  );
  // const [limitInfo, setLimitInfo] = useState<ILimitInfoResponse['data']>();
  const [limitInfoLoading, setLimitInfoLoading] = useState(true);

  useEffect(() => {
    if (!validating && paymentsDetails.length > 1) {
      setErrors(paymentsService.accountValidationErrors);
    }
  }, [paymentsService.accountValidationErrors, validating]);

  useEffect(() => {
    if (paymentsAccount.length) {
      getLimitInfo();
    }
  }, [paymentsAccount]);

  const history = useHistory();

  const SCHEME = {
    paymentAccount: () => true,
    destinationAccount: (data: IDestinationAccount) => {
      if (data?.response.code === 'success') {
        return true;
      }
      return false;
    },
    amount: (data: IAmount) => {
      const maxValue = Number(data.pa?.replace(/[\s,₦%]/g, ''));
      if (
        Number(data.value.replace(/[\s,₦%]/g, '')) > maxValue ||
        totalAmount > maxValue
      ) {
        return false;
      }
      return true;
    },
    destinationBank: () => true,
    comment: () => true,
    beneficiaryTypes: (data: {
      saveBeneficiary?: boolean;
      types: Array<IBeneficiary>;
    }) => {
      if (data.saveBeneficiary) {
        return data.types.length > 0;
      }
      return true;
    },
    saved: () => true,
  };

  const toggleLimitModal = () => setShowLimitModal((prev) => !prev);

  const handleAddNewBeneficiary = () => {
    setPaymentsDetails((old) => [...old, defaultPaymentDetails]);
  };
  // const handleRemoveBeneficiary = (index: number) => {
  //   const oldDetails = paymentsDetails;
  //   debug.info('before remove', oldDetails);

  //   if (index > -1) {
  //     oldDetails.splice(index, 1);
  //   }
  //   debug.info('remove', oldDetails);
  // };

  const renderBulkPaymentDetails = (value: Array<IBeneficiaries>) =>
    setPaymentsDetails([
      ...value.map((el) => ({
        ...defaultPaymentDetails,
        amount: {
          pa: '',
          value: String(el.defaultAmount),
        },
        destinationAccount: {
          response: {},
          value: el.nuban,
        },
        destinationBank: {
          label: el.bank.name,
          key: `${el.bank.nipCode}`,
        },
        beneficiaryTypes: {
          saveBeneficiary: false,
          types: [],
        },
        comment: el.comment,
        saved: false,
      })),
    ]);

  const getBankNames = async (): Promise<any> => {
    setBankNamesLoading(true);
    try {
      const result = await paymentsService.getBankNames();
      setBankNames(result);
      setBankNamesLoading(false);
      return result;
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error retrieving bank names');
      return null;
    }
  };

  const onAddFile = async () => {
    setUploadFileModal(false);
    if (file) {
      const beneficiaries = await onBulkTransactionUpload(file);
      const banks = await getBankNames();
      setBulk(true);
      const values = beneficiaries.map((bene) => ({
        name: '',
        tags: { id: '', name: '', slug: '' },
        bank: {
          nipCode: bene.bankCode,
          name: banks.filter((bank: any) => bank.nipCode === bene.bankCode)[0]
            ?.name,
        },
        id: 0,
        nuban: bene.accountNumber,
        defaultAmount: bene.amount,
        comment: bene.narration || '',
      }));
      renderBulkPaymentDetails(values);

      if (beneficiaries && beneficiaries.length) {
        success('Sucess', 'Beneficiaries Uploaded.');
        setCheckSwitch(true);
        setAddBatch(true);
      }
    }
  };

  const getPaymentAccounts = async (): Promise<void> => {
    setPaymentAccountsLoading(true);
    try {
      const result = await paymentsService.getPaymentAccounts();
      // setPaymentsAccount(result);
      dispatch(PaymentActions.savePaymentAccounts(result));
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error retrieving payments accounts');
    }
    setPaymentAccountsLoading(false);
  };

  const getBeneficiaryTypes = async (): Promise<void> => {
    try {
      const result = await beneficiaryService.getBeneficiaryTypes();
      setBeneficiariesTypes(result);
    } catch (err) {
      debug.error('Error retrieving bank names', err);
    }
  };

  const getAllDropdownData = async () => {
    getBankNames();
    getPaymentAccounts();
    getBeneficiaryTypes();
  };

  const onRemove = (index: number) => {
    const newValues = [];
    for (let i = 0; i < paymentsDetails.length; i += 1) {
      if (i !== index) {
        newValues.push(paymentsDetails[i]);
      }
    }
    dispatch(
      BeneficaryActions.removeSavedBeneficiary(
        paymentsDetails[index].destinationAccount?.value
      )
    );

    setPaymentsDetails(newValues);
  };

  let timeout: NodeJS.Timeout;

  const onChangeAmount = (index: number, value: string, fieldName: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setPaymentsDetails(
        (old) =>
          old.map((el: PaymentDetails, i: number) => {
            if (!bulk) {
              if (i === index) {
                return {
                  ...el,
                  amount: { ...el.amount, [fieldName]: value },
                };
              }
            } else if (bulk && fieldName === 'pa') {
              return {
                ...el,
                amount: { ...el.amount, [fieldName]: value },
              };
            } else if (i === index) {
              return {
                ...el,
                amount: { ...el.amount, [fieldName]: value },
              };
            }

            return el;
          }) as Array<PaymentDetails>
      );
    }, 300);
  };
  const hideBulkModal = () => {
    setShowBulkSaveModal(false);
    setTempSingleBulk(null);
  };

  const showBulkModal = (data?: any) => {
    setShowBulkSaveModal(true);
    if (data) setTempSingleBulk(data);
  };

  const changeBeneficiaryType = (
    index: number,
    name: string,
    value: Array<IBeneficiaries>
  ) => {
    if (
      index === 0 &&
      bulk &&
      !showBulkSaveModal &&
      paymentsDetails.length > 1
    ) {
      // Only show the modal if the item is the first item
      showBulkModal({ index, name, value });
      return;
    }

    setPaymentsDetails(
      (old) =>
        old.map((el: PaymentDetails, i: number) => {
          if (i === index) {
            return {
              ...el,
              beneficiaryTypes: {
                ...el.beneficiaryTypes,
                [name]: value,
              },
            };
          }
          return el;
        }) as Array<PaymentDetails>
    );
  };

  // const continueWithATransaction = () => {
  //   if (tempSingleBulk) {
  //     hideBulkModal();
  //     changeBeneficiaryType(tempSingleBulk.index, tempSingleBulk.name, tempSingleBulk.value);
  //   }
  // };

  const changeBulkBeneficiaryType = () => {
    hideBulkModal();
    if (tempSingleBulk) {
      setPaymentsDetails(
        (old) =>
          old.map((el: PaymentDetails) => ({
            ...el,
            beneficiaryTypes: {
              ...el.beneficiaryTypes,
              [tempSingleBulk.name]: tempSingleBulk.value,
            },
          })) as Array<PaymentDetails>
      );
    }
  };

  const onChangePaymentDetails = (
    index: number,
    fieldName: string | any,
    value: string
  ) => {
    setPaymentsDetails((old) =>
      old.map((el, i) => {
        if (!bulk) {
          if (i === index) {
            return {
              ...el,
              [fieldName]: value,
            };
          }
        } else if (bulk && fieldName === 'paymentAccount') {
          return {
            ...el,
            [fieldName]: value,
          };
        } else if (i === index) {
          return {
            ...el,
            [fieldName]: value,
          };
        }

        return el;
      })
    );
  };

  const onChangeDestinationAccount = (
    index: number,
    fieldName: string,
    value: any
  ) => {
    if (
      (typeof value === 'string' && value?.length === 10) ||
      (typeof value === 'object' && value?.nuban?.length === 10)
    ) {
      setPaymentsDetails(
        (old) =>
          old.map((el: PaymentDetails, i: number) => {
            if (i === index) {
              return {
                ...el,
                destinationAccount: {
                  ...el.destinationAccount,
                  [fieldName]: value,
                },
              };
            }
            return el;
          }) as Array<PaymentDetails>
      );
    }
    // else {
    //   setPaymentsDetails(
    //     (old) =>
    //       old.map((el: PaymentDetails, i: number) => {
    //         if (i === index) {
    //           return {
    //             ...el,
    //             destinationAccount: {
    //               ...el.destinationAccount,
    //               [fieldName]: value,
    //             },
    //           };
    //         }
    //         return el;
    //       }) as Array<PaymentDetails>
    //   );
    // }
  };

  const renderPaymentDetails = (value: Array<IBeneficiaries>) =>
    setPaymentsDetails([
      ...value.map((el) => ({
        ...defaultPaymentDetails,
        amount: {
          pa: '',
          value: String(el.defaultAmount),
        },
        destinationAccount: {
          response: {},
          value: el.nuban,
        },
        destinationBank: {
          label: el.bank.name,
          key: `${el.bank.nipCode}`,
        },
        beneficiaryTypes: {
          saveBeneficiary: false,
          types: [],
        },
        saved: true,
        beneficiaryId: el.id,
      })),
    ]);

  useEffect(() => {
    getAllDropdownData();
    renderPaymentDetails(beneficiaryTransfers);
    const validateResult = validateArrayOfObjects(
      [defaultPaymentDetails],
      SCHEME
    );
    setValidation(validateResult);
  }, []);

  useEffect(() => {
    if (
      paymentsDetails.length &&
      !paymentsDetails.some(
        (detail) => detail.amount && detail.amount.pa === ''
      )
    ) {
      const validateResult = validateArrayOfObjects(paymentsDetails, SCHEME);
      setValidation(validateResult);
      if (paymentsDetails.length) {
        let totalAmountValue = 0;
        paymentsDetails.forEach((detail) => {
          if (
            detail.amount &&
            detail.amount.value !== '' &&
            detail.amount.pa !== ''
          ) {
            if (
              detail.destinationBank &&
              detail.destinationBank.key !== '999999'
            ) {
              totalAmountValue +=
                Number(detail.amount.value.replace(/[\s,₦%]/g, '')) +
                Number(preference.withdrawalFee);
            } else {
              totalAmountValue += Number(
                detail.amount.value.replace(/[\s,₦%]/g, '')
              );
            }
          }
        });
        setTotalAmount(totalAmountValue);
      }
    }
  }, [paymentsDetails]);

  useEffect(() => {
    if (
      paymentsDetails.length &&
      !paymentsDetails.some(
        (detail) => detail.amount && detail.amount.pa === ''
      )
    ) {
      if (totalAmount) {
        const validateResult = validateArrayOfObjects(paymentsDetails, SCHEME);
        setValidation(validateResult);
      }
    }
  }, [totalAmount]);
  const closeModalView = () => {
    setshowAuthModal(false);
    setBulk(false);
    setTimeout(() => {
      history.push('/dashboard');
    }, 1000);
  };

  const closeErrorModal = () => {
    setErrors([]);
    paymentsService.clearError();
  };

  const getLimitInfo = async (): Promise<void> => {
    setLimitInfoLoading(true);
    try {
      if (!paymentsAccount?.length) return;
      dispatch(
        PaymentActions.getLimitInfo(
          paymentsAccount[0].nuban,
          failed,
          setLimitInfoLoading
        )
      );
    } catch (error) {
      const err = error as Error;
      failed('Failed', err.message || 'Error retrieving limit information');
      setLimitInfoLoading(false);
    }
  };

  const makeTransfer = () => {
    const totalAmount = paymentsDetails.reduce((acc, payment) => {
      return (acc += Number(payment.amount?.value.replace(/[\s,₦%]/g, '')));
    }, 0);

    let remainingLimit = limitInfo?.dailyWithdrawLimitBalance ?? 0;
    if (totalAmount <= remainingLimit && continueWithoutLimitupdate) {
      setshowAuthModal(true);
      paymentsService.clearError();

      return;
    }
    if (totalAmount > remainingLimit || remainingLimit <= 500000) {
      toggleLimitModal();
      dispatch(PaymentActions.clearSavedLimitRequest());
      return;
    }

    setshowAuthModal(true);
    paymentsService.clearError();
  };

  const renderModalRecord = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={showAuthModal}
      onCloseModal={
        currentView === 'confirmPage'
          ? () => setshowAuthModal(false)
          : () => closeModalView()
      }
      crossColor={colors.black._100}
      closeBackground={currentView === 'confirmPage' ? colors.pink._100 : ''}
      // onBottom={handleContainerOnBottom}
    >
      {currentView === 'confirmPage' ? (
        <ConfirmPayment
          beneficiaries={paymentsDetails}
          proceedHandler={() => {
            setcurrentView('pinauth');
          }}
          handleRemoveBeneficiary={onRemove}
          editBeneficiaryHandler={() => setshowAuthModal(false)}
        />
      ) : (
        <PinAuth paymentsDetails={paymentsDetails} batchName={batchName} />
      )}
    </ModalWrapper>
  );

  const renderValidationErrorsModal = () => (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={!!(!validating && errors.length && paymentsDetails.length > 1)}
      onCloseModal={() => closeErrorModal()}
      crossColor={colors.black._100}
      closeBackground={colors.pink._100}
    >
      <S.ErrorWrapper>
        <div style={{ marginBottom: 20 }}>
          <Text color={colors.pink._80} size={4}>
            Failed account validation
          </Text>
        </div>
        {paymentsService.accountValidationErrors &&
          paymentsService.accountValidationErrors.map((err, index) => (
            <S.ErrorTextContainer key={index.toString()}>
              <S.Text flex={1}>
                <Text color={colors.black._100}>{index + 1}</Text>
              </S.Text>
              <S.Text flex={2}>
                <Text color={colors.black._60}>{err.accountNumber}</Text>
              </S.Text>
              <S.Text flex={2}>
                <Text color={colors.black._60}>{err.bankName}</Text>
              </S.Text>
            </S.ErrorTextContainer>
          ))}
      </S.ErrorWrapper>
    </ModalWrapper>
  );

  return (
    <div>
      <AccessControl isPage requiredPermission={[EPermissions.CREATE_PAYMENT]}>
        {renderModalRecord()}
        <SelectBeneficiariesModal
          showModal={showBeneficiaryModal}
          bankNames={bankNames}
          beneficiariesTypes={beneficiariesTypes}
          setBeneficiariesTypes={setBeneficiariesTypes}
          onCloseModal={() => setShowBeneficiaryModal(false)}
          selectBeneficiaries={(value) => {
            setShowBeneficiaryModal(false);
            renderPaymentDetails(value);
          }}
        />
        <UploadFileModal
          visible={uploadFileModal}
          onCloseModal={() => setUploadFileModal(false)}
          onAdd={onAddFile}
          setFile={setFile}
          file={file}
          loading={uploadingFileLoader}
          bulkTransfer
        />
        <PageTitle.Wrapper>
          <BackBar name="Transfer to other accounts" />
        </PageTitle.Wrapper>
        <S.PanelsWrapper>
          <S.PanelWrapper>
            <EventPanel
              onClick={() => setShowBeneficiaryModal(true)}
              icon={{ name: 'users' }}
              title="Saved Beneficiaries"
              description="Select Beneficiaries"
            />
          </S.PanelWrapper>
          <S.PanelWrapper>
            <EventPanel
              onClick={() => setUploadFileModal(true)}
              icon={{ name: 'paper' }}
              title="Upload File"
              description="Select file to be uploaded."
            />
          </S.PanelWrapper>
        </S.PanelsWrapper>

        <S.Batch>
          <S.SwitchContainer>
            <Switch
              checked={checkSwitch}
              onClick={(isCheckedd) => {
                setAddBatch(isCheckedd);
                if (!isCheckedd) {
                  setAddBatch(false);
                }
              }}
              text="Set as a Batch"
            />
          </S.SwitchContainer>
          {addBatch && (
            <TextInput
              label="Batch name"
              onChange={(value: string) => {
                setBatchName(value);
              }}
              value={batchName}
            />
          )}
        </S.Batch>

        <div style={{ marginBottom: 30 }}>
          {/* {limitInfo && ( */}
          <LimitProgress
            usedLimit={
              limitInfo?.dailyWithdrawLimitCap &&
              limitInfo.dailyWithdrawLimitBalance
                ? limitInfo?.dailyWithdrawLimitCap -
                  limitInfo?.dailyWithdrawLimitBalance
                : 0
            }
            availableLimit={limitInfo?.dailyWithdrawLimitBalance ?? 0}
            totalLimit={limitInfo?.dailyWithdrawLimitCap ?? 0}
            loading={limitInfoLoading}
          />
          {/* )} */}
        </div>

        {paymentsDetails.map((data, index) => (
          <OtherBankPaymentDetails
            key={`${index.toString()}`}
            index={index}
            onRemove={onRemove}
            onChangeAmount={(item: IAmountChange) => {
              onChangeAmount(index, item.value, item.fieldName);
            }}
            bankNamesLoading={bankNamesLoading}
            paymentAccountsLoading={paymentAccountsLoading}
            bankNames={bankNames}
            paymentsAccounts={paymentsAccount}
            data={data}
            setBeneficiaryTypes={(name: string, value: any) =>
              changeBeneficiaryType(index, name, value)
            }
            setBeneficiariesTypes={setBeneficiariesTypes}
            beneficiariesTypes={beneficiariesTypes}
            validation={validation ? validation.data[index] : null}
            onChangeDestinationAccount={onChangeDestinationAccount}
            onChangeData={(fieldName: string, value: string) =>
              onChangePaymentDetails(index, fieldName, value)
            }
          />
        ))}
        <S.FooterWrapper>
          <S.FooterInnerWrapper>
            <Link
              icon="plus-in-circle"
              text="Add New Beneficiary"
              disabled={
                bankNamesLoading || paymentAccountsLoading || !limitInfo
              }
              onClick={handleAddNewBeneficiary}
            />
          </S.FooterInnerWrapper>

          {/* <Button disabled={validation ? !validation?.isValid : true} label="Make a transfer" onClick={makeTransfer} /> */}
          <Button
            disabled={
              (addBatch && !batchName) ||
              !validation?.isValid ||
              !paymentsDetails.length ||
              paymentsDetails.some((d) => d.amount?.value === '')
            }
            label="Make a transfer"
            onClick={() => {
              makeTransfer();
            }}
          />
        </S.FooterWrapper>
        <Modal
          visible={showBulkSaveModal}
          title="Save beneficiaries"
          showRoundButton={false}
          description="Do you want to update for all transactions or just for this transaction?"
          onCloseModal={hideBulkModal}
          action={changeBulkBeneficiaryType}
          buttonTitle="All"
          secondButtonTitle="This Transaction"
          prompt
        />
        <LoadingModal
          visible={validating}
          title="Please wait while we validate account number"
        />
        {renderValidationErrorsModal()}
      </AccessControl>

      <LimitInitiationModal
        show={showLimitModal}
        toggle={toggleLimitModal}
        fromRoute={location.pathname}
        usedLimit={
          limitInfo?.dailyWithdrawLimitCap &&
          limitInfo.dailyWithdrawLimitBalance
            ? limitInfo?.dailyWithdrawLimitCap -
              limitInfo?.dailyWithdrawLimitBalance
            : 0
        }
        availableLimit={limitInfo?.dailyWithdrawLimitBalance ?? 0}
        totalLimit={limitInfo?.dailyWithdrawLimitCap ?? 0}
        setContinue={setContinueWithoutLimitupdate}
      />
    </div>
  );
};
