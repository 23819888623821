import * as S from './styles';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Title } from '../../Onboarding/components/Title';
import { colors } from '../../../styles/variables';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { OnboardingSummary } from '../../../services/internal/onboarding/IOnboarding';
import { actions, otherActions } from '../config';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { BusinessTypesEnum, SIGN_UP } from '../constants';
import Terms from '../../../assets/documents/VBiz Terms of Use & Privacy Policy.pdf';
import { OnboardingCard } from '../../../components/OnboardingCard';
import PreviewDetails from './component/previewDetails';
import { TextInput } from '../../../components/TextInput';
import { Icon } from '../../../components/Icon';
import { SubaccountInterface } from '../../../redux/subaccount/ISubaccount';
import { CompanyInterface } from '../../../redux/company/ICompany';
import { failed } from '../../../components/Toasts';
import subaccountService from '../../../services/internal/subaccount/subaccount.service';
import { SubaccountActions } from '../../../redux/subaccount/actions';

export const CommonSubPreviewDetails: React.FC = () => {
                                                         const dispatch = useDispatch();
                                                         const route = useHistory();

                                                         const subaccountRequest = useSelector(
                                                           (state: {
                                                             subaccount: SubaccountInterface;
                                                           }) =>
                                                             state.subaccount
                                                               .subaccountRequest
                                                         );

                                                         const activeCompany = useSelector(
                                                           (state: {
                                                             company: CompanyInterface;
                                                           }) =>
                                                             state.company
                                                               .active
                                                         );

                                                         const businessType =
                                                           activeCompany
                                                             ?.accounts[0]
                                                             ?.type;

                                                         const leaders =
                                                           subaccountRequest
                                                             ?.business
                                                             ?.BusinessLeader ??
                                                           [];

                                                         const [
                                                           checked,
                                                           setChecked,
                                                         ] = useState(false);
                                                         const [
                                                           loading,
                                                           setLoading,
                                                         ] = useState(false);

                                                         useEffect(() => {
                                                           if (
                                                             subaccountRequest
                                                           ) {
                                                             getRequest();
                                                           }
                                                         }, []);

                                                         const getRequest = async () => {
                                                           try {
                                                             // get updated request and display details
                                                             if (
                                                               subaccountRequest
                                                             ) {
                                                               const response = await subaccountService.getRequest(
                                                                 {
                                                                   requestId:
                                                                     subaccountRequest.id,
                                                                 }
                                                               );
                                                               dispatch(
                                                                 SubaccountActions.handleSetSubaccountRequest(
                                                                   response
                                                                 )
                                                               );
                                                             }
                                                           } catch (error) {
                                                             const err = error as any;
                                                             failed(
                                                               'failed',
                                                               err.message
                                                             );
                                                           }
                                                         };

                                                         const continueHandle = async () => {
                                                           setLoading(true);
                                                           try {
                                                             if (
                                                               subaccountRequest?.id
                                                             ) {
                                                               await subaccountService.confirmRequest(
                                                                 {
                                                                   requestId:
                                                                     subaccountRequest.id,
                                                                 }
                                                               );
                                                               dispatch(
                                                                 SubaccountActions.handleClearSubaccountRequest()
                                                               );
                                                               seModalVisible(
                                                                 true
                                                               );
                                                             }
                                                           } catch (err) {
                                                             const error = err as any;
                                                             failed(
                                                               'Failed',
                                                               error.message
                                                             );
                                                           }

                                                           setLoading(false);
                                                         };

                                                         const [
                                                           modalVisible,
                                                           seModalVisible,
                                                         ] = useState<boolean>(
                                                           false
                                                         );
                                                         const onCloseModal = () => {
                                                           seModalVisible(
                                                             false
                                                           );
                                                         };

                                                         return (
                                                           <S.Container>
                                                             <PreviewDetails
                                                               visible={
                                                                 modalVisible
                                                               }
                                                               onClose={
                                                                 onCloseModal
                                                               }
                                                             />
                                                             <S.TopText>
                                                               <Title
                                                                 color={
                                                                   colors.black
                                                                     ._100
                                                                 }
                                                                 title='Summary and Preview Page.'
                                                                 subTitle='Review and confirm details of all the information you provided.'
                                                               />
                                                             </S.TopText>

                                                             <OnboardingCard>
                                                               <S.CompanyInfoWrapper>
                                                                 <Text
                                                                   bold
                                                                   color={
                                                                     colors
                                                                       .black
                                                                       ._20
                                                                   }
                                                                 >
                                                                   Business
                                                                   Information
                                                                 </Text>
                                                                 <S.CustomTable>
                                                                   {subaccountRequest &&
                                                                     activeCompany && (
                                                                       <>
                                                                         <S.ItemRow
                                                                           style={{
                                                                             display:
                                                                               'flex',
                                                                             gap: 15,
                                                                             width:
                                                                               '100%',
                                                                           }}
                                                                         >
                                                                           <div
                                                                             style={{
                                                                               flex: 1,
                                                                             }}
                                                                           >
                                                                             <TextInput
                                                                               label=' Account Name'
                                                                               type='text'
                                                                               disabled
                                                                               value={
                                                                                 subaccountRequest.name ??
                                                                                 ''
                                                                               }
                                                                               placeholder='name'
                                                                               // onChange={(value) => onChange(index as number, 'firstName', value)}
                                                                               // valid={firstNameIsValid}
                                                                             />
                                                                           </div>
                                                                           <div
                                                                             style={{
                                                                               flex: 1,
                                                                             }}
                                                                           >
                                                                             <TextInput
                                                                               label='Business Type'
                                                                               disabled
                                                                               value={
                                                                                 activeCompany
                                                                                   .accounts[0]
                                                                                   .type
                                                                               }
                                                                               placeholder=''
                                                                               // onChange={(value) => onChange(index as number, 'email', value)}
                                                                               // valid={emailIsValid}
                                                                             />
                                                                           </div>
                                                                         </S.ItemRow>

                                                                         <>
                                                                           <S.ItemRow
                                                                             style={{
                                                                               display:
                                                                                 'flex',
                                                                               gap: 15,
                                                                               width:
                                                                                 '100%',
                                                                             }}
                                                                           >
                                                                             <div
                                                                               style={{
                                                                                 flex: 1,
                                                                               }}
                                                                             >
                                                                               <TextInput
                                                                                 label='  Business Email'
                                                                                 type='text'
                                                                                 disabled
                                                                                 value={
                                                                                   activeCompany?.primaryEmail ??
                                                                                   ''
                                                                                 }
                                                                                 placeholder=''
                                                                                 // onChange={(value) => onChange(index as number, 'firstName', value)}
                                                                                 // valid={firstNameIsValid}
                                                                               />
                                                                             </div>
                                                                             <div
                                                                               style={{
                                                                                 flex: 1,
                                                                               }}
                                                                             >
                                                                               <TextInput
                                                                                 label=' Phone number'
                                                                                 disabled
                                                                                 value={
                                                                                   activeCompany.phone ??
                                                                                   ''
                                                                                 }
                                                                                 placeholder='Enter referee email'
                                                                                 // onChange={(value) => onChange(index as number, 'email', value)}
                                                                                 // valid={emailIsValid}
                                                                               />
                                                                             </div>
                                                                           </S.ItemRow>

                                                                           <S.ItemRow
                                                                             style={{
                                                                               display:
                                                                                 'flex',
                                                                               gap: 15,
                                                                               width:
                                                                                 '100%',
                                                                             }}
                                                                           >
                                                                             <div
                                                                               style={{
                                                                                 flex: 1,
                                                                               }}
                                                                             >
                                                                               <TextInput
                                                                                 label='Business address'
                                                                                 type='text'
                                                                                 disabled
                                                                                 value={
                                                                                   activeCompany?.address ??
                                                                                   ''
                                                                                 }
                                                                                 placeholder=''
                                                                                 // onChange={(value) => onChange(index as number, 'firstName', value)}
                                                                                 // valid={firstNameIsValid}
                                                                               />
                                                                             </div>
                                                                           </S.ItemRow>

                                                                           <S.EditSection>
                                                                             <Icon
                                                                               name='edit'
                                                                               color={
                                                                                 colors
                                                                                   .pink
                                                                                   ._100
                                                                               }
                                                                             />
                                                                             <Text
                                                                               size={
                                                                                 5
                                                                               }
                                                                               color='#B10180'
                                                                             >
                                                                               Edit
                                                                             </Text>
                                                                           </S.EditSection>
                                                                         </>
                                                                       </>
                                                                     )}
                                                                 </S.CustomTable>
                                                               </S.CompanyInfoWrapper>

                                                               <S.ButtonGroup>
                                                                 <S.TermsAndConditions>
                                                                   <Text
                                                                     color={
                                                                       colors
                                                                         .black
                                                                         ._100
                                                                     }
                                                                     size={6}
                                                                   >
                                                                     By clicking
                                                                     Continue,
                                                                     you agree
                                                                     with{' '}
                                                                     <S.TermsLink
                                                                       href={
                                                                         Terms
                                                                       }
                                                                       target='_blank'
                                                                       rel='noopener noreferrer'
                                                                     >
                                                                       Terms &
                                                                       Conditions
                                                                     </S.TermsLink>
                                                                   </Text>
                                                                 </S.TermsAndConditions>

                                                                 <div>
                                                                   <Button
                                                                     loading={
                                                                       loading
                                                                     }
                                                                     disabled={
                                                                       loading
                                                                     }
                                                                     label='Submit'
                                                                     style={{
                                                                       width: 304,
                                                                       background:
                                                                         '#B10180',
                                                                     }}
                                                                     onClick={() =>
                                                                       continueHandle()
                                                                     }
                                                                   />
                                                                 </div>
                                                               </S.ButtonGroup>
                                                             </OnboardingCard>
                                                           </S.Container>
                                                         );
                                                       };
