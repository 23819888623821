/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useSelector } from 'react-redux';
import { ErrorApiInterface } from '../../services/internal/api/api.error';
import { Icon } from '../../components/Icon';

import { Text } from '../../components/Text';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { Preload } from '../../components/Preload';
import { PageTitle } from '../../styles/wrappers';
import { CheckBox } from '../../components/CheckBox';
import { ModalWrapper } from '../../components/ModalWrapper';
import { Button } from '../../components/Button';

import { TextInput } from '../../components/TextInput';
import { RoundCheckBox } from '../../components/RoundCheckBox';
import { validateObject, ValidateResult } from '../../utils/validation';
import userService from '../../services/internal/user/user.service';
import { failed, success } from '../../components/Toasts';
import { FilterButton } from '../../components/FilterButton';
import SearchInput from '../../components/SearchInput';
import * as TableStyle from './TableStyle';
import { IPagination, Pagination } from '../../components/Pagination';
import { IGetQueries } from '../../interface';
import getQueryString from '../../utils/getQueryString';
import AccessControl, { EPermissions } from '../../components/AccessControl';
import defaultAvatar from '../../components/MiniProfile/assets/Images/defaultAvata.png';
import { RootState } from '../../redux';
import { CompanyInterface } from '../../redux/company/ICompany';
import {
  DropdownInput,
  DropdownOptionProps,
} from '../../components/DropdownInput';
import { idTypes, validIdTypes } from '../Onboard/constants';
import * as B from '../Onboarding/components/RangeSlider/styles';
import { InfoConponent } from '../../components/Info';
import { formatCash } from '../../utils/money';
import onboardingService from '../../services/internal/onboarding/onboarding.service';
import { useLocation } from 'react-router-dom';
const Processing = 'processing...';

export interface RecentTransactionsProps {
  data: Array<IPayments>;
  last: boolean;
}
export interface PaymentHistory {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
  check: boolean;
}
export interface OperatorsProps {
  blocked: boolean;
  check: boolean;
  createdAt: string;
  email: string;
  id: string;
  isRegisteredOnCba: boolean;
  lastLoginAt: any;
  phone: string;
  address: string;
  avatarUrl: string;
  bvn: string;
  dob: string;
  firstName: string;
  gender: string;
  lastName: string;
  middleName: string;
  nin: string;
  updatedAt: string;
  userId: string;
  // position: string;
  company: string;
  status: string;
  isActive: boolean;
  isOwner: boolean;
  role: string;
  approvalLimit?: number;
}

export interface CreateUserProps {
  status: string;
  role: string;
  // position: string;
  email: string;
  nin?: string;
  bvn: string;
  idType?: string;
  idNumber?: string;
  approvalLimit?: number;
}
export interface EditUserProps {
  status: string;
  role: string;
  // position: string;
  email?: string;
  approvalLimit?: number;
}

export interface IPayments {
  accountId: number;
  amount: number;
  businessId: number;
  comment: string;
  createdAt: string;
  flagReason: any; // TODO:
  gatewayResponse: string;
  gatewayResponseCode: string;
  id: number;
  isFlagged: boolean;
  lastFailureResponse: any; // TODO:
  receiverBankCode: string;
  receiverBvn: string;
  receiverClientId: string;
  receiverName: string;
  receiverNuban: string;
  receiverSavingsId: string;
  reference: string;
  retryCount: number;
  status: string;
  updatedAt: string;
  userId: number;
}

const SCHEME = {
  UserBvn: (value = '') => value.length === 11,
  //UserNin: (value = "") => value.length === 11,
  UserEmail: (value = '') => value.length > 0,
  UserRoles: (value = '') => value.length > 0,
  UserStatus: (value = '') => value.length > 0,
  idType: (value: { value: string }) => value?.value?.length > 0,
};
const EDIT_SCHEME = {
  UserEmail: (value = '') => value.length > 0,
  UserRoles: (value = '') => value.length > 0,
  UserStatus: (value = '') => value.length > 0,
};

const numberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};
export const UserManagement: React.FC = () => {
  const state = useLocation().state as { action: string };
  const preference = useSelector((store: RootState) => store.preference);

  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const filterData = [
    { name: 'Status', options: ['Active', 'Disabled', 'Blocked'] },
    {
      name: 'User role',
      options: preference.defaultRoles?.map((defaultRole) => defaultRole.value),
    },
  ];

  /**
   * Hooks initiation region
   */

  /**
   * Redux store initiation region
   */

  /**
   * Local state initiation region
   */
  const [OperatorList, setOperatorList] = useState<Array<OperatorsProps>>([]);
  const [currentOperator, setcurrentOperator] = useState<Array<OperatorsProps>>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showmodalView, setshowmodalView] = useState<boolean>(false);
  const [currentView, setcurrentView] = useState<string>('');

  const [UserBvn, setUserBvn] = useState<string>('');
  const [UserNin, setUserNin] = useState<string>('');
  const [idType, setIdType] = useState<DropdownOptionProps | null>(null);
  const [idNumber, setIdNumber] = useState<string>('');
  const [UserEmail, setUserEmail] = useState<string>('');
  const [UserRoles, setUserRoles] = useState<string>('');
  const [UserStatus, setUserStatus] = useState<string>('');
  const [UserApprovalLimit, setUserApprovalLimit] = useState(0.0);
  const [currentOperatorId, setcurrentOperatorId] = useState<string>('');
  const [validation, setValidation] = useState<ValidateResult | undefined>(
    undefined
  );
  const [updateValidation, setUpdateValidation] = useState<
    ValidateResult | undefined
  >(undefined);

  const [idNumberValidation, setIdNumberValidation] = useState<
    ValidateResult
  >();
  const [validInProgress, setValidInProgress] = useState(false);

  const [text, setText] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>({
    limit: 50,
    currentPage: 1,
    pageItems: 1,
    totalPages: 1,
  });
  const [statusFilter, setStatus] = useState<Array<string>>([]);
  const [roleFilter, setRole] = useState<Array<string>>([]);
  const [additionalQueries, setAddtionalQueries] = useState<IGetQueries>({});
  const [value, setValue] = useState(0.0);

  const CurrentRenderView = currentView as keyof typeof UserViews;
  //
  /**
   * Custom handlers initiation region
   */

  useEffect(() => {
    if (state && state.action === 'create') {
      CreateNewUser();
    }
  }, [state]);

  const getAllOperators = async (
    queriesData?: IGetQueries
  ): Promise<ErrorApiInterface | Array<IPayments>> => {
    try {
      let queries =
        queriesData && queriesData.search
          ? { ...queriesData }
          : {
              limit: 50,
              page: 1,
              ...additionalQueries,
              ...queriesData,
            };
      if (!queriesData?.search) {
        setText('');
        delete queries.search;
      }
      if (!queriesData) queries = { limit: 50, page: 1 };
      if (!queriesData?.search && !queriesData?.filter) setLoading(true);
      const result = await userService.getAllOperators(getQueryString(queries));
      setAddtionalQueries(queries);
      setLoading(false);
      const UserData = result.data.map((el: OperatorsProps) => ({
        ...el,
        check: false,
      }));
      setPagination({
        limit: result.meta.limit,
        currentPage: result.meta.page,
        pageItems: result.meta.pageCount,
        totalPages: result.meta.totalPages,
      });
      setOperatorList(UserData);

      return await Promise.resolve(result);
    } catch (error) {
      const err = error as ErrorApiInterface;
      setLoading(false);
      failed(
        'Failed!',
        err.message || 'Can not load users at the moment, try later'
      );
      return err;
    }
  };

  const searchOperator = async () => {
    if (text) {
      await getAllOperators({ search: text, page: 1 });
    }
  };

  useEffect(() => {
    if (currentView === 'EditUserData') {
      setValidation(
        validateObject(
          {
            UserEmail,
            UserRoles,
            UserStatus,
          },
          SCHEME
        )
      );
    } else {
      setValidation(
        validateObject(
          {
            UserBvn,
            UserNin,
            UserEmail,
            UserRoles,
            UserStatus,
            idType,
          },
          SCHEME
        )
      );
    }
  }, [UserBvn, UserNin, UserEmail, UserRoles, UserStatus, idType]);

  useEffect(() => {
    setUpdateValidation(
      validateObject(
        {
          UserEmail,
          UserRoles,
          UserStatus,
        },
        EDIT_SCHEME
      )
    );
  }, [UserEmail, UserRoles, UserStatus]);

  const idNumberScheme = {
    idNumber: (value: string) =>
      idType?.value === idTypes.nin
        ? value.length === 11
        : idType?.value === idTypes.voters_card
        ? value.length === 19
        : idType?.value === idTypes.drivers_license
        ? value.length > 10
        : idType?.value === idTypes.international_passport
        ? value.length === 9
        : false,
  };

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setIdNumberValidation(
        validateObject(
          {
            idNumber,
          },
          idNumberScheme
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [idNumber, idType]);

  const HandleCreateUserNow = async () => {
    setLoading(true);
    const payload = {
      status: UserStatus,
      role: UserRoles,
      email: UserEmail,
      idNumber,
      idType: idType?.value ?? '',
      bvn: UserBvn,
      ...(value && { approvalLimit: value }),
    };
    try {
      await userService.createNewUser(payload);
      // debug.success('Added operators ', Result);
      success('Success', 'User created successfully');
      getAllOperators();
      setshowmodalView(false);
    } catch (error) {
      const err = error as ErrorApiInterface;

      failed('Failed create user', err?.message);
    }
    setLoading(false);
  };
  const HandleUpdateUserNow = async () => {
    setLoading(true);
    const payload = {
      status: UserStatus,
      role: UserRoles,
      ...(UserApprovalLimit && { approvalLimit: UserApprovalLimit }),
      // email: UserEmail,
      // nin: UserNin,
      // bvn: UserBvn,
    };
    try {
      await userService.UpdateUser(payload, currentOperatorId);
      // debug.success('Updated operators ', Result);
      success('Success', 'User Updated successfully');
      getAllOperators();
      setshowmodalView(false);
    } catch (error) {
      const err = error as ErrorApiInterface;

      failed('Failed Update user', err?.message);
    }
    setLoading(false);
  };
  const EditExistingUser = () => {
    const OperatorForEdit = currentOperator[0];

    const {
      bvn,
      nin,
      email,
      id,
      role,
      status,
      approvalLimit,
    } = OperatorForEdit;

    setUserBvn(bvn);
    setUserNin(nin);
    setUserEmail(email);
    setUserRoles(role === null ? '' : role);
    setUserStatus(status);
    setcurrentOperatorId(id);
    setcurrentView('EditUserData');
    setshowmodalView(true);
    if (approvalLimit) setUserApprovalLimit(Number(approvalLimit));
  };
  const CreateNewUser = () => {
    setUserBvn('');
    setUserNin('');
    setUserEmail('');
    setUserRoles('');
    setUserStatus('');
    setcurrentOperatorId('');
    setcurrentView('createUserView');
    setshowmodalView(true);
  };
  const getImage = async (user: any): Promise<string> => {
    try {
      const result = await onboardingService.getDocLink({
        key: user?.avatarUrl,
      });
      return result.url;
    } catch (error) {
      return '';
    }
  };
  const toggleItemInArray = async (identifier: any) => {
    const uniqueKey = identifier.id;
    let bucketArray = [...currentOperator];
    if (identifier === 'empty') {
      bucketArray = [];
      return;
    }
    const check = bucketArray.findIndex((wizard) => wizard.id === uniqueKey);

    if (check !== -1) {
      bucketArray.splice(check, 1);
      setcurrentOperator(bucketArray);
    } else {
      bucketArray[bucketArray.length] = identifier;
    }

    setcurrentOperator(bucketArray);
    const newOperators = await Promise.all(
      OperatorList.map(async (op) => {
        if (op.id === identifier.id) {
          op.check = !identifier.check;
          if (!op.avatarUrl.includes('s3bucket'))
            op.avatarUrl = await getImage(identifier);
        }
        return op;
      })
    );
    setOperatorList([...newOperators]);

    // data: this.state.data.map(el => (el.id === id ? Object.assign({}, el, { text }) : el))
  };

  const selectFilter = (check: boolean, option: string, name: string) => {
    if (name === 'User role' && check) {
      setRole([...roleFilter, option]);
    } else if (name === 'User role' && !check) {
      setRole(roleFilter.filter((ro) => ro !== option));
    }

    if (name === 'Status' && check) {
      setStatus([...statusFilter, option]);
    } else if (name === 'Status' && !check) {
      setStatus(statusFilter.filter((stat) => stat !== option));
    }
  };

  const appllFilter = () => {
    let filter = '';
    if (statusFilter.length && roleFilter?.length) {
      filter = `status:${statusFilter.toString()};role:${roleFilter.toString()}`;
    } else if (statusFilter.length && !roleFilter.length) {
      filter = `status:${statusFilter.toString()}`;
    } else if (!statusFilter.length && roleFilter.length) {
      filter = `role:${roleFilter.toString()}`;
    }
    getAllOperators({ filter });
  };

  const resetFilter = () => {
    setRole([]);
    setStatus([]);
    getAllOperators();
  };
  const handleSearchText = (value: string) => {
    setText(value);
    if (!value.length) {
      getAllOperators({ page: 1 });
    }
  };

  useEffect(() => {
    let delayDebounceFn: any = null;
    if (text.length) {
      delayDebounceFn = setTimeout(() => {
        getAllOperators({ search: text, page: 1 });
      }, 1000);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [text]);

  const getDefaultRoles = () => {
    if (preference.defaultRoles && preference.defaultRoles.length > 0) {
      if (!activeCompany?.workflow)
        return preference.defaultRoles.filter(
          (defaultRole) => defaultRole.value === 'Viewer'
        );

      if (
        !activeCompany.workflow
          ?.split('role:')[2]
          ?.toLowerCase()
          .includes('verifier')
      )
        return preference.defaultRoles.filter(
          (defaultRole) =>
            defaultRole.value !== 'Superadmin' &&
            defaultRole.value !== 'Verifier'
        );

      return preference.defaultRoles.filter(
        (defaultRole) => defaultRole.value !== 'Superadmin'
      );
    }
    return [];
  };

  const ItemIsChecked = (userId: string) =>
    currentOperator.some((el) => el.id === userId);

  const MAX = 10000000;
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(value * 100) / MAX}% 100%`,
    };
  };

  const getEditBackgroundSize = () => {
    return {
      backgroundSize: `${(UserApprovalLimit * 100) / MAX}% 100%`,
    };
  };

  const handleOnChange = (e: any) => {
    setValue(e.target.value);
    setUserApprovalLimit(e.target.value);
  };

  const handleOnEditChange = (e: any) => {
    setUserApprovalLimit(e.target.value);
  };
  const UserTable = (item: OperatorsProps) => (
    <>
      <tr
        key={item.id}
        style={
          ItemIsChecked(item.id) === true
            ? { borderLeft: '5px solid #C6145E' }
            : {}
        }
      >
        <td>
          <CheckBox
            checked={item.check}
            onChange={() => toggleItemInArray(item)}
          />
        </td>
        <td className="uk-text-nowrap">
          <Text
            size={7}
            color={colors.black._100}
          >{`${item?.firstName} ${item?.lastName}`}</Text>
        </td>
        <td>
          <Text size={7} color={colors.black._100}>
            {item.role || '---'}
          </Text>
        </td>
        <td>
          <Text size={7} color={colors.black._100}>
            {item.company}
          </Text>
        </td>

        <td>
          <Text size={7} color={colors.black._100}>
            {item.status}
          </Text>
        </td>
        <td>
          <S.IconWrapp onClick={() => toggleItemInArray(item)}>
            {item.check === true ? (
              <Icon name="arrow-up" color={colors.black._100} />
            ) : (
              <Icon name="arrow-down" color={colors.black._100} />
            )}
          </S.IconWrapp>
        </td>
      </tr>
      {ItemIsChecked(item.id) === true && (
        <tr className="expandable" key="tr-expander">
          <td className="uk-background-muted" colSpan={9}>
            <TableStyle.Flex>
              {item.avatarUrl && (
                <TableStyle.Flex1>
                  <div className="uk-text-center">
                    <img
                      className="uk-preserve-width userimage"
                      src={item.avatarUrl || defaultAvatar}
                      alt="avatar"
                    />
                  </div>
                </TableStyle.Flex1>
              )}
              <TableStyle.Flex3>
                <TableStyle.TrStyle>
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          Date of birth
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.dob}
                      </Text>
                    </TableStyle.Container>
                  </td>
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          Phone number
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.phone}
                      </Text>
                    </TableStyle.Container>
                  </td>
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          Email
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.email}
                      </Text>
                    </TableStyle.Container>
                  </td>
                  {(item?.role?.toLowerCase() === 'authoriser' ||
                    item?.role?.includes('authoriser')) && (
                    <td>
                      <TableStyle.Container>
                        <TableStyle.Container>
                          <Text size={8} color={colors.black._60}>
                            Approval limit
                          </Text>
                        </TableStyle.Container>

                        <Text size={7} color={colors.black._100}>
                          ₦{formatCash(item.approvalLimit)}
                        </Text>
                      </TableStyle.Container>
                    </td>
                  )}
                </TableStyle.TrStyle>
                {/*  */}
                <tr key="">
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          Gender
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.gender}
                      </Text>
                    </TableStyle.Container>
                  </td>
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          BVN
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.bvn}
                      </Text>
                    </TableStyle.Container>
                  </td>
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          NIN
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.nin}
                      </Text>
                    </TableStyle.Container>
                  </td>
                  <td>
                    <TableStyle.Container>
                      <TableStyle.Container>
                        <Text size={8} color={colors.black._60}>
                          Address
                        </Text>
                      </TableStyle.Container>

                      <Text size={7} color={colors.black._100}>
                        {item.address}
                      </Text>
                    </TableStyle.Container>
                  </td>
                </tr>
              </TableStyle.Flex3>
            </TableStyle.Flex>
          </td>
        </tr>
      )}
    </>
  );

  const renderTableItem = () =>
    OperatorList ? (
      <>
        <S.TableControls>
          <AccessControl requiredPermission={[EPermissions.VIEW_OPERATOR]}>
            <S.SearchContainer>
              <SearchInput
                value={text}
                onChange={handleSearchText}
                onSubmitSearch={searchOperator}
              />
            </S.SearchContainer>
          </AccessControl>
          <S.ButtonContainer>
            <AccessControl requiredPermission={[EPermissions.VIEW_OPERATOR]}>
              <FilterButton
                data={filterData}
                onCheck={selectFilter}
                onReset={() => resetFilter()}
                apply={appllFilter}
              />

              <AccessControl
                requiredPermission={[EPermissions.UPDATE_OPERATOR]}
              >
                <Button
                  righIcon="pencil"
                  theme="info"
                  size="small"
                  onClick={() => EditExistingUser()}
                  disabled={currentOperator.length !== 1}
                />
              </AccessControl>
            </AccessControl>

            <AccessControl requiredPermission={[EPermissions.CREATE_OPERATOR]}>
              <Button
                label="Create a new user"
                size="small"
                onClick={() => CreateNewUser()}
              />
            </AccessControl>
          </S.ButtonContainer>
        </S.TableControls>

        <AccessControl requiredPermission={[EPermissions.VIEW_OPERATOR]} isPage>
          <TableStyle.TableStyle>
            <TableStyle.TableWrap>
              <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                <thead>
                  <TableStyle.TopTr>
                    <th className="uk-table-shrink">{/* <CheckBox /> */}</th>

                    <th className="uk-table-shrink">
                      <Text size={6} color={colors.black._100} bold>
                        Full Name
                      </Text>
                    </th>
                    <th className="uk-table-shrink">
                      <Text size={6} color={colors.black._100} bold>
                        USER ROLE
                      </Text>
                    </th>
                    <th className="uk-table-shrink">
                      <Text size={6} color={colors.black._100} bold>
                        COMPANY
                      </Text>
                    </th>
                    <th className="uk-table-shrink">
                      <Text size={6} color={colors.black._100} bold>
                        STATUS
                      </Text>
                    </th>
                    <th className="uk-table-shrink">
                      <Text size={6} color={colors.black._100} bold>
                        {' '}
                      </Text>
                    </th>
                  </TableStyle.TopTr>
                </thead>
                <tbody>{OperatorList?.map(UserTable)}</tbody>
              </table>
            </TableStyle.TableWrap>
          </TableStyle.TableStyle>
          {OperatorList.length > 0 && (
            <Pagination {...pagination} action={getAllOperators} />
          )}
        </AccessControl>
      </>
    ) : null;
  const UserCreateForm = () => (
    <S.Container>
      <S.ModalWrapper>
        <S.Header>
          <S.MarginBottom>
            <Text color={colors.pink._100} size={3} bold>
              Create new user
            </Text>
          </S.MarginBottom>
        </S.Header>
        <S.MarginBottom>
          <S.MarginBottom>
            <S.Container>
              <Text color={colors.black._100} size={4} bold>
                Personal data
              </Text>
            </S.Container>
            <Text color={colors.black._60} size={5}>
              Enter the personal data of the employee.
            </Text>
          </S.MarginBottom>
          <S.Container>
            <S.TextInputContainer>
              <S.TextInputWrapper>
                <TextInput
                  label="BVN"
                  value={UserBvn}
                  type="currency"
                  mask={createNumberMask(numberMaskOptions)}
                  onChange={setUserBvn}
                />
              </S.TextInputWrapper>
              <S.SpaceWrapper>
                <span> </span>
              </S.SpaceWrapper>
              <S.TextInputWrapper>
                {/* <TextInput
                  label="NIN"
                  value={UserNin}
                  type="currency"
                  mask={createNumberMask(numberMaskOptions)}
                  onChange={setUserNin}
                /> */}
                <DropdownInput
                  label="Valid ID Type"
                  options={[...validIdTypes]}
                  onSelect={setIdType}
                />
              </S.TextInputWrapper>
            </S.TextInputContainer>
            <S.TextInputWrapper>
              <TextInput
                label="Valid ID Number"
                type="text"
                value={idNumber}
                name="idNumber"
                //valid={!idNumberValid && idNumberValidation?.data.idNumber.isValid}
                onChange={setIdNumber}
              />
            </S.TextInputWrapper>
            <S.TextInputWrapper>
              <TextInput
                label="Email"
                type="text"
                value={UserEmail}
                onChange={setUserEmail}
              />
            </S.TextInputWrapper>
          </S.Container>
        </S.MarginBottom>
        <div>
          <S.MarginBottom>
            <Text color={colors.black._100} size={4} bold>
              Roles & Status
            </Text>
          </S.MarginBottom>
          <S.MarginBottom>
            <Text color={colors.black._100} size={5} bold>
              Roles
            </Text>
          </S.MarginBottom>
          <S.MarginBottom>
            <S.CheckboxContainer>
              {getDefaultRoles().length > 0 &&
                getDefaultRoles().map((defaultRole, index) => (
                  <>
                    <S.CheckboxWrapper key={index.toString()}>
                      <RoundCheckBox
                        text={defaultRole.value}
                        onChange={() => {
                          setUserRoles(defaultRole.value);
                        }}
                        checked={UserRoles === defaultRole.value}
                      />
                    </S.CheckboxWrapper>
                    <S.SpaceWrapper>
                      <span> </span>
                    </S.SpaceWrapper>
                  </>
                ))}
            </S.CheckboxContainer>
          </S.MarginBottom>
          <S.MarginBottom>
            <Text color={colors.black._100} size={5} bold>
              Status
            </Text>
          </S.MarginBottom>
          <S.MarginBottom>
            <S.CheckboxContainer>
              <S.CheckboxWrapper>
                <RoundCheckBox
                  text="Active"
                  onChange={() => {
                    setUserStatus('active');
                  }}
                  checked={UserStatus === 'active'}
                />
              </S.CheckboxWrapper>
              <S.SpaceWrapper>
                <span> </span>
              </S.SpaceWrapper>
              <S.CheckboxWrapper>
                <RoundCheckBox
                  text="Disable"
                  onChange={() => {
                    setUserStatus('disabled');
                  }}
                  checked={UserStatus === 'disabled'}
                />
              </S.CheckboxWrapper>
              <S.SpaceWrapper>
                <span> </span>
              </S.SpaceWrapper>
              <S.CheckboxWrapper>
                <RoundCheckBox
                  text="Block"
                  onChange={() => {
                    setUserStatus('blocked');
                  }}
                  checked={UserStatus === 'blocked'}
                />
              </S.CheckboxWrapper>
            </S.CheckboxContainer>
          </S.MarginBottom>
        </div>

        {UserRoles === 'Authoriser' && (
          <div>
            <S.MarginBottom>
              <Text color={colors.black._100} size={4} bold>
                Settings
              </Text>
            </S.MarginBottom>
            <S.MarginBottom>
              <Text color={colors.black._100} size={5} bold>
                Approval Limit
              </Text>

              <InfoConponent
                style={{ marginTop: 10 }}
                text="Setting this, limits the amount the user can authorise for a
              transaction"
              />
            </S.MarginBottom>
            <S.MarginBottom>
              <S.CheckboxContainer>
                <S.RangeWrapper>
                  <B.Wrapper>
                    <Text size={6} color={colors.black._100}>
                      Set approval amount limit{' '}
                      <span
                        style={{
                          color: '#DB9308',
                        }}
                      >
                        (Max 10 million)
                      </span>
                    </Text>
                    <B.Slider>
                      <input
                        step={50000}
                        style={getBackgroundSize()}
                        type="range"
                        min={0}
                        max={MAX}
                        value={value.toLocaleString()}
                        onChange={handleOnChange}
                      />
                    </B.Slider>
                    <B.Buble>
                      ₦{Number(UserApprovalLimit).toLocaleString()}
                    </B.Buble>
                  </B.Wrapper>
                  {/* <Slider/> */}
                </S.RangeWrapper>
              </S.CheckboxContainer>
            </S.MarginBottom>
          </div>
        )}

        <S.ButtonWrapper1>
          <Button
            label={loading === true ? Processing : 'Create new user'}
            disabled={
              !validation?.isValid ||
              !idNumberValidation?.isValid ||
              loading === true
            }
            onClick={() => HandleCreateUserNow()}
          />
        </S.ButtonWrapper1>
      </S.ModalWrapper>
    </S.Container>
  );
  const UserEditForm = () => (
    <S.Container>
      <S.ModalWrapper>
        <S.Header>
          <S.MarginBottom>
            <Text color={colors.pink._100} size={3} bold>
              Edit user
            </Text>
          </S.MarginBottom>
        </S.Header>
        <S.MarginBottom>
          <S.MarginBottom>
            <S.Container>
              <Text color={colors.black._100} size={4} bold>
                Personal data
              </Text>
            </S.Container>
            <Text color={colors.black._60} size={5}>
              Enter the personal data of the employee.
            </Text>
          </S.MarginBottom>
          <S.Container>
            <S.TextInputContainer>
              <S.TextInputWrapper>
                <TextInput
                  label="BVN"
                  value={UserBvn}
                  type="currency"
                  mask={createNumberMask(numberMaskOptions)}
                  onChange={setUserBvn}
                  disabled
                />
              </S.TextInputWrapper>
              <S.SpaceWrapper>
                <span> </span>
              </S.SpaceWrapper>
              <S.TextInputWrapper>
                <TextInput
                  label="NIN"
                  value={UserNin}
                  type="currency"
                  mask={createNumberMask(numberMaskOptions)}
                  onChange={setUserNin}
                  disabled
                />
              </S.TextInputWrapper>
            </S.TextInputContainer>
            <S.TextInputWrapper>
              <TextInput
                label="Email"
                type="text"
                value={UserEmail}
                onChange={setUserEmail}
                disabled
              />
            </S.TextInputWrapper>
          </S.Container>
        </S.MarginBottom>
        <div>
          <S.MarginBottom>
            <Text color={colors.black._100} size={4} bold>
              Roles & Status
            </Text>
          </S.MarginBottom>
          <S.MarginBottom>
            <Text color={colors.black._100} size={5} bold>
              Roles
            </Text>
          </S.MarginBottom>
          <S.MarginBottom>
            <S.CheckboxContainer>
              {getDefaultRoles().length > 0 &&
                getDefaultRoles().map((defaultRole, index) => (
                  <>
                    <S.CheckboxWrapper key={index.toString()}>
                      <RoundCheckBox
                        text={defaultRole.value}
                        onChange={() => {
                          setUserRoles(defaultRole.value);
                        }}
                        checked={
                          UserRoles
                            ? UserRoles.toLocaleLowerCase() ===
                              defaultRole.value.toLocaleLowerCase()
                            : false
                        }
                      />
                    </S.CheckboxWrapper>
                    <S.SpaceWrapper>
                      <span> </span>
                    </S.SpaceWrapper>
                  </>
                ))}
            </S.CheckboxContainer>
          </S.MarginBottom>
          <S.MarginBottom>
            <Text color={colors.black._100} size={5} bold>
              Status
            </Text>
          </S.MarginBottom>
          <S.MarginBottom>
            <S.CheckboxContainer>
              <S.CheckboxWrapper>
                <RoundCheckBox
                  text="Active"
                  onChange={() => {
                    setUserStatus('active');
                  }}
                  checked={UserStatus === 'active'}
                />
              </S.CheckboxWrapper>
              <S.SpaceWrapper>
                <span> </span>
              </S.SpaceWrapper>
              <S.CheckboxWrapper>
                <RoundCheckBox
                  text="Disable"
                  onChange={() => {
                    setUserStatus('disabled');
                  }}
                  checked={UserStatus === 'disabled'}
                />
              </S.CheckboxWrapper>
              <S.SpaceWrapper>
                <span> </span>
              </S.SpaceWrapper>
              <S.CheckboxWrapper>
                <RoundCheckBox
                  text="Block"
                  onChange={() => {
                    setUserStatus('blocked');
                  }}
                  checked={UserStatus === 'blocked'}
                />
              </S.CheckboxWrapper>
            </S.CheckboxContainer>
          </S.MarginBottom>

          {UserRoles?.toLowerCase() === 'authoriser' && (
            <div>
              <S.MarginBottom>
                <Text color={colors.black._100} size={4} bold>
                  Settings
                </Text>
              </S.MarginBottom>
              <S.MarginBottom>
                <Text color={colors.black._100} size={5} bold>
                  Approval Limit
                </Text>

                <InfoConponent
                  style={{ marginTop: 10 }}
                  text="Setting this, limits the amount the user can authorise for a
              transaction"
                />
              </S.MarginBottom>
              <S.MarginBottom>
                <S.CheckboxContainer>
                  <S.RangeWrapper>
                    <B.Wrapper>
                      <Text size={6} color={colors.black._100}>
                        Set approval amount limit{' '}
                        <span
                          style={{
                            color: '#DB9308',
                          }}
                        >
                          (Max 10 million)
                        </span>
                      </Text>
                      <B.Slider>
                        <input
                          step={50000}
                          style={getEditBackgroundSize()}
                          type="range"
                          min={0}
                          max={MAX}
                          value={
                            UserApprovalLimit?.toLocaleString()?.replace(
                              /,/g,
                              ''
                            ) ?? 0
                          }
                          onChange={handleOnEditChange}
                        />
                      </B.Slider>
                      <B.Buble>
                        ₦{Number(UserApprovalLimit ?? 0).toLocaleString()}
                      </B.Buble>
                    </B.Wrapper>
                    {/* <Slider/> */}
                  </S.RangeWrapper>
                </S.CheckboxContainer>
              </S.MarginBottom>
            </div>
          )}
        </div>

        <S.ButtonWrapper1>
          <Button
            label={loading === true ? Processing : 'Save changes'}
            disabled={!updateValidation?.isValid || loading === true}
            onClick={() => HandleUpdateUserNow()}
          />
        </S.ButtonWrapper1>
      </S.ModalWrapper>
    </S.Container>
  );

  const UserViews = {
    createUserView: UserCreateForm(),
    EditUserData: UserEditForm(),
  };

  const renderModalView = () => (
    // ,
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={showmodalView}
      onCloseModal={() => setshowmodalView(false)}
      crossColor={colors.black._100}
    >
      {UserViews[CurrentRenderView]}
    </ModalWrapper>
  );

  /**
   * Listeners region
   */

  /**
   * useEffect region
   */

  useEffect(() => {
    getAllOperators();
  }, []);

  return (
    <S.Container>
      {renderModalView()}
      <PageTitle.Wrapper>
        <Text color={colors.black._100} size={4} bold>
          User management{' '}
        </Text>
      </PageTitle.Wrapper>
      {loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      ) : (
        renderTableItem()
      )}
    </S.Container>
  );
};
