import styled, { css } from 'styled-components';

import { crossBrowserTransition, ptr } from '../../../styles/utils';
import { breakpoints } from '../../../styles/variables';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${ptr(30)};
  width: ${ptr(726)};
  gap: ${ptr(40)};

  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;
const ManualContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${ptr(30)};
  width: ${ptr(634)};
  gap: ${ptr(40)};

  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const StepContainer = styled.div`
  ul {
    list-style: unset;
    margin-left: 20px;
  }
  li:nth-child(1) {
    margin-bottom: 20px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const ManualStepContainer = styled.div`
  ol {
    list-style: decimal;
    margin-left: 20px;
  }
  li {
    margin-bottom: 40px;
    line-height: 30px;
  }
  li:nth-last-child() {
    margin-bottom: none !important;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TextContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button:nth-child(1) {
    width: 80px;
  }
`;

const ButtonContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button:nth-child(1) {
    width: 80px;
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${ptr(20)};
  padding: ${ptr(30)};
  width: ${ptr(634)};
  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const SuccessButtonContainer = styled.div`
  width: 400px;
  margin-top: 40px;
  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

export {
  Container,
  TitleContainer,
  StepContainer,
  ManualStepContainer,
  ImageContainer,
  TextContainer,
  ButtonContainer,
  ButtonContainerTwo,
  ManualContainer,
  InputContainer,
  SuccessContainer,
  SuccessButtonContainer,
};
