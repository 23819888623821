import styled, { css } from 'styled-components';

import { colors, breakpoints, fonts } from '../../../../styles/variables';
import { ptr } from '../../../../styles/utils';

const Container = styled.div<{ background?: string }>`
  padding: ${ptr(32)};
  // max-width:570px;
  // padding: ${ptr(25)} ${ptr(45)} ${ptr(31)} ${ptr(45)};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  background: ${colors.black._2};
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}
 
  max-width:100%;
  @media (max-width: ${breakpoints.lg}px) {
    
    max-width:100%;

  }
`;

const TopText = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${breakpoints.sm}px) {
    position: relative;
    button {
      position: absolute;
      right: 0;
      /* left: 0; */
      width: fit-content;
    }
  }
`;

const Links = styled.div`
  background: inherit;
  margin-top: 14px;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  overflow-x: auto;
  /* justify-content: space-between; */
  gap: 15px;
`;

const LinksInner = styled.div<{ svgBg?: string }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 6px;
  width: 120px;
  > svg {
    background: #eff0f6;
    ${(props) =>
      props.svgBg &&
      css`
        background: ${props.svgBg};
      `}
    width: 22px;
    height: 20px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 45px;
    margin-bottom: 9px;
    overflow: visible;
  }
  position: relative;
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 0;
`;

const CheckBtn = styled.div`
  position: absolute;
  right: 0;
`;

export { Container, Links, LinksInner, TopText, CloseBtn, CheckBtn };
