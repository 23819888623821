import React from 'react';

import {
  ContentContainer, FormButton, FormTitle, OneCenter,
} from '../../../../styles/wrappers';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { Button } from '../../../../components/Button';
import { Logo } from '../../../../components/Logo';
// import * as S from './styles';

export interface RequestSentProps {
  /**
   * Create request click action
   */
  onCreate?: () => void,
}

export const RequestSent: React.FC<RequestSentProps> = ({ onCreate }) => (
  <OneCenter.Wrapper>
    <ContentContainer justifyContent="flex-start">
      <Logo marginLeft={80} marginTop={32} />
    </ContentContainer>
    <ContentContainer flex={1} justifyContent="center" alignItems="center">
      <OneCenter.ContentWrapper>
        <FormTitle.Wrapper>
          <FormTitle.TitleWrapper>
            <Text color={colors.pink._100} size={2} bold>
              Request sent successfully!
            </Text>
          </FormTitle.TitleWrapper>
          <FormTitle.DescriptionWrapper>
            <Text color={colors.black._100}>
              After the approval you will receive an email with a
              link to create password in order to complete activation.
            </Text>
            <br />
            <Text color={colors.black._100}>
              After password is set up you will get an email with
              username and password with which you can log into your account.
            </Text>
          </FormTitle.DescriptionWrapper>
          <FormButton.Wrapper>
            <Button
              label="Got it"
              onClick={onCreate}
            />
          </FormButton.Wrapper>
        </FormTitle.Wrapper>
      </OneCenter.ContentWrapper>
    </ContentContainer>
  </OneCenter.Wrapper>
);
