import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div``;

const Wrapper = styled.div`
  margin-top: ${ptr(32)};

  > :not(:first-child) {
    margin-top: ${ptr(16)};
  }
`;

export { Container, Wrapper };
