import { ActionInterface } from '../IAction';
import { GET_PREFERENCE } from './actions';
import { IPreference } from './IPreference';

const initialState: IPreference = {
  defaultRoles: [{ name: '', value: '' }],
  withdrawalFee: null,
  workflow: {
    initToAuth: '',
    initVerAuth: '',
  },
  bvnConsentEnabled: false,
  enablePayloadEncryption: false,
};

const reducer = (
  state = initialState,
  action: ActionInterface<IPreference>
): IPreference => {
  switch (action.type) {
    case GET_PREFERENCE: {
      if (action.payload) {
        return {
          defaultRoles: action.payload.defaultRoles,
          withdrawalFee: action.payload.withdrawalFee,
          workflow: action.payload.workflow,
          bvnConsentEnabled: action.payload.bvnConsentEnabled,
          enablePayloadEncryption: action.payload.enablePayloadEncryption,
        };
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default reducer;
