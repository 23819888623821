import styled from 'styled-components';

import { ptr } from '../../styles/utils';

const Container = styled.div`

`;

const Handler = styled.button`
  border: 0;
  background: none;
  align-items: center;
  white-space:nowrap;
  display: flex;
  padding: 0;

  > svg {
    margin-right: ${ptr(16)};
  }
`;

export { Container, Handler };
