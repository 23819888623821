import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CircularProgressbar } from '../../components/CircularProgressbar';
import { Logo } from '../../components/Logo';
import { RoundButton } from '../../components/RoundButton';
import { ContentContainer, OneCenter } from '../../styles/wrappers';
import { RequestSuccess } from './components/RequestSuccess';
import { StepFifth } from './components/StepFifth';
import { StepFirst } from './components/StepFirst';
import { StepFourth } from './components/StepFourth';
import { StepSecond } from './components/StepSecond';
import { StepThird } from './components/StepThird';

import * as S from './styles';

export interface UnregisteredBusinessProps {
  /**
   * Example page property
   */
  example?: string,
}

export const UnregisteredBusiness: React.FC<UnregisteredBusinessProps> = () => {
  const [step, setStep] = useState(1);

  const history = useHistory();

  const onBack = () => {
    if (step === 1) {
      history.goBack();
    } else {
      setStep(step - 1);
    }
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepFirst onContinue={() => setStep(2)} />;
      case 2:
        return <StepSecond onContinue={() => setStep(3)} />;
      case 3:
        return <StepThird type="bvn" onContinue={() => setStep(4)} />;
      case 4:
        return <StepFourth onContinue={() => setStep(5)} />;
      case 5:
        return <StepFifth onContinue={() => setStep(6)} />;
      default:
        return <StepFirst />;
    }
  };
  return (
    <S.Wrapper>
      <ContentContainer justifyContent="flex-start">
        <Logo marginLeft={80} marginTop={32} />
      </ContentContainer>
      {step === 6 ? (
        <ContentContainer flex={1} justifyContent="center" alignItems="center">
          <OneCenter.ContentWrapper>
            <RequestSuccess onContinue={() => setStep(1)} />
          </OneCenter.ContentWrapper>
        </ContentContainer>
      ) : (
        <ContentContainer flex={1} justifyContent="center" alignItems="flex-start">
          <OneCenter.ContentWrapper>
            <S.BackButtonWrapper>
              <RoundButton
                icon="arrow-left"
                onClick={onBack}
              />
            </S.BackButtonWrapper>
            <S.ProgressWrapper>
              <CircularProgressbar
                bigText={step.toString()}
                smallText="5"
                textType="of"
                progress={(100 / 5) * step}
              />
            </S.ProgressWrapper>
            {renderStep()}
          </OneCenter.ContentWrapper>
        </ContentContainer>
      )}
    </S.Wrapper>
  );
};
