import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

interface IBackContainer {
  inApp?:boolean;
}

const BackContainer = styled.div<IBackContainer>`
  position: absolute;
  top: ${ptr(36)};
  left: ${ptr(32)};
  ${(props) => props.inApp && css`
  top: ${ptr(70)};
  left: ${ptr(42)};
  @media (max-width: ${breakpoints.sm}px) {
    left: ${ptr(30)};
    top: ${ptr(100)};
  }
   @media screen and (min-width: 1000px) and (max-width: 1024px) {
     top: ${ptr(30)};
  left: ${ptr(30)};
  }
  /* @media (min-width: 1600px) {
    left: ${ptr(270)};
    top: ${ptr(150)};
  } */
  `}
`;

const Back = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: transparent;
`;

export { BackContainer, Back };
