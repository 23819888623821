import debug from '../debbug.service';
import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';
import ErrorUser from './user.error';
import { UserDataInterface } from '../../../redux/current-user/ICurrentUser';
import { CreateUserProps, EditUserProps } from '../../../pages/UserManagement';
import { IPreference } from '../../../redux/preference/IPreference';

class UserService {
  private apiEndpoints = {
    userData: 'auth',
    accountInfo: 'users/account',
    changePassword: 'users/password',
    operators: (queryString: string) => `operators${queryString}`,
    createOperators: 'operators',
    preference: 'preference',
    uploadBusinessDoc: 'business/uploads',
  };

  public async getUserData(id: string) {
    try {
      const request = await apiService.request.get(`${this.apiEndpoints.userData}?id=${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await request.json();

      return result;
    } catch (globalError: any) {
      debug.error('Failed get account info', globalError);

      try {
        const result = await globalError.response.json();

        return Promise.reject(new ErrorUser({
          message: result.message,
          code: result.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getCurrentUserData(): Promise<UserDataInterface | void> {
    try {
      const request = await apiService.request.get(`${this.apiEndpoints.userData}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await request.json();

      debug.success('Result of retrieving data about the current user', result);

      return result;
    } catch (globalError: any) {
      debug.error('Failed get current user info', globalError);

      try {
        const result = await globalError.response.json();

        return Promise.reject(new ErrorUser({
          message: result.message,
          code: result.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async getAccountInfo(accountNumber: string) {
    try {
      const response = await apiService.request.get(`${this.apiEndpoints.accountInfo}?id=${accountNumber}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      const result = await response.json();
      return result;
    } catch (globalError) {
      debug.error('Failed get account info', globalError);

      throw globalError;
    }
  }

  public async changePassword(password: string) {
    try {
      const request = await apiService.request.patch(this.apiEndpoints.changePassword, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          password,
        }),
      });

      await request.json();

      return true;
    } catch (globalError: any) {
      debug.error('Failed change password', globalError);

      try {
        const result = await globalError.response.json();

        return Promise.reject(new ErrorUser({
          message: result.message,
          code: result.code,
        }));
      } catch (localError) {
        return ErrorApi.throwError(ErrorApi.ServerError);
      }
    }
  }

  public async createNewUser(payload:CreateUserProps) {
    try {
      const request = await apiService.request.post(this.apiEndpoints.createOperators, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await request.json() as { accessToken: string };

      return result;
    } catch (error: any) {
      if (error.response) {
        const localError = await error.response?.json();
        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async getAllOperators(queryString?: string) {
    try {
      const response = await apiService.request.get(this.apiEndpoints.operators(queryString || ''), {
        headers: {
          'content-type': 'application/json',
        },
      });

      const result = await response.json();

      return result;
    } catch (globalError: any) {
      if (globalError.response) {
        const result = await globalError.response?.json();

        throw result;
      } else {
        throw globalError;
      }
    }
  }

  public async UpdateUser(payload:EditUserProps, operatorId:string) {
    try {
      const endpoint = `${this.apiEndpoints.operators('')}/${operatorId}`;
      const request = await apiService.request.put(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await request.json() as { accessToken: string };

      return result;
    } catch (error: any) {
      if (error.response) {
        const localError = await error.response.json();
        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async getPreference() {
    try {
      const endpoint = this.apiEndpoints.preference;
      const request = await apiService.request.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await request.json() as IPreference;

      return result;
    } catch (error: any) {
      if (error.response) {
        const localError = await error.response.json();
        throw localError;
      } else {
        throw error;
      }
    }
  }

  public async uploadUserDocument(bodydata: any) {
    try {
      const endpoint = `${this.apiEndpoints.uploadBusinessDoc}`;
      const request = await apiService.request.post(endpoint, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
        },
        body: bodydata,
      });

      const result = await request.json();

      return result;
    } catch (error: any) {
      if (error.response) {
        const localError = await error.response.json();
        throw localError;
      } else {
        throw error;
      }
    }
  }
}

const userService = new UserService();
export default userService;
