import styled, { css } from 'styled-components';

import { colors } from '../../../../styles/variables';
import { ptr } from '../../../../styles/utils';

const Container = styled.div`
  padding: ${ptr(32)};
  // border: ${ptr(1)} solid ${colors.black._10};
  // border-radius: ${ptr(8)};
  background: ${colors.black._2};
`;

const Flex = styled.div`
  display: flex;
  width: 100%; 
  @media (max-width: ${ptr(545)}) {
    flex-direction: column;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  height: ${ptr(250)};
  width: 40%;
  @media (max-width: ${ptr(545)}) {
    width: 100%;
  }
`;

const Legend = styled.article`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  @media (max-width: ${ptr(545)}) {
    justify-content: space-between;
  }
  @media (max-width: ${ptr(389)}) {
    justify-content: center;
  }
`;

const Block = styled.div`
  > * {
    display: block;
  }

  > :last-child {
    margin-top: ${ptr(4)};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  margin-top: ${ptr(16)};
  min-width: 110px;
`;

const CirclePink = styled.figure<{ color: string }>`
  width: ${ptr(10)};
  height: ${ptr(10)};
  border-radius: ${ptr(10)};
  margin-right: ${ptr(8)};

  ${(props) => props.color && css`
    background: ${props.color};
  `}
`;

export {
  Container,
  Flex,
  Wrapper,
  Legend,
  Row,
  Block,
  CirclePink,
};
