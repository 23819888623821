import React from 'react';

import * as S from './styles';
import { Icon, IconName } from '../Icon';
import { colors } from '../../styles/variables';

export interface RoundButtonProps {
  /**
   * Name of icon
   */
  icon: IconName['name'],
  /**
   * OnClick event
   */
  onClick?: () => void,
}

export const RoundButton: React.FC<RoundButtonProps> = ({
  icon,
  ...props
}) => (
  <S.Button onClick={props.onClick || undefined}>
    <Icon name={icon} color={colors.pink._100} />
  </S.Button>
);
