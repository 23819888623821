import styled, { css } from 'styled-components';

import { crossBrowserTransition, ptr } from '../../styles/utils';
import { breakpoints, colors, fonts } from '../../styles/variables';

const ContentContainer = styled.div`
  width: ${ptr(600)};
  padding: ${ptr(20)} ${ptr(40)};

  @media (max-width: ${breakpoints.sm}px) {
    width: fit-content;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export { ContentContainer, ButtonContainer, InfoContainer };
