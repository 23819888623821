import React from 'react';
import { useHistory } from 'react-router';

import * as S from './styles';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { colors } from '../../styles/variables';

export interface BackBarProps {
  name?: string
}

export const BackBar: React.FC<BackBarProps> = ({
  name = '',
}) => {
  const history = useHistory();

  const onClick = () => {
    // const paths = history.location.pathname.split('/');
    // paths.pop();
    // history.push(paths.join('/'));
    history.goBack();
  };

  return (
    <S.Container>
      <S.Handler onClick={onClick}>
        <Icon name="arrow-left" color={colors.black._40} />
        <Text size={4} bold>
          {name}
        </Text>
      </S.Handler>
    </S.Container>
  );
};
