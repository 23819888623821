import React from 'react';
import styled, { css } from 'styled-components';

import { TextProps } from '.';
import { breakpoints, fonts } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const StyledText = styled(({ tag = 'span', children, ...props }: TextProps) => React.createElement(tag, props, children))`
  font-family: ${fonts.inter.regular};
  line-height: 140%;
  
  ${(props) => props.size === 1 && css`
    font-size: ${ptr(48)};
    @media (max-width: ${ptr(breakpoints.lg)}) {
      font-size: ${ptr(32)};
    }
  `}
  
  ${(props) => props.size === 2 && css`
    font-size: ${ptr(32)};
  `}
  
  ${(props) => props.size === 3 && css`
    font-size: ${ptr(24)};
  `}

  ${(props) => props.size === 4 && css`
    font-size: ${ptr(20)};
  `}

  ${(props) => props.size === 5 && css`
    font-size: ${ptr(16)};
  `}
  
  ${(props) => props.size === 6 && css`
    font-size: ${ptr(14)};
  `}
  
  ${(props) => props.size === 7 && css`
    font-size: ${ptr(12)};
  `}

  ${(props) => props.size === 8 && css`
    font-size: ${ptr(10)};
  `}
  
  ${(props) => props.color && css`
    color: ${props.color};
  `}

  ${(props) => props.background && css`
  background: ${props.background};
`}

  ${(props) => props.bold && css`
    font-family: ${fonts.inter.bold};
  `}
`;

export {
  StyledText,
};
