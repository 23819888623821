import React from 'react';
import { Button } from '../../../../components/Button';
import { CircleDecorators } from '../../../../components/CircleDecorator';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';

import * as S from './styles';

export interface AccountBalanceProps {
  /**
   * Example page property
   */
  example?: string,
}
const CIRCLES_OPTIONS = [
  {
    radius: 100,
    rotate: 46,
    position: {
      left: '-0%',
      bottom: '25%',
    },
  },
  {
    radius: 40,
    rotate: 60,
    position: {
      left: '30%',
      bottom: '30%',
    },
  },
  {
    radius: 95,
    rotate: 60,
    position: {
      left: '45%',
      bottom: '-45%',
    },
  },
  {
    radius: 28,
    rotate: 60,
    position: {
      right: '30%',
      top: '0%',
    },
  },
  {
    radius: 130,
    rotate: 150,
    position: {
      right: '-5%',
      top: '-40%',
    },
  },
];
export const AccountBalance: React.FC<AccountBalanceProps> = () => (
  <S.Container>
    <CircleDecorators
      circles={CIRCLES_OPTIONS}
    >
      <S.Wrapper>
        <S.Row>
          <S.Column>
            <Text size={7} color={colors.white._100}>
              Summary Account Balance
            </Text>
            <S.AmountWrapper>
              <Text size={2} bold color={colors.white._100}>
                ₦ 118 090 560.50
              </Text>
            </S.AmountWrapper>
          </S.Column>
          <Button size="small" label="Request account statement" theme="light" />
        </S.Row>
        <S.Row>
          <S.Column>
            <Text size={7} color={colors.white._100}>
              Debit acc. 7263746273642734
            </Text>
            <S.AmountWrapper>
              <Text size={5} bold color={colors.white._100}>
                ₦ 62 823 920.00
              </Text>
            </S.AmountWrapper>
          </S.Column>

          <S.Column>
            <Text size={7} color={colors.white._100}>
              Credit acc. 7263746273642734
            </Text>
            <S.AmountWrapper>
              <Text size={5} bold color={colors.white._100}>
                ₦ 16 730 150.50
              </Text>
            </S.AmountWrapper>
          </S.Column>

          <S.Column>
            <Text size={7} color={colors.white._100}>
              Debit acc. 7263746273642734
            </Text>
            <S.AmountWrapper>
              <Text size={5} bold color={colors.white._100}>
                ₦ 35 000 000.00
              </Text>
            </S.AmountWrapper>
          </S.Column>

          <S.Column>
            <Text size={7} color={colors.white._100}>
              Saving acc. 7263746273642734
            </Text>
            <S.AmountWrapper>
              <Text size={5} bold color={colors.white._100}>
                ₦ 3 536 490.00
              </Text>
            </S.AmountWrapper>
          </S.Column>
        </S.Row>
      </S.Wrapper>
    </CircleDecorators>
  </S.Container>
);
