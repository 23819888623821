export const SIGN_UP: string = "signup";

export const industryTypes: Array<string> = [
	"Agriculture",
	"Auto parts",
	"Construction",
	"Digital service",
	"E-commerce",
	"Electronics",
	"Fashion and beauty",
	"Financial services",
	"General services",
	"Haulage",
	"Hospital and health",
	"Household items",
	"Leisure and entertainment",
	"Logistics",
	"Others",
];

export interface Props {
	name: string,
	value: string,
};

export const businessTypes: Array<Props> = [
	{
		name: "Business Name",
		value: "bn"
	},
	{
		name: "Registered Corporation",
		value: "co"
	},
	{
		name: "Incorporated Trustees",
		value: "it"
	}
];

export enum UploadEnum {
	proprietorId = 'proprietorId',
	partnerId = 'partnerId',
	directorId = 'directorId',
	trusteeId = 'trusteeId',
	principalId = 'principalId',
	otherRegDoc = 'OtherRegistrationDocuments', //cacdoc
	certOfReg = 'certOfReg', //cacdoc
	proofOfAddress = 'proofOfCompanyAddress',
	scuml = 'scuml',
	cacDocs = 'cacDocs',
	applicationOfReg = 'applicationOfReg',
	certOfIncorporation = 'certOfIncorporation',
	constitutionOrTrusteeDeed = 'constitutionOrTrusteeDeed',
	cac2 = 'cac2',
	cac7 = 'cac7',
	memart = 'memart',
	partnershipDeed = 'partnershipDeed',
	trusteeResolution = 'trusteeResolution',
	boardResolution = 'boardResolution',
	uboCac = 'uboCac',
	proofOfRegistration = 'proofOfRegistration',
	minuteOfMeeting = 'minuteOfMeeting',
	ruleOrConstitution = 'ruleOrConstitution',

	// proprietorSignature = 'proprietorSignature',
	// partnerSignature = 'partnerSignature',
	// coopSignature = 'coopSignature',
	// trusteeSignature = 'trusteeSignature',
	// directorSignature = 'directorSignature',

	// utility = 'utility',

}

export const documentLabelAndValuePair = {
	'Sole proprietor’s valid ID': UploadEnum.proprietorId,
	'1st Partner\'s valid ID': UploadEnum.partnerId,
	'2nd Partner\'s valid ID': UploadEnum.partnerId,
	'1st Principal officer\'s valid ID': UploadEnum.principalId,
	'2nd Principal officer\'s valid ID': UploadEnum.principalId,
	'1st Trustee\'s valid ID': UploadEnum.trusteeId,
	'2nd Trustee\'s valid ID': UploadEnum.trusteeId,
	'1st Director\'s valid ID': UploadEnum.directorId,
	'2nd Director\'s valid ID': UploadEnum.directorId,
	'Certificate of Registration': UploadEnum.certOfReg,
	'Other registration documents (status report or CAC BN/1)': UploadEnum.otherRegDoc,
	'Application for registration of company (status report or CAC BN/1)': UploadEnum.applicationOfReg,
	'Certificate of Incorporation': UploadEnum.certOfIncorporation,
	'Certified true copy of constitution/Trustee deed': UploadEnum.constitutionOrTrusteeDeed,
	'CAC 2 (Particulars of Shareholders)': UploadEnum.cac2,
	'CAC 7 (Particulars of Directors)': UploadEnum.cac7,
	'MEMART (Memorandum and Articles of the Association)': UploadEnum.memart,
	'Proof of registration': UploadEnum.proofOfRegistration,
	'Minute of the meeting for account opening': UploadEnum.minuteOfMeeting,
	'Rules/Constitution of the society': UploadEnum.ruleOrConstitution,
	'Partnership deed': UploadEnum.partnershipDeed,
	'Board of trustee’s resolution for account opening': UploadEnum.trusteeResolution,
	'Board resolution': UploadEnum.boardResolution,
	'Proof of company’s address': UploadEnum.proofOfAddress,
	'SCUML certificate (Special Control Unit against Money Laundering) ': UploadEnum.scuml,
	'CAC document of UBO': UploadEnum.uboCac,
	'CAC Documents': UploadEnum.cacDocs,
}

export enum BusinessTypesEnum {
	soleProprietorship = "sole_proprietorship",
	partnership = "partnership",
	limitedLiability = "limited_liability",
	cooperativeSociety = "co_operative_society",
	trusteeNgo = "trustee_ngo",
}

export const validIdTypes: Array<{ label: string; value: string, extra?:string }> = [
	{ label: "Driver's License", value: "drivers_license" },
	{ label: "National ID Card", extra:' (NIN)', value: "nin" },
	{ label: "Voter's Card", value: "voter_id" },
	//  { label: "International Passport", value: "passport" },
];

export enum idTypes {
	drivers_license = "drivers_license",
	nin = "nin",
	voters_card = "voter_id",
	international_passport = "passport",
}
