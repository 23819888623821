import { FC } from 'react';

const Overlay: FC<{ handleClick?: () => void }> = ({ handleClick }) => {
  return (
    <div
      onClick={() => (handleClick ? handleClick() : null)}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,

        width: '100%',
        minHeight: '100%',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: 100,
      }}
    ></div>
  );
};

export default Overlay;
