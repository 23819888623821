import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const Wrapper = styled.div`
  position: absolute;
  top:80px;
  right:0px;
  width: 80%;
  height: 100vh;
  background-color:rgba(255,255,255, .8);
  z-index:1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, Wrapper };
