import { FC } from 'react';
import { colors } from '../../styles/variables';
import { Text } from '../Text';
import * as S from './styles';
import { formatCash } from '../../utils/money';
import { Loader } from '../Loader';

interface IProp {
  totalLimit: number;
  usedLimit: number;
  availableLimit: number;
  loading: boolean;
}

const LimitProgress: FC<IProp> = ({
  totalLimit,
  usedLimit,
  availableLimit,
  loading,
}) => {
  if (loading)
    return (
      <S.LimitSliderContainer>
        <Loader />
      </S.LimitSliderContainer>
    );

  return (
    <S.LimitSliderContainer>
      <S.LimitSliderContent>
        <Text size={5} color={colors.black._20} style={{ fontWeight: 700 }}>
          Daily Transaction Limit
        </Text>
        <Text size={5} color={colors.black._20} style={{ fontWeight: 700 }}>
          ₦{formatCash(totalLimit)}
        </Text>
      </S.LimitSliderContent>
      <S.LimitSliderContent>
        <S.ProgressBarContainer>
          <S.Filler
            completed={
              usedLimit && totalLimit ? (usedLimit * 100) / totalLimit : 0
            }
          />
          {/* <S.Label>{`${30}%`}</S.Label> */}
          {/* </S.Filler> */}
        </S.ProgressBarContainer>
      </S.LimitSliderContent>
      <S.LimitSliderContent>
        <S.OtherContent>
          <S.Dot />
          <Text color={colors.black._30} size={6}>
            ₦{formatCash(usedLimit)} sent
          </Text>
        </S.OtherContent>
        <S.OtherContent>
          <Text size={6} color="#00966D">
            ₦{formatCash(availableLimit)}
          </Text>
          <Text size={6} color={colors.black._20}>
            Left
          </Text>
        </S.OtherContent>
      </S.LimitSliderContent>
    </S.LimitSliderContainer>
  );
};

export default LimitProgress;
