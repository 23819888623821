import styled from "styled-components";

import { ptr } from "../../../../styles/utils";
import { colors } from "../../../../styles/variables";

const Container = styled.div``;

const TopTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${ptr(10)};
  width:95%;
`;

const Reference = styled.div`
  margin-top: ${ptr(16)};
`;
const InfoContainer
= styled.div`
width:85%;
  margin: 4px 0px 30px 0;
`;

const FirstReference = styled.div``;

const Wrapper = styled.div`
  margin-bottom: ${ptr(82)};
`;

const SecondReference = styled.div`
  margin-top: ${ptr(53)};
  margin-bottom: ${ptr(90)};
`;

const Block = styled.div``;

const DropzoneContainer = styled.div``;

const ButtonGroup= styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  gap: ${ptr(12)};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`;

const ItemContainer = styled.div``;

export {
  Container,
  Block,
  InfoContainer,
  DropzoneContainer,
  ButtonGroup,
  Wrapper,
  TopTitle,
  FirstReference,
  SecondReference,
  ItemContainer,
  ItemRow,
  HeaderRow,
  Remove,
  Reference,
};
