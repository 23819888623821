import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { CheckBox } from "../../components/CheckBox";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { validateObject, ValidateResult } from "../../utils/validation";

import * as S from "./styles";
import { failed, success } from "../../components/Toasts";
import authService from "../../services/internal/auth/auth.service";
import { colors } from "../../styles/variables";
import { ModalWrapper } from "../../components/ModalWrapper";
import { Modal } from "../../components/LoadingModal";
import onboardingService from "../../services/internal/onboarding/onboarding.service";
import Terms from "../../assets/documents/VBiz Terms of Use & Privacy Policy.pdf";
import { TextInput } from "../../components/TextInput";
import SignatureUpload from "../Onboarding/steps/common.upload.documents/directors.signature";
import { DocumentUpload } from "../../components/DocumentUpload";
import logo from "../../assets/images/newLogo.png";
import { BankNames } from "../OtherBankTransfer";
import banksService from "../../services/internal/banks/banks.service";
import {
  DropdownInput,
  DropdownOptionProps,
} from "../../components/DropdownInput";

const numberMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 11,
  allowNegative: false,
  allowLeadingZeroes: true,
};

const yearsMaskOptions = { ...numberMaskOptions, integerLimit: 2 };

const accountNumberMaskOptions = { ...numberMaskOptions, integerLimit: 10 };

const SCHEME = {
  years: (value: string) => Number(value) > 0,
  phone: (value: string) => value.length === 11,
  address: (value: string) => value.length > 3,
  comment: (value: string) => value.length > 3,
  signature: (value: string) => !!value,
  bankName: (value: string) => value.length > 2,
  accountName: (value: string) => value.length > 3,
  accountNumber: (value: string) => value.length === 10,
};

export const ReferenceForm: React.FC = () => {
  const { search } = useLocation();
  const route = useHistory();

  const reference = new URLSearchParams(search)?.get("reference") ?? "";
  const email = new URLSearchParams(search)?.get("email") ?? "";

  const [verifying, setVerifying] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  //form state
  const [years, setYears] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [signature, setSignature] = useState<any>();
  const [bankName, setBankName] = useState<DropdownOptionProps>();
  const [accountName, setAccountName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");

  const [validation, setValidation] = useState<ValidateResult>();
  const [validInProgress, setValidInProgress] = useState(false);
  const [bankNames, setBankNames] = useState<Array<BankNames>>([]);
  const [destinationAccountLoading, setDestinationAccountLoading] = useState<
    boolean
  >(false);

  const verifyHandle = async () => {
    try {
      const response = await onboardingService.verifyReference({
        email,
        reference,
      });
      setData(response);
      getBankNames();
    } catch (error) {
      const err = error as Error;
      failed("Failed", err.message || "No record found");
      route.push("/");
    }
    setVerifying(false);
  };

  useEffect(() => {
    verifyHandle();

    return () => setData(null);
  }, []);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (accountNumber && accountNumber.length === 10 && bankName?.key) {
        checkResolve();
      }
    }, 800);
    return () => {
      clearTimeout(debounceFn);
    };
  }, [accountNumber, bankName?.key]);

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            years,
            phone,
            address,
            comment,
            signature,
            bankName: bankName?.label,
            accountName,
            accountNumber,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [
    years,
    phone,
    address,
    comment,
    signature,
    bankName,
    accountName,
    accountNumber,
  ]);

  const getBankNames = async (): Promise<any> => {
    setLoading(true);
    try {
      const result = await banksService.getBankNames();
      setBankNames(result);
      setLoading(false);
    } catch (error) {
      const err = error as Error;
      failed("Failed", err.message || "Error retrieving bank names");
    }
  };

  const checkResolve = async (): Promise<void> => {
    setDestinationAccountLoading(true);

    if (accountNumber?.length && bankName?.key) {
      try {
        const result = (await banksService.bankExternalResolve(
          accountNumber,
          bankName?.key
        )) as any;
        setAccountName(result.name);
      } catch (error) {
        const err = error as Error;
        failed("Failed", err.message || "Error validating account number");
      }
      setDestinationAccountLoading(false);
    }
  };

  const convertBankNamesArray = (
    banks: Array<BankNames>
  ): Array<DropdownOptionProps> =>
    banks.map((bank) => ({
      label: bank.name,
      key: bank.nipCode?.toString(),
    }));

  const submitHandle = async (): Promise<void> => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("yearsKnown", years);
    formdata.append("phone", phone);
    formdata.append("address", address);
    formdata.append("comment", comment);
    formdata.append("reference", reference);
    formdata.append("bankName", bankName?.label || "");
    formdata.append("accountName", accountName);
    formdata.append("accountNumber", accountNumber);
    formdata.append("signatureUrl", signature);
    try {
      await onboardingService.submitReferenceForm(formdata);
      success("Success", "Your response has been recorded");
      route.push("/");
    } catch (error) {
      const err = error as Error;
      failed("Failed", err.message || "failed");
    }
    setLoading(false);
  };

  if (verifying)
    return <Modal title="Verifying, please wait..." visible={verifying} />;

  return (
    <div style={{}}>
      <ModalWrapper
        showModal
        crossColor={colors.black._100}
        overflowY="scroll"
        overflowX="inherit"
        maxWidth="60%"
      >
        <S.WrapperP1>
          <S.HeaderWrapper>
            <S.DisplayP1>
              <S.SectionsWrapper>
                <S.DisplayP2>
                  <div>
                    <img src={logo} style={{ width: 200 }} />
                    {/* <Text bold size={5}>
                      VFD
                    </Text> */}
                  </div>
                  {/* <div
                    style={{
                      height: 70,
                      width: 3,
                      background: colors.pink._100,
                      marginLeft: 10,
                      marginRight: 20,
                    }}
                  ></div>
                  <div style={{}}>
                    <div>
                      <Text size={4}>MICROFINANCE</Text>
                    </div>
                    <div>
                      <Text size={4}>BANK</Text>
                    </div>
                  </div> */}
                </S.DisplayP2>
              </S.SectionsWrapper>
              <S.SectionsWrapper>
                <div style={{}}>
                  <Text tag="span" size={2}>
                    REFERENCE FORM
                  </Text>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Text size={7}>
                    Elephant House, 214 Broad Street,Marina, Lagos
                  </Text>
                </div>
              </S.SectionsWrapper>
            </S.DisplayP1>
          </S.HeaderWrapper>

          <div
            style={{ width: "100%", background: colors.pink._100, height: 6 }}
          ></div>

          <S.HeaderWrapperBottomP1>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Text size={4} bold>
                {data?.name?.toUpperCase() ?? ""}
              </Text>
              <br />
              <Text>
                Reference Form For:{" "}
                <Text tag="span" bold>
                  {data?.businessName ?? ""}
                </Text>
              </Text>
            </div>
            <S.SectionsWrapper>
              <Text>
                <TextInput
                  label="Years Known"
                  type="currency"
                  mask={createNumberMask(yearsMaskOptions)}
                  value={years}
                  valid={validation?.data.years.isValid}
                  onChange={setYears}
                />
              </Text>
            </S.SectionsWrapper>

            <S.SectionsWrapper>
              <TextInput
                label="Phone Number"
                type="tel"
                mask={createNumberMask(numberMaskOptions)}
                value={phone}
                valid={validation?.data.phone.isValid}
                onChange={setPhone}
              />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <TextInput
                label="Address"
                value={address}
                valid={validation?.data.address.isValid}
                onChange={setAddress}
              />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <TextInput
                label="Comment"
                value={comment}
                valid={validation?.data.comment.isValid}
                onChange={setComment}
              />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <Text>YOUR BANK DETAILS</Text>
              <hr />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <DropdownInput
                options={convertBankNamesArray(bankNames)}
                title={bankName?.label}
                onSelect={(value) => {
                  if (value) {
                    setBankName(value);
                  }
                }}
                placeholder="Select bank name"
                label="Bank name"
                loading={destinationAccountLoading}
              />
            </S.SectionsWrapper>

            <S.SectionsWrapper>
              <TextInput
                label="Account Number"
                type="currency"
                mask={createNumberMask(accountNumberMaskOptions)}
                value={accountNumber}
                valid={validation?.data.accountNumber.isValid}
                onChange={setAccountNumber}
              />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <TextInput
                label="Account Name"
                value={accountName}
                valid={validation?.data.accountName.isValid}
                onChange={setAccountName}
                disabled
              />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <hr />
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <DocumentUpload
                type={"signature"}
                reference={""}
                isSignature={true}
                isReferenceForm={true}
                onChange={(file) => setSignature(file)}
              />
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Text tag="span">Signature:</Text>
              </div>
            </S.SectionsWrapper>
            <S.SectionsWrapper>
              <Text bold color={colors.pink._60}>
                CAUTION: IT IS VERY DANGEROUS TO INTRODUCE SOMEONE WHO IS NOT
                WELL KNOWN TO YOU
              </Text>
            </S.SectionsWrapper>

            <S.ButtonWrapper>
              <Button
                label="Submit"
                loading={loading}
                disabled={!validation?.isValid || loading}
                onClick={submitHandle}
              />
            </S.ButtonWrapper>
          </S.HeaderWrapperBottomP1>
        </S.WrapperP1>
      </ModalWrapper>
    </div>
  );
};
