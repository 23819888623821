import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const Container = styled.div`
 
`;

const TopTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width:85%;
  margin-bottom: ${ptr(70)};
`;

const TopTextInfo = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
margin-bottom:14px;
cursor:pointer;
gap: 8px;`;

const Wrapper = styled.div`
display:flex;
flex-direction:column;
  >:first-child{
    margin-bottom: ${ptr(32)};
  }
`;

const ButtonGroup= styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

const File = styled.div`
  background-color: ${colors.black._40};
  border: ${ptr(1)} solid ${colors.black._5};
  box-sizing: border-box;
  border-radius: ${ptr(4)};
  width: ${ptr(104)};
  height: ${ptr(104)};
  margin-bottom: ${ptr(32)};
  background-image: url('../../images/documents/certificate-3.jpeg');
  background-size: cover;
  position: relative;
`;

const FullScreen = styled.button`
  position: absolute;
  top: ${ptr(4)};
  right: ${ptr(4)};
  padding: 0;
  border: 0;
  background: transparent;
`;

export {
  Container, Wrapper, File, FullScreen, TopTitle, ButtonGroup,TopTextInfo
};
