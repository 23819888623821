import styled from 'styled-components';

import { ptr } from '../../../../styles/utils';

const TextInputWrapper = styled.div`
  margin-bottom: ${ptr(12)};
`;
const CheckBoxesWrapper = styled.div`
  margin-top: ${ptr(32)};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  
  :not(:last-child) {
    margin-bottom: ${ptr(16)};
  }
`;

export {
  CheckBoxesWrapper,
  CheckBoxWrapper,
  TextInputWrapper,
};
