import { ThunkAction } from 'redux-thunk';
import userService from '../../services/internal/user/user.service';
import { ActionInterface } from '../IAction';

import { IPreference } from './IPreference';
import { AnyAction } from 'redux';

export const GET_PREFERENCE = 'GET_PREFERENCE';

export const getPreference = (
  payload: IPreference
): ActionInterface<IPreference> => ({
  type: GET_PREFERENCE,
  payload,
});

export class PreferenceActions {
  public static async getPreference(): Promise<IPreference> {
    const result = (await userService.getPreference()) as IPreference;
    return result;
  }
}
