import React, { useState } from 'react';

import * as S from './styles';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';
import { FileUpload } from '../FileUpload';
import { Button } from '../Button';

import { handleDownloadFile } from '../../pages/BeneficiariesManagement/action';
import paymentsService from '../../services/internal/payments/payments.service';
import { failed } from '../Toasts';
import { downloadCSV } from '../../utils/download';

export interface UploadFileModalProps {
  onCloseModal?: () => void;
  visible?: boolean;
  onAdd?: () => void;
  setFile?: (file: File | undefined) => void;
  loading?: boolean;
  file?: File;
  bulkTransfer?: boolean;
}

export interface IBulkTransferFileContent {
  bankCode: string;
  accountNumber: string;
  amount: string;
  narration: string;
}

export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  visible = false,
  onCloseModal = () => { },
  onAdd = () => { },
  setFile = () => { },
  loading = false,
  file,
  bulkTransfer = false,
}) => {
  const [bankNamesLoading, setBankNamesLoading] = useState<boolean>(false);
  const getBankNames = async (): Promise<any> => {
    setBankNamesLoading(true);
    try {
      if (!bankNamesLoading) {
        const result = await paymentsService.getBankNames();

        const newArray = result.map((res: any) => ({
          'Bank name': res.name,
          'Bank Code': `'${res.nipCode}`,
        }));
        const field1 = ['Bank name', 'Bank Code'];
        downloadCSV(newArray, 'bankCodes', field1);
      }
    } catch (err) {
      const error = err as Error;
      failed('Failed', error.message || 'Error retrieving bank names');
    }
    setBankNamesLoading(false);
  };
  return (
    <ModalWrapper
      showModal={visible}
      onCloseModal={onCloseModal}
      overflowY="hidden"
      crossColor={colors.black._100}
    >
      <S.Wrapper>
        <Text size={2} bold>
          Upload Payments
        </Text>
        <S.TextContainer>
          <Text color={colors.black._60}>
            Use the{' '}
            <S.Link onClick={() => handleDownloadFile(bulkTransfer)}>
              template
            </S.Link>{' '}
            to upload the list of beneficiaries correctly. Click to download{' '}
            <S.Link onClick={() => getBankNames()}>bank codes</S.Link>.
          </Text>
        </S.TextContainer>
        <FileUpload
          file={file}
          onChange={setFile}
          acceptedFormats={[
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]}
        />
        <S.HelpText>
          <Text color={colors.black._40}>
            Supporting file type: *.csv, *.xlsx or *.xls only
          </Text>
        </S.HelpText>
        <Button size="medium" label="Add" onClick={onAdd} disabled={loading} />
      </S.Wrapper>
    </ModalWrapper>
  );
};
