import styled, { css } from 'styled-components';
import { ptr, hexToRgbA } from '../../styles/utils';
import { breakpoints, colors, transition } from '../../styles/variables';
// import { hexToRgbA, ptr } from '../../../../styles/utils';

const Container = styled.div``;
const ModalWrapper = styled.div`
padding: ${ptr(30)};
 `;
const CheckboxWrapper = styled.div`
    padding: 10px;
    border: 0.3px solid  ${colors.black._60};
    border-radius: 20px;
    margin-bottom: 10px;
`;

const ButtonWrapper1 = styled.div`
width:100%;
margin-top:30px;
button{
  width:100%;
}

`;
const TextInputContainer = styled.div`
display:flex;
/* margin-bottom: 10px; */
`;
const TextInputWrapper = styled.div`
flex:1;
margin-bottom: 10px;
`;
const SpaceWrapper = styled.div`
margin: 0 5px;
`;
const CheckboxContainer = styled.div`
display: flex;
flex-wrap:wrap;
margin-bottom: 10px;
`;

const TableNedded = styled.div`
width:100%;
table, th,tr:nth-child(1)    {
  /* border-bottom: 1px solid black; */
  border-collapse: collapse;
  width:100%
}
tr:nth-child(2){
border-top:1px solid;
}
th, td {
  padding: 15px 5px;
  text-align: left;    
}
`;
// no nedded
const TopWrapper = styled.div`
padding: 20px;
`;
const TopContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
flex-wrap: wrap;
`;
const Flex = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
`;
const MarginBottom = styled.div`
margin-bottom: 20px;
`;

const Header = styled.div`
position: sticky;
top:40px;
background-color: #ffffff;
z-index:99999;
`;

const TextsContainer = styled.div`
display: flex;
flex-direction: column;

margin-right:25px;
`;

const BtnSuccess = styled.div`
background: #F3FDF3;
color: #36B83B;
padding: 10px 15px;
border-radius: 10px;
`;

const BtnWarning = styled.div`
background: #FFF4D8;
color: #F3A100;
padding: 10px 15px;
border-radius: 10px;
`;

const BtnDanger = styled.div`
background: #F3FDF3;
color: #36B83B;
padding: 10px 15px;
border-radius: 10px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${ptr(8)};
  margin-bottom: ${ptr(40)};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  flex: 1;
  justify-content: flex-end;
  padding: 0;

  > svg {
    margin-left: ${ptr(14)};
  }
`;

const TabNav = styled.div`
  flex: 2;
  display: flex;
  overflow: scroll;

  > a {
    text-decoration: none;
  }

  > :not(:last-child) {
    margin-right: ${ptr(48)};
  }
`;

// const Wrapper = styled.div`
//   display: flex;
// `;

const LeftContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: ${ptr(32)};
`;

const RigthContainer = styled.div`
  flex: 1;
  margin-left: ${ptr(32)};
  display: flex;
  flex-direction: column;
  gap: ${ptr(32)};
`;
const Wrapper = styled.div`
  padding: ${ptr(64)};
  width: 60vw;
  min-height: calc(100vh - ${ptr(270)});
`;
const TopBarWrapper = styled.div`
  display: flex;
  padding: ${ptr(24)} 0;
  width: 72%;

`;

const ButtonWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white._100};
  padding: ${ptr(24)} ${ptr(64)};
  display: flex;
  border-top-width: 1px;
  border-color: ${colors.black._10};
  border-style: solid;
  justify-content: flex-end;
  position: sticky;
`;

const ButtonsGroup = styled.div`
  display: flex;
  min-width: ${ptr(240)};

  & > :not(:last-child) {
    margin-right: ${ptr(16)};
  }
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${ptr(16)};
  border-radius: ${ptr(8)};
  width: 100%;
  border: 1px solid ${colors.black._10};
  margin-right: ${ptr(32)};
`;

const Block = styled.div`
  position: relative;
`;

const ButtonItem = styled.button`
  width: ${ptr(48)};
  height: ${ptr(48)};
  background: ${colors.black._5};
  border-radius: ${ptr(6)};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: ${transition.slow};

  &[disabled] {
    opacity: 0.6;
  }
`;

const TextField = styled.input`
  width: inherit;
  outline: none;
  border: none;
  color: ${colors.black._100};
  transition: .3s;
  margin-left: ${ptr(-40)};
  margin-right: ${ptr(-16)};
  padding: ${ptr(16)} ${ptr(16)} ${ptr(12)} ${ptr(50)};
  background: transparent;

  &::placeholder {
    color: ${colors.black._40}
  }
`;

const Dropdown = styled.div`
  background: ${colors.white._100};
  position: absolute;
  top: ${ptr(60)};
  box-sizing: border-box;
  box-shadow: 0px ${ptr(4)} ${ptr(26)} ${hexToRgbA(colors.black._100, 20)};
  width: ${ptr(258)};
  border-radius: ${ptr(8)};
  right: ${ptr(-138)};
  z-index: 1;
`;

const CloseDropdown = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top : ${ptr(24)};
  right: ${ptr(24)};
  position: absolute;
`;

const DropdownContent = styled.div`
  padding: ${ptr(24)};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-height: ${ptr(476)};
  overflow: scroll;
  z-index: 1,
`;

const DropdownBottom = styled.div`
  padding: ${ptr(16)};
  border-top: ${ptr(1)} solid ${colors.black._5};
  display: flex;
  justify-content: space-between;

  > button {
    padding: ${ptr(12)};
    flex: 1;

    &:last-child {
      flex: 1.4;
      margin-left: ${ptr(12)};
    }
  }
`;

const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: ${ptr(24)};
  }

  > span {
    margin-bottom: ${ptr(12)};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  > div {
    margin: ${ptr(12)} 0;
  }
`;

const PreloadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ptr(94)} 0;
`;

const TableContainer = styled.div<{ loading: boolean }>`
  ${(props) => props.loading && css`
    opacity: 0.8;
  `}
`;

const Column = styled.div`
  > * {
    display: block;
    text-transform: capitalize; 
  }
`;

const WrapperContainer = styled.div`
padding: 20px 10px;
margin: 20px;
border:1px solid
`;

const WrapperContainer2 = styled.div`
padding: 40px 50px;
margin: 40px 0;
border:1px solid
`;

const CloseButton = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
margin-right: 10px;
`;

const GroupWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const ImageWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 20px;

img{
  width: 70%;
}
`;

const TextWrapper = styled.div`
margin-bottom: 5px;
`;
const TextWrapper1 = styled.div`
margin-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${ptr(40)};
`;

const CompanyDetailsContainer = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

const DetailsContainer = styled.div`
  margin-top:${ptr(40)} ;
`;

// User management styles
const UserWrapper = styled.div`
  width: 100%;
`;
const TableWrapper = styled.div`
  margin-top: ${ptr(30)};
`;

const TableControls = styled.div`
  display:flex; 
  justify-content: space-between;
    @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
   /* height: 200px; */
  }
  margin-bottom: 26px;
`;
const SearchContainer = styled.div`
  width:60%;
  margin-right: ${ptr(30)};
    @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
display: flex;
justify-content: space-between;
Button{
  margin-right: 10px
}
 @media (max-width: ${breakpoints.sm}px) {
   margin-top: 20px;
   justify-content: space-around;
    flex-direction: row;

    Button{
      margin-right: 20px;
    };
    /* width: 100%;
    margin-bottom: ${ptr(30)} */
  }
`;

const LoginPasswordInputWrapper = styled.div`
 margin-top: ${ptr(15)};
`;
const IconWrapp = styled.div`
 
  display: flex;
  cursor: pointer;
`;

const RangeWrapper = styled.div`
  
`;

export {
  Container, TabNav, Head, Button,
  LeftContainer, RigthContainer,
  TableContainer,
  PreloadContainer,
  Wrapper,
  TopBarWrapper,
  ButtonWrapper,
  ButtonsGroup,
  InputBox,
  ButtonItem,
  TextField,
  Dropdown,
  CloseDropdown,
  DropdownBottom,
  DropdownContent,
  FilterBox,
  Content,
  Block,
  Column,
  TopWrapper,
  TopContainer,
  Flex,
  MarginBottom,
  TextsContainer,
  BtnSuccess,
  BtnWarning,
  BtnDanger,
  WrapperContainer,
  CloseButton,
  GroupWrapper,
  ImageWrapper,
  TextWrapper,
  WrapperContainer2,
  TextWrapper1,
  CheckboxWrapper,
  ButtonWrapper1,
  ModalWrapper,
  TextInputContainer,
  TextInputWrapper,
  SpaceWrapper,
  CheckboxContainer,
  TableNedded,

  Row,
  CompanyDetailsContainer,
  DetailsContainer,
  UserWrapper,
  TableWrapper,
  TableControls,
  SearchContainer,
  ButtonContainer,
  LoginPasswordInputWrapper,
  IconWrapp,
  Header,
  RangeWrapper
};
