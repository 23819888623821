import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import * as S from './styles';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';
import { formatCash } from '../../../../utils/money';

export interface DonutProps {
  id: string,
  color:string,
  value: string,

}
export interface DonutChartProps {
  // CatData: Array<DonutProps>
  CatData: any

}

export const DonutChart: React.FC <DonutChartProps> = (props) => (
  <S.Container>
    {/* <Text bold size={4}>Spend by categories</Text> */}
    <S.Flex>
      <S.Wrapper>
        <ResponsivePie
          colors={props.CatData.map((el: { color: any }) => el.color)}
          data={props.CatData}
          isInteractive={false}
          margin={{
            top: 30, right: 12, bottom: 12, left: 12,
          }}
          innerRadius={0.7}
          padAngle={1.3}
          startAngle={-150}
          enableArcLinkLabels={false}
          enableArcLabels={false}
        />
      </S.Wrapper>
      <S.Legend>
        {props.CatData.map((el: { color: string; id: string | any[]; value: any; }) => (
          <S.Row>
            <S.CirclePink color={el.color} />
            <S.Block>
              <Text size={6}>{el.id[0].toLocaleUpperCase() + el.id.slice(1)}</Text>
              <Text size={6} color={colors.black._60}>
                ₦
                {' '}
                {formatCash(String(el.value))}
              </Text>
            </S.Block>
          </S.Row>
        ))}
      </S.Legend>
    </S.Flex>
  </S.Container>
);
