import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const Container = styled.div`
  span {
    display: block;
  }
`;

const Wrapper = styled.div`
  > span {
    margin-top: ${ptr(8)};
    margin-bottom: ${ptr(32)};
  }
`;

const OtpWrapper = styled.div`
> :first-child {
  margin-bottom: ${ptr(24)};
}
`;

const TextContainer = styled.div`
display:flex;
flex-wrap:wrap;
  text-align: center;
  margin-top: 20px;
 gap:4px;
  
`;

const ButtonGroup = styled.div`
width:100%;
border-top: 4px solid #DBDFE4;
padding-top:14px;
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right;
}
 
> button{
  display:flex; 
    width:80%;
}
`;

export {
  Container, Wrapper, OtpWrapper, TextContainer, ButtonGroup,
};
