import { FC, useEffect, useState } from 'react';
import { colors } from '../../../styles/variables';
import { ModalWrapper } from '../../../components/ModalWrapper';
import * as S from '../styles';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { failed, success } from '../../../components/Toasts';
import { copyToClipboard } from '../../../utils/collections';
import { useHistory } from 'react-router-dom';

interface IProps {
  toggle: () => void;
  show: boolean;
}
const TwoFactorNotificationModal: FC<IProps> = ({ toggle, show }) => {
  const history = useHistory();
  const handleCopy = async () => {
    try {
      await copyToClipboard('support@vbiz.ng');
      success('Copied', 'Email copied successfully');
    } catch (err) {
      const error = err as any;
      failed('Failed', error.message ?? 'Can not copy text at the moment');
    }
  };
  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      //   onCloseModal={toggle}
      crossColor={colors.black._100}
    >
      <S.TwoFactorContainer>
        <S.IconContainer>
          <Icon name="warning" />
        </S.IconContainer>
        <S.TextContainer>
          <Text size={5} bold>
            Contact support
          </Text>
        </S.TextContainer>
        <S.TextContainer>
          <Text size={6}>
            You haven't set up Two-Factor Authentication (2FA) yet. Please reach
            out to our support team at{' '}
            <span style={{ color: colors.pink._5 }}>support@vbiz.ng </span>to
            request a password reset. We'll be happy to assist you!"
          </Text>
        </S.TextContainer>
        <S.ButtonContainer>
          <Button
            label="Back to login"
            onClick={() => history.push('/')}
            theme="info"
          />
          <Button label="Copy support email" onClick={handleCopy} />
        </S.ButtonContainer>
      </S.TwoFactorContainer>
    </ModalWrapper>
  );
};

export default TwoFactorNotificationModal;
