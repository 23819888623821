import { ActionInterface } from '../IAction';
import { AuthInterface } from './IAuthorization';
import {
  AUTHORIZATION_FAILURE,
  AUTHORIZATION_REQUEST,
  AUTHORIZATION_SUCCESS,
  LOG_OUT,
  SET_PERMISSIONS,
  SET_PUBLIC_KEY,
  SET_ROLES,
} from './actions';
import authService from '../../services/internal/auth/auth.service';

const initialState: AuthInterface = {
  loading: false,
  success: false,
  failure: false,
  error: { message: '', code: '' },
  permissions: authService?.getPermissions(),
  roles: authService?.getRoles(),
  publicKey: null,
};

const reducer = (
  state = initialState,
  action: ActionInterface<unknown>
): AuthInterface => {
  switch (action.type) {
    case AUTHORIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        failure: false,
      };
    case AUTHORIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload as Array<string>,
      };
    case AUTHORIZATION_FAILURE:
      return {
        ...state,
        permissions: [],
        loading: false,
        success: false,
        failure: true,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload as string[],
      };
    case LOG_OUT:
      return {
        ...state,
        permissions: [],
        loading: false,
        success: false,
        failure: false,
      };
    case SET_PUBLIC_KEY:
      return {
        ...state,
        publicKey: action.payload as CryptoKey,
      };
    default:
      return state;
  }
};

export default reducer;
