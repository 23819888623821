import React from 'react';
import { Icon } from '../../../../components/Icon';
import { Text } from '../../../../components/Text';
import { colors } from '../../../../styles/variables';

import * as S from './styles';

export interface TotalResultsProps {
  /**
   * Example page property
   */
  example?: string,
}

export const TotalResults: React.FC<TotalResultsProps> = () => (
  <S.Wrapper>
    <S.Row>
      <S.IconWrapper>
        <Icon name="arrow-down-right" color={colors.black._100} />
      </S.IconWrapper>
      <S.Column>
        <Text size={7} color={colors.black._40}>
          Total Expences
        </Text>
        <S.AmountWrapper>
          <Text color={colors.black._100} size={4} bold>
            ₦ 15 500 840.30
          </Text>
        </S.AmountWrapper>
      </S.Column>
    </S.Row>

    <S.Row>
      <S.IconWrapper>
        <Icon name="money" color={colors.black._100} />
      </S.IconWrapper>
      <S.Column>
        <Text size={7} color={colors.black._40}>
          Summarized Transactions Value
        </Text>
        <S.AmountWrapper>
          <Text color={colors.black._100} size={4} bold>
            ₦ 62 118 652.82
          </Text>
        </S.AmountWrapper>
      </S.Column>
    </S.Row>

    <S.Row>
      <S.IconWrapper>
        <Icon name="arrow-left-right" color={colors.black._100} />
      </S.IconWrapper>
      <S.Column>
        <Text size={7} color={colors.black._40}>
          Summarized Transactions Volume
        </Text>
        <S.AmountWrapper>
          <Text color={colors.black._100} size={4} bold>
            837
          </Text>
        </S.AmountWrapper>
      </S.Column>
    </S.Row>
  </S.Wrapper>
);
