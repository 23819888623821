import React from 'react';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Text';
import { colors } from '../../../styles/variables';
import { useHistory } from 'react-router-dom';

export interface OnboardingTrackerProps {
  trackerItems: Array<{
    done: boolean;
    text: string;
    isActive: boolean;
    defaultOpaciy: number;
    route: string | string[];
    key: string;
  }>;
}
export const OnboardingTracker: React.FC<OnboardingTrackerProps> = ({
  trackerItems,
}) => {
  const history = useHistory();
  const opacityMap = {
    0: 1,
    1: 0.8,
    2: 0.6,
    3: 0.4,
    4: 0.2,
  };

  const next = () => {
    const activeTracks = trackerItems.filter((track) => track.isActive);
    if (activeTracks.length === 0) return;
    const activeTrack = activeTracks[activeTracks.length - 1];

    const currecntTrackIndex = trackerItems.findIndex(
      (track) => track.key === activeTrack.key
    );
    if (currecntTrackIndex >= 0) {
      if (Array.isArray(trackerItems[currecntTrackIndex + 1].route)) {
        if (trackerItems[currecntTrackIndex + 1]?.route[0])
          history.push(trackerItems[currecntTrackIndex + 1]?.route[0]);
      } else {
        if (trackerItems[currecntTrackIndex + 1].route)
          history.push(trackerItems[currecntTrackIndex + 1].route as string);
      }
    }
  };
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      {trackerItems.map((item, i) => (
        <>
          <div
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 15,
              opacity: opacityMap[i as keyof typeof opacityMap],
            }}
          >
            <Icon
              color={item.isActive ? '#B10180' : ''}
              name={item.done ? 'onboarding-check' : 'empty_check'}
            />
            <Text
              size={5}
              color={item.isActive ? '#B10180' : colors.black._20}
              bold={item.isActive}
            >
              {item.text}
            </Text>
          </div>
          <div
            style={{
              height: `${i + 1 === trackerItems.length ? 30 : 40}px`,
              opacity: opacityMap[i as keyof typeof opacityMap],

              borderLeft: `${
                item.isActive && item.key === 'preview'
                  ? 'unset'
                  : i + 1 === trackerItems.length
                  ? 'dashed 2px'
                  : 'solid  1px'
              }`,
              marginLeft: 12,
            }}
          />
        </>
      ))}

      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          display: 'flex',
          padding: '12px 8px',
          flexDirection: 'column',
          alignItems: 'flexStart',
          gap: 16,
          border: '1px solid #EAECF0',
          borderRadius: 8,
        }}
      >
        <div style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <Icon name='arrow-up' />
        </div>
        <div style={{ cursor: 'pointer' }} onClick={next}>
          <Icon name='arrow-down' />
        </div>
      </div>
    </div>
  );
};
