import {
  ILimitInfoResponse,
  ILimitRequestResponse,
  PaymementAccountInterface,
} from '../../services/internal/payments/IPayments';
import { ActionInterface } from '../IAction';
import {
  SET_VALIDATING,
  SET_ERROR,
  RE_SET_ERROR,
  SET_PAYMENT_ACCOUNTS,
  RESET_STATE,
  SET_LIMIT_INFO,
  SET_LIMIT_REQUEST,
  RESET_LIMIT_REQUEST,
} from './actions';
import { PaymentInterface, PaymentValidationErrorInterface } from './IPayment';

const initialState: {
  validating: boolean;
  errors: any[];
  paymentAccounts: Array<PaymementAccountInterface>;
  limitInfo: ILimitInfoResponse['data'] | null;
  limitRequest: ILimitRequestResponse | null;
} = {
  validating: false,
  errors: [],
  paymentAccounts: [],
  limitInfo: null,
  limitRequest: null,
};

const reducer = (
  state = initialState,
  action: ActionInterface<
    Record<string, unknown> | boolean | PaymentValidationErrorInterface
  >
): PaymentInterface => {
  switch (action.type) {
    case SET_VALIDATING: {
      const val = action.payload as boolean;
      if ((val && !state.validating) || (!val && state.validating)) {
        return {
          ...state,
          validating: val,
        };
      }
      return state;
    }
    case SET_ERROR: {
      const val = action.payload as PaymentValidationErrorInterface;
      if (state.errors) {
        return {
          ...state,
          errors: [...state.errors, { ...val }],
        };
      }
      return {
        ...state,
        errors: [{ ...val }],
      };
    }
    case RE_SET_ERROR: {
      return {
        ...state,
        errors: [],
      };
    }
    case SET_PAYMENT_ACCOUNTS: {
      return {
        ...state,
        paymentAccounts: (action.payload as unknown) as Array<
          PaymementAccountInterface
        >,
      };
    }
    case SET_LIMIT_INFO: {
      return {
        ...state,
        limitInfo: action.payload as ILimitInfoResponse['data'],
      };
    }
    case SET_LIMIT_REQUEST: {
      return {
        ...state,
        limitRequest: (action.payload as unknown) as ILimitRequestResponse,
      };
    }
    case RESET_LIMIT_REQUEST: {
      return {
        ...state,
        limitRequest: initialState.limitRequest,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
