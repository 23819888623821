import { FC } from 'react';
import { colors, fonts } from '../../../styles/variables';
import * as S from './styles';
import SampleQR from '../../../assets/images/sampleQR.svg';

import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';

interface IProps {
  handleCancel: () => void;
  handleNext: () => void;
  handleManualSetup: () => void;
  qrCode: string;
}
const StepOne: FC<IProps> = ({
  handleCancel,
  handleNext,
  handleManualSetup,
  qrCode,
}) => {
  return (
    <S.Container>
      <S.TitleContainer>
        <Text bold size={4}>
          Set up authenticator app
        </Text>
      </S.TitleContainer>

      <S.StepContainer>
        <ul style={{ fontFamily: fonts.inter.regular }}>
          <li>
            In the Google Authenticator app tap the{' '}
            <strong style={{ fontFamily: fonts.inter.bold }}>+</strong>
          </li>
          <li>
            Choose{' '}
            <strong style={{ fontFamily: fonts.inter.bold }}>
              Scan a QR Code
            </strong>
          </li>
        </ul>
      </S.StepContainer>
      <S.ImageContainer>
        {qrCode && <img src={qrCode} alt="QR code" />}
      </S.ImageContainer>

      <S.TextContainer>
        <Text size={5}>
          Unable to scan? you can use the{' '}
          <span
            style={{
              color: colors.pink._5,
              cursor: 'pointer',
              fontFamily: fonts.inter.bold,
            }}
            onClick={handleManualSetup}
          >
            Set up key
          </span>{' '}
          to manually setup your authenticator app
        </Text>
      </S.TextContainer>

      <S.ButtonContainer>
        <Button label="Cancel" theme="light" onClick={handleCancel} />
        <div style={{ width: '200px' }}>
          <Button style={{ width: '100%' }} label="Next" onClick={handleNext} />
        </div>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default StepOne;
