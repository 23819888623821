import React, { useEffect, useState } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Button } from '../../components/Button';
import { colors } from '../../styles/variables';
import { Icon } from '../Icon';
import * as S from '../OnBoardModal/styles';
import { Details } from '../../utils/businessTypeModalDetails';
import { Link } from '../Link';
import { handleCamelcasing } from '../../utils/strings';

export interface TypeOfBusinessModalProps {
  onCloseModal?: () => void;
  /**
   * Show modal
   */
  showModal?: boolean;
  crossColor?: string;
  overflowY?: string;
  overflowX?: string;
  margin?: string;
  maxHeight?: string | null;
  maxWidth?: string | null;
  onBottom?: () => void;
  closeBackground?: string;
  details?: Details | null;
  handleProceed: () => void;
}

export const TypeOfBusinessModal: React.FC<TypeOfBusinessModalProps> = ({
  showModal = false,
  crossColor = colors.black._100,
  overflowY = 'auto',
  overflowX = 'auto',
  margin = '0.2rem 0rem',
  maxHeight = null,
  maxWidth = '90%',
  onBottom = () => {},
  handleProceed,
  ...props
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  const [requirementData, setRequirementData] = useState<Details | null>();
  const onCloseModal = () => {
    setRequirementData(null);
    if (props.onCloseModal) props.onCloseModal();
  };

  return (
    <BottomScrollListener onBottom={onBottom}>
      {(scrollRef: any) => (
        <S.Container showModal={showModal}>
          <S.Wrapper
            overflowY={overflowY}
            overflowX={overflowX}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            ref={scrollRef}
            style={{ padding: 35, width: 650 }}
          >
            <S.BackButton regularFont>
              <Link
                icon='arrow-left'
                theme='black'
                text='Back'
                onClick={props.onCloseModal}
              />
            </S.BackButton>

            <S.InfoWrapper backgroundColor={props.details?.color}>
              <div style={{ flex: 1 }}>
                <p style={{ fontWeight: 'bold', marginBottom: 15 }}>
                  {props.details && props.details.title}
                </p>

                <p>{props.details && props.details.info}</p>
              </div>
            </S.InfoWrapper>

            <S.MidTitle>
              Requirements to set up{' '}
              <span> {props.details && props.details.title}</span> business{' '}
            </S.MidTitle>

            <S.ListWrapper>
              {props.details?.list &&
                props.details?.list.map((item, index) => (
                  <S.ListItem key={index}>
                    {item.details} <span>{item.extras}</span>
                  </S.ListItem>
                ))}
            </S.ListWrapper>

            <S.BottomDiv justifyContent='flex-end'>
              <Button
                // onClick={() => signup(requirementData.route)}
                onClick={handleProceed}
                label='Continue'
              />
            </S.BottomDiv>

            {props.children}
          </S.Wrapper>
        </S.Container>
      )}
    </BottomScrollListener>
  );
};
