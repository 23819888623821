import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/Button';
import { Title } from '../../Onboarding/components/Title';
import { Text } from '../../../components/Text';
import { validateObject, ValidateResult } from '../../../utils/validation';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import { actions } from '../config';
import { ImagePreviewModal } from '../../Onboarding/components/ImagePreviewModal';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { BusinessTypesEnum } from '../constants';
import CoperativeCompanyInfo from './co-op.company.info';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { DropdownInputModal } from '../../../components/DropdownInputModal';
import { ModalWrapper } from '../../../components/ModalWrapper';
import MultiOptions from '../../../components/MultiOptions';
import { multiOptionData } from '../../../utils/data';
import TrusteeCompanyEnterDetails from './trustee.company.info';

const SCHEME = {
  name: (value: string) => value.length > 2,
  address: (value: string) => value.length > 2,
  email: (value: string) => value.length > 2,
  cac: (value: string) => value.length > 0,
  tin: (value: string) => value.length > 0,
  phone: (value: string) => value.length === 11,
  industryType: (value: string) => value.length > 0,
  businesType: (value: string) => value?.length > 0,
};

const CommonCompanyEnterDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const credentials = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.credentials as Record<string, string>
  );

  /**
   * Local state initiation region
   */

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const businessInfo = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.businessInfo as Record<string, string>
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.businessType as string
  );

  const corporateBusinessName = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporateBusinessName as string
  );

  const [tin, setTin] = useState<string>(() => businessInfo?.tin ?? '');
  const [cac, setCac] = useState<string>(() => businessInfo?.cac ?? '');

  const [modalVisible, seModalVisible] = useState<boolean>(false);
  const [modalVisibleCo, setCoModalVisible] = useState<boolean>(false);
  const [modalVisibleIt, setItModalVisible] = useState<boolean>(false);
  const [modalVisibleBn, setBnModalVisible] = useState<boolean>(false);
  const [modalVisibleB, seModalVisibleB] = useState<boolean>(false);
  const [rcNumber, setRcNumber] = useState<string>('BN/RC/IT Number');
  const [validation, setValidation] = useState<ValidateResult>();
  const [name, setName] = useState<string>(() => businessInfo?.name ?? '');
  const [email, setEmail] = useState<string>(() => businessInfo?.email ?? '');
  const [number, setNumber] = useState<string>('');
  const [address, setAddress] = useState<string>(businessInfo?.address ?? '');
  const [passValidation, setPassValidation] = useState<ValidateResult>();
  const [validInProgress, setValidInProgress] = useState(false);
  const [industryType, setIndustryType] = useState<string>(
    () => businessInfo?.industryType ?? ''
  );
  // const [businesType, setBusinessType] = useState<DropdownOptionProps | null>();
  const [businesType, setBusinessType] = useState(
    () => businessInfo?.businessType ?? ''
  );
  const [businessTypeLabel, setBusinessTypeLabel] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<string>(() => businessInfo?.phone ?? '');
  const [phoneValid, setPhoneValid] = useState(false);
  /**
   * useEffect region
   */
  useEffect(() => {
    if (businessInfo?.businessType) {
      const businessLabel =
        businessInfo?.businessType === 'co'
          ? 'RC (Registered company)'
          : businessInfo?.businessType === 'bn'
          ? 'BN (Business Name)'
          : businessInfo?.businessType === 'it'
          ? 'IT (Incorporated Trustee)'
          : '';

      setBusinessTypeLabel(businessLabel);
    }
  }, [businessInfo]);
  // useEffect(() => {
  //   if (businesType && businesType.value) {
  //     setRcNumber((prev) => {
  //       const previous = prev.split(' ');
  //       return `${
  //         businesType.value === 'co' ? 'RC' : businesType.value?.toUpperCase()
  //       } ${previous[1]}`;
  //     });
  //   }
  // }, [businesType]);

  useEffect(() => {
    setValidInProgress(true);
    const timer = setTimeout(() => {
      setValidInProgress(false);
      setValidation(
        validateObject(
          {
            name,
            email,
            phone,
            address,
            cac,
            tin,
            industryType,
            businesType,
          },
          SCHEME
        )
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [name, email, cac, phone, address, tin, industryType, businesType]);

  const onCloseModalBT = () => {
    setCoModalVisible(false);
    setItModalVisible(false);
    setBnModalVisible(false);
  };

  const onCloseModal = () => {
    seModalVisible(false);
  };

  const continueHandle = async () => {
    setLoading(true);
    try {
      const response = await onboardingService.submitSignUpBusinessInfo({
        businessInfo: {
          name,
          email,
          cac,
          tin,
          industryType: industryType,
          // industryType: industryType?.value ?? '',
          businessType: businesType,
          address,
          phone,
        },
        reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetBusinessInfo({
            name,
            email,
            cac,
            tin,
            industryType: industryType,
            businessType: businesType,
            address,
            phone,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  if (businessType === BusinessTypesEnum.cooperativeSociety)
    return <CoperativeCompanyInfo />;

  if (businessType === BusinessTypesEnum.trusteeNgo)
    return <TrusteeCompanyEnterDetails />;

  const [selected, setSelected] = useState(
    () => businessInfo?.businessType ?? ''
  );
  const [isCheckeda, setIsCheckeda] = useState(false);
  const [isCheckedb, setIsCheckedb] = useState(false);
  const [isCheckedc, setIsCheckedc] = useState(false);

  const renderModalView = () => (
    <ModalWrapper
      crossColor='#000000'
      onCloseModal={onCloseModal}
      maxWidth='100vw'
      maxHeight='100%'
      showModal={modalVisible}
      title='Select business registration type'
    >
      <ImagePreviewModal
        visible={modalVisibleBn}
        onClose={onCloseModalBT}
        style={{
          width: '50vw',
          height: '100vh',
        }}
        image='../../images/documents/business-name.png'
      />
      <ImagePreviewModal
        visible={modalVisibleIt}
        onClose={onCloseModalBT}
        style={{
          width: '50vw',
          height: '100vh',
        }}
        image='../../images/documents/incorporated-trustee.png'
      />
      <ImagePreviewModal
        visible={modalVisibleCo}
        onClose={onCloseModalBT}
        style={{
          width: '50vw',
          height: '100vh',
        }}
        image='../../images/documents/registered-corporation.png'
      />

      <S.ContainerB>
        <S.ItemRow>
          <S.InnerItem>
            <S.FileOuter
              style={
                isCheckeda || selected === 'bn'
                  ? {
                      border: '1px solid #B10180',
                    }
                  : {}
              }
            >
              <S.File
                style={{
                  backgroundImage: `url("../../images/documents/business-name.png")`,
                }}
              />

              <S.FileTexts>
                <Text bold size={7} color={colors.black._20}>
                  BN{' '}
                  <Text size={7} color={colors.black._20}>
                    (Business Name )
                  </Text>
                </Text>
              </S.FileTexts>
              <S.FullScreen>
                <Text
                  onClick={() => setBnModalVisible(true)}
                  size={7}
                  color='#B10180'
                >
                  View full image
                </Text>
                <S.Circle
                  onClick={() => {
                    setIsCheckeda(true);
                    setIsCheckedb(false);
                    setIsCheckedc(false);
                    setSelected('bn');
                  }}
                >
                  {(isCheckeda || selected === 'bn') && <S.InsideCircle />}
                </S.Circle>
              </S.FullScreen>
            </S.FileOuter>
            <S.FileOuter
              style={
                isCheckedb || selected === 'co'
                  ? {
                      border: '1px solid #B10180',
                    }
                  : {}
              }
            >
              <S.File
                style={{
                  backgroundImage: `url("../../images/documents/incorporated-trustee.png")`,
                }}
              />

              <S.FileTexts>
                <Text bold size={7} color={colors.black._20}>
                  RC{' '}
                  <Text size={7} color={colors.black._20}>
                    (Registered company)
                  </Text>
                </Text>
              </S.FileTexts>

              <S.FullScreen>
                <Text
                  onClick={() => setCoModalVisible(true)}
                  size={7}
                  color='#B10180'
                >
                  View full image
                </Text>
                <S.Circle
                  onClick={() => {
                    setIsCheckedb(true);
                    setIsCheckeda(false);
                    setIsCheckedc(false);
                    setSelected('co');
                  }}
                >
                  {(isCheckedb || selected === 'co') && <S.InsideCircle />}
                </S.Circle>
              </S.FullScreen>
            </S.FileOuter>
            <S.FileOuter
              style={
                isCheckedc || selected === 'it'
                  ? {
                      border: '1px solid #B10180',
                    }
                  : {}
              }
            >
              <S.File
                style={{
                  backgroundImage: `url("../../images/documents/registered-corporation.png")`,
                }}
              />
              <S.FileTexts>
                <Text bold size={7} color={colors.black._20}>
                  IT{' '}
                  <Text size={7} color={colors.black._20}>
                    (Incorporated Trustee)
                  </Text>
                </Text>
              </S.FileTexts>
              <S.FullScreen>
                <Text
                  onClick={() => setItModalVisible(true)}
                  size={7}
                  color='#B10180'
                >
                  View full image
                </Text>
                <S.Circle
                  onClick={() => {
                    setIsCheckedc(true);
                    setIsCheckedb(false);
                    setIsCheckeda(false);
                    setSelected('it');
                  }}
                >
                  {(isCheckedc || selected === 'it') && <S.InsideCircle />}
                </S.Circle>
              </S.FullScreen>
            </S.FileOuter>
          </S.InnerItem>
        </S.ItemRow>
        <S.ButtonGroup
          style={{
            paddingBottom: '20px',
          }}
        >
          <div>
            <Button
              label='Continue'
              style={{
                background: '#B10180',
                width: 304,
              }}
              onClick={continueHandleBT}
            />
          </div>
        </S.ButtonGroup>
      </S.ContainerB>
    </ModalWrapper>
  );

  const continueHandleBT = async () => {
    onCloseModal();
    setBusinessType(selected);
    setBusinessTypeLabel(
      selected === 'co'
        ? 'RC (Registered company)'
        : selected === 'bn'
        ? 'BN (Business Name)'
        : 'IT (Incorporated Trustee)'
    );
  };

  return (
    <>
      {renderModalView()}
      <S.Container>
        <Title
          color={colors.black._100}
          title='Company Information'
          subTitle='Tell us about your company by providing the following details.'
        />
        <S.Wrapper>
          <OnboardingCard>
            <S.ItemRow
              style={{
                display: 'flex',
                gap: 15,
                width: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  label='Company Name'
                  type='text'
                  value={name}
                  onChange={setName}
                  placeholder='Enter company name'
                  valid={validation?.data.name.isValid}
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  label='Company Address'
                  type='text'
                  value={address}
                  onChange={setAddress}
                  placeholder='Enter company address'
                  valid={validation?.data?.address?.isValid}
                />
              </div>
            </S.ItemRow>

            <S.ItemRow
              style={{
                display: 'flex',
                gap: 15,
                width: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  label='Email Address'
                  type='text'
                  value={email}
                  onChange={setEmail}
                  placeholder='Enter company email address'
                  valid={validation?.data.email.isValid}
                />
              </div>

              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  label='Phone Number'
                  type='text'
                  value={phone}
                  valid={!phoneValid && validation?.data.phone.isValid}
                  onChange={setPhone}
                  placeholder='Enter company phone number'
                />
              </div>
            </S.ItemRow>

            <S.ItemRow
              style={{
                display: 'flex',
                gap: 15,
                width: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <DropdownInputModal
                  label='Business Registration Type'
                  icon='dropdown'
                  onIconClick={() => seModalVisible(true)}
                  type='text'
                  value={businessTypeLabel}
                  name='cac'
                  placeholder='RC (Registered Corporation)'
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  // label={rcNumber}
                  label='CAC Number'
                  type='text'
                  value={cac}
                  name='cac'
                  placeholder='Enter CAC number'
                  onChange={(value) => setCac(value)}
                  valid={validation?.data.cac.isValid}
                />
              </div>
            </S.ItemRow>

            <S.ItemRow
              style={{
                display: 'flex',
                gap: 15,
                width: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  label='TIN '
                  type='text'
                  value={tin}
                  onChange={setTin}
                  placeholder='Enter TIN'
                  labelExtra='(Tax identification number)'
                  valid={validation?.data.tin.isValid}
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                {/* label: string;
  value: string;
  subOptions?: Array<{
    label: string;
    value: string;
  }>;
  showSubOption?: boolean; */}
                <MultiOptions
                  items={multiOptionData}
                  placeholder='Select business sector'
                  value={industryType}
                  selectItem={setIndustryType}
                />
                {/* <DropdownInput
                  label='Business Sector'
                  options={industryTypes.map((industry) => ({
                    label: industry,
                    value: industry,
                  }))}
                  onSelect={setIndustryType}
                  placeholder='Select business sector'
                /> */}
              </div>
            </S.ItemRow>

            <S.ItemRow
              style={{
                display: 'flex',
                gap: 15,
                width: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextInput
                  label='Website '
                  type='text'
                  placeholder='Enter Website'
                  labelExtra=' (Optional)'
                />
              </div>
            </S.ItemRow>

            <S.ButtonGroup>
              <div>
                <Button
                  label='Continue'
                  loading={loading}
                  style={{
                    width: 304,
                  }}
                  disabled={!validation?.isValid || loading}
                  onClick={continueHandle}
                />
              </div>
            </S.ButtonGroup>
          </OnboardingCard>
        </S.Wrapper>
      </S.Container>
    </>
  );
};
export default CommonCompanyEnterDetails;
