/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
import React, { } from 'react';
import { useLocation } from 'react-router';
import Jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { IGetStatement } from '../../services/internal/payments/IPayments';
import { colors } from '../../styles/variables';
import { getBeautyDateStringWithSeparator } from '../../utils/datetime';
import { formatCash } from '../../utils/money';
import { Text } from '../Text';
import './styels.css';

const AccountStatementPDF:React.FC = () => {
  const { data } = useLocation<{ data: IGetStatement }>().state;

  const downloadPDF = async (
    id: Array<string>,
    buttonId: string,
    section2Index: number,
    fileName?: string,
  ): Promise<void> => {
    const button = document.getElementById(buttonId);
    if (button) button.style.display = 'none';
    const html1 = document.getElementById(id[0]);
    const html2 = document.getElementById(id[1]);

    if (html1 && html2) {
      const canvas1 = await html2canvas(html1);
      canvas1.toDataURL('image/jpeg');
      const canvas2 = await html2canvas(html2);
      canvas2.toDataURL('image/jpeg');
      const doc = new Jspdf('p', 'mm');
      doc.addImage(canvas1, 'JPEG', 0, 0, 210, 0); // A4 sizes
      doc.addImage(canvas2, 'JPEG', 0, 90, 210, 0); // A4 sizes
      let ids = '';
      let currentPosition = 5;
      for (let i = 1; i <= section2Index; i += 1) {
        ids = !ids ? (i - 1).toString() : `${ids} ${i - 1}`;
        if (i % 9 === 0 && currentPosition === 5) {
          currentPosition = 0;
          const sectionHtml1 = document.getElementById(`table-${ids.split(' ')[0]}`);
          const sectionHtml2 = document.getElementById(`table-${ids.split(' ')[1]}`);
          const sectionHtml3 = document.getElementById(`table-${ids.split(' ')[2]}`);
          const sectionHtml4 = document.getElementById(`table-${ids.split(' ')[3]}`);
          const sectionHtml5 = document.getElementById(`table-${ids.split(' ')[4]}`);
          const sectionHtml6 = document.getElementById(`table-${ids.split(' ')[5]}`);
          const sectionHtml7 = document.getElementById(`table-${ids.split(' ')[6]}`);
          const sectionHtml8 = document.getElementById(`table-${ids.split(' ')[7]}`);
          const sectionHtml9 = document.getElementById(`table-${ids.split(' ')[8]}`);
          ids = '';

          if (sectionHtml1 && sectionHtml2 && sectionHtml3 && sectionHtml4
            && sectionHtml5 && sectionHtml6 && sectionHtml7 && sectionHtml8
            && sectionHtml9) {
            const canva1 = await html2canvas(sectionHtml1);
            const canva2 = await html2canvas(sectionHtml2);
            const canva3 = await html2canvas(sectionHtml3);
            const canva4 = await html2canvas(sectionHtml4);
            const canva5 = await html2canvas(sectionHtml5);
            const canva6 = await html2canvas(sectionHtml6);
            const canva7 = await html2canvas(sectionHtml7);
            const canva8 = await html2canvas(sectionHtml8);
            const canva9 = await html2canvas(sectionHtml9);
            canva1.toDataURL('image/jpeg');
            canva2.toDataURL('image/jpeg');
            canva3.toDataURL('image/jpeg');
            canva4.toDataURL('image/jpeg');
            canva5.toDataURL('image/jpeg');
            canva6.toDataURL('image/jpeg');
            canva7.toDataURL('image/jpeg');
            canva8.toDataURL('image/jpeg');
            canva9.toDataURL('image/jpeg');
            currentPosition = 0;
            doc.addImage(canva1, 'JPEG', 0, 110, 210, 0);
            doc.addImage(canva2, 'JPEG', 0, 130, 210, 0);
            doc.addImage(canva3, 'JPEG', 0, 150, 210, 0);
            doc.addImage(canva4, 'JPEG', 0, 170, 210, 0);
            doc.addImage(canva5, 'JPEG', 0, 190, 210, 0);
            doc.addImage(canva6, 'JPEG', 0, 210, 210, 0);
            doc.addImage(canva7, 'JPEG', 0, 230, 210, 0);
            doc.addImage(canva8, 'JPEG', 0, 250, 210, 0);
            doc.addImage(canva9, 'JPEG', 0, 270, 210, 0);
          }
        } else if (i % 11 === 0 && currentPosition === 0) {
          if (ids.split(' ').length === 13) {
            // the additional two items have been added here
            // this will add the remaining 2 ids because 11 - 9 = 2. without this,
            //  two items will be missing from the table

            const sectionHtml1 = document.getElementById(`table-${ids.split(' ')[0]}`);
            const sectionHtml2 = document.getElementById(`table-${ids.split(' ')[1]}`);
            const sectionHtml3 = document.getElementById(`table-${ids.split(' ')[2]}`);
            const sectionHtml4 = document.getElementById(`table-${ids.split(' ')[3]}`);
            const sectionHtml5 = document.getElementById(`table-${ids.split(' ')[4]}`);
            const sectionHtml6 = document.getElementById(`table-${ids.split(' ')[5]}`);
            const sectionHtml7 = document.getElementById(`table-${ids.split(' ')[6]}`);
            const sectionHtml8 = document.getElementById(`table-${ids.split(' ')[7]}`);
            const sectionHtml9 = document.getElementById(`table-${ids.split(' ')[8]}`);
            const sectionHtml10 = document.getElementById(`table-${ids.split(' ')[9]}`);
            const sectionHtml11 = document.getElementById(`table-${ids.split(' ')[10]}`);
            const sectionHtml12 = document.getElementById(`table-${ids.split(' ')[11]}`);
            const sectionHtml13 = document.getElementById(`table-${ids.split(' ')[12]}`);

            ids = '';
            if (sectionHtml1 && sectionHtml2 && sectionHtml3 && sectionHtml4
            && sectionHtml5 && sectionHtml6 && sectionHtml7 && sectionHtml8
            && sectionHtml9 && sectionHtml10 && sectionHtml11 && sectionHtml12 && sectionHtml13) {
              const canva1 = await html2canvas(sectionHtml1);
              const canva2 = await html2canvas(sectionHtml2);
              const canva3 = await html2canvas(sectionHtml3);
              const canva4 = await html2canvas(sectionHtml4);
              const canva5 = await html2canvas(sectionHtml5);
              const canva6 = await html2canvas(sectionHtml6);
              const canva7 = await html2canvas(sectionHtml7);
              const canva8 = await html2canvas(sectionHtml8);
              const canva9 = await html2canvas(sectionHtml9);
              const canva10 = await html2canvas(sectionHtml10);
              const canva11 = await html2canvas(sectionHtml11);
              const canva12 = await html2canvas(sectionHtml12);
              const canva13 = await html2canvas(sectionHtml13);
              canva1.toDataURL('image/jpeg');
              canva2.toDataURL('image/jpeg');
              canva3.toDataURL('image/jpeg');
              canva4.toDataURL('image/jpeg');
              canva5.toDataURL('image/jpeg');
              canva6.toDataURL('image/jpeg');
              canva7.toDataURL('image/jpeg');
              canva8.toDataURL('image/jpeg');
              canva9.toDataURL('image/jpeg');
              canva10.toDataURL('image/jpeg');
              canva11.toDataURL('image/jpeg');
              canva12.toDataURL('image/jpeg');
              canva13.toDataURL('image/jpeg');
              doc.addPage();
              doc.addImage(canvas2, 'JPEG', 0, 0, 210, 0); // A4 sizes
              doc.addImage(canva1, 'JPEG', 0, 20, 210, 0);
              doc.addImage(canva2, 'JPEG', 0, 40, 210, 0);
              doc.addImage(canva3, 'JPEG', 0, 60, 210, 0);
              doc.addImage(canva4, 'JPEG', 0, 80, 210, 0);
              doc.addImage(canva5, 'JPEG', 0, 100, 210, 0);
              doc.addImage(canva6, 'JPEG', 0, 120, 210, 0);
              doc.addImage(canva7, 'JPEG', 0, 140, 210, 0);
              doc.addImage(canva8, 'JPEG', 0, 160, 210, 0);
              doc.addImage(canva9, 'JPEG', 0, 180, 210, 0);
              doc.addImage(canva10, 'JPEG', 0, 200, 210, 0);
              doc.addImage(canva11, 'JPEG', 0, 220, 210, 0);
              doc.addImage(canva12, 'JPEG', 0, 240, 210, 0);
              doc.addImage(canva13, 'JPEG', 0, 260, 210, 0);
            }
          } else if (ids.split(' ').length === 11) {
            const sectionHtml1 = document.getElementById(`table-${ids.split(' ')[0]}`);
            const sectionHtml2 = document.getElementById(`table-${ids.split(' ')[1]}`);
            const sectionHtml3 = document.getElementById(`table-${ids.split(' ')[2]}`);
            const sectionHtml4 = document.getElementById(`table-${ids.split(' ')[3]}`);
            const sectionHtml5 = document.getElementById(`table-${ids.split(' ')[4]}`);
            const sectionHtml6 = document.getElementById(`table-${ids.split(' ')[5]}`);
            const sectionHtml7 = document.getElementById(`table-${ids.split(' ')[6]}`);
            const sectionHtml8 = document.getElementById(`table-${ids.split(' ')[7]}`);
            const sectionHtml9 = document.getElementById(`table-${ids.split(' ')[8]}`);
            const sectionHtml10 = document.getElementById(`table-${ids.split(' ')[9]}`);
            const sectionHtml11 = document.getElementById(`table-${ids.split(' ')[10]}`);

            ids = '';

            if (sectionHtml1 && sectionHtml2 && sectionHtml3 && sectionHtml4
            && sectionHtml5 && sectionHtml6 && sectionHtml7 && sectionHtml8
            && sectionHtml9 && sectionHtml10 && sectionHtml11) {
              const canva1 = await html2canvas(sectionHtml1);
              const canva2 = await html2canvas(sectionHtml2);
              const canva3 = await html2canvas(sectionHtml3);
              const canva4 = await html2canvas(sectionHtml4);
              const canva5 = await html2canvas(sectionHtml5);
              const canva6 = await html2canvas(sectionHtml6);
              const canva7 = await html2canvas(sectionHtml7);
              const canva8 = await html2canvas(sectionHtml8);
              const canva9 = await html2canvas(sectionHtml9);
              const canva10 = await html2canvas(sectionHtml10);
              const canva11 = await html2canvas(sectionHtml11);
              canva1.toDataURL('image/jpeg');
              canva2.toDataURL('image/jpeg');
              canva3.toDataURL('image/jpeg');
              canva4.toDataURL('image/jpeg');
              canva5.toDataURL('image/jpeg');
              canva6.toDataURL('image/jpeg');
              canva7.toDataURL('image/jpeg');
              canva8.toDataURL('image/jpeg');
              canva9.toDataURL('image/jpeg');
              canva10.toDataURL('image/jpeg');
              canva11.toDataURL('image/jpeg');
              doc.addPage();
              doc.addImage(canvas2, 'JPEG', 0, 0, 210, 0); // A4 sizes
              doc.addImage(canva1, 'JPEG', 0, 20, 210, 0);
              doc.addImage(canva2, 'JPEG', 0, 40, 210, 0);
              doc.addImage(canva3, 'JPEG', 0, 60, 210, 0);
              doc.addImage(canva4, 'JPEG', 0, 80, 210, 0);
              doc.addImage(canva5, 'JPEG', 0, 100, 210, 0);
              doc.addImage(canva6, 'JPEG', 0, 120, 210, 0);
              doc.addImage(canva7, 'JPEG', 0, 140, 210, 0);
              doc.addImage(canva8, 'JPEG', 0, 160, 210, 0);
              doc.addImage(canva9, 'JPEG', 0, 180, 210, 0);
              doc.addImage(canva10, 'JPEG', 0, 200, 210, 0);
              doc.addImage(canva11, 'JPEG', 0, 220, 210, 0);
            }
          }
        }
        if (i === section2Index) {
          if (ids) {
            doc.addPage();
            // eslint-disable-next-line no-restricted-syntax
            for (const index in ids.split(' ')) {
              const otherHtml1 = document.getElementById(
                `table-${ids.split(' ')[index]}`,
              );
              if (otherHtml1) {
                // eslint-disable-next-line no-await-in-loop
                const otherCanva = await html2canvas(otherHtml1);
                otherCanva.toDataURL('image/jpeg');
                doc.addImage(otherCanva, 'JPEG', 0, +index * 20, 210, 0);
              }
            }
          }
          doc.save(`${fileName}.pdf`);
        }
      }
    }
  };

  return (
    <div className="container">
      <div id="download">
        <div className="logo">
          <img src="/images/logo/logo.svg" alt="logo" />
        </div>
        <div>
          <button
            id="button"
            type="button"
            onClick={() => downloadPDF(
              ['download', 'table'],
              'button',
              data.transactions.length,
              `${data.company_name}-account-statement`,
            )}
          >
            Download

          </button>

        </div>
        <div>
          <div className="card">
            <div className="description">
              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Customer name:
                </Text>
                <div>
                  <Text size={5} color={colors.white._100}>{data?.company_name}</Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Account number:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {data?.nuban}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Total credit amount:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {`₦ ${data?.total_credit_amount}`}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Total debit amount:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {`₦ ${data?.total_debit_amount}`}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Total credit count:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {data?.total_credit_count}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Total debit count:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {data?.total_debit_count}
                  </Text>
                </div>

              </div>

            </div>

            <div className="description">
              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Total transaction count:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {data?.total_transaction_count}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Starting balance:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {`₦ ${data.starting_balance}`}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Ending balance:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {`₦ ${data.ending_balance}`}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  Start date:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {getBeautyDateStringWithSeparator(new Date(data.start_date))}
                  </Text>
                </div>

              </div>

              <div className="descriptionContainer">
                <Text size={4} color={colors.white._100}>
                  End date:
                </Text>
                <div style={{ marginTop: 3 }}>
                  <Text size={5} color={colors.white._100}>
                    {getBeautyDateStringWithSeparator(new Date(data.end_date))}
                  </Text>
                </div>

              </div>

            </div>

          </div>
        </div>

      </div>

      <div className="tabular">
        <table style={{ width: '100%' }}>
          <tr id="table">
            <th>AMOUNT</th>
            <th>DESCRIPTION</th>
            <th>CREDIT</th>
            <th>DEBIT</th>
            <th>BALANCE</th>
            <th>DATE</th>
            <th>TRANSACTION ID</th>
          </tr>
          {data && data.transactions && data.transactions?.map((el, index) => (
            <tr id={`table-${index}`} key={index.toString()}>
              <td>
                {formatCash(el.amount)}
              </td>
              <td style={{ maxWidth: 200, fontSize: 13 }}>
                {el.remarks}
              </td>
              <td>{`₦ ${formatCash(el.credit)}`}</td>
              <td>{`₦ ${formatCash(el.debit)}`}</td>
              <td>{`₦ ${formatCash(el.running_balance)}`}</td>
              <td>{getBeautyDateStringWithSeparator(new Date(el.created_at))}</td>
              <td>{el.trx_id}</td>
            </tr>
          ))}
        </table>

      </div>

    </div>

  );
};

export default AccountStatementPDF;
