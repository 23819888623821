import * as S from './styles';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Title } from '../../components/Title';
import { colors } from '../../../../styles/variables';
import { Text } from '../../../../components/Text';
import { Button } from '../../../../components/Button';
import { CheckBox } from '../../../../components/CheckBox';
import onboardingService from '../../../../services/internal/onboarding/onboarding.service';
import { OnboardingSummary } from '../../../../services/internal/onboarding/IOnboarding';
import { actions, otherActions } from '../../../Onboard/config';
import { OnboardingActions } from '../../../../redux/onboarding/actions';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingInterface } from '../../../../redux/onboarding/IOnboarding';
import { BusinessTypesEnum, SIGN_UP } from '../../../Onboard/constants';
import Terms from '../../../../assets/documents/VBiz Terms of Use & Privacy Policy.pdf';
import { OnboardingCard } from '../../../../components/OnboardingCard';
import CommonPersonalAccAccCompleted from '../../../Onboard/common.personalAcc.acc-completed';
import { TextInput } from '../../../../components/TextInput';
import { Icon } from '../../../../components/Icon';

export const CommonPreviewDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const onboardingData = useSelector(
    (state: { onboardingStack: any }) => state.onboardingStack.data
  );

  const businessInfo = useSelector(
    (state: { onboardingStack: any }) => state.onboardingStack.businessInfo
  );

  const details = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.serverSummaryResponse as OnboardingSummary
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.businessType as string
  );

  const onboardingSummary = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.serverSummaryResponse as OnboardingSummary
  );

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const continueHandle = async () => {
    setLoading(true);
    try {
      const response = await onboardingService.confirmOnboarding({
        reference,
      });
      if (response.actionRequired === null) {
        seModalVisible(true);
        return;
      }
      if (onboardingData.type === SIGN_UP) {
        route.push(otherActions[response.actionRequired]);
      } else {
        route.push(actions[response.actionRequired]);
      }
    } catch (err) {
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }

    setLoading(false);
  };

function handleEditPersonalInfo() {
  if (onboardingData.type === 'create profile') {
    route.push('/onboarding/credentials');
  } else {
    route.push('/onboarding/signup/personal-information');
  }
}

function handleEditReferenceInfo() {
  route.push('/onboarding/reference-information');
}

const [modalVisible, seModalVisible] = useState<boolean>(false);
const onCloseModal = () => {
  seModalVisible(false);
};

return (
  <S.Container>
    <CommonPersonalAccAccCompleted
      visible={modalVisible}
      onClose={onCloseModal}
    />
    <S.TopText>
      <Title
        color={colors.black._100}
        title='Summary and Preview Page.'
        subTitle='Review and confirm details of all the information you provided.'
      />
    </S.TopText>

    <OnboardingCard>
      <S.PersonalInfoWrapper>
        {onboardingData && onboardingData?.credentials?.email && (
          <>
            <Text bold color='#394455'>
              Personal Information
            </Text>

            <S.CustomTable>
              <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
                <div style={{ flex: 1 }}>
                  <TextInput
                    label='Email'
                    type='text'
                    disabled
                    value={onboardingData?.credentials?.email ?? ''}
                    placeholder='Enter referee name'
                    // onChange={(value) => onChange(index as number, 'firstName', value)}
                    // valid={firstNameIsValid}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TextInput
                    label='Phone number'
                    disabled
                    value={onboardingData?.credentials?.phone ?? ''}
                    placeholder='Enter referee email'
                    // onChange={(value) => onChange(index as number, 'email', value)}
                    // valid={emailIsValid}
                  />
                </div>
              </S.ItemRow>

              <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
                <div style={{ flex: 1 }}>
                  <TextInput
                    label='   ID Type'
                    type='text'
                    disabled
                    value={
                      onboardingData?.credentials?.idType?.label ??
                      onboardingData?.credentials?.idType ??
                      ''
                    }
                    placeholder='Enter referee name'
                    // onChange={(value) => onChange(index as number, 'firstName', value)}
                    // valid={firstNameIsValid}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TextInput
                    label='ID Number'
                    disabled
                    value={onboardingData?.credentials?.idNumber ?? ''}
                    placeholder='Enter referee email'
                    // onChange={(value) => onChange(index as number, 'email', value)}
                    // valid={emailIsValid}
                  />
                </div>
              </S.ItemRow>

              <S.EditSection onClick={handleEditPersonalInfo}>
                <Icon name='edit' color={colors.pink._100} />
                <Text size={5} color='#B10180'>
                  Edit
                </Text>
              </S.EditSection>
            </S.CustomTable>
          </>
        )}
      </S.PersonalInfoWrapper>

      <S.CompanyInfoWrapper>
        <Text bold color='#394455'>
          Business Information
        </Text>
        <S.CustomTable>
          {onboardingSummary?.businessInfo && (
            <>
              <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
                <div style={{ flex: 1 }}>
                  <TextInput
                    label=' Business Name'
                    type='text'
                    disabled
                    value={onboardingSummary?.businessInfo?.name ?? ''}
                    placeholder='Enter referee name'
                    // onChange={(value) => onChange(index as number, 'firstName', value)}
                    // valid={firstNameIsValid}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TextInput
                    label='   Business Type'
                    disabled
                    value={
                      onboardingSummary?.businessInfo?.type
                        ?.split('_')
                        ?.join(' ')
                        ?.toUpperCase() ?? ''
                    }
                    placeholder='Enter referee email'
                    // onChange={(value) => onChange(index as number, 'email', value)}
                    // valid={emailIsValid}
                  />
                </div>
              </S.ItemRow>

              {onboardingData?.type !== SIGN_UP && (
                <>
                  <S.ItemRow
                    style={{ display: 'flex', gap: 15, width: '100%' }}
                  >
                    <div style={{ flex: 1 }}>
                      <TextInput
                        label='  Business Email'
                        type='text'
                        disabled
                        value={details?.businessInfo?.email ?? ''}
                        placeholder='Enter referee name'
                        // onChange={(value) => onChange(index as number, 'firstName', value)}
                        // valid={firstNameIsValid}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <TextInput
                        label=' Phone number'
                        disabled
                        value={details?.businessInfo?.phone ?? ''}
                        placeholder='Enter referee email'
                        // onChange={(value) => onChange(index as number, 'email', value)}
                        // valid={emailIsValid}
                      />
                    </div>
                  </S.ItemRow>

                  <S.ItemRow
                    style={{ display: 'flex', gap: 15, width: '100%' }}
                  >
                    <div style={{ flex: 1 }}>
                      <TextInput
                        label='      Account Number'
                        type='text'
                        disabled
                        value={details?.businessInfo?.nuban ?? ''}
                        placeholder='Enter referee name'
                        // onChange={(value) => onChange(index as number, 'firstName', value)}
                        // valid={firstNameIsValid}
                      />
                    </div>
                  </S.ItemRow>
                </>
              )}
              {businessInfo && onboardingData?.type === SIGN_UP && (
                <>
                  <S.ItemRow
                    style={{ display: 'flex', gap: 15, width: '100%' }}
                  >
                    <div style={{ flex: 1 }}>
                      <TextInput
                        label=' Industry Type'
                        type='text'
                        disabled
                        value={
                          onboardingSummary?.businessInfo?.industryType ?? ''
                        }
                        placeholder='Enter referee name'
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <TextInput
                        label={
                          businessType === BusinessTypesEnum.trusteeNgo
                            ? 'IT Number'
                            : 'RC/BN Number'
                        }
                        disabled
                        value={onboardingSummary?.businessInfo?.cac ?? ''}
                        placeholder='Enter referee email'
                      />
                    </div>
                  </S.ItemRow>
                  <S.ItemRow
                    style={{ display: 'flex', gap: 15, width: '100%' }}
                  >
                    <div style={{ flex: 1 }}>
                      <TextInput
                        label='  TIN'
                        type='text'
                        disabled
                        value={onboardingSummary?.businessInfo?.tin ?? ''}
                        placeholder='Enter referee name'
                        // onChange={(value) => onChange(index as number, 'firstName', value)}
                        // valid={firstNameIsValid}
                      />
                    </div>
                  </S.ItemRow>
                </>
              )}
            </>
          )}
        </S.CustomTable>
      </S.CompanyInfoWrapper>

      {onboardingData?.referenceInfo.length > 0 && (
        <S.ReferenceInfoWrapper>
          <Text bold color={colors.pink._100}>
            Reference Information
          </Text>
          <S.CustomTable>
            {onboardingData?.referenceInfo.map((data: any, index: number) => (
              <div key={index}>
                <Text color={colors.black._100} size={5} bold>
                  Reference {index + 1}
                </Text>

                <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
                  <div style={{ flex: 1 }}>
                    <TextInput
                      label='Name'
                      type='text'
                      disabled
                      value={`${data?.firstName ?? ''} ${data?.lastName ?? ''}`}
                      placeholder='Enter referee name'
                      // onChange={(value) => onChange(index as number, 'firstName', value)}
                      // valid={firstNameIsValid}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextInput
                      label='      Email'
                      type='text'
                      disabled
                      value={data?.email ?? ''}
                      placeholder='Enter referee name'
                      // onChange={(value) => onChange(index as number, 'firstName', value)}
                      // valid={firstNameIsValid}
                    />
                  </div>
                </S.ItemRow>
              </div>
            ))}
            <S.EditSection onClick={handleEditReferenceInfo}>
              <Icon name='edit' color={colors.pink._100} />
              <Text size={5} color='#B10180'>
                Edit
              </Text>
            </S.EditSection>
          </S.CustomTable>
        </S.ReferenceInfoWrapper>
      )}

      <S.ButtonGroup>
        <S.TermsAndConditions>
          <Text color={colors.black._100} size={6}>
            By clicking Continue, you agree with{' '}
            <S.TermsLink href={Terms} target='_blank' rel='noopener noreferrer'>
              Terms & Conditions
            </S.TermsLink>
          </Text>
        </S.TermsAndConditions>
        <div>
          <Button
            loading={loading}
            disabled={loading}
            label='Continue'
            style={{ width: 304, background: '#B10180' }}
            // onClick={continueHandle}
            onClick={() => continueHandle()}
          />
        </div>
      </S.ButtonGroup>
    </OnboardingCard>
  </S.Container>
);
};
