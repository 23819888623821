import * as S from './styles';
import { Text } from '../../../../components/Text';
import { Icon, IconName, IconProps } from '../../../../components/Icon';
import { colors } from '../../../../styles/variables';
import { FC } from 'react';

export interface MenuItem {
  id: string;
  businessId: string;
  name: string;
  nuban: string;
  bankCode: string;
  defaultAmount: number;
  createdAt: string;
  updatedAt: string;
}

export const FrequentBeneficiaries: FC<{ details: MenuItem[] }> = ({
  details,
}) => {
  return (
    <S.Container>
      <Text color={colors.black._20} bold size={5}>
        Frequent Beneficiaries
      </Text>

      <S.Links>
        <S.LinksB>
          <div style={{ display: 'flex', overflow: 'auto' }}>
            {details.map((items, index) => {
              return (
                <S.LinksInner>
                  <Icon
                    width={36}
                    height={36}
                    name={'profile_bg' as IconName['name']}
                    color={colors.black._100}
                  />
                  <S.LinksInnerText>
                    <Text color={colors.black._20} bold size={6}>
                      {items.name}
                    </Text>
                    <Text color='#6E7191' size={6}>
                      {items.nuban}
                    </Text>
                  </S.LinksInnerText>
                </S.LinksInner>
              );
            })}
          </div>{' '}
        </S.LinksB>
      </S.Links>
    </S.Container>
  );
};
