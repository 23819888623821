export type Currency = string| undefined;

export const getCurrencySign = (currency: Currency): string => {
  switch (currency?.toLocaleLowerCase()) {
    case 'usd':
      return '$';
    case 'ngn':
      return '₦';
    default:
      return '';
  }
};
