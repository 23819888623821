import styled, { css } from 'styled-components';

import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';

const Wrapper = styled.div<{ additionalButton: boolean }>`
  background: ${colors.black._5};
  border: ${ptr(1)} solid ${colors.black._10};
  box-sizing: border-box;
  border-radius: ${ptr(6)};
  padding: ${ptr(32)};
  cursor: pointer;
  ${(props) =>
    props.additionalButton &&
    css`
      padding: ${ptr(32)} ${ptr(32)} ${ptr(12)};
    `}
`;

const Container = styled.aside`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RootContent = styled.article`
  margin-right: ${ptr(16)};

  > span {
    display: block;

    &:last-child {
      margin-top: ${ptr(4)};
    }
  }
`;

const AdditionalButton = styled.div`
  margin-top: 20px;
  align-items: center;
  width: 100%;
  button {
    padding: 0;
    margin: 0 auto;
  }
  button:focus {
    padding: 0;
    border: none;
  }
  button:hover {
    box-shadow: none;
  }
`;

const ArrowBlock = styled.div``;

export { Wrapper, Container, RootContent, ArrowBlock, AdditionalButton };

// import React, { CSSProperties } from 'react';
// import { Button } from '../../../../components/Button';
// import { Icon } from '../../../../components/Icon';
// import { Text } from '../../../../components/Text';
// import { colors } from '../../../../styles/variables';

// import * as S from './styles';

// export interface InfoSectionProps {
//   title: string;
//   wrapperStyle?: CSSProperties;
//   style?: CSSProperties;
//   subTitle?: string;
//   onClick?: () => void;
//   onAdditionalButtonClick?: () => void;
//   buttonLabel?: string;
//   showIcon?: boolean;
//   showRequirements?: boolean;
//   additionalButton?: boolean;
// }

// export const InfoSection: React.FC<InfoSectionProps> = ({
//   title,
//   style,
//   subTitle,
//   wrapperStyle,
//   onClick = () => {},
//   buttonLabel,
//   showIcon = false,
//   showRequirements = false,
//   additionalButton = false,
//   onAdditionalButtonClick = () => {},
// }) => (
//   <S.Wrapper
//     onClick={() => (additionalButton ? null : onClick())}
//     additionalButton={additionalButton}
//     style={wrapperStyle}
//   >
//     <S.Container onClick={onClick}>
//       <S.RootContent>
//         {showIcon && <Icon name='wallet' />}
//         <div style={style}>
//           <Text size={5} color={colors.black._100}>
//             {title}
//           </Text>
//           {subTitle && <Text color={colors.black._60}>{subTitle}</Text>}
//         </div>
//       </S.RootContent>
//       <S.ArrowBlock style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
//         {showRequirements && (
//           <Text size={5} color='#B10180'>
//             View requirements
//           </Text>
//         )}
//         <Icon name='vector' color='#B10180' />
//       </S.ArrowBlock>
//     </S.Container>
//     {additionalButton && (
//       <S.AdditionalButton>
//         <Button
//           onClick={onAdditionalButtonClick}
//           backgroundColor='transparent'
//           color={colors.pink._100}
//           label={buttonLabel}
//         />
//       </S.AdditionalButton>
//     )}
//   </S.Wrapper>
// );

// import React, { CSSProperties } from 'react';
// import { Button } from '../../../../components/Button';
// import { Icon } from '../../../../components/Icon';
// import { Text } from '../../../../components/Text';
// import { colors } from '../../../../styles/variables';

// import * as S from './styles';

// export interface InfoSectionProps {
//   title: string;
//   wrapperStyle?: CSSProperties;
//   style?: CSSProperties;
//   subTitle?: string;
//   onClick?: () => void;
//   onAdditionalButtonClick?: () => void;
//   buttonLabel?: string;
//   showIcon?: boolean;
//   showRequirements?: boolean;
//   additionalButton?: boolean;
// }

// export const InfoSection: React.FC<InfoSectionProps> = ({
//   title,
//   style,
//   subTitle,
//   wrapperStyle,
//   onClick = () => {},
//   buttonLabel,
//   showIcon = false,
//   showRequirements = false,
//   additionalButton = false,
//   onAdditionalButtonClick = () => {},
// }) => (
//   <S.Wrapper
//     onClick={() => (additionalButton ? null : onClick())}
//     additionalButton={additionalButton}
//     style={wrapperStyle}
//   >
//     <S.Container onClick={onClick}>
//       <S.RootContent>
//         {showIcon && <Icon name='wallet' />}
//         <div style={style}>
//           <Text size={5} color={colors.black._100}>
//             {title}
//           </Text>
//           {subTitle && <Text color={colors.black._60}>{subTitle}</Text>}
//         </div>
//       </S.RootContent>
//       <S.ArrowBlock style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
//         {showRequirements && (
//           <Text size={5} color='#B10180'>
//             View requirements
//           </Text>
//         )}
//         <Icon name='vector' color='#B10180' />
//       </S.ArrowBlock>
//     </S.Container>
//     {additionalButton && (
//       <S.AdditionalButton>
//         <Button
//           onClick={onAdditionalButtonClick}
//           backgroundColor='transparent'
//           color={colors.pink._100}
//           label={buttonLabel}
//         />
//       </S.AdditionalButton>
//     )}
//   </S.Wrapper>
// );

// import styled, { css } from 'styled-components';

// import { ptr } from '../../../../styles/utils';
// import { colors } from '../../../../styles/variables';

// const Wrapper = styled.div<{ additionalButton: boolean }>`
//   // background: ${colors.black._5};
//   border: ${ptr(1)} solid ${colors.black._10};
//   box-sizing: border-box;
//   border-radius: ${ptr(6)};
//   padding: ${ptr(2)} ${ptr(12)} ${ptr(12)} ${ptr(12)};
//   cursor: pointer;
//   width: 100%;

//   ${(props) =>
//     props.additionalButton &&
//     css`
//       padding: ${ptr(20)} ${ptr(12)} ${ptr(2)};
//     `}
// `;

// const Container = styled.aside`
//   height: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: space-between;
// `;

// const RootContent = styled.article`
//   margin-right: ${ptr(16)};
//   display: flex;
//   flex-wrap: wrap;
//   gap: 12px;
//   >:first-child {
//     margin-top: ${ptr(24)};
//   }
//   > div {
//     gap: 2px;
//     display: flex;
//     max-width: 374px;
//     flex-direction: column;

//   }
//   > span {
//     display: block;

// `;

// const AdditionalButton = styled.div`
//   margin-top: 20px;
//   align-items: center;
//   width: 100%;
//   button {
//     padding: 0;
//     margin: 0 auto;
//   }
//   button:focus {
//     padding: 0;
//     border: none;
//   }
//   button:hover {
//     box-shadow: none;
//   }
// `;

// const ArrowBlock = styled.div`
//   height: 100%;
//   display: flex;
//   gap: 8px;
//   margin-top: 20px;
//   alignitems: center;
// `;

// export { Wrapper, Container, RootContent, ArrowBlock, AdditionalButton };
