import { batch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import authService from '../../services/internal/auth/auth.service';
import debug from '../../services/internal/debbug.service';
import { companySetList } from '../company/actions';

import { ActionInterface } from '../IAction';
import { AppDispatch } from '../index';
import { OnboardingActions } from '../onboarding/actions';
import { PaymentActions } from '../payment/actions';

export const AUTHORIZATION_REQUEST = 'AUTHORIZATION/REQUEST';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION/SUCCESS';
export const AUTHORIZATION_FAILURE = 'AUTHORIZATION/FAILURE';
export const LOG_OUT = 'AUTHORIZATION/LOG_OUT';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_ROLES = 'SET_ROLES';
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';

const authorizationRequest = (): ActionInterface<unknown> => ({
  type: AUTHORIZATION_REQUEST,
});

const authorizationSuccess = (): ActionInterface<unknown> => ({
  type: AUTHORIZATION_SUCCESS,
});

const authorizationFailure = (): ActionInterface<unknown> => ({
  type: AUTHORIZATION_FAILURE,
});

const setPermissions = (
  permissions: Array<string>
): ActionInterface<Array<string>> => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

const setPublicEncKey = (publicKey: CryptoKey): ActionInterface<CryptoKey> => ({
  type: SET_PUBLIC_KEY,
  payload: publicKey,
});

const setRoles = (roles: Array<string>): ActionInterface<Array<string>> => ({
  type: SET_ROLES,
  payload: roles,
});

const logOut = (): ActionInterface<unknown> => ({
  type: LOG_OUT,
});

export class AuthorizationActions {
  public static authorization(): ThunkAction<
    Promise<void>,
    any,
    any,
    AnyAction
  > {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch(authorizationRequest());
        dispatch(authorizationSuccess());
        dispatch(PaymentActions.resetPaymentState());
      } catch (error) {
        dispatch(authorizationFailure());
      }
    };
  }

  public static setPermission(
    permissions: Array<string>
  ): ThunkAction<Promise<void>, any, any, AnyAction> {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch(setPermissions(permissions));
      } catch (err) {
        debug.error('Error', err);
      }
    };
  }

  public static setRole(
    roles: Array<string>
  ): ThunkAction<Promise<void>, any, any, AnyAction> {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch(setRoles(roles));
      } catch (err) {
        debug.error('Error', err);
      }
    };
  }

  public static logOut(): ThunkAction<Promise<void>, any, any, AnyAction> {
    return async (dispatch: AppDispatch) => {
      try {
        await authService.logOut();
        batch(() => {
          dispatch(companySetList([]));
          dispatch(logOut());
          dispatch(OnboardingActions.handleUnSetEnv());
        });
      } catch (err) {
        debug.error('Error', err);
      }
    };
  }

  public static getToken(): string | null {
    return localStorage.getItem('user/accessToken');
  }

  public static setPublicKey(
    publicKey: CryptoKey
  ): ThunkAction<Promise<void>, any, any, AnyAction> {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch(setPublicEncKey(publicKey));
      } catch (err) {
        debug.error('Error', err);
      }
    };
  }
}
