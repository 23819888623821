import { useHistory } from "react-router-dom";
import SearchInput from "../../../../components/SearchInput";
import { Text } from "../../../../components/Text";
import {
  batchDetailsRoute,
  transactionDetailsRoute,
} from "../../../../navigation";
import { colors } from "../../../../styles/variables";
import * as S from "./styles";
import { useState, useEffect, useRef } from "react";

const GlobalSearch = () => {
  const history = useHistory();
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", (event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpen(false);
        }
      });
    }
    return () => {
      document.removeEventListener("mousedown", () => {});
    };
  }, [open]);

  const searchableRoutes = [
    {
      path: "/transfers/other-bank",
      name: "Transfer to other accounts",
      tags: [
        "transfer",
        "payments",
        "transfer to other bank",
        "other bank",
        "bulk transfer",
        "bulk payment",
      ],
    },
    {
      path: "/transfers/own-accounts",
      name: "Transfer to own accounts",
      tags: ["transfer", "payments", "transfer to own bank"],
    },
    {
      path: "/transfers/beneficiaries",
      name: "Beneficiaries Management",
      tags: [
        "beneficiary",
        "send",
        "transfer",
        "payments",
        "bulk beneficiary",
        "receiver",
      ],
    },

    {
      path: "/transfers/beneficiaries",
      name: "Saved Beneficiaries",
      tags: [
        "beneficiary",
        "send",
        "transfer",
        "payments",
        "bulk beneficiary",
        "receiver",
      ],
    },

    {
      path: "/transfers",
      name: "Make a Payment",
      tags: [
        "transfer",
        "payments",
        "transfer to other bank",
        "other bank",
        "bulk transfer",
        "bulk payment",
        "transfer to own bank",
      ],
    },

    {
      path: "/payments",
      name: "Analytics",
      tags: [
        "transactions",
        "transaction report",
        "transaction history",
        "report",
        "trend",
        "details",
        "transaction details",
        "statement",
        "account statement",
        "credit",
        "debit",
        "turnover",
        "bulk",
        "individual",
        "single",
        "payments",
      ],
    },

    {
      path: "/users",
      name: "User management",
      tags: [
        "users",
        "operator",
        "create user",
        "create operator",
        "super admin",
        "authoriser",
        "verifier",
        "settings",
      ],
    },

    {
      path: "/settings",
      name: "Settings",
      tags: [
        "company",
        "companies",
        "settings",
        "support",
        "add company",
        "add business",
        "business",
        "security",
        "password",
        "change password",
        "update password",
        "pin",
        "reset pin",
        "set",
      ],
    },
  ];

  function searchByTags(searchTerm: string) {
    // Normalize the search term to make the search case insensitive.
    const normalizedSearchTerm = searchTerm.toLowerCase();

    return searchableRoutes
      .map((route) => {
        // If there are no tags, set matches to 0.
        if (!route.tags) {
          return { ...route, matches: 0 };
        }

        // Count the number of matching tags.
        const matches = route.tags.reduce((count, tag) => {
          return tag.toLowerCase().includes(normalizedSearchTerm)
            ? count + 1
            : count;
        }, 0);

        return { ...route, matches };
      })
      .filter((route) => route.matches > 0) // Only consider items with one or more matches.
      .sort((a, b) => b.matches - a.matches); // Sort by descending order of matches.
  }
  function handleFocus() {
    setOpen(true);
  }
  function handleSearch() {
    if (search) {
      const result = searchByTags(search);
      setSearchResult(result);
    } else {
      setSearchResult([]);
    }
  }
  useEffect(() => {
    const debounceFn = setTimeout(() => {
      handleSearch();
    }, 800);

    return () => clearTimeout(debounceFn);
  }, [search]);
  return (
    <S.Input ref={ref}>
      <SearchInput
        placeholder="What are you looking for?"
        onFocus={handleFocus}
        onChange={setSearch}
      />
      <S.DropdownContent open={open}>
        {searchResult?.length ? (
          searchResult.map((item, index) => (
            <div key={item?.name}>
              <S.SingleItemWrapper
                onClick={() => history.push(item.path)}
                first={index === 0}
              >
                <Text size={6} color={colors.black._100}>
                  {item?.name}{" "}
                </Text>
              </S.SingleItemWrapper>
            </div>
          ))
        ) : (
          <S.SingleItemWrapper>
            <Text size={6} color={colors.black._100}>
              No Option
            </Text>
          </S.SingleItemWrapper>
        )}
      </S.DropdownContent>
    </S.Input>
  );
};

export default GlobalSearch;
