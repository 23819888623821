import React, { CSSProperties, useEffect, useState } from 'react';

import * as S from './styles';
import { CheckArea, CheckAreaProps } from '../CheckArea';

export interface CheckAreaGroupProps {
  /**
   * CheckArea items
   */
  items: Array<CheckAreaProps>;
  textStyle?: CSSProperties;
  dimension?: string;
  /**
   * On change event
   */
  onChange?: (value: string) => void;
  checkAreaStyle?: CSSProperties;
  chosenIndex?: number;
}

export const CheckAreaGroup: React.FC<CheckAreaGroupProps> = ({
  items,

  ...props
}) => {
  const [chosen, setChosen] = useState<number | null>(() =>
    props.chosenIndex !== undefined && props.chosenIndex >= 0
      ? props.chosenIndex
      : null
  );

  return (
    <S.Wrapper>
      {items.map((checkAreaItem, index) => (
        <CheckArea
          style={props.checkAreaStyle ? { ...props.checkAreaStyle } : {}}
          dimension={props.dimension}
          textStyle={props.textStyle}
          {...checkAreaItem}
          checked={chosen === index}
          onClick={(isChecked) => {
            if (props.onChange) {
              props.onChange(isChecked ? checkAreaItem.value || '' : '');
            }
            setChosen(index);
          }}
        />
      ))}
    </S.Wrapper>
  );
};
