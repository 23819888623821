import styled, { css } from 'styled-components';
import { crossBrowserTransition, ptr } from '../../../../styles/utils';
import { breakpoints, colors, transition } from '../../../../styles/variables';
import { ItemProps } from '.';

const Container = styled.div`
  padding: ${ptr(32)};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  background: ${colors.black._2};
  max-width: 100%;
  /* Vbiz web cards */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${ptr(24)};
  flex-wrap: wrap;
  align-items: center;
`;

const Box = styled.div`
  min-width: ${ptr(40)};
  height: ${ptr(40)};
  background: ${colors.black._5};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${ptr(6)};
  margin-right: ${ptr(16)};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  & > a {
    border-top: ${ptr(1)} solid ${colors.black._10};
  }

  & > a:last-child > aside {
    padding-bottom: 0;
  }
`;

const Row = styled.aside`
  padding: ${ptr(24)} 0;
  display: flex;
  align-items: center;
`;

const Charts = styled.div``;

const ChartsInner = styled.div``;

const Transaction = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-top: 1px dotted rgba(110, 113, 145, 0.42);
  border-bottom: 1px dotted rgba(110, 113, 145, 0.42);
`;

const IconWrapper = styled.div<{ open: boolean }>`
  margin-left: ${ptr(60)};
  border-radius: 36px;
  background: var(--dark-white-pry-text, #fff);
  position: relative;
  cursor: pointer;
  /* Cards Shadow */
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 8px 24px -6px rgba(0, 0, 0, 0.16);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;

  ${crossBrowserTransition(transition.slow)}

  height: 36px;
  width: 36px;
`;

const Account = styled.div`
  padding-bottom: ${ptr(16)};
  display: flex;
  width: fit-content;
  width: 95%;

  padding: 12px;
  //   border-bottom: 1px dotted rgba(110, 113, 145, 0.42);
  justify-content: space-between;
  border-radius: 5px;
  background: rgba(219, 223, 228, 0.39);

  padding-bottom: 18px;
  margin-bottom: 18px;

  > svg {
    margin-left: ${ptr(60)};
    border-radius: 36px;
    background: var(--dark-white-pry-text, #fff);
    position: relative;
    cursor: pointer;
    /* Cards Shadow */
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
      0px 8px 24px -6px rgba(0, 0, 0, 0.16);
    display: inline-flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    gap: 4px;
    ${crossBrowserTransition(transition.slow)}
  }

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const Calendar = styled.div<{ open: boolean }>`
  color: var(--light-gohan, #fff);
  background: #2c323a;
  width: fit-content;
  border-radius: 36px;
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
  display: flex;

  align-items: center;
  gap: 4px;

  ${(props) =>
    props.open &&
    css`
      svg {
        color: #fffff;
      }
    `}

  > * {
    margin-right: ${ptr(12)};
  }

  @supports (gap: ${ptr(12)}) {
    gap: ${ptr(12)};

    > * {
      margin-right: 0;
    }
  }
`;

const Block = styled.div`
  margin-right: auto;
  span {
    display: block;

    &:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: ${ptr(200)};
      margin-bottom: ${ptr(4)};

      @media (max-width: ${ptr(1380)}) {
        max-width: ${ptr(600)};
      }

      @media (max-width: ${ptr(breakpoints.sm)}) {
        max-width: ${ptr(200)};
      }
    }
  }

  ~ svg {
    transform: rotate(180deg);
  }
`;

const BottomContainer = styled.div`
  border-radius: ${ptr(8)};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${ptr(32)};

  > div {
    flex: 1;
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Article = styled.article`
  display: flex;

  justify-content: space-between;
`;

const Article1 = styled.article`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.div<Pick<ItemProps, 'income'>>`
  border-radius: ${ptr(6)};

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${ptr(16)};

  ${(props) =>
    props.income &&
    css`
      & > svg {
        transform: rotate(180deg);
      }
    `}
`;

const Data = styled.aside`
  display: flex;
  flex-direction: column;
  //   align-items: baseline;

  > :first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    max-width: ${ptr(176)};
  }

  > :last-child {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 20px;
    flex-shrink: 0;
    border-radius: 80px;
    text-align: center;
    // background: #fc8484;
    color: ${colors.white._100};
    gap: 2px;
    font-size: 12px;
    padding: 2px 0px 2px 12px;
    font-family: Inter;
    font-weight: 500;
    line-height: 16px;
    margin-top: 8px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: ${ptr(breakpoints.xxl)}) {
    position: relative;
    flex-direction: column;
  }
`;

const AccountItemInfoBlock = styled.div`
  flex: 2;
  padding: 0 ${ptr(8)};
`;

const AccountItemBox = styled.div`
  display: flex;
  flexdirection: 'row';
  flex: 1;
  margin-top: 15px;
  align-items: center;
  margin-bottom: ${ptr(8)};
  border: '1px solid #000';
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
  background: rgba(219, 223, 228, 0.39);
`;
const Percent = styled.div``;

const Company = styled.div`
  position: relative;
`;

const DropownContent = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? css`none` : css`block`)};
  position: absolute;
  top: 55px;
  right: 0px;
  width: calc(90% - ${ptr(64)});
  height: fit-content;
  overflow: auto;
  background: ${colors.white._100};
  border: ${ptr(1)} solid ${colors.black._10};
  border-radius: ${ptr(8)};
  z-index: 9;
  padding: 31px 25px;

  ${(props) =>
    props.open
      ? css`
          border-top: ${ptr(1)} solid ${colors.black._10};
        `
      : css`
          border-top: ${ptr(0)} solid ${colors.black._10};
        `}

  ${crossBrowserTransition(transition.slow)}
`;

const AccountItemBalanceBlock = styled.div`
  flex: 1;
  text-align: right;
`;

export {
  Container,
  Head,
  Body,
  Row,
  Block,
  Box,
  Charts,
  Calendar,
  Account,
  ItemBox,
  Icon,
  Article,
  Article1,
  Data,
  Company,
  Percent,
  Transaction,
  ChartsInner,
  DropownContent,
  BottomContainer,
  AccountItemInfoBlock,
  AccountItemBox,
  IconWrapper,
  AccountItemBalanceBlock,
};
