import styled from 'styled-components';

import { ptr } from '../../../styles/utils';

const Container = styled.div`
  > button {
    width: 100%
  }
`;

const Wrapper = styled.div`
  margin-top: ${ptr(32)};
  margin-bottom: ${ptr(40)};

  & > :not(:last-child) {
    margin-bottom: ${ptr(48)};
  }
`;

export { Container, Wrapper };
