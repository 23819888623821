import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { CheckBox } from '../../components/CheckBox';

import * as S from './styles';
import { failed, success } from '../../components/Toasts';
import { colors } from '../../styles/variables';
import { Table } from '../../components/Table';
import { Icon } from '../../components/Icon';
import { Signature } from './Signature';
import Upload from './Upload';
import { SuccessSection } from '../Onboarding/components/SuccessSection';
import { Logo } from '../../components/Logo';
import subaccountService from '../../services/internal/subaccount/subaccount.service';
import { IVerifyConsentResponse } from '../../services/internal/subaccount/ISubaccount';
import { dateTimeMonthString } from '../../utils/datetime';
import { DataURIToBlob } from '../../utils/collections';
import Terms from '../../assets/documents/VBiz Terms of Use & Privacy Policy.pdf';
import Indemnity from '../../assets/documents/Indemnity.pdf';

const TEST_DATA = [
  {
    name: 'Operator 1',
    role: 'Authorizer',
    limit: 'N1,000,000',
  },
  {
    name: 'Operator 2',
    role: 'Initaitor',
    limit: '-',
  },
  {
    name: 'Operator 3',
    role: 'Initaitor',
    limit: '-',
  },
];

export interface ViewComponentProps {
  Drag: any;
  Screen: any;
}

export const SubaccountConsentForm: React.FC = () => {
  const { search } = useLocation();
  const route = useHistory();
  const signatureRef = useRef<any>(null);

  const token = new URLSearchParams(search)?.get('token') ?? '';

  const [verifying, setVerifying] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [data, setData] = useState<IVerifyConsentResponse | null>(null);

  const { state } = useLocation<{ tab: string }>();
  const [CurrentUserView, ChangeuserView] = useState<string>('Drag');
  const ChangeUserDisplay = (userView: string) => {
    ChangeuserView(userView);
  };
  const UserViews: ViewComponentProps = {
    Drag: <Signature onChangeFile={handleFileChange} />,
    Screen: (
      <Upload clear={clearSignature} save={saveSignature} ref={signatureRef} />
    ),
  };
  const CurrentRenderView = CurrentUserView as keyof typeof UserViews;
  useEffect(() => {
    const view = state?.tab && state?.tab === 'UserReport' ? 'Drag' : 'Drag';
    ChangeuserView(view);
  }, []);
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedb, setIsCheckedb] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccept] = useState(false);
  const [file, setFile] = useState<File | Blob | null>(null);

  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  function clearSignature() {
    signatureRef.current.clear();
    setFile(null);
  }

  function saveSignature() {
    if (signatureRef.current) {
      if (signatureRef.current.isEmpty()) {
        failed('Failed', 'Sign your signature');

        return;
      }
      const signatureValue = signatureRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      setFile(DataURIToBlob(signatureValue));

      success('Success', 'Signature saved');
    }
  }

  function handleFileChange(value: any) {
    setFile(value);
  }

  const verifyHandle = async () => {
    try {
      const response = await subaccountService.verifyConsent({
        token,
      });
      setData(response);
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'No record found');
      route.push('/');
    }
    setVerifying(false);
  };

  useEffect(() => {
    verifyHandle();

    return () => setData(null);
  }, []);

  const approveConsent = async (): Promise<void> => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append('file', file as File);
      formData.append('approve', 'true');
      formData.append('token', token);

      await subaccountService.approveConsent(formData);
      success('Success', 'Your response has been recorded');
      timer.current = setTimeout(() => {
        window.close();
      }, 2000);
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'failed');
    }
    setLoading(false);
  };

  const declineConsent = async (): Promise<void> => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('approve', 'false');
      formData.append('token', token);

      await subaccountService.approveConsent(formData);
      success('Success', 'Your response has been recorded');
      timer.current = setTimeout(() => {
        window.close();
      }, 2000);
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'failed');
    }
    setLoading(false);
  };

  return (
    <S.MainWrapper>
      <S.LogoWrapper>
        <Logo />
      </S.LogoWrapper>

      <S.MainWrapperInner>
        <S.Image />
        <S.Container>
          <S.TopText>
            <S.TopTextLhs>
              <S.Img alt='logo' src='/images/logo/logo.svg' />
            </S.TopTextLhs>

            <S.TopTextRhs>
              <Text bold size={7} color='#000000'>
                Date :
              </Text>

              <Text size={7} color='#000000'>
                {dateTimeMonthString()}
              </Text>
              <div />
            </S.TopTextRhs>
          </S.TopText>

          <S.ConsentTexts>
            <Text bold size={8} color='#000000'>
              Hello {data?.leaderName ?? 'Board member'},
            </Text>

            <Text bold size={6} color='#000000'>
              Consent to onboard account on VBiz.
            </Text>

            <Text size={7} color='#000000'>
              This is to formally request for your consent to proceed with
              onboarding your company on VBiz
            </Text>

            <Text
              style={{ marginTop: '10px', lineHeight: '20px' }}
              size={7}
              color='#000000'
            >
              The purpose of onboarding {data?.business?.name || ''}{' '}
              {data?.business?.accounts[0]?.nuban} on VBiz is to provide you
              with convenient and secure access to a wide range of banking
              services and transactions. VBiz offers smart features and
              functionalities that will streamline your financial management
              processes and enhance the overall efficiency of your company's
              banking operations.
            </Text>

            <Text style={{ marginTop: '10px' }} size={7} color='#000000'>
              By providing your consent, you agree to the following:
            </Text>
            <Text style={{ marginTop: '-4px' }} bold size={7} color='#000000'>
              Workflow on account:{' '}
              {data && (
                <>
                  {' '}
                  {data?.business?.workflow
                    ? 'Multiple Operators'
                    : 'Single Operator'}
                  {data?.business?.workflow
                    ? `(${data?.business?.workflow || ''})`
                    : ''}
                </>
              )}{' '}
            </Text>
          </S.ConsentTexts>

          <S.OperatorDetails>
            <Text bold size={7} color='#000000'>
              Operator details
            </Text>

            {data && data.operators?.data?.length > 0 && (
              <Table
                headerStyle={{
                  paddingTop: '0px',
                  padding: '0px',
                  color: '#667085',
                  fontWeight: 500,
                  fontSize: 12,
                }}
                header={{
                  cells: {
                    0: {
                      align: 'left',
                      children: <Text size={7}>Operator name</Text>,
                      padding: '0rem 4rem 0rem 1.5rem',
                    },
                    1: {
                      align: 'left',
                      children: <Text size={7}>Role</Text>,
                      padding: '0rem 4rem 0rem 0rem',
                    },
                    2: {
                      align: 'left',
                      children: <Text size={7}>Approval limit</Text>,
                      padding: '0.3rem 1rem 0.3rem 0rem',
                    },
                  },
                }}
                rows={data.operators.data.map(
                  (operator: any, index: number) => ({
                    padding: '1.25rem 0',
                    border: {
                      color: colors.black._10,
                      width: 1,
                      style: 'solid',
                      sides: ['top'],
                    },
                    cells: {
                      0: {
                        align: 'left',

                        children: (
                          <Text
                            key={index}
                            bold
                            style={{ width: '100%' }}
                            size={7}
                          >
                            {`${operator.firstName} ${operator.lastName}`}
                          </Text>
                        ),
                        padding: '0.3rem 2rem 0.3rem 1.5rem',
                      },
                      1: {
                        align: 'left',

                        children: (
                          <Text key={index} bold size={7}>
                            {operator.role}
                          </Text>
                        ),
                        padding: '0rem 4rem 0rem 0rem',
                      },
                      2: {
                        align: 'left',
                        children: (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              gap: '8px',
                              cursor: 'pointer',
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Text bold size={7}>
                              {operator.limit}
                            </Text>
                          </div>
                        ),
                        padding: '0.3rem 1rem 0.3rem 0rem',
                      },
                    },
                  })
                )}
              />
            )}

            <S.Info>
              <Icon color='#ED405C' name='info' />
              <Text size={7} color='#ED405C'>
                Please review this consent letter carefully and indicate your
                agreement by signing below.  
              </Text>
            </S.Info>
          </S.OperatorDetails>

          <S.Upload>
            <S.UploadText>
              <Text
                style={{ marginBottom: '14px' }}
                bold
                size={7}
                color='#000000'
              >
                Choose your preferred signing options
              </Text>
            </S.UploadText>

            <S.TypeTabs>
              <S.TableAlign2>
                <div>
                  <S.Tab1
                    style={{
                      border:
                        CurrentUserView === 'Drag'
                          ? '1px solid #B10180'
                          : '1px solid #EAECF0',
                    }}
                    onClick={() => {
                      ChangeUserDisplay('Drag');
                      setIsChecked(true);
                      setIsCheckedb(false);
                    }}
                  >
                    <S.Circle>{isChecked && <S.InsideCircle />}</S.Circle>
                    <Text
                      tag='span'
                      size={7}
                      background={
                        CurrentUserView === 'Drag' ? 'unset' : 'unset'
                      }
                      color={CurrentUserView === 'Drag' ? '#394455' : '#394455'}
                    >
                      Upload signature or drag & drop
                    </Text>
                  </S.Tab1>
                </div>
                <div>
                  <S.Tab1
                    style={{
                      border:
                        CurrentUserView === 'Drag'
                          ? '1px solid #EAECF0'
                          : '1px solid #B10180',
                    }}
                    onClick={() => {
                      ChangeUserDisplay('Screen');
                      setIsCheckedb(true);
                      setIsChecked(false);
                    }}
                  >
                    <S.Circle>{isCheckedb && <S.InsideCircle />}</S.Circle>
                    <Text
                      tag='span'
                      size={7}
                      background={
                        CurrentUserView === 'Screen' ? 'unset' : 'unset'
                      }
                      color={
                        CurrentUserView === 'Screen' ? '#394455' : '#394455'
                      }
                    >
                      Sign on the screen
                    </Text>
                  </S.Tab1>
                </div>
              </S.TableAlign2>
            </S.TypeTabs>
            <S.SigningOptionsDiv>
              {UserViews[CurrentRenderView]}
            </S.SigningOptionsDiv>

            {/* <CheckAreaGroup
            //   onChange={setRole}
            dimension='12px'
            textStyle={{ fontSize: 14 }}
            items={[
              { value: 'Upload signature or drag & drop' },
              { value: 'Sign on the screen' },
            ]}
          /> */}

            <S.TermsText>
              <CheckBox
                //   height={1}

                onChange={(isCheckedd) => {
                  setChecked(isCheckedd);
                  if (!isCheckedd) {
                    setChecked(false);
                  }
                }}
              >
                aa
              </CheckBox>

              <Text size={7} color='#394455'>
                By checking this box, you attest to have read our{' '}
                <S.TermsLink
                  href={Indemnity}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  indemnity,
                </S.TermsLink>{' '}
                <S.TermsLink
                  href={Terms}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy and Terms of Use,
                </S.TermsLink>{' '}
                understood its contents, and consent to the information
                provided.
              </Text>
            </S.TermsText>
          </S.Upload>

          {/* <ModalWrapper
            showModal={declined}
            maxWidth='100%'
            // onCloseModal={!creationLoading ? onCloseModal : undefined}
            crossColor={colors.black._100}
            overflowY='auto'
            overflowX='hidden'
          >
            <S.ModalInner
              style={{
                width: '681px',
                height: '390px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'space-around',
              }}
            >
              <Text
                style={{ marginBottom: '-26px' }}
                size={6}
                bold
                color='#394455'
              >
                We’ll like to know why you declined
              </Text>
              <div style={{ marginTop: '16px' }}>
                <textarea
                  style={{ padding: '8px' }}
                  placeholder='Write your reason here'
                  rows={9.5}
                  cols={92}
                />
              </div>

              <S.ButtonGroupA>
                <div>
                  <Button
                    label='Submit'
                    style={{ width: 277, height: 42 }}
                    //   onClick={continueHandle}
                    onClick={declineConsent}
                    loading={loading}
                    // disabled={!checked || loading}
                  />
                </div>
              </S.ButtonGroupA>
            </S.ModalInner>
          </ModalWrapper>

          <ModalWrapper
            showModal={accepted}
            maxWidth='100%'
            // onCloseModal={!creationLoading ? onCloseModal : undefined}
            crossColor={colors.black._100}
            overflowY='auto'
            overflowX='hidden'
          >
            <S.ModalInner
              style={{
                width: '681px',
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  style={{
                    marginBottom: '30px',
                    paddingBottom: '30px',
                    textAlign: 'center',
                    borderBottom: '1px dotted rgba(110, 113, 145, 0.42)',
                  }}
                >
                  <S.CheckCircle>
                    <S.Img1 src='/images/onboarding/success.png' alt='..' />
                  </S.CheckCircle>
                  <Text size={4} bold color={'#394455'}>
                    We have received your response.
                  </Text>
                </div>
              </div>
            </S.ModalInner>
          </ModalWrapper> */}
          {/* <SuccessSection
          title='We have received your response.'
          // onClick={gotItHandler}
        /> */}

          <S.ButtonGroup>
            <div>
              <Button
                label='Decline'
                size='tiny'
                theme='light'
                style={{ width: 277, color: '#394455' }}
                // onClick={onClose}
                onClick={() => {
                  declineConsent();
                }}
                loading={loading}
              />
            </div>
            <div>
              <Button
                label='Accept'
                style={{ width: 277 }}
                onClick={approveConsent}
                loading={loading}
                disabled={!checked || loading || !file}
              />
            </div>
          </S.ButtonGroup>
        </S.Container>
      </S.MainWrapperInner>
    </S.MainWrapper>
  );
};
