/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OnboardingActions } from '../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { InfoSection } from '../../Onboarding/components/InfoSection';
import { Title } from '../../Onboarding/components/Title';
import { businessInfoActions } from '../config';

import * as S from './styles';

// const SUBMIT_BUSINESS_INFO = 'SUBMIT_BUSINESS_INFO';

const CommonSwitcher: React.FC = () => {
  /**
   * Hooks initiation region
   */
  const dispatch = useDispatch();

  const route = useHistory();

  /**
   * Redux store initiation region
   */
  const hasVBankAccount = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack.hasVBankAccount,
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) => state.onboardingStack.businessType,
  );

  React.useEffect(() => {
    // check if the user already selected businessType
    if (route.action !== 'POP') {
      if (businessType !== undefined) {
        if (businessType === 'hasCompany') {
          route.push(businessInfoActions.COMPANY_DETAILS);
        } else if (businessType === 'hasNotCompany') {
          route.push(businessInfoActions.PERSONAL_COMPANY_DETAILS);
        } else if (businessType === 'personalAccount') {
          if (hasVBankAccount) {
            route.push('/onboarding/personal/business-details');
            return;
          }
          route.push('/onboarding/v-bank/create');
        }
      }
    }
  }, []);

  /**
   * Custom handlers initiation region
   */
  const hasCompany = () => {
    dispatch(OnboardingActions.handleSetBusinessType('hasCompany'));
    if (!hasVBankAccount) {
      route.push('/onboarding/register-details');
      return;
    }
    route.push(businessInfoActions.COMPANY_DETAILS);
  };

  const hasNotCompany = () => {
    dispatch(OnboardingActions.handleSetBusinessType('hasNotCompany'));
    if (!hasVBankAccount) {
      route.push('/onboarding/register-details');
      return;
    }
    route.push(businessInfoActions.PERSONAL_COMPANY_DETAILS);
  };

  // const personalAcc = () => {
  //   if (hasVBankAccount) {
  //     dispatch(OnboardingActions.handleSetBusinessType('personalAccount'));
  //     route.push('/onboarding/personal/business-details');
  //     return;
  //   }

  //   dispatch(OnboardingActions.handleSetBusinessType('personalAccount'));
  //   route.push('/onboarding/v-bank/create');
  // };
  const infoOne = 'My business is already registered with Corporate Affairs Commission (CAC) and I have the RC Number and other registration details. With this option, you will get access to V Biz, set up Operators and perform transactions.';

  const infoTwo = 'My business is not yet registered with the Corporate Affairs Commission and I would like VFB MFB to assist with this registration. With this option, VFD MFB will assist you with the process of registering your business and your V Biz profile will be created once your business registration is complete.';

  const [registeredCompanyInfo, setRegisteredCompanyInfo] = useState({ info: `${infoOne.substring(0, 90)}.....`, showMore: false });
  const [registerACompanyInfo, setRegisterACompanyInfo] = useState({ info: `${infoTwo.substring(0, 90)}.....`, showMore: false });

  const showMoreOrLess = (label: string) => {
    if (label === 'registeredCompanyInfo') {
      const showMore = !registeredCompanyInfo.showMore;
      if (showMore) {
        setRegisteredCompanyInfo({ info: infoOne, showMore });
      } else {
        setRegisteredCompanyInfo({ info: `${infoOne.substring(0, 90)}.....`, showMore });
      }
    } else {
      const showMore = !registerACompanyInfo.showMore;
      if (showMore) {
        setRegisterACompanyInfo({ info: infoTwo, showMore });
      } else {
        setRegisterACompanyInfo({ info: `${infoTwo.substring(0, 90)}.....`, showMore });
      }
    }
  };

  return (
    <S.Container>
      <Title title="What type of business do you want to setup?" />
      <S.Wrapper>
        <InfoSection
          title="I have a registered company"
          subTitle={registeredCompanyInfo.info}
          onClick={hasCompany}
          additionalButton
          buttonLabel={registeredCompanyInfo.showMore ? 'Read Less' : 'Read More'}
          onAdditionalButtonClick={() => showMoreOrLess('registeredCompanyInfo')}
        />
        <InfoSection
          title="I would like to register a company"
          subTitle={registerACompanyInfo.info}
          onClick={hasNotCompany}
          additionalButton
          buttonLabel={registerACompanyInfo.showMore ? 'Read Less' : 'Read More'}
          onAdditionalButtonClick={() => showMoreOrLess('registerACompanyInfo')}
        />
        {/* <InfoSection
          title="I'll be working with my personal account"
          subTitle="I will like to experience this with my personal account first while
          I work on registering my business.With this option, you will get a profile on V Biz
          with your personal V Bank account, however you will be restricted
          from performing some activities."
          onClick={personalAcc}
        /> */}
      </S.Wrapper>
    </S.Container>
  );
};
export default CommonSwitcher;
