import styled from 'styled-components';
import { breakpoints } from '../../styles/variables';
import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  padding: ${ptr(64)};
  box-sizing: border-box;
  width: 44.445vw; 

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    min-width:fit-content;
    padding: ${ptr(21)};
  }

  > span {
    display: block;
    margin-bottom: ${ptr(24)}; 
  }

  > button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: ${ptr(40)};
      white-space:nowrap;

      @media (max-width: ${breakpoints.sm}px) {
        width: 50%; 
      }
  }
`;

export { Wrapper };
