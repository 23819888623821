import React, { useState } from 'react';

import * as S from './styles';
import { TextInput, TextInputProps } from '../TextInput';
import { Link } from '../Link';

export interface TextInputGroupProps {
  /**
   * TextInput items
   */
  item: TextInputProps;
  /**
   * On change event
   */
  onChange?: (value: Array<string | null>) => void;
}

type TextInputItem = Array<string | null>;

const config = {
  maxElements: 9,
};

export const TextInputGroup: React.FC<TextInputGroupProps> = ({ ...props }) => {
  const [values, setValues] = useState<TextInputItem>(['']);

  const onChangeExternal = (newValues: TextInputItem) => {
    if (props.onChange) {
      props.onChange(newValues.filter((item) => item !== null));
    }
  };

  const onAdd = () => {
    if (values.filter((item) => item !== null).length < config.maxElements) {
      const newValues = values.slice(0);
      newValues.push('');
      setValues(newValues);
      onChangeExternal(newValues);
    }
  };

  const onChange = (value: string, index: number) => {
    const newValues = values.slice(0);
    newValues[index] = value;
    setValues(newValues);
    onChangeExternal(newValues);
  };

  const onRemove = (index: number) => {
    const newValues = values.slice(0);
    newValues[index] = null;
    setValues(newValues);
    onChangeExternal(newValues);
  };

  return (
    <S.Wrapper>
      {values.map((valueItem, index) =>
        valueItem !== null ? (
          <TextInput
            {...props.item}
            icon={index > 0 ? 'trash-basket' : undefined}
            onChange={(value) => {
              onChange(value, index);
            }}
            onIconClick={() => {
              onRemove(index);
            }}
          />
        ) : null,
      )}
      <S.AddOneMoreWrapper>
        <Link
          text="Add One More BVN"
          icon="plus-in-circle"
          theme="dark"
          onClick={onAdd}
        />
      </S.AddOneMoreWrapper>
    </S.Wrapper>
  );
};
