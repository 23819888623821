import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, transition } from '../../styles/variables';
import { ModalWrapperProps } from './index';
import { crossBrowserTransition, ptr } from '../../styles/utils';

const Container = styled.div<ModalWrapperProps>`
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  align-items: center;
  justify-content: center;
  ${crossBrowserTransition(transition.slow)}
  ${(props) => css`
      display: ${props.showModal ? 'flex' : 'none'};
    `
  }
`;


const Title = styled.div`
  font-family:${fonts.inter.regular}
`;


const Wrapper = styled.div<ModalWrapperProps>`
  background-color: ${colors.white._100};
  border-radius: ${ptr(12)};
  position: relative;
  max-height: 95%;
  
  ${(props) => props.maxWidth && css`
    max-width: ${props.maxWidth ? props.maxWidth : '90%'};
  `} 

  /* overflow: hidden; */

  ${(props) => props.maxHeight && css`
    max-height: ${props.maxHeight};
  `}

  ${(props) => props.overflowY && css`
    overflow-y: ${props.overflowY || 'visible'};
  `}

  ${(props) => props.overflowX && css`
    overflow-x: ${props.overflowX || 'hidden'};
  `}
  /* ::-webkit-scrollbar {
  display: none;
}
 -ms-overflow-style: none;
  scrollbar-width: none; */

  @media (max-width: ${breakpoints.md}px) {
    max-width: 90%;
  }
`;
const CrossWrapper = styled.div<{ background?: string }>`
  position: sticky;
  color: var(--grayscale-body, #394455);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
align-items:center;
line-height: 24px; /* 150% */
  display: flex;
  justify-content: space-between;
  padding-right:20px;
  padding-left:20px;
  padding-top:20px;
  padding-bottom:20px;
  background-color:#ffffff;
  ${(props) => props.background && css`
  background-color: ${props.background};
  `}
  top: 0;
  left:0;
  right:0;
  cursor: pointer;
  z-index: 9999;
`;
export {
  Container,
  Wrapper,
  Title,
  CrossWrapper,
};
