import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { colors, fonts } from '../../styles/variables';
import { formatCash } from '../../utils/money';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { ModalWrapper } from '../ModalWrapper';
import { Text } from '../Text';
import * as S from './styles';

interface IProps {
  toggle: () => void;
  show: boolean;
  amount: number;
}
const LimitApprovedModal: FC<IProps> = ({ toggle, show, amount }) => {
  const history = useHistory();
  const location = useLocation();

  const backToDashboard = () => {
    history.push(`/dashboard`);
    toggle();
  };
  const makeTransfer = () => {
    history.push('/transfers');
    toggle();
  };

  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      // onCloseModal={toggle}
      crossColor={colors.black._100}
      // title="Daily transaction limit"
      titleStyle={{
        fontSize: 28,
        fontWeight: 700,
        color: '##2C2A3B',
        fontFamily: fonts.inter.bold,
      }}
    >
      <S.ContentContainer>
        <S.InfoContainer>
          <div>
            <Icon name="big_info_icon" />
          </div>

          <Text bold size={2}>
            Request Approved!
          </Text>

          <Text size={4} style={{ textAlign: 'center' }}>
            Your daily transaction limit has been increased to{' '}
            <strong style={{ fontFamily: fonts.inter.bold }}>
              ₦{formatCash(amount ?? 0)}
            </strong>{' '}
            You can now make transactions up to your new limit without any
            additional approval.
          </Text>

          <S.ButtonContainer>
            <Button
              style={{ fontSize: 14, width: '70%' }}
              theme="info"
              // size="small"
              label="Back to dashboard"
              onClick={backToDashboard}
            />
            <Button
              style={{ fontSize: 14, width: '100%' }}
              theme="primary"
              // size="small"
              label="Make a transfer"
              onClick={makeTransfer}
            />
          </S.ButtonContainer>
        </S.InfoContainer>
      </S.ContentContainer>
    </ModalWrapper>
  );
};

export default LimitApprovedModal;
