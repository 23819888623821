import { FC } from 'react';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../ModalWrapper';
import * as S from './styles';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { Button } from '../Button';
import { useHistory } from 'react-router-dom';
import { twoFactor } from '../../navigation';
import { useSelector } from 'react-redux';

interface IProps {
  toggle: () => void;
  show: boolean;
  showSkipButton?: boolean;
  fromRoute?: string;
}
const TwoFactorModal: FC<IProps> = ({
  toggle,
  show,
  showSkipButton = true,
  fromRoute,
}) => {
  const history = useHistory();
  const UserProfile = useSelector(
    (state: { currentUser: any }) => state.currentUser.data
  );

  return (
    <ModalWrapper
      overflowY="auto"
      overflowX="auto"
      maxHeight="96vh"
      showModal={show}
      //   onCloseModal={toggle}
      crossColor={colors.black._100}
    >
      <S.Container>
        <Icon name="guard" />
        <div>
          <S.TextContainer>
            <Text bold size={3}>
              Two Factor Authentication
            </Text>
          </S.TextContainer>

          <S.DescriptionContainer>
            <Text size={5}>
              Hi{' '}
              <Text bold>
                {UserProfile?.profile?.firstName ?? ''}{' '}
                {UserProfile?.profile?.lastName ?? ''}
              </Text>
              , we care about your security! Enable 2FA to add an extra layer of
              protection to your account.
            </Text>
          </S.DescriptionContainer>
        </div>
        <S.ButtonContainer>
          <Button
            style={{ width: '100%' }}
            label="Proceed"
            onClick={() => history.push(twoFactor, { fromRoute })}
          />
        </S.ButtonContainer>
        {showSkipButton && (
          <S.ButtonContainer2>
            <Button
              style={{ width: '100%', fontWeight: 800 }}
              theme="light"
              label="Skip for now"
              onClick={toggle}
            />
          </S.ButtonContainer2>
        )}
      </S.Container>
    </ModalWrapper>
  );
};

export default TwoFactorModal;
