import styled, { css } from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints, colors } from '../../styles/variables';

const Container = styled.div`
  margin: ${ptr(0)} ${ptr(20)};

  @media (max-width: ${breakpoints.sm}px) {
    margin: 0 ${ptr(5)};
  }
`;

const CircleDiv = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  min-height: 24px;
  min-width: 24px;
  border-radius: 44px;
  background-color: ${(props) => props.backgroundColor ?? colors.black._25};
  border: solid 1px ${(props) => props.borderColor ?? colors.black._25};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleDescription = styled.div`
  margin-top: 15px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row;
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column !important;
  }
`;

const Line = styled.div`
  height: 2px;
  width: 145px;
  background-color: #a0a3bd;
`;

const TitleWrapper = styled.div`
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #d9dbe9;
  @media (max-width: ${breakpoints.sm}px) {
    height: fit-content;
  }
`;

const ContentContainer = styled.div`
  margin-top: 30px;
`;

const InputContainer = styled.div`
  margin-bottom: 30px;
`;

const BusinessTypeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-bottom: solid 1px ${colors.black._10};
  padding-bottom: 30px;
`;

const uploadSection = styled.div`
  padding-bottom: 30px;

  border-bottom: solid 1px ${colors.black._10};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
  button {
    width: 230px !important;
    @media (max-width: ${breakpoints.sm}px) {
      width: 100% !important;
    }
  }
`;

export {
  Container,
  TitleWrapper,
  CircleDiv,
  TitleDescription,
  Line,
  ContentContainer,
  InputContainer,
  BusinessTypeSection,
  ButtonContainer,
  uploadSection,
};
