import styled, { css } from 'styled-components';
import { colors, transition } from '../../styles/variables';
import { crossBrowserTransition, hexToRgbA, ptr } from '../../styles/utils';

const SectionsWrapper = styled.div`
    margin-top: ${ptr(30)};
`;
const OperatorsConatiner = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${ptr(32)};
    line-height: ${ptr(31)};

    @media (max-width: 992px) {
        grid-template-columns: auto;
    }
`;
const OperatorWrapper = styled.div`
    text-transform: capitalize;
`;

const TermsText = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
`;

const TermsLink = styled.a`
    color: ${colors.pink._100};
`;

const WrapperP1 = styled.div``;

const AlignP1 = styled.div`
    text-align: right;
`;

const HeaderWrapperP1 = styled.div`
    padding: ${ptr(50)} ${ptr(40)};
    background-color: ${colors.pink._100};

    @media (max-width: 576px) {
        padding: ${ptr(30)} ${ptr(30)};
    }
`;
const HeaderWrapperBottomP1 = styled.div`
    padding: ${ptr(50)} ${ptr(50)};

    @media (max-width: 576px) {
        padding: ${ptr(50)} ${ptr(10)};
    }
`;
const HeaderWrapper = styled.div`
    padding: ${ptr(0)} ${ptr(50)};

    @media (max-width: 576px) {
        padding: ${ptr(50)} ${ptr(10)};
    }
`;

const MarginP1 = styled.div`
    text-align: center;
    margin: ${ptr(10)} ${ptr(0)};
`;

const DisplayP1 = styled.div`
    margin: ${ptr(30)} ${ptr(0)};
    display: flex;
    justify-content: space-between;

    button {
        margin: 0 5px;
    }
`;
const DisplayP2 = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        margin: 0 5px;
    }
`;

const Wrapper = styled.div`
    padding: ${ptr(0)};
    /* width: 60vw; */
    width: 100%;
    max-width: 621px;
    min-height: calc(100vh - ${ptr(270)});
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 320px) {
        max-width: 300px;
    }
    @media (min-width: 360px) {
        max-width: 340px;
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 621px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    /* @media (max-width: 992px)
 {
    max-width: 960px;
 }

 @media (max-width: 768px)
{
    max-width: 720px;
}
@media (max-width: 576px)
 {
    max-width: 540px;
} */
`;
const TemplateWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
  background-color: ${colors.black._10};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  border: ${ptr(1)} solid ${colors.black._10};

  ${crossBrowserTransition(transition.slow)}

  border-radius: ${ptr(8)};
  color: ${colors.white._100};
`;

const TemplateWrapperButtonContainer = styled.div`
    @media only screen and (max-width: 800px) {
        margin-bottom: ${ptr(10)};
    }
`;
const TopBarWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: ${ptr(24)} 0;
`;

const ButtonWrapper = styled.div`
  button{
      width: 100%;
      margin-top: ${ptr(16)};
  }
`;

const ButtonsGroup = styled.div`
    display: flex;
    min-width: ${ptr(240)};
    margin-right: ${ptr(16)};
    & > :not(:last-child) {
        margin-right: ${ptr(16)};
    }
    @media only screen and (max-width: 1200px) {
        margin-bottom: ${ptr(20)};
    }
`;

const InputBox = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${ptr(16)};
    border-radius: ${ptr(8)};
    flex: 1.5;
    border: 1px solid ${colors.black._10};
    margin-right: ${ptr(32)};
    @media only screen and (max-width: 1200px) {
        margin-bottom: ${ptr(10)};
    }
`;

const Block = styled.div`
    position: relative;
`;

const ButtonItem = styled.button`
    width: ${ptr(48)};
    height: ${ptr(48)};
    background: ${colors.black._5};
    border-radius: ${ptr(6)};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: ${transition.slow};

    &[disabled] {
        opacity: 0.6;
    }
`;

const TextField = styled.input`
    width: 100%;
    outline: none;
    border: none;
    color: ${colors.black._100};
    transition: 0.3s;
    margin-left: ${ptr(-40)};
    margin-right: ${ptr(-16)};
    padding: ${ptr(16)} ${ptr(16)} ${ptr(12)} ${ptr(50)};
    background: transparent;

    &::placeholder {
        color: ${colors.black._40};
    }
`;

const Dropdown = styled.div`
    background: ${colors.white._100};
    position: absolute;
    top: ${ptr(60)};
    box-sizing: border-box;
    box-shadow: 0px ${ptr(4)} ${ptr(26)} ${hexToRgbA(colors.black._100, 20)};
    width: ${ptr(258)};
    border-radius: ${ptr(8)};
    right: ${ptr(-138)};
    z-index: 1;
`;

const CloseDropdown = styled.button`
    background: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${ptr(24)};
    right: ${ptr(24)};
    position: absolute;
`;

const DropdownContent = styled.div`
    padding: ${ptr(24)};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-height: ${ptr(476)};
    overflow: scroll;
    z-index: 1;
`;

const DropdownBottom = styled.div`
    padding: ${ptr(16)};
    border-top: ${ptr(1)} solid ${colors.black._5};
    display: flex;
    justify-content: space-between;

    > button {
        padding: ${ptr(12)};
        flex: 1;

        &:last-child {
            flex: 1.4;
            margin-left: ${ptr(12)};
        }
    }
`;

const FilterBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    &:not(:last-child) {
        margin-bottom: ${ptr(24)};
    }

    > span {
        margin-bottom: ${ptr(12)};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    > div {
        margin: ${ptr(12)} 0;
    }
`;

const PreloadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${ptr(94)} 0;
`;

const TableContainer = styled.div<{ loading: boolean }>`
    ${(props) =>
        props.loading &&
        css`
            opacity: 0.8;
        `}
`;

const Column = styled.div`
    > * {
        display: block;
        text-transform: capitalize;
    }
`;

const Blank = styled.div`
    height: ${ptr(24)};
`;

export {
    TemplateWrapper,
    TemplateWrapperButtonContainer,
    Blank,
    TableContainer,
    PreloadContainer,
    Wrapper,
    TopBarWrapper,
    ButtonWrapper,
    ButtonsGroup,
    InputBox,
    ButtonItem,
    TextField,
    Dropdown,
    CloseDropdown,
    DropdownBottom,
    DropdownContent,
    FilterBox,
    Content,
    Block,
    Column,
    /*  */
    WrapperP1,
    AlignP1,
    MarginP1,
    HeaderWrapperP1,
    HeaderWrapperBottomP1,
    DisplayP1,
    SectionsWrapper,
    OperatorsConatiner,
    OperatorWrapper,
    TermsText,
    TermsLink,
    HeaderWrapper,
    DisplayP2,
};
