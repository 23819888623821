import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import isEmail from 'validator/lib/isEmail';

import * as S from './styles';
import { WelcomeSlider } from '../../components/WelcomeSlider';
import { TwoSide, Columns, FormButton } from '../../styles/wrappers';
import { RoundButton } from '../../components/RoundButton';
import { FormTitle } from '../../components/FormTitle';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { validateObject, ValidateResult } from '../../utils/validation';
import ResetDone from './components/ResetDone';
import authService from '../../services/internal/auth/auth.service';
import { failed } from '../../components/Toasts';
import * as LoginStyle from '../LogIn/styles';
import { Text } from '../../components/Text';
import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';
import { ModalWrapper } from '../../components/ModalWrapper';
import { Icon } from '../../components/Icon';
import { SuccessModal } from '../../components/SuccessModal';
import { SignUpButton } from '../../components/SignupButton';
import TwoFactorNotificationModal from './components/2faModal';

const SCHEME = {
  email: (value = '') => isEmail(value),
};

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState<ValidateResult | undefined>(
    undefined
  );
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show2fa, setShow2fa] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setValidation(validateObject({ email }, SCHEME));
  }, [email]);

  const toggle2fa = () => setShow2fa((prev) => !prev);

  const sendRequest = async () => {
    try {
      setLoading(true);
      await authService.forgorPassword(email);
      setEmailSent(true);
      setLoading(false);
    } catch (err) {
      const error = err as any;
      failed('Error', error.message || '');
      if (error.code === '2FA_NOT_SET') {
        toggle2fa();
      }
      setLoading(false);
    }
  };

  const onEnterPressedEmail = () => {
    if (validation?.isValid) {
      sendRequest();
    }
  };

  const onBack = () => {
    history.goBack();
  };
  const onComplete = () => {
    history.push('/');
  };

  // if (emailSent) {
  //   return <ResetDone onClick={onComplete} />;
  // }
  function onCloseModal() {
    setEmailSent(false);
    onComplete();
  }
  return (
    <TwoSide.Wrapper>
      <SuccessModal
        title="Done!"
        description="We have sent you an email to reset your password. Follow the link in the email to create a new password."
        onClose={onCloseModal}
        show={emailSent}
      />
      <TwoSide.Left>
        <WelcomeSlider />
      </TwoSide.Left>
      <TwoSide.Right>
        <Columns.Wrapper>
          <Columns.ContentWrapper>
            <RoundButton icon="arrow-left" onClick={onBack} />
            <SignUpButton />
          </Columns.ContentWrapper>
          <div style={{ padding: `0 ${ptr(60)} 0 ${ptr(30)}` }}>
            <Columns.ContentWrapper>
              <FormTitle
                title="Reset password"
                titleColor={colors.black._20}
                description="Enter the email address associated with your VBiz account and we will send the link to your email to reset password."
                descriptionColor={colors.black._15}
              />
              <S.TextInputWrapper>
                <TextInput
                  label="Email"
                  valid={validation?.data?.email?.isValid}
                  value={email}
                  onChange={setEmail}
                  onEnterPressed={onEnterPressedEmail}
                />
              </S.TextInputWrapper>
              <FormButton.WrapperEnd>
                <Button
                  onClick={onEnterPressedEmail}
                  label="Reset password"
                  disabled={!validation?.isValid || loading}
                  style={{ width: '100%' }}
                />
              </FormButton.WrapperEnd>
            </Columns.ContentWrapper>
          </div>

          <Columns.ContentWrapper />
          <LoginStyle.Onboarding>
            <Text color="#6E7191">
              © {new Date().getFullYear()} VFD MFB, all rights reserved.
            </Text>
          </LoginStyle.Onboarding>
        </Columns.Wrapper>
      </TwoSide.Right>

      <TwoFactorNotificationModal show={show2fa} toggle={toggle2fa} />
    </TwoSide.Wrapper>
  );
};

export default ResetPassword;
