import React, { useState, useEffect } from 'react';
import { Preload } from '../../../components/Preload';
import * as S from './styles';
import { TextInput } from '../../../components/TextInput';
import { DropdownInput } from '../../../components/DropdownInput';
import { Icon } from '../../../components/Icon';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { InfoConponent } from '../../../components/Info';
import { DropdownInputModal } from '../../../components/DropdownInputModal';
import { InfoForm5 } from '../../Onboarding/components/InfoForm5';
import { MinimizedInfo } from '../../../components/MinimizedInfo';
import { batch, useDispatch, useSelector } from 'react-redux';
import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';
import { SignUpUboInterface } from '../../../services/internal/onboarding/IOnboarding';
import {
  ValidateArrayResult,
  validateArrayOfObjects,
} from '../../../utils/validation';
import onboardingService from '../../../services/internal/onboarding/onboarding.service';
import { OnboardingActions } from '../../../redux/onboarding/actions';
import { actions } from '../config';
import { useHistory } from 'react-router-dom';
import { ptr } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const DefaultUboItem = {
  businessType: '',
  cac: '',
  companyName: '',
  uboType: '',
};

const SCHEME = {
  cac: (value: string) => value.length > 1,
  businessType: (value: string) => value.length > 1,
  companyName: (value: string) => value.length > 1,
};
export const BusinessEntity: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();
  const ubos = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.ultimateBeneficialOwners as Array<
        SignUpUboInterface
      >
  );
  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const [validationCollection, setValidationCollection] = useState<
    ValidateArrayResult
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [collection, setCollection] = useState<Array<SignUpUboInterface>>(
    ubos?.length
      ? ubos.map((item) => ({
          bvn: item.bvn,
          idNumber: item.idNumber,
          idType: item.idType,
          uboType: 'businessEntity',
        }))
      : [DefaultUboItem]
  );

  const push = () => {
    if (validationCollection?.isValid) {
      const newCollection = collection.map((coll) => {
        coll.isAdded = true;
        coll.isEdit = false;
        return coll;
      });
      setCollection([...newCollection, DefaultUboItem]);
    }
  };

  const remove = (index: number) => {
    setCollection([...collection.filter((_item, i) => i !== index)]);
  };

  const onChangeValue = (index: number, property: string, value: unknown) => {
    setCollection(
      (old) =>
        old.map((el: SignUpUboInterface, i: number) => {
          if (i === index) {
            return {
              ...el,
              [property]: value,
            };
          }

          return el;
        }) as Array<SignUpUboInterface>
    );
  };

  const editInfo = (index: number) => {
    const newCollection = [...collection];
    newCollection[index].isEdit = true;
    setCollection([...newCollection]);
  };

  const continueHandle = async (extraPayload?: { skip: true }) => {
    setLoading(true);
    const newCollection = collection.map((_collection) => {
      delete _collection.isAdded;
      delete _collection.isEdit;
      _collection.uboType = 'businessEntity';
      return _collection;
    });
    try {
      const response = await onboardingService.submitUboDetails({
        ...(extraPayload?.skip && { skip: extraPayload.skip }),
        ...(!extraPayload?.skip && { ultimateBeneficialOwners: newCollection }),
        reference,
      });

      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            ultimateBeneficialOwners: [...newCollection],
            reference: response.data.reference,
          })
        );
      });
      route.push(actions[response.actionRequired]);
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setValidationCollection(validateArrayOfObjects(collection, SCHEME));
  }, [collection]);

  const renderBusinessEntity = () => (
    <>
      <Text bold size={6} color='#394455'>
        Provide company’s information
      </Text>

      {collection.map((item, index) => (
        <div style={{ marginBottom: '30px' }}>
          {!item.isAdded || item.isEdit ? (
            <InfoForm5
              companyName={item.companyName}
              cac={item.cac}
              businessType={item.businessType}
              index={index}
              removeFromIndex={1}
              remove={remove}
              onChange={onChangeValue}
              companyNameIsValid={
                validationCollection?.data[index]?.data?.companyName?.isValid
              }
              cacIsValid={validationCollection?.data[index]?.data?.cac?.isValid}
            />
          ) : (
            <MinimizedInfo
              title={`${item.companyName} || ${item.cac}`}
              description=''
              handleEdit={() => editInfo(index)}
              handleDelete={() => remove(index)}
            />
          )}
        </div>
      ))}

      <S.InfoContainer>
        <InfoConponent text='Please note that you will be required to provide the CAC documents of business entity/ies in the upload page' />
      </S.InfoContainer>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <S.Add onClick={push}>
          <Icon name='plus' color='#000000' />
          <Text color='#7F91A8'>Add another UBO </Text>
        </S.Add>
        {collection.length > 1 && (
          <div
            style={{
              display: 'flex',
              gap: 10,
              alignItems: 'center',
              marginBottom: ptr(40),
              cursor: 'pointer',
            }}
            onClick={() =>
              collection.length > 1 && remove(collection.length - 1)
            }
          >
            <Icon name='cancel' color={colors.red._100} />
            <Text size={6} color={colors.red._100}>
              Cancel
            </Text>
          </div>
        )}
      </div>
      <S.ButtonGroup>
        <div>
          <Button
            style={{
              width: '161px',
              color: colors.black._20,
              background: '#F5F7FA',
            }}
            label='Skip'
            loading={loading}
            onClick={() => continueHandle({ skip: true })}
          />
          <Button
            style={{ width: '304px' }}
            label='Continue'
            loading={loading}
            disabled={!validationCollection?.isValid || loading}
            onClick={continueHandle}
          />
        </div>
      </S.ButtonGroup>
    </>
  );

  return (
    <S.Container>
      {renderBusinessEntity()}

      {loading ? (
        <S.PreloadContainer>
          <Preload />
        </S.PreloadContainer>
      ) : (
        ' '
      )}
    </S.Container>
  );
};
