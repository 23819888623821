import React, { useCallback, useEffect, useState } from 'react';

import { ModalWrapper } from '../ModalWrapper';

import * as S from './styles';
import { Text } from '../Text';
import { Table } from '../Table';
import { Icon } from '../Icon';
import { CheckBox } from '../CheckBox';
import { CheckAreaGroup } from '../CheckAreaGroup';
import { Button } from '../Button';
import { colors } from '../../styles/variables';
import { DeletePopup } from '../../pages/Onboarding/components/DeletePopup';
import { useDropzone } from 'react-dropzone';
import { failed, success } from '../Toasts';
import { useHistory } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import onboardingService from '../../services/internal/onboarding/onboarding.service';
import { OnboardingActions } from '../../redux/onboarding/actions';
import { actions } from '../../pages/Onboard/config';
import { Loader } from '../Loader';
import { Preload } from '../Preload';
import { IUploadData } from '../../pages/Onboarding/steps/common.upload.documents';
import {
  capitalize,
  getBusinessTypeIdLabel,
  handleCamelcasing,
} from '../../utils/strings';
import { DirectorsList } from '../../pages/Onboarding/steps/common.upload.documents/business.types/others';
import { OnboardingInterface } from '../../redux/onboarding/IOnboarding';
import { LeaderDirectorShareholderInterface } from '../../services/internal/onboarding/IOnboarding';
import { DropdownInput, DropdownOptionProps } from '../DropdownInput';
import { BusinessTypesEnum, UploadEnum } from '../../pages/Onboard/constants';

const FORMATS_TO_PREVIEW = ['image/png', 'image/jpg', 'image/jpeg'];
const EXCEL_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
const EXCEL_EXTENSIONS = ['csv', 'xls', 'xlsx'];
const MAX_SIZE = 2000000;

interface FileIconProps {
  title?: string;
}

const FileIcon: React.FC<FileIconProps> = ({ title = '' }) => (
  <S.FileIconContainer>
    <S.FileIconTitleWrapper>
      <S.FileIconTitle>{title?.toUpperCase()}</S.FileIconTitle>
    </S.FileIconTitleWrapper>
    <S.FileIconBackground />
  </S.FileIconContainer>
);

interface DeleteCrossProps {
  onClick: () => void;
}

const DeleteCross: React.FC<DeleteCrossProps> = ({ onClick }) => (
  <S.DeleteCross onClick={onClick}>
    <Icon name='trash-can-updated' />
  </S.DeleteCross>
);

export interface PreviewProps {
  withPreviewImage: boolean;
}
export interface FileUploadProps {
  /**
   * Accepted formats of files (all accepted by default)
   */
  acceptedFormats?: Array<string>;
  /**
   * Formats to preview (images 'image/png', 'image/jpg', 'image/jpeg' by default)
   */
  formatsToPreview?: Array<string>;
  file?: File;
  visible: boolean;
  /**
   * On change file event
   */
  onClose: () => void;
  onChange?: (file: File | undefined) => void;
  /**
   * Custom props
   */
  email?: string;
  type: IUploadData;
  reference: string;
  isSignature?: boolean;
  isReferenceForm?: boolean;
  setUploadData?: (data: IUploadData[]) => void;
  uploadData?: IUploadData[];
}

interface FileNameAndFormat {
  name: string;
  format: string;
}

export interface ConsentModalProps {
  image?: string;
  onClose: () => void;
  visible: boolean;
}

export const UploadModal: React.FC<FileUploadProps> = ({
  visible,
  formatsToPreview = FORMATS_TO_PREVIEW,
  email = '',
  onClose,
  isSignature = false,
  uploadData,
  setUploadData,
  ...props
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const route = useHistory();

  const [openn, setOpen] = useState(false);
  const [file, setFile] = useState<Blob | string>('');
  const [fileSize, setFileSize] = useState('');
  const [fileName, setFileName] = useState<FileNameAndFormat | null>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [directors, setDirectors] = useState<DirectorsList>([]);
  const [director, setDirector] = useState<DropdownOptionProps | null>(null);

  const leaders = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.directors as Array<
        LeaderDirectorShareholderInterface
      >
  );

  const businessType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.businessType as BusinessTypesEnum
  );

  const getFileNameAndFormat = (name: string): FileNameAndFormat => {
    const split = name.split('.');
    return {
      name: split.slice(0, split.length - 1).join(''),
      format: split[split.length - 1],
    };
  };

  const handleClose = () => {
    deleteFile();
    onClose();
  };

  const isNeedPreview = (fileType: string) =>
    !!formatsToPreview?.includes(fileType);

  const getAndSetPreview = (newFile: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(newFile);

    setLoading(true);

    reader.onloadend = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPreview(reader.result);
      setLoading(false);
    };
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (
      EXCEL_EXTENSIONS?.includes(acceptedFiles[0].name.split('.').pop()) ||
      EXCEL_FORMATS?.includes(acceptedFiles[0].type)
    ) {
      failed('Failed', 'Wrong file format');
      return;
    }
    if (acceptedFiles[0].size > MAX_SIZE) {
      failed('Failed', 'File should not be more then 2mb');
      return;
    }
    if (isSignature && !formatsToPreview?.includes(acceptedFiles[0].type)) {
      failed('Failed', 'Signature must be in picture format');
      return;
    }
    if (acceptedFiles[0]) {
      if (props.onChange) {
        props.onChange(acceptedFiles[0]);
      }
      setFileSize(acceptedFiles[0].size);
      setFile(acceptedFiles[0]);
      setFileName(getFileNameAndFormat(acceptedFiles[0].name));

      if (isNeedPreview(acceptedFiles[0].type)) {
        getAndSetPreview(acceptedFiles[0]);
      }
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: props?.acceptedFormats?.join(',') || undefined,
  });

  const deleteFile = () => {
    setFile('');
    setFileName(null);
    setPreview(undefined);

    if (props.onChange) {
      props.onChange(undefined);
    }

    setOpen(false);
  };

  const deleteFilePopup = () => {
    setOpen(!openn);
  };

  const uploadDocument = () => {
    setLoading(true);
    setShowModal(true);
    const timer = setTimeout(() => {
      setLoading(false);
      setShowModal(false);
    }, 500);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const deleteFilePopupCan = () => {
    setOpen(true);
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append('file', file);
      data.append('type', props.type.value);
      data.append('reference', props.reference);
      data.append('email', director?.value || '');
      const response = await onboardingService.uploadDocuments(data);
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            reference: response.data.reference,
          })
        );
      });
      success('File upload', 'Successful');
      if (uploadData && setUploadData) {
        const updatedUploadData = uploadData.map((data) => {
          if (data.label === props.type.label) {
            data.file = file;
            data.email = director?.value;
          }
          return data;
        });
        setUploadData(updatedUploadData);
        setDirector(null);
      }
      handleClose();
    } catch (error) {
      handleClose();
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(
          actions[err.message.trim().split(':')[1].replace(/\s/g, '')]
        );
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setFile(props.file as File);
  }, [props.file]);

  // useEffect(() => {
  //   if (file && !props.isReferenceForm) {
  //     handleUpload();
  //   }
  // }, [file]);

  useEffect(() => {
    if (leaders && leaders.length) {
      setDirectors(
        leaders.map((item, index) => ({
          label: item.firstName
            ? `${item.firstName} ${item.lastName}`
            : item.email,
          value: item.email,
          category: 'Directors',
          subLabel: item.email,
          key: index.toString(),
        }))
      );
    }
  }, [leaders]);


  function getUpdatedDirectorList() {
    const data = uploadData;
    const dataObject = data
      ?.map((_data) => _data.email)
      .filter((_data) => _data);
    if (dataObject) {
      const newDirectors = directors.filter(
        (item) => !dataObject.includes(item.value)
      );
      return newDirectors;
    } else {
      return directors;
    }
  }

  function showEmailSelection() {
    switch (props.type.value) {
      case UploadEnum.directorId:
      case UploadEnum.partnerId:
      case UploadEnum.trusteeId:
      case UploadEnum.principalId:
      case UploadEnum.proprietorId:
        return true;
      default:
        return false;
    }
  }

  return (
    <ModalWrapper
      maxWidth='681px'
      // maxWidth='818px'
      // maxHeight='691px'

      // maxHeight='100vh'
      showModal={visible}
      onCloseModal={handleClose}
      title='Upload document'
      crossColor='#000000'
    >
      {' '}
      {showModal && (
        <S.LoadContainer>
          {' '}
          <S.PreloadContainer>
            <Preload />
          </S.PreloadContainer>
        </S.LoadContainer>
      )}
      <S.Container>
        {/* {loading ? (
          <S.PreloadContainer>
            <Preload />
          </S.PreloadContainer>
        ) : (
          ''
        )}{' '} */}
        <S.TopText>
          <Text size={8} color='#6E7191'>
            Document type to upload: {'  '}
            <Text bold size={8} color='#394455'>
              {props.type.label}
            </Text>
          </Text>
        </S.TopText>
        <S.Wrapper {...getRootProps()}>
          <input {...getInputProps()} />

          {file && !loading ? (
            <>
              <S.WrapperEmpty>
                <S.FileIconWrapper></S.FileIconWrapper>
                <S.TextDrag1>
                  <div
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon name='file-displayb' color={colors.pink._100} />
                  </div>
                  <div>
                    <S.FileFormat>
                      <S.FileName>
                        <Text bold size={7} color='#000000'>
                          {fileName?.name}.{fileName?.format}
                        </Text>
                      </S.FileName>{' '}
                    </S.FileFormat>
                  </div>
                  <div>
                    <Text size={7} color='#394455'>
                      {fileSize}kb
                    </Text>
                  </div>
                  <div
                    style={{
                      marginTop: '4px',
                    }}
                  >
                    <DeleteCross
                      // onClick={deleteFilePopupCan}
                      onClick={deleteFile}
                    />
                  </div>
                  {/* {openn && (
                    <div style={{ position: 'absolute', zIndex: 90 }}>
                      <DeletePopup
                        onClick={deleteFile}
                        onCancel={deleteFilePopup}
                      />
                    </div>
                  )} */}
                </S.TextDrag1>
              </S.WrapperEmpty>

              {showEmailSelection() && (
                <S.IdWrapper
                  style={{
                    marginTop: 10,
                    marginBottom: 5,
                    width: 'inherit',
                  }}
                >
                  <div>
                    <Text size={6}>This ID card belongs to who? </Text>
                    <span style={{ color: '#DB9308', fontSize: 14 }}>
                      Please select the {getBusinessTypeIdLabel(businessType)}{' '}
                      from the list earlier provided.
                    </span>
                  </div>
                  <DropdownInput
                    placeholder={`Select ${capitalize(
                      getBusinessTypeIdLabel(businessType)
                    )}'s email`}
                    options={getUpdatedDirectorList()}
                    //value={firstDirector?.value}
                    onSelect={setDirector}
                  />
                </S.IdWrapper>
              )}

              <S.ButtonGroup>
                <div>
                  <Button
                    label='Upload document'
                    onClick={handleUpload}
                    disabled={!file || (showEmailSelection() && !director)}
                    loading={loading}
                    style={{ width: 304 }}
                  />
                </div>
              </S.ButtonGroup>
              {/* {loading ? <Loader /> : <Loader />} */}
              {/* <S.PreviewWrapper withPreviewImage={!!preview}>
                <S.FileNameWrapper>
                  <S.FileFormat>
                    <Icon name='file-display' color={colors.pink._100} />
                    <S.FileName>{fileName?.name}</S.FileName> .
                    {fileName?.format.toUpperCase()}
                  </S.FileFormat>

                  <DeleteCross onClick={deleteFilePopupCan} />
                </S.FileNameWrapper>
              </S.PreviewWrapper>
              {openn && (
                <div style={{ position: 'absolute', zIndex: 90 }}>
                  <DeletePopup
                    onClick={deleteFile}
                    onCancel={deleteFilePopup}
                  />
                </div>
              )} */}
            </>
          ) : (
            //  : loading ? (
            //   <S.PreloadContainer>
            //     <Preload />
            //   </S.PreloadContainer>
            // )
            <>
              <S.WrapperEmpty onClick={open}>
                <S.FileIconWrapper>
                  <Icon name='cloud-upload' />
                </S.FileIconWrapper>
                <S.TextDrag>
                  <div style={{ textAlign: 'center' }}>
                    <Text bold size={8} color='#000000'>
                      Drag and drop or{'  '}
                      <Text bold size={8} color='#B10180'>
                        Browse{' '}
                      </Text>
                      file
                    </Text>
                  </div>

                  <div>
                    <Text size={8} color={colors.black._80}>
                      {isSignature
                        ? 'Accepted file format: *.jpeg, *.png or *.pdf only'
                        : 'Accepted file format: *.jpeg, *.png or *.pdf, *.gif'}
                    </Text>
                  </div>
                </S.TextDrag>
              </S.WrapperEmpty>

              <S.ButtonGroup>
                <div>
                  <Button
                    disabled={true}
                    label='Upload document'
                    style={{ width: 304 }}
                    loading={loading}
                  />
                </div>
              </S.ButtonGroup>
            </>
          )}
        </S.Wrapper>
      </S.Container>
    </ModalWrapper>
  );
};
