import styled from 'styled-components';

import { ptr } from '../../../../styles/utils';
import { colors } from '../../../../styles/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 271px;
  width: 420px;
  padding: 40px;
  > button {
    width: 100%;
  }
`;
const Img = styled.img``;

const SuccessDescription = styled.div`
  width: fit-content;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  > div {
    margin-top: 8px;
    display: flex;
    padding: 8px 10px;
    background: #fff9ef;
    justify-content: center;
  }
`;

const Circle = styled.div`
  // background: ${colors.pink._100};
  border-radius: ${ptr(104)};
  width: ${ptr(104)};
  height: ${ptr(104)};
  display: flex;
  margin-bottom:-10px;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin: ${ptr(28)} 0;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 0.6px dotted rgba(110, 113, 145, 0.42);

  > span {
    text-align: center;
    display: block;

    :last-child {
      margin-top: ${ptr(8)};
    }
  }

  > :last-child {
    width: 100%;

    background: pink;
    display: flex;
    margin: 0 auto;
    margin-top: ${ptr(30)};
    justify-content: center;
  }
`;

export { Container, Wrapper, Circle, Img, SuccessDescription };
