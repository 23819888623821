import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OnboardingInterface } from '../../../redux/onboarding/IOnboarding';

import { Button } from '../../../components/Button';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from '../../../components/LoadingModal';
import { OnboardingCard } from '../../../components/OnboardingCard';
import { FormTitle } from '../../../components/FormTitle';
import { TextInput } from '../../../components/TextInput';
import { OnboardingOtpModal2 } from '../../../components/OnboardingOtpModal2';
import { CompanyInterface } from '../../../redux/company/ICompany';
import subaccountService from '../../../services/internal/subaccount/subaccount.service';
import { failed, success } from '../../../components/Toasts';
import { SubaccountActions } from '../../../redux/subaccount/actions';

export const CommonConfirmDetails: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();
  const location = useLocation();

  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const [loading, setLoading] = useState(false);

  const continueHandle = async () => {
    try {
      setLoading(true);
      if (activeCompany) {
        const response = await subaccountService.requestOtpForSubaccount(
          activeCompany.id
        );
        success('Otp request successful', response.message);
        toggleOtp();
      }
    } catch (error) {
      const err = error as any;
      failed('Error', err.message);
    }
    setLoading(false);
  };

  const modalCallBack = async (otp: string) => {
    try {
      setLoading(true);
      if (activeCompany) {
        const response = await subaccountService.validateOtpForSubaccount({
          businessId: activeCompany.id,
          otp,
        });
        dispatch(SubaccountActions.handleSetSubaccountRequest(response));
        route.push('/subaccount/account-information');
      }
    } catch (error) {
      const err = error as any;
      failed('Error', err.message);
    }
    setLoading(false);
  };

  const corporatePhoneNumber = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.corporatePhoneNumber as string
  );
  const [showOtp, setShowOtp] = useState(false);

  const toggleOtp = () => {
    setShowOtp((prev) => !prev);
  };

  const [phoneNumber, setPhoneNumber] = useState<string>(
    corporatePhoneNumber || ''
  );

  return (
    <S.Container>
      {/* <Modal visible={loading} /> */}
      <S.TopTitle>
        <FormTitle
          margin={-3}
          titleColor={colors.black._20}
          title='Confirm company details'
          description='Please  review your company details'
        />
      </S.TopTitle>

      {activeCompany && (
        <OnboardingCard>
          <S.Wrapper>
            <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
              <div style={{ flex: 1 }}>
                <TextInput
                  value={activeCompany.name}
                  label='Company name'
                  disabled={true}
                />
              </div>

              {activeCompany.address && (
                <div style={{ flex: 1 }}>
                  <TextInput
                    value={activeCompany.address}
                    label='Address'
                    disabled={true}
                  />
                </div>
              )}
            </S.ItemRow>

            <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
              <div style={{ flex: 1 }}>
                <TextInput
                  value={activeCompany.primaryEmail}
                  label='Email Address'
                  disabled={true}
                />
              </div>

              <div style={{ flex: 1 }}>
                <TextInput
                  value={activeCompany.phone}
                  label='Phone Number'
                  disabled={true}
                />
              </div>
            </S.ItemRow>

            <S.ItemRow style={{ display: 'flex', gap: 15, width: '100%' }}>
              <div style={{ flex: 1 }}>
                <TextInput
                  value={activeCompany.accounts[0]?.type}
                  label='Business Type'
                  disabled={true}
                />
              </div>

              {/* <div style={{ flex: 1 }}>
                <TextInput value={''} label='Sector' disabled={true} />
              </div> */}
            </S.ItemRow>

            <OnboardingOtpModal2
              showModal={showOtp}
              onContinue={(otp) => modalCallBack(otp)}
              // data={{ email, phoneNumber }}
              onCloseModal={toggleOtp}
            />

            <S.ButtonGroup>
              <div>
                <Button
                  loading={loading}
                  style={{ width: 304 }}
                  label='Continue'
                  onClick={() => continueHandle()}
                  // onClick={toggleOtp}
                />
              </div>
            </S.ButtonGroup>
          </S.Wrapper>
        </OnboardingCard>
      )}
    </S.Container>
  );
};
