import {
  CSSProperties,
  FC,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
// import { Tooltip as ReactTooltip } from 'react-tooltip';

export interface IToolTipProps {
  list: {
    text: string;
    innerList?: {
      text: string;
    }[];
  }[];
  style?: CSSProperties;
  reff?: RefObject<HTMLDivElement>;
  infoType?: string; // 'ordered' | 'unordered';
}
const Tooltip: FC<IToolTipProps> = ({ list, style, infoType, reff }) => {
  const [show, setShow] = useState(false);

  let timer = useRef<NodeJS.Timeout>();
  const toolRef = useRef<any>();

  const toggle = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);
  const scrollToBottom = () => {
    if (reff?.current && show) {
      reff.current.scrollIntoView({ behavior: 'smooth' });
      // console.log(reff.current.scrollHeight);
      // reff.current.scrollTop = reff.current.scrollHeight;
    }
    if (toolRef?.current && show) {
      toolRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // let debouncefn: NodeJS.Timeout;
    // debouncefn = setTimeout(() => {
    scrollToBottom();
    // }, 800);
    // return clearTimeout(debouncefn);
  }, [show]);
  const handleMouseEnter = () => {
    if (timer.current) clearTimeout(timer.current);
    setShow(true);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setShow(false);
    }, 800);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        cursor: 'pointer',
        // overflowY: 'visible',
      }}
      onMouseEnter={(e) => {
        e.stopPropagation();
        handleMouseEnter();
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();
        handleMouseLeave();
      }}
    >
      <Icon name={'info'} color={colors.amber._90} />
      {show && (
        <S.Wrapper
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={toolRef}
        >
          <S.Content>
            {!infoType ||
              (infoType === 'unordered' && (
                <ul style={{ listStyle: 'unset' }}>
                  {list.length > 0 &&
                    list.map((li, i) => (
                      <li key={i.toString()}>
                        {li.text}
                        {li.innerList &&
                          li.innerList?.length > 0 &&
                          li.innerList.map((li2, i) => (
                            // {/* <ul style={{ marginLeft: 15, listStyle: 'unset' }}> */}
                            <li key={i.toString()} style={{ marginLeft: 15 }}>
                              {li2.text}
                            </li>
                          ))}
                      </li>
                    ))}
                </ul>
              ))}

            {infoType && infoType === 'ordered' && (
              <ol style={{ listStyle: 'decimal' }}>
                {list.length > 0 &&
                  list.map((li, i) => (
                    <li key={i.toString()}>
                      {li.text}
                      {li.innerList &&
                        li.innerList?.length > 0 &&
                        li.innerList.map((li2, i) => (
                          // {/* <ul style={{ marginLeft: 15, listStyle: 'unset' }}> */}
                          <li key={i.toString()} style={{ marginLeft: 15 }}>
                            {li2.text}
                          </li>
                        ))}
                    </li>
                  ))}
              </ol>
            )}
          </S.Content>
        </S.Wrapper>
      )}
    </div>
  );
};

export default Tooltip;
