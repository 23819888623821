import * as S from './styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Title } from '../../components/Title';
import { batch, useDispatch, useSelector } from 'react-redux';
import { InfoSection } from '../../components/InfoSection';
import { OnboardingActions } from '../../../../redux/onboarding/actions';
import { OnboardingInterface } from '../../../../redux/onboarding/IOnboarding';
import onboardingService from '../../../../services/internal/onboarding/onboarding.service';
import { actions } from '../../../Onboard/config';
import { Modal } from '../../../../components/LoadingModal';
import { BusinessTypesEnum, SIGN_UP } from '../../../Onboard/constants';
import {
  Details,
  getTypeOfBusinessModalDetails,
} from '../../../../utils/businessTypeModalDetails';
import { TypeOfBusinessModal } from '../../../../components/TypeOfBusinessModal';
import { UseDetailsModal } from '../../../../components/UseDetailsModal';
import { OnboardingCard } from '../../../../components/OnboardingCard';
import { InfoSectionUpdated } from '../../components/InfoSectionUpdated';
import { OnboardingFormHeader } from '../../../../components/OnboardingFormHeader';
import { colors } from '../../../../styles/variables';
import { OnboardClickPanel } from '../../../../components/OnboardClickPanel';

export const data = [
  {
    title: 'Sole proprietorship',
    description:
      'The business is owned by one person who will oversee the account operations.',
    backgroundColor: '#EEF7FF',
    businessType: BusinessTypesEnum.soleProprietorship,
  },
  {
    title: 'Partnership',
    description:
      'The business is co-owned by partners who will jointly manage the account.',
    backgroundColor: '#FFF9EF',
    businessType: BusinessTypesEnum.partnership,
  },
  {
    title: 'Limited Liability Company',
    description:
      'The business entity is owned by shareholders and overseen by a board.',
    backgroundColor: '#FFF3F8',
    businessType: BusinessTypesEnum.limitedLiability,
  },
  {
    title: 'Trust/NGO',
    description:
      'The business is acting as a guardian holding authority and responsible for managing the purpose of the trust.',
    backgroundColor: '#EFEEFB',
    businessType: BusinessTypesEnum.trusteeNgo,
  },
  {
    title: 'Associations, Clubs and Societies',
    description:
      'We pursue a common interest, put funds together to achieve the objectives of the entity.',
    backgroundColor: '#EFF0F6',
    businessType: BusinessTypesEnum.cooperativeSociety,
  },
];

export const CommonTypeOfBusiness: React.FC = () => {
  const dispatch = useDispatch();
  const route = useHistory();

  const reference = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.reference as string
  );

  const onboardingType = useSelector(
    (state: { onboardingStack: OnboardingInterface }) =>
      state.onboardingStack.data.type as string
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<Details | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showIdModal, setShowIdModal] = useState<boolean>(false);
  const [businessType, setBusinessType] = useState<BusinessTypesEnum | null>(
    null
  );

  const handleCloseModal = () => {
    setDetails(null);
    setShowModal(false);
  };

  const continueHandle = async (
    businessType: BusinessTypesEnum,
    withDetails: boolean = true
  ) => {
    setLoading(true);

    try {
      const response = await onboardingService.submitBusinessType({
        businessType,
        reference,
      });
      batch(() => {
        dispatch(
          OnboardingActions.handleSetData({
            businessType,
            reference: response.data.reference,
          })
        );
      });
      if (response.actionRequired === 'SUBMIT_BUSINESS_INFO') {
        if (withDetails) {
          route.push(actions[response.actionRequired]);
        } else {
          route.push(actions[response.actionRequired]);
          // route.push('/onboarding/company-details');
        }
      } else {
        route.push(actions[response.actionRequired]);
      }
    } catch (error) {
      const err = error as any;
      if (err.code === 'INVALID_ACTION_REQUIRED') {
        route.push(actions[err.message.substring(50)], {
          reference,
          businessType,
        });
      } else {
        dispatch(
          OnboardingActions.handleSetError(
            err.message || 'Can not complete request at the moment'
          )
        );
      }
    }
    setLoading(false);
  };

  const handleProceed = () => {
    if (businessType === BusinessTypesEnum.cooperativeSociety) {
      continueHandle(businessType);
    } else {
      setDetails(null);
      setShowModal(false);
      // setShowIdModal(true);
      if (businessType) continueHandle(businessType);
    }
  };

  const handleBusinessTypeClick = (action: BusinessTypesEnum) => {
    if (onboardingType === SIGN_UP) {
      const actionDetails = getTypeOfBusinessModalDetails(action);
      setDetails(actionDetails);
      setShowModal(true);
      setBusinessType(action);
      // route.push('/onboarding/company-details');
    } else {
      continueHandle(action);
    }
  };

  const continueOnboarding = (withDetails: boolean) => {
    if (!withDetails) {
      batch(() => {
        dispatch(OnboardingActions.handleDontUseDetails());
      });
    }
    businessType && continueHandle(businessType, withDetails);
  };

  return (
    <S.Container>
      <TypeOfBusinessModal
        showModal={showModal}
        onCloseModal={handleCloseModal}
        details={details}
        handleProceed={handleProceed}
      />
      <UseDetailsModal
        showModal={showIdModal}
        onCloseModal={() => setShowIdModal(false)}
        continueWithDetails={() => continueOnboarding(true)}
        continueWithoutDetails={() => continueOnboarding(false)}
      />
      <Modal visible={loading} title="Please wait..." />
      <OnboardingFormHeader
        description="What type of business do you want to set up?"
        // descriptionColor='#6E7191'
        title="Choose business type"
        titleColor={colors.black._20}
      />
      <OnboardingCard>
        <S.Wrapper>
          {data.map((info, i) => (
            <OnboardClickPanel
              key={i}
              title={info.title}
              actionText="View requirements"
              showActionButton={onboardingType === SIGN_UP ? true : false}
              description={info.description}
              backgroundColor={info.backgroundColor}
              handleOnClick={() => {
                handleBusinessTypeClick(info.businessType);
              }}
            />
          ))}
        </S.Wrapper>
      </OnboardingCard>
    </S.Container>
  );
};
