import styled, { css } from 'styled-components';

import { SwitchProps } from '.';
import { colors, fonts, transition } from '../../styles/variables';
import { crossBrowserDisableSelection, crossBrowserTransition, ptr } from '../../styles/utils';

const Wrapper = styled.div<SwitchProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${crossBrowserDisableSelection()}

 
  ${(props) => props.size&& css`
  ${props.size === 'regular' && `  
  display: flex;
  flex: 1;
   justify-content: space-between;
  `}
  ${props.size === 'small' && `
  flex: 0;
  `}
`};

  ${(props) => props.disabled && css`
    cursor: default;
  `};
`;

const SwitchWrapper = styled.div<SwitchProps>`
 



  box-sizing: border-box;

  ${(props) => props.size&& css`
  ${props.size === 'regular' && ` 
  padding: ${ptr(2)};
     border-radius: ${ptr(18)};
  width: ${ptr(64)};
  min-width: ${ptr(64)};
    height: ${ptr(36)};
  `}
  ${props.size === 'small' && `
  padding: ${ptr(3)};
  display:flex;
  align-items:center;
  border-radius: ${ptr(24)};
  width: ${ptr(40)};
  min-width: ${ptr(40)};
    height: ${ptr(22)};
  `}
`};

    ${(props) => props.theme && css`
      ${props.theme === 'dark1' && `background-color: ${colors.black._40};`}
      ${props.theme === 'dark2' && `background-color: ${colors.black._10};`}
    `};
  
  ${(props) => props.checked && css`
    ${props.theme === 'dark1' && `background-color: ${colors.pink._100};`}
    ${props.theme === 'dark2' && `background-color: ${colors.black._100};`}
  `}

  ${(props) => props.disabled && css`
    ${!props.checked && `background-color: ${colors.pink._20}`}
    ${props.checked && `background-color: ${colors.black._10}`}
  `}
`;

const Circle = styled.div<SwitchProps>`
 
  border-radius: ${ptr(18)};
  background-color: ${colors.white._100};

  ${crossBrowserTransition(`${transition.slow}`)}
  

  ${(props) => props.checked && css`
    margin-left: calc(${ptr(32)} - ${ptr(4)});
  `}

  ${(props) => props.checked && props.size === 'small'&& css`
  margin-left: calc(${ptr(20)} - ${ptr(4)});
`}

  ${(props) => props.size&& css`
  ${props.size === 'regular' && `  
  width: ${ptr(32)};
  height: ${ptr(32)};
  `}
  ${props.size === 'small' && `
  width: ${ptr(15)};
  height: ${ptr(15)};
  `}
`};
`;

const Text = styled.div`
  font-family: ${fonts.inter.regular};
  color: ${colors.black._100};
  line-height: 140%;
  box-sizing: border-box;
  padding-right: ${ptr(32)};
`;

export {
  Wrapper,
  SwitchWrapper,
  Circle,
  Text,
};
