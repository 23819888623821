import React, { useState, useEffect } from 'react';

import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
import { Link } from '../Link';

interface LinkProperty {
  title: string;
  to: string;
}

export interface CheckBoxProps {
  /**
   * Is check box disabled
   */
  disabled?: boolean;
  /**
   * Is checked
   */
  checked?: boolean;
  /**
   * Text right of check box
   */
  text?: string;
  /**
   * Dark or Light theme
   */
  theme?: 'dark' | 'light';
  /**
   * Link after text
   */
  height?: number;
  link?: LinkProperty;
  /**
   * On change event
   */
  onChange?: (isChecked: boolean) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  theme = 'dark',
  ...props
}) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onChange = () => {
    if (!props.disabled) {
      setChecked(!checked);
      if (props.onChange) {
        props.onChange(!checked);
      }
    }
  };

  return (
    <S.Wrapper disabled={props.disabled} onClick={onChange}>
      <S.BoxWrapper theme={theme} checked={checked} disabled={props.disabled}>
        {checked && <Icon name='check-mark' color={colors.white._100} />}
      </S.BoxWrapper>
      {props.text && (
        <S.Text disabled={props.disabled} theme={theme}>
          {props.text}
          {props.link && (
            <>
              {' '}
              <Link
                text={props.link.title}
                to={props.link.to}
                target='__blank'
              />
            </>
          )}
        </S.Text>
      )}
    </S.Wrapper>
  );
};
