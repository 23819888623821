import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { CheckBox } from '../../components/CheckBox';

import * as S from './styles';
import { failed, success } from '../../components/Toasts';
import authService from '../../services/internal/auth/auth.service';
import { colors } from '../../styles/variables';
import { ModalWrapper } from '../../components/ModalWrapper';
import { Modal } from '../../components/LoadingModal';
import onboardingService from '../../services/internal/onboarding/onboarding.service';
import Terms from '../../assets/documents/VBiz Terms of Use & Privacy Policy.pdf';
import Indemnity from '../../assets/documents/Indemnity.pdf';
import { Table } from '../../components/Table';
import { Icon } from '../../components/Icon';
import { CheckAreaGroup } from '../../components/CheckAreaGroup';
import { useDropzone } from 'react-dropzone';
import { Signature } from './Signature';
import Upload from './Upload';
import { SuccessSection } from '../Onboarding/components/SuccessSection';
import { Logo } from '../../components/Logo';
import { dateTimeMonthString } from '../../utils/datetime';
import { DataURIToBlob } from '../../utils/collections';
import VerifyDirectorBvn from './verifyDirectorBvn';
import { formatCash } from '../../utils/money';
import { getPreference } from '../../redux/preference/actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const TEST_DATA = [
  {
    name: 'Operator 1',
    role: 'Authorizer',
    limit: 'N1,000,000',
  },
  {
    name: 'Operator 2',
    role: 'Initaitor',
    limit: '-',
  },
  {
    name: 'Operator 3',
    role: 'Initaitor',
    limit: '-',
  },
];

export interface ViewComponentProps {
  Drag: any;
  Screen: any;
}

export const ConsentForm: React.FC = () => {
  const { search } = useLocation();
  const route = useHistory();
  const token = new URLSearchParams(search)?.get('token') ?? '';
  const reference = new URLSearchParams(search)?.get('reference') ?? '';
  const email = new URLSearchParams(search)?.get('email') ?? '';
  const signatureRef = useRef<any>(null);

  const preference = useSelector((store: RootState) => store.preference);

  const [verifying, setVerifying] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [bvnData, setBvnData] = useState<string>('');
  const [bvnInitiated, setBvnInitiated] = useState(false);

  const { state } = useLocation<{ tab: string }>();
  const [CurrentUserView, ChangeuserView] = useState<string>('Drag');
  const ChangeUserDisplay = (userView: string) => {
    ChangeuserView(userView);
  };
  const UserViews: ViewComponentProps = {
    Drag: <Signature onChangeFile={handleFileChange} />,
    Screen: (
      <Upload clear={clearSignature} save={saveSignature} ref={signatureRef} />
    ),
  };
  const CurrentRenderView = CurrentUserView as keyof typeof UserViews;
  useEffect(() => {
    const view = state?.tab && state?.tab === 'UserReport' ? 'Drag' : 'Drag';
    ChangeuserView(view);
  }, []);
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedb, setIsCheckedb] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccept] = useState(false);
  const [file, setFile] = useState<File | Blob | null>(null);

  const pagetimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (pagetimer.current) {
        clearTimeout(pagetimer.current);
      }
    };
  }, []);

  function clearSignature() {
    signatureRef.current.clear();
    setFile(null);
  }

  function saveSignature() {
    if (signatureRef.current) {
      if (signatureRef.current.isEmpty()) {
        failed('Failed', 'Sign your signature');

        return;
      }
      const signatureValue = signatureRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      setFile(DataURIToBlob(signatureValue));

      success('Success', 'Signature saved');
    }
  }

  function handleFileChange(value: any) {
    setFile(value);
  }

  const verifyHandle = async () => {
    try {
      const response = await onboardingService.verifyConsent({
        email,
        token,
        reference,
      });
      setData(response.data);
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'No record found');
      route.push('/');
    }
    setVerifying(false);
  };

  useEffect(() => {
    verifyHandle();

    return () => setData(null);
  }, []);

  const approveConsent = async (): Promise<void> => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append('file', file as File);
      formData.append('approve', 'true');
      formData.append('email', email);
      formData.append('token', token);
      formData.append('reference', reference);
      formData.append('bvn', bvnData);
      await onboardingService.approveConsent(formData);
      success('Success', 'Your response has been recorded');
      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'failed');
    }
    setLoading(false);
  };

  const declineConsent = async (): Promise<void> => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('approve', 'true');
      formData.append('email', email);
      formData.append('token', token);
      formData.append('reference', reference);
      await onboardingService.approveConsent(formData);
      success('Success', 'Your response has been recorded');
      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (error) {
      const err = error as any;
      failed('Failed', err.message || 'failed');
    }
    setLoading(false);
  };

  if (
    !bvnInitiated &&
    preference?.bvnConsentEnabled &&
    data?.requireBvnConsent
  ) {
    return (
      <VerifyDirectorBvn
        setBvnInitiated={setBvnInitiated}
        email={email}
        token={token}
        reference={reference}
        setBvnData={setBvnData}
        data={data}
      />
    );
  }

  return (
    <S.MainWrapper>
      <S.LogoWrapper>
        <Logo />
      </S.LogoWrapper>

      <S.MainWrapperInner>
        <S.Image />
        <S.Container>
          <S.TopText>
            <S.TopTextLhs>
              <S.Img alt='logo' src='/images/logo/logo.svg' />
            </S.TopTextLhs>

            <S.TopTextRhs>
              <Text bold size={7} color='#000000'>
                Date :
              </Text>

              <Text size={7} color='#000000'>
                {dateTimeMonthString()}
              </Text>
              <div />
            </S.TopTextRhs>
          </S.TopText>

          <S.ConsentTexts>
            <Text bold size={8} color='#000000'>
              Hello {data?.leader?.name || 'Board member'},
            </Text>

            <Text bold size={6} color='#000000'>
              Consent to onboard account on VBiz.
            </Text>

            <Text size={7} color='#000000'>
              This is to formally request for your consent to proceed with
              onboarding your company on VBiz
            </Text>

            <Text
              style={{ marginTop: '10px', lineHeight: '20px' }}
              size={7}
              color='#000000'
            >
              The purpose of onboarding {data?.leader?.businessName || ''} (
              {data?.leader?.accountNumber ?? ''}) on VBiz is to provide you
              with convenient and secure access to a wide range of banking
              services and transactions. VBiz offers smart features and
              functionalities that will streamline your financial management
              processes and enhance the overall efficiency of your company's
              banking operations.
            </Text>

            <Text style={{ marginTop: '10px' }} size={7} color='#000000'>
              By providing your consent, you agree to the following:
            </Text>
            <Text style={{ marginTop: '-4px' }} bold size={7} color='#000000'>
              Workflow on account:{' '}
              {data && (
                <>
                  {' '}
                  {data?.workflow ? 'Multiple Operators' : 'Single Operator'}
                  {data?.workflow ? `(${data?.workflow || ''})` : ''}
                </>
              )}{' '}
            </Text>
          </S.ConsentTexts>

          <S.OperatorDetails>
            <Text bold size={7} color='#000000'>
              Operator details
            </Text>

            {data?.operators?.length && (
              <Table
                headerStyle={{
                  paddingTop: '0px',
                  padding: '0px',
                  color: '#667085',
                  fontWeight: 500,
                  fontSize: 12,
                }}
                header={{
                  cells: {
                    0: {
                      align: 'left',
                      children: <Text size={7}>Operator name</Text>,
                      padding: '0rem 4rem 0rem 1.5rem',
                    },
                    1: {
                      align: 'left',
                      children: <Text size={7}>Role</Text>,
                      padding: '0rem 4rem 0rem 0rem',
                    },
                    2: {
                      align: 'left',
                      children: <Text size={7}>Approval limit</Text>,
                      padding: '0.3rem 1rem 0.3rem 0rem',
                    },
                  },
                }}
                rows={data.operators.map((operator: any, index: number) => ({
                  padding: '1.25rem 0',
                  border: {
                    color: colors.black._10,
                    width: 1,
                    style: 'solid',
                    sides: ['top'],
                  },
                  cells: {
                    0: {
                      align: 'left',

                      children: (
                        <Text bold style={{ width: '100%' }} size={7}>
                          {`${operator.firstName} ${operator.lastName}`}
                        </Text>
                      ),
                      padding: '0.3rem 2rem 0.3rem 1.5rem',
                    },
                    1: {
                      align: 'left',

                      children: (
                        <Text bold size={7}>
                          {operator.role}
                        </Text>
                      ),
                      padding: '0rem 4rem 0rem 0rem',
                    },
                    2: {
                      align: 'left',
                      children: (
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            cursor: 'pointer',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Text bold size={7}>
                            ₦ {formatCash(operator.approvalLimit || 0)}
                          </Text>
                        </div>
                      ),
                      padding: '0.3rem 1rem 0.3rem 0rem',
                    },
                  },
                }))}
              />
            )}

            <S.Info>
              <Icon color='#ED405C' name='info' />
              <Text size={7} color='#ED405C'>
                Please review this consent letter carefully and indicate your
                agreement by signing below.  
              </Text>
            </S.Info>
          </S.OperatorDetails>

          <S.Upload>
            <S.UploadText>
              <Text
                style={{ marginBottom: '14px' }}
                bold
                size={7}
                color='#000000'
              >
                Choose your preferred signing options
              </Text>
            </S.UploadText>

            <S.TypeTabs>
              <S.TableAlign2>
                <div>
                  <S.Tab1
                    style={{
                      border:
                        CurrentUserView === 'Drag'
                          ? '1px solid #B10180'
                          : '1px solid #EAECF0',
                    }}
                    onClick={() => {
                      ChangeUserDisplay('Drag');
                      setIsChecked(true);
                      setIsCheckedb(false);
                    }}
                  >
                    <S.Circle>{isChecked && <S.InsideCircle />}</S.Circle>
                    <Text
                      tag='span'
                      size={7}
                      background={
                        CurrentUserView === 'Drag' ? 'unset' : 'unset'
                      }
                      color={CurrentUserView === 'Drag' ? '#394455' : '#394455'}
                    >
                      Upload signature or drag & drop
                    </Text>
                  </S.Tab1>
                </div>
                <div>
                  <S.Tab1
                    style={{
                      border:
                        CurrentUserView === 'Drag'
                          ? '1px solid #EAECF0'
                          : '1px solid #B10180',
                    }}
                    onClick={() => {
                      ChangeUserDisplay('Screen');
                      setIsCheckedb(true);
                      setIsChecked(false);
                    }}
                  >
                    <S.Circle>{isCheckedb && <S.InsideCircle />}</S.Circle>
                    <Text
                      tag='span'
                      size={7}
                      background={
                        CurrentUserView === 'Screen' ? 'unset' : 'unset'
                      }
                      color={
                        CurrentUserView === 'Screen' ? '#394455' : '#394455'
                      }
                    >
                      Sign on the screen
                    </Text>
                  </S.Tab1>
                </div>
              </S.TableAlign2>
            </S.TypeTabs>
            <S.SigningOptionsDiv hideBorder={isCheckedb ? true : false}>
              {UserViews[CurrentRenderView]}
            </S.SigningOptionsDiv>

            {/* <CheckAreaGroup
            //   onChange={setRole}
            dimension='12px'
            textStyle={{ fontSize: 14 }}
            items={[
              { value: 'Upload signature or drag & drop' },
              { value: 'Sign on the screen' },
            ]}
          /> */}

            <S.TermsText>
              <CheckBox
                //   height={1}

                onChange={(isCheckedd) => {
                  setChecked(isCheckedd);
                  if (!isCheckedd) {
                    setChecked(false);
                  }
                }}
              >
                aa
              </CheckBox>

              <Text size={7} color='#394455'>
                By checking this box, you attest to have read our{' '}
                <S.TermsLink
                  href={Indemnity}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  indemnity,
                </S.TermsLink>{' '}
                <S.TermsLink
                  href={Terms}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy and Terms of Use,
                </S.TermsLink>{' '}
                understood its contents, and consent to the information
                provided.
              </Text>
            </S.TermsText>
          </S.Upload>

          <ModalWrapper
            showModal={declined}
            maxWidth='100%'
            // onCloseModal={!creationLoading ? onCloseModal : undefined}
            crossColor={colors.black._100}
            overflowY='auto'
            overflowX='hidden'
          >
            <S.ModalInner
              style={{
                width: '681px',
                height: '390px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'space-around',
              }}
            >
              <Text
                style={{ marginBottom: '-26px' }}
                size={6}
                bold
                color='#394455'
              >
                We’ll like to know why you declined
              </Text>
              <div style={{ marginTop: '16px' }}>
                <textarea
                  style={{ padding: '8px' }}
                  placeholder='Write your reason here'
                  rows={9.5}
                  cols={92}
                />
              </div>

              <S.ButtonGroupA>
                <div>
                  <Button
                    label='Submit'
                    style={{ width: 277, height: 42 }}
                    //   onClick={continueHandle}
                    onClick={declineConsent}
                    loading={loading}
                    // disabled={!checked || loading}
                  />
                </div>
              </S.ButtonGroupA>
            </S.ModalInner>
          </ModalWrapper>

          <ModalWrapper
            showModal={accepted}
            maxWidth='100%'
            // onCloseModal={!creationLoading ? onCloseModal : undefined}
            crossColor={colors.black._100}
            overflowY='auto'
            overflowX='hidden'
          >
            <S.ModalInner
              style={{
                width: '681px',
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  style={{
                    marginBottom: '30px',
                    paddingBottom: '30px',
                    textAlign: 'center',
                    borderBottom: '1px dotted rgba(110, 113, 145, 0.42)',
                  }}
                >
                  <S.CheckCircle>
                    <S.Img1 src='/images/onboarding/success.png' alt='..' />
                  </S.CheckCircle>
                  <Text size={4} bold color={'#394455'}>
                    We have received your response.
                  </Text>
                </div>
              </div>
            </S.ModalInner>
          </ModalWrapper>
          {/* <SuccessSection
          title='We have received your response.'
          // onClick={gotItHandler}
        /> */}

          <S.ButtonGroup>
            <div>
              <Button
                label='Decline'
                size='tiny'
                theme='light'
                style={{ width: 277, color: '#394455' }}
                // onClick={onClose}
                onClick={() => {
                  // approveConsent(false);
                  declineConsent();
                }}
                loading={loading}
              />
            </div>
            <div>
              <Button
                label='Accept'
                style={{ width: 277 }}
                onClick={approveConsent}
                // onClick={() => {
                //   // approveConsent(true);
                //   setAccept(true);
                // }}
                loading={loading}
                disabled={!checked || loading || !file}
              />
            </div>
          </S.ButtonGroup>
        </S.Container>

        {/*  */}
        {/* <S.WrapperP1>
        <S.HeaderWrapperP1>
          <S.MarginP1>
            <Text size={3} color='#fff' bold>
              VBiz CONSENT FORM
            </Text>
          </S.MarginP1>
        </S.HeaderWrapperP1>
        <S.HeaderWrapperBottomP1>
          <Text color={colors.pink._100} bold>
            Before you accept our Terms of Use, please confirm the following
            information on account.
          </Text>
          <S.SectionsWrapper>
            <Text>
              <Text tag='span' bold>
                Preferred workflow :
              </Text>{' '}
              {data && (
                <>
                  {' '}
                  {data?.workflow ? 'Multiple Operators' : 'Single Operator'}
                </>
              )}
            </Text>
          </S.SectionsWrapper>
          <S.SectionsWrapper>
            <div>
              <Text bold>Operators’ details</Text>
            </div>
          </S.SectionsWrapper>
          <S.OperatorsConatiner>
            {data &&
              data.operators &&
              data.operators.map((operator: any, index: number) => (
                <S.OperatorWrapper key={index}>
                  <div>
                    <Text>
                      Name : {operator?.firstName ?? ''}{' '}
                      {operator?.lastName ?? ''}
                    </Text>
                  </div>
                  <div>
                    <Text>Role : {operator?.role ?? ''}</Text>
                  </div>
                  {operator.approvalLimit ? (
                    <div>
                      <Text>
                        Approval Limit : &#8358; {operator.approvalLimit}
                      </Text>
                    </div>
                  ) : null}
                </S.OperatorWrapper>
              ))}
          </S.OperatorsConatiner>

          <S.SectionsWrapper>
            <S.TermsText>
              <CheckBox
                onChange={(isCheckedd) => {
                  setChecked(isCheckedd);
                  if (!isCheckedd) {
                    setChecked(false);
                  }
                }}
              >
                aa
              </CheckBox>
              <Text>
                I have read and I understand the information provided in the
                Bank's{' '}
                <S.TermsLink
                  href={Terms}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of Use &amp; Privacy Policy.pdf
                </S.TermsLink>
              </Text>
            </S.TermsText>
          </S.SectionsWrapper>

          <S.DisplayP1>
            <Button
              onClick={() => {
                approveConsent(true);
              }}
              loading={loading}
              disabled={!checked || loading}
              label='Accept'
              size='tiny'
            />
            <Button
              onClick={() => {
                approveConsent(false);
              }}
              loading={loading}
              label='Decline'
              size='tiny'
              theme='info'
            />
          </S.DisplayP1>
        </S.HeaderWrapperBottomP1>
      </S.WrapperP1> */}
      </S.MainWrapperInner>
    </S.MainWrapper>
  );
};
