import React, { useState } from 'react';

import * as S from './styles';
import { CheckBoxProps, RoundCheckBox } from '../RoundCheckBox';

export interface RoundCheckBoxGroupProps {
  /**
   * CheckArea items
   */
  items: Array<CheckBoxProps>,
  /**
   * On change event
   */
  onChange?: (value: boolean, name?: string) => void,
}

export const RoundCheckBoxGroup: React.FC<RoundCheckBoxGroupProps> = ({ items, ...props }) => {
  const [chosen, setChosen] = useState<number | null>(null);

  return (
    <S.Wrapper>
      {items.map(((checkBoxItem, index) => (
        <S.CheckboxWrapper>
          <RoundCheckBox
            {...checkBoxItem}
            checked={chosen === index}
            onChange={(isChecked) => {
              if (props.onChange) {
                props.onChange(isChecked, checkBoxItem.text);
              }
              setChosen(index);
            }}
          />
        </S.CheckboxWrapper>

      )))}
    </S.Wrapper>
  );
};
