import React, { useState, useEffect, CSSProperties } from 'react';

import * as S from './styles';

export interface CheckAreaProps {
  /**
   * Is this the principal call to action on the page?
   */
  checked?: boolean;
  /**
   * Is disabled
   */
  disabled?: boolean;
  /**
   * Text value
   */
  value?: string;
  dimension?: string;
  textStyle?: CSSProperties;
  /**
   * Dark or light theme
   */
  theme?: 'dark' | 'light' | 'no';
  /**
   * Optional click handler
   */
  onClick?: (isChecked: boolean) => void;
  style?: CSSProperties;
}

export const CheckArea: React.FC<CheckAreaProps> = ({
  theme = 'dark',
  checked = false,
  disabled = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <S.Wrapper
      checked={isChecked}
      disabled={disabled}
      style={props.style ? { ...props.style } : {}}
      theme={theme}
      onClick={() => {
        if (!disabled) {
          setIsChecked(!isChecked);
          if (props.onClick) {
            props.onClick(!isChecked);
          }
        }
      }}
    >
      <S.Circle dimension={props.dimension} theme={theme} checked={isChecked}>
        {isChecked && <S.InsideCircle theme={theme} />}
      </S.Circle>
      <S.ValueText style={props.textStyle} theme={theme} checked={isChecked}>
        {props.value}
      </S.ValueText>
    </S.Wrapper>
  );
};
