import styled, { css } from 'styled-components';
import { ptr } from '../../../styles/utils';
import { breakpoints, colors, fonts } from '../../../styles/variables';

const Container = styled.div` 
 

`;

const TopTitle = styled.div`
/* border-bottom: 1px solid #E9EBF1; */
 padding-bottom: ${ptr(24)}; 
`;

const Wrapper = styled.div<any>`
 
`;



const TextInputLine = styled.div`
border-radius: 8px;
display: flex;
align-items: center;
gap: 14px;
justify-content: center;

>div{
  display: flex;
width: 16px;
height: 3px; 
  background: var(--grayscale-placeholder, #A0A3BD);
}
`;

const TextInputOuter = styled.div`
border-radius: 8px;
border: 1px solid #D9DBE9;
// align-self: stretch;
// position:relative;
display:flex
flex-direction: row;
//  padding:12px 16px;
//  height:48px; 
`;

const TextInputB = styled.div`
//  width:10px; 
`;


const ItemRow = styled.div`
margin-top: ${ptr(16)};
margin-bottom: ${ptr(12)};
display: flex;
flex-wrap: wrap;
gap: ${ptr(12)};

@media (max-width: ${breakpoints.sm}px) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
`;

const AccountName = styled.div`
 
`;


const ButtonGroup = styled.div` 
  display:flex;
  gap: ${ptr(10)};
  margin-top: ${ptr(23)};
  justify-content: flex-end;  

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center; 
  }

border-top: 4px solid #DBDFE4;
padding-top:14px;
/* 
margin-top:20px;
> div{
  height:50px;
  display:flex; 
    width:100%; 
    justify-content:right; */

`;



export {
  Container,
  TopTitle,
  Wrapper,
  ItemRow,
  AccountName,
  ButtonGroup,
  TextInputB,
  TextInputLine,
  TextInputOuter,
};
