import apiService from '../api/api.service';
import ErrorApi from '../api/api.error';

// #region Onboarding imports
import ErrorOnboarding from './onboarding.error';
import {
  // step 1.1
  OtpBusinessRequestInterface,
  OtpBusinessResponseInterface,
  // step 1.2
  GuestOtpBusinessRequestInterface,
  GuestOtpBusinessResponseInterface,
  // step 1.3
  ClientOtpBusinessRequestInterface,
  ClientOtpBusinessResponseInterface,
  // step 2
  ValidateOtpBusinessRequestInterface,
  ValidateOtpBusinessResponseInterface,
  // step 3
  AuthBusinessRequestInterface,
  AuthBusinessResponseInterface,
  // step 4
  SubmitBusinessInfoRequestInterface,
  SubmitBusinessInfoResponseInterface,
  // step 5.1
  SubmitBusinessLeaderInfoRequestInterface,
  SubmitBusinessLeaderInfoResponseInterface,
  // step 5.2
  // SubmitBusinessDirectorInfoRequestInterface,
  SubmitBusinessDirectorInfoResponseInterface,
  // step 5.3
  // SubmitBusinessShareholderInfoRequestInterface,
  SubmitBusinessShareholderInfoResponseInterface,
  // step 6
  SubmitBusinessOperatorsInfoRequestInterface,
  SubmitBusinessOperatorsInfoResponseInterface,
  // step 7
  ConfirmRequestInterface,
  ConfirmResponseInterface,
  //Profile
  PersonalProfileRequestInterface,
  PersonalProfileResponseInterface,
  //business type
  SubmitBusinessTypeRequestInterface,
  SubmitBusinessTypeResponseInterface,
  // proprietor details
  ProprietorDetailsTypeRequestInterface,
  ProprietorDetailsTypeResponseInterface,
  //directors details
  DirectorsDetailsTypeRequestInterface,
  //consent
  SubmitConsentRequestInterface,
  //pin
  SubmitPinRequestInterface,
  // approval limit
  ApprovalLimitRequestInterface,
  ApprovalLimitResponseInterface,
  //confirm onboarding
  ConfirmOnboardingRequestInterface,
  ConfirmOnboardingResponseInterface,
  //resend OTP
  ResendOtpRequestInterface,
  //submit auth
  SubmitAuthRequestInterface,
  PartnersDetailsTypeRequestInterface,
  SignatoriesDetailsTypeRequestInterface,
  TrusteesDetailsTypeRequestInterface,
  SubmitSignUpBusinessInfoRequestInterface,
  SignUpDirectorsDetailsTypeRequestInterface,
  SignUpProprietorDetailsTypeRequestInterface,
  ContinueAfterUploadsRequestInterface,
  SubmitReferencesRequestInterface,
  VerifyConsentRequestInterface,
  ApproveConsentRequestInterface,
  ConsentResponseInterface,
  SubmitPinResponseInterface,
  SubmitBusinessOperatorsRequestInterface,
  SetOperatorApproverLimitRequestInterface,
  VerifyReferenceRequestInterface,
  VerifyReferenceResponseInterface,
  SignUpUboDetailsTypeRequestInterface,
  IGetDocLink,
  IGetDocResponse,
  IBvnConsent,
  ValidateBvnConsentResponseInterface,
  ConfirmBvnConsentResponseInterface,
} from './IOnboarding';
// #endregion

import debug from '../debbug.service';
import getQueryString from '../../../utils/getQueryString';

class OnboardingService {
  private apiEndpoints = {
    business: {
      root: 'onboarding/business', // step 1.1
      initSignup: 'onboarding/business/init-signup', // step 1.1
      guest: 'onboarding/business/guest', // step 1.2
      client: 'onboarding/business/client', // step 1.3
      validateOtp: 'onboarding/business/validate-otp', // step 2
      validatePhoneOtp: 'onboarding/business/validate-phone-otp',
      validateEmailOtp: 'onboarding/business/validate-email-otp',
      auth: 'onboarding/business/auth', // step 3
      info: 'onboarding/business/info', // step 4
      leaders: 'onboarding/business/leaders', // step 5.1
      directors: 'onboarding/business/directors', // step 5.2
      shareholders: 'onboarding/business/shareholders', // step 5.3
      operators: 'onboarding/business/operators', // step 6
      verifyOperators: 'onboarding/business/operators-verification',
      confirm: 'onboarding/business/confirm', // step 7
      // business: 'onboarding/business', // in app
      profile: 'onboarding/business/profile',
      businessType: 'onboarding/business/businessType',
      businessInfo: 'onboarding/business/business-info',
      proprietors: 'onboarding/business/proprietors',
      partners: 'onboarding/business/partners',
      trustees: 'onboarding/business/trustees',
      signatories: 'onboarding/business/signatories',
      consent: 'onboarding/business/consent-letter',
      pin: 'onboarding/business/pin',
      limit: 'onboarding/business/approver-limit',
      resendOtp: 'onboarding/business/resend-otp',
      uploads: 'onboarding/business/uploads',
      uploadsContinue: 'onboarding/business/uploads/continue',
      references: 'onboarding/business/references',
      verify: 'onboarding/business/verify-consent',
      approve: 'onboarding/business/approve-consent',
      workflow: 'business/workflow',
      setOperatorLimit: 'business/approver-limit',
      verifyReference: 'onboarding/business/verify-reference/',
      referenceForm: 'onboarding/business/reference-form',
      ubo: 'onboarding/business/ubo',
      getDocLink: 'onboarding/business/uploads/get-vbiz-signed-doc',
      bvnConsent: 'onboarding/business/bvn-consent',
      confirmBvnConsent: 'onboarding/business/confirm-bvn-consent',
    },
  };

  /**
   * A wrapper for POST Form requests for onboarding flow
   * @param data - FormData type
   * @param url
   * @returns F type
   */
  private static async requestMultiPartWrapper<F>(
    data: FormData,
    url: string
  ): Promise<F> {
    try {
      const request = await apiService.request.post(url, {
        body: data,
      });
      return (await request.json()) as F;
    } catch (globalError) {
      debug.error(globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          return Promise.reject(
            new ErrorApi({
              message: result.message,
              code: result.code || result.status || 500,
            })
          );
        }
        return Promise.reject(new ErrorApi(ErrorOnboarding.Fatal));
      } catch (localError) {
        debug.error(localError);
        throw new ErrorApi(ErrorOnboarding.Fatal);
      }
    }
  }

  /**
   * A wrapper for POST requests for onboarding flow
   * @param data - T type
   * @param url
   * @returns F type
   */
  private static async requestPostWrapper<T, F>(
    data: T,
    url: string
  ): Promise<F> {
    try {
      const request = await apiService.request.post(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return (await request.json()) as F;
    } catch (globalError) {
      debug.error(globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          if (result && result.message) {
            throw new ErrorApi({
              message: result.message,
              code: result.code || result.status || 500,
            });
          } else {
            throw new ErrorApi(ErrorOnboarding.Fatal);
          }
        } else {
          throw new ErrorApi(ErrorOnboarding.Fatal);
        }
      } catch (localError) {
        debug.error(localError);
        throw localError;
      }
    }
  }

  /**
   *  A wrapper for GET requests for onboarding flow
   * @param data - T type
   * @param url
   * @returns F type
   */
  private static async requestGetWrapper<T, F>(
    data: T,
    url: string
  ): Promise<F> {
    const queryString = getQueryString(data);
    try {
      const request = await apiService.request.get(url + queryString, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return (await request.json()) as F;
    } catch (globalError) {
      debug.error(globalError);
      try {
        if (globalError.response) {
          const result = await globalError.response.json();
          if (result && result.message) {
            throw new ErrorApi({
              message: result.message,
              code: result.code || result.status || 500,
            });
          } else {
            throw new ErrorApi(ErrorOnboarding.Fatal);
          }
        } else {
          throw new ErrorApi(ErrorOnboarding.Fatal);
        }
      } catch (localError) {
        debug.error(localError);
        throw localError;
      }
    }
  }

  /**
   * **ONBOARDING STEP 1.1**
   *
   * Triger OTP to account number.
   *
   * Client can either call this endpoint to submit
   * business creation request for both guests and
   * existing vbank users.\
   * OR call their dedicated endpoints for same purpose.
   */
  public async sendOtpForBusiness(
    data: OtpBusinessRequestInterface
  ): Promise<OtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      OtpBusinessRequestInterface,
      OtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.root);
  }

  public async initSignup(
    data: Pick<
      OtpBusinessRequestInterface,
      | 'referralCode'
      | 'email'
      | 'phone'
      | 'idNumber'
      | 'idType'
      | 'bvn'
      | 'reference'
    >
  ): Promise<OtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      Pick<
        OtpBusinessRequestInterface,
        | 'referralCode'
        | 'email'
        | 'phone'
        | 'idNumber'
        | 'idType'
        | 'bvn'
        | 'reference'
      >,
      OtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.initSignup);
  }

  /**
   * **ONBOARDING STEP 1.2**
   *
   * Triger OTP to phone linked to BVN for new clients (guests).
   *
   * Client can either call this endpoint to submit
   * business creation request for both guests and
   * existing vbank users.\
   * OR call their dedicated endpoints for same purpose.
   */
  public async sendGuestOtpForBusiness(
    data: GuestOtpBusinessRequestInterface
  ): Promise<GuestOtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      GuestOtpBusinessRequestInterface,
      GuestOtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.guest);
  }

  /**
   * **ONBOARDING STEP 1.3**
   *
   * Triger OTP to existing clients (with account number).
   *
   * Client can either call this endpoint to submit
   * business creation request for both guests and
   * existing vbank users.\
   * OR call their dedicated endpoints for same purpose.
   */
  public async sendClientOtpForBusiness(
    data: ClientOtpBusinessRequestInterface
  ): Promise<ClientOtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ClientOtpBusinessRequestInterface,
      ClientOtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.client);
  }

  /**
   * **ONBOARDING STEP 2**
   *
   * Validate OTP sent in step one.
   */
  public async validateOtpForBusiness(
    data: ValidateOtpBusinessRequestInterface
  ): Promise<ValidateOtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ValidateOtpBusinessRequestInterface,
      ValidateOtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.validateOtp);
  }

  public async validatePhoneOtp(
    data: ValidateOtpBusinessRequestInterface
  ): Promise<GuestOtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ValidateOtpBusinessRequestInterface,
      GuestOtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.validatePhoneOtp);
  }

  public async validateEmailOtp(
    data: ValidateOtpBusinessRequestInterface
  ): Promise<ValidateOtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ValidateOtpBusinessRequestInterface,
      ValidateOtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.validateEmailOtp);
  }

  public async resendOtp(
    data: ResendOtpRequestInterface
  ): Promise<ValidateOtpBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ResendOtpRequestInterface,
      ValidateOtpBusinessResponseInterface
    >(data, this.apiEndpoints.business.resendOtp);
  }

  /**
   * **ONBOARDING STEP 3**
   *
   * Create auth credentials.
   */
  public async createAuthCredsForBusiness(
    data: AuthBusinessRequestInterface
  ): Promise<AuthBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      AuthBusinessRequestInterface,
      AuthBusinessResponseInterface
    >(data, this.apiEndpoints.business.auth);
  }

  /**
   * **ONBOARDING STEP 3**
   *
   * Submit auth
   */
  public async submitAuth(
    data: SubmitAuthRequestInterface
  ): Promise<AuthBusinessResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitAuthRequestInterface,
      AuthBusinessResponseInterface
    >(data, this.apiEndpoints.business.auth);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Consent.
   */

  public async verifyConsent(
    data: VerifyConsentRequestInterface
  ): Promise<ConsentResponseInterface> {
    return OnboardingService.requestGetWrapper<
      VerifyConsentRequestInterface,
      ConsentResponseInterface
    >(data, this.apiEndpoints.business.verify);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Reference
   */

  public async verifyReference(
    data: VerifyReferenceRequestInterface
  ): Promise<VerifyReferenceResponseInterface> {
    return OnboardingService.requestGetWrapper<
      VerifyReferenceRequestInterface,
      VerifyReferenceResponseInterface
    >(data, this.apiEndpoints.business.verifyReference + data?.reference ?? '');
  }

  public async submitReferenceForm(
    data: FormData
  ): Promise<SubmitBusinessTypeResponseInterface> {
    return OnboardingService.requestMultiPartWrapper<
      SubmitBusinessDirectorInfoResponseInterface
    >(data, this.apiEndpoints.business.referenceForm);
  }

  public async approveConsent(
    data: FormData
  ): Promise<ConsentResponseInterface> {
    return OnboardingService.requestMultiPartWrapper<ConsentResponseInterface>(
      data,
      this.apiEndpoints.business.approve
    );
  }

  /**
   * **ONBOARDING STEP **
   *
   * Personal info with credentials.
   */

  public async submitProfileInfo(
    data: PersonalProfileRequestInterface
  ): Promise<PersonalProfileResponseInterface> {
    return OnboardingService.requestPostWrapper<
      PersonalProfileRequestInterface,
      PersonalProfileResponseInterface
    >(data, this.apiEndpoints.business.profile);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit business type.
   */

  public async submitBusinessType(
    data: SubmitBusinessTypeRequestInterface
  ): Promise<SubmitBusinessTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitBusinessTypeRequestInterface,
      SubmitBusinessTypeResponseInterface
    >(data, this.apiEndpoints.business.businessType);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit business type.
   */

  public async submitSignUpBusinessInfo(
    data: SubmitSignUpBusinessInfoRequestInterface
  ): Promise<SubmitBusinessTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitSignUpBusinessInfoRequestInterface,
      SubmitBusinessTypeResponseInterface
    >(data, this.apiEndpoints.business.businessInfo);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit document upload.
   */

  public async uploadDocuments(
    data: FormData
  ): Promise<SubmitBusinessTypeResponseInterface> {
    return OnboardingService.requestMultiPartWrapper<
      SubmitBusinessDirectorInfoResponseInterface
    >(data, this.apiEndpoints.business.uploads);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit document upload.
   */

  public async continueAfterUploads(
    data: ContinueAfterUploadsRequestInterface
  ): Promise<SubmitBusinessTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ContinueAfterUploadsRequestInterface,
      SubmitBusinessTypeResponseInterface
    >(data, this.apiEndpoints.business.uploadsContinue);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit business type.
   */

  public async submitApprovalLimit(
    data: ApprovalLimitRequestInterface
  ): Promise<ApprovalLimitResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ApprovalLimitRequestInterface,
      ApprovalLimitResponseInterface
    >(data, this.apiEndpoints.business.limit);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit References.
   */

  public async submitReferences(
    data: SubmitReferencesRequestInterface
  ): Promise<ApprovalLimitResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitReferencesRequestInterface,
      ApprovalLimitResponseInterface
    >(data, this.apiEndpoints.business.references);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit proprietors details.
   */

  public async submitProprietorsDetails(
    data: ProprietorDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ProprietorDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.proprietors);
  }

  public async submitSignUpProprietorsDetails(
    data: SignUpProprietorDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SignUpProprietorDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.proprietors);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit directors details.
   */

  public async submitDirectorsDetails(
    data: DirectorsDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      DirectorsDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.directors);
  }

  public async submitSignUpDirectorsDetails(
    data: SignUpDirectorsDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SignUpDirectorsDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.directors);
  }

  public async submitUboDetails(
    data: SignUpUboDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SignUpUboDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.ubo);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit directors details.
   */

  public async submitPartnersDetails(
    data: PartnersDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      PartnersDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.partners);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit signatories details.
   */

  public async submitSignatoriesDetails(
    data: SignatoriesDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SignatoriesDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.signatories);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit trustees details.
   */

  public async submitTrusteesDetails(
    data: TrusteesDetailsTypeRequestInterface
  ): Promise<ProprietorDetailsTypeResponseInterface> {
    return OnboardingService.requestPostWrapper<
      TrusteesDetailsTypeRequestInterface,
      ProprietorDetailsTypeResponseInterface
    >(data, this.apiEndpoints.business.trustees);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Submit consent.
   */

  public async submitConsentLetter(
    data: SubmitConsentRequestInterface
  ): Promise<SubmitPinResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitConsentRequestInterface,
      SubmitPinResponseInterface
    >(data, this.apiEndpoints.business.consent);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Create auth credentials.
   */
  public async submitPin(
    data: SubmitPinRequestInterface
  ): Promise<SubmitPinResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitPinRequestInterface,
      SubmitPinResponseInterface
    >(data, this.apiEndpoints.business.pin);
  }

  /**
   * **ONBOARDING STEP **
   *
   * Review and confirm onboarding.
   */
  public async confirmOnboarding(
    data: ConfirmOnboardingRequestInterface
  ): Promise<ConfirmOnboardingResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ConfirmOnboardingRequestInterface,
      ConfirmOnboardingResponseInterface
    >(data, this.apiEndpoints.business.confirm);
  }

  /**
   * **ONBOARDING STEP 4**
   *
   * Submit business information.
   */
  public async submitBusinessInfo(
    data: SubmitBusinessInfoRequestInterface
  ): Promise<SubmitBusinessInfoResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitBusinessInfoRequestInterface,
      SubmitBusinessInfoResponseInterface
    >(data, this.apiEndpoints.business.info);
  }

  /**
   * **ONBOARDING STEP 5.1**
   *
   * Submit leader information.
   *
   * Client can either call this endpoint to submit both
   * leaders and shareholders or call their dedicated
   * endpoints for same purpose.
   */
  public async submitBusinessLeaderInfo(
    data: SubmitBusinessLeaderInfoRequestInterface
  ): Promise<SubmitBusinessLeaderInfoResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitBusinessLeaderInfoRequestInterface,
      SubmitBusinessLeaderInfoResponseInterface
    >(data, this.apiEndpoints.business.leaders);
  }

  /**
   * **ONBOARDING STEP 5.2**
   *
   * Submit director information.
   *
   * Client can either call this endpoint to submit both
   * directors and shareholders or call their dedicated
   * endpoints for same purpose.
   */
  public async submitBusinessDirectorInfo(
    data: FormData
  ): Promise<SubmitBusinessDirectorInfoResponseInterface> {
    return OnboardingService.requestMultiPartWrapper<
      SubmitBusinessDirectorInfoResponseInterface
    >(data, this.apiEndpoints.business.directors);
  }

  /**
   * **ONBOARDING STEP 5.3**
   *
   * Submit shareholder information.
   *
   * Client can either call this endpoint to submit both
   * directors and shareholders or call their dedicated
   * endpoints for same purpose.
   */
  public async submitBusinessShareholderInfo(
    data: FormData
  ): Promise<SubmitBusinessShareholderInfoResponseInterface> {
    return OnboardingService.requestMultiPartWrapper<
      SubmitBusinessShareholderInfoResponseInterface
    >(data, this.apiEndpoints.business.shareholders);
  }

  /**
   * **ONBOARDING STEP 6**
   *
   * Submit business operators information.
   */
  public async submitBusinessOperatorsInfo(
    data: SubmitBusinessOperatorsInfoRequestInterface
  ): Promise<SubmitBusinessOperatorsInfoResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitBusinessOperatorsInfoRequestInterface,
      SubmitBusinessOperatorsInfoResponseInterface
    >(data, this.apiEndpoints.business.operators);
  }

  public async verifyBusinessOperatorsInfo(
    data: SubmitBusinessOperatorsInfoRequestInterface
  ): Promise<SubmitBusinessOperatorsInfoResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitBusinessOperatorsInfoRequestInterface,
      SubmitBusinessOperatorsInfoResponseInterface
    >(data, this.apiEndpoints.business.verifyOperators);
  }
  /**
   * Set Operators After Sign Up
   *
   * @param data
   * @returns
   */

  public async submitBusinessOperators(
    data: SubmitBusinessOperatorsRequestInterface
  ): Promise<SubmitBusinessOperatorsInfoResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SubmitBusinessOperatorsRequestInterface,
      SubmitBusinessOperatorsInfoResponseInterface
    >(data, this.apiEndpoints.business.workflow);
  }

  public async setOperatorApproverLimit(
    data: SetOperatorApproverLimitRequestInterface
  ): Promise<SubmitBusinessOperatorsInfoResponseInterface> {
    return OnboardingService.requestPostWrapper<
      SetOperatorApproverLimitRequestInterface,
      SubmitBusinessOperatorsInfoResponseInterface
    >(data, this.apiEndpoints.business.setOperatorLimit);
  }

  /**
   * **ONBOARDING STEP 7**
   *
   * Review and confirm submitted details.
   */
  public async confirmViewersInfo(
    data: ConfirmRequestInterface
  ): Promise<ConfirmResponseInterface> {
    return OnboardingService.requestPostWrapper<
      ConfirmRequestInterface,
      ConfirmResponseInterface
    >(data, this.apiEndpoints.business.confirm);
  }

  public async getDocLink(data: IGetDocLink): Promise<IGetDocResponse> {
    return OnboardingService.requestPostWrapper<IGetDocLink, IGetDocResponse>(
      data,
      this.apiEndpoints.business.getDocLink
    );
  }

  public async bvnConsent(
    data: IBvnConsent
  ): Promise<ValidateBvnConsentResponseInterface> {
    return OnboardingService.requestPostWrapper<
      IBvnConsent,
      ValidateBvnConsentResponseInterface
    >(data, this.apiEndpoints.business.bvnConsent);
  }

  public async confirmBvnConsent(
    data: IBvnConsent
  ): Promise<ConfirmBvnConsentResponseInterface> {
    return OnboardingService.requestPostWrapper<
      IBvnConsent,
      ConfirmBvnConsentResponseInterface
    >(data, this.apiEndpoints.business.confirmBvnConsent);
  }
}

const onboardingService = new OnboardingService();
export default onboardingService;
