import styled from 'styled-components';

import { ptr } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
 
`;

const TitleWrapper = styled.div``;

const DescriptionWrapper = styled.div`
  margin-top: ${ptr(3)};
`;

export {
  Wrapper,
  TitleWrapper,
  DescriptionWrapper,
};
