import { ActionInterface } from '../IAction';
import { QuickLinkInterface } from './IQuickLinks';
import {
  SET_ACTIVE_LINKS,
} from './actions';
import { MenuItem } from './IQuickLinks'
import { EPermissions } from '../../components/AccessControl';

type ReducerTypes = Array<MenuItem>;

const defaultCategory = [
  {
    label: 'Payments',
    id: 1,
  },
  {
    label: 'Reports',
    id: 2,
  },
  {
    label: 'User management',
    id: 3,
  },
  {
    label: 'Settings',
    id: 4,
  },
]

const storageKey = 'QUICKLINKS'

const savedLinks = localStorage.getItem(storageKey)

const defaultActiveQuicklinks: Array<MenuItem> = savedLinks ? JSON.parse(savedLinks) : [
  {
    icon: 'arrow-thick',
    label: 'Transfers',
    to: '/transfers',
    keywords: [''],
    categoryId: 1,
    isActive: false,
  },
  // { icon: 'wallet', text: 'Pay Bills', to: '/dashboard' },
  // { icon: 'cards', text: 'Cards', to: '/dashboard' },
  // { icon: 'topup', text: 'Top up', to: '/dashboard' },
  {
    icon: 'report',
    label: 'Statements',
    to: '/payments',
    keywords: [''],
    categoryId: 2,
    isActive: false,
  },
];

const allLinks = [
  {
    icon: 'arrow-thick',
    label: 'Transfers',
    to: '/transfers',
    keywords: ['transfer', 'send money', 'pay', 'payment'],
    categoryId: 1,
    isActive: false,
  },
  {
    icon: 'git-branch',
    label: 'Transfer to own account',
    to: '/transfers',
    routeData: { type: 'own' },
    keywords: ['transfer', 'send money', 'pay', 'payment'],
    categoryId: 1,
    isActive: false,
    requiredPermission: [EPermissions.CREATE_PAYMENT]
  },
  {
    icon: 'two-opposite-arrows-rotated',
    label: 'Transfer to other accounts',
    to: '/transfers',
    routeData: { type: 'others' },
    keywords: ['transfer', 'send money', 'pay', 'payment'],
    categoryId: 1,
    isActive: false,
    requiredPermission: [EPermissions.CREATE_PAYMENT]
  },
  // { icon: 'wallet', text: 'Pay Bills', to: '/dashboard' },
  // { icon: 'cards', text: 'Cards', to: '/dashboard' },
  // { icon: 'topup', text: 'Top up', to: '/dashboard' },
  {
    icon: 'report',
    label: 'Statements',
    to: '/payments',
    keywords: ['statement', 'account', 'report', 'payments'],
    routeData: { openStatement: true },
    categoryId: 2,
    isActive: false,
  },
  {
    icon: 'analytics',
    label: 'Analytics',
    to: '/payments',
    keywords: ['analytics', 'report', 'payments'],
    categoryId: 2,
    isActive: false,
  },
  {
    icon: 'transaction-report',
    label: 'Transaction report',
    to: '/payments',
    keywords: ['report', 'transactions', 'account', 'payments', 'bulk transaction'],
    routeData: { tab: 'UserReport' },
    categoryId: 2,
    isActive: false,
  },
  // {
  //   icon: 'report',
  //   label: 'Transaction details',
  //   to: '/payments/all/details',
  //   keywords: [''],
  //   categoryId: 2,
  //   isActive: false,
  // },
  {
    icon: 'users',
    label: 'Users',
    to: '/users',
    keywords: ['users'],
    categoryId: 3,
    isActive: false,
  },
  {
    icon: 'add',
    label: 'Create user',
    to: '/users',
    routeData: { action: 'create' },
    keywords: ['users', 'create users', 'operators', 'create operators'],
    categoryId: 3,
    isActive: false,
    requiredPermission: [EPermissions.CREATE_OPERATOR]
  },
  // {
  //   icon: 'copy',
  //   label: 'Add business',
  //   to: '/onboarding/add-business',
  //   keywords: [''],
  //   categoryId: 4,
  //   isActive: false,
  // },
  {
    icon: 'pin',
    label: 'Change PIN',
    to: '/settings',
    routeData: { type: 'pin' },
    keywords: ['security', 'pin', 'settings', 'change pin'],
    categoryId: 4,
    isActive: false,
  },
  {
    icon: 'password',
    label: 'Change password',
    to: '/settings',
    routeData: { type: 'password' },
    keywords: ['security', 'password', 'change password', 'settings'],
    categoryId: 4,
    isActive: false,
  },
]


const initialState: QuickLinkInterface = {
  allLinks,
  activeQuickLinks: defaultActiveQuicklinks,
  quickLinkCategories: defaultCategory,
  maximumQuickLinks: 5
};



const reducer = (state = initialState, action: ActionInterface<ReducerTypes>): QuickLinkInterface => {
  switch (action.type) {
    case SET_ACTIVE_LINKS:
      if (action.payload) localStorage.setItem(storageKey, JSON.stringify(action.payload))

      return {
        ...state,
        activeQuickLinks: action.payload as Array<MenuItem>,
      };


    default:
      return state;
  }
};

export default reducer;
