import styled from 'styled-components';

import { ptr } from '../../../styles/utils';
import { breakpoints } from '../../../styles/variables';

const Container = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

const Body = styled.div`
  margin-top: ${ptr(30)};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BackBarContainer = styled.div`
  border-bottom: solid 1px #d9dbe9;
  padding-bottom: 15px;
`;

const TextContainer = styled.div`
  margin-top: 30px;
`;

export { Container, Body, BackBarContainer, TextContainer };
