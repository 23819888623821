import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Text } from '../../../components/Text';
import * as S from '../styles';
import SignaturePad from 'react-signature-canvas';
import './styles.css';
import { Button } from '../../../components/Button';

const Upload: React.ForwardRefRenderFunction<
  any,
  { clear: () => void; save: () => void }
> = ({ clear, save }, ref) => {
  // const sigCanvas = useRef<any>(null);

  const clearValue = () => {
    if (clear) clear();
  };
  const saveValue = () => {
    if (save) save();
  };
  return (
    <S.DrawDiv>
      <div>
        {/* <Text bold size={8} color='#A0A3BD'>
          Draw signature here
        </Text> */}
        <SignaturePad
          ref={ref}
          canvasProps={{ className: 'signatureCanvas' }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Button label='Clear' theme='light' onClick={clearValue} />
          <Button style={{ height: 35 }} label='Save' onClick={saveValue} />
        </div>
      </div>
    </S.DrawDiv>
  );
};

export default forwardRef(Upload);
