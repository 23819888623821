import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { LogInForm } from './components/LogInForm';
import { WelcomeSlider } from '../../components/WelcomeSlider';
import { TwoSide, Columns } from '../../styles/wrappers';
import { RoundButton } from '../../components/RoundButton';
import { Text } from '../../components/Text';
import { Link } from '../../components/Link';
import { colors } from '../../styles/variables';
import { ptr } from '../../styles/utils';
import { SignUpButton } from '../../components/SignupButton';
import { HeaderMenu } from '../../components/HeaderMenu';
import { useDispatch } from 'react-redux';
import { OnboardingActions } from '../../redux/onboarding/actions';

const LogIn: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onBack = () => {
    history.push('');
  };

  useEffect(() => {
    dispatch(OnboardingActions.resetOnboardingState());
    sessionStorage.removeItem('onboardingState');
    sessionStorage.removeItem('onboardingTracker');

  }, []);

  return (
    <>
      <HeaderMenu fixed={false} />
      <TwoSide.Wrapper>
        <TwoSide.Left>
          <WelcomeSlider />
        </TwoSide.Left>
        <TwoSide.Right>
          <Columns.Wrapper>
            <Columns.ContentWrapper padding={`${ptr(10)} 0`}>
              {/* <RoundButton icon='arrow-left' onClick={onBack} /> */}
              <SignUpButton />
            </Columns.ContentWrapper>
            <Columns.ContentWrapper>
              <LogInForm />
            </Columns.ContentWrapper>
            <Columns.ContentWrapper />
            <S.Onboarding>
              <Text color='#6E7191'>
                © {new Date().getFullYear()} VFD MFB, all rights reserved.
              </Text>
            </S.Onboarding>
          </Columns.Wrapper>
        </TwoSide.Right>
      </TwoSide.Wrapper>
    </>
  );
};

export default LogIn;
