/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';
import * as S from './styles';
import { Icon } from '../Icon';
import { colors } from '../../styles/variables';
import { Text } from '../Text';
import { AuthorizationActions } from '../../redux/authorization/actions';
import { Modal } from '../SimpleModal';
import defaultAvata from './assets/Images/defaultAvata.png';
import authService from '../../services/internal/auth/auth.service';
import { failed } from '../Toasts';
import { Button } from '../../components/Button';
import { ApiService } from '../../services/internal/api/api.service';
import { CompanyInterface } from '../../redux/company/ICompany';
import Overlay from '../Overlay';
import onboardingService from '../../services/internal/onboarding/onboarding.service';
import * as Storage from '../../utils/storage';
import { ILimitRequestResponse } from '../../services/internal/payments/IPayments';
import paymentsService from '../../services/internal/payments/payments.service';
import LimitApprovedModal from '../LimitModals/limitApprovedModal';

export interface ProfileProps {
  open?: boolean;
  onDropdown?: () => void;
}

export const MiniProfile: React.FC<ProfileProps> = ({
  open = false,
  onDropdown = () => {},
}) => {
  const dispatch = useDispatch();
  const route = useHistory();
  const location = useLocation();
  const UserProfile = useSelector(
    (state: { currentUser: any }) => state.currentUser.data
  );

  const activeCompany = useSelector(
    (state: { company: CompanyInterface }) => state.company.active
  );

  const [showModal, setshowModal] = React.useState(false);
  const [showPopModal, setshowPopModal] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [profilePicture, setProfilePicture] = useState('');
  const [showLimitRequestSuccess, setShowLimitRequestSuccess] = useState(false);
  const [limitAmount, setLimitAmount] = useState<number>();
  // const [idle, setIdle] = React.useState(false);

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const onLogOut = () => {
    dispatch(AuthorizationActions.logOut());
    if (location.pathname.includes('onboarding')) {
      route.push('/');
    } else {
      timeout.current = setTimeout(() => {
        route.push('/');
      }, 1000);
    }
  };

  useEffect(() => {
    if (UserProfile?.profile?.avatarUrl) {
      getProfile();
    }
  }, [UserProfile]);

  const getProfile = async () => {
    try {
      const result = await onboardingService.getDocLink({
        key: UserProfile?.profile?.avatarUrl,
      });

      setProfilePicture(result?.url);
    } catch (error) {
      const err = error as any;
      return false;
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const decoded: any = jwt_decode(authService.getAccessToken() as string);
      const countDownDate = new Date(decoded.exp * 1000).getTime();
      const now = new Date().getTime();
      const diff = countDownDate - now;
      const minutes = Math.floor(diff / (1000 * 60));
      // const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (minutes === 5 && (!ApiService.refreshingToken || !refreshing)) {
        setshowPopModal(true);
      }
      if (minutes === 0) {
        setshowPopModal(false);
        clearInterval(timer);
        failed('Failed', 'Your session has expired, please sign in again');
        setTimeout(() => {
          onLogOut();
        }, 1000);
      }
    }, 60000);

    return () => {
      clearInterval(timer);
      setshowPopModal(false);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
  let savedLimitRequest = Storage.getItem(
    Storage.storageKey.DAILY_LIMIT_REQUEST
  );
  useEffect(() => {
    if (savedLimitRequest) {
      savedLimitRequest = JSON.parse(savedLimitRequest as string);
      getLimitRequestByReference(
        (savedLimitRequest as ILimitRequestResponse).reference
      );
    }
  }, []);

  const toggleLimitRequestSuccess = () =>
    setShowLimitRequestSuccess((prev) => !prev);

  const getLimitRequestByReference = async (reference: string) => {
    try {
      const response = await paymentsService.getLimitRequestByReference(
        reference
      );
      if (
        response &&
        response.status === 'approved' &&
        (savedLimitRequest as ILimitRequestResponse).status === 'pending'
      ) {
        setLimitAmount(
          (savedLimitRequest as ILimitRequestResponse).newDailyTransactionLimit
        );
        toggleLimitRequestSuccess();
        Storage.removeItem(Storage.storageKey.DAILY_LIMIT_REQUEST);
      }
    } catch (error) {
      // silence the error as it is not needed
    }
  };

  const idleAction = useCallback(() => {
    setshowPopModal(false);
    onLogOut();
    failed(
      'Failed',
      'Your session has been inactive for a while, please sign in again'
    );
  }, []);

  const handleOnIdle = () => {
    idleAction();
  };

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_INACTIVE_TIMEOUT),
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const hideModal = () => {
    setshowModal(false);
  };

  const hidePopModal = () => {
    setshowPopModal(false);
  };
  const action = () => {
    hideModal();
    onLogOut();
  };

  const popAction = async () => {
    setRefreshing(true);
    try {
      await authService.refreshToken();
      setRefreshing(false);
      hidePopModal();
    } catch (error) {
      setRefreshing(false);
      const err = error as any;
      failed(
        'Failed',
        err?.message || 'Unable to refresh session at the moment'
      );
      if (err?.code === 'INVALID_TOKEN') onLogOut();
    }
  };

  const [drop, setDrop] = useState(false);

  const dropdownA = (): void => {
    setDrop(!drop);
  };

  return (
    <S.Wrapper>
      <S.PhotoWrapper>
        {UserProfile && (
          <S.Photo src={profilePicture ? profilePicture : defaultAvata} />
        )}
      </S.PhotoWrapper>

      <S.IconName>
        <Text bold color={colors.black._100} size={5}>
          {activeCompany?.name}
        </Text>

        <Text
          color="#1F8CEB"
          size={6}
          style={{
            textOverflow: 'ellipsis',
            width: '200px',
            overflowX: 'hidden',
          }}
        >
          {UserProfile?.email}
        </Text>
      </S.IconName>

      <S.Company>
        <S.DropdownWrapper onClick={dropdownA}>
          <Icon color="#394455" name="dropdown" />
        </S.DropdownWrapper>

        {drop ? (
          <S.DropownContent>
            <S.DropWrapper>
              <S.PhotoWrapper2>
                {UserProfile && (
                  <S.Photo
                    src={profilePicture ? profilePicture : defaultAvata}
                  />
                )}
              </S.PhotoWrapper2>

              <S.IconName>
                <Text bold color={colors.black._100} size={5}>
                  {activeCompany?.name}
                </Text>

                <Text
                  color="#1F8CEB"
                  size={6}
                  style={{
                    textOverflow: 'ellipsis',
                    width: '250px',
                    overflowX: 'hidden',
                  }}
                >
                  {UserProfile?.email}
                </Text>
              </S.IconName>
            </S.DropWrapper>

            <S.ButtonWrapper>
              {/* <Button
                style={{
                  borderRadius: 30,
                  padding: 12,
                  background: '#F1F1F5',
                  display: 'flex',
                  border: '1px solid #C8D2DF',
                  gap: '12px',
                  width: '100%',
                }}
                disabled
                label='View profile'
                color='#7F91A8'
                size='tiny'
              /> */}
            </S.ButtonWrapper>

            <S.LogOutWrapper onClick={() => setshowModal(true)}>
              <Icon name="sign-out" color={colors.black._100} />
              <Text color={colors.black._100} size={7}>
                Log out
              </Text>
            </S.LogOutWrapper>
          </S.DropownContent>
        ) : (
          ''
        )}

        {drop && <Overlay handleClick={dropdownA} />}
      </S.Company>

      <Modal
        visible={showModal}
        title="Log out"
        showRoundButton={false}
        description="Are you sure you want to log out?"
        onCloseModal={hideModal}
        action={action}
        buttonTitle="Log out"
      />

      <Modal
        visible={showPopModal}
        title="Session Timeout"
        showRoundButton={false}
        description="Your V Biz session is about to timeout. Click on Refresh to continue transacting"
        onCloseModal={hidePopModal}
        actionLoading={refreshing}
        action={popAction}
        buttonTitle="Refresh"
      />

      {limitAmount && (
        <LimitApprovedModal
          show={showLimitRequestSuccess}
          toggle={toggleLimitRequestSuccess}
          amount={limitAmount ?? 0}
        />
      )}
    </S.Wrapper>
  );
};
