import styled from 'styled-components';

import { ptr } from '../../../../styles/utils';
import { breakpoints } from '../../../../styles/variables';

const ItemContainer = styled.div``;

const ItemRow = styled.div`
  margin-top: ${ptr(16)};
  margin-bottom: ${ptr(12)};
  display: flex;
  flex-wrap: wrap;
  gap: ${ptr(12)};

  @media (max-width: ${breakpoints.sm}px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Remove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
`;

export { ItemContainer, ItemRow, HeaderRow, Remove };
