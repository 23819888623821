import styled from 'styled-components';

import { ptr } from '../../styles/utils';
import { breakpoints } from '../../styles/variables';

const Wrapper = styled.div`
`;

const AccountsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: ${ptr(6)} ${ptr(-6)};
`;

const AccountWrapper = styled.div`
  margin: ${ptr(6)};
  margin-left: ${ptr(6)};
  margin-right: ${ptr(6)};
  width: calc(33.33% - ${ptr(12)});
  @media (max-width: ${breakpoints.xxl}px) {
    flex: 1;
    width: auto;
  }
`;

export {
  Wrapper,
  AccountsWrapper,
  AccountWrapper,
};
