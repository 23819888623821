import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackBar } from '../../../components/BackBar';
import { EventPanel } from '../../../components/EventPanel';
import { Text } from '../../../components/Text';
import { resetTransactionPinSettingsRoute } from '../../../navigation';
import { colors } from '../../../styles/variables';
import * as S from './styles';
import { useSelector } from 'react-redux';
import TwoFactorModal from '../../../components/2faModal';

const TransactionPin: React.FC = () => {
  const route = useHistory();
  const [show2fa, setShow2fa] = useState(false);

  const UserProfile = useSelector(
    (state: { currentUser: any }) => state.currentUser.data
  );

  const toggle2fa = () => setShow2fa((prev) => !prev);

  useEffect(() => {
    if (UserProfile) {
      setShow2fa(!UserProfile.use2fa);
    }
  }, [UserProfile]);

  return (
    <S.Container>
      <BackBar name="Transaction PIN" />
      <S.TextContainer>
        <Text color={colors.black._60} size={6}>
          Transaction PIN is used to authorise all your activities on VBusiness.
        </Text>
      </S.TextContainer>

      <S.Body>
        <EventPanel
          showIcon={false}
          onClick={() => route.push(resetTransactionPinSettingsRoute)}
          title="Reset Tansaction PIN"
          description=""
        />
      </S.Body>

      {UserProfile && (
        <TwoFactorModal
          showSkipButton={false}
          show={show2fa}
          toggle={toggle2fa}
          fromRoute={location.pathname}
        />
      )}
    </S.Container>
  );
};

export default TransactionPin;
