import styled from 'styled-components';
import { colors } from '../../../../styles/variables';

const Container = styled.div`
  padding: 0px 29px 0px 29px;
`;

const Image = styled.img`
  border-radius: 2px;
  background: var(
    --vfd-gradient,
    linear-gradient(
      90deg,
      #a54189 0%,
      #ee3a3e 45.31%,
      #f79431 99.99%,
      rgba(196, 196, 196, 0) 100%
    )
  );
  width: 100%;
  height: 14px;
  flex-shrink: 0;
  margin-top: -6px;
`;

const TopText = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 17px 29px 16px 29px;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 0;
  right: -50px;
  cursor: pointer;
`;

const TopTextLhs = styled.div``;

const OperatorDetails = styled.div`
  padding: 0px 0px 32px 0px;

  > :nth-child(2) {
    margin-top: 8px;
  }
`;

const ButtonGroup = styled.div`
  padding: 0px 0px 64px 0px;

  border-top: 4px solid #dbdfe4;
  padding-top: 14px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  > div {
    height: 50px;
    display: flex;

    justify-content: right;
  }

  > button {
    display: flex;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 17.5px;
  gap: 8px;
`;

const Upload = styled.div`
  padding: 0px;
  margin-bottom: 20px;
  > :nth-child(3) {
    margin-top: 16px;
  }
`;

const UploadText = styled.div`
  margin-bottom: 14px;
`;

const ConsentTexts = styled.div`
  padding: 0px 0px 16px 0px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  > :nth-child(2) {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }
`;

const TopTextRhs = styled.div`
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    width: 4px;
    height: 22px;
    border-radius: 2px;
    background: var(
      --vfd-gradient,
      linear-gradient(
        90deg,
        #a54189 0%,
        #ee3a3e 45.31%,
        #f79431 99.99%,
        rgba(196, 196, 196, 0) 100%
      )
    );
  }
`;

const TermsText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Img = styled.img`
  width: 76px;
  height: 52px;
`;

const TermsLink = styled.a`
  color: ${colors.pink._100};
`;

export {
  Container,
  Image,
  TopText,
  TopTextLhs,
  TopTextRhs,
  Img,
  TermsText,
  TermsLink,
  UploadText,
  ConsentTexts,
  OperatorDetails,
  ButtonGroup,
  Upload,
  Info,
  Close,
};
