import styled from 'styled-components';

import { hexToRgbA, ptr } from '../../styles/utils';
import { colors } from '../../styles/variables';

const Dropdown = styled.div`
  position: relative;
  display: inherit;
`;
const Content = styled.div`
  position: absolute;
  /* bottom:0; */
  top:70px;
  background-color: ${colors.white._100};
  /* min-width: 25%; */
  width: 347px;
  max-height: ${ptr(500)};
  overflow: scroll;
  margin-bottom: ${ptr(30)};
  box-shadow: 0px ${ptr(4)} ${ptr(26)} ${hexToRgbA(colors.black._100, 20)};
  z-index: 1;
 -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
  display: none;
}
`;

const ContentContainer = styled.div`
  margin-bottom: ${ptr(30)};

`;

const ContentWrapper = styled.div`
 padding: ${ptr(20)};
`;

const Item = styled.div`
  margin-top: ${ptr(20)};
  display: flex;
`;

const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right:${ptr(20)};
  padding-top: ${ptr(15)};
`;

const ActionContainer = styled.div`
  position: sticky;
  bottom: 0px;
  z-index:100;
  border-top: solid 1px ${colors.black._5};
  background-color:${colors.white._100};
`;

const ActionLayout = styled.div`
display: flex;
padding: 15px;
`;

export {
  Dropdown,
  Content,
  ContentContainer,
  Item,
  CloseIcon,
  ActionContainer,
  ActionLayout,
  ContentWrapper,
};
