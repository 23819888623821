import { ActionInterface } from "../IAction";
import {
  OnboardingInterface,
  StackInterface,
  BusinessType,
  BusinessInfo,
} from "./IOnboarding";
import {
  CorporateOperatorInterface,
  DirectorPreviewDetails,
  LeaderDirectorShareholderInterface,
  OnboardingSummary,
  OperatorInterface,
} from "../../services/internal/onboarding/IOnboarding";
import {
  SET_IN_APP,
  UNSET_IN_APP,
  NEXT_STACK,
  NEXT_STEP,
  SET_REQUIRED_ACTION,
  EXTEND_STEPS_TO_TOP,
  SET_DATA,
  SET_BUSINESS_TYPE,
  SET_BUSINESS_INFO,
  SET_ERROR,
  BACK,
  RESET_STACKS,
  HAS_ACCOUNT,
  HAS_NOT_ACCOUNT,
  SET_SERVER_DIRECTOR_RESPONSE,
  SET_ONBOARDING_SUMMARY_RESPONSE,
  RESET_STATE,
  DONT_USE_DETAILS,
} from "./actions";

export const defaultLeaderDirectorShareholder: LeaderDirectorShareholderInterface = {
  bvn: "",
  cac: "",
  nin: "",
  email: "",
  isSignatory: true,
  isDirector: true,
  isShareholder: true,
  image: "",
  signatures: undefined,
};

export const defaultOperator: OperatorInterface = {
  role: "",
  email: "",
  //nin: '',
  idNumber: "",
  idType: "",
  phone: "",
  bvn: "",
};

export const defaultCorporateOperator: CorporateOperatorInterface = {
  role: "",
  email: "",
  name: "",
};
const savedState = sessionStorage.getItem('onboardingState')
const initialState = savedState ? JSON.parse(savedState) : {
  inApp: !!(
    localStorage.getItem("inApp") && localStorage.getItem("inApp") === "true"
  ),
  requiredAction: "",
  currentStep: 0,
  hasVBankAccount: false,
  businessType: undefined,
  stacks: [] as Array<StackInterface>,
  error: "",
  serverResponse: [] as Array<DirectorPreviewDetails>,
  serverSummaryResponse: {} as OnboardingSummary,
  corporateBusinessName: "",
  corporateAccountNumber: "",
  corporateEmailAddress: "",
  corporatePhoneNumber: "",
  businessInfo: {} as BusinessInfo,
  useDetails: true,
  data: {
    type: "",
    businessType: "",
    dob: "",
    pin: "",
    role: "",
    workflow: "",
    reference: "",
    maskedEmail: "",
    maskedPhone: "",
    vbankAccount: "",
    credentials: {
      personalEmail: "",
      businessEmail: "",
      password: "",
      idNumber: "",
      idType: "",
      bvn: "",
      phone: "",
    },
    business: {
      name: "",
      phone: "",
      bvn: "",
      nin: "",
      tin: "",
      cac: "",
    },
    leaders: [],
    directors: [],
    shareholders: [],
    operators: [],
    referenceInfo:[],
    corporateOperators: [],
    viewers: [],
  },
};

const reducer = (
  state = initialState,
  action: ActionInterface<
    | StackInterface
    | Record<string, unknown>
    | BusinessType
    | string
    | Array<DirectorPreviewDetails>
    | OnboardingSummary
    | BusinessInfo
  >
): OnboardingInterface => {
  switch (action.type) {
    case SET_IN_APP: {
      localStorage.setItem("inApp", "true");
      return {
        ...state,
        inApp: true,
      };
    }
    case UNSET_IN_APP: {
      localStorage.setItem("inApp", "false");
      return {
        ...state,
        inApp: false,
      };
    }
    case SET_REQUIRED_ACTION: {
      if (action.payload !== undefined) {
        return {
          ...state,
          requiredAction: action.payload as string,
        };
      }
      return state;
    }
    case NEXT_STACK: {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        currentStep: 0,
        stacks: [...state.stacks, action.payload as StackInterface],
      };
    }
    case EXTEND_STEPS_TO_TOP: {
      if (!action.payload) {
        return state;
      }
      const lastStack: StackInterface = state.stacks[state.stacks.length - 1];
      lastStack.steps.unshift(...(action.payload as StackInterface).steps);
      return {
        ...state,
        stacks: [...state.stacks],
      };
    }
    case SET_SERVER_DIRECTOR_RESPONSE:
      return {
        ...state,
        serverResponse: action.payload as Array<DirectorPreviewDetails>,
      };
    case SET_ONBOARDING_SUMMARY_RESPONSE:
      return {
        ...state,
        serverSummaryResponse: action.payload as OnboardingSummary,
      };
    case SET_DATA:
      sessionStorage.setItem('onboardingState', JSON.stringify({
        ...state,
        data: {
          ...state.data,
          ...(action.payload as Record<string, unknown>),
        }
      }))
      return {
        ...state,
        data: {
          ...state.data,
          ...(action.payload as Record<string, unknown>),
        },
      };
    case SET_BUSINESS_TYPE:
      return {
        ...state,
        businessType: action.payload as BusinessType,
      };
      case SET_BUSINESS_INFO:
      return {
        ...state,
        businessInfo: action.payload as BusinessInfo,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload as string,
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case BACK: {
      if (!state.stacks.length) {
        return state;
      }
      if (!state.currentStep) {
        const stacksCopy = [...state.stacks];
        stacksCopy.pop();

        const lastStackSteps = stacksCopy[stacksCopy.length - 1].steps;

        return {
          ...state,
          currentStep: lastStackSteps.length - 1,
          stacks: stacksCopy,
        };
      }
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }
    case RESET_STACKS: {
      const firstStack = state.stacks[0];

      if (firstStack) {
        return {
          ...initialState,
          stacks: [firstStack],
        };
      }

      return state;
    }
    case HAS_ACCOUNT:
      return {
        ...state,
        hasVBankAccount: true,
      };
    case HAS_NOT_ACCOUNT:
      return {
        ...state,
        hasVBankAccount: false,
      };
      case DONT_USE_DETAILS:
        return {
          ...state,
          useDetails: false,
        };
    case RESET_STATE:
      return {
        ...state,
        requiredAction: "",
        currentStep: 0,
        hasVBankAccount: false,
        businessType: undefined,
        stacks: [] as Array<StackInterface>,
        error: "",
        serverResponse: [] as Array<DirectorPreviewDetails>,
        serverSummaryResponse: {} as OnboardingSummary,
        corporateBusinessName: "",
        corporateAccountNumber: "",
        corporateEmailAddress: "",
        corporatePhoneNumber: "",
        businessInfo: {} as BusinessInfo,
        useDetails: true,
        data: {
          type: "",
          businessType: "",
          dob: "",
          pin: "",
          role: "",
          workflow: "",
          reference: "",
          maskedEmail: "",
          maskedPhone: "",
          vbankAccount: "",
          credentials: {
            personalEmail: "",
            businessEmail: "",
            password: "",
          },
          business: {
            name: "",
            phone: "",
            bvn: "",
            nin: "",
            tin: "",
            cac: "",
          },
          leaders: [],
          directors: [],
          shareholders: [],
          operators: [],
          referenceInfo: [],
          corporateOperators: [],
          viewers: [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
