import styled, { css } from 'styled-components';

import { hexToRgbA, ptr } from '../../styles/utils';
import { CircleOptions } from '.';
import { colors } from '../../styles/variables';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
`;

const Circle = styled.span<CircleOptions>`
  ${(props) => css`
    width: ${ptr(props.radius * 2)};
    height: ${ptr(props.radius * 2)};
    ${props.position.left && `left: ${props.position.left}`};
    ${props.position.right && `right: ${props.position.right}`};
    ${props.position.top && `top: ${props.position.top}`};
    ${props.position.bottom && `bottom: ${props.position.bottom}`};
    transform: rotate(${props.rotate}deg);
  `}

  border-radius: 50%;
  background: linear-gradient(159.28deg, ${hexToRgbA(colors.white._100, 12)} 7.45%, ${hexToRgbA(colors.white._100, 0)} 86.28%);
  position: absolute;
  z-index: 0;
`;

export {
  Wrapper,
  Circle,
};
