import styled, { css } from "styled-components";
import { colors, fonts, transition } from "../../styles/variables";
import { OperatorsModalProps } from "./index";
import { crossBrowserTransition, ptr } from "../../styles/utils";

const Container = styled.div<OperatorsModalProps>`
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  ${crossBrowserTransition(transition.slow)}
  ${(props) => css`
    display: ${props.showModal ? "flex" : "none"};
  `}
`;
const Wrapper = styled.div<OperatorsModalProps>`
  background-color: ${colors.white._100};
  border-radius: ${ptr(12)};
  position: relative;
  max-height: 95%;
  
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth ? props.maxWidth : "90%"};
    `} 

  /* overflow: hidden; */

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}

  ${(props) =>
    props.overflowY &&
    css`
      overflow-y: ${props.overflowY || "visible"};
    `}

  ${(props) =>
    props.overflowX &&
    css`
      overflow-x: ${props.overflowX || "hidden"};
    `}
  /* ::-webkit-scrollbar {
  display: none;
}
 -ms-overflow-style: none;
  scrollbar-width: none; */
  
  padding: ${ptr(0)} ${ptr(18)};
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.inter.bold};
  margin-bottom: ${ptr(8)};
  width: 100%;
  border-bottom: 1px solid ${colors.black._10};
`;

const HeaderTitle = styled.h3``;

const CrossWrapper = styled.div<{ background?: string }>`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${colors.white._100};
  ${(props) =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 9999;
`;

export {
  Container,
  Wrapper,
  Header,
  HeaderTitle,
  CrossWrapper,
};
